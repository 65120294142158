import { SourceEntityType } from './newBackendTypes';

const fetchingSynonyms = ['Summoning', 'Collecting', 'Obtaining', 'Acquiescing', 'Procuring', 'Gathering'];

export function randomFetchSynonym() {
  return fetchingSynonyms[Math.floor(Math.random() * fetchingSynonyms.length)];
}

export enum UserGroupsEnum {
  DIT = 'DIT',
  HEDGING = 'Hedging',
  FINANCE = 'Finance',
  EXECUTIVE = 'Executive',
  EXECUTIVE_VP = 'Executive VP',
  CONTROLLER = 'Controller',
  TRADING_MANAGERS = 'Trading Managers',
  ASSISTANT_CONTROLLER = 'Assistant Controller',
  OCEAN_RATES_OPERATOR = 'Ocean Rates Operator',
  LCS = "LC's",
}

export const OFFICE_ADDRESS = `Royce Corporation\n20900 NE 30th Ave #102\nMiami, FL 33180`;

// Contracts
export const NON_HEDGE_MTM_VALUATION_IDS = [140943, 2060583, 10343499, 10343501];

//#region FA Icons

//#region Entity Types
export const BookingIcon = 'fas fa-envelope-open-text';
export const JournalIcon = 'fas fa-cash-register';
export const DraftIcon = 'fas fa-file-signature';
export const ContractIcon = 'fas fa-file-alt';
export const ContactIcon = 'fas fa-address-book';
export const ShipmentIcon = 'fas fa-box-open';
export const InvoiceIcon = 'fas fa-file-invoice-dollar';
export const DiscrepancyIcon = 'fas fa-exclamation-circle';
export const ServiceOrderIcon = 'fas fa-file-invoice';
export const ExchangeContractIcon = 'fas fa-shekel-sign';
export const GlAccountIcon = 'fas fa-book';
export const ReportIcon = 'fas fa-chart-line';
export const FiscalCompanyIcon = 'fas fa-calendar';
//#endregion

//#region Departments
export const HomeIcon = 'fas fa-home';
export const ExecutiveIcon = 'fas fa-user-shield';
export const TradingIcon = 'fas fa-exchange-alt';
export const QualityControlIcon = 'fas fa-medkit';
export const LogisticsIcon = 'fas fa-route';
export const LogisticsManagerIcon = 'fas fa-project-diagram';
export const FinanceIcon = 'fas fa-calculator';
export const AdvanceIcon = 'fas fa-comment-dollar';
export const MetalControlIcon = 'fas fa-hard-hat';
export const DITIcon = 'fas fa-robot';
export const UtilIcon = 'fas fa-tools';
//#endregion

//#region Features
export const GenericListIcon = 'fas fa-chart-bar';
export const UserAssignmentIcon = 'fas fa-id-card-clip';
export const ApprovalsIcon = 'fas fa-user-check';
export const MatchingIcon = 'fas fa-balance-scale';
export const FlexViewIcon = 'fas fa-layer-group';
export const PurchaseTicketIcon = 'fas fa-file-invoice-dollar';
export const NewIcon = 'fas fa-plus-circle';
export const EditIcon = 'fas fa-edit';
export const ExternalLinkIcon = 'fas fa-external-link-alt';
export const CommentsIcon = 'fas fa-comments';
export const DocumentsIcon = 'fas fa-paperclip';
export const TasksIcon = 'fas fa-tasks';
export const FolderIcon = 'fas fa-folder';
export const GoToIcon = 'fas fa-location-arrow';
export const WindowIcon = 'fas fa-window-maximize';
export const TagsIcon = 'fas fa-tags';
export const NotificationActivatedIcon = 'fas fa-bell';
export const NotificationDeactivatedIcon = 'fas fa-bell-slash';
export const MarkAsReadIcon = 'fas fa-circle';
export const ChartIcon = 'fas fa-chart-line';
export const UserAuthorizedCompaniesIcon = 'fas fa-user-shield';
//#endregion
//#endregion

export function getIconClassFromEntityType(entityType: SourceEntityType): string | undefined {
  switch (entityType) {
    case SourceEntityType.ALF_CODE:
      return ContactIcon;
    case SourceEntityType.JOURNAL_ID:
      return JournalIcon;
    case SourceEntityType.CONTRACT_KEY:
      return ContractIcon;
    case SourceEntityType.DRAFT_KEY:
      return DraftIcon;
    case SourceEntityType.DISCREPANCY_ID:
      return DiscrepancyIcon;
    case SourceEntityType.FREIGHT_BOOKING_KEY:
      return BookingIcon;
    case SourceEntityType.CHUNK_KEY:
      return ShipmentIcon;
    case SourceEntityType.SERVICE_ORDER_KEY:
      return ServiceOrderIcon;
    case SourceEntityType.AUTOMATED_REPORTS_ID:
      return ReportIcon;
    case SourceEntityType.INVOICE_KEY:
    case SourceEntityType.PAYMENT_KEY:
    case SourceEntityType.RATE_KEY:
    case SourceEntityType.THALOS_LETTER_OF_CREDIT:
    case SourceEntityType.LETTER_OF_CREDIT_PRESENTATION:
    case SourceEntityType.BUDGET_ELEMENT_KEY:
    case SourceEntityType.IMPUTE_ID:
    case SourceEntityType.FUTURE_KEY:
      return InvoiceIcon;
    case SourceEntityType.EXCHANGE_CONTRACT:
      return ExchangeContractIcon;
    case SourceEntityType.GL_ACCOUNT_LEAVES_ID:
    case SourceEntityType.GL_ACCOUNT_BRANCHES_ID:
      return GlAccountIcon;
    case SourceEntityType.PAYMENT_TERM_ID:
    case SourceEntityType.PAYMENT_TERM_DATE_ID:
      return FiscalCompanyIcon;
    default:
      return;
  }
}
