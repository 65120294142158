import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { WaConstelliumAppointment } from 'src/lib/newBackendTypes/waConstelliumAppointment';

const deleteAppointmentPrefill: prefillCallback<DeleteAppointmentsPrefill> = (delegate, id, column) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const appointmentsRefs = Array.isArray(id) ? id : [id];
      const appointmentsResponses: WaConstelliumAppointment[] = [];
      for (const appointmentReference of appointmentsRefs) {
        const appointments = await api.run<WaConstelliumAppointment>(endpoints.getLogisticsAppointment, { filters: { appointmentReference } }, null);
        appointmentsResponses.push(appointments);
      }

      return { appointmentsResponses };
    })()
  );
};

const deleteAppointmentCheck: checkPrefillCallback<DeleteAppointmentsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.appointmentsResponses.length === 0) return of('No Appointments References to be deleted.');
  for (let item of prefill.appointmentsResponses) {
    if (item.appointmentDate !== null || item.appointmentTime !== null) return of(`Unable to delete the Appointment Reference: Appointment Reference ${item.appointmentReference} is in use`);
  }
  return of(true);
};

const deleteAppointmentForm: createFormCallback<DeleteAppointmentsPrefill, DeleteAppointmentForm> = (delegate, id, prefill) => {
  const appointments = Array.isArray(prefill.appointmentsResponses) ? prefill.appointmentsResponses : [prefill.appointmentsResponses];
  const labels: { type: 'Label'; text: string; style?: object }[] = [
    {
      type: 'Label',
      text: `You are going to delete the following Appointment(s):`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px', display: 'flex', 'justify-content': 'center', color: 'red' },
    },
    {
      type: 'Label',
      text: `${appointments.map((item) => `- ${item.appointmentReference}<br/>`).join('')}`,
      style: { 'margin-bottom': '15px' },
    },
    { type: 'Label', text: `Are you sure to delete the Appointment(s)?`, style: { 'font-weight': 'bold', display: 'flex', 'justify-content': 'center' } },
  ];

  return labels;
};

const deleteAppointmentCallback: submitFormCallback<DeleteAppointmentsPrefill, DeleteAppointmentForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<{ successAppointmentDeleted: WaConstelliumAppointment[]; failedAppointmentReferences: string[] }>((resolve, reject) => {
      (async () => {
        const successAppointmentDeleted: WaConstelliumAppointment[] = [];
        const failedAppointmentReferences: string[] = [];

        for (let res of prefill.appointmentsResponses) {
          const deleteAppointmentRequest: { filters: { appointmentReference: string } } = { filters: { appointmentReference: res.appointmentReference } };

          const appointmentDeleted = await api.run<WaConstelliumAppointment>(endpoints.deleteLogisticsAppointment, deleteAppointmentRequest, null, { blockRedirect: true, hideErrorPopup: true });
          if (appointmentDeleted !== null) successAppointmentDeleted.push(appointmentDeleted);
          else failedAppointmentReferences.push(res.appointmentReference);
        }

        if (successAppointmentDeleted || failedAppointmentReferences) return resolve({ successAppointmentDeleted, failedAppointmentReferences });
        return reject('Unknown result. Please check if the Appointment(s) were deleted and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const successMessage = 'Appointment(s) successfully deleted:';
          const errorMessage = 'An error ocurred with the following Appointment(s). Could not delete:';
          const successIds = res.successAppointmentDeleted.map((ap) => `- ${ap.appointmentReference}`).join(`\n`);
          const failedIds = res.failedAppointmentReferences.map((apf) => `- ${apf}`).join(`\n`);
          if (res.successAppointmentDeleted && res.successAppointmentDeleted.length > 0 && res.failedAppointmentReferences && res.failedAppointmentReferences.length > 0)
            return prompt.htmlDialog('Warning', `<div style="white-space: pre">${successMessage} \n${successIds} \n\n${errorMessage} \n${failedIds}</div>`);
          if (res.successAppointmentDeleted && res.successAppointmentDeleted.length > 0 && !(res.failedAppointmentReferences && res.failedAppointmentReferences.length > 0))
            return prompt.htmlDialog('Success', `<div style="white-space: pre">${successMessage} \n${successIds} </div>`);
          if (!(res.successAppointmentDeleted && res.successAppointmentDeleted.length > 0) && res.failedAppointmentReferences && res.failedAppointmentReferences.length > 0)
            return prompt.htmlDialog('Error', `<div style="white-space: pre">${errorMessage} \n${failedIds}</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const deleteAppointmentReferencePreset: DynamicFormConstant<DeleteAppointmentsPrefill, DeleteAppointmentForm> = {
  allowMultipleRows: true,
  checkPrefill: deleteAppointmentCheck,
  createForm: deleteAppointmentForm,
  entityType: SourceEntityType.APPOINTMENTS,
  getPrefill: deleteAppointmentPrefill,
  label: 'Delete Appointment Reference',
  submitForm: deleteAppointmentCallback,
  title: 'Delete Appointment Reference',
  value: DynamicFormType.DELETE_APPOINTMENT_REFERENCE,
  endpoints: [endpoints.deleteLogisticsAppointment, endpoints.getLogisticsAppointment],
  width: 500,
};

export type DeleteAppointmentsPrefill = {
  appointmentsResponses: WaConstelliumAppointment[];
};
export type DeleteAppointmentForm = {
  appointmentRef: number;
};
