import { of, tap } from 'rxjs';
import { printAdvance } from 'src/app/+modules/+accounting/advance-queue/advance-queue.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { Advance } from 'src/lib/newBackendTypes/advance';
import { DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { updateAdvancePrefillCheck } from './updateAdvance';

export const advancePrefill: prefillCallback<Advance> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<Advance>(endpoints.getAdvance, { filters: { id } }, null);
};

/**
 * @todo Make this generic
 */
export const printAdvanceCallback: submitFormCallback<Advance> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  if (!form.contract) return of(null);
  return api.rpc<ListResponse<Document>>(endpoints.listDocuments, { filters: { entityId: form.id, entityType: SourceEntityType.ADVANCE_KEY } }, null).pipe(
    tap((docResponse) => {
      if (docResponse) {
        printAdvance(delegate, form, docResponse.list);
      }
    })
  );
};

export const printAdvancePreset: DynamicFormConstant<Advance> = {
  allowMultipleRows: false,
  checkPrefill: updateAdvancePrefillCheck,
  endpoints: [endpoints.getAdvance, endpoints.listDocuments, endpoints.generateDocumentsFromPacket],
  entityType: SourceEntityType.ADVANCE_KEY,
  getPrefill: advancePrefill,
  label: 'Print Advance',
  submitForm: printAdvanceCallback,
  title: 'Print Advance',
  value: DynamicFormType.PRINT_ADVANCE,
  width: 300,
};
