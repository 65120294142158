import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { HotkeysService } from 'angular2-hotkeys';
import { BusinessLogicService } from './business-logic-service';
import { CommonDataService } from './common-data.service';
import { DataFormattingService } from './data-formatting.service';
import { EntityLookupService } from './entity-lookup.service';
import { FileUploadService } from './file-upload.service';
import { FlexService } from './flex.service';
import { NotificationService } from './notification.service';
import { PromptService } from './prompt.service';
import { SelectorPopupService } from './selector-popup.service';
import { SpinnerService } from './spinner.service';
import { Store } from './store.service';
import { ThalosApiService } from './thalos-api.service';
import { ValidatorService } from './validator.service';
import { SocketIOService } from './socket.io.service';
import { GraphqlService } from './graphql.service';

/**
 * God Service to expose all services, to make dependency enjection easier
 */
@Injectable({
  providedIn: 'root',
})
export class DelegateService {
  log: string[];

  private _businessLogic: BusinessLogicService;
  private _commonData: CommonDataService;
  private _dataFormatter: DataFormattingService;
  private _notification: NotificationService;
  private _prompt: PromptService;
  private _selector: SelectorPopupService;
  private _store: Store;
  private _api: ThalosApiService;
  private _validator: ValidatorService;
  private _router: Router;
  private _entityLookup: EntityLookupService;
  private _spinner: SpinnerService;
  private _dialog: DialogService;
  private _hotkeys: HotkeysService;
  private _upload: FileUploadService;
  private _flexService: FlexService;
  private _socketIOService: SocketIOService;
  private _graphql: GraphqlService;

  constructor(private injector: Injector) {
    this.log = [];
  }

  getService<K extends keyof DelegateService>(key: K): DelegateService[K] {
    let s = this[key];

    this.log.push(`Service '${key}' accessed`);

    return s;
  }

  get api() {
    if (!this._api) {
      this._api = this.injector.get(ThalosApiService);
    }
    return this._api;
  }

  get businessLogic() {
    if (!this._businessLogic) {
      this._businessLogic = this.injector.get(BusinessLogicService);
    }
    return this._businessLogic;
  }

  get commonData() {
    if (!this._commonData) {
      this._commonData = this.injector.get(CommonDataService);
    }
    return this._commonData;
  }

  get dataFormatter() {
    if (!this._dataFormatter) {
      this._dataFormatter = this.injector.get(DataFormattingService);
    }
    return this._dataFormatter;
  }

  get notification() {
    if (!this._notification) {
      this._notification = this.injector.get(NotificationService);
    }
    return this._notification;
  }

  get prompt() {
    if (!this._prompt) {
      this._prompt = this.injector.get(PromptService);
    }
    return this._prompt;
  }

  get router() {
    if (!this._router) {
      this._router = this.injector.get(Router);
    }
    return this._router;
  }

  get selector() {
    if (!this._selector) {
      this._selector = this.injector.get(SelectorPopupService);
    }
    return this._selector;
  }

  get store() {
    if (!this._store) {
      this._store = this.injector.get(Store);
    }
    return this._store;
  }

  get validator() {
    if (!this._validator) {
      this._validator = this.injector.get(ValidatorService);
    }
    return this._validator;
  }

  get entityLookup() {
    if (!this._entityLookup) {
      this._entityLookup = this.injector.get(EntityLookupService);
    }
    return this._entityLookup;
  }

  get spinner() {
    if (!this._spinner) {
      this._spinner = this.injector.get(SpinnerService);
    }
    return this._spinner;
  }

  get dialog() {
    if (!this._dialog) {
      this._dialog = this.injector.get(DialogService);
    }
    return this._dialog;
  }

  get hotkeys() {
    if (!this._hotkeys) {
      this._hotkeys = this.injector.get(HotkeysService);
    }
    return this._hotkeys;
  }

  get upload() {
    if (!this._upload) {
      this._upload = this.injector.get(FileUploadService);
    }
    return this._upload;
  }

  get flexService() {
    if (!this._flexService) {
      this._flexService = this.injector.get(FlexService);
    }
    return this._flexService;
  }

  get socketIOService() {
    if (!this._socketIOService) {
      this._socketIOService = this.injector.get(SocketIOService);
    }
    return this._socketIOService;
  }

  get graphql() {
    if (!this._graphql) {
      this._graphql = this.injector.get(GraphqlService);
    }
    return this._graphql;
  }
}
