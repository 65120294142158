import { DialogResult } from '@progress/kendo-angular-dialog';
import { from, map, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ContractLine, FinishContractLineRequest, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const finishContractLinePrefill: prefillCallback<FinishContractLinePrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const contractLine = await api.run<ContractLine>(endpoints.getContractLineData, {
        filters: { id: id },
      });

      return contractLine;
    })()
  );
};

const finishContractLineCheck: checkPrefillCallback<FinishContractLinePrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  return of(true);
};

const FinishContractLineRequest: openFormCallback<FinishContractLinePrefill, YN> = (delegate, id, prefill) => {
  const prompt = delegate.getService('prompt');
  const text = `Contract ${prefill.contract.number ? prefill.contract.number : 'Unknown'}, Element #${prefill.lineNumber ? prefill.lineNumber : 'Unknown'} ${
    prefill.finished === YN.N ? 'will be Finished' : 'will be Unfinished'
  }. Continue?`;
  return prompt
    .simpleConfirmation(`Update Contract ${prefill.contract.number ? prefill.contract.number : 'Unknown'}, Element #${prefill.lineNumber ? prefill.lineNumber : 'Unknown'}`, text)
    .pipe(map((res) => (res ? prefill.finished : 'Close')));
};

const submitFinishContractLine: submitFormCallback<FinishContractLinePrefill, YN> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        try {
          const request: FinishContractLineRequest = {
            id: id,
            finished: result === YN.Y ? YN.N : YN.Y,
          };
          const finishContractLineResponse = await api.run<ContractLine>(endpoints.finishContractLine, request, null);
          return resolve(
            prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Contract ${finishContractLineResponse.contract.number + ', Element #' + finishContractLineResponse.lineNumber} has been updated as ${
                finishContractLineResponse.finished === YN.Y ? 'Finished' : 'Unfinished'
              }.</div>`
            )
          );
        } catch (err) {
          return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the contract line was finished and try again if necessary.</div>`));
        }
      })();
    })
  );
};

export const finishContractLinePreset: DynamicFormConstant<FinishContractLinePrefill, YN> = {
  allowMultipleRows: false,
  checkPrefill: finishContractLineCheck,
  openForm: FinishContractLineRequest,
  entityType: SourceEntityType.CONTRACT_LINE_ID,
  getPrefill: finishContractLinePrefill,
  label: 'Finish/Unfinish Contract Line',
  submitForm: submitFinishContractLine,
  title: 'Finish/Unfinish Contract Line',
  value: DynamicFormType.FINISH_CONTRACT_LINE,
  endpoints: [endpoints.getContractLineData, endpoints.finishContractLine],
  width: 400,
};

export type FinishContractLinePrefill = Pick<ContractLine, 'id' | 'contract' | 'lineNumber' | 'finished'>;
