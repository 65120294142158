import { ListColumnForm } from 'src/app/+modules/+it/containers/flex/flex-column/flex-column.component';
import { endpoints } from '../apiEndpoints';
import { ListFilterGroupUserGroupAssignment } from '../flex/flexFilterGroupUserGroupAssignment';
import { OnlyIncludes } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { YN } from '../newBackendTypes';

export enum ListFilterType {
  HARDCODED_VALUE = 1,
  PLACEHOLDER = 2,
  PROMPT_USER = 3,
  DYNAMIC_LIST = 4,
  AND = 5,
  OR = 6,
  NOT = 7,
}
export const ListFilterTypes = enumOptionsFromEnum<ListFilterType>(ListFilterType);

export enum ListComparativeOperator {
  IS_NULL = 0,
  EQUAL_TO = 1,
  NOT_EQUAL_TO = 2,
  GREATER_THAN = 3,
  LESS_THAN = 4,
  GREATER_THAN_OR_EQUAL_TO = 5,
  LESS_THAN_OR_EQUAL_TO = 6,
  LIKE = 7,
  NOT_LIKE = 8,
  STARTS_WITH = 9,
  ENDS_WITH = 10,
  IN = 11,
  NOT_IN = 12,
  NOT_NULL = 13,
}

export const ListComparativeOperators = enumOptionsFromEnum(ListComparativeOperator);

export enum ListFilterValueType {
  STRING = 1,
  NUMBER = 2,
  DATE = 3,
  NUMERIC_DATE = 4,
}
export const ListFilterValueTypes = enumOptionsFromEnum(ListFilterValueType, [ListFilterValueType.NUMERIC_DATE]);

export enum PlaceholderType {
  CURRENT_USER = 1,
  TODAY = 2,
  YESTERDAY = 3,
  BEGINNING_OF_THIS_MONTH = 4,
  END_OF_THIS_MONTH = 5,
  BEGINNING_OF_NEXT_MONTH = 6,
  BEGINNING_OF_THIS_YEAR = 7,
  BEGINNING_OF_NEXT_YEAR = 8,
  BEGINNING_OF_LAST_YEAR = 9,
  BEGINNING_OF_LAST_MONTH = 10,
  THIS_WEEK = 11,
  LAST_WEEK = 12,
  YEAR_AGO_TODAY = 13,
}
export const PlaceholderTypes = enumOptionsFromEnum(ListFilterValueType);

export type ListFilterGroupResponse = {
  id: number;
  flexViewId: number;
  name: string;
  isDefault: YN;
  defaultOrder: number;
  rows?: ListFilterRowResponse[];
  authorizedGroups?: ListFilterGroupUserGroupAssignment[];
};

export type ListFilterGroup = Omit<ListFilterGroupResponse, 'rows'> & {
  rows: ListFilterRow[];
};

export type ListFilterRowResponse = {
  id: number;
  filterGroupId: number;
  label: string;
  fieldId: string | null;
  type: ListFilterType;
  typeConfiguration: string;
  defaultOrder: number;
};

export type BaseListFilterRow = Omit<ListFilterRowResponse, 'typeConfiguration'> & {
  field?: OnlyIncludes<ListColumnForm, 'id' | 'name'> | null;
};

export type ListFilterSubRow = Omit<ListFilterRow, 'id' | 'filterGroupId'>;

export type BaseListFilterConfiguration = {
  operator: ListComparativeOperator;
};

export type HardcodedListFilterConfiguration = BaseListFilterConfiguration & {
  valueType: ListFilterValueType;
  value: string | number | Date;
  valueArray: string[] | number[];
};

export function isHardcodedFilterConfiguration(filterConfig: ListFilterConfiguration): filterConfig is HardcodedListFilterConfiguration {
  return (
    typeof (filterConfig as HardcodedListFilterConfiguration).valueType === 'number' &&
    (typeof (filterConfig as HardcodedListFilterConfiguration).value === 'string' ||
      typeof (filterConfig as HardcodedListFilterConfiguration).value === 'number' ||
      Array.isArray((filterConfig as HardcodedListFilterConfiguration).value) ||
      (filterConfig as HardcodedListFilterConfiguration).value === null)
  );
}

export type PlaceholderListFilterConfiguration = BaseListFilterConfiguration & {
  valueType: ListFilterValueType;
  placeholder: PlaceholderType;
  placeholderArray: PlaceholderType[];
};

export function isPlaceholderFilterConfiguration(filterConfig: ListFilterConfiguration): filterConfig is PlaceholderListFilterConfiguration {
  return typeof (filterConfig as PlaceholderListFilterConfiguration).valueType === 'number' && typeof (filterConfig as PlaceholderListFilterConfiguration).placeholder === 'number';
}

export type PromptListFilterConfiguration = BaseListFilterConfiguration & {
  valueType: ListFilterValueType;
  mandatory: YN;
};

export function isPromptFilterConfiguration(filterConfig: ListFilterConfiguration): filterConfig is PromptListFilterConfiguration {
  return typeof (filterConfig as PromptListFilterConfiguration).valueType === 'number';
}

export type DynamicListListFilterConfiguration = BaseListFilterConfiguration & {
  endpoint: endpoints;
  valueKey: string;
  labelKey: string;
  mandatory: YN;
};

export function isDynamicListFilterConfiguration(filterConfig: ListFilterConfiguration): filterConfig is DynamicListListFilterConfiguration {
  return (
    typeof (filterConfig as DynamicListListFilterConfiguration).endpoint === 'string' &&
    typeof (filterConfig as DynamicListListFilterConfiguration).valueKey === 'string' &&
    typeof (filterConfig as DynamicListListFilterConfiguration).labelKey === 'string'
  );
}

//wrapping the array in object in case more data is needed at a later time
export type LogicSubRowConfiguration = {
  rows: ListFilterSubRow[];
};

export function isLogicSubRowFilterConfiguration(filterConfig: ListFilterConfiguration): filterConfig is LogicSubRowConfiguration {
  return (
    typeof filterConfig === 'object' &&
    filterConfig !== null &&
    'rows' in filterConfig &&
    Array.isArray((filterConfig as LogicSubRowConfiguration).rows) &&
    filterConfig.rows.every((r) => ('fieldId' in r && !!r.fieldId) || isLogicSubRowFilterConfiguration(r.typeConfiguration))
  );
}

export type ListFilterConfiguration =
  | HardcodedListFilterConfiguration
  | PlaceholderListFilterConfiguration
  | PromptListFilterConfiguration
  | DynamicListListFilterConfiguration
  | LogicSubRowConfiguration;

export type HardcodedListFilterRow = BaseListFilterRow & {
  type: ListFilterType.HARDCODED_VALUE;
  typeConfiguration: HardcodedListFilterConfiguration;
};

export type PlaceholderListFilterRow = BaseListFilterRow & {
  type: ListFilterType.PLACEHOLDER;
  typeConfiguration: PlaceholderListFilterConfiguration;
};

export type PromptListFilterRow = BaseListFilterRow & {
  type: ListFilterType.PROMPT_USER;
  typeConfiguration: PromptListFilterConfiguration;
};

export type DynamicListListFilterRow = BaseListFilterRow & {
  type: ListFilterType.DYNAMIC_LIST;
  typeConfiguration: DynamicListListFilterConfiguration;
};

export type AndListFilterRow = BaseListFilterRow & {
  type: ListFilterType.AND;
  typeConfiguration: LogicSubRowConfiguration;
};

export type OrListFilterRow = BaseListFilterRow & {
  type: ListFilterType.OR;
  typeConfiguration: LogicSubRowConfiguration;
};

export type NotListFilterRow = BaseListFilterRow & {
  type: ListFilterType.NOT;
  typeConfiguration: LogicSubRowConfiguration;
};

export type ListFilterRow = HardcodedListFilterRow | PlaceholderListFilterRow | PromptListFilterRow | DynamicListListFilterRow | AndListFilterRow | OrListFilterRow | NotListFilterRow;

/**
 * A logical row, either a top level row or a sub row, with a uuid to recreate the tree
 */
export type ListFilterAbstractRow = Omit<ListFilterRow | ListFilterSubRow, 'typeConfiguration'> & {
  uuid: string;
  typeConfiguration:
    | HardcodedListFilterConfiguration
    | PlaceholderListFilterConfiguration
    | PromptListFilterConfiguration
    | DynamicListListFilterConfiguration
    | {
        rows: ListFilterAbstractRow[];
      };
};

/**
 * A logical row that is of type prompt or dynamic list
 */
export type ListFilterAbstractPromptRow = abstractPromptRow | abstractDynamicRow;

type abstractPromptRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.PROMPT_USER;
  typeConfiguration: PromptListFilterConfiguration;
};

type abstractDynamicRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.DYNAMIC_LIST;
  typeConfiguration: DynamicListListFilterConfiguration;
};

/**
 * A logical row that is of type hardcoded or placehodler
 */
export type ListFilterAbstractHardcodedRow = abstractHardcodedRow | abstractPlaceholderRow;

type abstractHardcodedRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.HARDCODED_VALUE;
  typeConfiguration: HardcodedListFilterConfiguration;
};

type abstractPlaceholderRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.PLACEHOLDER;
  typeConfiguration: PlaceholderListFilterConfiguration;
};

export type CreateListFilterGroupRequest = Omit<ListFilterGroupResponse, 'id' | 'rows'>;
export type UpdateListFilterGroupRequest = Partial<Omit<CreateListFilterGroupRequest, 'flexViewId'>> & Pick<ListFilterGroupResponse, 'id'>;

export type CreateListFilterRowRequest = Omit<ListFilterRowResponse, 'id'>;
export type UpdateListFilterRowRequest = Partial<Omit<CreateListFilterRowRequest, 'filterGroupId'>> & Pick<ListFilterRowResponse, 'id'>;
