import { YN } from './enums';

export class BookingExpense {
  bookingId: number;
  serviceOrderId: number;
  counterpartyName: string;
  elementDescription: string;
  orderComment: string;
  serviceName: string;
  orderDate: number;
  orderReference: string;
  orderPrice: number;
  currencyId: number;
  completelyInvoiced: YN;
  invoiceKey: number;
  invoiceNumber: string;
}
