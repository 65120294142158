<div class="grid-container grid-container--h100 d-flex">
  <div class="grid-container__grid">
    <ag-grid-angular
      class="ag-grid-container ag-grid-container--h500 ag-theme-balham"
      [rowData]="data"
      [gridOptions]="gridOptions"
      (cellValueChanged)="onCellValueChanged($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
<div *ngIf="!readonly" class="container-file-button">
  <input hidden type="file" id="file" #file (click)="file.value = null" (change)="importExcel($event)" accept=".xlsx, .xlsm" />
  <button type="button" class="btn btn-info" (click)="file.click()"><i class="fas fa-file-excel"></i> Import Excel</button>
</div>
