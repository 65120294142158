import { InjectionToken } from '@angular/core';
import { UserState, usersReducer } from './user';
import { layoutsReducer, LayoutState } from './layout';
import { FormState, FormReducer } from './form';

export const REDUCERS_TOKEN = new InjectionToken('reducers');

export const reducers: ReducerMap = {
  user: usersReducer,
  layout: layoutsReducer,
  form: FormReducer,
};

export interface State {
  user: UserState;
  layout: LayoutState;
  form: FormState;
}

export type ReducerMap = {
  [key: string]: ReducerFunction;
};

export type ReducerFunction = (action: Action, state: StateSlice) => StateSlice;

export type Action = {
  type: string;
  payload?: any;
};

export type StateSlice = UserState | LayoutState | FormState;
