import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { round } from 'lodash';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { dollarFormat, fixationRateFormat, lbPriceFormat, mtWeightFormat } from 'src/lib/commonTypes';
import { CreatePriceFixationPrefill, PriceFixationForm } from 'src/lib/flex/forms/createPriceFixation';
import { fixationRateValidator, metalUnitPercentageValidator, quantityToFixValidator } from 'src/lib/genericValidators';
import { getTodayUTC, markFormGroupTouched, weightFormat } from 'src/lib/helperFunctions';
import { CommonUnits, CommonUnitsNameMap, Contact, ContactMethodType, ContractType, ContractTypes, UnitInfo } from 'src/lib/newBackendTypes';
import { ContractLineFixationData } from 'src/lib/newBackendTypes/contractLineFixationData';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { FixationSendEmailOption, FixationSendEmailOptions } from 'src/lib/newBackendTypes/priceFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-create-price-fixation',
  templateUrl: './create-price-fixation.component.html',
})
export class CreatePriceFixationComponent implements ModalFormComponent<PriceFixationForm, CreatePriceFixationPrefill> {
  form: TypedFormGroup<PriceFixationForm>;
  popup = true;

  weightFormat: NumberFormatOptions;
  marketPriceFormat: NumberFormatOptions;
  fixedContractPriceFormat: NumberFormatOptions;
  fixationRateFormat = fixationRateFormat();
  metalUnitPercentageFormat = mtWeightFormat();
  muDecimals: number;
  contractTypes = ContractTypes;
  showCounter = true;
  maxlength = 255;

  // Market
  market: string = '';
  marketCurrAbbr: string = '';
  marketUnitAbbr: string = '';
  marketUnitId: number | null = null;
  marketCurrId: number | null = null;
  spread: number | null = null;
  percentage: number | null = null;
  // Premium Market
  premiumValuationName: string = '';
  premiumMarketCurrAbbr: string = '';
  premiumMarketUnitAbbr: string = '';
  premiumFixedMarketPrice: number | null = null;
  premiumSpread: number | null = null;
  premimumPercentage: number | null = null;
  premiumUnitId: number | null = null;
  premiumCurrencyId: number | null = null;
  // Premium
  fixedPremium: number | null = null;
  contractCurrAbbr: string = '';
  contractPriceUnitAbbr: string = '';
  contractPriceUnitId: number | null = null;
  contractCurrencyId: number | null = null;
  contractUnitAbbr: string = '';
  contractQtyUnitKey: number | null = null;
  productId: number | null = null;

  //Currencies
  currencyConversions: ExchangeRateInfo[];

  contractPriceFactor: number | null = null;
  quantityToFixBreakdown: string = '';
  fixedContractPrice: number | null = null;
  quantityPendingToFix: number | null = null;
  quantityToFix: number | null = null;
  quantityToFixNew: number | null = null;
  hasDifferentCurrencies = false;

  dataPriceFixation: ContractLineFixationData;
  counterparty: Contact;

  /**
   * Inputs for the shipments fixations list
   */
  contractType: ContractType;
  fixWeight: number | null = null;
  canApply: boolean = false;

  emailOptions = FixationSendEmailOptions;

  constructor(public commonData: CommonDataService, private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<PriceFixationForm>({
      quantityFixed: new UntypedFormControl(null),
      fixDate: new UntypedFormControl(getTodayUTC(), Validators.required),
      contractType: new UntypedFormControl(null),
      termPrice: new UntypedFormControl(null, Validators.required),
      market: new UntypedFormControl(''),
      quantityToFixBreakdown: new UntypedFormControl(''),
      fixedContractPrice: new UntypedFormControl(null),
      quantityToFix: new UntypedFormControl(null),
      metalUnits: new UntypedFormControl(null, Validators.required),
      metalUnitsPercentage: new UntypedFormControl(null, [Validators.required, metalUnitPercentageValidator()]),
      leftOverFutQtyComment: new UntypedFormControl(''),
      fixationRate: new UntypedFormControl(null, [Validators.required, fixationRateValidator()]),
      shipments: new UntypedFormControl([]),
      applyShipmentsIds: new UntypedFormControl([]),
      sendEmail: new UntypedFormControl(FixationSendEmailOption.DO_NOT_SEND_EMAIL),
    });
  }

  calculateMetalUnits() {
    const quantityFixed = this.form.get('quantityFixed').value;
    const mup = this.form.get('metalUnitsPercentage').value;
    let metalUnits = (mup * quantityFixed) / 100;
    metalUnits = this.formatter.roundQuantity(metalUnits, this.contractQtyUnitKey);
    this.form.patchValue({ metalUnits });
    this.form.get('metalUnits').updateValueAndValidity();
  }

  calculateMetalUnitsPercentage() {
    const quantityFixed = this.form.get('quantityFixed').value;
    const mu = this.form.get('metalUnits').value;
    let metalUnitsPercentage = (mu / quantityFixed) * 100;
    metalUnitsPercentage = round(metalUnitsPercentage, 3);
    this.form.patchValue({ metalUnitsPercentage });
    this.form.get('metalUnitsPercentage').markAsTouched();
    this.form.get('metalUnitsPercentage').updateValueAndValidity();
  }

  validateCurrencies(marketCurrAbbr: number, contractCurrAbbr: number) {
    marketCurrAbbr === contractCurrAbbr ? (this.hasDifferentCurrencies = false) : (this.hasDifferentCurrencies = true);

    if (!this.hasDifferentCurrencies) {
      this.form.patchValue({
        fixationRate: 1,
      });
    }
  }

  calculateFixedContractPrice() {
    const termPriceVal = this.form.get('termPrice').value;
    let market = null;
    if (!!termPriceVal) {
      let marketFormula = ((termPriceVal + this.spread) * this.percentage) / 100;
      market = this.makeConversions(marketFormula, this.marketUnitId, this.marketCurrId);
    }

    const premiumMarket = this.calculatePremiumMarket();
    this.fixedContractPrice = market !== null && premiumMarket !== null ? market + premiumMarket + this.fixedPremium : null;
    this.fixedContractPrice = this.formatter.roundPrice(this.fixedContractPrice, this.contractCurrencyId, this.contractPriceUnitId);

    this.form.patchValue({
      fixedContractPrice: this.fixedContractPrice,
    });
  }

  calculatePremiumMarket() {
    if (this.premiumSpread !== null && this.premimumPercentage !== null) {
      if (this.premiumFixedMarketPrice !== null) {
        const premiumMarketFormula = ((this.premiumFixedMarketPrice + this.premiumSpread) * this.premimumPercentage) / 100;
        this.canApply = true;
        return this.makeConversions(premiumMarketFormula, this.premiumUnitId, this.premiumCurrencyId);
      } else {
        this.canApply = false;
        return null;
      }
    }
    this.canApply = true;
    return 0;
  }

  makeConversions(formula: number, unitId: number, currencyId: number): number {
    const fxRate = this.form.get('fixationRate').value;
    // Conversion of base unit to MT
    const marketUnit = this.commonData.staticUnits.value.find((u) => u.unitId === unitId);
    const marketUnitFactor: UnitInfo = marketUnit.unitFactors.find((f) => f.productId === this.productId);
    // Conversion of base currency to dollars
    const currencyRate = this.currencyConversions.find((fx) => fx.baseCurrencyId === currencyId && fx.targetCurrencyId === 3);

    const calc = ((formula / fxRate) * currencyRate.factor) / marketUnitFactor.factor;

    // Return the calculation converted to the contract unit
    return calc * this.contractPriceFactor;
  }

  calculateQuantityToFix(value: number) {
    this.fixWeight = value;
    this.quantityToFixNew = this.quantityPendingToFix - value;

    this.form.patchValue({
      quantityToFix: this.quantityToFixNew,
    });

    this.form.get('quantityFixed').setValidators([Validators.required, quantityToFixValidator(this.quantityToFix, this.contractUnitAbbr)]);
  }

  shipmentsAppliedHandler(applyShipmentsIds: number[]) {
    this.form.patchValue({ applyShipmentsIds });
  }

  prefillForm(data: CreatePriceFixationPrefill) {
    if (!!data) {
      this.dataPriceFixation = data.contractLine;
      this.counterparty = data.counterparty;

      //Currencies
      this.currencyConversions = data.currencyConversions;

      // Market
      this.market = this.dataPriceFixation.screenFormula;
      this.marketCurrAbbr = this.dataPriceFixation.marketCurrAbbr;
      this.marketUnitAbbr = this.dataPriceFixation.marketUnitAbbr;
      this.marketUnitId = this.dataPriceFixation.marketUnitId;
      this.marketCurrId = this.dataPriceFixation?.marketCurrId;
      this.spread = this.dataPriceFixation.spread;
      this.percentage = this.dataPriceFixation.percentage;
      // Premium Market
      this.premiumValuationName = this.dataPriceFixation.premiumValuationName;
      this.premiumMarketCurrAbbr = this.dataPriceFixation.premiumMarketCurrAbbr;
      this.premiumMarketUnitAbbr = this.dataPriceFixation.premiumMarketUnitAbbr;
      this.premiumFixedMarketPrice = this.dataPriceFixation.premiumFixedMarketPrice;
      this.premiumUnitId = this.dataPriceFixation.premiumMarketUnitId;
      this.premiumCurrencyId = this.dataPriceFixation.premiumMarketCurrId;
      this.premiumSpread = this.dataPriceFixation.premiumSpread;
      this.premimumPercentage = this.dataPriceFixation.premimumPercentage;
      // Premium
      this.fixedPremium = this.dataPriceFixation.fixedPremium;
      this.contractCurrAbbr = this.dataPriceFixation.contractCurrAbbr;
      this.contractPriceUnitAbbr = this.dataPriceFixation.contractPriceUnitAbbr;
      this.contractPriceUnitId = this.dataPriceFixation.contractPriceUnitKey;
      this.contractCurrencyId = this.dataPriceFixation.contractCurrencyId;
      this.contractUnitAbbr = this.dataPriceFixation.contractUnitAbbr;
      this.contractQtyUnitKey = this.dataPriceFixation.contractQtyUnitKey;
      this.productId = this.dataPriceFixation.productId;

      this.muDecimals = this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0;

      if (this.contractPriceUnitId && this.productId) {
        const contractPriceUnit = this.commonData.staticUnits.value.find((u) => u.unitId === this.contractPriceUnitId);
        if (contractPriceUnit) {
          const contractPriceFactor = contractPriceUnit.unitFactors.find((f) => f.productId === this.productId);
          if (contractPriceFactor) this.contractPriceFactor = contractPriceFactor.factor;
        }
      }

      this.weightFormat = this.formatter.getUnitsFormat(this.contractUnitAbbr);
      this.marketPriceFormat = this.marketUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? dollarFormat() : lbPriceFormat();
      //TODO: Implement getPriceFormat in the rest of required fields
      this.fixedContractPriceFormat = this.formatter.getPriceFormat(this.contractCurrencyId, this.contractPriceUnitId);
      this.quantityPendingToFix = this.dataPriceFixation.quantityPendingToFix;
      this.quantityToFix = this.formatter.roundQuantity(this.quantityPendingToFix, this.contractQtyUnitKey);
      this.contractType = this.dataPriceFixation.contractType;
      this.fixWeight = this.quantityToFix;
      const quantity = this.dataPriceFixation.quantity ? weightFormat(this.dataPriceFixation.quantity, this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0) : 0;
      const quantityFixed = this.dataPriceFixation.quantityFixed ? weightFormat(this.dataPriceFixation.quantityFixed, this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0) : 0;
      this.quantityToFixBreakdown = `${
        quantity +
        ' ' +
        this.contractUnitAbbr +
        ' + ' +
        this.dataPriceFixation.tolerance +
        '% TOL - ' +
        quantityFixed +
        ' ' +
        this.contractUnitAbbr +
        ' QTY Fixed = ' +
        weightFormat(this.quantityToFix) +
        ' ' +
        this.contractUnitAbbr
      }`;

      this.form.patchValue({
        ...data,
        contractType: this.contractType,
        market: this.market,
        quantityToFixBreakdown: this.quantityToFixBreakdown,
        quantityFixed: this.quantityToFix,
      });

      this.validateCurrencies(this.dataPriceFixation.marketCurrId, this.dataPriceFixation.contractCurrencyId);
      this.calculateFixedContractPrice();
      this.calculateQuantityToFix(this.quantityToFix);
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): PriceFixationForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    return {
      quantityFixed: formVal.quantityFixed,
      fixDate: formVal.fixDate,
      termPrice: round(formVal.termPrice, 4),
      fixedContractPrice: formVal.fixedContractPrice,
      leftOverFutQtyComment: formVal.leftOverFutQtyComment,
      fixationRate: formVal.fixationRate,
      applyShipmentsIds: formVal.applyShipmentsIds,
      metalUnits: this.formatter.roundQuantity(formVal.metalUnits, this.contractQtyUnitKey),
      metalUnitsPercentage: round(formVal.metalUnitsPercentage, 3),
      sendEmail: formVal.sendEmail,
    };
  }

  get hasPricingConfEmail(): boolean {
    return this.counterparty.methods.some((method) => method.type === ContactMethodType.PCE);
  }
}
