import { Component, Input, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { AmountTag, CurrencyTag, FormulaTag as Tag, MarketTag, MultiplierTag, TagType, UnitTag } from 'src/lib/newBackendTypes/formulaTag';
import { FutureMarketTerm } from 'src/lib/newBackendTypes/futureMarketTerm';
import { v4 as uuid } from 'uuid';
import { FormElementComponent } from '../form-element/form-element.component';

@UntilDestroy()
@Component({
  selector: 'formula-input',
  templateUrl: './formula-input.component.html',
  styleUrls: ['./formula-input.component.scss'],
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => FormulaInputComponent) }],
  host: {
    '(blur)': '_onTouch()',
  },
})
export class FormulaInputComponent extends FormElementComponent {
  @Input()
  markets: FutureMarketTerm[] = [];

  @Input()
  units: Unit[] = [];

  @Input()
  currencies: Currency[] = [];

  @Input()
  placeholder: string = '';

  // Use the component as a currency amount entry and not as a full fledged formula
  @Input()
  simpleMode: boolean = false;

  // Allow standalone percentages, only if the component is being used in simpleMode
  @Input()
  allowPercentage: boolean = false;

  @Input()
  requireMarket: boolean = false;

  @ViewChild('multiselect', { static: false })
  private multiselectEl: MultiSelectComponent;
  multiselectFc: UntypedFormControl;
  options: Tag[];
  hint: string = '';
  private nextOptionsMap: Map<TagType, TagType[]>;
  private changeSubscription: Subscription;
  formControl: AbstractControl;

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this.multiselectFc = new UntypedFormControl();
    this.nextOptionsMap = new Map();
    this.nextOptionsMap.set(TagType.OPEN_PARENTHESIS, [TagType.MARKET, TagType.AMOUNT, TagType.OPEN_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.CLOSE_PARENTHESIS, [TagType.CLOSE_PARENTHESIS, TagType.PLUS, TagType.MINUS, TagType.TIMES]);
    this.nextOptionsMap.set(TagType.MARKET, [TagType.PLUS, TagType.MINUS, TagType.TIMES, TagType.CLOSE_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.AMOUNT, [TagType.CURRENCY]);
    this.nextOptionsMap.set(TagType.CURRENCY, [TagType.UNIT]);
    this.nextOptionsMap.set(TagType.UNIT, [TagType.PLUS, TagType.MINUS, TagType.CLOSE_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.MULTIPLIER, [TagType.PLUS, TagType.MINUS, TagType.CLOSE_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.PLUS, [TagType.AMOUNT, TagType.MARKET, TagType.OPEN_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.MINUS, [TagType.AMOUNT, TagType.MARKET, TagType.OPEN_PARENTHESIS]);
    this.nextOptionsMap.set(TagType.TIMES, [TagType.MULTIPLIER]);
  }

  ngOnInit(): void {
    this.formControl = this.controlDir.control;
    this.changeSubscription = this.multiselectFc.valueChanges
      .pipe(
        tap((workingTags) => {
          this.valueChange(workingTags);
          this.onChange(this.cleanWorkingTags(workingTags || []));
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.multiselectEl.onBlur
      .pipe(
        take(1),
        tap((event) => this.onTouch())
      )
      .pipe(untilDestroyed(this))
      .subscribe();

    if (this.formControl) setTimeout(() => this.formControl.setValidators([this.validate]));
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl) this.formControl.updateValueAndValidity({ onlySelf: true });
  }

  // Function Factory. Returns a function that converts the selected tags into groups and checks for invalid and partial tags
  tagMapper() {
    let nextOptionsMap = this.nextOptionsMap;
    return (tags: WorkingTag[]) => {
      let formulaTags: Array<WorkingTag | WorkingTag[]> = [];
      for (let i = 0; i < tags.length; i++) {
        let tag = tags[i];
        let previousType: TagType = i > 0 ? tags[i - 1].type : TagType.OPEN_PARENTHESIS;
        let group: WorkingTag[] = [];
        if (!nextOptionsMap.get(previousType).includes(tag.type)) {
          formulaTags.push({ ...tag, status: 'invalid' });
          continue;
        }
        switch (tag.type) {
          case TagType.OPEN_PARENTHESIS:
            let foundClosing = 0;
            for (let j = i + 1; j < tags.length; j++) {
              if (tags[j].type === ')') foundClosing++;
              if (tags[j].type === '(') foundClosing--;
            }
            if (foundClosing <= 0) {
              formulaTags.push({ ...tag, status: 'partial' });
              break;
            }
            group = [{ ...tag, status: 'valid' }];
            if (previousType === TagType.PLUS || previousType === TagType.MINUS) {
              group.unshift({ ...(formulaTags.pop() as WorkingTag), status: 'valid' });
            }
            formulaTags.push(group);
            break;
          case TagType.CLOSE_PARENTHESIS:
            let foundOpening = 0;
            for (let j = 0; j < i; j++) {
              if (tags[j].type === '(') foundOpening++;
              if (tags[j].type === ')') foundOpening--;
            }
            formulaTags.push(foundOpening > 0 ? [{ ...tag, status: 'valid' }] : { ...tag, status: 'invalid' });
            break;
          case TagType.AMOUNT:
            let currencyTag = tags.length > i + 1 && tags[i + 1].type === 'currency' ? (tags[i + 1] as WorkingTag<CurrencyTag>) : undefined;
            let unitTag = tags.length > i + 2 && tags[i + 2].type === 'unit' ? (tags[i + 2] as WorkingTag<UnitTag>) : undefined;
            if (!currencyTag || (!unitTag && (this.units.length > 0 || !this.simpleMode))) {
              formulaTags.push({ ...tag, status: 'partial' });
              break;
            }
            tags[i].status = 'valid';
            tags[i + 1].status = 'valid';
            if (unitTag) tags[i + 2].status = 'valid';
            group = [{ ...tag, status: 'valid' }, currencyTag];
            if (!!unitTag) group.push(unitTag);
            if (previousType === TagType.PLUS || previousType === TagType.MINUS) {
              tags[i - 1].status = 'valid';
              group.unshift({ ...(formulaTags.pop() as WorkingTag), status: 'valid' });
            }
            formulaTags.push(group);
            i += 2;
            break;
          case TagType.MARKET:
            group = [{ ...tag, status: 'valid' }];
            tags[i].status = 'valid';
            if (previousType === TagType.PLUS || previousType === TagType.MINUS) {
              tags[i - 1].status = 'valid';
              group.unshift({ ...(formulaTags.pop() as WorkingTag), status: 'valid' });
            }
            formulaTags.push(group);
            break;
          case TagType.MULTIPLIER:
            let times = formulaTags[formulaTags.length - 1];
            if (Array.isArray(times) || (times.type !== TagType.TIMES && (!this.simpleMode || !this.allowPercentage)) || times.status !== 'partial') {
              formulaTags.push({ ...tag, status: 'invalid' });
              break;
            }
            group = [
              { ...(formulaTags.pop() as WorkingTag), status: 'valid' },
              { ...tag, status: 'valid' },
            ];
            formulaTags.push(group);
            break;
          case TagType.CURRENCY:
          case TagType.UNIT:
          case TagType.PLUS:
          case TagType.MINUS:
          case TagType.TIMES:
            formulaTags.push({ ...tag, status: 'partial' });
            break;
        }
      }

      return formulaTags;
    };
  }

  // When the formula tags are modified we want to regenerate the hint based on the current options and regenerate the list of options
  valueChange(newValue: WorkingTag[]) {
    this.generateHint();
    this.refreshOptions();
  }

  // Refresh the options when the search term changes
  filterChange(filterText: string) {
    this.refreshOptions();
  }

  // Generates a list of options given the current possible tag types and the user entry. If it determines that a perfect match has happened, adds the tag to the formula
  refreshOptions() {
    let options: Tag[] = [];
    let userEntry = !!this.multiselectEl && !!this.multiselectEl.searchbar ? this.multiselectEl.searchbar.value : '';
    let search = userEntry.trim().toUpperCase();
    let perfectMatch: Tag = undefined;

    if (this.nextOptions.includes(TagType.AMOUNT) && /^\d*(\.\d*)?$/.test(search) && parseFloat(search) > 0) {
      let tag: AmountTag = {
        type: TagType.AMOUNT,
        label: userEntry,
        amount: parseFloat(search),
      };
      options.push(tag);
      if (userEntry.slice(-1) === ' ') {
        perfectMatch = tag;
      }
    }
    if (
      this.nextOptions.includes(TagType.MULTIPLIER) &&
      /^([1]*[0-9]{1,2})?(\.[0-9]{1,2})?[%]?$/.test(search) &&
      parseFloat(search.replace('%', '')) > 0 &&
      parseFloat(userEntry.replace('%', '')) <= 100
    ) {
      let pct = search.indexOf('%') !== -1;
      let value = parseFloat(search.replace('%', ''));

      if (!pct && value < 1) {
        value = value * 100;
      }
      let tag: MultiplierTag = {
        type: TagType.MULTIPLIER,
        label: `${value}%`,
        value,
      };
      options.push(tag);
      if ((userEntry.slice(-1) === ' ' && !perfectMatch) || userEntry.slice(-1) === '%') {
        perfectMatch = tag;
      }
    }

    if (this.nextOptions.includes(TagType.MARKET) && /^[\w\s]+$/.test(search)) {
      let markets: MarketTag[] = this.markets
        .filter((market) => market.name.toUpperCase().indexOf(search) >= 0)
        .map((market): MarketTag => {
          return {
            type: TagType.MARKET,
            label: market.name,
            id: market.id,
          };
        });
      options = options.concat(markets);
      perfectMatch = markets.find((market) => market.label.toUpperCase() === search) || perfectMatch;
    }

    if (this.nextOptions.includes(TagType.CURRENCY) && /^[\w\s/]*$/.test(search)) {
      let currencies: CurrencyTag[] = this.currencies
        .filter((currency) => currency.code.toUpperCase().indexOf(search) >= 0)
        .map((currency): CurrencyTag => {
          return {
            type: TagType.CURRENCY,
            label: currency.code,
            id: currency.id,
          };
        });
      options = options.concat(currencies);
      perfectMatch = currencies.find((currency) => currency.label.toUpperCase() === search);
    }

    if (this.nextOptions.includes(TagType.UNIT) && /^[/]?[\w\s]*$/.test(search)) {
      let units: UnitTag[] = this.units
        .filter((unit) => unit.code.toUpperCase().indexOf(search) >= 0)
        .map((unit): UnitTag => {
          return {
            type: TagType.UNIT,
            label: unit.code,
            unitId: unit.unitId,
          };
        });
      options = options.concat(units);
      perfectMatch = units.find((unit) => unit.label.toUpperCase() === search);
    }

    if ((this.nextOptions as string[]).includes(search)) {
      switch (search) {
        case TagType.PLUS:
        case TagType.MINUS:
        case TagType.TIMES:
        case TagType.OPEN_PARENTHESIS:
        case TagType.CLOSE_PARENTHESIS:
          perfectMatch = { type: search, label: search };
          options.push(perfectMatch);
          break;
      }
    }

    if (!!perfectMatch) {
      let currentValues = this.multiselectFc.value;
      currentValues = (Array.isArray(currentValues) ? currentValues : []).concat(this.generateWorkingTags([perfectMatch]).pop());
      this.multiselectEl.reset();
      this.multiselectEl.searchbar.input.nativeElement.value = '';
      this.multiselectFc.setValue(currentValues);
      this.options = [];
      this.generateHint();
      return;
    }
    this.options = this.generateWorkingTags(options);
  }

  // Returns the type of the last tag entered in the formula
  get lastTagType() {
    return !!this.multiselectFc && Array.isArray(this.multiselectFc.value) && this.multiselectFc.value.length > 0
      ? this.multiselectFc.value[this.multiselectFc.value.length - 1].type
      : TagType.OPEN_PARENTHESIS;
  }

  // Returns an array of the possible types of tags that can be entered at this point in the formula
  get nextOptions() {
    let nextOptions = this.nextOptionsMap.has(this.lastTagType) ? this.nextOptionsMap.get(this.lastTagType) : [];
    let foundOpening = 0;
    if (!!this.multiselectFc && Array.isArray(this.multiselectFc.value) && !this.simpleMode) {
      for (let tag of this.multiselectFc.value) {
        if (tag.type === '(') foundOpening++;
        if (tag.type === ')') foundOpening--;
      }
    }
    if (this.simpleMode) {
      nextOptions = nextOptions.filter((option) => ![TagType.OPEN_PARENTHESIS, TagType.CLOSE_PARENTHESIS, TagType.PLUS, TagType.MINUS, TagType.TIMES].includes(option));
    }
    if (nextOptions.includes(TagType.MARKET) && (!Array.isArray(this.markets) || this.markets.length < 1)) {
      nextOptions = nextOptions.filter((option) => option != TagType.MARKET);
    }
    if (nextOptions.includes(TagType.UNIT) && (!Array.isArray(this.units) || this.units.length < 1)) {
      nextOptions = nextOptions.filter((option) => option != TagType.UNIT);
    }
    if (nextOptions.includes(TagType.OPEN_PARENTHESIS) && this.simpleMode) {
      nextOptions = nextOptions.filter((option) => option != TagType.OPEN_PARENTHESIS);
    }
    if (nextOptions.includes(TagType.CLOSE_PARENTHESIS) && foundOpening < 1) {
      nextOptions = nextOptions.filter((option) => option != TagType.CLOSE_PARENTHESIS);
    }
    if (this.lastTagType === TagType.OPEN_PARENTHESIS && this.simpleMode && this.allowPercentage) {
      nextOptions.push(TagType.MULTIPLIER);
    }
    return nextOptions;
  }

  // Generates the human readable form of the allowed types of tokens
  generateHint() {
    let options = this.nextOptions.map((expecting) =>
      expecting === TagType.MARKET
        ? 'a market'
        : expecting === TagType.MULTIPLIER
        ? 'a percentage'
        : expecting === TagType.AMOUNT
        ? 'an amount'
        : expecting === TagType.CURRENCY
        ? 'the currency'
        : expecting === TagType.UNIT
        ? 'the unit'
        : expecting
    );
    switch (options.length) {
      case 0:
        this.hint = this.simpleMode ? '' : 'No options available';
        if (this.simpleMode) this.multiselectEl.toggle(false);
        return;
      case 1:
        this.hint = 'Enter ' + options.pop();
        return;
      default:
        let last = options.pop();
        this.hint = 'Enter ' + options.join(', ') + ' or ' + last;
    }
  }

  generateWorkingTags(tags: Tag[]): WorkingTag[] {
    return tags.map((tag): WorkingTag => {
      return { ...tag, uid: uuid(), status: 'partial' };
    });
  }

  cleanWorkingTags(workingTags: WorkingTag[]): Tag[] {
    return workingTags.map((workingTag): Tag => {
      let { uid, status, ...tag } = workingTag;
      return tag;
    });
  }

  writeValue(formula: Tag[]): void {
    this.multiselectFc.setValue(this.generateWorkingTags(formula || []));
  }

  validate = (control: UntypedFormControl): ValidationErrors => {
    let tags = <Tag[]>control.value; //this.multiselectFc.value
    //let currentValue = control.value;
    if (typeof tags !== 'object' && typeof tags !== 'undefined') {
      return {
        parseError: true,
        empty: true,
      };
    }
    if (typeof tags === 'undefined' || !Array.isArray(tags)) {
      // || currentValue.length === 0){
      return null;
    }

    let partialError: boolean = false;
    let invalidError: boolean = false;
    let bradyFormula: boolean = false;
    let parenthesisMismatch: boolean = false;
    let mtmMarket: boolean = false;
    let currencyCodes: number[] = undefined;
    let unitCodes: number[] = undefined;
    let marketIds: number[] = undefined;

    let closeParenthesisCount = 0,
      openParenthesisCount = 0;

    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i];
      let previousType: TagType = i > 0 ? tags[i - 1].type : tag.type === TagType.MULTIPLIER && this.allowPercentage ? TagType.TIMES : TagType.OPEN_PARENTHESIS;

      if (!this.nextOptionsMap.get(previousType).includes(tag.type)) {
        invalidError = true;
        continue;
      }
      if (this.simpleMode && [TagType.MINUS, TagType.PLUS, TagType.TIMES, TagType.CLOSE_PARENTHESIS, TagType.OPEN_PARENTHESIS].includes(tag.type)) {
        invalidError = true;
        continue;
      }

      //if(!tag.status || !['partial','valid'].includes(tag.status)){ invalidError = true; continue; }
      if (!tag.label || typeof tag.label !== 'string' || tag.label.trim() === '') {
        invalidError = true;
        continue;
      }
      if (!tag.type || typeof tag.type !== 'string') {
        invalidError = true;
        continue;
      }
      let complete;
      switch (tag.type) {
        case TagType.AMOUNT:
          if (!tag.amount || typeof tag.amount !== 'number') {
            invalidError = true;
            continue;
          }
          complete = i + 1 < tags.length && tags[i + 1].type === TagType.CURRENCY;
          if ((this.units || []).length > 0) complete = complete && i + 2 < tags.length && tags[i + 2].type === TagType.UNIT;
          if (!complete) {
            partialError = true;
            continue;
          }
          break;
        case TagType.CURRENCY:
          if (typeof currencyCodes === 'undefined') currencyCodes = (this.currencies || []).map((currency) => currency.id);
          if (!tag.id || typeof tag.id !== 'number' || !currencyCodes.includes(tag.id)) {
            invalidError = true;
            continue;
          }
          break;
        case TagType.MARKET:
          if (typeof marketIds === 'undefined') marketIds = (this.markets || []).map((market) => market.id);
          if (!tag.id || typeof tag.id !== 'number' || !marketIds.includes(tag.id)) {
            invalidError = true;
            continue;
          }
          break;
        case TagType.MULTIPLIER:
          if (!tag.value || typeof tag.value !== 'number') {
            invalidError = true;
            continue;
          }
          complete = (i > 0 && tags[i - 1].type === TagType.TIMES) || this.allowPercentage;
          if (!complete) {
            partialError = true;
            continue;
          }
          break;
        case TagType.UNIT:
          if (typeof unitCodes === 'undefined') unitCodes = (this.units || []).map((unit) => unit.unitId);
          if (!tag.unitId || typeof tag.unitId !== 'number' || !unitCodes.includes(tag.unitId)) {
            invalidError = true;
            continue;
          }
          break;
        case TagType.OPEN_PARENTHESIS:
          openParenthesisCount++;
          break;
        case TagType.CLOSE_PARENTHESIS:
          closeParenthesisCount++;
          if (closeParenthesisCount > openParenthesisCount) {
            parenthesisMismatch = true;
            continue;
          }
          break;
        case TagType.TIMES:
          complete = i + 1 < tags.length;
          if (!complete) {
            partialError = true;
            continue;
          }
          break;
        case TagType.PLUS:
          complete = i + 1 < tags.length;
          if (!complete) {
            partialError = true;
            continue;
          }
          break;
        case TagType.MINUS:
          complete = i + 1 < tags.length;
          if (!complete) {
            partialError = true;
            continue;
          }
          break;
      }
    }

    if (closeParenthesisCount !== openParenthesisCount) parenthesisMismatch = true;

    let containsMarket = tags.some((tag) => tag.type === TagType.MARKET);

    if (!containsMarket && !!this.requireMarket) mtmMarket = true;

    //Brady limitations
    let markets = tags.filter((tag) => tag.type === TagType.MARKET);

    if (markets.length > 2) {
      bradyFormula = true;
    }
    let premiumMarkets = this.markets.filter((market) => market.futureMarket.marketType === 5);

    let midwest = markets.find((tag) => tag.type === TagType.MARKET && !!premiumMarkets.some((market) => market.id === tag.id));
    let market = markets.find((tag) => tag.type === TagType.MARKET && !premiumMarkets.some((market) => market.id === tag.id));

    if (markets.length === 2 && !midwest) bradyFormula = true;
    if (!market && !!midwest) bradyFormula = true;

    if (!partialError && !invalidError && !parenthesisMismatch && !bradyFormula && !mtmMarket) return null;

    return {
      parenthesisMismatch,
      parseError: false,
      empty: false,
      partialError,
      invalidError,
      bradyFormula,
      mtmMarket,
    };
  };

  public focus() {
    if (this.multiselectEl) {
      setTimeout(() => {
        this.multiselectEl.focus();
      });
    }
  }
}

export type WorkingTag<T = Tag> = T & {
  status: 'partial' | 'valid' | 'invalid';
  uid: string;
};

export interface Market {
  id: number;
  fullName: string;
}

export interface Currency {
  code: string;
  id: number;
}

export interface Unit {
  code: string;
  unitId: number;
}
