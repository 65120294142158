<kendo-datepicker
  *ngIf="!loader"
  #datepicker
  [readonly]="readonly"
  [ngClass]="{ readonly: readonly }"
  [placeholder]="placeholder"
  [max]="max"
  [min]="min"
  [format]="format"
  [activeView]="activeView"
  [bottomView]="activeView"
  [calendarType]="calendarType"
  [incompleteDateValidation]="true"
  [rangeValidation]="true"
  [twoDigitYearMax]="99"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  [formControl]="innerControl"
>
</kendo-datepicker>
<button kendoButton [icon]="'calculator'" type="button" (click)="openCalculator()" *ngIf="showCalculator && !readonly && !loader"></button>
<input-field-loader class="w-100" *ngIf="loader" [readonly]="readonly"></input-field-loader>
