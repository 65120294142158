import { Component, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import * as _ from 'lodash';
import { combineLatest, concat, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { State } from 'src/app/core/reducers';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse, StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { DropdownConfig } from 'src/lib/DropdownConfig';
import { EnumLabels } from 'src/lib/generics';
import { endpointAuthorizationSubscription, endpointsAuthorized, markFormGroupTouched } from 'src/lib/helperFunctions';
import { Approval, ApprovalStatus, CreateApprovalRequest, CreateRelatedApproval, UpdateApprovalRequest } from 'src/lib/newBackendTypes/approval';
import { ApprovalType } from 'src/lib/newBackendTypes/approvalTypes';
import { SubEntityContainer } from 'src/lib/SubEntityContainer';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';

export type ApprovalForm = Pick<Approval, 'id' | 'approvalType' | 'approvedAt' | 'approvedBy' | 'status' | 'requestedAt' | 'requestedBy' | 'approvalRequests'> & {
  action?: ApprovalStatus;
  actionsAvailable?: EnumLabels<ApprovalStatus>;
};

export const ApprovalActions: EnumLabels<ApprovalStatus> = [
  { value: ApprovalStatus.Y, label: 'Approve' },
  { value: ApprovalStatus.N, label: 'Deny' },
  { value: ApprovalStatus.NY, label: 'Request Approval' },
  { value: ApprovalStatus.YN, label: 'Request Review' },
];

@UntilDestroy()
@Component({
  selector: 'entity-approvals',
  templateUrl: './entity-approvals.component.html',
  providers: [{ provide: SubEntityContainer, useExisting: forwardRef(() => EntityApprovalsComponent) }],
})
export class EntityApprovalsComponent extends SubEntityContainer<Approval> {
  userApprovalTypes: readonly number[];

  approvalForm: TypedFormArray<ApprovalForm>;
  approvals?: Approval[];

  approvalTypeConfig: DropdownConfig<ApprovalType>;

  statusOptions: { value: ApprovalStatus; label: string }[];

  valueChangeSubscription: Subscription;

  authorized: endpointsAuthorized;

  get createAuthorized() {
    return this.authorized[endpoints.createApproval] && this.authorized[endpoints.listApprovalTypeApprovers] && this.authorized[endpoints.listApprovalTypes];
  }

  get updateAuthorized() {
    return this.authorized[endpoints.updateApproval] && this.authorized[endpoints.listApprovalTypeApprovers];
  }

  get deleteAuthorized() {
    return this.authorized[endpoints.deleteApproval];
  }

  approvalSub: StoreSubscription<number[]>;

  constructor(route: ActivatedRoute, controlDir: NgControl, store: Store, private api: ThalosApiService, private spinnerService: SpinnerService, private dialogService: DialogService) {
    super(route, controlDir, store);

    this.approvalForm = new TypedFormArray([]);

    this.approvalTypeConfig = new DropdownConfig({
      labelField: 'name',
      valueField: 'id',
      listProcedure: endpoints.listApprovalTypes,
    });

    this.approvalSub = this.store.subscribe((value: State) => value.user.userApprovalTypes);
    this.approvalSub.$.pipe(untilDestroyed(this)).subscribe((approvalTypes) => {
      this.userApprovalTypes = approvalTypes;
      this.approvalForm.patchValue(
        this.approvalForm.value.map((a) => {
          return {
            ...a,
            actionsAvailable: this.actionsFilter(a),
          };
        })
      );
    });

    this.statusOptions = [
      { value: ApprovalStatus.N, label: 'Denied' },
      { value: ApprovalStatus.NY, label: 'Pending Approval' },
      { value: ApprovalStatus.Y, label: 'Approved' },
      { value: ApprovalStatus.YN, label: 'Pending Review' },
    ];

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit() {
    this.getFormControl().setValidators(() => (this.approvalForm.valid ? null : { error: true }));
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.approvalSub.unsubscribe();
  }

  async loadSubForm() {
    if (!this.authorized[endpoints.listApprovals]) return;

    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
    let filters = { entityId: this.entityId, entityType: this.entityType };
    let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Approvals');
    let approvals = await lastValueFrom(this.api.rpc<ListResponse<Approval>>(endpoints.listApprovals, { filters }, { list: [], count: 0 }).pipe(map((res) => res.list)));

    this.setValue(approvals, false);

    this.spinnerService.completeRequest(rid);
  }

  setValue(approvals: Approval[], external?: boolean) {
    this.approvals = approvals || [];
    this.approvalForm.controls = [];

    for (let _ of this.approvals) {
      this.addApproval();
    }
    this.approvalForm.patchValue(
      (approvals || []).map((a) => {
        let returnVal: ApprovalForm = {
          approvedAt: a.approvedAt ? new Date(a.approvedAt) : null,
          approvedBy: a.approvedBy,
          requestedAt: a.requestedAt ? new Date(a.requestedAt) : null,
          requestedBy: a.requestedBy,
          id: a.id,
          approvalType: a.approvalType,
          approvalRequests: a.approvalRequests,
          status: a.status,
        };
        returnVal.actionsAvailable = this.actionsFilter(returnVal);
        return returnVal;
      }),
      { emitEvent: false }
    );
  }

  addApproval() {
    let fg = new TypedFormGroup<ApprovalForm>({
      id: new UntypedFormControl(),
      approvalType: new UntypedFormControl(null, [Validators.required, this.approvalTypeValidator()]),
      approvalRequests: new UntypedFormControl([]),
      approvedAt: new UntypedFormControl(),
      approvedBy: new UntypedFormControl(),
      status: new UntypedFormControl(ApprovalStatus.NY, Validators.required),
      requestedAt: new UntypedFormControl(),
      requestedBy: new UntypedFormControl(),
      action: new UntypedFormControl(),
      actionsAvailable: new UntypedFormControl([]),
    });

    this.approvalForm.push(fg);

    concat(fg.get('approvalType').valueChanges, fg.get('status').valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe((c) => {
        fg.get('actionsAvailable').setValue(this.actionsFilter(fg.value));
      });
  }

  markAsTouched() {
    markFormGroupTouched(this.approvalForm);
  }

  isCurrentUserAnApprover(type?: ApprovalType): boolean {
    if (!type) return false;
    const approval = this.userApprovalTypes.find((t) => type.id === t);
    return !!approval;
  }

  actionsFilter(approval: ApprovalForm) {
    return ApprovalActions.filter((action) => {
      switch (action.value) {
        case ApprovalStatus.Y:
          return this.isCurrentUserAnApprover(approval.approvalType) && approval.status !== ApprovalStatus.Y;
        case ApprovalStatus.N:
          return this.isCurrentUserAnApprover(approval.approvalType) && approval.status !== ApprovalStatus.N;
        case ApprovalStatus.NY:
          return approval.status === ApprovalStatus.N || approval.status === ApprovalStatus.NY;
        case ApprovalStatus.YN:
          return approval.status === ApprovalStatus.Y || approval.status === ApprovalStatus.YN;
        default:
          return false;
      }
    });
  }

  showApproverDropdown(approval: ApprovalForm) {
    return approval.action === ApprovalStatus.NY || approval.action === ApprovalStatus.YN || (approval.approvalRequests && approval.approvalRequests.length > 0) || !!approval.approvedBy;
  }

  approverDropdownReadonly(approval: ApprovalForm) {
    return (approval.action !== ApprovalStatus.NY && approval.action !== ApprovalStatus.YN) || (!!approval.id && !this.updateAuthorized);
  }

  deleteApproval(index: number) {
    let approvalForm = this.approvalForm.get([index]);
    if (!approvalForm || (!this.deleteAuthorized && !!approvalForm.value.id)) return;
    this.dialogService
      .open({
        content: 'Are you sure you wish to delete this approval?  This cannot be undone.',
        actions: [
          {
            text: 'Cancel',
          },
          {
            themeColor: 'primary',
            text: 'Delete',
          },
        ],
      })
      .result.subscribe(async (res) => {
        if (!(res instanceof DialogCloseResult) && res.text === 'Delete') {
          if (approvalForm.value.id) {
            let res = await lastValueFrom(this.api.rpc<Approval>(endpoints.deleteApproval, { filters: { id: approvalForm.value.id } }, null));
            if (res) {
              this.approvalForm.removeAt(index);
            }
          } else {
            this.approvalForm.removeAt(index);
          }
        }
      });
  }

  getValue(): (CreateRelatedApproval | CreateApprovalRequest | UpdateApprovalRequest)[] {
    let appRequests: ApprovalForm[] = this.approvalForm.value;

    return appRequests
      .filter((af) => {
        return !af.id || !!af.action;
      })
      .map((af) => {
        if (!!af.id) {
          let request: UpdateApprovalRequest = {
            id: af.id,
          };
          if (af.action === ApprovalStatus.Y) request.status = ApprovalStatus.Y;
          else if (af.action === ApprovalStatus.N) request.status = ApprovalStatus.N;
          else if (af.action === ApprovalStatus.NY) {
            request.approvalRequests = af.approvalRequests || [];
            request.status = ApprovalStatus.NY;
          } else if (af.action === ApprovalStatus.YN) {
            request.approvalRequests = af.approvalRequests || [];
            request.status = ApprovalStatus.YN;
          }
          return request;
        } else {
          let request: CreateRelatedApproval = {
            approvalTypeId: af.approvalType ? af.approvalType.id : null,
            status: ApprovalStatus.NY,
            approvalRequests: [],
          };

          if (af.action === ApprovalStatus.Y) request.status = ApprovalStatus.Y;
          else if (af.action === ApprovalStatus.N) request.status = ApprovalStatus.N;
          else if (af.action === ApprovalStatus.NY) {
            request.approvalRequests = af.approvalRequests || [];
            request.status = ApprovalStatus.NY;
          } else if (af.action === ApprovalStatus.YN) {
            request.approvalRequests = af.approvalRequests || [];
            request.status = ApprovalStatus.YN;
          }

          if (this.autoSave) {
            (<CreateApprovalRequest>request).entityType = this.entityType;
            if (this.entityId) (<CreateApprovalRequest>request).entityId = this.entityId;
          }

          return request;
        }
      });
  }

  save(entityId: number): Observable<Approval[]> {
    if (this.autoSave === false && !this._readonlyMode) return of([null]);
    let appRequests: ApprovalForm[] = this.approvalForm.value;
    let observables: Observable<Approval>[] = [];

    for (let a of appRequests) {
      if (!!a.id) {
        let existingApproval = this.approvals.find((approval) => {
          return a.id === approval.id;
        });
        if (!this.authorized[endpoints.updateApproval]) {
          if (!!existingApproval) {
            observables.push(of(existingApproval));
          }
          return;
        }

        if (
          existingApproval &&
          a.action &&
          (existingApproval.status !== a.action ||
            !_.isEqual(
              a.approvalRequests.map((ar) => ar.approverId),
              (existingApproval.approvalRequests || []).map((ar) => ar.approverId)
            ))
        ) {
          const request: UpdateApprovalRequest = {
            id: a.id,
            status: a.action,
            approvalRequests: a.action === ApprovalStatus.Y || a.action === ApprovalStatus.N ? [] : a.approvalRequests,
          };

          let o = this.api.rpc<Approval>(endpoints.updateApproval, request, null);
          observables.push(o);
        } else if (existingApproval) {
          observables.push(of(existingApproval));
        }
      } else if (this.authorized[endpoints.createApproval]) {
        const request: CreateApprovalRequest = {
          status: a.action,
          entityId,
          entityType: this.entityType,
          approvalTypeId: a.approvalType ? a.approvalType.id : null,
          approvalRequests: a.action === ApprovalStatus.Y || a.action === ApprovalStatus.N ? [] : a.approvalRequests,
        };

        let o = this.api.rpc<Approval>(endpoints.createApproval, request, null);
        if (this.createAuthorized) {
          observables.push(o);
        }
      }
    }

    let rid: string;
    return observables.length === 0
      ? of([])
      : of(null).pipe(
          tap((_) => {
            rid = this.spinnerService.startRequest('Saving Approvals');
          }),
          switchMap((res) => {
            return combineLatest(observables);
          }),
          tap((res) => {
            this.spinnerService.completeRequest(rid);
          })
        );
  }

  public clickSaveChanges() {
    markFormGroupTouched(this.approvalForm);
    if (!this.entityId) return;
    if (this.approvalForm.invalid) {
      this.dialogService.open({
        title: 'Invalid',
        content: 'One or more field(s) are invalid or missing',
      });
      return;
    }
    this.save(this.entityId).subscribe((res) => {
      if (res.every((a) => !!a)) {
        this.dialogService
          .open({
            title: 'Success',
            content: 'Approvals saved successfully',
          })
          .result.subscribe(() => {
            this.loadSubForm();
          });
      }
    });
  }

  approvalTypeValidator() {
    return (control: AbstractControl) => {
      let approvalForms = this.approvalForm.controls.filter((g: UntypedFormGroup) => {
        return g.get('approvalType') !== control && g.value.approvalType && control.value && g.value.approvalType.id === control.value.id;
      });
      return approvalForms.length > 0 ? { custom: 'Cannot have duplicate approval types' } : null;
    };
  }
}
