import { Validators } from '@angular/forms';
import { of } from 'rxjs';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { maxValidator, minValidator } from 'src/lib/genericValidators';
import { ContractLine, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';

type rolloverForm = { marketValuationId: number; spread?: number; percentage?: number };
export const contractLineRolloverForm: createFormCallback<rolloverForm> = (delegate, id, prefill) => {
  const commonData = delegate.getService('commonData');
  const valuations = commonData.staticFilteredMarketValuations.value;

  const formElements: (FormElementConfig<rolloverForm> | FormElementConfig<rolloverForm>[])[] = [
    _fe('marketValuationId', 'Market Valuation', { data: valuations, valueField: 'valuationId', labelField: 'valuationName' }, null, Validators.required),
  ];
  if (!Array.isArray(id) || id.length === 1) {
    formElements.push([
      _fe('spread', 'Spread', { minimumFractionDigits: 2, maximumFractionDigits: 4 }, null, [Validators.required]),
      _fe('percentage', 'Percentage', { minimumFractionDigits: 0, maximumFractionDigits: 2, fieldType: 4 }, null, [minValidator(0, false), maxValidator(200, true), Validators.required]),
    ]);
  }

  return formElements;
};

export const contractLineRolloverSubmit: submitFormCallback<rolloverForm> = (delegate, id, form) => {
  if (!id || id.length === 0) return of(null);
  const api = delegate.getService('api');

  if (!Array.isArray(id)) id = [id];

  id = Array.from(new Set(id));

  const request: {
    lineId: number;
    marketValuationId: number;
    spread?: number;
    percentage?: number;
  }[] = (id as number[]).map((lineId) => ({
    lineId,
    marketValuationId: form.marketValuationId,
  }));

  if (!!form.spread) request[0].spread = form.spread;
  if (!!form.percentage) request[0].percentage = form.percentage;

  return api.rpc<ContractLine[]>(endpoints.rollOverUnfulfilledContractLine, request, null);
};

export const contractLineRolloverPreset: DynamicFormConstant<rolloverForm> = {
  value: DynamicFormType.CONTRACT_LINE_ROLLOVER,
  label: 'Rollover Contract',
  title: 'Rollover Contract',
  entityType: SourceEntityType.CONTRACT_LINE_ID,
  allowMultipleRows: true,
  endpoints: [endpoints.rollOverUnfulfilledContractLine],
  width: 300,
  createForm: contractLineRolloverForm,
  submitForm: contractLineRolloverSubmit,
};
