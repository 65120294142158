<div *ngFor="let clause of clauses">
  <div class="clause-header">
    <div>Clause {{ clause.clauseNumber }}</div>
    <div>Type: {{ clause.clauseType }}</div>
  </div>
  <div class="clause">
    <p>
      {{ clause.text }}
    </p>
  </div>
</div>
