<kendo-combobox
  #dropdown
  [data]="dropdownOptions"
  [filterable]="true"
  [valueField]="'id'"
  [textField]="'label'"
  [ngClass]="{ readonly: readonly }"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [suggest]="true"
  (open)="onOpen()"
  [readonly]="readonly"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
>
  <ng-template kendoComboBoxItemTemplate let-dataItem>
    <div class="mb-1">
      <div class="">{{ dataItem?.label }}</div>
      <div class="small" *ngIf="dataItem?.counterpartyName">{{ dataItem?.counterpartyName }} - {{ dataItem?.contractDate | date : 'shortDate' }}</div>
    </div>
  </ng-template>
</kendo-combobox>
