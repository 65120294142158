<form [formGroup]="form">
  <div class="row justify-content-center" style="max-width: 500px">
    <vertical-field name="Email to" class="col-12">
      <simple-dropdown [formControlName]="'emailTo'" [data]="traderEmails" [valuePrimitive]="true" [valueField]="'value'" [textField]="'value'"> </simple-dropdown>
    </vertical-field>
    <vertical-field [name]="'Message'" class="col-12">
      <textarea-wrapper [formControlName]="'message'" [showCounter]="showCounter" [maxlength]="maxlength"> </textarea-wrapper>
    </vertical-field>
  </div>
</form>
