import { SystemColumn } from './systemColumn';

export class SystemObject {
  name: string;
  objectId: number;
  principalId: number | null;
  schemaId: number;
  parentObjectId: number;
  type: CommonSystemObjectType;
  createDate: Date;
  modifyDate: Date;
  isMsShipped: number;
  isPublished: number;
  isSchemaPublished: number;
  schema?: { schemaId: number; name: string; principalId: number };
  columns?: SystemColumn[];
}

export enum CommonSystemObjectType {
  TYPE_TABLE = 'TT',
  SQL_SCALAR_FUNCTION = 'FN',
  SQL_INLINE_TABLE_VALED_FUNCTION = 'IF',
  CHECK_CONSTRAINT = 'C',
  UNIQUE_CONSTRAINT = 'UQ',
  SERVICE_QUEUE = 'SQ',
  FOREIGN_KEY_CONSTRAINT = 'F',
  USER_TABLE = 'U',
  CLR_SCALAR_FUNCTION = 'FS',
  DEFAULT_CONSTRAINT = 'D',
  PRIMARY_KEY_CONSTRAINT = 'PK',
  VIEW = 'V',
  SYSTEM_TABLE = 'S',
  AGGREGATE_FUNCTION = 'AF',
  INTERNAL_TABLE = 'IT',
  SQL_STORED_PROCEDURE = 'P',
  SQL_TABLE_VALUED_FUNCTION = 'TF',
  SQL_TRIGGER = 'TR',
}
