import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ApplyFixationToInvoiceForm } from 'src/lib/flex/forms/applyFixationToInvoice';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Contact, ContactMethodType } from 'src/lib/newBackendTypes';
import { ApplyUnapplyFixationToInvoicePrefill, ApplyUnapplyPriceFixationRequest, FixationSendEmailOption, FixationSendEmailOptions } from 'src/lib/newBackendTypes/priceFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-apply-fixation-to-invoice',
  templateUrl: './apply-fixation-to-invoice.component.html',
})
export class ApplyFixationToInvoiceComponent implements ModalFormComponent<ApplyFixationToInvoiceForm, ApplyUnapplyFixationToInvoicePrefill> {
  form: TypedFormGroup<ApplyFixationToInvoiceForm>;
  popup = true;

  invoiceNumber: number | null = null;
  contractNumber: number | null = null;
  counterparty: string = '';

  emailOptions = FixationSendEmailOptions;

  contact?: Contact;

  constructor() {
    this.form = new TypedFormGroup<ApplyFixationToInvoiceForm>({
      shipments: new UntypedFormControl([]),
      shipmentsToFix: new UntypedFormControl([]),
      sendEmail: new UntypedFormControl(FixationSendEmailOption.DO_NOT_SEND_EMAIL),
    });
  }

  shipmentsAppliedHandler(applyShipments: ApplyUnapplyPriceFixationRequest[]) {
    this.form.patchValue({
      shipmentsToFix: applyShipments,
    });
  }

  prefillForm(data: ApplyUnapplyFixationToInvoicePrefill) {
    if (!!data) {
      this.invoiceNumber = data.invoiceInformation[0]?.voucherNumber;
      this.contractNumber = data.invoiceInformation[0]?.contractNumber;
      this.counterparty = data.invoiceInformation[0]?.counterpartyName;
      this.contact = data.counterparty;

      this.form.patchValue(data);
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): ApplyFixationToInvoiceForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    return {
      shipmentsToFix: formVal.shipmentsToFix,
      sendEmail: formVal.sendEmail,
    };
  }

  get hasPricingConfEmail(): boolean {
    return this.contact?.methods?.some((method) => method.type === ContactMethodType.PCE);
  }
}
