<vertical-field *ngIf="config" [name]="label">
  <numerictextbox-wrapper
    class="vertical-input-dropdown-with-btn"
    [ignoreReadonlyMode]="true"
    [fieldType]="fieldType"
    [format]="config"
    [decimals]="config?.maximumFractionDigits"
    [(ngModel)]="value"
    [placeholder]="label"
  >
  </numerictextbox-wrapper>
  <div class="unit-label">
    <span>{{ unitLabel }}</span>
  </div>
</vertical-field>
