import { ContractLine } from './contractLine';
import { DraftLine } from './draftLine';

export class DeliveryType {
  deliveryKey: number;
  deliveryAbbr: string;
  deliveryType: number | null;
  requiresDoubleMonth: string | null;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  elements?: ContractLine[];
  draftLines?: DraftLine[];
}
