import { EnumLabels, Subset } from '../generics';
import { City } from './city';
import { Country } from './country';

export enum ContactAddressType {
  NONE = 0,
  MAILING = 1,
  SHIPPING = 2,
  BILLING = 3,
  LOCATION = 4,
  SECONDARY_MAILING = 5,
  TERTIARY_MAILING = 6,
  LOGISTIC_DOCUMENTS = 7,
  OTHER = 99,
}

export const ContactAddressTypeNameMap: { [key in ContactAddressType]: string } = {
  [ContactAddressType.NONE]: 'None',
  [ContactAddressType.MAILING]: 'Mailing',
  [ContactAddressType.SHIPPING]: 'Shipping/Yard',
  [ContactAddressType.BILLING]: 'Billing',
  [ContactAddressType.LOCATION]: 'Legal/Main',
  [ContactAddressType.SECONDARY_MAILING]: 'Bank Docs',
  [ContactAddressType.TERTIARY_MAILING]: 'Other Mailing',
  [ContactAddressType.LOGISTIC_DOCUMENTS]: 'Logistics Documents',
  [ContactAddressType.OTHER]: 'Other',
};
export const ContactAddressTypes: EnumLabels<ContactAddressType> = [
  { value: ContactAddressType.MAILING, label: 'Mailing' },
  { value: ContactAddressType.SHIPPING, label: 'Shipping/Yard' },
  { value: ContactAddressType.BILLING, label: 'Billing' },
  { value: ContactAddressType.LOCATION, label: 'Legal/Main' },
  { value: ContactAddressType.SECONDARY_MAILING, label: 'Bank Docs' },
  { value: ContactAddressType.TERTIARY_MAILING, label: 'Other Mailing' },
  { value: ContactAddressType.LOGISTIC_DOCUMENTS, label: 'Logistic Documents' },
  { value: ContactAddressType.OTHER, label: 'Other' },
];

export class Address {
  id: number;
  contactId: number;
  address1: string;
  address2: string;
  address3: string;
  cityName: string | null;
  postalCode: string;
  stateName: string | null;
  countryKey: number;
  isArchived: string | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  latitude: number | null;
  longitude: number | null;
  cityId: number | null;
  fullAddress: string;
  contactAddressType: ContactAddressType;
  place?: City | null;
  country?: Country;
}

export type CreateAddressRequest = Subset<Address, 'fullAddress' | 'postalCode' | 'cityId' | 'contactAddressType' | 'contactId'>;
export type UpdateAddressRequest = Subset<Address, 'id', 'fullAddress' | 'postalCode' | 'cityId' | 'contactAddressType'>;
