<div class="card desktop-h-100 w-100">
  <div class="text-right">
    <button type="button" kendoButton [icon]="'refresh'" class="k-button" (click)="fetchShipments()" title="Refresh data"></button>
  </div>
  <ag-grid-angular
    class="ag-grid-container ag-theme-balham"
    [rowData]="shipmentsData"
    [gridOptions]="gridOptions"
    (cellValueChanged)="onCellValueChanged($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
