import { UntypedFormGroup } from '@angular/forms';
import { endpoints } from 'src/lib/apiEndpoints';
import { Action } from '.';
import { SET_ACTIVE_FORM, SET_FORM_ENTITY, SET_READONLY_MODE } from './actions';

export class FormState {
  protectedFormProcedureId?: endpoints | null;
  viewingProtectedForm: boolean;
  activeForm?: (() => UntypedFormGroup) | null;
  readonlyMode: boolean;
  formEntity: object | null;
}

const initialFormState: FormState = {
  protectedFormProcedureId: null,
  activeForm: null,
  viewingProtectedForm: true,
  readonlyMode: false,
  formEntity: null,
};

export function FormReducer(action: Action, state = initialFormState): FormState {
  switch (action.type) {
    case SET_ACTIVE_FORM:
      return {
        ...state,
        viewingProtectedForm: !!action.payload.activeForm,
        protectedFormProcedureId: action.payload.procedureId,
        activeForm: action.payload.activeForm,
      };
    case SET_READONLY_MODE:
      return {
        ...state,
        readonlyMode: action.payload,
      };
    case SET_FORM_ENTITY:
      return {
        ...state,
        formEntity: action.payload,
      };
    default:
      return state;
  }
}
