import { Validators } from '@angular/forms';
import { isArray } from 'lodash';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { multipleGroupByArray } from 'src/lib/helperFunctions';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { FutureContract } from 'src/lib/newBackendTypes/futureContract';
import { FutureMatching } from 'src/lib/newBackendTypes/futureMatching';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const createFutureMatchingPrefill: prefillCallback<GenerateFutureMatchingPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const matchableIds = Array.isArray(id) ? id : [id];
      const matchableItemsResponse = await api.run<ListResponse<FutureContract>>(endpoints.listFutureContracts, { filters: { id: matchableIds } }, null);
      const items = matchableItemsResponse.list;
      return { items };
    })()
  );
};

const createFutureMatchingCheck: checkPrefillCallback<GenerateFutureMatchingPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  const matchableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
  const groupBy = multipleGroupByArray(matchableItems, (property: FutureContract) => [property.counterpartyId, property.priceTermId]);
  let sumNumberOfLots = 0;
  for (let group of groupBy) {
    const matchableGroups = Array.isArray(group) ? group : [group];
    if (matchableGroups.length < 2) return of('Unable to Create Future Matching: You need at least 2 Contracts with the same Broker or Market to make the match');
    const firstEntry = matchableGroups[0];
    const promptDate = firstEntry.promptDate;
    const priceTermId = firstEntry.priceTermId;
    for (let contract of matchableGroups) {
      if (contract.promptDate !== promptDate) return of('Unable to Create Future Matching: You have different dates in the selected contracts');
      if (contract.priceTermId !== priceTermId) return of('Unable to Create Future Matching: You have different market or terms');
      sumNumberOfLots += contract.numberOfLots;
    }
  }
  if (sumNumberOfLots !== 0) return of('Unable to Create Future Matching: Group Sum of Number of Lots must be 0');
  return of(true);
};

const createFutureMatchingForm: createFormCallback<GenerateFutureMatchingPrefill, GenerateFutureMatchingRequest> = (delegate, id, prefill) => {
  return [
    { type: 'Label', text: `Are you sure you want to match the following Future Contracts?\n${prefill.items ? prefill.items.map((item) => item.contractNumber).join(', ') : 'Unknown'}` },
    {
      type: 'Date',
      field: 'valueDate',
      label: 'Value Date',
      validator: [Validators.required, bradyDateValidator()],
      startingValue: prefill.items[0].promptDate ? prefill.items[0].promptDate : prefill.items[0].priceTerm ? prefill.items[0].priceTerm.endDate : new Date(),
    },
  ];
};

const createFutureMatchingCallback: submitFormCallback<GenerateFutureMatchingPrefill, GenerateFutureMatchingRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<FutureMatching[]>((resolve, reject) => {
      (async () => {
        const matchableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
        const groupBy = multipleGroupByArray(matchableItems, (property: FutureContract) => [property.counterpartyId, property.marketId]);
        let request: GenerateFutureMatchingRequest = {
          ids: [],
          valueDate: result.valueDate,
        };
        const futureMatch: FutureMatching[] = [];
        for (let item of groupBy) {
          request.ids = item.map((item) => item.id);
          futureMatch.push(await api.run<FutureMatching>(endpoints.generateFuturMatching, request, null));
        }

        let futureMatchResponses: FutureMatching[] = [];
        for (let res of futureMatch) {
          const itemsRes = isArray(res) ? res : [res];
          itemsRes.forEach((item) => futureMatchResponses.push(item));
        }

        if (futureMatchResponses) return resolve(futureMatchResponses);
        return reject('Unknown result. Please check if the Future Matching was created and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Future Matching successfully created: \nKey(s):\n${response.map((match) => `- ${match.futurMatchKey}`).join(`\n`)}</div>`
            );
          }
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createFutureMatchingPreset: DynamicFormConstant<GenerateFutureMatchingPrefill, GenerateFutureMatchingRequest> = {
  allowMultipleRows: true,
  checkPrefill: createFutureMatchingCheck,
  createForm: createFutureMatchingForm,
  entityType: SourceEntityType.FUTURE_KEY,
  getPrefill: createFutureMatchingPrefill,
  label: 'Create Future Matching',
  submitForm: createFutureMatchingCallback,
  title: 'Create Future Matching',
  value: DynamicFormType.CREATE_FUTURE_MATCHING,
  endpoints: [endpoints.listFutureContracts, endpoints.generateFuturMatching],
  width: 600,
};

export type GenerateFutureMatchingPrefill = { items: FutureContract[]; valueDate: Date };
export type GenerateFutureMatchingRequest = { ids: number[]; valueDate: Date };
