<header class="flex-shrink-0 flex-grow-0">
  <nav class="main navbar navbar-expand navbar-dark bg-primary bg-gradient">
    <div class="container">
      <div class="navbar-brand ms-2">
        <div class="logo">Royce Corporation</div>
      </div>
    </div>
  </nav>
</header>
<div class="error-container">
  <span [ngSwitch]="error | async" class="error-main">
    <p *ngSwitchCase="0">The server is unavailable, please contact your administrator</p>
    <p *ngSwitchCase="500">The server encountered an error, please contact your administrator</p>
    <p *ngSwitchCase="404">The page you are looking for does not exist</p>
    <p *ngSwitchCase="'unauthorized'">You are not authorized to view this page</p>
    <p *ngSwitchDefault>Something went wrong</p>
  </span>
  <div class="error-sub">Error code: {{ error | async }}</div>
  <div *ngIf="requestId | async" class="error-sub">Request Id: {{ requestId | async }}</div>
  <div *ngIf="endpoint | async" class="error-sub">Endpoint: {{ endpoint | async }}</div>
  <div *ngIf="message | async" class="error-sub">Message: {{ message | async }}</div>
  <div style="margin-top: 10px"><a href="/">HOME</a></div>
</div>
