import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CellEditingStoppedEvent, ColDef, GridOptions, NewValueParams, RowNode, SelectionChangedEvent, SuppressKeyboardEventParams, ValueFormatterParams, ValueSetterParams } from 'ag-grid-community';
import { round } from 'lodash';
import Mexp from 'math-expression-evaluator';
import { Observable, Subscriber, lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { StatusBarAction, StatusBarSetting, StatusBarSettingType, StatusBarSettingsPanel } from 'src/app/shared/aggrid/statusbarsettingspanel/StatusBarSettingsPanel';
import { ValidationStatusPanel } from 'src/app/shared/aggrid/validationstatuspanel/ValidationStatusPanel';
import { ListResponse } from 'src/lib';
import {
  ContextMenuGetter,
  amountStringComparator,
  dateColumn,
  defaultComplexGrid,
  enumLabelFormatter,
  getContextMenuItems,
  gotoMenu,
  gotoMenuItem,
  gridDateFormatter,
  quantityColumn,
} from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { entityIdFormat } from 'src/lib/commonTypes';
import { Permissions } from 'src/lib/componentPermissions';
import { endpointAuthorizationSubscription, endpointsAuthorized, weightFormat } from 'src/lib/helperFunctions';
import { Document, PropertyDocument, SourceEntityType, StorageTypes, Unit, UnitInfo, YN } from 'src/lib/newBackendTypes';
import { PriceFixedTypes, PurchaseInvoiceQueueResult } from 'src/lib/newBackendTypes/purchaseInvoiceQueue';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@UntilDestroy()
@Component({
  selector: 'metal-control-purchase-invoice-queue',
  templateUrl: './purchase-invoice-queue.component.html',
  styleUrls: ['./purchase-invoice-queue.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PurchaseInvoiceQueueComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PurchaseInvoiceQueueComponent),
    },
  ],
})
@Permissions('Purchase Ticket Queue', [endpoints.purchaseTicketQueue])
export class PurchaseInvoiceQueueComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, SelectorComponent<PurchaseInvoiceQueueSelection> {
  public popup: boolean = false;
  public popupObservable: Observable<PurchaseInvoiceQueueSelection>;
  private popupSubscriber: Subscriber<PurchaseInvoiceQueueSelection>;

  /**
   * Any possible error messages or null if the grid is valid
   */
  errors: string[] = [];
  gridOptions: GridOptions;
  unitSumColDefs: ColDef[];
  currencySumColDefs: ColDef[];

  form: TypedFormGroup<ShipmentQueryForm>;
  intFormat = entityIdFormat();

  data: PurchaseInvoiceQueueResult[];
  shipmentsData: PurchaseInvoiceQueueResult[];

  units: readonly Unit[];
  mappedUnits: Map<number, Unit>;

  contractPriceUnitPrecision: number;
  authorized: endpointsAuthorized;
  documents: { [bookingId: number]: Document[] } = {};

  settings = new Map<string, StatusBarSetting | StatusBarAction>();

  @Input()
  readonly = false;

  @Input()
  preselectedShipments?: PurchaseInvoiceQueueResult[];

  @Input()
  contractQtyUnitCode?: string;

  @Input()
  contractQtyPrecision?: number;

  @Input()
  preselectedDocuments?: { [bookingId: number]: Document[] };

  @Output()
  newGrandTotal = new EventEmitter<number>();

  @Output()
  newTotalQuantity = new EventEmitter<number>();

  get changeAllLines() {
    const setting = this.settings.get('changeAllLines');
    return !setting || setting.type !== StatusBarSettingType.SETTING ? false : setting.value;
  }

  constructor(
    private api: ThalosApiService,
    private formatter: DataFormattingService,
    private commonData: CommonDataService,
    private spinnerService: SpinnerService,
    private delegate: DelegateService,
    store: Store
  ) {
    this.data = [];

    this.form = new TypedFormGroup<ShipmentQueryForm>({
      id: new UntypedFormControl(),
    });

    this.popupObservable = new Observable((subscriber) => {
      this.popupSubscriber = subscriber;
    });

    this.units = this.commonData.staticUnits.value;
    this.mappedUnits = this.units.reduce((units, unit) => units.set(unit.unitId, unit), new Map<number, Unit>());

    this.settings.set('changeAllLines', {
      type: StatusBarSettingType.SETTING,
      value: false,
      options: [
        { icon: 'link-horizontal', label: 'Change all rows', value: true },
        { icon: 'unlink-horizontal', label: 'Change single row', value: false },
      ],
    });

    endpointAuthorizationSubscription(store, this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preselectedShipments'] && !this.popup) {
      this.shipmentsData = this.preselectedShipments || [];
    }

    if (changes['contractQtyUnitCode'] && !this.popup) {
      this.contractQtyUnitCode = changes['contractQtyUnitCode'].currentValue;
    }

    if (changes['contractQtyPrecision'] && !this.popup) {
      this.contractQtyPrecision = changes['contractQtyPrecision'].currentValue;
    }

    if (changes['preselectedDocuments'] && !this.popup) {
      this.documents = this.preselectedDocuments || {};
    }
  }

  ngOnInit(): void {
    if (!this.readonly) {
      this.fetchShipments();
    }

    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'shipmentId'),
      getRowId: (params) => params.data.shipmentId,
      domLayout: 'autoHeight',
      animateRows: false,
      stopEditingWhenCellsLoseFocus: true,
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 135,
      },
      getGroupRowAgg: this.groupRowAggregator(),
      autoGroupColumnDef: {
        hide: true,
        cellStyle: { 'font-weight': 'bold' },
        width: 200,
        sort: 'asc',
        sortIndex: 1,
      },
      getRowStyle: (params) => {
        return !!params?.node?.data?.duplicateInvoicingAlert ? { background: 'rgba(255,0,0,0.15)' } : {};
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: StatusBarSettingsPanel,
            align: 'left',
            statusPanelParams: {
              settings: this.settings,
            },
          },
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          {
            statusPanel: ValidationStatusPanel,
            align: 'right',
            key: 'validationStatusPanel',
            statusPanelParams: {
              errors: this.errors,
            },
          },
        ],
      },
      columnDefs: this.columnDefinitions,
      onSelectionChanged: this.selectionChange(),
      isRowSelectable: this.isRowSelectable(),
      onColumnRowGroupChanged: (params) => params.api.expandAll(),
      getContextMenuItems: getContextMenuItems(
        gotoMenu(
          gotoMenuItem(this.delegate, 'Contract', 'contractId', SourceEntityType.CONTRACT_KEY, 'get', 'contractNumber', false),
          gotoMenuItem(this.delegate, 'Supplier', 'counterpartyId', SourceEntityType.ALF_CODE, 'get', 'counterpartyName', false),
          gotoMenuItem(this.delegate, 'Booking', 'bookingid', SourceEntityType.FREIGHT_BOOKING_KEY, 'get', 'bookingNumber', false)
        ),
        this.getUrlDocs()
      ),
    };
  }

  get columnDefinitions(): ColDef[] {
    return [
      {
        colId: 'checkbox',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        width: 50,
        cellStyle: (params) => {
          return this.readonly ? { 'pointer-events': 'none' } : null;
        },
      },
      { field: 'shipmentId', headerName: 'Shipment' },
      { field: 'duplicateInvoicingAlert', width: 200 },
      { field: 'supplierDocsRecievedDate', headerName: 'Suppl Docs Rcvd', sort: 'asc', sortIndex: 0, valueFormatter: gridDateFormatter(), width: 165 },
      { field: 'bookingNumber', headerName: 'Booking', width: 100 },
      { field: 'contractNumber', headerName: 'Contract', width: 100 },
      { field: 'counterpartyName', headerName: 'Supplier', width: 150, cellRenderer: (params) => params.value || params.data?.counterpartyName, rowGroup: true, enableRowGroup: true, hide: true },
      { field: 'purchasePriceFixed', headerName: 'Price Fixed', valueFormatter: enumLabelFormatter(PriceFixedTypes) },
      { field: 'containerNumber', headerName: 'Container' },
      {
        field: 'grossWeight',
        headerName: 'Gross',
        valueFormatter: this.formatter.gridUnitFormatter('contractQuantityUnitId'),
        width: 125,
        cellStyle: { 'text-align': 'right' },
        comparator: amountStringComparator(),
      },
      {
        field: 'netWeight',
        headerName: 'Net',
        valueFormatter: this.formatter.gridUnitFormatter('contractQuantityUnitId'),
        width: 125,
        cellStyle: { 'text-align': 'right' },
        comparator: amountStringComparator(),
      },
      {
        field: 'purchasePrice',
        headerName: 'Price',
        ...quantityColumn(),
        valueFormatter: this.formatter.gridPriceCurrencyPerUnitFormatter<PurchaseInvoiceQueueResult>('purchasePriceUnitId', 'purchasePriceCurrencyId'),
        width: 150,
        cellStyle: { 'text-align': 'right' },
        editable: (params) => params.data.purchasePriceFixed === YN.N,
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          let conversionFactor = 1;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }

          if (params.data.purchasePriceUnitId !== params.data.contractQuantityUnitId) {
            conversionFactor = this.priceUnitConversion(params.data.contractQuantityUnitId, params.data.purchasePriceUnitId, params.data.productId);
            if (!conversionFactor) return null;
          }

          params.data.purchasePrice = isNaN(value) ? params.newValue : value;
          params.data.purchaseAmount = isNaN(value) ? params.newValue * params.data.netWeight * conversionFactor : value * params.data.netWeight * conversionFactor;
          return isNaN(value) ? params.oldValue !== params.newValue : parseFloat(params.oldValue) != value;
        },
        onCellValueChanged: (event) => {
          if (!!event.newValue && typeof event.newValue === 'number') {
            let conversionFactor = 1;

            for (const row of this.changeAllLines ? this.shipmentsData : [event.data]) {
              if (row.purchasePriceFixed !== YN.N) continue;
              if (row.purchasePriceUnitId !== row.contractQuantityUnitId) {
                conversionFactor = this.priceUnitConversion(row.contractQuantityUnitId, row.purchasePriceUnitId, row.productId);
                if (!conversionFactor) return null;
              }
              row.purchasePrice = !!event.newValue ? event.newValue : null;
              row.purchaseAmount = !!event.newValue ? event.newValue * row.netWeight * conversionFactor : null;
            }
          }
          event.api.redrawRows({ rowNodes: this.changeAllLines ? undefined : [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if ((params.event.key === 'Delete' || params.event.key === 'Backspace') && !params.editing) {
            params.event.stopPropagation();
            for (const row of this.changeAllLines ? this.shipmentsData : [params.data]) {
              if (row.purchasePriceFixed !== YN.N) continue;
              row.purchasePrice = null;
              row.purchaseAmount = null;
              this.onChange(this.shipmentsData);
            }
            params.api.redrawRows({ rowNodes: this.changeAllLines ? undefined : [params.node] });
            return true;
          }
          return false;
        },
        cellClassRules: {
          'invalid-cell': (params) => !!params.data && (typeof params.data.purchasePrice !== 'number' || params.data.purchasePrice <= 0),
        },
      },
      {
        field: 'purchaseAmount',
        headerName: 'Purchase Amount',
        valueFormatter: this.formatter.gridCurrencyFormatter('purchasePriceCurrencyId'),
        width: 150,
        cellStyle: { 'text-align': 'right' },
        comparator: amountStringComparator(),
      },
      { field: 'itemName', headerName: 'Item', enableRowGroup: true, rowGroup: false, hide: false, showRowGroup: 'itemName', cellRenderer: (params) => params.value || params.data?.itemName },
      {
        field: 'originCountryName',
        headerName: 'Origin',
        enableRowGroup: true,
        rowGroup: false,
        hide: false,
        showRowGroup: 'originCountryName',
        cellRenderer: (params) => params.value || params.data?.originCountryName,
      },
      {
        field: 'destinationPlaceName',
        headerName: 'Destination',
        enableRowGroup: true,
        rowGroup: false,
        hide: false,
        showRowGroup: 'destinationPlaceName',
        cellRenderer: (params) => params.value || params.data?.destinationPlaceName,
      },
      dateColumn('domesticDeliveryDate', 'Delivery Date'),
      { field: 'productId', headerName: 'Product', valueFormatter: this.formatter.gridProductFormatter(), filter: 'agSetColumnFilter' },
      { field: 'traderName', headerName: 'Trader', filter: 'agSetColumnFilter' },
      { field: 'incotermId', headerName: 'Incoterm', valueFormatter: this.formatter.gridIncotermFormatter('loadingPlaceName') },
    ];
  }

  ngOnDestroy() {}

  onCellValueChanged(event: NewValueParams) {
    this.onDataChanged();
  }

  onDataChanged() {
    if (typeof this.onChange === 'function') {
      this.onChange(
        this.shipmentsData.map((entry): PurchaseInvoiceQueueResult => {
          return {
            shipmentId: entry.shipmentId,
            duplicateInvoicingAlert: entry.duplicateInvoicingAlert,
            supplierDocsRecievedDate: entry.supplierDocsRecievedDate,
            bookingid: entry.bookingid,
            bookingNumber: entry.bookingNumber,
            contractId: entry.contractId,
            contractNumber: entry.contractNumber,
            contractDate: entry.contractDate,
            containerNumber: entry.containerNumber,
            grossWeight: entry.grossWeight,
            grossWeightMT: entry.grossWeightMT,
            netWeight: entry.netWeight,
            netWeightMT: entry.netWeightMT,
            purchasePrice: entry.purchasePrice,
            purchaseAmount: entry.purchaseAmount,
            itemName: entry.itemName,
            originCountryName: entry.originCountryName,
            destinationPlaceName: entry.destinationPlaceName,
            domesticDeliveryDate: entry.domesticDeliveryDate,
            productId: entry.productId,
            traderName: entry.traderName,
            incotermId: entry.incotermId,
            contractProductId: entry.contractProductId,
            contractQuantityUnitId: entry.contractQuantityUnitId,
            counterpartyId: entry.counterpartyId,
            counterpartyName: entry.counterpartyName,
            loadingPlaceName: entry.loadingPlaceName,
            purchasePriceCurrencyId: entry.purchasePriceCurrencyId,
            purchasePriceFixed: entry.purchasePriceFixed,
            purchasePriceUnitId: entry.purchasePriceUnitId,
            quantityUnitId: entry.quantityUnitId,
          };
        })
      );
    }
    if (typeof this.onValidationChange === 'function') {
      this.onValidationChange();
    }
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {}

  onGridReady(event) {
    if (this.popup) {
      this.gridOptions.api.setDomLayout('normal');
      this.gridOptions!.api.setPopupParent(document.querySelector('.k-dialog'));
    }

    if (this.readonly) {
      let defs = this.gridOptions.columnDefs;
      defs.splice(0, 1);
      this.gridOptions.api.setColumnDefs(defs);
    }
  }

  convertWeights() {
    this.shipmentsData = [];

    let contractQtyUnit: Unit;
    let contractQtyFactor: UnitInfo;
    let grossWeightConverted: number;
    let netWeightConverted: number;

    for (const row of this.data) {
      if (row.contractQuantityUnitId && row.contractProductId) {
        contractQtyUnit = this.units.find((u) => u.unitId === row.contractQuantityUnitId);
        if (contractQtyUnit) {
          this.contractQtyPrecision = contractQtyUnit.precision;
          contractQtyFactor = contractQtyUnit.unitFactors.find((f) => f.productId === row.contractProductId);
        }
      }
      if (contractQtyUnit && contractQtyFactor) {
        const rowUnit = this.units.find((u) => u.unitId === row.quantityUnitId);
        let rowFactor: UnitInfo;
        if (!rowUnit) return null;
        rowFactor = rowUnit.unitFactors.find((f) => f.productId === row.productId);
        if (!rowFactor) return null;
        const finalFactor = contractQtyFactor.factor / rowFactor.factor;
        grossWeightConverted = round(row.grossWeight / finalFactor, this.contractQtyPrecision);
        netWeightConverted = round(row.netWeight / finalFactor, this.contractQtyPrecision);
      } else {
        grossWeightConverted = null;
        netWeightConverted = null;
      }

      this.shipmentsData.push({
        ...row,
        grossWeight: grossWeightConverted,
        netWeight: netWeightConverted,
      });
    }

    this.gridOptions.api?.setRowData(this.shipmentsData);
    this.gridOptions.api?.setColumnDefs(this.columnDefinitions);
  }

  priceUnitConversion(quantityUnitId: number, purchasePriceUnitId: number, productId: number) {
    const quantityUnit = this.units.find((unit) => unit.unitId === quantityUnitId);
    const quantityUnitFactor = quantityUnit ? quantityUnit.unitFactors.find((factor) => factor.productId === productId) : undefined;
    const priceUnit = this.units.find((unit) => unit.unitId === purchasePriceUnitId);
    const priceUnitFactor = priceUnit ? priceUnit.unitFactors.find((factor) => factor.productId === productId) : undefined;

    return priceUnitFactor && quantityUnitFactor ? quantityUnitFactor.factor / priceUnitFactor.factor : null;
  }

  selectionChange() {
    return (event: SelectionChangedEvent) => {
      if (!!this.popupSubscriber) {
        const shipments: PurchaseInvoiceQueueResult[] = this.gridOptions.api.getSelectedRows();
        const documents: { [bookingId: number]: Document[] } = {};
        let bookingIds = Array.from(new Set(shipments.map((s) => s.bookingid)));
        for (let id of bookingIds) {
          const d = this.documents[id];
          if (!!d) documents[id] = d;
        }
        this.popupSubscriber.next({ documents, shipments, contractQtyUnitCode: this.contractQtyUnitCode, contractQtyPrecision: this.contractQtyPrecision });
      }

      this.gridOptions.api.forEachNode((node) => {
        node.setRowSelectable(this.isRowSelectable()(node));
      });
    };
  }

  fetchShipments() {
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Shipments');
    this.api
      .rpc<ListResponse<PurchaseInvoiceQueueResult>>(endpoints.purchaseTicketQueue, { filters: {} }, { list: [], count: 0 })
      .pipe(
        switchMap(async (res) => {
          this.documents = [];
          const bookingIds = Array.from(new Set(res.list.map((shipment) => shipment.bookingid))).filter((bookingId) => !!bookingId);
          if (bookingIds.length > 0) {
            const docs = await lastValueFrom(
              this.api.rpc<ListResponse<Document>>(
                endpoints.listDocuments,
                { filters: { entityId: bookingIds, entityType: SourceEntityType.FREIGHT_BOOKING_KEY, storageType: [StorageTypes.URL, StorageTypes.FILESYSTEM_FOLDER] } },
                { list: [], count: 0 }
              )
            );
            for (let d of docs.list) {
              const link = d.documentLinks?.find((link) => bookingIds.some((b) => b === link.entityId));
              if (!!link) {
                if (!this.documents[link.entityId]) this.documents[link.entityId] = [];
                this.documents[link.entityId].push(d);
              }
            }
          }
          return res;
        })
      )
      .subscribe((res) => {
        this.spinnerService.completeRequest(rid);
        this.gridOptions.onRowDataUpdated = (event) => {
          if (this.preselectedShipments) {
            for (let shipment of this.preselectedShipments) {
              let node = event.api.getRowNode(`${shipment.shipmentId}`);
              if (!!node) {
                event.api.selectNode(node, true);
              }
            }
          }

          this.gridOptions.onRowDataUpdated = null;
        };

        this.data = res.list;
        this.convertWeights();
      });
  }

  groupRowAggregator() {
    return (params) => {
      const nodes = params.nodes;
      if (nodes.length === 0) return {};
      if (nodes[0].group) return {};

      let totalGrossWeight = 0;
      let totalNetWeight = 0;
      let purchaseAmount = 0;
      const currencyIds: number[] = Array.from(new Set(nodes.map((n) => n.data?.purchasePriceCurrencyId).filter((c) => !!c)));
      const groupedData = { [nodes[0].parent?.field]: nodes[0].parent?.key };

      const currency = currencyIds.length === 1 ? this.commonData.staticCurrencies.value.find((c) => c.id === currencyIds[0]) : null;
      for (const node of nodes) {
        const data: PurchaseInvoiceQueueResult | null | undefined = node.data;
        if (!data) continue;

        const grossWeight = data.grossWeight;
        const netWeight = data.netWeight;
        let contractQtyUnit: Unit;

        if (data.contractQuantityUnitId && data.contractProductId) {
          contractQtyUnit = this.units.find((u) => u.unitId === data.contractQuantityUnitId);
          if (contractQtyUnit) {
            this.contractQtyPrecision = contractQtyUnit.precision;
            this.contractQtyUnitCode = contractQtyUnit.code;
          }
        }

        if (!!grossWeight) totalGrossWeight += grossWeight;

        if (!!netWeight) {
          totalNetWeight += netWeight;
          this.newTotalQuantity.emit(totalNetWeight);
        }

        if (!!currency) {
          purchaseAmount += data.purchaseAmount || 0;
          this.newGrandTotal.emit(purchaseAmount);
        }
      }

      return {
        grossWeight: `${weightFormat(totalGrossWeight, this.contractQtyPrecision) + ' ' + this.contractQtyUnitCode}`,
        netWeight: `${weightFormat(totalNetWeight, this.contractQtyPrecision) + ' ' + this.contractQtyUnitCode}`,
        purchaseAmount: !!currency ? this.formatter.amountCurrencyFormatter(purchaseAmount, currency) : '',
        ...groupedData,
      };
    };
  }

  isRowSelectable() {
    return (node: RowNode) => {
      if (node.group) return false;
      if (node.isSelected()) return true;
      if (!node.data) return false;
      if (this.readonly) return false;
      let selected: PurchaseInvoiceQueueResult[] = this.gridOptions.api.getSelectedRows();
      let selectedContracts = new Set(selected.map((qr) => qr.contractId).filter((c) => !!c));
      if (selectedContracts.size > 1) return false;
      if (selectedContracts.size === 0) return true;
      return selectedContracts.values().next().value === node.data.contractId;
    };
  }

  unitsValueFormatter(params: ValueFormatterParams<number>) {
    if (params && params.value) {
      const unit = this.mappedUnits.get(params.value);
      if (unit) return unit.code;
      return '??';
    }
    return null;
  }

  preselectItems(items: PurchaseInvoiceQueueSelection) {
    this.preselectedShipments = items.shipments;
    this.contractQtyUnitCode = items.contractQtyUnitCode;
    this.contractQtyPrecision = items.contractQtyPrecision;
    this.documents = items.documents || {};
  }

  getUrlDocs(): ContextMenuGetter {
    return (params) => {
      const row: PurchaseInvoiceQueueResult = params.node?.data;
      if (!row) return [];

      const docs = this.documents[row.bookingid];
      if (!docs || docs.length === 0) return [];

      return {
        name: 'Open Linked Folder',
        subMenu: docs.flatMap((d) => {
          if (d.storageType === StorageTypes.FILESYSTEM_FOLDER && !/Win/.test(navigator.platform)) return [];
          return {
            name: d.fileName || d.fsPath,
            action: () => {
              if (d.storageType === StorageTypes.URL) {
                window.open(d.fsPath);
              } else if (d.storageType === StorageTypes.FILESYSTEM_FOLDER) {
                let baseUrl = d.fsPath.replace(/\\/g, '/');

                let finalUrl = `linkeddox:${baseUrl}`;
                window.open(finalUrl);
              }
            },
          };
        }),
      };
    };
  }

  validate(control: AbstractControl): ValidationErrors {
    this.errors.splice(0, this.errors.length);
    if (!(control.value instanceof Array)) {
      this.errors.push('Invalid line values');
    } else {
      for (const line of control.value as PurchaseInvoiceQueueResult[]) {
        if (!line.purchasePrice || typeof line.purchasePrice !== 'number' || line.purchasePrice <= 0) {
          this.errors.push(`Missing Price on shipment ${line.shipmentId}`);
        }
      }
    }
    return !!this.errors && this.errors.length > 0 ? { custom: this.errors[0] } : null;
  }

  onChange = (entries: PurchaseInvoiceQueueResult[]) => {};
  onValidationChange = () => {};
  onTouched = () => {};

  registerOnChange(onChange: (entries: PurchaseInvoiceQueueResult[]) => any): void {
    this.onChange = onChange;
  }

  registerOnValidatorChange(onValidationChange: () => any): void {
    this.onValidationChange = onValidationChange;
  }

  registerOnTouched(onTouched: () => any): void {
    this.onTouched = onTouched;
  }

  writeValue(entries: PurchaseInvoiceQueueResult[]): void {
    this.shipmentsData = entries;
  }
}

export type ShipmentQueryForm = Pick<PropertyDocument, 'id'>;

export type PurchaseInvoiceQueueSelection = {
  shipments: PurchaseInvoiceQueueResult[];
  contractQtyUnitCode: string;
  contractQtyPrecision: number;
  documents?: { [bookingId: number]: Document[] };
};
