import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { BudgetElement } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getBudgetElementListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<BudgetElement> = {
    where: { name: { contains: new VariableType('name') } },
    orderBy: [{ name: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getBudgetElementListVariablesGraphql() {
  const variables: GraphqlVariables<BudgetElement> = { name: 'String' };
  return variables;
}

export const listBudgetElementGraphqlRequest = (args: GraphqlRequest<BudgetElement>): GraphqlArgsRequest<BudgetElement> => {
  return {
    __aliasFor: ExistingGraphql.budgetElements,
    __args: args,
    id: true,
    name: true,
  };
};
