import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, GridReadyEvent, KeyCode, RowClickedEvent, SuppressKeyboardEventParams } from 'ag-grid-community';
import { map } from 'rxjs';
import { GraphqlService } from 'src/app/core/services/graphql.service';
import { ListFilterService } from 'src/app/core/services/list-filter.service';
import { DropdownConfig } from 'src/lib';
import { GraphqlDropdownConfig } from 'src/lib/graphql/GraphqlDropdownConfig';

@Component({
  selector: 'auto-complete-editor',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: `position: absolute;
					left: 0px;
					top: 0px;
					overflow: visible;
					`,
  },
  template: `
    <input
      #input
      [(ngModel)]="inputValue"
      (ngModelChange)="processDataInput($event)"
      style=" height: 28px; font-weight: 400; font-size: 12px;"
      [style.width]="(params.minWidth || params.column.actualWidth) + 'px'"
    />
    <ag-grid-angular
      style="font-weight: 150;"
      [style.height]="gridHeight + 'px'"
      [style.max-width]="gridWidth + 'px'"
      class="ag-theme-balham"
      [rowData]="rowData"
      [gridOptions]="gridOptions"
      [rowSelection]="rowSelection"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      (gridReady)="onGridReady($event)"
      (rowClicked)="rowClicked($event)"
    >
    </ag-grid-angular>
  `,
})
export class AutoCompleteEditor<T = any> implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  public gridOptions: GridOptions;
  // variables for agGrid
  public params: any;
  public gridApi: GridApi;
  public rowData: Array<any> = [];
  public columnDefs: Array<ColDef>;
  public rowSelection: string = 'single';
  public overlayNoRowsTemplate: string;
  // variables for component
  public returnObject: boolean;
  public clearInputValue: boolean;
  public cellValue: string;
  public filteredRowData: any;
  public inputValue: string;
  public useApi: boolean = false;
  public useGraphqlApi: boolean = false;
  public queryChars: number = 2;
  public gridHeight: number = 175;
  public gridWidth: number = 375;
  public propertyName: string;
  public isCanceled: boolean = true;
  public selectedObject: any = {};
  public asyncListConfig: DropdownConfig<T> | null;
  public asyncGraphqlListConfig: T extends Array<infer U> ? GraphqlDropdownConfig<U> : GraphqlDropdownConfig<T> | null;

  @ViewChild('input') input: ElementRef;

  constructor(private listFilterService: ListFilterService, private changeDetection: ChangeDetectorRef, private graphqlService: GraphqlService) {
    this.gridOptions = {
      columnDefs: [],
      defaultColDef: {
        cellStyle: { wordBreak: 'normal' },
        wrapText: true,
        wrapHeaderText: true,
        autoHeight: true,
        autoHeaderHeight: true,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (this.gridApi && params.event.key === 'ArrowUp') {
            const focusedCell = this.gridApi.getFocusedCell();
            if (focusedCell && focusedCell.rowIndex === 0) {
              window.setTimeout(() => {
                this.input.nativeElement.focus();
              });
              return true;
            }
          }
          return false;
        },
      },
    };
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.inputValue == this.cellValue ? this.input.nativeElement.select() : this.input.nativeElement.focus();
      if (this.inputValue && (!this.useApi || !this.useGraphqlApi)) this.updateFilter();
    });
  }

  // ICellEditorAngularComp functions
  agInit(params: any): void {
    this.params = params;
    if (!!params.asyncListConfig) {
      this.asyncListConfig = params.asyncListConfig;
      if (typeof this.asyncListConfig === 'function') {
        this.asyncListConfig = params.asyncListConfig(this.params.node.data);
      }
      this.useApi = true;
    } else if (!!params.asyncGraphqlListConfig) {
      this.asyncGraphqlListConfig = params.asyncGraphqlListConfig;
      if (typeof this.asyncGraphqlListConfig === 'function') {
        this.asyncGraphqlListConfig = params.asyncGraphqQlListConfig(this.params.node.data);
      }
      this.useGraphqlApi = true;
    } else {
      this.rowData = Array.isArray(params.rowData) ? params.rowData : typeof params.rowData === 'function' ? params.rowData(params) : [];
    }
    if (params.gridHeight) this.gridHeight = params.gridHeight;
    if (params.gridWidth) this.gridWidth = params.gridWidth;

    if (params.queryChars > -1) this.queryChars = params.queryChars;
    this.columnDefs = params.columnDefs;
    this.gridOptions.columnDefs = this.columnDefs;
    this.propertyName = params.propertyRendered;
    this.returnObject = params.returnObject;
    this.clearInputValue = params.clearInputValue || params.keyPress === KeyCode.DELETE || params.keyPress === KeyCode.BACKSPACE;
    this.cellValue = params.propertyRendered == '' || params.returnObject == false || params.value == null ? '' : params.value[this.propertyName];

    if (this.queryChars == 0) {
      this.overlayNoRowsTemplate = this.overLayLoading();
    } else {
      this.overlayNoRowsTemplate = this.overLayMinimumCharacters();
    }

    if (!params.charPress) {
      if (this.cellValue != null && !this.clearInputValue) this.inputValue = this.cellValue;
    } else {
      this.inputValue = params.charPress;
    }

    if (this.useApi == true && (this.queryChars == 0 || (this.inputValue != null && this.inputValue != '' && this.inputValue.toString().length > this.queryChars))) {
      this.getApiData(this.inputValue).subscribe((data) => {
        this.rowData = data as Array<ColDef>;
        this.changeDetection.detectChanges();
        window.setTimeout(() => {
          this.updateFilter();
        });
      });
    } else if (this.useGraphqlApi == true && (this.queryChars == 0 || (this.inputValue != null && this.inputValue != '' && this.inputValue.length > this.queryChars))) {
      this.getGraphQlApiData(this.inputValue).then((data: any) => {
        this.rowData = data as Array<ColDef>;
        this.changeDetection.detectChanges();
        window.setTimeout(() => {
          this.updateFilter();
        });
      });
    } else {
      this.changeDetection.detectChanges();
    }
  }

  ngOnDestroy() {
    this.changeDetection.detach();
  }

  getValue(): any {
    if (!this.returnObject) return this.selectedObject[this.propertyName];
    return this.selectedObject;
  }
  isPopup(): boolean {
    return true;
  }
  isCancelAfterEnd(): boolean {
    return this.isCanceled;
  }

  // ag-Grid functions
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.sizeColumnsToFit();
  }

  // component functions
  rowClicked(event: RowClickedEvent): void {
    this.selectedObject = event.data;
    this.isCanceled = false;
    this.params.api.stopEditing();
  }

  rowConfirmed(): void {
    if (this.gridApi.getSelectedRows()[0]) {
      this.selectedObject = this.gridApi.getSelectedRows()[0];
      this.isCanceled = false;
    }
    this.params.api.stopEditing();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key == 'Escape') {
      this.params.api.stopEditing();
      return false;
    }
    if (event.key == 'Enter' || event.key == 'Tab') {
      this.rowConfirmed();
      return false;
    }
    if (event.key == 'ArrowUp' && document.activeElement !== this.input.nativeElement) {
      this.navigateGrid();
      return false;
    }
    if (event.key == 'ArrowDown') {
      this.navigateGrid();
      return false;
    }
  }

  processDataInput(inputValue: string): void {
    if (this.useApi === true) {
      if (inputValue.length < this.queryChars) {
        this.gridApi.setRowData([]);
      } else {
        this.getApiData(inputValue).subscribe((data) => {
          this.rowData = data as Array<ColDef>;
          this.changeDetection.detectChanges();
          window.setTimeout(() => {
            this.updateFilter();
          });
        });
      }
    } else if (this.useGraphqlApi === true) {
      if (inputValue.length < this.queryChars) {
        this.gridApi.setRowData([]);
      } else {
        this.getGraphQlApiData(inputValue).then((data: any) => {
          this.rowData = data as Array<ColDef>;
          this.changeDetection.detectChanges();
          window.setTimeout(() => {
            this.updateFilter();
          });
        });
      }
    } else {
      this.updateFilter();
    }
  }

  getApiData(filter: string) {
    return this.listFilterService.handleFilter(filter, this.asyncListConfig).pipe(map((listResult) => listResult.list));
  }

  getGraphQlApiData(filter: string) {
    return this.graphqlService.handleFilter(filter, this.asyncGraphqlListConfig);
  }

  updateFilter(): void {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(this.inputValue.toString());

      if (this.gridApi.getDisplayedRowAtIndex(0)) {
        this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
        this.gridApi.ensureIndexVisible(0, 'top');
      } else {
        this.gridApi.deselectAll();
      }
    }
  }

  navigateGrid(): void {
    if (this.gridApi.getFocusedCell() == null || this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex) == null) {
      // check if no cell has focus, or if focused cell is filtered
      const firstRow = this.gridApi.getDisplayedRowAtIndex(0);
      if (firstRow) {
        this.gridApi.setFocusedCell(this.gridApi.getDisplayedRowAtIndex(0).rowIndex, this.columnDefs[0].field);
        this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
      }
    } else {
      this.gridApi.setFocusedCell(this.gridApi.getFocusedCell().rowIndex, this.columnDefs[0].field);
      this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
    }
  }

  overLayLoading(): string {
    return '<span class="ag-overlay-no-rows-center">No rows to be shown. <br> Loading...</span>';
  }
  overLayMinimumCharacters(): string {
    return `<span class="ag-overlay-no-rows-center">No rows to be shown. <br> This search field requires at least ${this.queryChars} characters.</span>`;
  }
}
