import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { ThalosApiService } from './thalos-api.service';

@UntilDestroy()
@Injectable()
export class ValidatorService {
  constructor(private api: ThalosApiService) {}

  public uniqueNameValidator<T>(listProcedure: endpoints, field: keyof T, primaryKey?: keyof T, existingId?: any): (AbstractControl) => Observable<ValidationErrors> {
    return (control: AbstractControl) => {
      const value: T | null = control.value;
      if (!value) return of(null);
      return this.api.rpc<ListResponse<T>>(listProcedure, { filters: { [field]: value } }, { count: 0, list: [] }).pipe(
        map((res) => res.list),
        map((res) => res.filter((entity) => !existingId || !primaryKey || entity[primaryKey] !== existingId)),
        map((res) => (!!res.length ? { custom: 'This name already exists' } : null))
      );
    };
  }
}
