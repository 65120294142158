import { EnumLabels, Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { Contact } from './contact';
import { YesNo, YN } from './enums';

export enum InfoKeys {
  INVESTMENT_GRADE = 5697177,
  SWIFT = 635716,
  ABA = 20138967,
  FINAL_INVOICE_REQD = 19951788,
  ADMIN_FEE_CHARGE = 19952851,
  PAYMENT_FREQUENCY = 16047996,
  ASSIGNED_TRADER = 2913763,
  FIX_AFTER_BL = 22533865,
  FIX_AFTER_ATA = 22534146,
  FIX_BEFORE_ETA = 22534444,
  FIX_AFTER_DLVRY = 22536797,
  FIX_BEFORE_BL = 22537126,
  PMT_AFTER_DLVRY = 23434069,
  REQUIRES_1099 = 23502700,
  // FIX_AVERAGE_PREV_MONTH = 26958871,
  // FIX_AVERAGE_CURR_MONTH = 26958872,
  // FIX_AVERAGE_NEXT_MONTH = 26958873,
  // FIX_AVERAGE_CUSTOM = 26958874,
  FIX_AFTER_MATCH = 26958875,
  /**
   * @todo get real number
   */
  TAX_NUMBER = -1,
  /**
   * @todo get real number
   */
  FIXATION_RULE = -2,
  /**
   * @todo get real number
   */
  FIXATION_DAYS = -3,
}

export enum PaymentFrequency {
  AS_INSTRUCTED = 16047990,
  DUE_DATE = 16047989,
  DAILY = 16047991,
  WEEKLY = 16047992,
  BIWEEKLY = 16062902,
  MONTHLY = 16047994,
  UPON_RECEIPT = 16047993,
  TBD = 16048384,
}

export const PaymentFrequencies = enumOptionsFromEnum(PaymentFrequency);

export function isPaymentFrequency(dl: DropListValues): dl is PaymentFrequency {
  return dl === null || Object.values(PaymentFrequency).includes(dl as PaymentFrequency);
}

export enum YesNoListValues {
  NO = 14220619,
  YES = 14220620,
}

export function YesNoDLFromYN(yn: YN) {
  return yn === YN.Y ? YesNoListValues.YES : YesNoListValues.NO;
}
export function YNFromYesNoDL(dl: DropListValues) {
  return dl === YesNoListValues.YES ? YN.Y : YN.N;
}

export enum FixationRule {
  FIX_AFTER_BL = 22533865,
  FIX_AFTER_ATA = 22534146,
  FIX_BEFORE_ETA = 22534444,
  FIX_AFTER_DLVRY = 22536797,
  FIX_BEFORE_BL = 22537126,
  // FIX_AVERAGE_PREV_MONTH = 26958871,
  // FIX_AVERAGE_CURR_MONTH = 26958872,
  // FIX_AVERAGE_NEXT_MONTH = 26958873,
  // FIX_AVERAGE_CUSTOM = 26958874,
  FIX_AFTER_MATCH = 26958875,
  NONE = 1,
}

export const FixationRules = enumOptionsFromEnum(FixationRule);

export function isFixationRule(dl: DropListValues): dl is FixationRule {
  return dl === null || Object.values(FixationRule).includes(dl as FixationRule);
}

export const _FixationRules: EnumLabels<FixationRule> = [
  { value: FixationRule.FIX_AFTER_BL, label: 'Fix After BL' },
  { value: FixationRule.FIX_AFTER_ATA, label: 'Fix After ATA' },
  { value: FixationRule.FIX_BEFORE_ETA, label: 'Fix Before ETA' },
  { value: FixationRule.FIX_AFTER_DLVRY, label: 'Fix After Delivery' },
  { value: FixationRule.FIX_BEFORE_BL, label: 'Fix Before BL' },
  // { value: FixationRule.FIX_AVERAGE_PREV_MONTH, label: 'Fix Average M-1' },
  // { value: FixationRule.FIX_AVERAGE_CURR_MONTH, label: 'Fix Average M' },
  // { value: FixationRule.FIX_AVERAGE_NEXT_MONTH, label: 'Fix Average M+1' },
  // { value: FixationRule.FIX_AVERAGE_CUSTOM, label: 'Fix Average Custom' },
  { value: FixationRule.FIX_AFTER_MATCH, label: 'Fix After Match' },
  { value: FixationRule.NONE, label: 'None' },
];

export type DropListValues = PaymentFrequency | YesNoListValues | FixationRule;

export type ContactMetadata = {
  contactId: number;
  lineNumber: number;
  infoKey: InfoKeys;
  iVal: number | null;
  rVal: number | null;
  tVal: string | null;
  dropListItemKey: DropListValues | null;
  alf?: Contact;
};

export function getPaymentFrequencyLabel(paymentFrequency: PaymentFrequency) {
  switch (paymentFrequency) {
    case PaymentFrequency.AS_INSTRUCTED:
      return 'As Instructed';
    case PaymentFrequency.DUE_DATE:
      return 'Due Date';
    case PaymentFrequency.DAILY:
      return 'Daily';
    case PaymentFrequency.WEEKLY:
      return 'Weekly';
    case PaymentFrequency.BIWEEKLY:
      return 'Bi-weekly';
    case PaymentFrequency.MONTHLY:
      return 'Monthly';
    case PaymentFrequency.UPON_RECEIPT:
      return 'Upon Receipt';
    case PaymentFrequency.TBD:
      return 'TBD';
    default:
      return '';
  }
}

export function getYesNoValue(yesNo: YesNo) {
  return yesNo === YesNo.YES ? YesNoListValues.YES : yesNo === YesNo.NO ? YesNoListValues.NO : 0;
}

export type CreateContactMetadataRequest = Subset<ContactMetadata, 'contactId' | 'infoKey', 'dropListItemKey' | 'iVal' | 'rVal' | 'tVal'>;

export type UpdateContactMetadataRequest = Subset<ContactMetadata, 'contactId' | 'infoKey', 'dropListItemKey' | 'iVal' | 'rVal' | 'tVal'>;
export type SyncContactMetadataRequest = {
  contactId: number;
  metadata: (Omit<CreateContactMetadataRequest, 'contactId'> | Omit<UpdateContactMetadataRequest, 'contactId'>)[];
};
