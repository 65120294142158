import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { EntityLayout } from 'src/lib/views';
import { SpinnerService } from '../services/spinner.service';
import { ThalosApiService } from '../services/thalos-api.service';

@Injectable()
export class EntityLayoutsResolver implements Resolve<any> {
  constructor(private api: ThalosApiService, private spinnerService: SpinnerService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data.listView) return [];

    let rId = this.spinnerService.startRequest(randomFetchSynonym() + ' Data');
    const layouts = await this.api.run<ListResponse<EntityLayout>>(
      endpoints.listEntityLayouts,
      { filters: { listId: route.data.listView }, orderBy: { fieldName: 'name', order: 'ASC' } },
      { count: 0, list: [] }
    );
    this.spinnerService.completeRequest(rId);

    let res = [];
    if (layouts && layouts.list) res = layouts.list;

    return res;
  }
}
