import { ReportAutomationDefinition } from './automatedReports';
import { Contact } from './contact';
import { ExchangeContract } from './exchangeContract';
import { GlAccountLeaves } from './glAccount';
import { PaymentHeader } from './payment';

export class FiscalCompany {
  companyId: number;
  currKey: number;
  multiCurrencies: string | null;
  vatNumber: string | null;
  registrationId: string | null;
  freeText: string | null;
  clientDefaultGroupKey: number | null;
  supplierDefaultGroupKey: number | null;
  bvrClientNumber: string | null;
  bvrAdherentNumber: string | null;
  anlEntry: number | null;
  cpnStructDefKey: number | null;
  fcTplKey: number | null;
  linkedCompany: number | null;
  isGl: string | null;
  fcUseSaAccount: string | null;
  fcUseSaCombo: string | null;
  fcUseSaNbLines: number | null;
  fcUseAccLongIdenInvhd: string | null;
  fcUseAccLongIdenInvld: string | null;
  fcUseAccLongIdenInaod: string | null;
  fcUseAccLongIdenInvhc: string | null;
  fcUseAccLongIdenInvlc: string | null;
  fcUseAccLongIdenInaoc: string | null;
  fcUseAccLongIdenCnhd: string | null;
  fcUseAccLongIdenCnld: string | null;
  fcUseAccLongIdenCnaod: string | null;
  fcUseAccLongIdenCnhc: string | null;
  fcUseAccLongIdenCnlc: string | null;
  fcUseAccLongIdenCnaoc: string | null;
  fcUseAccLongIdenPyhd: string | null;
  fcUseAccLongIdenPyld: string | null;
  fcUseAccLongIdenPyhc: string | null;
  fcUseAccLongIdenPylc: string | null;
  fcUseAccLongIdenJe: string | null;
  fcUseExtRefForConso: string | null;
  fcUseExtRefForAuxAcc: string | null;
  fcUseNormalRateCapture: string | null;
  fcUseUnitPriceInvd: number | null;
  fcUseUnitPriceInvc: number | null;
  fcUseUnitPriceCnc: number | null;
  fcUseUnitPriceCnd: number | null;
  fcUseTreasuryAbbrInPe: string | null;
  fcCopyExtRefPayLines: string | null;
  dtaExpId: string | null;
  fcUseHolding: string | null;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  lastOperationType: number | null;
  fcDisplayQuantity: string | null;
  consoDate: number | null;
  poContactGroupKey: number | null;
  isRepo: string | null;
  repoCompanyId: number | null;
  permDateOperations: Date;
  permDateAccounting: Date;
  permDateNetPosition: Date;
  contact?: Contact;
  exchangeContracts?: ExchangeContract[];
  glAccounts?: GlAccountLeaves[];
  paymentHeaders?: PaymentHeader[];
  reports?: ReportAutomationDefinition[];
  resultsAccount?: GlAccountLeaves | null;
}
