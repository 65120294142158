import { map, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { LogisticsQueueData, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const bookingFinderPrefill: prefillCallback<LogisticsQueueData> = (delegate, id) => {
  let api = delegate.getService('api');

  return api.rpc<ListResponse<LogisticsQueueData>>(endpoints.bookingQueue, { filters: { id } }, { list: [], count: 0 }).pipe(
    map((res) => {
      if (res.list.length !== 1) {
        return null;
      } else {
        return res.list[0];
      }
    })
  );
};

const trackCarrierBookingSubmit: submitFormCallback<LogisticsQueueData> = (delegate, id, booking) => {
  const dialog = delegate.getService('dialog');

  if (booking === null) {
    dialog.open({
      title: 'Error',
      content: 'Booking not found',
    });

    return of(null);
  } else if (!booking.carrierTrackingUrl) {
    dialog.open({
      title: 'No Carrier Url',
      content: 'Booking does not have carrier booking reference',
    });

    return of(null);
  } else {
    const url = booking.carrierTrackingUrl.replace(/{{CARRIER_BOOKING}}/g, booking.carrierBookingNumber);
    if (url !== booking.carrierTrackingUrl) {
      window.open(url);
      return of(null);
    } else {
      const permissionName = 'clipboard-write' as PermissionName;
      return of(
        navigator.permissions.query({ name: permissionName }).then((result) => {
          if (result.state == 'granted' || result.state == 'prompt') {
            navigator.clipboard.writeText(booking.carrierBookingNumber).then(() => {
              window.open(url);
            }),
              () => {
                window.open(url);
              };
          } else {
            window.open(url);
          }
        })
      );
    }
  }
};

const trackOriginalsToBanking: submitFormCallback<LogisticsQueueData> = (delegate, id, booking) => {
  const dialog = delegate.getService('dialog');

  if (booking === null) {
    dialog.open({
      title: 'Error',
      content: 'Booking not found',
    });

    return of(null);
  } else if (!booking.docsToBankTracking) {
    dialog.open({
      title: 'No Tracking Ref',
      content: 'Booking does not have a Documents to Bank tracking Ref.',
    });

    return of(null);
  } else {
    const url = `https://www.fedex.com/fedextrack/?trknbr=` + booking.docsToBankTracking.trim();
    const permissionName = 'clipboard-write' as PermissionName;
    return of(
      navigator.permissions.query({ name: permissionName }).then((result) => {
        if (result.state == 'granted' || result.state == 'prompt') {
          navigator.clipboard.writeText(booking.docsToBankTracking).then(() => {
            window.open(url);
          }),
            () => {
              window.open(url);
            };
        } else {
          window.open(url);
        }
      })
    );
  }
};

const trackOriginalsToCustomer: submitFormCallback<LogisticsQueueData> = (delegate, id, booking) => {
  const dialog = delegate.getService('dialog');

  if (booking === null) {
    dialog.open({
      title: 'Error',
      content: 'Booking not found',
    });

    return of(null);
  } else if (!booking.docsToCustomerTracking) {
    dialog.open({
      title: 'No Tracking Ref',
      content: 'Booking does not have a Documents to Customer tracking Ref.',
    });

    return of(null);
  } else {
    const url = `https://www.fedex.com/fedextrack/?trknbr=` + booking.docsToCustomerTracking.trim();
    const permissionName = 'clipboard-write' as PermissionName;
    return of(
      navigator.permissions.query({ name: permissionName }).then((result) => {
        if (result.state == 'granted' || result.state == 'prompt') {
          navigator.clipboard.writeText(booking.docsToCustomerTracking).then(() => {
            window.open(url);
          }),
            () => {
              window.open(url);
            };
        } else {
          window.open(url);
        }
      })
    );
  }
};

export const carrierTrackingPreset: DynamicFormConstant<LogisticsQueueData> = {
  value: DynamicFormType.CARRIER_TRACKING,
  label: 'Track Carrier Booking',
  endpoints: [endpoints.bookingQueue],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  title: 'Track Booking',
  width: 0,
  getPrefill: bookingFinderPrefill,
  submitForm: trackCarrierBookingSubmit,
};

export const bankingTrackingPreset: DynamicFormConstant<LogisticsQueueData> = {
  value: DynamicFormType.BANKING_TRACKING,
  label: 'Track Originals to Bank',
  endpoints: [endpoints.bookingQueue],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  title: 'Track Originals to Bank',
  width: 0,
  getPrefill: bookingFinderPrefill,
  submitForm: trackOriginalsToBanking,
};

export const customerTrackingPreset: DynamicFormConstant<LogisticsQueueData> = {
  value: DynamicFormType.CUSTOMER_TRACKING,
  label: 'Track Originals to Client',
  endpoints: [endpoints.bookingQueue],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  title: 'Track Originals to Client',
  width: 0,
  getPrefill: bookingFinderPrefill,
  submitForm: trackOriginalsToCustomer,
};
