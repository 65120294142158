import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html',
  styleUrls: ['./checkbox-wrapper.component.scss'],
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => CheckboxWrapperComponent) }],
})
export class CheckboxWrapperComponent extends FormElementComponent {
  @ViewChild('checkbox', { static: false })
  checkbox: ElementRef;

  @Input()
  valueMask?: { true: any; false: any };

  @Input()
  label?: string;

  setValue(val) {
    if (this.valueMask) {
      val = val === this.valueMask.true ? true : val === this.valueMask.false ? false : val;
    }
    this._value = val;
  }

  getValue() {
    if (this.valueMask) {
      return this._value === true ? this.valueMask.true : this._value === false ? this.valueMask.false : this._value;
    }
    return this._value;
  }

  id: string;
  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this.id = `checkbox-${CheckboxWrapperComponent.idCounter++}`;
  }

  ngOnInit() {
    //if value has already been assigned to form control when value mask is initialized, we need to set the value again
    this.value = this._value;
  }

  static idCounter = 0;

  public focus() {
    if (this.checkbox) {
      setTimeout(() => {
        this.checkbox.nativeElement.focus();
      });
    }
  }
}
