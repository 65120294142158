<div style="height: 100%; display: flex; flex-direction: column" class="microsoft-file-browser">
  <kendo-treelist
    kendoTreeListExpandable
    [data]="filteredData"
    [fetchChildren]="fetchChildren"
    [hasChildren]="hasChildren"
    kendoTreeListSelectable
    [selectable]="selectionSettings"
    [isSelected]="isSelected"
    [rowClass]="rowCallback"
    (selectionChange)="selectionChanged($event)"
    style="flex-grow: 1; max-height: calc(100% - 80px)"
  >
    <kendo-treelist-checkbox-column [width]="35" [showSelectAll]="false"> </kendo-treelist-checkbox-column>
    <kendo-treelist-column [expandable]="true" title="Name" [width]="250" [field]="'name'">
      <ng-template kendoTreeListCellTemplate let-dataItem let-isExpanded="isExpanded">
        <span style="cursor: pointer" (dblclick)="itemDoubleClicked(dataItem)">
          <i [ngClass]="itemClass(dataItem, isExpanded)" style="font-size: 16px"></i>
          {{ dataItem.name }}
        </span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Size" [width]="250">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ dataItem.size | filesize }}
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Created" [width]="250">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span *ngIf="dataItem.createdDateTime">{{ dataItem.createdDateTime | date : 'short' }}</span>
        <span *ngIf="dataItem.createdBy"> by {{ dataItem.createdBy.user?.displayName }} </span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Last Modified" [width]="250">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span *ngIf="dataItem.lastModifiedDateTime">{{ dataItem.lastModifiedDateTime | date : 'short' }}</span>
        <span *ngIf="dataItem.lastModifiedBy"> by {{ dataItem.lastModifiedBy.user?.displayName }} </span>
      </ng-template>
    </kendo-treelist-column>
  </kendo-treelist>
  <div class="total-footer">
    <div *ngIf="loadMoreLink">
      Showing {{ rootData.length }} items out of ?
      <span style="cursor: pointer; font-weight: bold" (click)="clickLoadMore()">...Load More</span>
    </div>
    <div *ngIf="!loadMoreLink">Showing all {{ rootData.length }} items</div>
    <div *ngIf="selectedFolder" class="right-justified">
      <button kendoButton type="button" (click)="createFolder()">Create Folder</button>
    </div>
  </div>
</div>
