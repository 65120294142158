import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EntityContainer } from 'src/lib/EntityContainer';
import { Store } from '../services/store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StoreSubscription } from 'src/lib';
import { SET_TIMEDOUT } from '../reducers/actions';

@UntilDestroy()
@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<EntityContainer<any, any, any>>, OnDestroy {
  timedOut: boolean;

  timedOutSub: StoreSubscription<boolean>;

  constructor(private store: Store) {
    this.timedOutSub = this.store.subscribe((state) => state.layout.timedOut, [SET_TIMEDOUT]);
    this.timedOutSub.$.pipe(untilDestroyed(this)).subscribe((timedOut) => {
      this.timedOut = timedOut;
    });
  }

  canDeactivate(component: EntityContainer<any, any, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (component.popup) return false;
    if (!this.timedOut) return component.close();
    else return true;
  }

  ngOnDestroy() {
    this.timedOutSub.unsubscribe();
  }
}
