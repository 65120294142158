import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { filter, map } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { BudgetElement, BudgetElementGroup, CommonBudgetElementGroups } from 'src/lib/newBackendTypes/budgetElement';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'budget-category-dropdown',
  templateUrl: './budget-category-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => BudgetElementDropdownComponent) }],
})
export class BudgetElementDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  private dropdown: ComboBoxComponent;

  @Input()
  type: CommonBudgetElementGroups;

  filteredData: BudgetElement[];
  budgetElements: BudgetElement[];

  constructor(private api: ThalosApiService, controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this.filteredData = [];
  }

  ngOnInit(): void {
    let filters: Partial<BudgetElementGroup> = {};
    if (this.type) filters.id = this.type;

    this.api
      .rpc<BudgetElementGroup>(endpoints.getBudgetElementGroup, { filters }, null)
      .pipe(
        filter((group) => !!group),
        map((group) => group.budgetElements)
      )
      .subscribe((elements) => {
        this.budgetElements = elements;
        this.filteredData = elements;
      });
  }

  handleFilter(text: string) {
    if (typeof text === 'string') this.filteredData = this.budgetElements.filter((e) => e.name.toLowerCase().includes(text.toLowerCase()));
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  comparator(a: BudgetElement, b: BudgetElement) {
    return a?.id === b?.id;
  }
}
