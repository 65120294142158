import { Injectable } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { FilePreviewComponent } from 'src/app/shared/file-preview/file-preview.component';
import { FileUploadService } from './file-upload.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class FilePreviewService {
  constructor(private dialogService: DialogService, private downloadService: FileUploadService, private spinnerService: SpinnerService) {}

  openFile(file: File) {
    var type = file.type;
    if (type.match(/image\/*/) === null && type.match(/pdf\/*/) === null) {
      this.dialogService.open({
        title: 'Cannot open file',
        content: 'This is not a supported file type.  Cannot preview.',
        actions: [
          {
            themeColor: 'primary',
            text: 'Close',
          },
        ],
      });

      return;
    }
    let d = this.dialogService.open({
      title: 'Preview - ' + file.name,
      content: FilePreviewComponent,
      minWidth: 400,
      width: window.innerWidth - 40,
      height: window.innerHeight - 40,
    });

    let component: FilePreviewComponent = d.content.instance;
    if (component) {
      component.file = file;
    }
  }

  //TODO: THIS MAY NOT STAY A DOCUMENTID
  openDocument(documentId: number, fileName: string) {
    let fileSegments = fileName.split('.');
    let mimeType: string;
    if (fileSegments.length > 1) {
      let extension = fileSegments[fileSegments.length - 1];

      if (extension === 'pdf') {
        mimeType = 'application/pdf';
      } else if (extension === 'jpeg' || extension === 'png' || extension === 'jpg') {
        mimeType = 'image/' + extension;
      } else {
        this.dialogService.open({
          title: 'Cannot open file',
          content: 'This is not a supported file type.  Cannot preview.',
          actions: [
            {
              themeColor: 'primary',
              text: 'Close',
            },
          ],
        });
        return;
      }
    }

    let fid = this.spinnerService.startRequest('Opening File');
    this.downloadService.download(documentId).subscribe((data) => {
      this.spinnerService.completeRequest(fid);
      let blob = new Blob([data]);
      let options: FilePropertyBag = {};
      if (!!mimeType) {
        options.type = mimeType;
      }
      let file = new File([blob], fileName, options);

      let d = this.dialogService.open({
        title: 'Preview - ' + fileName,
        content: FilePreviewComponent,
        minWidth: 400,
        width: window.innerWidth - 40,
        height: window.innerHeight - 40,
      });

      let component: FilePreviewComponent = d.content.instance;
      if (component) {
        component.file = file;
      }
    });
  }
}
