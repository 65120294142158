<ag-grid-angular style="width: 100%; height: 300px; margin-bottom: 10px" class="ag-theme-balham" [rowData]="data" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"> </ag-grid-angular>
<form [formGroup]="ticketForm" class="container form-container">
  <div class="row">
    <div class="col-md-6">
      <horizontal-field [name]="'Type'">
        <simple-dropdown [formControlName]="'source'" [data]="weightTicketSourceOptions" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </horizontal-field>
      <horizontal-field [name]="'Shipment'">
        <simple-dropdown cyData="shipment" [formControlName]="'shipment'" [data]="shipments" [valueField]="'id'" [textField]="'label'" [secondaryTextField]="'id'"> </simple-dropdown>
      </horizontal-field>
      <horizontal-field [name]="'Gross Weight'">
        <numerictextbox-wrapper [formControlName]="'grossWeight'" [fieldType]="3" [min]="1" [format]="numberFormat" [decimals]="3"> </numerictextbox-wrapper>
      </horizontal-field>
      <horizontal-field [name]="'Net Weight'">
        <numerictextbox-wrapper [formControlName]="'netWeight'" [fieldType]="3" [min]="1" [format]="numberFormat" [decimals]="3"> </numerictextbox-wrapper>
      </horizontal-field>
    </div>
    <div class="col-md-6">
      <horizontal-field [name]="'Date'">
        <datepicker-wrapper cyData="Date" [formControlName]="'date'"> </datepicker-wrapper>
      </horizontal-field>
      <horizontal-field [name]="'Unit'">
        <dropdown-wrapper cyData="unit" [formControlName]="'unit'" [dropdownConfig]="unitDropdown"> </dropdown-wrapper>
      </horizontal-field>
      <horizontal-field [name]="'Location'">
        <dropdown-graphql cyData="location" [formControlName]="'place'" [dropdownConfig]="locationDropdown"> </dropdown-graphql>
      </horizontal-field>
    </div>
  </div>
  <div style="margin-bottom: 10px; margin-top: 10px; display: flex; flex-direction: row-reverse">
    <button type="submit" kendoButton (click)="createTicket()" style="width: 100px">Add</button>
  </div>
</form>
