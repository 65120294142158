import { Subset } from '../generics';
import { Currency } from './currency';
import { FutureMarketTerm } from './futureMarketTerm';
import { MarketHoliday } from './marketHoliday';
import { Product } from './product';
import { Unit, UnitInfo } from './unit';

export enum MarketType {
  DELIVERY_MONTH = 1,
  DAILY_PROMPT = 2,
  PREMIUM = 5,
  FOREX = 3,
  INTEREST = 4,
}

export enum FutureMarkets {
  NONE = 0,
  LME_AHD = 100112,
  LME_CAD = 100113,
  COMEX_COOPER = 100146,
  LME_PBD = 114575,
  AH_MIDWEST_PLATTS = 120117,
  LME_ZSD = 125766,
  LME_NID = 125767,
  LME_SND = 140931,
  NON_HEDGE_USD_MT = 216963,
  NON_HEDGE_USD_LB = 471152,
  LME_NAD = 7669988,
  NON_HEDGE_EUR_MT = 10343493,
  NON_HEDGE_GBP_MT = 10343494,
  MWTI = 10650662,
}

export type FutureMarket = {
  marketId: number;
  currencyKey: number;
  productId: number;
  unitKey: number;
  name: string;
  lotSize: number;
  brokerKey: number | null;
  currencyFactor: number | null;
  includeInLongShort: string;
  marketType: MarketType;
  lmeNumberOfMonths: number | null;
  roundingNumber: number;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  versionNb: number | null;
  tolerance: number | null;
  priceTerm?: FutureMarketTerm[];
  unitHeader?: Unit;
  unitInfo?: UnitInfo;
  currencyInfo?: Currency;
  product?: Product;
  holidays?: MarketHoliday[];
};
export type CreateFutureMarketRequest = Subset<FutureMarket, 'name' | 'lotSize' | 'unitKey' | 'currencyKey' | 'productId' | 'marketType' | 'roundingNumber' | 'includeInLongShort'>;
export type UpdateFutureMarketRequest = Pick<FutureMarket, 'marketId'> & Partial<CreateFutureMarketRequest>;
