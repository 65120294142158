<div style="padding: 0" [ngStyle]="{ border: formControl?.invalid && formControl?.touched ? 'solid 1px red' : 'none' }">
  <kendo-multiselect
    #multiselect
    kendoMultiSelectSummaryTag
    textField="label"
    valueField="uid"
    [formControl]="multiselectFc"
    [data]="options"
    [filterable]="true"
    [autoClose]="false"
    [placeholder]="placeholder"
    [valuePrimitive]="false"
    [tagMapper]="tagMapper()"
    (filterChange)="filterChange($event)"
    (valueChange)="valueChange($event)"
    (open)="valueChange($event)"
  >
    <ng-template kendoMultiSelectHeaderTemplate let-dataItem>
      <span class="hint">{{ hint }}</span>
    </ng-template>
    <ng-template kendoMultiSelectNoDataTemplate></ng-template>
    <ng-template kendoMultiSelectTagTemplate let-dataItem>
      {{ dataItem.label }}
    </ng-template>
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
      <ng-container *ngFor="let dataItem of dataItems">
        <ng-container [ngSwitch]="dataItem.type">
          <ng-container *ngSwitchCase="'+'">+ </ng-container>
          <ng-container *ngSwitchCase="'-'">- </ng-container>
          <ng-container *ngSwitchCase="'*'">* </ng-container>
          <ng-container *ngSwitchCase="'currency'"> {{ dataItem.label }}</ng-container>
          <ng-container *ngSwitchCase="'unit'"> / {{ dataItem.label }}</ng-container>
          <ng-container *ngSwitchDefault>{{ dataItem.label }}</ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-multiselect>
</div>
