import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuEvent, MenuItem } from '@progress/kendo-angular-menu';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { DocumentVersion } from 'src/lib/newBackendTypes/document';

@Component({
  selector: 'document-version-menu',
  templateUrl: './document-version-menu.component.html',
})
export class DocumentVersionMenuComponent implements OnInit {
  @Input()
  id: string;

  @Output()
  downloadVersion: EventEmitter<any> = new EventEmitter();

  data$: BehaviorSubject<any>;

  constructor(private api: ThalosApiService) {
    this.data$ = new BehaviorSubject([]);
  }

  ngOnInit(): void {}

  onOpen = (event) => {
    this.api.rpc<DocumentVersion[]>(endpoints.listDocumentVersions, { id: this.id }, []).subscribe((res) => {
      if (!!res) {
        if (res.length === 0)
          this.data$.next([
            {
              text: 'No versions found, file may have been deleted',
              data: null,
            },
          ]);
        else
          this.data$.next(
            res
              .sort((v1, v2) => {
                return new Date(v2.versionDate).getTime() - new Date(v1.versionDate).getTime();
              })
              .map((version: DocumentVersion, index: number) => {
                let text = moment(version.versionDate).fromNow();
                if (version.version) text = `${text} (v ${version.version})`;

                let menuItem: MenuItem = {
                  text,
                  data: { ...version, id: this.id },
                };
                return menuItem;
              })
          );
      }
    });
  };

  selectVersion(event: ContextMenuEvent) {
    if (event && event.item && event.item.data) {
      this.downloadVersion.emit(event.item.data);
    }
  }
}
