import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { NetPositionLines, SourceEntityType } from 'src/lib/newBackendTypes';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { Validators } from '@angular/forms';

const netPositionEntryUpdatePrefill: prefillCallback<NetPositionLines> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const netPositionLine = await api.run<NetPositionLines>(endpoints.getNetPositionLine, { filters: { id } });
      return netPositionLine;
    })()
  );
};

const netPositionEntryUpdateCheck: checkPrefillCallback<NetPositionLines> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to update Net Position Line: Net Position line not found');
  if (!prefill.netPositionHeader) return of('Unable to update Net Position Line: Net Position header not found');
  return of(true);
};

const netPositionEntryUpdateForm: createFormCallback<NetPositionLines, NetPositionEntryUpdatePrefill> = (delegate, id, prefill) => {
  return [
    _fe('mup', 'Metal Unit Percentage', { minimumFractionDigits: 0, maximumFractionDigits: 3, useGrouping: false, fieldType: 4 }, prefill.metalUnitPercentage, Validators.required),
    {
      type: 'TextArea',
      field: 'comments',
      label: 'Comment',
      startingValue: prefill.comments ?? '',
    },
  ];
};

const netPositionEntryUpdateCallback: submitFormCallback<NetPositionLines, NetPositionEntryUpdateRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<NetPositionLines>((resolve, reject) => {
      (async () => {
        const request: NetPositionEntryUpdateRequest = {
          id,
          mup: result.mup,
          companyId: prefill.netPositionHeader.companyId,
          comments: result.comments,
        };
        const netPositionEntry = await api.run<NetPositionLines>(endpoints.updateNPLineProperties, request, null);
        if (netPositionEntry) return resolve(netPositionEntry);
        return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Net Position Line ${res.id} successfuly updated.</div>`);
      })
      .catch((err) => {
        if (err) return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if line was updated and try again if necessary.</div>`);
      })
  );
};

export const netPositionEntryUpdatePreset: DynamicFormConstant<NetPositionLines, NetPositionEntryUpdateRequest> = {
  allowMultipleRows: false,
  checkPrefill: netPositionEntryUpdateCheck,
  getPrefill: netPositionEntryUpdatePrefill,
  createForm: netPositionEntryUpdateForm,
  entityType: SourceEntityType.NET_POSITION_ENTRIES_KEY,
  label: 'Update Net Position Entry',
  submitForm: netPositionEntryUpdateCallback,
  title: 'Update Net Position Entry',
  value: DynamicFormType.UPDATE_NET_POSITION_ENTRY,
  endpoints: [endpoints.updateNPLineProperties],
  width: 600,
};

export type NetPositionEntryUpdateRequest = { id: number; mup: number; companyId: number; comments: string };
export type NetPositionEntryUpdatePrefill = { mup: number; companyId: number; comments: string };
