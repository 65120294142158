import { Item } from './item';
import { SteelType } from './steelType';

export class SteelArticle {
  articleNumber: number;
  steelTypeKey: number | null;
  qualityName: string | null;
  steelText1: string | null;
  steelText2: string | null;
  steelText3: string | null;
  steelText4: string | null;
  steelText5: string | null;
  steelText6: string | null;
  steelFloat1: number | null;
  steelFloat2: number | null;
  steelFloat3: number | null;
  steelFloat4: number | null;
  steelFloat5: number | null;
  steelFloat6: number | null;
  steelFloat7: number | null;
  steelFloat8: number | null;
  steelFloat9: number | null;
  steelMtLinearMeter: number | null;
  steelMtPiece: number | null;
  sizes: string | null;
  length: string | null;
  weight: string | null;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  articleText?: Item;
  steelType?: SteelType;
}
