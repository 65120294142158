<kendo-combobox
  [readonly]="readonly"
  #dropdown
  [data]="filteredData"
  [filterable]="true"
  [valueField]="'id'"
  (filterChange)="handleFilter($event)"
  [ngClass]="{ readonly: readonly }"
  [textField]="'name'"
  [placeholder]="placeholder"
  [(ngModel)]="_value"
  [suggest]="true"
  (blur)="onBlur()"
  (valueChange)="onValueChange(value)"
>
</kendo-combobox>
