<form [formGroup]="formGroup" autocomplete="off" class="container">
  <input id="username" style="display: none" type="text" name="fakeusernameremembered" />
  <input id="password" style="display: none" type="password" name="fakepasswordremembered" />
  <div class="card">
    <div class="card-header d-flex">
      <h4 class="card-header__entity flex-grow-1">
        <i *ngIf="icon" [ngClass]="icon"></i>
        <span> {{ !entityId && entityId !== 0 ? 'New' : '' }} {{ title }}</span>
        <span *ngIf="!!entityId || entityId === 0"> {{ entityId }}</span>
        <span *ngIf="formGroup?.dirty && formGroup?.touched">*</span>
      </h4>

      <div
        *ngIf="usersConnected && usersConnected.length > 0"
        class="d-flex usersConnected-container"
        kendoTooltip
        filter="div.user-content"
        [tooltipTemplate]="templateUserFullnameTooltip"
        position="bottom"
      >
        <ng-template #templateUserFullnameTooltip let-anchor>
          {{ anchor.nativeElement.getAttribute('data-fullname') }}
        </ng-template>

        <div *ngFor="let user of usersConnected" class="user-container">
          <div class="user-content" [attr.data-fullname]="user.fullname" [ngStyle]="{ 'background-color': user.color }">
            <span class="user-initials">{{ user.initials }}</span>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="headerContents"></ng-container>
      <div *ngIf="!!sourceEntityType || !!altFeature" class="arrows-container">
        <div *ngIf="!!headerContents" class="content-space" style="height: 100%; width: 16px; display: block"></div>
        <div class="button-container button__notification" *ngIf="notificationsAuthorized">
          <button kendoButton type="button" [ngClass]="{ 'k-button-solid-success': subscribed }" style="min-width: 34px" (click)="clickNotificationSubscription()">
            <i [ngClass]="!subscribed ? notificationDeactivatedIcon : notificationActivatedIcon"></i>
          </button>
        </div>

        <div *ngIf="previousEntity" class="button-container button__previous">
          <button
            kendoButton
            type="button"
            entityLink
            [entityId]="previousEntity.id"
            [entityType]="sourceEntityType"
            id="entity-left-button"
            [altFeature]="altFeature"
            class="arrow-right-button"
            (nextOrPreviusEntityNavigate)="onNextOrPreviusEntityNavigate($event)"
          >
            <i class="k-icon k-i-arrow-left"></i>
            <span>{{ previousEntity.label }}</span>
          </button>
        </div>
        <div *ngIf="!previousEntity">
          <button kendoButton type="button" class="arrow-right-button" [disabled]="true">
            <i class="k-icon k-i-arrow-left"></i>
          </button>
        </div>
        <div *ngIf="!nextEntity">
          <button kendoButton type="button" class="arrow-right-button" [disabled]="true">
            <i class="k-icon k-i-arrow-right"></i>
          </button>
        </div>
        <div *ngIf="nextEntity" class="button-container button__next">
          <button
            kendoButton
            type="button"
            entityLink
            [entityId]="nextEntity.id"
            [entityType]="sourceEntityType"
            id="entity-right-button"
            [altFeature]="altFeature"
            class="arrow-left-button"
            (nextOrPreviusEntityNavigate)="onNextOrPreviusEntityNavigate($event)"
          >
            <span>{{ nextEntity.label }}</span>
            <i class="k-icon k-i-arrow-right"></i>
          </button>
        </div>
      </div>
      <div style="margin-left: 10px" class="button-close">
        <button tabindex="-1" kendoButton [icon]="'close'" type="button" class="k-button" id="entity-close-button" (click)="clickClose()"></button>
      </div>
    </div>
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </div>
</form>
