import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { employeeDropdown } from 'src/lib/commonTypes';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Contact, YN } from 'src/lib/newBackendTypes';
import { Task } from 'src/lib/newBackendTypes/task';
import { toLocalDate } from 'src/lib/toUTCDate';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit, ModalFormComponent<TaskForm> {
  popup = true;
  form: TypedFormGroup<TaskForm>;
  employeeDropdown = employeeDropdown();

  createdByLabel: string;
  updatedByLabel: string;

  taskPriorities = [
    { label: '1 - Highest', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5 - Normal', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9 - Lowest', value: 9 },
  ];

  constructor() {
    this.form = new TypedFormGroup<TaskForm>({
      accomplished: new UntypedFormControl(YN.N),
      dueDate: new UntypedFormControl(),
      id: new UntypedFormControl(),
      taskTitle: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
      taskText: new UntypedFormControl(''),
      assignees: new UntypedFormControl([]),
      taskPriority: new UntypedFormControl(5, Validators.required),
      creator: new UntypedFormControl(null),
    });
  }

  ngOnInit(): void {}

  prefillForm(val: Partial<Task>) {
    this.form.patchValue(val);

    if (val.updator && val.updateDate) {
      this.updatedByLabel = `Updated by ${val.updator.displayName} at ${toLocalDate(val.updateDate).toLocaleDateString()}`;
    }

    if (val.creator && val.creationDate) {
      this.createdByLabel = `Created by ${val.creator.displayName} at ${toLocalDate(val.creationDate).toLocaleDateString()}`;
    }
  }

  submit() {
    return this.form.value;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid && !this.form.pending;
  }
}

export type TaskForm = Pick<Task, 'accomplished' | 'id' | 'dueDate' | 'taskTitle' | 'taskText' | 'taskPriority' | 'creator'> & {
  assignees: Contact[];
};
