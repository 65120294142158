import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { bradyDateValidator, conditionalValidators, minValidator } from 'src/lib/genericValidators';
import { Subset } from 'src/lib/generics';
import { dollarAmountFormat, formatDate, fromBradyDate, markFormGroupTouched } from 'src/lib/helperFunctions';
import { Currency, YN } from 'src/lib/newBackendTypes';
import { Advance } from 'src/lib/newBackendTypes/advance';
import { DueDateType, dueDateTypeLabels } from 'src/lib/newBackendTypes/advancePayments';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'accounting-create-advance',
  templateUrl: './create-advance.component.html',
})
export class CreateAdvanceComponent implements ModalFormComponent<AdvanceForm, AdvancePrefill> {
  form: TypedFormGroup<AdvanceForm>;
  popup = true;

  paymentTermName: string;
  price: string;
  currencyCode: string;
  dueDateTypeLabels = dueDateTypeLabels;

  isContractDate: boolean;
  isLoadingETD: boolean;
  contractDate: number;
  contractDateFormatted: string;
  dueDateContractDate: Date;
  dueDateManual: Date;

  amountFormat: NumberFormatOptions;

  constructor(private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<AdvanceForm>({
      valueDate: new UntypedFormControl(null, [Validators.required, bradyDateValidator()]),
      dueDateType: new UntypedFormControl(DueDateType.CONTRACT_DATE, Validators.required),
      dueDate: new UntypedFormControl(null),
      automaticAmount: new UntypedFormControl(YN.Y),
      amount: new UntypedFormControl(null),
      overrideAmount: new UntypedFormControl(null),
    });
  }

  prefillForm(data: AdvancePrefill) {
    if (!!data) {
      this.paymentTermName = data.paymentTermName;
      this.price = dollarAmountFormat(data.price);
      this.currencyCode = data.currency.code;
      this.contractDate = data.contractDate;
      this.amountFormat = this.formatter.getAmountFormat(this.currencyCode);

      const dueDateType = data.dueDateType ?? this.form.get('dueDateType').value;
      const automaticAmount = data.automaticAmount ?? this.form.get('automaticAmount').value;

      if (dueDateType === DueDateType.MANUAL) this.dueDateManual = data.dueDate;

      this.form.patchValue(data);
      this.setContractDate();
      this.dueDateValidators(dueDateType);
      this.requestedAmountValidators(automaticAmount);
    }
  }

  setContractDate() {
    const contractDate = fromBradyDate(this.contractDate);
    const dateSum = contractDate.setDate(contractDate.getDate() + 5);
    this.dueDateContractDate = new Date(dateSum);
    this.contractDateFormatted = formatDate(fromBradyDate(this.contractDate), true);
  }

  dueDateValidators(value: DueDateType) {
    this.isContractDate = value === DueDateType.CONTRACT_DATE;
    this.isLoadingETD = value === DueDateType.LOADING_ETD;
    if (this.isContractDate) this.form.patchValue({ dueDate: this.dueDateContractDate });
    if (!this.isContractDate && !this.isLoadingETD && this.dueDateManual) this.form.patchValue({ dueDate: this.dueDateManual });

    this.form.get('dueDate').setValidators(conditionalValidators(() => !this.isContractDate && !this.isLoadingETD, Validators.required, bradyDateValidator()));
    this.form.get('dueDate').updateValueAndValidity();
  }

  requestedAmountValidators(value: YN) {
    const isMandatory = value === YN.Y;

    this.form.get('overrideAmount').setValidators(conditionalValidators(() => !isMandatory, Validators.required, minValidator(0, false)));
    this.form.get('overrideAmount').updateValueAndValidity();
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): AdvanceForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    const dueDate = !this.isLoadingETD ? formVal.dueDate : null;

    return {
      valueDate: formVal.valueDate,
      dueDateType: formVal.dueDateType,
      dueDate,
      automaticAmount: formVal.automaticAmount,
      amount: formVal.automaticAmount === YN.Y ? formVal.amount : formVal.overrideAmount < 0 ? -formVal.overrideAmount : formVal.overrideAmount,
    };
  }
}

export type AdvanceForm = Pick<Advance, 'valueDate' | 'dueDate' | 'dueDateType'> & {
  amount: number;
  automaticAmount: YN;
  overrideAmount?: number;
};
export type AdvancePrefill = Subset<AdvanceForm, 'valueDate' | 'amount', 'dueDateType' | 'dueDate' | 'automaticAmount'> & {
  paymentTermName: string;
  currency: Currency;
  price: number;
  contractDate: number;
};
