<kendo-combobox
  #dropdown
  [data]="activeValuations | async"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [filterable]="true"
  [suggest]="true"
  [ngClass]="{ readonly: readonly }"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  (open)="onOpen()"
  [textField]="'valuationName'"
  [valueField]="'valuationId'"
  [valuePrimitive]="true"
>
</kendo-combobox>
