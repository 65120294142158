import { Subset } from '../generics';
import { Contact } from './contact';
import { YN } from './enums';

export class Task {
  id: number;
  taskTitle: string | null;
  taskText: string | null;
  taskPriority: number | null;
  accomplished: YN | null;
  accomplishedBy: number | null;
  accomplishedDate: Date | null;
  startDate: Date | null;
  dueDate: Date | null;
  sourceId: number | null;
  sourceKey1: number | null;
  sourceKey2: number | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  sourceClientId: number | null;
  taskAssignees?: TasksAssignee[];
  readableName?: string;
  creator?: Contact;
  updator?: Contact;
  sourceClient: Contact | null;
}

export type CreateTaskRequest = Subset<Task, 'taskTitle' | 'taskText' | 'sourceId' | 'sourceKey1' | 'taskPriority', 'sourceClientId' | 'dueDate'> & {
  assignees: number[];
};

export type UpdateTaskRequest = Subset<Task, 'id', 'taskTitle' | 'taskText' | 'sourceId' | 'sourceKey1' | 'sourceClientId' | 'accomplished' | 'dueDate' | 'taskAssignees' | 'taskPriority'> & {
  assignees?: number[];
};

export class TasksAssignee {
  assigneeKey: number;
  taskKey: number;
  assigneeAlfCode: number;
  task?: Task;
  assignee?: Contact;
}
