import { AuthorizedCurrency } from './authorizedCurrency';
import { PhysicalContractExpense } from './contractExpense';
import { BaseDiscrepancy } from './discrepancy';
import { PhysicalDraft } from './draft';
import { PhysicalDraftExpense } from './draftExpense';
import { FutureMarket } from './futureMarket';
import { PricePrecision } from './pricePrecision';
import { ServiceOrder } from './serviceOrder';

export type Currency = {
  id: number;
  name?: string;
  code: string;
  currAbbrIso: string;
  currPrecision: number;
  lastOperationType: number | null;
  clientPrecision: number | null;
  supplierPrecision: number | null;
  inEuro: string | null;
  euroFactor: number | null;
  mainCurrKey: number | null;
  mainFactor: number | null;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  reutersInstrumentCode: string | null;
  symbol: string | null;
  currAmountPrecision: number;
  futureMarket?: FutureMarket[];
  serviceOrder?: ServiceOrder[];
  supplierDiscrepanciesAsCurrency?: BaseDiscrepancy[];
  customerDiscrepanciesAsCurrency?: BaseDiscrepancy[];
  contractExpenses?: PhysicalContractExpense[];
  draftExpenses?: PhysicalDraftExpense[];
  drafts?: PhysicalDraft[];
  draftsAsPremiumCurrency?: PhysicalDraft[];
  authorizedCurrencies?: AuthorizedCurrency[];
  pricePrecisions?: PricePrecision[];
};

export enum CommonCurrencies {
  CHF = 1,
  EUR = 2,
  USD = 3,
  GBP = 4,
  USC = 5,
  MXN = 101964,
  HKD = 130348,
  CAD = 11500744,
  SGD = 24904694,
  BRL = 25710050,
}
