import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { isDate } from 'moment';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { DropdownConfig } from 'src/lib';
import {
  DynamicListListFilterConfiguration,
  isDynamicListFilterConfiguration,
  isPromptFilterConfiguration,
  ListColumn,
  ListComparativeOperator,
  ListComparativeOperators,
  ListFilterAbstractPromptRow,
  ListFilterAbstractRow,
  ListFilterType,
  ListFilterValueType,
  PromptListFilterConfiguration,
} from 'src/lib/views';
import { formatDate, markFormGroupTouched } from 'src/lib/helperFunctions';
import { YN } from 'src/lib/newBackendTypes';

@Component({
  selector: 'flex-filter-popup',
  templateUrl: './flex-filter-popup.component.html',
  styleUrls: ['./flex-filter-popup.component.scss'],
})
export class FlexFilterPopupComponent implements OnInit, ModalFormComponent<{ obj: any; filters: string }> {
  popup = true;

  universalNumberFormat: NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
    useGrouping: false,
  };

  @Input()
  set flexPrompts(value: ListFilterAbstractPromptRow[]) {
    this._flexPrompts = value.flatMap((p) => {
      if (p.type === ListFilterType.PROMPT_USER && isPromptFilterConfiguration(p.typeConfiguration)) return { ...p, fieldLabel: this.getPromptLabel(p as promptRow) };
      else if (p.type === ListFilterType.DYNAMIC_LIST && isDynamicListFilterConfiguration(p.typeConfiguration)) {
        return {
          ...p,
          config: new DropdownConfig({
            listProcedure: p.typeConfiguration.endpoint,
            labelField: p.typeConfiguration.labelKey,
            valueField: p.typeConfiguration.valueKey,
          }),
          fieldLabel: this.getPromptLabel(p as dynamicRow),
        };
      } else return [];
    });
  }

  @Input()
  columns: ListColumn[];

  _flexPrompts: ((promptRow | (dynamicRow & { config: DropdownConfig<any> })) & {
    formControl?: UntypedFormControl;
    fieldLabel: string;
  })[];

  form: UntypedFormGroup;

  constructor() {
    this.form = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    //Build forms

    if (!this.columns || !this._flexPrompts) return;

    for (let prompt of this._flexPrompts) {
      let field = this.columns.find((c) => c.id === prompt.fieldId);
      if (!field) return;

      let control = new UntypedFormControl();
      prompt.formControl = control;

      if (prompt.typeConfiguration.mandatory === YN.Y) {
        control.setValidators(Validators.required);
      }

      if (prompt.type === ListFilterType.PROMPT_USER && prompt.typeConfiguration.valueType === ListFilterValueType.DATE) {
      }

      this.form.addControl(prompt.uuid, control);
    }
  }

  prefillForm() {}

  allowSubmit() {
    if (!this.form || !this.columns || !this._flexPrompts) return false;
    markFormGroupTouched(this.form);

    return this.form.valid;
  }

  submit() {
    const obj: any = {};

    const val = this.form.value;
    // Array to store filter values for display
    const filtersValues: string[] = [];

    for (const k in val) {
      const v = val[k];
      // If the value is not an object, store it directly in the object and add to filter values array
      if (typeof v !== 'object') {
        obj[k] = v;
        filtersValues.push(`${v}`);
      } else if (isDate(v)) {
        // If the value is a date, format it and add to filter values array
        obj[k] = v;
        filtersValues.push(formatDate(v, true));
      } else {
        // If the value is an object, process it based on its type and configuration
        const filter = this._flexPrompts.find((f) => {
          return f.uuid === k;
        });
        // Skip if the filter is not found or if it's not a dynamic list filter
        if (!filter || filter.type !== ListFilterType.DYNAMIC_LIST) continue;

        // Find the corresponding column for the filter
        const col = this.columns.find((c) => {
          return c.id === filter.fieldId;
        });
        if (!col) continue;

        const typeConfig = filter.typeConfiguration;

        // Process filter values based on the operator and type configuration
        if (typeConfig?.operator === ListComparativeOperator.IN || (typeConfig?.operator === ListComparativeOperator.NOT_IN && Array.isArray(v))) {
          obj[k] = (v || []).map((v) => v[typeConfig.valueKey]).filter((v) => !!v || v === 0);
          filtersValues.push(...(v || []).map((v) => `(${v[typeConfig.labelKey]})`).filter((v) => !!v || v === 0));
        } else {
          obj[k] = v[typeConfig.valueKey];
          filtersValues.push(`${v[typeConfig.labelKey]}`);
        }
      }
    }
    // Concatenate filter values into a single string
    const filtersValuesConcat = filtersValues.join(' - ');
    // Return processed form values and concatenated filter values
    return { obj, filters: filtersValuesConcat };
  }

  getPromptLabel(prompt: promptRow | dynamicRow) {
    let operatorTag = ListComparativeOperators.find((o) => prompt.typeConfiguration.operator === o.value);

    return `${prompt.label}${prompt.typeConfiguration.mandatory ? '*' : ''} ${operatorTag?.label || ''}`;
  }
}

type promptRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.PROMPT_USER;
  typeConfiguration: PromptListFilterConfiguration;
};

type dynamicRow = Omit<ListFilterAbstractRow, 'type' | 'typeConfiguration'> & {
  type: ListFilterType.DYNAMIC_LIST;
  typeConfiguration: DynamicListListFilterConfiguration;
};
