import { EnumLabels } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { SourceEntityType, YN } from '../newBackendTypes';
import { DynamicFormType } from '../flex/forms/types';
import { QueryFilters } from '../agGridFunctions';
import { EditableCallbackParams, IAggFuncParams, ValueGetterParams } from 'ag-grid-community';

export type ListColumnResponse = {
  id?: string;
  order?: number;
  flexViewId?: number;
  name: string;
  field: string;
  sort?: 'asc' | 'desc' | null;
  type: ListColumnType;
  allowGrouping?: YN;
  defaultWidth: number;
  defaultOrder: number;
  typeConfiguration?: string;
  menuConfiguration?: string;
  visibility?: ListColumnVisibility;
};

export type BaseListColumn = Omit<ListColumnResponse, 'typeConfiguration' | 'type' | 'menuConfiguration'> & {
  menuConfiguration?: ListColumnMenuConfig;
};

export type AnyListColumn = Omit<ListColumnResponse, 'typeConfiguration' | 'menuConfig'> & {
  typeConfiguration: ListColumnTypeConfig;
  menuConfiguration?: ListColumnMenuConfig;
};

export enum ListColumnType {
  TEXT = 1,
  NUMBER = 2,
  DATE = 3,
  ENUM = 4,
  WEIGHT = 5,
  AMOUNT = 6,
  AMOUNT_UNIT = 7,
  ID = 8,
  DEBIT_CREDIT = 9,
  PRICE_UNIT = 10,
  COMPANY = 11,
}
export const ListColumnTypes = enumOptionsFromEnum(ListColumnType);

export type BaseTypeConfig = {
  allowAggregation?: YN;
  customAggregation?: (params: IAggFuncParams) => any;
  editable?: (params: EditableCallbackParams) => boolean;
  valueGetter?: (params: ValueGetterParams) => any;
};

export enum ListColumnVisibility {
  ALWAYS_HIDE = 1,
  HIDE = 2,
  SHOW = 3,
  ALWAYS_SHOW = 4,
}
export const ListColumnVisibilities = enumOptionsFromEnum(ListColumnVisibility);

export type TextColumnConfig = BaseTypeConfig & {
  commaJoin?: YN;
};

export function isTextColumnConfig(column: ListColumnTypeConfig): column is TextColumnConfig {
  return true;
}

export type Condition = 'equal' | 'greaterThan' | 'lessThan' | 'equalOrGreaterThan' | 'equalOrLessThan';

export type CustomStyle = {
  condition: Condition;
  value?: number;
  color?: string;
  background?: string;
};

export type NumberColumnConfig = BaseTypeConfig & {
  decimals: number;
  showUnit?: YN | string;
  useGrouping?: YN;
  percentage?: YN;
  customStyle?: CustomStyle[];
};

export function isNumberColumnConfig(column: ListColumnTypeConfig): column is NumberColumnConfig {
  let decimals = (column as NumberColumnConfig).decimals;
  let useGrouping = (column as NumberColumnConfig).useGrouping;
  return (!decimals || typeof decimals === 'number') && typeof useGrouping === 'string';
}

export type DateColumnConfig = BaseTypeConfig & {
  numeric?: YN;
};

export function isDateColumnConfig(column: ListColumnTypeConfig): column is DateColumnConfig {
  return true;
}

export type EnumColumnConfig = BaseTypeConfig & {
  enumValues: EnumLabels<any>;
  useGrouping?: YN;
};

export function isEnumColumnConfig(column: ListColumnTypeConfig): column is EnumColumnConfig {
  let enumValues = (column as EnumColumnConfig).enumValues;
  return Array.isArray(enumValues) && (enumValues.length === 0 || (!!enumValues[0].value && !!enumValues[0].label));
}

export type WeightColumnConfig = NumberColumnConfig &
  (
    | {
        unitColumnField: string;
      }
    | {
        staticUnit: string | number;
      }
    | {}
  );

export function isWeightColumnConfig(column: ListColumnTypeConfig): column is WeightColumnConfig {
  return isNumberColumnConfig(column);
}

export type AmountColumnConfig = NumberColumnConfig &
  (
    | {
        currencyColumnField: string;
      }
    | {
        staticCurrency: string | number;
      }
    | {}
  );

export function isAmountColumnConfig(column: ListColumnTypeConfig): column is AmountColumnConfig {
  return isNumberColumnConfig(column);
}

export type AmountUnitColumnConfig = WeightColumnConfig & AmountColumnConfig;

export function isAmountUnitColumnConfig(column: ListColumnTypeConfig): column is AmountUnitColumnConfig {
  return isAmountColumnConfig(column) && isWeightColumnConfig(column);
}

export type IDColumnConfig = BaseTypeConfig & {
  entityType: SourceEntityType;
  entityLabelColumnField?: string;
  dynamicForms?: DynamicFormType[];
  primaryId?: YN;
  filterForm?: FilteredForm[];
  customLabelName?: string;
};

export type FilteredForm = {
  filter: QueryFilters<any>;
  scope: DynamicFormType;
};

export function isIDColumnConfig(column: ListColumnTypeConfig): column is IDColumnConfig {
  return true;
}

export type DebitCreditColumnConfig = NumberColumnConfig &
  (
    | {
        currencyColumnField: string;
      }
    | {
        staticCurrency: string | number;
      }
    | {}
  );

export function isDebitCreditColumnConfig(column: ListColumnTypeConfig): column is AmountColumnConfig {
  return isNumberColumnConfig(column);
}

export type CompanyColumnConfig = Omit<IDColumnConfig, 'dynamicForms'> & {
  applyFilter?: YN;
};

export type ListColumnTypeConfig =
  | TextColumnConfig
  | NumberColumnConfig
  | DateColumnConfig
  | EnumColumnConfig
  | WeightColumnConfig
  | AmountColumnConfig
  | AmountUnitColumnConfig
  | IDColumnConfig
  | DebitCreditColumnConfig
  | CompanyColumnConfig;

export type TextListColumn = BaseListColumn & {
  type: ListColumnType.TEXT;
  typeConfiguration?: TextColumnConfig;
};

export type NumberListColumn = BaseListColumn & {
  type: ListColumnType.NUMBER;
  typeConfiguration: NumberColumnConfig;
};

export type DateListColumn = BaseListColumn & {
  type: ListColumnType.DATE;
  typeConfiguration?: DateColumnConfig;
};

export type EnumListColumn = BaseListColumn & {
  type: ListColumnType.ENUM;
  typeConfiguration?: EnumColumnConfig;
};

export type WeightListColumn = BaseListColumn & {
  type: ListColumnType.WEIGHT;
  typeConfiguration?: WeightColumnConfig;
};

export type AmountListColumn = BaseListColumn & {
  type: ListColumnType.AMOUNT;
  typeConfiguration?: AmountColumnConfig;
};

export type AmountUnitListColumn = BaseListColumn & {
  type: ListColumnType.AMOUNT_UNIT;
  typeConfiguration?: AmountUnitColumnConfig;
};

export type IDListColumn = BaseListColumn & {
  type: ListColumnType.ID;
  typeConfiguration?: IDColumnConfig;
};

export type DebitCreditListColumn = BaseListColumn & {
  type: ListColumnType.DEBIT_CREDIT;
  typeConfiguration?: DebitCreditColumnConfig;
};

export type PriceUnitListColumn = BaseListColumn & {
  type: ListColumnType.PRICE_UNIT;
  typeConfiguration?: AmountUnitColumnConfig;
};

export type CompanyListColumn = BaseListColumn & {
  type: ListColumnType.COMPANY;
  typeConfiguration?: CompanyColumnConfig;
};

export type ListColumn =
  | TextListColumn
  | NumberListColumn
  | DateListColumn
  | EnumListColumn
  | WeightListColumn
  | AmountListColumn
  | AmountUnitListColumn
  | IDListColumn
  | DebitCreditListColumn
  | PriceUnitListColumn
  | CompanyListColumn;

export type ListColumnMenuConfig = {
  menus?: ListColumnMenu[];
};

export enum ListColumnMenu {
  GENERAL = 1,
  COLUMN = 2,
  FILTER = 3,
}
export const ListColumnMenus = enumOptionsFromEnum<ListColumnMenu>(ListColumnMenu);
export const agGridColumnMenuMap = {
  [ListColumnMenu.GENERAL]: 'generalMenuTab',
  [ListColumnMenu.COLUMN]: 'columnMenuTab',
  [ListColumnMenu.FILTER]: 'filterMenuTab',
};

export type CreateListColumnRequest = ListColumnResponse & { entityType: null };
export type UpdateListColumnRequest = Partial<Omit<CreateListColumnRequest, 'flexViewId'>> & Pick<ListColumnResponse, 'id'>;

export enum ListDateGroupBehavior {
  BY_DATE = 'By Date',
  BY_WEEK = 'By Week',
  BY_MONTH = 'By Month',
  BY_YEAR = 'By Year',
  BY_QUARTER = 'By Quarter',
}
export const ListDateGroupBehaviors = [
  { value: ListDateGroupBehavior.BY_DATE, label: 'By Date' },
  { value: ListDateGroupBehavior.BY_WEEK, label: 'By Week' },
  { value: ListDateGroupBehavior.BY_MONTH, label: 'By Month' },
  { value: ListDateGroupBehavior.BY_QUARTER, label: 'By Quarter' },
  { value: ListDateGroupBehavior.BY_YEAR, label: 'By Year' },
];
