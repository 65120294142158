<div class="form-container" [formGroup]="form">
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field name="Requested Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="requestedAmount" [fieldType]="1" [format]="amountFormat" [readonly]="true" #amountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Advance Payments'">
    <advance-payment-list [formControlName]="'advancePaymentEntries'" [requestedAmount]="getRequestedAmount()" [currencyId]="currencyId" [counterparty]="counterparty"> </advance-payment-list>
  </collapsible-card>
</div>
