import { of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { PhysicalContract, SourceEntityType } from 'src/lib/newBackendTypes';
import { confirmationForm } from '../flexDynamicForm';
import { DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';

export const submitCancelContract: submitFormCallback<PhysicalContract> = (delegate, id) => {
  if (!id) return of(null);
  const api = delegate.getService('api');

  return api.rpc<PhysicalContract>(endpoints.cancelContractRemainder, { contractId: id }, null);
};

export const cancelContractPreset: DynamicFormConstant<{}> = {
  value: DynamicFormType.CANCEL_CONTRACT,
  label: 'Cancel Contract',
  endpoints: [endpoints.cancelContractRemainder],
  entityType: SourceEntityType.CONTRACT_KEY,
  width: 250,
  openForm: confirmationForm(
    'Warning - Cancel Contract',
    `Warning!  All unshipped quantities from this contract will be canceled.  This is irriversible.
            Are you sure you wish to continue?`,
    'Yes',
    'No'
  ),
  submitForm: submitCancelContract,
};
