import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'thalos-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  error: Observable<number>;
  requestId: Observable<string>;
  message: Observable<string>;
  endpoint: Observable<string>;

  constructor(route: ActivatedRoute) {
    this.error = route.params.pipe(map((params) => params.error));

    this.requestId = route.queryParams.pipe(map((p) => p.requestId || null));
    this.message = route.queryParams.pipe(map((p) => p.message || null));
    this.endpoint = route.queryParams.pipe(map((p) => p.endpoint || null));
  }
}
