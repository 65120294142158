import { AbstractControl } from '@angular/forms';
import slugify from 'slugify';
import { DocumentMetadata, SourceEntityType } from '.';
import { Subset } from '../generics';

export type Document = BaseDocument & {
  documentLinks?: DocumentLink[];
  metadata?: DocumentMetadata[];
};

export type BaseDocument = {
  id: number; //docKey from edm_docs
  fsPath: string | null;
  fileName: string;
  uploadDate: string;
  storageType: StorageTypes;
  entityType: number;
  entityId: number;
};

export type DocumentLink = {
  documentId: number;
  entityId: number;
  entityType: SourceEntityType;
  id: number;
  linkDate: string;
  linkedBy: number;
  primaryLinkKey: number;
  sourceKey2: number | null;
  sourceKeyDesc: number | null;
};

export type UploadDocumentParams = {
  entityType: SourceEntityType;
  entityId: number;
  documentId?: number;
};

export type DocumentVersion = {
  id: number;
  versionDate?: string;
  version?: number;
};

export type UpdateDocumentRequest = Pick<BaseDocument, 'id'> & {
  metadata?: Subset<DocumentMetadata, 'type' | 'value', 'documentId'>[];
};

export enum StorageTypes {
  FILESYSTEM_FILE = 1,
  FILESYSTEM_FOLDER = 7,
  DATABASE_FILE = 2,
  URL = 8,
}

export function cleanFileName(fileName: string) {
  return slugify(fileName.replace(/[^a-zA-Z0-9_\.\(\)\ \-]+/g, ''));
}

export function fileNameValidator() {
  return (control: AbstractControl) => {
    const val = control.value;

    const error = validateFileName(val);
    if (error) return { custom: error };
    return null;
  };
}

export function validateFileName(fileName: string): string | undefined {
  if (!fileName) return;
  if (!validateFileNameCharacters(fileName)) return `File name has unsupported characters, only spaces, alphanumeric characters, and the following punctuation are allowed: _ - . ) (`;
  if (!validateFileNameFormat(fileName)) return `File name is in the wrong format. File must be named and have a valid extension`;
  return;
}

function validateFileNameCharacters(fileName: string) {
  const fileNameRegex = /^[a-zA-Z0-9\_\.\(\)\ \-]+$/;
  return fileNameRegex.test(fileName);
}

function validateFileNameFormat(fileName: string) {
  const fileNameRegex = /^[a-zA-Z0-9\_\.\(\)\ \-]+\.[A-Za-z0-9]{3,4}$/;
  return fileNameRegex.test(fileName);
}

export type PrintDocumentRequest = {
  packetId: number;
  entityId: number;
  outputName: string;
  savingLocation: number | null;
  copies?: { templateId: number; quantity: number }[];
};
export type PrintMultipleDocumentsRequest = Omit<PrintDocumentRequest, 'entityId'> & {
  emailTo: string;
  entityName: string;
  entityIds: number[];
};
