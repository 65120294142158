import { Component, OnInit } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ContainerForm } from 'src/app/+modules/+logistics/container/container.component';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { Booking, SourceEntityType } from 'src/lib/newBackendTypes';

@Component({
  selector: 'thalos-split-booking-links',
  templateUrl: './booking-links.component.html',
  styleUrls: ['./booking-links.component.scss'],
})
export class BookingLinksComponent implements OnInit, SelectorComponent<never> {
  bookings: (Omit<Booking, 'containers'> & { index: number; containers: string })[] = [];
  bookingKey = SourceEntityType.FREIGHT_BOOKING_KEY;

  observer: Subscriber<never>;

  constructor() {}

  ngOnInit(): void {}

  popup = true;
  popupObservable = new Observable<never>((sub) => (this.observer = sub));

  preselectItems(v: (Booking & { index: number; containers: ContainerForm[] })[]) {
    this.bookings = v.map((b) => {
      return {
        ...b,
        containers: b.containers.map((c) => c.containerNumber).join(', '),
      };
    });
  }

  onNavigate() {
    this.observer.next();
  }
}
