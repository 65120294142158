import { Component, ElementRef, OnInit, ViewChild, forwardRef } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'editor-wrapper',
  templateUrl: './editor-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => EditorWrapperComponent) }],
})
export class EditorWrapperComponent extends FormElementComponent implements OnInit {
  @ViewChild('texteditor', { static: false })
  texteditor: ElementRef;

  ngOnInit(): void {}

  onValueChange(ev: string): void {
    super.onValueChange(ev);
  }

  focus() {
    if (this.texteditor) {
      setTimeout(() => {
        this.texteditor.nativeElement.focus();
      });
    }
  }
}
