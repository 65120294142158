import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BLOCK_ANCHOR_POINT } from 'src/app/core/reducers/actions';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { Store } from 'src/app/core/services/store.service';
import { UtilsService } from 'src/lib/layoutsUtils';
import { MenuItem } from 'src/lib/MenuItem';

@UntilDestroy()
@Component({
  selector: 'roco-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  item: MenuItem;

  @Input()
  forceClose?: boolean;

  @Output()
  forceOpen: EventEmitter<void>;

  open: boolean;
  containsActive: boolean;
  active: boolean;

  constructor(private router: Router, private store: Store, private delegate: DelegateService, private layoutUtils: UtilsService) {
    this.open = false;
    this.containsActive = false;
    this.active = false;
    this.forceOpen = new EventEmitter();

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateStatus();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['forceClose'].currentValue) {
      this.open = false;
    } else if (this.containsActive) {
      this.open = true;
    }
  }

  updateStatus() {
    if (!!this.item.regexPath && this.item.regexPath.test(this.router.url) && !this.router.url.includes('/flex/')) {
      this.active = true;
      this.open = false;
      this.containsActive = false;
      setTimeout(() => this.forceOpen.emit());
    } else {
      this.containsActive = false;
      this.active = false;
      this.open = false;
    }
  }

  openActive() {
    this.open = true;
    this.containsActive = true;
    this.forceOpen.emit();
  }

  ngOnInit(): void {
    this.updateStatus();
  }

  ngOnDestroy(): void {}

  onAuxClick(event: MouseEvent, item: MenuItem) {
    if (event.button === 1) this.layoutUtils.emitToggleSideBar();
    this.store.dispatch({ type: BLOCK_ANCHOR_POINT });
  }

  clearAnchorPoint(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) this.layoutUtils.emitToggleSideBar();
    this.store.dispatch({ type: BLOCK_ANCHOR_POINT });
  }

  get hasVisibleChildren(): boolean {
    return this.item.children && this.item.children.length > 0 && !!this.item.children.find((child) => child.hidden === false || child.hidden === undefined);
  }

  clickItem() {
    if (!this.item.effect) return;

    this.item.effect(this.delegate).subscribe(() => {});
  }
}
