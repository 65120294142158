export enum endpoints {
  listUnits = '/listUnits',

  listCountries = '/listCountries',

  listCounterparties = '/listCounterparties',
  listTraders = '/listTraders',

  getPaymentTerm = '/getPaymentTerm',
  listPaymentTerms = '/listPaymentTerms',
  createPaymentTerm = '/createPaymentTerm',
  updatePaymentTerm = '/updatePaymentTerm',
  deletePaymentTerm = '/deletePaymentTerm',
  archiveUnarchivePaymentTerm = '/archiveUnarchivePaymentTerm',

  listCities = '/listCities',
  createCity = '/createCity',
  updateCity = '/updateCity',
  deleteCity = '/deleteCity',
  getCity = '/getCity',
  listCitiesMaxWeights = '/listCitiesMaxWeights',
  upsertCitiesMaxWeights = '/upsertCitiesMaxWeights',

  listCurrencies = '/listCurrencies',

  listIncoterms = '/listIncoterms',
  createIncoterm = '/createIncoterm',
  getIncoterm = '/getIncoterm',
  updateIncoterm = '/updateIncoterm',
  deleteIncoterm = '/deleteIncoterm',

  listBudgetElements = '/listBudgetElements',
  getBudgetElementGroup = '/getBudgetElementGroup',
  listBudgetElementGroups = '/listBudgetElementGroups',

  listClauseTemplates = '/listClauseTemplates',
  createClauseTemplate = '/createClauseTemplate',
  updateClauseTemplate = '/updateClauseTemplate',
  deleteClauseTemplate = '/deleteClauseTemplate',
  getClauseTemplate = '/getClauseTemplate',

  listContractClauses = '/listContractClauses',

  listContactTypes = '/listContactTypes',
  createContactType = '/createContactType',
  deleteContactType = '/deleteContactType',
  getContactType = '/getContactType',
  updateContactType = '/updateContactType',

  listLanguages = '/listLanguages',

  listContacts = '/listContacts',
  getContact = '/getContact',
  createContact = '/createContact',
  updateContact = '/updateContact',
  deleteContact = '/deleteContact',
  archiveUnarchiveContact = '/archiveUnarchiveContact',
  listBankContacts = '/listBankContacts',
  listBanks = '/listBanks',

  getContract = '/getContract',
  updateContract = '/updateContract',
  updateContractManagement = '/updateContractManagement',
  valuateContract = '/valuateContract',
  sendClauseMissingInformationEmail = '/sendClauseMissingInformationEmail',

  getContractLineData = '/getContractLineData',
  finishContractLine = '/finishContractLine',

  deleteContractLine = '/deleteContractLine',
  deleteContractClause = '/deleteContractClause',
  deleteContractExpense = '/deleteContractExpense',

  listContractFixationDetails = '/listContractFixationDetails',

  listFutureMarkets = '/listFutureMarkets',

  listItems = '/listItems',
  listProducts = '/listProducts',
  listCompanies = '/listCompanies',

  listContainerTypes = '/listContainerTypes',

  listrpcids = '/listrpcids',
  me = '/me',

  listRequirements = '/listRequirements',
  getRequirement = '/getRequirement',
  deleteRequirement = '/deleteRequirement',
  createRequirement = '/createRequirement',
  updateRequirement = '/updateRequirement',

  listApprovals = '/listApprovals',
  createApproval = '/createApproval',
  updateApproval = '/updateApproval',
  deleteApproval = '/deleteApproval',
  approvalQueue = '/approvalQueue',

  listApprovalTypeApprovers = '/listApprovalTypeApprovers',
  getApprovalTypeApprover = '/getApprovalTypeApprover',
  deleteApprovalTypeApprover = '/deleteApprovalTypeApprover',
  createApprovalTypeApprover = '/createApprovalTypeApprover',
  updateApprovalTypeApprover = '/updateApprovalTypeApprover',

  getDraft = '/getDraft',
  updateDraft = '/updateDraft',
  createDraft = '/createDraft',

  deleteDraftLine = '/deleteDraftLine',

  convertDraft = '/convertDraftToContract',
  poLineFinder = '/poLineFinder',
  printContract = '/printContract',

  listApprovalTypes = '/listApprovalTypes',

  listComments = '/listComments',
  createComment = '/createComment',
  updateComment = '/updateComment',
  deleteComment = '/deleteComment',

  shipmentLookup = '/shipmentLookup',
  shipmentLookupGenealogy = '/shipmentLookupGenealogy',

  listShipmentWalkPrice = '/listShipmentWalkPrice',

  createDiscrepancy = '/createDiscrepancy',
  updateDiscrepancy = '/updateDiscrepancy',
  deleteDiscrepancy = '/deleteDiscrepancy',
  getDiscrepancy = '/getDiscrepancy',
  listDiscrepancies = '/listDiscrepancies',
  archiveUnarchiveDiscrepancy = '/archiveUnarchiveDiscrepancy',

  createWeightTicket = '/createWeightTicket',
  listWeightTickets = '/listWeightTickets',
  deleteWeightTicket = '/deleteWeightTicket',

  listDocumentsNames = '/listDocumentsNames',
  listDocuments = '/listDocuments',
  downloadDocument = '/downloadDocument',
  uploadDocument = '/uploadDocument/:entityType/:entityId/:documentId?',
  updateDocument = '/updateDocument',

  listDocumentVersions = '/listDocumentVersions',
  linkDocument = '/linkDocument',
  unlinkDocument = '/unlinkDocument',
  updateDocumentName = '/updateDocumentName',

  deleteDraftClause = '/deleteDraftClause',

  deleteDraftExpense = '/deleteDraftExpense',

  listMarketValuations = '/listMarketValuations',
  listActiveMarketValuations = '/listActiveMarketValuations',

  listLmeMarketData = '/listLmeMarketData',

  mockError = '/mockError',

  globalSearch = '/globalSearch',

  getShipmentInvoiceInformation = '/getShipmentInvoiceInformation',

  createShipmentFromContractLine = '/createShipmentFromContractLine',
  updateShipment = '/updateShipment',
  deleteShipment = '/deleteShipment',
  getShipment = '/getShipment',
  listShipments = '/listShipments',
  listShipmentsSimple = '/listShipmentsSimple',
  updateRefReceived = '/updateRefReceived',
  generateInventoryWriteOff = '/generateInventoryWriteOff',
  reverseInventoryWriteOff = '/reverseInventoryWriteOff',

  createContainer = '/createContainer',
  updateContainer = '/updateContainer',
  deleteContainer = '/deleteContainer',
  listContainers = '/listContainers',
  linkContainerToBooking = '/linkContainerToBooking',
  unlinkContainerFromBooking = '/unlinkContainerFromBooking',
  listUnlinkedContainers = '/listUnlinkedContainers',

  createBooking = '/createBooking',
  updateBooking = '/updateBooking',
  deleteBooking = '/deleteBooking',
  getBooking = '/getBooking',
  bookingQueue = '/bookingQueue',
  logisticsFileAssignmentQueue = '/logisticsFileAssignmentQueue',

  listRoutineTrackingBookings = '/listRoutineTrackingBookings',
  listArrivalConfirmationBookings = '/listArrivalConfirmationBookings',

  listVessels = '/listVessels',

  invoicePurchaseTicket = '/invoicePurchaseTicket',

  purchaseTicketQueue = '/purchaseTicketQueue',

  createVessel = '/createVessel',

  listServiceOrders = '/listServiceOrders',
  createServiceOrder = '/createServiceOrder',
  updateServiceOrder = '/updateServiceOrder',
  deleteServiceOrder = '/deleteServiceOrder',
  getServiceOrder = '/getServiceOrder',
  invoiceServiceOrder = '/invoiceServiceOrder',
  uninvoiceServiceOrder = '/uninvoiceServiceOrder',
  generateBatchServiceOrder = '/generateBatchServiceOrder',

  listServices = '/listServices',

  listCheckListItem = '/listCheckListItem',
  listCheckListEntries = '/listCheckListEntries',
  createCheckListEntry = '/createCheckListEntry',

  createMatching = '/createMatching',
  unmatch = '/unmatch',
  listMatchings = '/listMatchings',

  listPurchaseMatchData = '/listPurchaseMatchData',
  listMatchingSaleContractData = '/listMatchingSaleContractData',

  getItem = '/getItem',
  createItem = '/createItem',
  updateItem = '/updateItem',
  deleteItem = '/deleteItem',
  archiveItem = '/archiveItem',
  unarchiveItem = '/unarchiveItem',
  createRoyceItem = '/createRoyceItem',
  createClientItem = '/createClientItem',
  listQualityTypes = '/listQualityTypes',
  listItemReportingGroups = '/listItemReportingGroups',
  listItemAnalyticGroups = '/listItemAnalyticGroups',
  listFinalSalesCommissions = '/listFinalSalesCommissions',
  listFinalPurchaseCommissions = '/listFinalPurchaseCommissions',

  createFlexView = '/createFlexView',
  updateFlexView = '/updateFlexView',
  deleteFlexView = '/deleteFlexView',
  listFlexViews = '/listFlexViews',
  getFlexView = '/getFlexView',
  getFlexViewAdmin = '/getFlexViewAdmin',

  createFlexColumn = '/createFlexColumn',
  updateFlexColumn = '/updateFlexColumn',
  deleteFlexColumn = '/deleteFlexColumn',

  createFlexFilterGroup = '/createFlexFilterGroup',
  updateFlexFilterGroup = '/updateFlexFilterGroup',
  deleteFlexFilterGroup = '/deleteFlexFilterGroup',
  listFlexFilterGroups = '/listFlexFilterGroups',

  createFlexFilterRow = '/createFlexFilterRow',
  updateFlexFilterRow = '/updateFlexFilterRow',
  deleteFlexFilterRow = '/deleteFlexFilterRow',
  listFlexFilterRows = '/listFlexFilterRows',

  listViewsAndTables = '/listViewsAndTables',
  getDefinitions = '/getDefinitions',
  getFlexViewData = '/getFlexViewData',

  listFlexViewLayouts = '/listFlexViewLayouts',
  createFlexViewLayout = '/createFlexViewLayout',
  updateFlexViewLayout = '/updateFlexViewLayout',
  updateFlexViewLayoutAdmin = '/updateFlexViewLayoutAdmin',
  deleteFlexViewLayout = '/deleteFlexViewLayout',

  listAllFlexFilterGroupsAdmin = '/listAllFlexFilterGroupsAdmin',
  listFlexViewsAdmin = '/listFlexViewsAdmin',

  getGenealogy = '/getGenealogy',
  splitShipment = '/splitShipment',
  mergeShipments = '/mergeShipments',

  undoShipmentMerger = '/undoShipmentMerger',
  undoShipmentSplit = '/undoShipmentSplit',

  listMarketHolidays = '/listMarketHolidays',

  checkDuplicateContainerNumber = '/checkDuplicateContainerNumber',

  getReservedInvoice = '/getReservedInvoice',

  listTasks = '/listTasks',
  createTask = '/createTask',
  getTask = '/getTask',
  updateTask = '/updateTask',
  pendingTasks = '/pendingTasks',

  setDefaultFlexLayout = '/setDefaultFlexLayout',

  listDocumentTemplates = '/listDocumentTemplates',
  createDocumentTemplate = '/createDocumentTemplate',
  updateDocumentTemplate = '/updateDocumentTemplate',
  deleteDocumentTemplate = '/deleteDocumentTemplate',
  getDocumentTemplate = '/getDocumentTemplate',

  createDocumentPacket = '/createDocumentPacket',
  updateDocumentPacket = '/updateDocumentPacket',
  deleteDocumentPacket = '/deleteDocumentPacket',
  listDocumentPackets = '/listDocumentPackets',
  getDocumentPacket = '/getDocumentPacket',

  generateDocumentsFromPacket = '/generateDocumentsFromPacket',
  generateMultipleDocumentsFromPacket = '/generateMultipleDocumentsFromPacket',
  documentsFromPacketZipFile = '/documentsFromPacketZipFile',
  generateClientInvoicesDocuments = '/generateClientInvoicesDocuments',

  listContractShipmentsAndFixationData = '/listContractShipmentsAndFixationData',
  listContractShipmentsAndFixationSummary = '/listContractShipmentsAndFixationSummary',
  listContractInvoicesAndAdvances = '/listContractInvoicesAndAdvances',

  listShipmentContractData = '/listShipmentContractData',

  listMetalControlGroups = '/listMetalControlGroups',
  listUserGroups = '/listUserGroups',
  createUserGroupEndpointAssignment = '/createUserGroupEndpointAssignment',
  deleteUserGroupEndpointAssignment = '/deleteUserGroupEndpointAssignment',
  listUserGroupEndpointAssignments = '/listUserGroupEndpointAssignments',
  findGroups = '/findGroups',

  linkShipment = '/linkShipment',
  unlinkShipment = '/unlinkShipment',

  listWarehouseMovements = '/listWarehouseMovements',
  createWarehouseMovement = '/createWarehouseMovement',
  deleteWarehouseMovement = '/deleteWarehouseMovement',

  listBookingExpenses = '/listBookingExpenses',

  reportError = '/reportError',

  setFlexViewUserGroupAssignments = '/setFlexViewUserGroupAssignments',
  setFilterGroupUserGroupAssignments = '/setFilterGroupUserGroupAssignments',

  listOceanRates = '/listOceanRates',
  listOceanSegments = '/listOceanSegments',
  listOceanSurcharges = '/listOceanSurcharges',
  createOceanRate = '/createOceanRate',
  createOceanRateJob = '/createOceanRateJob',
  getOceanRate = '/getOceanRate',
  getOceanSurcharge = '/getOceanSurcharge',
  getOceanSegment = '/getOceanSegment',
  updateOceanRate = '/updateOceanRate',
  deleteOceanRate = '/deleteOceanRate',
  oceanRateLookup = '/oceanRateLookup',
  listSegmentsUsage = '/listSegmentsUsage',
  listRatesTemplates = '/listRatesTemplates',
  createRatesCitiesTemplate = '/createRatesCitiesTemplate',
  listRatesCitiesTemplate = '/listRatesCitiesTemplate',
  upsertOceanSegment = '/upsertOceanSegment',

  requestOceanRate = '/requestOceanRate',
  linkRelatedBooking = '/linkRelatedBooking',
  unlinkRelatedBooking = '/unlinkRelatedBooking',
  getRelatedBookings = '/getRelatedBookings',

  listPackingTypes = '/listPackingTypes',

  cancelContractRemainder = '/cancelContractRemainder',

  listBankAccounts = '/listBankAccounts',

  listSTBBookingData = '/listSTBBookingData',

  archiveContract = '/archiveContract',
  unarchiveContract = '/unarchiveContract',

  listClientCreditInsurance = '/listClientCreditInsurance',
  createClientCreditInsurance = '/createClientCreditInsurance',
  updateClientCreditInsurance = '/updateClientCreditInsurance',
  deleteClientCreditInsurance = '/deleteClientCreditInsurance',

  listCommentCategories = '/listNoteCategories',

  listContactBankInformation = '/listContactBankInformation',

  listTags = '/listTags',
  createTag = '/createTag',
  updateTag = '/updateTag',
  getTag = '/getTag',
  deleteTag = '/deleteTag',
  assignTags = '/assignTags',
  listTagAssignments = '/listTagAssignments',

  createContactBankInformation = '/createContactBankInformation',
  updateContactBankInformation = '/updateContactBankInformation',
  deleteContactBankInformation = '/deleteContactBankInformation',

  //TEMP
  listContractData = '/listContractData',
  listDraftData = '/listDraftData',

  createContactMetadata = '/createContactMetadata',
  updateContactMetadata = '/updateContactMetadata',
  deleteContactMetadata = '/deleteContactMetadata',
  getContactMetadata = '/getContactMetadata',
  syncContactMetadata = '/syncContactMetadata',

  createContactMethod = '/createContactMethod',
  updateContactMethod = '/updateContactMethod',
  deleteContactMethod = '/deleteContactMethod',

  listAddresses = '/listAddresses',
  createAddress = '/createAddress',
  updateAddress = '/updateAddress',
  deleteAddress = '/deleteAddress',

  syncContactTypes = '/syncContactTypes',

  listGlAccounts = '/listGlAccounts',
  createGlAccount = '/createGlAccount',
  updateGlAccount = '/updateGlAccount',
  getGlAccount = '/getGlAccount',
  deleteGlAccount = '/deleteGlAccount',
  archiveUnarchiveGlAccount = '/archiveUnarchiveGlAccount',

  listCpnTemplateHeaders = '/listCpnTemplateHeaders',
  listTemplateHeaders = '/listTemplateHeaders',

  updateContactAccountingInfo = '/updateContactAccountingInfo',

  updateAuthorizedCurrencies = '/updateAuthorizedCurrencies',

  listEndpointsAdmin = '/listEndpointsAdmin',

  linkSharepointFile = '/linkSharepointFile',

  rollOverUnfulfilledContractLine = '/rollOverUnfulfilledContractLine',

  getContactNames = '/getContactNames',

  listConfigurations = '/listConfigurations',
  listConfigurationsAdmin = '/listConfigurationsAdmin',
  getConfiguration = '/getConfiguration',
  createConfiguration = '/createConfiguration',
  updateConfiguration = '/updateConfiguration',
  deleteConfiguration = '/deleteConfiguration',

  listAdvancesToOpen = '/listAdvancesToOpen',
  listAdvances = '/listAdvances',
  createAdvanceFromContract = '/createAdvanceFromContract',
  getAdvance = '/getAdvance',
  updateAdvance = '/updateAdvance',

  listShipmentInformationForClientInvoice = '/listShipmentInformationForClientInvoice',
  createClientInvoice = '/createClientInvoice',
  validateClientInvoiceNumber = '/validateClientInvoiceNumber',

  listMarketClosingPrices = '/listMarketClosingPrices',
  getDailyExchangeRates = '/getDailyExchangeRates',

  listVouchers = '/listVouchers',
  listVouchersSimple = '/listVouchersSimple',
  listVouchersBalance = '/listVouchersBalance',
  createVoucher = '/createVoucher',
  updateVoucher = '/updateVoucher',
  updateVoucherReferences = '/updateVoucherReferences',
  deleteVoucher = '/deleteVoucher',
  getVoucher = '/getVoucher',
  setInvoiceSentDate = '/setInvoiceSentDate',
  setIVADeclarationDate = '/setIVADeclarationDate',

  deleteAdvance = '/deleteAdvance',
  createAdvancePayment = '/createAdvancePayment',

  linkUnlinkAdvancePayment = '/linkUnlinkAdvancePayment',
  listOpenPaymentsToAdvance = '/listOpenPaymentsToAdvance',

  getJournal = '/getJournal',
  listJournals = '/listJournals',
  createJournal = '/createJournal',
  updateJournal = '/updateJournal',
  deleteJournal = '/deleteJournal',

  listJournalReconciliation = '/listJournalReconciliation',
  reconcileJournalEntries = '/reconcileJournalEntries',
  unreconcileJournalEntries = '/unreconcileJournalEntries',

  listAnalyticalGroups = '/listAnalyticalGroups',

  getExchangeContract = '/getExchangeContract',
  createExchangeContract = '/createExchangeContract',
  updateExchangeContract = '/updateExchangeContract',
  deleteExchangeContract = '/deleteExchangeContract',
  listExchangeContracts = '/listExchangeContracts',
  bookExchangeContract = '/bookExchangeContract',
  unbookExchangeContract = '/unbookExchangeContract',

  setSalePrice = '/setSalePrice',

  listContractLineFixationData = '/listContractLineFixationData',
  listShipmentFixations = '/listShipmentFixations',
  getPriceFixation = '/getPriceFixation',
  createPriceFixation = '/createPriceFixation',
  updatePriceFixation = '/updatePriceFixation',
  deletePriceFixation = '/deletePriceFixation',
  applyPriceFixations = '/applyPriceFixations',
  unapplyPriceFixations = '/unapplyPriceFixations',
  getShipmentsAndFixationsFromInvoice = '/getShipmentsAndFixationsFromInvoice',
  listFixationsLinkedToShipment = '/listFixationsLinkedToShipment',
  listShipmentFixationSummary = '/listShipmentFixationSummary',

  listPremiumFixationSummary = '/listPremiumFixationSummary',
  listPremiumFixations = '/listPremiumFixations',
  createPremiumFixation = '/createPremiumFixation',
  updatePremiumFixation = '/updatePremiumFixation',
  deletePremiumFixation = '/deletePremiumFixation',

  listPayments = '/listPayments',
  createPayment = '/createPayment',
  getPayment = '/getPayment',
  updatePayment = '/updatePayment',
  deletePayment = '/deletePayment',

  listOpenPayableItems = '/listOpenPayableItems',
  getPayOrder = '/getPayOrder',
  listPayOrders = '/listPayOrders',
  createPayOrder = '/createPayOrder',
  updatePayOrder = '/updatePayOrder',
  deletePayOrder = '/deletePayOrder',

  matchPayments = '/matchPayments',
  bookPayOrder = '/bookPayOrder',
  unbookPayOrder = '/unbookPayOrder',

  applyItemsToVoucher = '/applyItemsToVoucher',

  reverseVoucher = '/reverseVoucher',

  listPaymentEntries = '/listPaymentEntries',

  listReportAutomationDefinition = '/listReportAutomationDefinition',
  createReportAutomationDefinition = '/createReportAutomationDefinition',
  getReportAutomationDefinition = '/getReportAutomationDefinition',
  deleteReportAutomationDefinition = '/deleteReportAutomationDefinition',
  updateReportAutomationDefinition = '/updateReportAutomationDefinition',

  processAutomatedReport = '/processAutomatedReport',
  scheduleAutomatedReport = '/scheduleAutomatedReport',
  listPendingAutomatedReportRequests = '/listPendingAutomatedReportRequests',
  listStoredProcedures = '/listStoredProcedures',

  listEntityLayouts = '/listEntityLayouts',
  deleteEntityLayout = '/deleteEntityLayout',
  createEntityLayout = '/createEntityLayout',
  updateEntityLayout = '/updateEntityLayout',
  upsertEntityLayoutDefault = '/upsertEntityLayoutDefault',

  revaluateFx = '/revaluateFx',

  getFiscalCompany = '/getFiscalCompany',
  listFiscalCompanies = '/listFiscalCompanies',
  updateFiscalCompanyPermDate = '/updateFiscalCompanyPermDate',

  generateJournalViewReport = '/generateJournalViewReport',
  generateGeneralSimpleTypeReport = '/generateGeneralSimpleTypeReport',
  generateJournalReconciliationReport = '/generateJournalReconciliationReport',
  listOpenItems = '/listOpenItems',
  listOpenItemsByEntryType = '/listOpenItemsByEntryType',

  generateFuturMatching = '/generateFuturMatching',
  reverseFuturMatching = '/reverseFuturMatching',
  listFuturMatchings = '/listFuturMatchings',
  listFutureContracts = '/listFutureContracts',
  calculateOHUHBalance = '/calculateOHUHBalance',

  listJournalRelations = '/listJournalRelations',

  updateShipmentValueDate = '/updateShipmentValueDate',

  reconcilePayment = '/reconcilePayment',

  generateBalanceSheetReport = '/generateBalanceSheetReport',

  listLettersOfCredit = '/listLettersOfCredit',
  getLetterOfCredit = '/getLetterOfCredit',
  createLetterOfCredit = '/createLetterOfCredit',
  updateLetterOfCredit = '/updateLetterOfCredit',
  deleteLetterOfCredit = '/deleteLetterOfCredit',

  listLcPresentations = '/listLcPresentations',
  getLcPresentation = '/getLcPresentation',
  createLcPresentation = '/createLcPresentation',
  updateLcPresentation = '/updateLcPresentation',
  deleteLcPresentation = '/deleteLcPresentation',

  listLcPresentationFees = '/listLcPresentationFees',

  sendFixationPriceEmail = '/sendFixationPriceEmail',
  listSurchargesTypes = '/listSurchargesTypes',
  getSurchargeType = '/getSurchargeType',
  createSurchargeType = '/createSurchargeType',
  deleteSurchargeType = '/deleteSurchargeType',

  createFutureContract = '/createFutureContract',
  updateFutureContract = '/updateFutureContract',
  deleteFutureContract = '/deleteFutureContract',
  getFutureContract = '/getFutureContract',

  listFutureMarketTerms = '/listFutureMarketTerms',
  listNoAffectedReasons = '/listNoAffectedReasons',
  listContractLines = '/listContractLines',
  listNetPositionSummary = '/listNetPositionSummary',
  listNetPositionEntries = '/listNetPositionEntries',
  listNetPositionAccounts = '/listNetPositionAccounts',
  getNetPositionLine = '/getNetPositionLine',
  getNetPositionOperation = '/getNetPositionOperation',
  updateNPLineProperties = '/updateNPLineProperties',
  reverseNetPositionLine = '/reverseNetPositionLine',
  reverseNPJournalByLines = '/reverseNPJournalByLines',
  correctNetPositionOperation = '/correctNetPositionOperation',
  createNetPositionJournal = '/createNetPositionJournal',
  updateNetPositionJournalDates = '/updateNetPositionJournalDates',
  generateNetPositionPriceReport = '/generateNetPositionPriceReport',
  fixMupForEntriesByEntity = '/fixMupForEntriesByEntity',

  listNotificationsSubscriptions = '/listNotificationsSubscriptions',
  getNotificationSubscription = '/getNotificationSubscription',
  createNotificationSubscription = '/createNotificationSubscription',
  deleteNotificationSubscription = '/deleteNotificationSubscription',
  listNotifications = '/listNotifications',
  updateNotificationStatus = '/updateNotificationStatus',

  sendThalosMessage = '/sendThalosMessage',

  getBudgetElement = '/getBudgetElement',
  createBudgetElement = '/createBudgetElement',
  updateBudgetElement = '/updateBudgetElement',
  deleteBudgetElement = '/deleteBudgetElement',
  archiveUnarchiveBudgetElement = '/archiveUnarchiveBudgetElement',

  listBudgetElementAccounting = '/listBudgetElementAccounting',
  createBudgetElementAccounting = '/createBudgetElementAccounting',
  getBudgetElementAccounting = '/getBudgetElementAccounting',
  updateBudgetElementAccounting = '/updateBudgetElementAccounting',
  deleteBudgetElementAccounting = '/deleteBudgetElementAccounting',
  archiveUnarchiveBudgetElementAcc = '/archiveUnarchiveBudgetElementAcc',
  getBudgetElementUsage = '/getBudgetElementUsage',
  listBudgetElementsAndAccounting = '/listBudgetElementsAndAccounting',

  graphql = '/graphql',

  listEndpointStatsPerNumberOfRequests = '/listEndpointStatsPerNumberOfRequests',
  listEndpointStatsPerNumberOfUsers = '/listEndpointStatsPerNumberOfUsers',
  listEndpointStatsPerAverageTime = '/listEndpointStatsPerAverageTime',

  createUserGroupGraphqlAssignment = '/createUserGroupGraphqlAssignment',
  deleteUserGroupGraphqlAssignment = '/deleteUserGroupGraphqlAssignment',
  listUserGroupGraphqlAssignments = '/listUserGroupGraphqlAssignments',
  listGraphqlAdmin = '/listGraphqlAdmin',
  listGraphqlRpcIds = '/listGraphqlRpcIds',
  getGenericEntity = '/getGenericEntity',

  openFuturesMTM = '/openFuturesMTM',

  createMWTIMarketPrice = '/createMWTIMarketPrice',

  listLogisticsAppointments = '/listLogisticsAppointments',
  getLogisticsAppointment = '/getLogisticsAppointment',
  listLogisticsAppointmentsList = '/listLogisticsAppointmentsList',
  listElementDescription = '/listElementDescription',
  createLogisticsAppointments = '/createLogisticsAppointments',
  deleteLogisticsAppointment = '/deleteLogisticsAppointment',

  checkVoucherBalanceRelease = '/checkVoucherBalanceRelease',
  listContractsForHedge = '/listContractsForHedge',

  listPricePrecision = '/listPricePrecision',

  getBookingUpdateHistory = '/getBookingUpdateHistory',

  linkDiscrepancy = '/linkDiscrepancy',
  unlinkDiscrepancy = '/unlinkDiscrepancy',

  listGlStructures = '/listGlStructures',
  listGlBranches = '/listGlBranches',
  getGlBranch = '/getGlBranch',
  createGlBranch = '/createGlBranch',
  updateGlBranch = '/updateGlBranch',
  deleteGlBranch = '/deleteGlBranch',
  archiveUnarchiveGlBranch = '/archiveUnarchiveGlBranch',
  getGlStructureTree = '/getGlStructureTree',
  updateGlAccountParents = '/updateGlAccountParents',
  updateGlBranchParents = '/updateGlBranchParents',
  createGlStructureFromCopy = '/createGlStructureFromCopy',

  updateVoucherPaymentTerm = '/updateVoucherPaymentTerm',

  getPaymentTermDate = '/getPaymentTermDate',
  listPaymentTermDates = '/listPaymentTermDates',
  createPaymentTermDate = '/createPaymentTermDate',
  updatePaymentTermDate = '/updatePaymentTermDate',
  deletePaymentTermDate = '/deletePaymentTermDate',
  listPaymentTermGroups = '/listPaymentTermGroups',

  listContractsForNetPosition = '/listContractsForNetPosition',

  getUserAuthorizedCompany = '/getUserAuthorizedCompany',
  listUserAuthorizedCompanies = '/listUserAuthorizedCompanies',
  createUserAuthorizedCompanies = '/createUserAuthorizedCompanies',
  updateUserAuthorizedCompanies = '/updateUserAuthorizedCompanies',
  deleteUserAuthorizedCompanies = '/deleteUserAuthorizedCompanies',
  createThalosUser = '/createThalosUser',
  getThalosUser = '/getThalosUser',
  listThalosUsers = '/listThalosUsers',
  deleteThalosUser = '/deleteThalosUser',

  listNetPositionDailyReview = '/listNetPositionDailyReview',

  listProductFamilies = '/listProductFamilies',

  listCollateralAR = '/listCollateralAR',
  listCollateralMarkets = '/listCollateralMarkets',
  rebuildMarketMatrixAR = '/rebuildMarketMatrixAR',
  refreshMarketMatrixARPrices = '/refreshMarketMatrixARPrices',
  updateMarketMatrixAR = '/updateMarketMatrixAR',
}
