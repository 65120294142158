/**
 * Defines a class that can be used as a route destination
 */

import { Observable } from 'rxjs';

export interface IRoutable {
  /**
   * Returns the text to display in the browser tab.
   */
  getTabTitle(): string;

  /**
   * An event that fires when navigating within this component. Updates the tab title in the browser.
   */
  onTitleChange?: Observable<string>;

  /**
   * If true, data is stored from this component.
   */
  anchorPoint?: true;
}

export function isRoutable(comp: any): comp is IRoutable {
  return 'getTabTitle' in comp && typeof comp['getTabTitle'] === 'function';
}
