<div class="thalos-ui-widget" [ngClass]="{ readonly: readonly }">
  <textarea
    kendoTextArea
    [(ngModel)]="_value"
    [placeholder]="placeholder || ''"
    [readonly]="readonly"
    [maxlength]="maxlength"
    (valueChange)="onValueChange(value)"
    (blur)="onBlur()"
    #textarea
    autocomplete="_none"
    [rows]="rows"
    style="resize: vertical"
  >
  </textarea>
  <kendo-textarea-suffix *ngIf="showCounter" class="kendo-counter">
    <span class="counter">{{ counter }}</span>
  </kendo-textarea-suffix>
</div>
