<div class="d-flex" style="height: 100%; flex-direction: column; overflow: hidden">
  <ng-container *ngIf="readonly && popup"> Readonly mode </ng-container>

  <form [formGroup]="searchForm" *ngIf="!readonly" class="form-container">
    <div class="row">
      <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Container Number'">
        <input-wrapper cyData="containerNumber" [formControlName]="'containerNumber'"> </input-wrapper>
      </vertical-field>
    </div>
    <div *ngIf="searchForm.valid === false && searchForm.touched === true && searchForm?.errors?.required" style="text-align: end">
      <label class="error-label">You must fill out at least one field.</label>
    </div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row-reverse">
      <button type="submit" kendoButton (click)="clickSearch()" style="width: 100px">Search</button>
    </div>
  </form>
  <div style="flex-grow: 1; width: 100%">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" [rowData]="data" [ngClass]="{ hidden: data === null }" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
