<div class="card desktop-h-100 flex-view">
  <div class="card-header d-flex flex-column">
    <h4 class="flex-grow-1" style="margin-bottom: 0"><i [ngClass]="FlexViewIcon"></i> {{ flexView?.name }}</h4>
    <h6 *ngIf="isUserFiltering" class="flex-grow-1 mt-2" style="margin-bottom: 0">{{ selectedFilterName }}</h6>
  </div>
  <div class="card-body" style="display: flex; flex-direction: column">
    <div class="grid-header">
      <button kendoButton type="button" (click)="autosizeColumns()" title="Auto size columns" [icon]="'arrows-resizing'"></button>
      <kendo-dropdownlist class="layouts-dropdown" [formControl]="layoutControl" [data]="layouts" [valueField]="'id'" [textField]="'name'"></kendo-dropdownlist>
      <button kendoButton type="button" (click)="clickSaveLayout()">Save Layout</button>
      <button kendoButton type="submit" (click)="deleteLayout()" *ngIf="!!layoutControl.value">Delete Layout</button>
      <button kendoButton type="button" (click)="reloadLayout()" *ngIf="!!layoutControl.value">Reload Layout</button>
      <div class="grid-header__divider"></div>
      <button kendoButton type="submit" (click)="gotoFlexView()" *ngIf="editFlexViewAuthorized">Edit View</button>
      <button kendoButton type="submit" (click)="getFlexViewData()" [icon]="'filter'" *ngIf="hasUserFilters"></button>
      <button kendoButton type="submit" (click)="getFlexViewData(false)" [icon]="'refresh'"></button>
      <input kendoTextBox [formControl]="quickFilterControl" class="w-auto" />
      <button kendoButton type="button" [icon]="'filter-clear'" (click)="resetFilters()" title="Clear local grid filters"></button>
    </div>
    <ag-grid-angular style="width: 100%; flex-grow: 1" class="ag-theme-balham" [rowData]="data || []" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"> </ag-grid-angular>
  </div>
</div>
