<form [formGroup]="form">
  <div class="row" style="max-width: 400px">
    <p>The following service order(s) will be invoiced: {{ serviceOrderIds }}</p>
    <vertical-field [name]="'Voucher Type'" class="col-12">
      <simple-dropdown [formControlName]="'documentation'" [data]="documentation" [textField]="'label'" [valueField]="'label'" [valuePrimitive]="false"> </simple-dropdown>
    </vertical-field>
    <vertical-field [name]="'Invoice Date'" class="col-12">
      <datepicker-wrapper [formControlName]="'invoiceDate'"></datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Due Date'" class="col-12">
      <datepicker-wrapper [formControlName]="'dueDate'"></datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Payment Term'" class="col-12">
      <dropdown-wrapper [formControlName]="'paymentTerm'" [dropdownConfig]="paymentTermConfig"></dropdown-wrapper>
    </vertical-field>
    <vertical-field [name]="'Counterparty Reference'" class="col-12">
      <input-wrapper [formControlName]="'yourReference'"></input-wrapper>
    </vertical-field>
  </div>
</form>
