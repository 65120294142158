import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { GridOptions, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { getContextMenuItems } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { entityIdFormat } from 'src/lib/commonTypes';
import { QueryFilters } from 'src/lib/generics';
import { Container } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@Component({
  selector: 'thalos-container-finder',
  templateUrl: './container-finder.component.html',
  styleUrls: ['./container-finder.component.scss'],
})
export class ContainerFinderComponent implements OnInit, SelectorComponent<Container[]> {
  @Input()
  public readonly: boolean;

  @Input()
  preselectedContainers: Container[];

  public popup: boolean = false;

  gridOptions: GridOptions;

  data: Container[] | null;
  numberFormat: NumberFormatOptions = entityIdFormat();

  searchForm: TypedFormGroup<ContainerFilterForm>;

  public selectedContainers: BehaviorSubject<Container[]>;
  public popupObservable: Observable<Container[]>;
  public popupObserver: Subscriber<Container[]>;

  constructor(private api: ThalosApiService, private spinnerService: SpinnerService, private dataFormatter: DataFormattingService) {
    this.searchForm = new TypedFormGroup<ContainerFilterForm>({
      containerNumber: new UntypedFormControl(),
    });

    this.data = [];
    this.popupObservable = new Observable((subscriber) => {
      this.popupObserver = subscriber;
      subscriber.next([]);
    });

    this.gridOptions = {
      domLayout: 'autoHeight',
      onGridReady: this.onGridReady,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      getRowId: (params) => params.data.id,
      defaultColDef: {
        resizable: true,
        width: 100,
      },
      pagination: true,
      paginationPageSize: 50,
      columnDefs: [
        {
          colId: 'checkbox',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: (params) => !this.readonly,
          headerCheckboxSelectionFilteredOnly: true,
          width: 50,
          cellStyle: (params) => {
            return this.readonly ? { 'pointer-events': 'none' } : null;
          },
        },
        { headerName: 'id', field: 'id' },
        { field: 'containerNumber' },
        { field: 'seal' },
        {
          headerName: 'Container Type',
          field: 'containerTypeId',
          valueFormatter: this.dataFormatter.gridContainerTypeFormatter(),
        },
        { field: 'status' },
        { headerName: 'Net Weight', valueGetter: this.netWeightGetter() },
        { headerName: 'Gross Weight', valueGetter: this.grossWeightGetter() },
      ],
      onSelectionChanged: this.onSelectionChange(),
      getContextMenuItems: getContextMenuItems(),
    };
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preselectedContainers']) {
      this.data = [];
      this.addExistingContainers(this.preselectedContainers);

      if (this.gridOptions.api) {
        this.gridOptions.onRowDataChanged = () => {
          this.gridOptions.api.selectAll();
          this.gridOptions.onRowDataChanged = null;
        };
      }
    }
  }

  addExistingContainers(containers: Container[]) {
    if (containers.length <= 0) return;
    if (this.data === null) this.data = [];
    this.data = containers.concat(this.data);
    if (this.popupObserver) {
      this.popupObserver.next(this.data);
    }
  }

  preselectItems(containers: Container[]) {
    this.addExistingContainers(containers);
  }

  //Arrow function to preserve this
  onGridReady = (event) => {
    if (!!this.popup) {
      this.gridOptions.api.setDomLayout('normal');
    }

    if (this.data) {
      for (let c of this.data) {
        this.gridOptions.api.getRowNode(`${c.id}`).selectThisNode(true);
      }
    }
    if (this.readonly) {
      this.gridOptions.columnApi.setColumnVisible('checkbox', false);
    }
  };

  clickSearch() {
    let filters: QueryFilters<Container> = {};

    if (this.searchForm.value.containerNumber) {
      filters.containerNumber = this.searchForm.value.containerNumber;
    }

    const containers$ = this.api.rpc<ListResponse<Container>>(endpoints.listUnlinkedContainers, { filters }, { count: 0, list: [] });
    let rId = this.spinnerService.startRequest(randomFetchSynonym() + ' Containers');
    containers$.pipe(map((res) => res.list)).subscribe((containers) => {
      this.spinnerService.completeRequest(rId);
      let selectedContainers: Container[];
      if (this.data !== null) {
        selectedContainers = this.gridOptions.api.getSelectedRows();
      }

      if (!!selectedContainers) {
        this.gridOptions.onRowDataChanged = () => {
          for (let container of selectedContainers) {
            const row = this.gridOptions.api.getRowNode(`${container.id}`);
            if (row) row.selectThisNode(true);
          }
          this.gridOptions.onRowDataChanged = null;
        };

        for (let container of selectedContainers) {
          if (!containers.some((c) => container.id === c.id)) {
            containers.unshift(container);
          }
        }
      }

      this.data = containers;
    });
  }

  onSelectionChange() {
    return (params: SelectionChangedEvent) => {
      if (!this.popup || this.readonly) return;
      let containers = this.gridOptions.api.getSelectedRows();
      this.popupObserver.next(containers);
    };
  }

  netWeightGetter() {
    return (params: ValueGetterParams) => {
      let data: Container = params.data;
      if (!data) return '';

      if (!data.shipments) return '';

      let totalNet = data.shipments
        .map((s) => s.netWeight)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);

      return totalNet;
    };
  }

  grossWeightGetter() {
    return (params: ValueGetterParams) => {
      let data: Container = params.data;
      if (!data) return '';

      if (!data.shipments) return '';

      let totalGross = data.shipments
        .map((s) => s.grossWeight)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);

      return totalGross;
    };
  }
}

export type ContainerFilterForm = Pick<Container, 'containerNumber'>;
