<kendo-combobox
  #dropdown
  [data]="dropdownOptions"
  [filterable]="true"
  [valueField]="dropdownConfig.valueField"
  [ngClass]="{ readonly: readonly }"
  [textField]="!!dropdownConfig.labelTransform ? 'label' : dropdownConfig.labelField"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [suggest]="true"
  (open)="onOpen()"
  [loading]="fetching"
  [readonly]="readonly"
  (blur)="onBlur()"
  (valueChange)="onValueChange(value)"
  (selectionChange)="onSelectionChange($event)"
>
  <ng-template *ngIf="!!dropdownConfig?.secondaryLabelFields" kendoComboBoxItemTemplate let-dataItem>
    <div class="mb-1">
      <div class="">{{ dataItem[dropdownConfig.labelField] }}</div>
      <div class="small">{{ dataItem['secondaryLabel'] }}</div>
    </div>
  </ng-template>
</kendo-combobox>
