import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RowClassArgs, SelectableSettings, SelectionChangeEvent, TreeListComponent } from '@progress/kendo-angular-treelist';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DriveItem } from 'microsoft-graph';
import { Observable, Observer, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromptService } from 'src/app/core/services/prompt.service';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SharePointService } from 'src/app/core/services/share-point-service.service';

@Component({
  selector: 'microsoft-file-browser',
  templateUrl: './microsoft-file-browser.component.html',
  styleUrls: ['./microsoft-file-browser.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MicrosoftFileBrowserComponent implements OnInit, SelectorComponent<DriveItem> {
  rootData: DriveItem[] = [];

  filteredData: DriveItem[] = [];

  loadMoreLink: string | null = null;

  public filter: CompositeFilterDescriptor;

  public selectionSettings: SelectableSettings;

  selectedFolder: DriveItem = null;

  @ViewChild(TreeListComponent)
  treeList: TreeListComponent;

  popup = true;

  popupObservable: Observable<DriveItem>;
  popupObserver: Observer<DriveItem>;

  constructor(private sharepoint: SharePointService, private prompt: PromptService) {
    this.fetchStartingData().subscribe((res) => {
      this.rootData = res;
      this.filteredData = this.getFilteredData();
    });

    this.selectionSettings = {
      mode: 'row',
      multiple: false,
      drag: false,
      enabled: true,
      readonly: false,
    };

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  ngOnInit(): void {}

  public getFilteredData(): DriveItem[] {
    return [...this.rootData];
  }

  hasChildren = (item: DriveItem) => {
    return !!item?.folder?.childCount;
  };

  fetchChildren = (item: DriveItem) => {
    if (!item.id) return of([]);
    return this.sharepoint.fetchChildren(item).pipe(
      map((res) => {
        return res;
      })
    );
  };

  fetchStartingData(forceUrl?: string) {
    return this.sharepoint.getRootFiles(forceUrl).pipe(
      map((res) => {
        if (res['@odata.nextLink']) {
          this.loadMoreLink = res['@odata.nextLink'];
        } else this.loadMoreLink = null;
        return res.value;
      })
    );
  }

  public loadMoreItems = (): Observable<any[]> => {
    if (!this.loadMoreLink) {
      return of([]);
    } else {
      return this.fetchStartingData(this.loadMoreLink);
    }
  };

  clickLoadMore() {
    this.loadMoreItems().subscribe((res) => {
      this.rootData = this.rootData.concat(...res);
      this.filteredData = this.getFilteredData();
    });
  }

  itemDoubleClicked(item: DriveItem) {
    if (!item) return;
    if (item.webUrl) {
      window.open(item.webUrl);
    }
  }

  itemClass(dataItem: DriveItem, isExpanded: boolean) {
    let classes = 'fas';
    if (dataItem.folder) {
      classes += isExpanded ? ' fa-folder-open' : ' fa-folder';
    } else if (dataItem.file) {
      if (dataItem.file.mimeType === 'application/pdf') classes += ' fa-file-pdf';
      else if (dataItem.file.mimeType === 'application/msword') classes += ' fa-file-word';
      else if (dataItem.file.mimeType?.includes('image/')) classes += ' fa-file-image';
      else classes += ' fa-file';
    }
    return classes;
  }

  selectionChanged(event: SelectionChangeEvent) {
    if (event.action === 'select' && event.items && event.items.every((i) => !!i.dataItem?.folder) && event.items.length === 1) {
      this.selectedFolder = event.items[0].dataItem;
    } else {
      this.selectedFolder = null;
    }
    if (this.popupObserver) {
      this.popupObserver.next(this.selectedFolder);
    }
  }

  public isSelected = (dataItem: DriveItem) => {
    return dataItem === this.selectedFolder;
  };

  public rowCallback = (context: RowClassArgs) => {
    return {
      folder: !!context.dataItem.folder,
      file: !context.dataItem.folder,
    };
  };

  createFolder() {
    if (!this.selectedFolder) return;

    this.prompt.textPrompt('New Folder', 'Name').subscribe((res) => {
      if (res) {
        this.sharepoint.createFolder(this.selectedFolder, res).subscribe((res) => {
          if (!!res) {
            this.treeList.reload(this.selectedFolder, true);
          }
        });
      }
    });
  }

  preselectItems(di: DriveItem) {
    //no
  }
}
