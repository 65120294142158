import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss'],
})
export class CollapsibleCardComponent {
  @Input()
  title: string;

  @Input()
  showExternalLinkIcon?: boolean = false;

  @Input()
  externalLink?: string;

  @Input()
  entityNumber?: number;

  @Input()
  set isOpen(v: boolean) {
    this._isOpen = v;
  }
  get isOpen() {
    return this._isOpen;
  }

  _isOpen: boolean = true;

  @Input()
  icon?: string;

  @Input()
  addLineButton?: boolean;

  public addLine: boolean = false;

  @Output()
  emitAddLine: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleCard(event: Event | null) {
    if (event) event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  addLineOption() {
    this.addLine = true;
    this.emitAddLine.emit(this.addLine);
    this.addLine = false;
  }

  openLink() {
    if (this.externalLink) {
      if (this.entityNumber) localStorage.setItem('entityNumber', JSON.stringify(this.entityNumber));
      window.open(this.externalLink);
    }
  }
}
