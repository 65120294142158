import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getToday, getTodayUTC } from 'src/lib/helperFunctions';
import { Booking, SourceEntityType, UpdateBookingRequest } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

export const bookingListPrefillCallback: prefillCallback<ListBookingsPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const bookingIds = Array.isArray(id) ? id : [id];
      const bookingResponses: Booking[] = [];
      for (const id of bookingIds) {
        bookingResponses.push(await api.run<Booking>(endpoints.getBooking, { filters: { id } }, null));
      }

      return { bookingResponses };
    })()
  );
};

const releaseBookingCheck: checkPrefillCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.bookingResponses.length === 0) return of('No bookings to be released.');
  return of(true);
};

const financeReleaseTodayForm: createFormCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  const bookings = Array.isArray(prefill.bookingResponses) ? prefill.bookingResponses : [prefill.bookingResponses];
  return [
    {
      type: 'Label',
      text: `Release Today - ${getToday().toLocaleDateString()}. Booking(s): ${prefill ? bookings.map((item) => item.number).join(', ') : 'Unknown'}`,
    },
  ];
};

const financeReleaseTodaySubmit: submitFormCallback<ListBookingsPrefill> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve, reject) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (const res of prefill.bookingResponses) {
          const request: UpdateBookingRequest = {
            id: res.id,
            financeReleaseDate: getTodayUTC(),
          };

          try {
            updateBookingResponses.push(await api.run<Booking>(endpoints.updateBooking, request, null));
          } catch (err) {
            return reject(err);
          }
        }
        return resolve(updateBookingResponses);
      })();
    })
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => item !== null);
          if (response.length > 0) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully released: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were released and try again if necessary.</div>`);
      })
  );
};

export const financeReleaseTodayPreset: DynamicFormConstant<ListBookingsPrefill> = {
  allowMultipleRows: true,
  value: DynamicFormType.FINANCE_RELEASE_TODAY,
  label: 'Finance Release Today',
  title: 'Release Today',
  endpoints: [endpoints.getBooking, endpoints.updateBooking],
  getPrefill: bookingListPrefillCallback,
  checkPrefill: releaseBookingCheck,
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  createForm: financeReleaseTodayForm,
  submitForm: financeReleaseTodaySubmit,
  width: 400,
};

const financeReleaseForm: createFormCallback<ListBookingsPrefill, Pick<Booking, 'financeReleaseDate'>> = (delegate, id, prefill) => {
  const bookings = Array.isArray(prefill.bookingResponses) ? prefill.bookingResponses : [prefill.bookingResponses];
  return [
    {
      type: 'Label',
      text: `Release Booking(s): ${prefill ? bookings.map((item) => item.number).join(', ') : 'Unknown'}`,
    },
    _fe('financeReleaseDate', 'Finance Release', 'Date', null, Validators.required, false),
  ];
};

const financeReleaseSubmit: submitFormCallback<ListBookingsPrefill, Pick<Booking, 'financeReleaseDate'>> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve, reject) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (const res of prefill.bookingResponses) {
          const request: UpdateBookingRequest = {
            id: res.id,
            financeReleaseDate: result.financeReleaseDate,
          };

          try {
            updateBookingResponses.push(await api.run<Booking>(endpoints.updateBooking, request, null));
          } catch (err) {
            return reject(err);
          }
        }
        return resolve(updateBookingResponses);
      })();
    })
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => item !== null);
          if (response.length > 0) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully released: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were released and try again if necessary.</div>`);
      })
  );
};

export const financeReleasePreset: DynamicFormConstant<ListBookingsPrefill, Pick<Booking, 'financeReleaseDate'>> = {
  allowMultipleRows: true,
  value: DynamicFormType.FINANCE_RELEASE,
  label: 'Finance Release Date',
  title: 'Release As Of',
  endpoints: [endpoints.getBooking, endpoints.updateBooking],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  getPrefill: bookingListPrefillCallback,
  checkPrefill: releaseBookingCheck,
  createForm: financeReleaseForm,
  submitForm: financeReleaseSubmit,
  width: 400,
};

const deleteFinanceReleaseForm: createFormCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  const bookings = Array.isArray(prefill.bookingResponses) ? prefill.bookingResponses : [prefill.bookingResponses];
  return [
    {
      type: 'Label',
      text: `Are you sure to Delete Finance Release Date from Booking(s): ${prefill ? bookings.map((item) => item.number).join(', ') : 'Unknown'}?`,
    },
  ];
};

const deleteFinanceReleaseSubmit: submitFormCallback<ListBookingsPrefill> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (const res of prefill.bookingResponses) {
          const request: UpdateBookingRequest = {
            id: res.id,
            financeReleaseDate: null,
          };

          try {
            updateBookingResponses.push(await api.run<Booking>(endpoints.updateBooking, request, null));
          } catch (err) {
            return resolve(err);
          }
        }
        return resolve(updateBookingResponses);
      })();
    })
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => item !== null);
          if (response.length > 0) {
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Finance Release Date successfully deleted. Booking(s): \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`
            );
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Finance Release Date was deleted and try again if necessary.</div>`);
      })
  );
};

export const deleteFinanceReleasePreset: DynamicFormConstant<ListBookingsPrefill> = {
  allowMultipleRows: true,
  value: DynamicFormType.DELETE_FINANCE_RELEASE,
  label: 'Delete Finance Release Date',
  title: 'Delete Finance Release Date',
  endpoints: [endpoints.getBooking, endpoints.updateBooking],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  getPrefill: bookingListPrefillCallback,
  checkPrefill: releaseBookingCheck,
  createForm: deleteFinanceReleaseForm,
  submitForm: deleteFinanceReleaseSubmit,
  width: 400,
};

export type ListBookingsPrefill = {
  bookingResponses: Booking[];
};
