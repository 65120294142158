import { PhysicalContract } from './contract';
import { PhysicalDraft } from './draft';
import { YN } from './enums';
import { Booking } from './freightBooking';
import { FutureMarket } from './futureMarket';
import { MarketValuationHeader } from './marketValuation';
import { PricePrecision } from './pricePrecision';
import { Product } from './product';
import { PropertyDocument } from './propertyDocument';
import { ThalosLetterOfCredit } from './thalosLetterOfCredit';
import { WeightTicket } from './weightTicket';

export enum CommonUnits {
  MT = 100093,
  KG = 100095,
  LB = 100145,
  EA = 113606,
}
export const CommonUnitsNameMap: { [key in CommonUnits]: string } = {
  [CommonUnits.MT]: 'MT',
  [CommonUnits.KG]: 'KG',
  [CommonUnits.LB]: 'LB',
  [CommonUnits.EA]: 'EA',
};

/**
 * UNIT_HEADER in backend
 */
export type Unit = {
  unitId: number;
  code: string | null;
  name: string | null;
  indivisible: YN | null;
  precision: number;
  unitFactors?: UnitInfo[];
  futureMarkets?: FutureMarket[];
  shipmentsAsQuantityUnit?: PropertyDocument[];
  weightTickets?: WeightTicket[];
  contracts?: PhysicalContract[];
  drafts?: PhysicalDraft[];
  draftsAsPriceUnit?: PhysicalDraft[];
  draftsAsPremiumUnit?: PhysicalDraft[];
  bookings?: Booking[];
  pricePrecisions?: PricePrecision[];
  locAsQuantityUnit?: ThalosLetterOfCredit[];
};

export type UnitInfo = {
  unitId: number;
  productId: number;
  factor: number;
  defaultUnit: string | null;
  unitHeader?: Unit;
  product?: Product;
  contractsAsPriceUnitFactor?: PhysicalContract[];
  contractsAsQuantityUnitFactor?: PhysicalContract[];
  drafts?: PhysicalDraft[];
  shipmentsAsQuanityUnitFactor?: PropertyDocument[];
  shipmentsAsBuyPriceUnitFactor?: PropertyDocument[];
  shipmentsAsSalePriceUnitFactor?: PropertyDocument[];
  marketValuationHeaders?: MarketValuationHeader[];
};

export type CreateUnitRequest = Pick<Unit, 'indivisible' | 'code' | 'name'>;
export type UpdateUnitRequest = Pick<Unit, 'unitId'> & Partial<CreateUnitRequest>;
