import { round } from 'lodash';
import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import _ from 'lodash';
import { ReconcileJournalEntriesPrefill, ReconcileJournalsRequest, reconcileJournalEntriesPrefill } from './reconcileJournalEntries';
import { JournalReconciliation } from 'src/lib/newBackendTypes/journalReconciliation';

const unreconcileJournalEntriesCheck: checkPrefillCallback<ReconcileJournalEntriesPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (!(prefill.items && prefill.items.length > 1)) return of('Unable to un-reconcile journals: entries selected should be more than 1.');

  const entryIdsToMatch = new Set(prefill.items);
  const firstEntry = prefill.items[0];

  if (prefill.items.length !== entryIdsToMatch.size) return of('Unable to un-reconcile journals: some entries are not available.');

  const accountId = firstEntry.accountId;
  let amountSum = 0;

  for (const journalEntry of prefill.items) {
    if (journalEntry.accountId !== accountId) return of('Unable to un-reconcile journals: all entries to match should belong to the same account.');

    const amount = journalEntry.amount * -1;
    amountSum += amount;
  }

  if (round(amountSum, 2) !== 0) return of('Unable to un-reconcile journals: the sum of the amounts in the entries to match should be zero.');

  return of(true);
};

const unreconcileJournalEntriesForm: createFormCallback<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Are you sure you want to un-reconcile Journal Entries: ${prefill.items ? prefill.items.map((item) => `${item.journalNumber} - ${item.entryPosition}`).join(', ') : 'Unknown'}`,
    },
  ];
};

const unreconcileJournalEntriesCallback: submitFormCallback<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<JournalReconciliation[] | null>((resolve, reject) => {
      (async () => {
        const journalEntryIds: number[] = [];
        const matchableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
        for (const entry of matchableItems) {
          journalEntryIds.push(entry.journalEntryId);
        }

        const request: ReconcileJournalsRequest = {
          title: result.title,
          journalEntryIds,
        };
        try {
          const JournalUnreconciliation = await api.run<JournalReconciliation[]>(endpoints.unreconcileJournalEntries, request, null);
          return resolve(JournalUnreconciliation);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (!!res && Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => !_.isNil(item));
          if (response.length > 0) return prompt.htmlDialog('Success', `<div style="white-space: pre">Journals successfully un-reconciled: ${response.length} line(s).</div>`);
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the journals were un-reconciled and try again if necessary.</div>`);
      })
  );
};

export const unreconcileJournalEntriesPreset: DynamicFormConstant<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = {
  allowMultipleRows: true,
  checkPrefill: unreconcileJournalEntriesCheck,
  createForm: unreconcileJournalEntriesForm,
  entityType: SourceEntityType.JOURNAL_ENTRY_ID,
  getPrefill: reconcileJournalEntriesPrefill,
  label: 'Un-reconcile Journal Entries',
  submitForm: unreconcileJournalEntriesCallback,
  title: 'Un-reconcile Journal Entries',
  value: DynamicFormType.DELETE_JOURNAL_RECONCILIATION,
  endpoints: [endpoints.unreconcileJournalEntries],
  width: 600,
};
