export class Evidence {
  id: number;
  discrepancyId: number;
  fileName: string;
  fileTypeName: string;
  uploadDate: Date;
  notes: string;
}

export class CreateEvidenceRequest {
  discrepancyId: number;
  fileName: string;
  fileTypeName: string;
  uploadDate: Date;
  notes: string;
}

export class UpdateEvidenceRequest {
  id: number;
  fileName?: string;
  fileTypeName?: string;
  uploadDate?: Date;
  notes?: string;
}
