<div class="table-wrapper" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)" *ngIf="!loadingDocuments" (dragleave)="dragLeaveHandler()">
  <div class="drag-overlay" [ngClass]="{ 'file-hover': fileHover }" *ngIf="uploadAuthorized"></div>
  <form-table [title]="'Documents'" [formArray]="documentFormArray" [noDataMessage]="uploadAuthorized ? 'Drag Files Here' : 'No Documents Found'">
    <ng-template #tableHeader>
      <div class="col-form-label col-form-label-sm col-lg-2 col-md-3">Type</div>
      <div class="col-form-label col-form-label-sm col-xl-2 col-lg-3 col-md-4">File</div>
      <div class="col-form-label col-form-label-sm col-xl-2 col-lg-3 col-md-5">Description</div>
      <div class="col-form-label col-form-label-sm col-lg-1 col-md-2">Upload Date</div>
      <div class="col-form-label col-form-label-sm col-lg-1 col-md-2">Actions</div>
    </ng-template>
    <ng-template #tableRow let-row="element" let-i="i">
      <table-field class="col-lg-2 col-md-3" name="Type" [required]="true">
        <simple-dropdown
          [readonly]="(!updateAuthorized && !!row.value?.id) || (!row.value?.id && !uploadAuthorized) || (row.get('storageType').value !== 2 && !!row.get('storageType').value)"
          [formControl]="row.get('documentType')"
          [data]="documentTypes"
          [ignoreReadonlyMode]="true"
        >
        </simple-dropdown>
      </table-field>
      <table-field class="col-xl-2 col-lg-3 col-md-4" name="File">
        <ng-container *ngIf="row.get('storageType').value === 2 || !row.get('storageType').value; else filesystemTemplate">
          <input-wrapper style="display: none" [formControl]="row.get('fileName')" [readonly]="true" [required]="true"></input-wrapper>
          <div *ngIf="!!row.value.id && !!row.value.file">{{ row.value.file.name }}*</div>
          <div *ngIf="!!row.value.id && !row.value.file" class="file-download" (click)="clickDownload(row.value.fileName || 'download', row.value.id)">
            <span *ngIf="!downloadAuthorized">{{ row.value?.fileName }}</span>
            <a *ngIf="downloadAuthorized">{{ row.value?.fileName }}</a>
          </div>
          <div *ngIf="!row.value.id && !row.value.file" class="file-download" (click)="rowFileSelect.clickSelectFile()">
            <a>Select File</a>
          </div>
          <div *ngIf="!row.value.id && !!row.value.file" style="cursor: text">{{ row.value.fileName }}*</div>
        </ng-container>
        <ng-template #filesystemTemplate>
          <div [ngClass]="{ 'file-download': isWindows }">
            <a (click)="gotoFilesystemFile(row.value)">{{ row.value.fsPath }}</a>
          </div>
        </ng-template>
      </table-field>
      <table-field class="col-xl-2 col-lg-3 col-md-5" name="Description">
        <input-wrapper
          [ignoreReadonlyMode]="true"
          [formControl]="row.get('comments')"
          [readonly]="(!updateAuthorized && !!row.value?.id) || (!row.value?.id && !uploadAuthorized) || (row.get('storageType').value !== 2 && !!row.get('storageType').value)"
        ></input-wrapper>
      </table-field>
      <table-field class="col-lg-1 col-md-1" name="Upload Date">
        {{ row.get('documentDate').value }}
      </table-field>
      <table-field class="col-lg-2 col-md-3" name="Actions">
        <ng-container *ngIf="row.get('storageType').value === 2 || !row.get('storageType').value">
          <button *ngIf="!!uploadAuthorized && ((!!row.value.id && !row.value.file) || (!row.value.id && !!row.value.file))" type="button" kendoButton (click)="rowFileSelect.clickSelectFile()">
            Replace
          </button>
          <button *ngIf="!!row.value.id && !!row.value.file" type="button" kendoButton (click)="removeNewDocument(row)">Cancel Replace</button>
          <document-version-menu *ngIf="row.value.id && !!versionsAuthorized" [id]="row.value.id" (downloadVersion)="clickDownload(row.value.fileName || 'download', row.value.id, $event)">
          </document-version-menu>
          <button type="button" icon="trash" kendoButton (click)="clickDelete(i)" *ngIf="!row.value?.id" title="Delete"></button>
          <button type="button" icon="unlink-horizontal" kendoButton (click)="clickUnlink(i)" *ngIf="unlinkAuthorized && entityId && !!row.value.id" title="Unlink"></button>
          <button [icon]="'edit'" kendoButton type="button" (click)="openFileNameEdit(row)" *ngIf="!!row.value.file || (!!row.value.id && updateNameAuthorized)" title="Edit filename"></button>
          <file-select-widget style="display: none" [showButton]="false" (fileSelected)="fileSelectedRow($event, row)" #rowFileSelect></file-select-widget>
        </ng-container>
        <ng-container *ngIf="row.get('storageType').value !== 2 && !!row.get('storageType').value">
          <button type="button" icon="trash" kendoButton (click)="clickDelete(i)" *ngIf="!row.value?.id"></button>
          <button type="button" icon="unlink-horizontal" kendoButton (click)="clickUnlink(i)" *ngIf="unlinkAuthorized && entityId && !!row.value.id"></button>
        </ng-container>
      </table-field>
    </ng-template>
    <ng-template #tableFooter>
      <button kendoButton type="button" (click)="clickPrintDocument()" *ngIf="generateDocAuthorized">Print Document</button>
      <button kendoButton type="button" (click)="clickAddDocument()" *ngIf="uploadAuthorized">Add Document</button>
      <button
        kendoButton
        type="button"
        (click)="clickSaveChanges()"
        *ngIf="entityId && (uploadAuthorized || updateAuthorized) && _readonlyMode && documentFormArray.dirty && !!documentFormArray.controls?.length"
      >
        Save Changes
      </button>
    </ng-template>
  </form-table>
</div>
<file-select-widget [showButton]="false" (fileSelected)="fileSelected($event)" #fileSelect></file-select-widget>
