import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { DeletePriceFixationForm, DeletePriceFixationPrefill } from 'src/lib/flex/forms/deletePriceFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-delete-price-fixation',
  templateUrl: './delete-price-fixation.component.html',
})
export class DeletePriceFixationComponent implements ModalFormComponent<DeletePriceFixationForm> {
  form: TypedFormGroup<DeletePriceFixationForm>;
  popup = true;

  contractNumber: number | null;
  elementNumber: number | null;

  constructor() {
    this.form = new TypedFormGroup<DeletePriceFixationForm>({
      id: new UntypedFormControl(null, Validators.required),
    });
  }

  prefillForm(data: DeletePriceFixationPrefill) {
    this.contractNumber = data.contractLine.contract.number;
    this.elementNumber = data.elementNumber;
    this.form.patchValue(data);
  }

  allowSubmit() {
    return this.form.valid;
  }

  submit(): DeletePriceFixationForm {
    const formVal = this.form.value;
    return {
      id: formVal.id,
    };
  }
}
