import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'file-select-widget',
  templateUrl: './file-select-widget.component.html',
})
export class FileSelectWidgetComponent implements OnInit {
  @ViewChild('file', { static: false })
  fileInput: any;

  @Output()
  fileSelected: EventEmitter<File[]> = new EventEmitter();

  @Input()
  showButton: boolean = true;

  @Input()
  allowMultiple: boolean = true;

  constructor() {}

  ngOnInit() {}

  onFileChange(event) {
    if (event.srcElement.files && event.srcElement.files.length > 0) {
      let files: File[] = event.srcElement.files;

      this.fileSelected.emit(files);
    } else {
      this.fileSelected.emit(null);
    }
  }

  public clickSelectFile() {
    this.fileInput.nativeElement.click();
  }
}
