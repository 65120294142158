import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { intFormat } from 'src/lib/commonTypes';
import { earlierThanValidator } from 'src/lib/genericValidators';
import { getTodayUTC, getTomorrowUTC, markFormGroupTouched } from 'src/lib/helperFunctions';
import { ChunkType, ChunkTypes, CommonPackingTypes, PropertyDocument, ShipmentPacking } from 'src/lib/newBackendTypes';
import { TypedFormArray, TypedFormControl, TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'shipment-merge',
  templateUrl: './shipment-merge.component.html',
  styleUrls: ['./shipment-merge.component.scss'],
})
export class ShipmentMergeComponent implements OnInit, OnDestroy, ModalFormComponent<MergeShipmentForm> {
  form: TypedFormGroup<MergeShipmentForm>;
  popup = true;

  @Input()
  sourceShipment: PropertyDocument;

  chunkTypes = ChunkTypes;
  intFormat = intFormat();

  constructor(protected commonData: CommonDataService) {
    this.form = new TypedFormGroup<MergeShipmentForm>({
      linkChildShipment: new UntypedFormControl(false),
      valueDate: new TypedFormControl<Date>(getTodayUTC(), [Validators.required, earlierThanValidator(getTomorrowUTC(), `Date must be today or earlier`)]),
      packingTypes: new TypedFormArray<Omit<ShipmentPacking, 'shipmentId'>>([]),
      status: new TypedFormControl<ChunkType>(null, [Validators.required]),
    });

    this.addPacking();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  prefillForm(val: MergeShipmentForm) {
    (this.form.get('packingTypes') as UntypedFormArray).clear();
    this.form.reset();

    for (let _ in val.packingTypes) {
      this.addPacking();
    }
    if (!val.packingTypes || val.packingTypes.length === 0) {
      this.addPacking();
    }

    this.form.patchValue(val);
  }

  allowSubmit() {
    this.markAsTouched();

    if (!this.form.valid) return false;

    return this.form.valid;
  }

  submit() {
    return this.form.value;
  }

  addPacking() {
    let fg = new TypedFormGroup<Omit<ShipmentPacking, 'shipmentId'>>({
      packingTypeId: new TypedFormControl<number>(),
      quantity: new TypedFormControl<number>(),
    });

    fg.get('packingTypeId').setValidators([this.packingValidator(fg), this.duplicatePackingTypeValidator()]);
    fg.get('quantity').setValidators(this.packingValidator(fg));

    (this.form.get('packingTypes') as UntypedFormArray).push(fg);
  }

  removePacking(i: number) {
    let arr = <UntypedFormArray>this.form.get('packingTypes');
    arr.removeAt(i);
  }

  packingValidator(fg: TypedFormGroup<ShipmentPacking>) {
    return (control) => {
      let i = (this.form.get('packingTypes') as UntypedFormArray).controls.indexOf(fg);
      if (i === 0 || !!fg.value.packingTypeId || (fg.value.quantity !== null && fg.value.quantity !== undefined)) return Validators.required(control);
      return null;
    };
  }

  duplicatePackingTypeValidator() {
    return (control: AbstractControl) => {
      let packingId: number = control.value;

      let matching = this.form.value.packingTypes.filter((pt) => pt.packingTypeId === packingId);
      return matching.length > 1 ? { custom: 'Duplicate packing types' } : null;
    };
  }

  markAsTouched() {
    markFormGroupTouched(this.form);
  }

  get packingTypesFormArray() {
    return this.form.get('packingTypes') as TypedFormArray<Omit<ShipmentPacking, 'shipmentId'>>;
  }
}

export type MergeShipmentForm = Pick<PropertyDocument, 'status' | 'valueDate'> & {
  linkChildShipment?: boolean;
  packingTypes: ShipmentPacking[];
};

export function sumPackingTypes(shipments: PropertyDocument[]) {
  let newPackingTypes: ShipmentPacking[] = [];

  for (let s of shipments) {
    if (!s.packingTypes) continue;

    for (let pt of s.packingTypes) {
      let existing = newPackingTypes.find((match) => match.packingTypeId === pt.packingTypeId);
      if (!!existing) {
        if (pt.packingTypeId === CommonPackingTypes.LOOSE) continue;
        existing.quantity += pt.quantity;
      } else {
        newPackingTypes.push({ packingTypeId: pt.packingTypeId, quantity: pt.quantity });
      }
    }
  }

  return newPackingTypes;
}
