import { filter, isArray } from 'lodash';
('use strict');

var __find = function (value, items, data, options) {
  var found;
  var searchField = options && options.searchField ? options.searchField : 'key'; //link
  items = isArray(items) ? items : [items];
  for (var i = 0; i < items.length; i++) {
    if (items[i][searchField] && items[i][searchField].test(value)) {
      return items[i];
    } else {
      var fields = [];
      if (options && options.children) {
        fields = isArray(options.children) ? options.children : [options.children];
      } else {
        fields = filter(Object.keys(items[i]), function (o) {
          return isArray(items[i][o]);
        });
      }
      for (var j = 0; j < fields.length; j++) {
        if (items[i][fields[j]]) {
          found = __find(value, items[i][fields[j]], data, options);
          if (found) {
            if (data) {
              data.push(found);
            } else {
              return found;
            }
          }
        }
      }
    }
  }
};

export const deepFind = function (value, items, options?: { searchField?: string; children?: any }) {
  try {
    return __find(value, items, null, options);
  } catch (err) {
    console.log(err);
  }
};
