import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { PropertyDocument } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getPropertyDocumentListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<PropertyDocument> = {
    where: { id: { not: { equals: 0 } }, documentName: { contains: new VariableType('documentName') } },
    orderBy: [{ id: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getPropertyDocumentListVariablesGraphql() {
  const variables: GraphqlVariables<PropertyDocument> = { documentName: 'String' };
  return variables;
}

export const listPropertyDocumentGraphqlRequest = (args: GraphqlRequest<PropertyDocument>): GraphqlArgsRequest<PropertyDocument> => {
  return {
    __aliasFor: ExistingGraphql.propertyDocuments,
    __args: args,
    id: true,
    documentName: true,
  };
};
