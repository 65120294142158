import { from } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { BaseDiscrepancy, Discrepancy, DiscrepancyListView, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { Subset } from 'src/lib/generics';
import { LinkUnlinkDiscrepanciesComponent } from 'src/app/+modules/+quality-control/containers/discrepancy/link-unlink-discrepancies/link-unlink-discrepancies.component';
import { ListResponse } from 'src/lib/ListResponse';

const linkUnlinkDiscrepanciesPrefill: prefillCallback<Discrepancy> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const discrepancy = await api.run<BaseDiscrepancy>(endpoints.getDiscrepancy, { filters: { id } }, null);
      const discrepancyList =
        discrepancy.relatedDiscrepancies.length > 0
          ? (await api.run<ListResponse<DiscrepancyListView>>(endpoints.listDiscrepancies, { filters: { discrepancyId: discrepancy.relatedDiscrepancies.map((item) => item.id) } }, null)).list
          : [];
      return {
        ...discrepancy,
        relatedDiscrepancies:
          discrepancy.relatedDiscrepancies.length > 0
            ? discrepancy.relatedDiscrepancies.map((item) => {
                return {
                  ...item,
                  discrepancyId: item.id,
                  discrepancyType: item.type,
                  freightBooking: discrepancyList.find((dc) => dc.discrepancyId === item.id).freightBooking,
                };
              })
            : [],
      };
    })()
  );
};

const linkUnlinkDiscrepanciesForm: openFormCallback<BaseDiscrepancy, DiscrepanciesToCreateAndDelete> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<DiscrepanciesToCreateAndDelete, LinkUnlinkDiscrepanciesComponent, BaseDiscrepancy>(LinkUnlinkDiscrepanciesComponent, {
    title: `Link/Unlink Discrepancies: ${prefill.id}`,
    initializer: (c) => {
      c.exisitingRelatedDiscrepancies = prefill.relatedDiscrepancies;
    },
    prefillValue: prefill,
    maxWidth: 500,
  });
};

const linkUnlinkDiscrepanciesSubmit: submitFormCallback<Discrepancy, DiscrepanciesToCreateAndDelete> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  const spinner = delegate.getService('spinner');

  return from(
    new Promise<{ linkedDiscrepancy: BaseDiscrepancy; unlinkedDiscrepancy: BaseDiscrepancy } & DiscrepanciesToCreateAndDelete>(async (resolve, reject) => {
      let linkedDiscrepancy: BaseDiscrepancy | null = null;
      if (form.toCreate.relatedDiscrepanciesIds.length > 0) {
        const rid = spinner.startRequest('Linking discrepancies');
        linkedDiscrepancy = await api.run<BaseDiscrepancy>(endpoints.linkDiscrepancy, form.toCreate, null);
        spinner.completeRequest(rid);
      }

      let unlinkedDiscrepancy: BaseDiscrepancy | null = null;
      if (form.toDelete.relatedDiscrepanciesIds.length > 0) {
        const rid = spinner.startRequest('Unlinking discrepancies');
        unlinkedDiscrepancy = await api.run<BaseDiscrepancy>(endpoints.unlinkDiscrepancy, form.toDelete, null);
        spinner.completeRequest(rid);
      }
      resolve({ linkedDiscrepancy, unlinkedDiscrepancy, toCreate: form.toCreate, toDelete: form.toDelete });
      return reject('Unknown result. Please check if the Discrepancies were link/unlink and try again if necessary.');
    })
      .then((res) => {
        const linkedDiscrepancies = res.toCreate.relatedDiscrepanciesIds.map((so) => `- ${so}`).join(`\n`);
        const unlinkedDiscrepancies = res.toDelete.relatedDiscrepanciesIds.map((so) => `- ${so}`).join(`\n`);
        if (res.linkedDiscrepancy && res.unlinkedDiscrepancy)
          return prompt.htmlDialog('Success', `<div style="white-space: pre">Discrepancies linked:\n${linkedDiscrepancies}\nDiscrepancies unlinked:\n${unlinkedDiscrepancies}</div>`);
        if (res.linkedDiscrepancy && !res.unlinkedDiscrepancy) return prompt.htmlDialog('Success', `<div style="white-space: pre">Discrepancies linked:\n${linkedDiscrepancies}</div>`);
        if (!res.linkedDiscrepancy && res.unlinkedDiscrepancy) return prompt.htmlDialog('Success', `<div style="white-space: pre">Discrepancies unlinked:\n${unlinkedDiscrepancies}</div>`);
      })
      .catch((err) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
      })
  );
};

export const linkUnlinkDiscrepanciesPreset: DynamicFormConstant<BaseDiscrepancy, DiscrepanciesToCreateAndDelete> = {
  value: DynamicFormType.LINK_UNLINK_DISCREPANCIES,
  label: 'Link/Unlink Discrepancies',
  endpoints: [endpoints.getDiscrepancy, endpoints.listDiscrepancies, endpoints.linkDiscrepancy, endpoints.unlinkDiscrepancy],
  entityType: SourceEntityType.DISCREPANCY_ID,
  title: 'Link/Unlink Discrepancies',
  width: 300,
  allowMultipleRows: false,
  getPrefill: linkUnlinkDiscrepanciesPrefill,
  openForm: linkUnlinkDiscrepanciesForm,
  submitForm: linkUnlinkDiscrepanciesSubmit,
};

export type LinkUnlinkDiscrepancyRequest = Subset<BaseDiscrepancy, 'id'> & {
  relatedDiscrepanciesIds: number[];
};

export type DiscrepanciesToCreateAndDelete = { toCreate: LinkUnlinkDiscrepancyRequest; toDelete: LinkUnlinkDiscrepancyRequest };
