<div class="horizontal-form-field form-group row">
  <label class="col-lg-6 col-form-label col-form-label-sm d-flex justify-content-lg-end col-form-label-offset" [ngStyle]="{ visibility: !!noLabel ? 'hidden' : 'visible' }">
    {{ name }}<span *ngIf="required">*</span>
  </label>
  <div class="col-lg-6 d-flex field-content-container" style="flex-grow: 1">
    <ng-content></ng-content>
  </div>
  <div *ngIf="controlTouched" cyData="errorLabel" class="col-lg-6 offset-lg-6 error-label" [ngClass]="{ 'active-error': !!errorMessage }">
    {{ errorMessage }}
  </div>
</div>
