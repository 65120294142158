import { EnumLabels, Subset } from '../generics';
import { Contact } from './contact';

export enum ContactMethodType {
  FAX = 1,
  TELEX = 2,
  EMAIL = 3,
  PHONE = 10,
  IM = 11,
  URL = 12,
  MOBILE = 14,
  PCE = 15,
  OTHER = 99,
}

export const ContactMethodTypes: EnumLabels<ContactMethodType> = [
  { value: ContactMethodType.FAX, label: 'Fax' },
  { value: ContactMethodType.TELEX, label: 'Payment Conf Email' },
  { value: ContactMethodType.EMAIL, label: 'Email' },
  { value: ContactMethodType.PHONE, label: 'Phone' },
  { value: ContactMethodType.IM, label: 'Booking Cutoff Email' },
  { value: ContactMethodType.URL, label: 'Website' },
  { value: ContactMethodType.MOBILE, label: 'Mobile' },
  { value: ContactMethodType.PCE, label: 'Pricing Conf Email' },
  { value: ContactMethodType.OTHER, label: 'Other' },
];

export enum ContactMethodTypeLabel {
  FAX = 'FAX',
  TELEX = 'TLX',
  EMAIL = 'EML',
  PHONE = 'PHO',
  IM = 'IM',
  URL = 'URL',
  MOBILE = 'MOB',
  PCE = 'PCE',
  OTHER = 'OTH',
}

export const ContactMethodLabelMap: { [key in ContactMethodType]: ContactMethodTypeLabel } = {
  [ContactMethodType.FAX]: ContactMethodTypeLabel.FAX,
  [ContactMethodType.TELEX]: ContactMethodTypeLabel.TELEX,
  [ContactMethodType.EMAIL]: ContactMethodTypeLabel.EMAIL,
  [ContactMethodType.PHONE]: ContactMethodTypeLabel.PHONE,
  [ContactMethodType.IM]: ContactMethodTypeLabel.IM,
  [ContactMethodType.URL]: ContactMethodTypeLabel.URL,
  [ContactMethodType.MOBILE]: ContactMethodTypeLabel.MOBILE,
  [ContactMethodType.PCE]: ContactMethodTypeLabel.PCE,
  [ContactMethodType.OTHER]: ContactMethodTypeLabel.OTHER,
};

export type ContactMethod = {
  id: number;
  contactId: number;
  number: number;
  type: ContactMethodType;
  typeLabel: ContactMethodTypeLabel;
  value: string;
  name: string;
  position: string;
  nbValueStd: string | null;
  isPrivate: 'Y' | 'N';
  isDefault: 'Y' | 'N';
  addressKey: number | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  contact?: Contact;
};

export type CreateContactMethodRequest = Subset<ContactMethod, 'contactId' | 'name' | 'value' | 'position' | 'type'>;

export type UpdateContactMethodRequest = Subset<ContactMethod, 'id', 'name' | 'value' | 'position' | 'type'>;
