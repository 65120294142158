import { EnumLabels, Subset } from '../generics';
import { AccIntegrationByOriginLine } from './accIntegrationByOriginLine';
import { YN } from './enums';
import { FiscalCompany } from './fiscalCompany';
import { Impute } from './impute';
import { InvoiceHeader } from './invoice';
import { InvoiceLine } from './invoiceLine';
import { JournalEntry } from './journal';
import { PaymentEntry, PaymentHeader } from './payment';
import { PayOrderLine } from './payOrder';
import { Contact } from './contact';
import { GlStructures, GlStructuresDetails } from './glStructures';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import _ from 'lodash';
import { Product } from './product';

export enum AccountType {
  ASSET = 1,
  LIABILITY = 2,
  EXPENSE = 3,
  REVENUE = 4,
  RESULT = 5,
}

export const AccountTypes: EnumLabels<AccountType> = [
  { label: 'Asset', value: 1 },
  { label: 'Liability', value: 2 },
  { label: 'Result', value: 5 },
  { label: 'Revenue', value: 4 },
  { label: 'Expense', value: 3 },
];

export enum AccountOption {
  TREASURY = 1,
  EXCHANGE_DIFFERENCE = 2,
  SUB_PROFIT_LOSS = 3,
  PROFIT_LOSS = 4,
  PRIVATE = 5,
  NET_INCOME = 6,
  RETAINED_EARNINGS = 7,
  CAPITAL = 8,
  VAT = 9,
  CLIENT_ACCOUNT = 10,
  SUPPLIER_ACCOUNT = 11,
  INTEREST = 12,
}

export const AccountOptions: EnumLabels<AccountOption> = [
  { label: 'Treasury', value: 1 },
  { label: 'Exchange Difference', value: 2 },
  { label: 'Sub Profit Loss', value: 3 },
  { label: 'Profit Loss', value: 4 },
  { label: 'Private', value: 5 },
  { label: 'Net Income', value: 6 },
  { label: 'Retained Earnings', value: 7 },
  { label: 'Capital', value: 8 },
  { label: 'VAT', value: 9 },
  { label: 'Client Account', value: 10 },
  { label: 'Supplier Account', value: 11 },
  { label: 'Interest', value: 12 },
];

/**
 * @deprecated
 */
export class GlAccountView {
  /**
   * @deprecated
   */
  accKey: number;
  /**
   * @deprecated
   */
  companyId: number;
  /**
   * @deprecated
   */
  accCode: string;
  /**
   * @deprecated
   */
  currKey: number;
  /**
   * @deprecated
   */
  accAbbr: string;
  /**
   * @deprecated
   */
  accName: string;
  /**
   * @deprecated
   */
  accType: AccountType;
  /**
   * @deprecated
   */
  accOption: AccountOption | null;
  /**
   * @deprecated
   */
  glAccountsFreeTypeKey: number | null;
  /**
   * @deprecated
   */
  accChangeSignum: string | null;
  /**
   * @deprecated
   */
  historicalRate: YN | null;
  /**
   * @deprecated
   */
  consolidationDetails: string | null;
  /**
   * @deprecated
   */
  busInfoRequired: string | null;
  /**
   * @deprecated
   */
  noManualEntry: YN | null;
  /**
   * @deprecated
   */
  accExchDiff: number | null;
  /**
   * @deprecated
   */
  cpnTplKey: number | null;
  /**
   * @deprecated
   */
  alfCode: number | null;
  /**
   * @deprecated
   */
  interestKey: number | null;
  /**
   * @deprecated
   */
  interestAccKey: number | null;
  /**
   * @deprecated
   */
  iden: string | null;
  /**
   * @deprecated
   */
  idenLong: string | null;
  /**
   * @deprecated
   */
  isAuto: YN | null;
  /**
   * @deprecated
   */
  lastOperationType: number | null;
  /**
   * @deprecated
   */
  tplKey: number | null;
  /**
   * @deprecated
   */
  interestTplKey: number | null;
  /**
   * @deprecated
   */
  versionNb: number;
  /**
   * @deprecated
   */
  modifiedBy: string | null;
  /**
   * @deprecated
   */
  modifyDate: Date | null;
  /**
   * @deprecated
   */
  parentAccKey: number | null;
  /**
   * @deprecated
   */
  archived: YN;
  /**
   * @deprecated
   */
  leafAccount: YN;
  /**
   * @deprecated
   */
  productId: number | null;
}

export class GlAccountLeaves {
  id!: number;
  companyId!: number;
  accountCode!: string;
  currencyId!: number;
  accountName!: string | null;
  accountType!: AccountType | null;
  accountOption!: AccountOption | null;
  idenLong!: string | null;
  version!: number | null;
  modifiedBy!: string | null;
  modifyDate!: Date | null;
  archived!: YN;
  productId!: number | null;
  product?: Product;
  journalEntries?: JournalEntry[];
  integrationEntries?: AccIntegrationByOriginLine[];
  vouchers?: InvoiceHeader[];
  parentBranches?: GlStructuresDetails[];
  contactsAsReceivablesAccount?: Contact[];
  contactsAsPayablesAccount?: Contact[];
  invoiceLines?: InvoiceLine[];
  imputes?: Impute[];
  paymentHeaders?: PaymentHeader[];
  paymentEntries?: PaymentEntry[];
  fiscalCompany?: FiscalCompany;
  payOrderLines?: PayOrderLine[];
  parentAccKey?: number;
  structures?: UpdateParentsStructure[];
  isFxRevalNeeded?: YN;
}

export class GlAccountBranches {
  id!: number;
  structureId!: number;
  companyId!: number;
  accountCode!: string;
  currencyId!: number;
  accountName!: string | null;
  accountType!: AccountType | null;
  accountOption!: AccountOption | null;
  idenLong!: string | null;
  version!: number | null;
  modifiedBy!: string | null;
  modifyDate!: Date | null;
  archived!: YN;
  productId!: number | null;
  parentBranchId!: number | null;
  parentBranch?: GlAccountBranches | null;
  childBranch?: GlAccountBranches[];
  structure?: GlStructures;
  branchDetails?: GlStructuresDetails[];
  fiscalCompany?: FiscalCompany;
  product?: Product;
  parentAccKey?: number;
  structures?: UpdateParentsStructure[];
}

export type BaseCreateGLAccountRequest = Subset<GlAccountLeaves, 'companyId' | 'accountCode' | 'currencyId' | 'accountName' | 'accountType' | 'accountOption' | 'productId'>;

export type BaseUpdateGLAccountRequest = Subset<GlAccountLeaves, 'id', 'companyId' | 'accountCode' | 'currencyId' | 'accountName' | 'accountType' | 'accountOption' | 'archived' | 'productId'>;

export type CreateGLAccountLeafRequest = BaseCreateGLAccountRequest & {
  isFxRevalNeeded: YN;
  structures: UpdateParentsStructure[];
};
export type CreateGLAccountBranchRequest = BaseCreateGLAccountRequest & {
  structures: UpdateParentsStructure[];
};

export type UpdateGLAccountLeafRequest = BaseUpdateGLAccountRequest & {
  isFxRevalNeeded: YN;
  structures: UpdateParentsStructure[];
};

export type UpdateParentsStructure = {
  structureId: number;
  parentBranchId: number;
  structureName?: string;
  parentAccount?: GlAccountBranches;
  accountCode?: string;
};

export type UpdateGLAccountBranchRequest = BaseUpdateGLAccountRequest & {
  structures: UpdateParentsStructure[];
};

export function mapGlAccountLeaveToGlAccountView(delegate: DelegateService, glAccountLeaf?: GlAccountLeaves): GlAccountView & GlAccountLeaves {
  if (_.isNil(glAccountLeaf)) return undefined;
  const commonData = delegate.getService('commonData');
  const currency = commonData.staticCurrencies.value.find((curr) => curr.id === glAccountLeaf.currencyId);
  return {
    ...glAccountLeaf,
    accKey: glAccountLeaf.id,
    accCode: glAccountLeaf.accountCode,
    currKey: glAccountLeaf.currencyId,
    accAbbr: `${glAccountLeaf.accountCode}/${currency?.code}`,
    accName: glAccountLeaf.accountName,
    accType: glAccountLeaf.accountType,
    accOption: glAccountLeaf.accountOption,
    glAccountsFreeTypeKey: 0,
    accChangeSignum: YN.N,
    historicalRate: YN.N,
    consolidationDetails: YN.N,
    busInfoRequired: YN.N,
    noManualEntry: YN.N,
    iden: `${glAccountLeaf.accountCode} / ${currency?.code}`,
    isAuto: YN.N,
    lastOperationType: 1,
    tplKey: 0,
    interestTplKey: 0,
    versionNb: glAccountLeaf.version,
    accExchDiff: 0,
    cpnTplKey: 0,
    alfCode: 0,
    interestKey: 0,
    interestAccKey: 0,
    parentAccKey: glAccountLeaf.parentAccKey,
    leafAccount: YN.Y,
  };
}
