<form [formGroup]="form">
  <div class="row">
    <vertical-field name="Fixation Date" class="col-6 col-sm-3">
      <datepicker-wrapper [formControlName]="'fixDate'" [readonly]="isFixationApplied"></datepicker-wrapper>
    </vertical-field>
    <vertical-field name="Contract Type" class="col-6 col-sm-3">
      <simple-dropdown type="text" [formControlName]="'contractType'" [data]="contractTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="true"> </simple-dropdown>
    </vertical-field>
  </div>
  <div class="form-divider-line"></div>
  <div class="d-flex flex-column flex-sm-row">
    <div class="col-12 pf-formula-form">
      <vertical-field name="Market Price Formula" class="col-12">
        <textarea-wrapper [formControlName]="'market'" [readonly]="true"></textarea-wrapper>
      </vertical-field>
      <vertical-field name="Market Price" class="col-6">
        <numerictextbox-wrapper
          [formControlName]="'termPrice'"
          [fieldType]="2"
          [format]="marketPriceFormat"
          (ngModelChange)="calculateFixedContractPrice()"
          [readonly]="isFixationApplied"
        ></numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ marketCurrAbbr }}/{{ marketUnitAbbr }}</div>
      </vertical-field>
      <vertical-field name="Fixed Contract Price" class="col-6">
        <numerictextbox-wrapper [formControlName]="'fixedContractPrice'" [fieldType]="2" [format]="fixedContractPriceFormat" [readonly]="true"> </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ contractCurrAbbr }}/{{ contractPriceUnitAbbr }}</div>
      </vertical-field>
      <div *ngIf="!canApply" class="col-12" style="font-size: 12px; margin-bottom: 5px">
        <i class="fas fa-triangle-exclamation"></i> Missing Fixation:
        {{ premiumValuationName ? premiumValuationName : 'Unknown' }}
      </div>
      <vertical-field name="FX Rate Fixation" class="col-6" *ngIf="hasDifferentCurrencies">
        <numerictextbox-wrapper [formControlName]="'fixationRate'" [fieldType]="2" [format]="fixationRateFormat" (ngModelChange)="calculateFixedContractPrice()" [readonly]="isFixationApplied">
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ marketCurrAbbr }}/{{ contractCurrAbbr }}</div>
      </vertical-field>
    </div>
    <div class="form-divider-line d-sm-none"></div>
    <div class="form-divider-vertical-line d-none d-sm-flex"></div>
    <div class="col-12 pf-formula-form">
      <vertical-field name="Balance To Fix Breakdown" class="col-12">
        <textarea-wrapper [formControlName]="'quantityToFixBreakdown'" [readonly]="true"> </textarea-wrapper>
      </vertical-field>
      <div class="row">
        <vertical-field name="Quantity To Fix" class="col-6">
          <numerictextbox-wrapper [formControlName]="'quantityFixed'" [fieldType]="3" [format]="weightFormat" (ngModelChange)="calculateQuantityToFix($event)" (blur)="calculateMetalUnits()">
          </numerictextbox-wrapper>
          <div class="unit-label" style="flex-grow: 0">{{ contractUnitAbbr }}</div>
        </vertical-field>
        <vertical-field name="Balance To Fix" class="col-6">
          <numerictextbox-wrapper [formControlName]="'quantityToFix'" [fieldType]="3" [format]="weightFormat" [readonly]="true"> </numerictextbox-wrapper>
          <div class="unit-label" style="flex-grow: 0">{{ contractUnitAbbr }}</div>
        </vertical-field>
      </div>
      <div style="height: 17px"></div>
      <div class="row">
        <vertical-field name="Metal Units" class="col-6">
          <numerictextbox-wrapper [formControlName]="'metalUnits'" [fieldType]="3" [format]="weightFormat" [decimals]="muDecimals" (blur)="calculateMetalUnitsPercentage()"> </numerictextbox-wrapper>
          <div class="unit-label" style="flex-grow: 0">{{ contractUnitAbbr }}</div>
        </vertical-field>
        <vertical-field name="Metal Unit Percentage" class="col-6">
          <numerictextbox-wrapper [formControlName]="'metalUnitsPercentage'" [fieldType]="4" [format]="metalUnitPercentageFormat" [decimals]="3" (blur)="calculateMetalUnits()">
          </numerictextbox-wrapper>
          <div class="unit-label" style="flex-grow: 0">%</div>
        </vertical-field>
      </div>
    </div>
  </div>
  <div class="form-divider-line"></div>
  <div class="row">
    <vertical-field [name]="'Comment'" class="col-12">
      <textarea-wrapper [formControlName]="'leftOverFutQtyComment'" [showCounter]="showCounter" [maxlength]="maxlength"> </textarea-wrapper>
    </vertical-field>
  </div>
  <collapsible-card [title]="'Shipments'">
    <hedging-shipments-fixations-list
      [formControlName]="'shipments'"
      [contractType]="contractType"
      [fixWeight]="fixWeight"
      [contractUnitAbbr]="contractUnitAbbr"
      [contractQtyUnitKey]="contractQtyUnitKey"
      [productId]="productId"
      [contractPF]="contractPF"
      [canApply]="canApply"
      (shipmentsSelected)="shipmentsAppliedHandler($event)"
      (shipmentsUnselected)="shipmentsUnappliedHandler($event)"
    >
    </hedging-shipments-fixations-list>
  </collapsible-card>
  <div class="row" *ngIf="form.value.applyShipmentsIds && form.value.applyShipmentsIds.length > 0">
    <vertical-field [name]="'Email Options'" class="col-12 col-sm-4">
      <simple-dropdown [formControlName]="'sendEmail'" [data]="emailOptions" [readonly]="false" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"> </simple-dropdown>
    </vertical-field>
    <vertical-field class="col-12 col-sm-8" *ngIf="!hasPricingConfEmail">
      <div class="col-12" style="margin-top: 5px">
        <span class="error-label"><i class="fas fa-triangle-exclamation"></i> The counterparty does not have a registered "Pricing conf email"</span>
      </div>
    </vertical-field>
  </div>
</form>
