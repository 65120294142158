import { Component, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { ListFilterService } from '../../../core/services/list-filter.service';
import { FormElementComponent } from '../form-element/form-element.component';
import { MultiselectWrapperComponent } from './multiselect-wrapper.component';

@Component({
  selector: 'nullable-multiselect-wrapper',
  templateUrl: './multiselect-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => NullableMultiselectWrapperComponent) }],
})
export class NullableMultiselectWrapperComponent extends MultiselectWrapperComponent {
  constructor(listFilterService: ListFilterService, controlDir: NgControl, store: Store) {
    super(listFilterService, controlDir, store);
  }

  ngOnInit() {
    this.multiselect.valueNormalizer = this.valueNormalizer;
    this.multiselect.allowCustom = true;
    super.ngOnInit();
  }

  valueNormalizer = (text: Observable<string>): Observable<any> => {
    return text.pipe(
      map((text) => {
        if (text.toLowerCase() === 'null')
          return {
            [this.dropdownConfig.labelField]: 'NULL',
            [this.dropdownConfig.valueField]: null,
          };
        else return null;
      })
    );
  };
}
