import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { Contact } from './contact';
import { Booking } from './freightBooking';

export type WarehouseMovement = {
  id: number;
  shipmentId: number;
  movementDate: Date;
  movementType: WarehouseMovementType;
  notes: string;
  createdBy: number;
  createdAt: Date;
  warehouseAlfCode: number;
  booking?: Booking;
  operator?: Contact;
  warehouseAlf?: Contact;
};

export enum WarehouseMovementType {
  IN = 1,
  OUT = -1,
}
export const WarehouseMovementTypes = enumOptionsFromEnum(WarehouseMovementType);

export interface CreateWarehouseMovementRequest extends Subset<WarehouseMovement, 'shipmentId' | 'movementType' | 'movementDate' | 'warehouseAlfCode', 'notes'> {}
