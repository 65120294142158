import { Component, Input, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SET_FORM_ENTITY } from 'src/app/core/reducers/actions';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from 'src/app/shared/form-elements/form-element/form-element.component';
import { StoreSubscription } from 'src/lib';
import { minValidator } from 'src/lib/genericValidators';
import { ContractClass, Currency, MarketValuationHeader, Unit } from 'src/lib/newBackendTypes';
import { LinePriceType } from 'src/lib/newBackendTypes/contractLine';
import { ContractFormPricing, QPStatuses } from 'src/lib/newBackendTypes/contractPricing';
import { TypedFormGroup } from 'src/lib/typedForms';
import { NON_HEDGE_MTM_VALUATION_IDS } from 'src/lib/uiConstants';

@UntilDestroy()
@Component({
  selector: 'simple-formula',
  templateUrl: './simple-formula.component.html',
  styleUrls: ['./simple-formula.component.scss'],
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => SimpleFormulaComponent) }],
  host: {
    '(blur)': '_onTouch()',
  },
})
export class SimpleFormulaComponent extends FormElementComponent implements OnInit, OnDestroy {
  editMode: boolean = true;
  formulaText: string = '';

  priceTypes: { label: string; value: LinePriceType }[];
  formTouchedAndInvalid: boolean;
  get priceType(): LinePriceType {
    return this.formulaForm ? this.formulaForm.get('priceType').value : null;
  }

  existingContract: boolean = false;

  @Input()
  contractClass: ContractClass;

  @Input()
  priceUnit: Unit | null;

  @Input()
  currency: Currency | null;

  @Input()
  premiumUnit: Unit | null;

  @Input()
  entityName: 'Contract' | 'Draft';

  @Input()
  contractDate: Date | null;

  @Input()
  existEntity: boolean = false;

  _value: ContractFormPricing = null;

  priceDiscountSign = new UntypedFormControl('+');
  premiumDiscountSign = new UntypedFormControl('+');
  premiumMarketSign = new UntypedFormControl('+');
  fixedPremiumSign = new UntypedFormControl('+');

  formulaForm: TypedFormGroup<ContractFormPricing>;

  selectedUnit: string = '';
  selectedCurrency: string = '';

  intFormat: NumberFormatOptions;
  dollarFormat: NumberFormatOptions;
  percentFormat: NumberFormatOptions;

  contractSub: StoreSubscription<boolean>;

  qpStatuses = QPStatuses;

  valuationId: number;
  premiumValuationId: number;

  constructor(controlDir: NgControl, store: Store, private commonDataService: CommonDataService) {
    super(controlDir, store);
    this.intFormat = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    };
    this.dollarFormat = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    };
    this.percentFormat = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    };
    this.priceTypes = [
      { value: LinePriceType.FIXED, label: 'Fixed' },
      { value: LinePriceType.FORMULA, label: 'Formula' },
    ];

    this.formulaForm = new TypedFormGroup<ContractFormPricing>({
      priceType: new UntypedFormControl(null, [this.required, this.priceTypeValidator]),
      priceValuationId: new UntypedFormControl(null, this.requiredIfFormulaIncludeDraft),
      priceDiscount: new UntypedFormControl(0, this.requiredIfFormula),
      pricePercentage: new UntypedFormControl(100, [this.requiredIfPremium, minValidator(0, false)]),
      priceId: new UntypedFormControl(),
      premiumValuationId: new UntypedFormControl(null),
      premiumDiscount: new UntypedFormControl(0, this.requiredIfPremium),
      premiumPercentage: new UntypedFormControl(100, [this.requiredIfPremium, minValidator(0, false)]),
      premiumId: new UntypedFormControl(),
      fixedPremium: new UntypedFormControl(0, this.requiredIfFormula),
      hedgePrice: new UntypedFormControl(0),
      amount: new UntypedFormControl(0, this.requiredIfFixed),
      mtmValuationId: new UntypedFormControl(null, [this.requiredIfHedged, this.validateMTMValuationIfHedged]),
      mtmDifferential: new UntypedFormControl(0, this.requiredIfFixedAndHedged),
      qpEndDate: new UntypedFormControl(null, [this.requiredIfQPHedged, this.qpEndDateValidator]),
      qpStartDate: new UntypedFormControl(null, [this.requiredIfQPHedged, this.qpStartDateValidator]),
      qpStatus: new UntypedFormControl(null, this.requiredIfQPHedged),
      givenFX: new UntypedFormControl(null, this.requiredIfHedgedAndForeignCurrency),
    });

    this.contractSub = this.store.subscribe((s) => !!s.form.formEntity, [SET_FORM_ENTITY]);
    this.contractSub.$.pipe(untilDestroyed(this)).subscribe((existing: boolean) => {
      this.existingContract = existing;

      this.formTouchedAndInvalid = this.formulaForm.invalid && (this.formulaForm.touched || this.existingContract);
    });
  }

  ngOnInit(): void {
    this.formulaForm
      .get('qpStartDate')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        setTimeout(() => {
          this.formulaForm.get('qpEndDate').updateValueAndValidity({ onlySelf: true });
          if (this.formulaForm.invalid) this.formulaForm.get('qpEndDate').markAsTouched({ onlySelf: true });
        });
      });

    this.formulaForm.valueChanges.pipe(untilDestroyed(this)).subscribe((val: ContractFormPricing) => {
      if (this.formulaForm.touched) this.onValueChange(this.value);

      setTimeout(() => {
        for (let key in this.formulaForm.controls) {
          (<UntypedFormControl>this.formulaForm.get(key)).updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }

        this.formTouchedAndInvalid = this.formulaForm.invalid && (this.formulaForm.touched || this.existingContract);
      });
    });

    this.fixedPremiumSign.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => this.onChange(this.value));
    this.priceDiscountSign.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => this.onChange(this.value));
    this.premiumMarketSign.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => this.onChange(this.value));
    this.premiumDiscountSign.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => this.onChange(this.value));

    this.formulaForm
      .get('priceValuationId')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val: number) => {
        if (
          this.formulaForm.value.priceType === LinePriceType.FORMULA &&
          !this.formulaForm.value.priceId &&
          (this.formulaForm.value.mtmValuationId === null || this.formulaForm.get('mtmValuationId').pristine === true)
        ) {
          this.formulaForm.patchValue({ mtmValuationId: val });
        }
      });

    combineLatest([this.commonDataService.staticMarketValuations, this.formulaForm.get('priceValuationId').valueChanges.pipe(startWith(this.formulaForm.get('priceValuationId').value))])
      .pipe(untilDestroyed(this))
      .subscribe((val: [MarketValuationHeader[], number]) => {
        if (!!val[1]) {
          let markets = val[0] || [];
          let market = markets.find((m) => m.valuationId === val[1]);
          if (market !== undefined) {
            this.marketUnit = (this.commonDataService.staticFilteredUnits.getValue() || []).find((u) => u.unitId === market.unitId) || null;
            this.marketCurrency = (this.commonDataService.staticCurrencies.getValue() || []).find((c) => c.id === market.currencyId) || null;
            return;
          }
        }
        this.marketUnit = null;
        this.marketCurrency = null;
      });

    combineLatest([this.commonDataService.staticPremiumValuations, this.formulaForm.get('premiumValuationId').valueChanges.pipe(startWith(this.formulaForm.get('premiumValuationId').value))])
      .pipe(untilDestroyed(this))
      .subscribe((val: [MarketValuationHeader[], number]) => {
        if (!!val[1]) {
          let markets = val[0] || [];
          let market = markets.find((m) => m.valuationId === val[1]);
          if (market !== undefined) {
            this.premiumMarketUnit = (this.commonDataService.staticFilteredUnits.getValue() || []).find((u) => u.unitId === market.unitId) || null;
            this.premiumMarketCurrency = (this.commonDataService.staticCurrencies.getValue() || []).find((c) => c.id === market.currencyId) || null;
            return;
          }
        }
        this.premiumMarketUnit = null;
        this.premiumMarketCurrency = null;
      });

    combineLatest([this.commonDataService.staticMarketValuations, this.formulaForm.get('mtmValuationId').valueChanges.pipe(startWith(this.formulaForm.get('mtmValuationId').value))])
      .pipe(untilDestroyed(this))
      .subscribe((val: [MarketValuationHeader[], number]) => {
        if (!!val[1]) {
          let markets = val[0] || [];
          let market = markets.find((m) => m.valuationId === val[1]);
          if (market !== undefined) {
            this.mtmMarketUnit = (this.commonDataService.staticFilteredUnits.getValue() || []).find((u) => u.unitId === market.unitId) || null;
            this.mtmMarketCurrency = (this.commonDataService.staticCurrencies.getValue() || []).find((c) => c.id === market.currencyId) || null;
            return;
          }
        }
        this.mtmMarketUnit = null;
        this.mtmMarketCurrency = null;
      });

    setTimeout(() => {
      this.onChange({ ...this.value, firstChange: true });
      this.controlDir.control.markAsPristine();

      if (this.controlDir.control) this.controlDir.control.setValidators([this.validate]);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contractDate'] && changes['contractDate'].currentValue !== changes['contractDate'].previousValue) {
      setTimeout(() => {
        this.formulaForm.get('qpStartDate').updateValueAndValidity({ onlySelf: true });
        if (this.formulaForm.get('qpStartDate').invalid) this.formulaForm.get('qpStartDate').markAsTouched({ onlySelf: true });
      });
    }
    if (changes['contractClass'] && changes['contractClass'].currentValue !== changes['contractClass'].previousValue) {
      this.formulaForm.get('priceType').updateValueAndValidity({ onlySelf: true });
    }

    if (changes['currency'] && changes['currency'].currentValue?.id !== changes['currency'].previousValue?.id) {
      this.formulaForm.get('givenFX').updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.contractSub.unsubscribe();
  }

  getValue(): ContractFormPricing {
    const priceDiscountMultiplier = this.priceDiscountSign.value === '+' ? 1 : -1;
    const premiumMarketMultiplier = this.premiumMarketSign.value === '+' ? 1 : -1;
    const premiumDiscountMultiplier = (this.premiumDiscountSign.value === '+' ? 1 : -1) * premiumMarketMultiplier;
    const fixedPremiumMultiplier = this.fixedPremiumSign.value === '+' ? 1 : -1;

    let basePrice: ContractFormPricing = {
      amount: null,
      fixedPremium: null,
      hedgePrice: null,
      mtmDifferential: null,
      mtmValuationId: null,
      premiumDiscount: null,
      premiumValuationId: null,
      premiumPercentage: null,
      priceDiscount: null,
      priceValuationId: null,
      pricePercentage: null,
      priceType: null,
      qpEndDate: null,
      qpStartDate: null,
      qpStatus: null,
      priceId: null,
      premiumId: null,
      givenFX: null,
    };

    this._value = this.formulaForm.value;
    let val = this._value;
    if (val.priceType === LinePriceType.FIXED) {
      return {
        ...basePrice,
        priceType: val.priceType,
        mtmDifferential: (this.hedged ? val.mtmDifferential : null) || null,
        mtmValuationId: (this.hedged ? val.mtmValuationId : null) || null, //in case of undefined
        amount: val.amount,
        hedgePrice: (this.hedged ? val.hedgePrice : null) || null,
        givenFX: this.hedged ? val.givenFX : null,
      };
    } else if (val.priceType === LinePriceType.FORMULA) {
      let premiumPercentage = val.premiumPercentage;
      if (premiumPercentage !== 0 && !premiumPercentage) premiumPercentage = 100;

      let pricePercentage = val.pricePercentage;
      if (pricePercentage !== 0 && !pricePercentage) pricePercentage = 100;

      if (this.contractClass === ContractClass.QP) {
        basePrice.qpEndDate = val.qpEndDate;
        basePrice.qpStartDate = val.qpStartDate;
        basePrice.qpStatus = val.qpStatus;
      }

      return {
        ...basePrice,
        priceType: val.priceType,
        priceValuationId: val.priceValuationId || null,
        priceDiscount: (val.priceDiscount || 0) * priceDiscountMultiplier,
        pricePercentage: pricePercentage || null,
        premiumValuationId: val.premiumValuationId || null,
        premiumDiscount: (val.premiumDiscount || 0) * premiumDiscountMultiplier,
        premiumPercentage: premiumPercentage * premiumMarketMultiplier,
        fixedPremium: (val.fixedPremium || 0) * fixedPremiumMultiplier,
        mtmValuationId: val.mtmValuationId || null,
        priceId: val.priceId || null,
        premiumId: val.premiumId || null,
        givenFX: this.hedged ? val.givenFX : null,
      };
    } else {
      return {
        priceType: null,
        ...basePrice,
      };
    }
  }

  setValue(val?: ContractFormPricing) {
    if (!val) return;
    if (val.priceType === LinePriceType.FIXED) {
      this.valuationId = val.mtmValuationId || null;
      this.formulaForm.patchValue({
        priceType: val.priceType,
        mtmDifferential: val.mtmDifferential,
        mtmValuationId: val.mtmValuationId || null,
        amount: val.amount,
        hedgePrice: val.hedgePrice,
        givenFX: val.givenFX,
      });
    } else {
      this.priceDiscountSign.setValue(val.priceDiscount < 0 ? '-' : '+');
      this.premiumMarketSign.setValue(val.premiumPercentage < 0 ? '-' : '+');
      this.premiumDiscountSign.setValue((this.premiumMarketSign.value === '-' && val.priceDiscount < 0) || (this.premiumMarketSign.value === '+' && val.priceDiscount! < 0) ? '+' : '-');
      this.fixedPremiumSign.setValue(val.fixedPremium < 0 ? '-' : '+');

      this.valuationId = val.priceValuationId;
      this.premiumValuationId = val.premiumValuationId;
      this.formulaForm.patchValue({
        priceId: val.priceId,
        premiumId: val.premiumId,
        priceType: val.priceType,
        priceValuationId: val.priceValuationId,
        priceDiscount: Math.abs(val.priceDiscount || 0),
        pricePercentage: val.pricePercentage,
        premiumValuationId: val.premiumValuationId,
        premiumDiscount: Math.abs(val.premiumDiscount || 0),
        premiumPercentage: Math.abs(val.premiumPercentage === 0 ? 0 : val.premiumPercentage || 100),
        fixedPremium: Math.abs(val.fixedPremium || 0),
        mtmValuationId: val.mtmValuationId,
        qpEndDate: val.qpEndDate,
        qpStartDate: val.qpStartDate,
        qpStatus: val.qpStatus,
        givenFX: val.givenFX,
      });
    }
  }

  printFormula() {
    let print = '';
    let formula: ContractFormPricing = this.formulaForm.value;
    if (!this.currency || !this.priceUnit) return 'Missing data';

    if (formula.priceType === LinePriceType.FIXED) {
      if (!formula.amount) print = 'Missing data';
      else {
        print = `${formula.amount} ${this.currency.code}/${this.priceUnit.code}`;
      }
    } else {
      let price = formula.priceDiscount > 0 ? `${this.priceDiscountSign.value} ${formula.priceDiscount} ${this.currency}/${this.priceUnit.code} ` : '';
      print = `( ${formula.priceValuationId || 'No Market Selected'} ${price}  )`;
    }

    return print;
  }
  validate = (control: UntypedFormControl): ValidationErrors => {
    if (this.readonly) return null;
    let invalid = this.formulaForm.invalid;
    if (control.touched && invalid) {
      //mark form as touched but not recursive
      Object.keys(this.formulaForm.controls)
        .map((x) => this.formulaForm.controls[x])
        .forEach((c: AbstractControl) => {
          if (c.untouched) {
            c.markAsTouched({ onlySelf: true });
            c.updateValueAndValidity({ emitEvent: true, onlySelf: true });
          }
        });
    }

    return invalid ? { error: true } : null;
  };

  requiredIfFormula = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.formulaForm.value.priceType === LinePriceType.FORMULA ? { required: true } : null;
    }
    return null;
  };

  requiredIfFormulaIncludeDraft = (control: UntypedFormControl) => {
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.formulaForm.value.priceType === LinePriceType.FORMULA ? { required: true } : null;
    }
    return null;
  };

  requiredIfPremium = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.formulaForm.value.priceType === LinePriceType.FORMULA && !!this.formulaForm.value.premiumValuationId ? { required: true } : null;
    }
    return null;
  };
  requiredIfFixed = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.formulaForm.value.priceType === LinePriceType.FIXED ? { required: true } : null;
    }
    return null;
  };
  requiredIfHedged = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (control.value === null || control.value === undefined) {
      return this.hedged ? { required: true } : null;
    }
    return null;
  };

  validateMTMValuationIfHedged = (control: UntypedFormControl) => {
    // Check if the MTM Valuation ID corresponds to a Non Hedge for a Hedge contract
    const mtmValuationId = this.formulaForm && this.formulaForm.value.mtmValuationId;
    return this.contractClass !== ContractClass.NH && mtmValuationId && NON_HEDGE_MTM_VALUATION_IDS.includes(mtmValuationId) ? { required: true } : null;
  };

  requiredIfFixedAndHedged = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.hedged && this.formulaForm.value.priceType === LinePriceType.FIXED ? { required: true } : null;
    }
    return null;
  };

  requiredIfQPHedged = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (control.value === null || control.value === undefined) {
      return this.qpHedged && this.formulaForm.value.priceType === LinePriceType.FORMULA ? { required: true } : null;
    }
    return null;
  };

  qpStartDateValidator = (control: UntypedFormControl) => {
    if (!this.formulaForm) return null;
    let value: Date | null = control.value;
    let cDate: Date | null = this.contractDate;
    if (!value) return null;
    if (cDate === null) return null;
    value = new Date(value);
    cDate = new Date(cDate);
    cDate.setHours(0, 0, 0, 0);
    value.setHours(0, 0, 0, 0);
    if (cDate > value) {
      return { custom: 'QP Start Date cannot be ealier than Contract Date' };
    }
    return null;
  };

  qpEndDateValidator = (control: UntypedFormControl) => {
    if (!this.formulaForm) return null;
    let value: Date | null = control.value;
    if (!value) return null;
    let qpStartDate = this.formulaForm.value.qpStartDate;
    if (qpStartDate === null) return null;
    value = new Date(value);
    qpStartDate = new Date(qpStartDate);
    qpStartDate.setHours(0, 0, 0, 0);
    value.setHours(0, 0, 0, 0);
    if (qpStartDate > value) {
      return { custom: 'QP End Date cannot be ealier than QP Start Date' };
    }
    return null;
  };

  requiredIfHedgedAndForeignCurrency = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    if (!this.formulaForm) return null;
    if (!this.hedged) return null;
    if (this.mtmMarketCurrency?.id === this.currency?.id) return null;
    return Validators.required(control);
  };

  required = (control: UntypedFormControl) => {
    if (this.entityName === 'Draft') return null;
    return Validators.required(control);
  };

  priceTypeValidator = (control: UntypedFormControl) => {
    if (this.contractClass === ContractClass.NH && control.value === LinePriceType.FORMULA) {
      return { custom: 'Non-hedged contract must have a fixed price' };
    }
  };

  get hedged(): boolean {
    return this.contractClass === ContractClass.H || this.contractClass === ContractClass.QP;
  }

  get qpHedged(): boolean {
    return this.contractClass === ContractClass.QP;
  }

  get currencyPerUnit(): string {
    let unit = this.priceUnit ? this.priceUnit.code : '?';
    let currency = this.currency ? this.currency.code : '?';

    return `${currency}/${unit}`;
  }

  get premiumCurrencyPerUnit(): string {
    let premiumUnit = this.premiumUnit ? this.premiumUnit.code : '?';
    let currency = this.currency ? this.currency.code : '?';

    return `${currency}/${premiumUnit}`;
  }

  private marketCurrency?: Currency;
  private marketUnit?: Unit;

  get marketCurrencyPerUnit(): string {
    let marketCurrencyCode = this.marketCurrency ? this.marketCurrency.code : '?';
    let marketUnitCode = this.marketUnit ? this.marketUnit.code : '?';
    return `${marketCurrencyCode}/${marketUnitCode}`;
  }

  private premiumMarketCurrency?: Currency;
  private premiumMarketUnit?: Unit;

  get premiumMarketCurrencyPerUnit(): string {
    let marketCurrencyCode = this.premiumMarketCurrency ? this.premiumMarketCurrency.code : '?';
    let marketUnitCode = this.premiumMarketUnit ? this.premiumMarketUnit.code : '?';
    return `${marketCurrencyCode}/${marketUnitCode}`;
  }

  private mtmMarketCurrency?: Currency;
  private mtmMarketUnit?: Unit;

  get mtmMarketCurrencyPerUnit(): string {
    let marketCurrencyCode = this.mtmMarketCurrency ? this.mtmMarketCurrency.code : '?';
    let marketUnitCode = this.mtmMarketUnit ? this.mtmMarketUnit.code : '?';
    return `${marketCurrencyCode}/${marketUnitCode}`;
  }

  blurPriceDiscount() {
    if (this.formulaForm.value.priceType === LinePriceType.FIXED) return;
    let price = this.formulaForm.value.priceDiscount;
    if (price < 0) {
      this.priceDiscountSign.setValue('-');
      this.formulaForm.patchValue({ priceDiscount: price * -1 });
    }
  }

  blurPremiumDiscount() {
    if (this.formulaForm.value.priceType === LinePriceType.FIXED) return;
    let price = this.formulaForm.value.premiumDiscount;
    if (price < 0) {
      this.premiumDiscountSign.setValue('-');
      this.formulaForm.patchValue({ premiumDiscount: price * -1 });
    }
  }

  blurPremium() {
    if (this.formulaForm.value.priceType === LinePriceType.FIXED) return;
    let price = this.formulaForm.value.fixedPremium;
    if (price < 0) {
      this.fixedPremiumSign.setValue('-');
      this.formulaForm.patchValue({ fixedPremium: price * -1 });
    }
  }

  comparator(a: ContractFormPricing, b: ContractFormPricing) {
    return _.isEqual(a, b);
  }
}
