import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { BankAccount, BudgetElement, City, CommonContactTypes, Contact, PaymentTerm, PropertyDocument, YN } from '../newBackendTypes';
import { basicContactsGraphqlRequest, getContactListArgsGraphql, getContactListVariablesGraphql } from './entities/contact.graphql';
import { WaTermDate, WaTermGroup } from '../newBackendTypes';
import { GlAccountView } from '../newBackendTypes';
import { GraphqlVariables, GraphqlArgsRequest, GraphqlQueryRequest } from './graphqlRequestTypes';
import { ExistingGraphql, GraphqlNames } from './graphQlEnums';
import { GraphqlDropdownConfig } from './GraphqlDropdownConfig';
import { getPaymentTermListArgsGraphql, getPaymentTermListVariablesGraphql, listPaymentTermGraphqlRequest } from './entities/paymentTerm.graphql';
import { getPropertyDocumentListArgsGraphql, getPropertyDocumentListVariablesGraphql, listPropertyDocumentGraphqlRequest } from './entities/propertyDocument.graphql';
import { getBudgetElementListArgsGraphql, getBudgetElementListVariablesGraphql, listBudgetElementGraphqlRequest } from './entities/budgetElement.graphql';
import { getAccountListArgsGraphql, getAccountListVariablesGraphql, listAccountGraphqlRequest } from './entities/accounts.graphql';
import { getGlAccountListArgsGraphql, getGlAccountListVariablesGraphql, listGlAccountGraphqlRequest } from './entities/glAccount.graphql';
import { basicCityGraphqlRequest, getCityListArgsGraphql, getCityListVariablesGraphql } from './entities/city.graphql';
import { getPaymentTermGroupListArgsGraphql, getPaymentTermGroupListVariablesGraphql, listPaymentTermGroupGraphqlRequest } from './entities/paymentTermGroup.graphql';
import { getPaymentTermDateListArgsGraphql, getPaymentTermDateListVariablesGraphql, listPaymentTermDateGraphqlRequest } from './entities/paymentTermDate.graphql';

/**
 * Build the request that will be sent to the backend.
 *
 * @param name String. Support for named queries/mutations.
 * @param request GraphqlArgsRequest<T>. Object with the fields that will be called in the query, the __aliasFor and the __args.
 * @param variables GraphqlVariables<T>. Contains the variable we are going to filter by. Used on dropdowns mostly
 * @returns Returns a string with the final request to the backend.
 */
export function getGraphqlRequestQuery<T>(name: GraphqlNames, request: GraphqlArgsRequest<T>, variables?: GraphqlVariables<T>) {
  const query: GraphqlQueryRequest<T> = {
    query: {
      __name: name,
      __variables: variables,
      request,
    },
  };
  return jsonToGraphQLQuery(query, { pretty: true });
}

/**
 * Basic arguments to filter and sort by non-zero id.
 *
 * @returns Returns the arguments to filter and sort by non-zero id.
 */
export function getBasicGraphqlArgs() {
  return {
    where: { id: { not: { equals: 0 } } },
    orderBy: [{ id: new EnumType('asc') }],
  };
}

/**
 * Used to get a basic dropdown of contacts with id and displayName.
 *
 * @param take Number. Number of records to be obtained.
 * @returns basic dropdown of contacts with id and displayName.
 */
export function basicGraphqlContactsDropdown(contactTypes?: CommonContactTypes[], take: number = 20, skipArchived: YN = YN.Y, companyId?: number): GraphqlDropdownConfig<Contact> {
  return new GraphqlDropdownConfig<Contact>({
    query: getGraphqlRequestQuery<Contact>(
      GraphqlNames.simpleContactsList,
      basicContactsGraphqlRequest(getContactListArgsGraphql(contactTypes, take, skipArchived, companyId)),
      getContactListVariablesGraphql()
    ),
    graphQlName: ExistingGraphql.alves,
    principalVariableKey: 'displayName',
    labelField: 'displayName',
    valueField: 'id',
  });
}

export function basicGraphqlPaymentTermDropdown(): GraphqlDropdownConfig<PaymentTerm> {
  return new GraphqlDropdownConfig<PaymentTerm>({
    query: getGraphqlRequestQuery<PaymentTerm>(GraphqlNames.simplePaymentTermsList, listPaymentTermGraphqlRequest(getPaymentTermListArgsGraphql()), getPaymentTermListVariablesGraphql()),
    graphQlName: ExistingGraphql.paymentTerms,
    principalVariableKey: 'name',
    labelField: 'name',
    valueField: 'id',
  });
}

export function basicGraphqlShipmentsDropdown(): GraphqlDropdownConfig<PropertyDocument> {
  return new GraphqlDropdownConfig<PropertyDocument>({
    query: getGraphqlRequestQuery<PropertyDocument>(
      GraphqlNames.simpleShipmentsList,
      listPropertyDocumentGraphqlRequest(getPropertyDocumentListArgsGraphql()),
      getPropertyDocumentListVariablesGraphql()
    ),
    graphQlName: ExistingGraphql.propertyDocuments,
    principalVariableKey: 'documentName',
    labelField: 'id',
    valueField: 'id',
  });
}

export function basicGraphqlBudgetElementDropdown(): GraphqlDropdownConfig<BudgetElement> {
  return new GraphqlDropdownConfig<BudgetElement>({
    query: getGraphqlRequestQuery<BudgetElement>(GraphqlNames.simpleBudgetElementsList, listBudgetElementGraphqlRequest(getBudgetElementListArgsGraphql()), getBudgetElementListVariablesGraphql()),
    graphQlName: ExistingGraphql.budgetElements,
    principalVariableKey: 'name',
    labelField: 'name',
    valueField: 'id',
  });
}

export function basicGraphqlBankAccountDropdown(): GraphqlDropdownConfig<BankAccount> {
  return new GraphqlDropdownConfig<BankAccount>({
    query: getGraphqlRequestQuery<BankAccount>(GraphqlNames.simpleBankAccountsList, listAccountGraphqlRequest(getAccountListArgsGraphql()), getAccountListVariablesGraphql()),
    graphQlName: ExistingGraphql.accounts,
    principalVariableKey: 'iden',
    labelField: 'iden',
    valueField: 'id',
  });
}

export function basicGraphqlGlAccounttDropdown(): GraphqlDropdownConfig<GlAccountView> {
  return new GraphqlDropdownConfig<GlAccountView>({
    query: getGraphqlRequestQuery<GlAccountView>(GraphqlNames.simpleGlAccountsList, listGlAccountGraphqlRequest(getGlAccountListArgsGraphql()), getGlAccountListVariablesGraphql()),
    graphQlName: ExistingGraphql.glAccounts,
    principalVariableKey: 'idenLong',
    labelField: 'idenLong',
    valueField: 'accKey',
  });
}

export function basicGraphqlCityDropdown(take: number = 20): GraphqlDropdownConfig<City> {
  return new GraphqlDropdownConfig<City>({
    query: getGraphqlRequestQuery<City>(GraphqlNames.simpleCitiesList, basicCityGraphqlRequest(getCityListArgsGraphql(take)), getCityListVariablesGraphql()),
    graphQlName: ExistingGraphql.places,
    principalVariableKey: 'name',
    labelField: 'name',
    valueField: 'id',
  });
}

export function basicGraphqlPaymentTermGroupDropdown(): GraphqlDropdownConfig<WaTermGroup> {
  return new GraphqlDropdownConfig<WaTermGroup>({
    query: getGraphqlRequestQuery<WaTermGroup>(GraphqlNames.termGroupsList, listPaymentTermGroupGraphqlRequest(getPaymentTermGroupListArgsGraphql()), getPaymentTermGroupListVariablesGraphql()),
    graphQlName: ExistingGraphql.waTermGroups,
    principalVariableKey: 'termGroupName',
    labelField: 'termGroupName',
    valueField: 'termGroupKey',
  });
}

export function basicGraphqlPaymentTermDateDropdown(): GraphqlDropdownConfig<WaTermDate> {
  return new GraphqlDropdownConfig<WaTermDate>({
    query: getGraphqlRequestQuery<WaTermDate>(GraphqlNames.termDatesList, listPaymentTermDateGraphqlRequest(getPaymentTermDateListArgsGraphql()), getPaymentTermDateListVariablesGraphql()),
    graphQlName: ExistingGraphql.waTermDates,
    principalVariableKey: 'label',
    labelField: 'label',
    valueField: 'id',
  });
}
