import { Subset } from 'src/lib/generics';
import { Notifications } from './notifications';

export enum NotificationStatus {
  NOT_SENT = 0,
  SENT = 1,
  READ = 2,
}

export type NotificationsStatus = {
  id: number;
  notificationId: number;
  status: NotificationStatus;
  userId: number;
  creationDate: Date;
  updateDate: Date | null;
  notification?: Notifications;
};

export type UpdateNotificationStatusRequest = Subset<NotificationsStatus, 'status' | 'userId'> & { notificationStatusIdArr: number | number[] };
