import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { InvoiceHeader, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const updateVoucherTitleAndRefsPrefill: prefillCallback<InvoiceHeader> = (delegate, id) => {
  let api = delegate.getService('api');

  return api.rpc<InvoiceHeader>(endpoints.getVoucher, { filters: { id } }, null);
};

const updateVoucherTitleAndRefsCheck: checkPrefillCallback<InvoiceHeader> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to update voucher: voucher not found');
  return of(true);
};

const updateVoucherTitleAndRefsForm: createFormCallback<InvoiceHeader, UpdateVoucherTitleRefsForm> = (delegate, id, prefill) => {
  return [
    { type: 'Label', text: `Voucher number: ${prefill ? prefill.number : 'Unknown'}` },
    _fe('invoiceTitle', 'Title', 'Text', prefill.invoiceTitle, Validators.required),
    _fe('ourReference', 'Our Reference', 'Text', prefill.ourReference),
    _fe('yourReference', 'Your Reference', 'Text', prefill.yourReference),
    _fe('externalReference', 'External Reference', 'Text', prefill.externalReference),
  ];
};

const updateVoucherTitleAndRefsCallback: submitFormCallback<InvoiceHeader, UpdateVoucherTitleRefsForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<InvoiceHeader>((resolve) => {
      (async () => {
        const request: UpdateVoucherReferencesRequest = {
          id,
          invoiceTitle: form.invoiceTitle,
          ourReference: form.ourReference,
          yourReference: form.yourReference,
          externalReference: form.externalReference,
        };
        const updateVoucher = await api.run<InvoiceHeader>(endpoints.updateVoucherReferences, request, null);
        if (updateVoucher) return resolve(updateVoucher);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Voucher ${res.number} successfuly updated.</div>`);
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Voucher was updated and try again if necessary.</div>`);
      })
  );
};

export const updateVoucherTitleAndRefsPreset: DynamicFormConstant<InvoiceHeader, UpdateVoucherTitleRefsForm> = {
  allowMultipleRows: false,
  checkPrefill: updateVoucherTitleAndRefsCheck,
  createForm: updateVoucherTitleAndRefsForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: updateVoucherTitleAndRefsPrefill,
  label: 'Update Voucher Title/References',
  submitForm: updateVoucherTitleAndRefsCallback,
  title: 'Update Voucher Title/References',
  value: DynamicFormType.UPDATE_VOUCHER_TITLE_REFS,
  endpoints: [endpoints.updateVoucherReferences, endpoints.getVoucher],
  width: 600,
};

export type UpdateVoucherTitleRefsForm = Pick<InvoiceHeader, 'invoiceTitle' | 'ourReference' | 'yourReference' | 'externalReference'>;

export type UpdateVoucherReferencesRequest = Subset<InvoiceHeader, 'id', 'invoiceTitle' | 'externalReference' | 'ourReference' | 'yourReference'>;
