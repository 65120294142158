import { SystemObject } from './systemObject';

export class SystemColumn {
  objectId: number;
  columnId: number;
  name: string | null;
  systemTypeId: number;
  userTypeId: number;
  maxLength: number;
  precision: number;
  scale: number;
  collationName: string | null;
  isNullable: number;
  isAnsiPadded: number;
  isRowguidcol: number;
  is_identity: number;
  isComputed: number;
  isFilestream: number;
  isReplicated: number;
  isNonSqlSubscribed: number;
  isMergePublished: number;
  isDtsReplicated: number;
  isXmlDocument: number;
  xmlCollectionId: number;
  defaultObjectId: number;
  ruleObjectId: number;
  isSparse: number;
  isColumnSet: number;
  parent?: SystemObject;
}
