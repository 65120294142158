<form autocomplete="off" class="container">
  <input id="username" style="display: none" type="text" name="fakeusernameremembered" />
  <input id="password" style="display: none" type="password" name="fakepasswordremembered" />
  <div class="card">
    <div class="card-header d-flex">
      <h4 class="flex-grow-1">
        <i *ngIf="icon" [ngClass]="icon"></i>
        <span> {{ title }}</span>
      </h4>
    </div>
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </div>
</form>
