import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

export type StatusBarSpacerPanelParams = IStatusPanelParams & { width?: number };
@Component({
  selector: 'status-bar-spacer-panel',
  template: `<div [ngStyle]="{ width: width }">&nbsp;</div>`,
})
export class StatusBarSpacerPanel implements IStatusPanelAngularComp {
  params: StatusBarSpacerPanelParams;

  width: string;
  agInit(params: StatusBarSpacerPanelParams): void {
    this.params = params;
    this.width = `${params.width || 200}px`;
  }
}
