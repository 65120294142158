import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef } from 'ag-grid-community';
import { UtilsService } from 'src/lib/layoutsUtils';

@UntilDestroy()
@Component({
  selector: 'color-picker-toolbar',
  templateUrl: './color-picker-toolbar.component.html',
  styleUrls: ['./color-picker-toolbar.component.scss'],
})
export class ColorPickerToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('toolPanel', { static: false })
  toolPanel: ElementRef;

  columns: colOption[];

  presetValues: { [key: string]: string } = {};

  constructor(private listUtils: UtilsService) {
    this.listUtils.colorSelected.pipe(untilDestroyed(this)).subscribe((selection) => {
      //if the grid has been initialized, find the column and set its color, otherwise, set preset values to use as defaults once init is run
      if (this.columns) {
        if (selection.columnId === null) {
          this.columns.forEach((c) => (c.color = null));
        } else {
          let col = this.columns.find((c) => c.id === selection.columnId);
          if (col) {
            col.color = selection.color;
          }
        }
      } else {
        if (selection.columnId !== null) this.presetValues[selection.columnId] = selection.color;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  refresh() {}

  getGui() {
    return this.toolPanel?.nativeElement;
  }

  agInit(params: any): void {
    params.api.addEventListener('modelUpdated', () => {
      //Once columns have been popuated and are accessible, set them, but only once
      if (params.columnApi.columnController?.columnDefs?.length && !this.columns) {
        this.columns = params.columnApi.columnController?.columnDefs
          ?.map((def: ColDef) => {
            return {
              name: def.headerName ?? def.field,
              id: def.colId,
              color: this.presetValues[def.colId] ?? null,
            };
          })
          .filter((c) => !!c.name && !!c.id);
      }
    });
  }

  onColorSelected(column: colOption, color: string | null) {
    if (color === null) column.color = null;
    //publish color event
    this.listUtils.selectColor(column.id, color);
  }
}

type colOption = {
  name: string;
  id: any;
  color: string | null;
};
