import { YN } from './enums';
import { FutureMarket } from './futureMarket';

export enum MarketValuationType {
  PRICE = 1,
  PREMIUM = 2,
}

export type MarketValuationHeader = {
  valuationId: number;
  valuationName: string;
  lastPrice: number;
  lastPriceDate: Date | null;
  differential: number | null;
  currencyId: number;
  unitId: number;
  createdById: number;
  creationDate: Date;
  updatedById: number | null;
  updateDate: Date | null;
  versionNb: number | null;
  articleType: number;
  isArchived: string | null;
  lastPremium: number | null;
  archived: YN;
  hidden: YN;
  incotermId: number;
  productId: number;
  loadingPortKey: number;
  destinationPortKey: number;
  line?: MarketValuationLine;
};

export type ActiveMarketValuationHeader = {
  valuationId: number;
  valuationName: string;
  priceTermId: number;
  firstNoticeDate: number;
  futureDate: number;
  lineType: MarketValuationType;
};

export type MarketValuationLine = {
  valuationId: number;
  valuationLineKey: number;
  percentage: number | null;
  marketKey: number | null;
  priceTermId: number | null;
  pricingDays: number | null;
  pricingQualifier: number;
  pricingDate: number | null;
  pricingDate2: number | null;
  cotation: number | null;
  lineType: MarketValuationType;
  header?: MarketValuationHeader;
  priceTerm?: PriceTerm;
};

//TODO, remove future market term mapping everywhere
export type PriceTerm = {
  id: number;
  name: string;
  articleType: number | null;
  futureMarketId: number;
  anneeMois: number | null;
  endDate: Date | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  versionNb: number | null;
  lastKnownPrice: number | null;
  lastKnownPriceDate: number | null;
  curr1: number | null;
  curr2: number | null;
  startDate: Date;
  reutersInstrumentCode: string | null;
  futureMarket?: FutureMarket;
  marketValuationLine?: MarketValuationLine;
};
