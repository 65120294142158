import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
import { FINISH_REQUEST, START_REQUEST, UPDATE_REQUEST_PROGRESS } from '../reducers/actions';
import { Store } from './store.service';

@Injectable()
export class SpinnerService {
  constructor(private store: Store) {}

  public startRequest(text: string = null, progress?: number, background?: boolean, hidden?: boolean) {
    let id = uuid.v4();
    this.store.dispatch({
      type: START_REQUEST,
      payload: { text, progress, id, background, hidden },
    });
    return id;
  }

  public startBackgroundRequest(text: string = null) {
    return this.startRequest(text, null, true);
  }

  public completeRequest(id: string) {
    this.store.dispatch({ type: FINISH_REQUEST, payload: id });
  }

  public updateRequest(id: string, args: { progress?: number; text?: string }) {
    this.store.dispatch({ type: UPDATE_REQUEST_PROGRESS, payload: { id, ...args } });
  }
}
