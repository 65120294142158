<div class="form-group row vertical-form-field">
  <label *ngIf="showLabel" class="col-12 col-form-label col-form-label-sm col-form-label-offset" [ngStyle]="{ visibility: noLabel ? 'hidden' : 'visible' }">
    {{ name }}<span *ngIf="required">*</span>
  </label>
  <div class="col-12 d-flex field-content-container" style="flex-grow: 1">
    <ng-content> </ng-content>
  </div>
  <div *ngIf="controlTouched" cyData="errorLabel" class="col-12 error-label" [ngClass]="{ 'active-error': !!errorMessage }">
    {{ errorMessage }}
  </div>
  <div *ngIf="warning" class="col-12 error-label custom-warning">
    {{ warningText }}
  </div>
</div>
