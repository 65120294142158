<form [formGroup]="form">
  <div class="row">
    <vertical-field [name]="'Number of Bookings'" class="col-xl-2 co-lg-3 col-md-4 col-sm-6 col-12">
      <numerictextbox-wrapper [formControlName]="'numberOfBookings'" [format]="intFormat" [min]="1"> </numerictextbox-wrapper>
    </vertical-field>
  </div>
  <div *ngIf="!!form.value.numberOfBookings" class="d-flex" style="flex-direction: column" class="container-assignments">
    <div class="row" *ngFor="let fg of form.get('containerLinks').controls" [formGroup]="fg">
      <vertical-field [name]="'Container'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <div style="line-height: 30px; margin-left: 6px; vertical-align: text-bottom">
          {{ fg?.value?.container?.containerNumber }}
        </div>
      </vertical-field>
      <vertical-field [name]="'Items'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <div style="min-height: 30px">
          <p style="margin-left: 6px; vertical-align: text-bottom; padding-top: 6px; white-space: pre">
            {{ fg?.value?.items }}
          </p>
        </div>
      </vertical-field>
      <vertical-field [name]="'Weight'" class="col-xl-2 co-lg-2 col-md-4 col-sm-6 col-12">
        <div style="line-height: 30px; margin-left: 6px; vertical-align: text-bottom">
          {{ fg?.value?.container?.totalWeight }}
        </div>
      </vertical-field>
      <vertical-field [name]="'Sale Contracts'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <div style="white-space: pre; line-height: 30px; margin-left: 7px">
          {{ fg.value?.saleContracts }}
        </div>
      </vertical-field>
      <vertical-field [name]="'Booking'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown [data]="bookingNumbers" [formControlName]="'bookingNumber'" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Purchase Folder" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" *ngIf="!!fg.value.bookingNumber && fg.value.bookingNumber !== originalBookingNumber">
        <simple-dropdown [formControlName]="'purchaseFolder'" [data]="pathOptions" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </vertical-field>
    </div>
  </div>
</form>
