<li class="menu-item" cyData="menuItem" *ngIf="item.link || hasVisibleChildren || item.effect">
  <div class="menu-item-wrapper" [ngClass]="{ highlight: active }" [hidden]="item.hidden && !active" (click)="open = !open">
    <span class="menu-text">
      <span *ngIf="item.icon" [ngClass]="item.icon" style="width: 22px; text-align: center"></span>
      <a [hidden]="item.hidden || !item.link" [routerLink]="item.link" (click)="clearAnchorPoint($event)" (contextmenu)="$event.preventDefault()" (auxclick)="onAuxClick($event)">
        {{ item.title }}
      </a>
      <a [hidden]="!item.hidden && !!item.link" (click)="clickItem()" [ngClass]="{ 'menu-effect': !!item.effect }">
        {{ item.title }}
      </a>
    </span>
    <button type="button" [hidden]="item.hidden" kendoButton class="menu-open-button" [icon]="!!open ? 'arrow-chevron-up' : 'arrow-chevron-down'" *ngIf="hasVisibleChildren"></button>
  </div>
  <ul class="menu-children" [hidden]="!open">
    <roco-menu-item *ngFor="let child of item.children" [item]="child" [forceClose]="forceClose" (forceOpen)="openActive()"> </roco-menu-item>
  </ul>
</li>
