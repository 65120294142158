import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { UnapplyFixationFromInvoiceForm } from 'src/lib/flex/forms/unapplyFixationFromInvoice';
import { ApplyUnapplyFixationToInvoicePrefill, ApplyUnapplyPriceFixationRequest } from 'src/lib/newBackendTypes/priceFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-unapply-fixation-from-invoice',
  templateUrl: './unapply-fixation-from-invoice.component.html',
})
export class UnapplyFixationFromInvoiceComponent implements ModalFormComponent<UnapplyFixationFromInvoiceForm, ApplyUnapplyFixationToInvoicePrefill> {
  form: TypedFormGroup<UnapplyFixationFromInvoiceForm>;
  popup = true;

  shipmentsIds: number[] = [];

  constructor() {
    this.form = new TypedFormGroup<UnapplyFixationFromInvoiceForm>({
      shipmentsToUnfix: new UntypedFormControl([]),
    });
  }

  prefillForm(data: ApplyUnapplyFixationToInvoicePrefill) {
    if (!!data) {
      const shipments = data.shipments;
      let shipmentsToUnfix: ApplyUnapplyPriceFixationRequest[] = [];

      for (const shipment of shipments) {
        if (shipment.appliedFixationId !== null) {
          this.shipmentsIds.push(shipment.shipmentId);
          shipmentsToUnfix.push({
            id: shipment.appliedFixationId,
            shipmentIdArr: [shipment.shipmentId],
          });
        }
      }

      shipmentsToUnfix = Object.values(
        shipmentsToUnfix.reduce((s, { id, shipmentIdArr }) => {
          s[id] = s[id] || { id, shipmentIdArr: new Array() };
          s[id].shipmentIdArr.push(...shipmentIdArr);
          return s;
        }, {})
      );

      this.form.patchValue({
        shipmentsToUnfix: shipmentsToUnfix,
      });
    }
  }

  allowSubmit() {
    return this.form.valid;
  }

  submit(): UnapplyFixationFromInvoiceForm {
    const formVal = this.form.value;
    return {
      shipmentsToUnfix: formVal.shipmentsToUnfix,
    };
  }
}
