import { ContentChildren, HostBinding, Input, OnDestroy, OnInit, QueryList, SimpleChanges, Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { getErrorLabel } from 'src/lib/genericValidators';
import { FormElementComponent } from '../../form-elements/form-element/form-element.component';

@UntilDestroy()
@Directive()
export abstract class FormFieldComponent implements OnInit, OnDestroy {
  @ContentChildren(FormElementComponent)
  formElements: QueryList<FormElementComponent>;

  @Input()
  name: string;

  @Input('control')
  formControls: AbstractControl[];

  @Input('required')
  required: boolean;

  @Input('warning') warning: boolean = false;
  @Input('warningText') warningText: string = '';

  @Input()
  @HostBinding('hidden')
  hidden: boolean = false;

  @Input('showLabel') showLabel: boolean = true;

  controlTouched: boolean = false;

  public errorMessage: string;

  noLabel: boolean;

  constructor() {
    this.formControls = [];
  }

  ngOnInit(): void {
    if (!this.name) {
      this.noLabel = true;
      this.name = '.';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    let formHideEvents = [];
    if (this.formElements.length > 0) this.hidden = true;
    for (let formEl of this.formElements.toArray()) {
      this.formControls.push(formEl.getFormControl());
      if (!formEl.hidden) this.hidden = false;
      formHideEvents.push(formEl.onHiddenChanged.pipe(untilDestroyed(this)));
    }
    if (formHideEvents.length > 0)
      combineLatest(formHideEvents).subscribe((hidden: boolean[]) => {
        this.updateHiddenStatus(hidden);
      });
    if (this.formControls.length > 0) {
      this.getErrorMessage();
      combineLatest([...this.formControls.map((c) => c.valueChanges.pipe(startWith(c.value)))])
        .pipe(debounceTime(100), untilDestroyed(this))
        .subscribe((_) => {
          this.controlTouched = this.formControls.some((c) => c.touched);
          this.getErrorMessage();
        });

      combineLatest([...this.formControls.map((c) => c.statusChanges.pipe(startWith(c.status)))])
        .pipe(debounceTime(100), untilDestroyed(this))
        .subscribe((_) => {
          this.controlTouched = this.formControls.some((c) => c.touched);
          this.getErrorMessage();
        });
    }
  }

  updateHiddenStatus(hiddenStatuses: boolean[]) {
    this.hidden = true;
    for (let status of hiddenStatuses) {
      if (!status) this.hidden = false;
    }
  }

  ngOnDestroy(): void {}

  private getErrorMessage() {
    let message = '';
    let firstError = this.formControls.find((c) => !!c.errors);

    if (firstError) message = getErrorLabel(firstError);
    this.errorMessage = message;
  }
}
