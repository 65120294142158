import { Component, ElementRef, NgZone } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { EntityContainer } from 'src/lib/EntityContainer';
import { EnumLabels } from 'src/lib/generics';
import { CreateDocumentTemplateRequest, DocumentTemplate, DocumentTemplateType, SourceEntityTypeNames, UpdateDocumentTemplateRequest } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'it-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss'],
})
export class DocumentTemplateComponent extends EntityContainer<DocumentTemplate, CreateDocumentTemplateRequest, UpdateDocumentTemplateRequest> {
  entityTypes = SourceEntityTypeNames;
  form: TypedFormGroup<DocumentTemplateForm> = null;
  popup = false;
  types: EnumLabels<DocumentTemplateType> = [
    { value: DocumentTemplateType.EXCELOPENXML, label: 'Excel' },
    { value: DocumentTemplateType.PDF, label: 'PDF' },
    { value: DocumentTemplateType.WORDOPENXML, label: 'Word' },
  ];

  constructor(route: ActivatedRoute, elementRef: ElementRef, zone: NgZone, delegate: DelegateService) {
    super(
      {
        entityName: 'DocumentTemplate',
        idFields: ['id'],
        createProcedureId: endpoints.createDocumentTemplate,
        updateProcedureId: endpoints.updateDocumentTemplate,
        deleteProcedureId: endpoints.deleteDocumentTemplate,
      },
      route,
      elementRef,
      zone,
      delegate
    );
  }
  initializeForm() {
    this.form = new TypedFormGroup<DocumentTemplateForm>({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      sourceEntityTypeId: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(null, Validators.required),
      url: new UntypedFormControl(null, Validators.required),
    });
    if (!!this.prefill) {
      this.form.patchValue(this.prefill);
    }
  }

  initializeFormListeners() {}

  getCreateEntityRequest() {
    let { url, name, sourceEntityTypeId, type } = this.form.value;

    return {
      url,
      name,
      sourceEntityTypeId,
      type,
    };
  }

  getUpdateEntityRequest() {
    let formVal = this.form.value;
    let request: UpdateDocumentTemplateRequest = {
      id: this.entity!.id,
    };
    if (formVal.url !== this.entity.url) {
      request.url = formVal.url;
    }
    if (formVal.name !== this.entity.name) {
      request.name = formVal.name;
    }
    if (formVal.sourceEntityTypeId !== this.entity.sourceEntityTypeId) {
      request.sourceEntityTypeId = formVal.sourceEntityTypeId;
    }
    if (formVal.type !== this.entity.type) {
      request.type = formVal.type;
    }
    return request;
  }

  loadEntity(entity: DocumentTemplate) {
    this.form.reset(entity);
  }

  prefillForm(val: Partial<DocumentTemplateForm>) {
    this.form.reset(val);
  }
}

export type DocumentTemplateForm = Pick<DocumentTemplate, 'id' | 'name' | 'sourceEntityTypeId' | 'type' | 'url'>;
