import { QueryList, ViewChildren, Directive, forwardRef } from '@angular/core';
import { FormElementComponent } from 'src/app/shared/form-elements/form-element/form-element.component';

@Directive()
export abstract class InnerFormGroupFormElement extends FormElementComponent {
  @ViewChildren(forwardRef(() => InnerFormGroupFormElement))
  innerGroups: QueryList<InnerFormGroupFormElement>;

  protected abstract markAsTouched();

  public markAllTouched() {
    if (this.innerGroups) {
      this.innerGroups.forEach((el) => el.markAllTouched());
    }
    this.markAsTouched();
  }
}
