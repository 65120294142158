import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { CreateCommentRequest, UpdateCommentRequest } from 'src/lib/newBackendTypes/comment';
import { EntityCommentsComponent } from '../entity-comments/entity-comments.component';

@UntilDestroy()
@Component({
  selector: 'comments-popup',
  templateUrl: './comments-popup.component.html',
})
export class CommentsPopupComponent implements OnInit, OnDestroy {
  @ViewChild('comments', { static: false })
  comments: EntityCommentsComponent;

  entityId: number;
  entityType: SourceEntityType;

  formControl: UntypedFormControl;

  constructor(private spinnerService: SpinnerService) {
    this.formControl = new UntypedFormControl();
  }

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: (CreateCommentRequest | UpdateCommentRequest)[]) => {
      this.saveComments(value);
    });
  }

  async saveComments(value: (CreateCommentRequest | UpdateCommentRequest)[]) {
    if (value && value.length > 0) {
      let rid = this.spinnerService.startRequest('Saving Comment');
      await lastValueFrom(this.comments.save(this.entityId));
      this.spinnerService.completeRequest(rid);
      if (this.comments) {
        this.comments.loadSubForm();
      }
    }
  }

  ngOnDestroy(): void {}
}
