import { Component, ViewEncapsulation } from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'horizontal-field',
  templateUrl: './horizontal-field.component.html',
  styleUrls: ['./horizontal-field.component.scss', '../form-field/form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HorizontalFieldComponent extends FormFieldComponent {}
