import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService {
  public userChangeEvent: BehaviorSubject<AccountInfo>;

  public loggedOutEvent: BehaviorSubject<void>;

  lastStatus: InteractionStatus = null;

  constructor(
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    public cookieService: CookieService,
    public router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.userChangeEvent = new BehaviorSubject(null);
    this.loggedOutEvent = new BehaviorSubject(null);

    this.setRedirectCallbacks();
  }

  setRedirectCallbacks() {
    this.broadcastService.inProgress$
      .pipe(
        filter((is) => is === InteractionStatus.None),
        take(1)
      )
      .subscribe((payload) => {
        this.lastStatus = payload;
        let account = this.msalService.instance.getActiveAccount();
        const accounts = this.msalService.instance.getAllAccounts();
        if (!account && accounts.length > 0) {
          this.msalService.instance.setActiveAccount(accounts[0]);
          account = accounts[0];
        }
        if (!!account) {
          this.userChangeEvent.next(account);
        }
      });

    this.broadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
      if (!environment.production) console.debug(result);
    });

    this.msalService.handleRedirectObservable().subscribe((res) => {
      if (!environment.production) console.debug('handled', res);
    });
  }

  login() {
    if (this.lastStatus === InteractionStatus.None) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        extraQueryParameters: { responseMode: 'post' },
      } as RedirectRequest);
    }
  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.msalConfig.logoutUri,
    });
  }

  forceLogout() {
    const account = this.msalService.instance.getActiveAccount();
    this.msalService.logout({
      account,
      postLogoutRedirectUri: environment.msalConfig.logoutInactiveUri,
    });
  }
}
