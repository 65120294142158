import { Subset } from '../generics';
import { Advance } from './advance';
import { Contact } from './contact';
import { PhysicalContract } from './contract';
import { BaseDiscrepancy } from './discrepancy';
import { PhysicalDraft } from './draft';
import { YN } from './enums';
import { InvoiceHeader } from './invoice';
import { LetterOfCredit } from './letterOfCredit';
import { LoCPresentations } from './letterOfCreditPresentation';
import { WaTermGroup } from './waTermGroup';
import { WaTermLine } from './waTermLine';
import { WaTermWhiteList } from './waTermWhiteList';

export enum TermGroups {
  CREDIT = 2,
  DP = 3,
  LC = 4,
  OTHER = 5,
  TT = 7,
}

export type PaymentTerm = {
  id: number;
  name: string;
  groupId: TermGroups;
  authorizedUseOnly: YN;
  archived: YN;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  termGroup?: WaTermGroup;
  termLines?: WaTermLine[];
  whiteList?: WaTermWhiteList[];
  supplierDiscrepancies?: BaseDiscrepancy[];
  customerDiscrepancies?: BaseDiscrepancy[];
  lettersOfCredit?: LetterOfCredit[];
  locPresentations?: LoCPresentations[];
  vouchers?: InvoiceHeader[];
  advances?: Advance[];
  deliveryAdvances?: Advance[];
  contracts?: PhysicalContract[];
  drafts?: PhysicalDraft[];
};

export type UpsertPaymentTermRequest = Subset<PaymentTerm, 'name' | 'groupId' | 'authorizedUseOnly' | 'archived', 'id'> & {
  lines: UpsertPaymentTermLines[];
  whiteList: UpsertPaymentTermWhiteList[];
};

export type UpsertPaymentTermLines = Subset<WaTermLine, 'daysAdjusted' | 'termPercentage' | 'termFixedAmount' | 'isAdvance' | 'isDefault' | 'paymentTermId' | 'termDateId', 'id'> & {
  isUpdated: boolean;
};

export type UpsertPaymentTermWhiteList = Subset<WaTermWhiteList, 'contactId', 'paymentTermId'>;

export type PaymentTermForm = Subset<PaymentTerm, 'name' | 'termGroup' | 'authorizedUseOnly' | 'archived', 'id'> & {
  lines?: PaymentTermLinesForm[];
  whiteList?: Contact[];
};

export type PaymentTermLinesForm = Subset<WaTermLine, 'daysAdjusted' | 'termPercentage' | 'termFixedAmount' | 'isAdvance' | 'isDefault' | 'termDate', 'paymentTermId' | 'id'> & {
  linePosition: number;
  isUpdated: boolean;
};

export type ArchiveUnarchivePaymentTerm = Pick<PaymentTerm, 'id' | 'archived'>;

export const upsertPaymentTerm = async (formVal: PaymentTermForm) => {
  const lines: PaymentTermLinesForm[] = [];
  if (formVal.lines && formVal.lines.length > 0) {
    for (const line of formVal.lines) if (line.termDate) lines.push(line);
  }
  const showAuthorizedContacts = formVal.authorizedUseOnly === YN.Y;
  const request: UpsertPaymentTermRequest = {
    id: formVal.id ?? null,
    name: formVal.name,
    groupId: formVal.termGroup.termGroupKey,
    authorizedUseOnly: formVal.authorizedUseOnly ?? YN.N,
    archived: formVal.archived ?? YN.N,
    lines: lines.map((line) => ({
      paymentTermId: line.paymentTermId ?? null,
      id: line.id ?? null,
      daysAdjusted: line.daysAdjusted ?? 0,
      termPercentage: line.termPercentage ?? 0,
      termFixedAmount: line.termFixedAmount ?? 0,
      isAdvance: line.isAdvance,
      isDefault: line.isDefault,
      termDateId: line.termDate.id,
      isUpdated: line.isUpdated,
    })),
    whiteList: formVal.whiteList && showAuthorizedContacts ? formVal.whiteList.map((wl) => ({ contactId: wl.id, paymentTermId: formVal.id ?? null })) : [],
  };
  return request;
};
