import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { dateGetter } from 'src/lib/agGridFunctions';
import { earlierOrEqualThanValidator, laterThanValidator } from 'src/lib/genericValidators';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { PropertyDocument } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'shipment-update-value-date',
  templateUrl: './shipment-update-value-date.component.html',
  styleUrls: ['./shipment-update-value-date.component.scss'],
})
export class ShipmentUpdateValueDateComponent implements ModalFormComponent<UpdateShipmentValueDateForm, PropertyDocument> {
  form: TypedFormGroup<UpdateShipmentValueDateForm>;
  popup = true;
  popupObservable: Observable<UpdateShipmentValueDateForm>;
  popupObserver: Observer<UpdateShipmentValueDateForm>;

  shipment: PropertyDocument;
  constructor() {
    this.form = new TypedFormGroup<UpdateShipmentValueDateForm>({
      valueDate: new UntypedFormControl(),
    });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  reevaluateDate() {
    this.form.controls['valueDate'].updateValueAndValidity();
  }

  prefillForm(row: PropertyDocument) {
    this.shipment = row;
    this.form.patchValue({
      valueDate: dateGetter(this.shipment.valueDate),
    });

    if (this.shipment.chunkDate) {
      const chunkDate = dateGetter(this.shipment.chunkDate);
      const chunkDateAfterValidator = dateGetter(this.shipment.chunkDate);
      chunkDateAfterValidator.setFullYear(chunkDate.getFullYear() + 1);
      const chunkDateBeforeValidator = dateGetter(this.shipment.chunkDate);
      chunkDateBeforeValidator.setMonth(chunkDate.getMonth() - 1);

      this.form.get('valueDate').setValidators([
        Validators.required,
        earlierOrEqualThanValidator(
          chunkDateAfterValidator,
          `Cannot be later than ${chunkDateAfterValidator.toLocaleDateString('en-US', {
            timeZone: 'UTC',
          })}`
        ),
        laterThanValidator(
          chunkDateBeforeValidator,
          `Cannot be earlier than ${chunkDateBeforeValidator.toLocaleDateString('en-US', {
            timeZone: 'UTC',
          })}`
        ),
      ]);
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): UpdateShipmentValueDateForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    let formVal = this.form.value;
    return {
      valueDate: formVal.valueDate,
    };
  }
}

export type UpdateShipmentValueDateForm = Pick<PropertyDocument, 'valueDate'>;
