export interface IStateful<T> {
  /**
   * Generic state function save state of current page to the store so it can be recalled if the browser is reloaded or navigated back to.
   *
   * @param state The state to be saved to the store.
   */

  _saveState(): T;

  /**
   * Generic state function loads previous state of this page.
   *
   * @param state The state to be loaded from store.
   */
  _loadState(state: T): void;
}

export function isStateful<T>(component: any): component is IStateful<T> {
  return '_saveState' in component && typeof component._saveState === 'function' && '_loadState' in component && typeof component._loadState === 'function';
}
