import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Observable, Subscription, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityContainer } from 'src/lib';
import { IStateful, isStateful } from 'src/lib/hasState';
import { isRoutable } from 'src/lib/isRoutable';
import * as uuid from 'uuid';
import { SET_ANCHOR_POINT } from '../../reducers/actions';
import { NavigationState } from '../../reducers/layout';
import { NotificationService } from '../../services/notification.service';
import { EntityVersionEvent, SocketIOService, WEB_SOCKET_THALOS_GET_USERS_EVENT } from '../../services/socket.io.service';
import { Store } from '../../services/store.service';
import { getEntityName } from 'src/lib/newBackendTypes';

export const timeoutSecondsDefault: number = 600;
export const timeoutDialogSecondsDefault: number = 300;

export const broadcastChannel = environment.broadcastChannel + '-' + environment.name;
export const channelId = uuid.v4();

@Component({
  selector: 'roco-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'frontend';
  dummy = { a: 'a' };
  device: 'tablet' | 'mobile' | 'desktop' = 'desktop';
  tablet: boolean = false;

  timeout: Observable<number>;
  timeoutSubscription: Subscription;
  timeoutSeconds: number;
  timeoutDialogSeconds: number;

  userCleared = false;

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:click', ['$event'])
  refresh() {
    if (!this.dialog) {
      this.resetTimer();
    }
  }

  dialog: DialogRef;

  iframe: boolean;

  currentUrl: string = null;
  anchorPoint: NavigationState<any> | null = null;
  currentComponent: IStateful<any> | any | null = null;

  channel: BroadcastChannel;
  entityVersionEvent: EntityVersionEvent;

  constructor(
    private store: Store,
    private titleService: Title,
    private router: Router,
    private hotkeys: HotkeysService,
    private notificationService: NotificationService,
    private socketIOService: SocketIOService
  ) {
    this.refresh();

    this.timeout = interval(1000);
    this.iframe = window !== window.parent && !window.opener;

    this.timeoutSubscription = this.timeout.subscribe((val) => {});

    let existingAnchorPointRaw = sessionStorage.getItem(`thalos-anchor-point-${environment.name}`);
    let existingAnchorPoint: NavigationState<any>;
    try {
      existingAnchorPoint = JSON.parse(existingAnchorPointRaw);
    } catch (e) {
      existingAnchorPoint = null;
    }
    if (existingAnchorPoint && typeof existingAnchorPoint === 'object' && typeof existingAnchorPoint.url === 'string' && existingAnchorPoint.state) {
      this.anchorPoint = existingAnchorPoint;
      this.store.dispatch({ type: SET_ANCHOR_POINT, payload: this.anchorPoint });
    }

    try {
      this.channel = new BroadcastChannel(broadcastChannel);

      this.channel.onmessage = (e) => {
        if (e.data?.message === 'PersistData' && e.data?.id !== channelId && e.data?.target === channelId) {
          const anchorPoint: NavigationState<any> = e.data.data;
          if (anchorPoint && anchorPoint.url && anchorPoint.state) {
            store.dispatch({ type: SET_ANCHOR_POINT, payload: anchorPoint });
            if (this.currentComponent instanceof EntityContainer && !!this.currentComponent.entity) {
              this.currentComponent.setNavigationData(this.currentComponent.entity);
            }
            this.channel.close();
          }
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit() {
    if (navigator.userAgent.includes('iPad')) {
      this.device = 'tablet';
    } else if (navigator.userAgent.includes('iPhone') || navigator.userAgent.toLocaleLowerCase().includes('android')) {
      this.device = 'mobile';
    } else {
      this.device = 'desktop';
    }
    this.hotkeys['_preventIn'] = [];
    this.hotkeys.add(
      new Hotkey(
        ['shift+ctrl+x', 'shift+meta+x'],
        (ev) => {
          localStorage.clear();
          this.notificationService.show('Cache cleared', 'info');

          let anchorPoint = this.store.snapshot((state) => state.layout.anchorPoint);
          if (anchorPoint || this.anchorPoint) {
            this.anchorPoint = null;
            this.store.dispatch({ type: SET_ANCHOR_POINT, payload: null });
            this.notificationService.show('Session data cleared', 'info');
            this.userCleared = true;
          } else {
            this.notificationService.show('No session data found', 'none');
          }

          return false;
        },
        ['input', 'textarea', 'select'],
        'Clear session data'
      )
    );

    if (this.channel) {
      this.channel.postMessage({
        message: 'Init',
        data: null,
        id: channelId,
        path: location.pathname,
      });
    }
  }

  ngOnDestroy() {
    this.timeoutSubscription.unsubscribe();

    if (this.channel) {
      this.channel.close();
    }
  }

  resetTimer() {
    let totalSeconds = environment.timeoutSeconds;
    if (!totalSeconds || typeof totalSeconds !== 'number') totalSeconds = timeoutSecondsDefault;
    this.timeoutSeconds = totalSeconds;

    let totalDialogSeconds = environment.timeoutDialogSeconds;
    if (!totalDialogSeconds || typeof totalDialogSeconds !== 'number') totalDialogSeconds = timeoutDialogSecondsDefault;
    this.timeoutDialogSeconds = totalDialogSeconds;
  }

  async onActivate($event: any) {
    setTimeout(async () => {
      this.currentComponent = await $event;
      const entityType = this.currentComponent?.sourceEntityType;
      const entityNumber = this.currentComponent?.entity ? getEntityName(entityType, this.currentComponent?.entity) : '';
      this.entityVersionEvent = {
        entityId: this.currentComponent?.entity?.id,
        entityNumber: entityNumber !== 'Unknown' ? entityNumber : undefined,
        entityType,
        channelId,
        url: this.router.url,
      };

      this.socketIOService.socket?.emit(WEB_SOCKET_THALOS_GET_USERS_EVENT, this.entityVersionEvent);
    }, 1000);

    this.currentUrl = this.router.url;
    let title = environment.title;
    if (isRoutable($event)) {
      title = `${$event.getTabTitle()} - ${title}`;
      if ($event.onTitleChange) {
        $event.onTitleChange.subscribe((entityTitle) => {
          this.titleService.setTitle(`${entityTitle} - ${environment.title}`);
        });
      }
    }
    this.titleService.setTitle(title);

    if (this.anchorPoint && isStateful($event) && this.anchorPoint.url === this.router.url) {
      $event._loadState(this.anchorPoint.state);
    }

    this.userCleared = false;
  }

  onDeactivate($event: any) {
    let { anchorPointGoTo, block } = this.store.snapshot((state) => ({
      anchorPointGoTo: state.layout.anchorPoint,
      block: state.layout.blockNextAnchor,
    }));

    if (anchorPointGoTo && anchorPointGoTo.url === this.router.url) {
      this.anchorPoint = anchorPointGoTo;
    } else {
      this.anchorPoint = null;
    }

    if (isStateful($event) && !block) {
      let state = $event._saveState();
      this.store.dispatch({ type: SET_ANCHOR_POINT, payload: { state, url: this.currentUrl } });
    } else if (block) {
      this.store.dispatch({ type: SET_ANCHOR_POINT, payload: null });
    }

    this.currentUrl = null;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    if (this.currentComponent && isStateful(this.currentComponent) && !this.userCleared) {
      let state: any;

      try {
        state = this.currentComponent._saveState();
      } catch (e) {
        return;
      }

      if (state) {
        this.store.dispatch({ type: SET_ANCHOR_POINT, payload: { url: this.router.url, state } });
      }
    }
  }
}
