import { enumOptionsFromEnum } from '../helperFunctions';
import { InvoiceHeader } from './invoice';

export enum CommonInvoiceKeywords {
  INVOICING_COLLATERAL = 26041761,
  INVOICING_COLLATERAL_PCT = 26041762,
  SHIPMENT_ID = 236,
  MARKET_PRICE = 60,
  INVOICING_MARKET_DATE = 26041936,
  INVOICING_MARKET_VALUATION_ID = 26041934,
  INVOICING_FORMULA = 26004928,
  INVOICING_MARKET_PRICE = 26004924,
  INVOICING_PREMIUM_MARKET_PRICE = 26004926,
  INVOICING_LINE_NOTE = 26005129,
  INVOICING_FX_RATE = 26157001,
  INVOICING_MATERIAL_PRICE = 26157002,
  INVOICING_TYPE = 26157003,
  INVOICING_NUMBER_OF_DECIMALS = 26157004,
}
export const InvoiceKeywords = enumOptionsFromEnum(CommonInvoiceKeywords);

export type InvoiceKeyword = {
  keywordKey: number;
  invoiceId: number;
  linePosition: number | null;
  invoiceKeywordsKey: number;
  keywordValue: string;
  invoiceHeader?: InvoiceHeader;
};
