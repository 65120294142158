import { from, of } from 'rxjs';
import { BookingUpdateCobComponent } from 'src/app/+modules/+logistics/booking/booking-update-cob/booking-update-cob.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { Booking, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, submitFormCallback } from './types';
import { bookingListPrefillCallback, ListBookingsPrefill } from './updateBooking';

const updateBookingCobCheck: checkPrefillCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.bookingResponses.length === 0) return of('No bookings to be updated.');
  return of(true);
};

const updateBookingCobForm: openFormCallback<ListBookingsPrefill, cobUpdateForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  const title = ` ${prefill.bookingResponses.map((b) => b.number).join(', ')}`;
  return selector.openForm<cobUpdateForm, BookingUpdateCobComponent, ListBookingsPrefill>(BookingUpdateCobComponent, {
    title: prefill.bookingResponses.length > 0 ? `COB Update on Selected Booking(s): ${title}` : `COB Update Booking: ${title}`,
    prefillValue: prefill,
    width: '70%',
  });
};

const updateBookingCobSubmit: submitFormCallback<ListBookingsPrefill, cobUpdateForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (let res of prefill.bookingResponses) {
          let request: cobUpdateRequest = {
            id: res.id,
            lastTrackingDate: getTodayUTC(),
            originalETD: result.originalETD,
            placeOfReceiptETD: result.placeOfReceiptETD,
            loadingPortETD: result.loadingPortETD,
            transhipmentETD: result.transhipmentETD,
            unloadingPortETA: result.unloadingPortETA,
            finalDestinationETA: result.finalDestinationETA,
            vesselId: result.vessel ? result.vessel.shipKey : null,
            voyage: result.voyage,
          };
          const updateBooking = await api.run<Booking>(endpoints.updateBooking, request, null);
          updateBookingResponses.push(updateBooking);
        }
        if (updateBookingResponses) return resolve(updateBookingResponses);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully updated: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were updated and try again if necessary.</div>`);
      })
  );
};

export const updateBookingCobPreset: DynamicFormConstant<ListBookingsPrefill, cobUpdateForm> = {
  allowMultipleRows: true,
  value: DynamicFormType.UPDATE_BOOKING_COB,
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  label: 'COB Update',
  title: 'COB Update',
  getPrefill: bookingListPrefillCallback,
  checkPrefill: updateBookingCobCheck,
  openForm: updateBookingCobForm,
  submitForm: updateBookingCobSubmit,
  endpoints: [endpoints.updateBooking],
};

export type cobUpdateForm = Pick<Booking, 'originalETD' | 'placeOfReceiptETD' | 'loadingPortETD' | 'transhipmentETD' | 'unloadingPortETA' | 'finalDestinationETA' | 'vessel' | 'voyage'>;

export type cobUpdateRequest = Pick<
  Booking,
  'id' | 'lastTrackingDate' | 'originalETD' | 'placeOfReceiptETD' | 'loadingPortETD' | 'transhipmentETD' | 'unloadingPortETA' | 'finalDestinationETA' | 'vesselId' | 'voyage'
>;
