import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { dateGreaterThan, markFormGroupTouched } from 'src/lib/helperFunctions';
import { PaymentTerm } from 'src/lib/newBackendTypes';
import { VoucherType, VoucherTypes } from 'src/lib/newBackendTypes/discrepancy';
import { InvoiceDocumentType, InvoiceDocumentTypes } from 'src/lib/newBackendTypes/invoiceDocumentType';
import { ExpenseType, InvoiceServiceOrderRequest, ServiceOrder } from 'src/lib/newBackendTypes/serviceOrder';
import { TypedFormGroup } from 'src/lib/typedForms';
import { ServiceOrderForm, ServiceOrderLineForm } from '../service-order/service-order.component';
import { paymentTermDropdown } from 'src/lib/commonTypes';

@Component({
  selector: 'core-service-order-invoice',
  templateUrl: './service-order-invoice.component.html',
})
export class ServiceOrderInvoiceComponent implements OnInit, ModalFormComponent<BaseInvoiceServiceOrderRequest> {
  form: TypedFormGroup<InvoiceServiceOrderForm>;
  popup = true;
  popupObservable: Observable<BaseInvoiceServiceOrderRequest>;
  popupObserver: Observer<BaseInvoiceServiceOrderRequest>;

  @Input()
  serviceOrders: (ServiceOrder | (ServiceOrderForm & ServiceOrderLineForm))[];

  @Input()
  serviceOrderIds: string;

  documentTypes = InvoiceDocumentTypes;
  voucherTypes = VoucherTypes;
  paymentTermConfig = paymentTermDropdown();

  documentation: Documentation[] = [];

  expenseType: ExpenseType;

  constructor() {
    this.form = new TypedFormGroup<InvoiceServiceOrderForm>({
      dueDate: new UntypedFormControl(null, [Validators.required, this.dueDateValidator()]),
      paymentTerm: new UntypedFormControl(null, [this.paymentTermValidator()]),
      invoiceDate: new UntypedFormControl(null, Validators.required),
      yourReference: new UntypedFormControl(''),
      documentation: new UntypedFormControl(null, Validators.required),
    });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  ngOnInit(): void {
    if (!this.serviceOrders || this.serviceOrders.length === 0) {
      this.popupObserver.next(null);
    } else {
      let orderPrice = this.serviceOrders.reduce((previous: number, current: ServiceOrder) => previous + current.orderPrice, 0);

      if (orderPrice < 0) {
        this.documentation = [
          {
            label: 'Client Invoice',
            documentType: InvoiceDocumentType.CUSTOMER,
            voucherType: VoucherType.INVOICE,
          },
          {
            label: 'Client Debit Note',
            documentType: InvoiceDocumentType.CUSTOMER,
            voucherType: VoucherType.DEBIT,
          },
          {
            label: 'Supplier Credit Note',
            documentType: InvoiceDocumentType.SUPPLIER,
            voucherType: VoucherType.CREDIT,
          },
        ];
        if (this.expenseType === ExpenseType.PURCHASE) {
          this.form.patchValue({ documentation: this.documentation[2] });
        } else if (this.expenseType === ExpenseType.SALE) [this.form.patchValue({ documentation: this.documentation[0] })];
      } else {
        this.documentation = [
          {
            label: 'Supplier Invoice',
            documentType: InvoiceDocumentType.SUPPLIER,
            voucherType: VoucherType.INVOICE,
          },
          {
            label: 'Supplier Debit Note',
            documentType: InvoiceDocumentType.SUPPLIER,
            voucherType: VoucherType.DEBIT,
          },
          {
            label: 'Client Credit Note',
            documentType: InvoiceDocumentType.CUSTOMER,
            voucherType: VoucherType.CREDIT,
          },
        ];
        if (this.expenseType === ExpenseType.PURCHASE) {
          this.form.patchValue({ documentation: this.documentation[0] });
        } else if (this.expenseType === ExpenseType.SALE) [this.form.patchValue({ documentation: this.documentation[2] })];
      }
    }
  }

  prefillForm(values: InvoiceServiceOrderRequest) {
    this.form.patchValue(values);
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): BaseInvoiceServiceOrderRequest {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    let formVal = this.form.value;
    return {
      documentType: formVal.documentation?.documentType,
      dueDate: formVal.dueDate,
      termKey: formVal.paymentTerm ? formVal.paymentTerm.id : null,
      invoiceDate: formVal.invoiceDate,
      voucherType: formVal.documentation?.voucherType,
      yourReference: formVal.yourReference,
    };
  }

  dueDateValidator() {
    return (control: AbstractControl) => {
      if (!this.form) return;
      let invoiceDate = this.form.value.invoiceDate;

      if (!invoiceDate || !control.value) return null;

      let earlierThan = dateGreaterThan(invoiceDate, control.value);
      return earlierThan ? { custom: 'Due Date cannot be earlier than Invoice Date' } : null;
    };
  }

  paymentTermValidator() {
    return (control: AbstractControl) => {
      if (!this.form) return;
      let orderPrice = this.serviceOrders.reduce((previous: number, current: ServiceOrder) => previous + (current.orderPrice ?? 0), 0);
      if (orderPrice !== 0) return Validators.required(control);
      return null;
    };
  }
}

export type InvoiceServiceOrderForm = Pick<InvoiceServiceOrderRequest, 'bankAccountId' | 'deliveryDate' | 'dueDate' | 'invoiceDate' | 'yourReference'> & {
  paymentTerm?: PaymentTerm;
  documentation: Documentation;
};

export type BaseInvoiceServiceOrderRequest = Omit<InvoiceServiceOrderRequest, 'id'>;

type Documentation = {
  label: string;
  documentType: InvoiceDocumentType;
  voucherType: VoucherType;
};
