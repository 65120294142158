import { AfterViewInit, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/services/graphql.service';
import { Store } from 'src/app/core/services/store.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { FormElementComponent } from '../form-element/form-element.component';
import { GraphqlDropdownConfig } from 'src/lib/graphql/GraphqlDropdownConfig';

@UntilDestroy()
@Component({
  selector: 'dropdown-graphql',
  templateUrl: './dropdown-graphql.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => DropdownGraphqlComponent) }],
})
export class DropdownGraphqlComponent extends FormElementComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('dropdown', { static: false })
  private dropdown: ComboBoxComponent;

  @Input()
  dropdownConfig: GraphqlDropdownConfig<any>;

  @Input()
  autoSelectIfSingleValue?: boolean = false;

  @Input()
  set readonly(val) {
    this._readonly = val;
  }
  get readonly() {
    return this._readonly || (!this.ignoreReadonlyMode && this._readonlyMode) || !this.authorized?.[endpoints.graphql];
  }

  dropdownOptions: any[];
  fetching: boolean;
  filterObservable: BehaviorSubject<any>;

  setValue(value: any) {
    if (typeof value !== 'object' && typeof value !== 'undefined' && !!this.dropdownConfig) {
      let obj = { [this.dropdownConfig.labelField]: value, [this.dropdownConfig.valueField]: value };
      value = obj;
    } else if (!value) {
      this._value = null;
      return;
    } else if (typeof value === 'object' && !!this.dropdownConfig && value[this.dropdownConfig.valueField] === 0) {
      //if id of object is 0, set to null
      this._value = null;
      return;
    }

    if (!!this.dropdownConfig && this.dropdownConfig.labelTransform && !!value) {
      value = { label: this.dropdownConfig.labelTransform(value), ...value };
    }

    //if we already added this value to dropdown options there will be duplicate id's in the list so we need to remove the old one
    if (!!value) this.dropdownOptions = this.dropdownOptions.filter((option) => option[this.dropdownConfig.valueField] !== value[this.dropdownConfig.valueField]);

    if (!!value && !this.dropdownOptions.includes(value) && !Array.isArray(value)) {
      this.dropdownOptions.push(value);
    }

    this._value = value;
  }

  authorized: endpointsAuthorized;

  constructor(public graphqlService: GraphqlService, controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this.dropdownOptions = [];
    this.fetching = false;
    this.filterObservable = new BehaviorSubject(null);
  }

  protected initFilterSubscriber() {
    return this.filterObservable.asObservable().pipe(
      filter((text) => text !== null),
      switchMap((text: string) => {
        if (this.authorized && this.authorized[endpoints.graphql]) {
          this.fetching = true;
          return this.graphqlService.handleFilter(text, this.dropdownConfig);
        } else {
          return of({ list: [] });
        }
      }),
      catchError((error) => {
        this.fetching = false;

        throw error;
      }),
      map((list) => {
        return list.map((val) => {
          let transformedValue = { ...val };

          transformedValue[this.dropdownConfig.labelField] = val[this.dropdownConfig.labelField].toString();

          //use secondary label function to set a custom display based on other fields
          if (!!this.dropdownConfig.secondaryLabelFields) transformedValue.secondaryLabel = this.dropdownConfig.secondaryLabelFields(val);

          //use label transform function to set a custom label based on other fields
          if (!!this.dropdownConfig.labelTransform) transformedValue.label = this.dropdownConfig.labelTransform(val);
          return transformedValue;
        });
      }),
      tap(() => {
        this.fetching = false;
      })
    );
  }

  protected handleFilter(event) {
    this.filterObservable.next(event);
    return this.filterObservable;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dropdownConfig'] && changes['dropdownConfig'].currentValue !== changes['dropdownConfig'].previousValue) {
      this.value = this._value;
    }
  }

  ngOnInit(): void {
    endpointAuthorizationSubscription(this.store, this);

    if (!!this.value) this.value = this.value;

    this.initFilterSubscriber().subscribe((list) => {
      this.dropdownOptions = list;
    });

    if (!!this.autoSelectIfSingleValue && !this.value) {
      this.initFilterSubscriber()
        .pipe(first())
        .subscribe((list) => {
          if (list.length === 1) {
            if (!this.value) {
              this.value = list[0];
              this.onChange(this.value);
              this.controlDir.control.markAsPristine();
            }
            if (list[0] === this.value || (!!this.value && list[0][this.dropdownConfig.valueField] === this.value[this.dropdownConfig.valueField])) this.readonly = true;
          }
        });
      this.handleFilter('');
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.dropdown)
      this.dropdown.filterChange
        .asObservable()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged((a, b) => a === b),
          debounceTime(100)
        )
        .subscribe((text) => {
          if (this.empty || !!text) this.handleFilter(text);
        });
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  comparator(a, b) {
    return a?.[this.dropdownConfig.valueField] === b?.[this.dropdownConfig.valueField];
  }

  onSelectionChange(s) {
    if (s === undefined) {
      this.handleFilter('');
    }
  }
}
