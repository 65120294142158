import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListNotifications } from 'src/lib/newBackendTypes/notificationTypes/listNotifications';
import { NotificationStatus, UpdateNotificationStatusRequest } from 'src/lib/newBackendTypes/notificationTypes/notificationsStatus';
import { markNotificationsVisuallyAsRead } from '../notification-center.component';
import { SocketIOService } from 'src/app/core/services/socket.io.service';

@Component({
  selector: 'roco-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, AfterViewInit {
  @Input()
  notification: ListNotifications;

  constructor(protected api: ThalosApiService, protected router: Router, private socketIOService: SocketIOService, private store: Store) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.notification && this.notification.status === NotificationStatus.READ) markNotificationsVisuallyAsRead(this.socketIOService, this.notification.transactionId, false, true);
  }

  clickEvents(event: Event) {
    // If event is from 'mark as read' button then changes notification status
    const eventAttributes = event.target['attributes'];
    const eventValue = eventAttributes.value?.value;
    if (eventValue) {
      const eventClass = eventAttributes.class?.value;
      if (eventValue === 'markAsReadBtn' && !eventClass.match(/disabled/)) {
        const user = this.store.snapshot((state) => state.user.user);
        const notificationStatusId = this.notification.statusId;

        if (user && notificationStatusId) {
          const request: UpdateNotificationStatusRequest = {
            status: NotificationStatus.READ,
            userId: user.id,
            notificationStatusIdArr: notificationStatusId,
          };

          markNotificationsVisuallyAsRead(this.socketIOService, this.notification.transactionId, false);
          this.api.run<NotificationStatus[]>(endpoints.updateNotificationStatus, request, null);
        }
      } else if (eventValue.match(/\/portal\//)) {
        // If event is from 'link' then gets the route
        window.open(eventValue);
      }
    }
  }
}
