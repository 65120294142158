<ng-container *ngIf="!currentUser"> Obtaining user information... </ng-container>
<ng-container *ngIf="!!currentUser && !loadingComments">
  <div class="comment-list">
    <div *ngFor="let comment of _value" class="comment">
      <ng-container *ngIf="comment.editing === false">
        <div class="comment-header">
          <span class="bold-text"> {{ comment?.commentOwner?.firstName }} {{ comment?.commentOwner?.lastName }} </span><span> - </span>
          <span class="bold-text"> Updated on {{ comment.updateDate || comment.creationDate | date : 'short' }} </span>
          <div
            class="k-icon k-i-edit cursor-pointer"
            style="margin-left: 5px"
            *ngIf="comment.owner === currentUser?.id || (comment.shared === 'Y' && updateAuthorized)"
            (click)="toggleEditComment(comment)"
          ></div>
          <span style="padding-left: 15px"> {{ comment?.noteCategory?.categoryName }} </span>
          <div class="k-icon k-i-delete cursor-pointer" style="margin-left: auto" *ngIf="deleteAuthorized" (click)="comment.owner === currentUser?.id && clickDelete(comment)"></div>
        </div>
        <div class="comment-text" [ngClass]="{ 'show-all': comment.state === 'show', hide: comment.state === 'hide' }">
          <p [innerHTML]="comment.state === 'hide' ? comment.croppedText : comment.visibleText"></p>
        </div>
        <div *ngIf="comment.state !== 'short'" class="read-more cursor-pointer" (click)="readMore(comment)">
          <span *ngIf="comment.state === 'hide'">Read more</span>
          <span *ngIf="comment.state === 'show'">Show less</span>
        </div>
      </ng-container>
      <ng-container *ngIf="comment.editing === true">
        <div [formGroup]="editCommentFG" class="edit-comment">
          <div class="row no-pad">
            <vertical-field name="Category" class="col-12 col-md-6 col-lg-3 col-xxl-2">
              <dropdown-wrapper [formControlName]="'category'" [dropdownConfig]="categoryDropdown" [placeholder]="'Category'"></dropdown-wrapper>
            </vertical-field>
          </div>
          <textarea-wrapper class="tall-textarea" [ignoreReadonlyMode]="true" [formControlName]="'text'"> </textarea-wrapper>
          <div *ngIf="editCommentFG.invalid && editCommentFG.touched">
            <span class="error-label">Comment must be longer</span>
          </div>
          <div class="new-comment-footer">
            <checkbox-wrapper [ignoreReadonlyMode]="true" *ngIf="currentUser?.id === comment.owner" [formControlName]="'shared'" [valueMask]="{ true: 'Y', false: 'N' }" [label]="'Shared'">
            </checkbox-wrapper>
            <button style="margin-left: auto" type="button" (click)="toggleEditComment(comment)" kendoButton>Discard</button>
            <button type="button" (click)="saveComment(comment)" kendoButton>
              {{ _readonlyMode ? 'Save' : 'Done' }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="newCommentVisible">
    <div [formGroup]="newCommentFG" class="new-comment">
      <div class="row no-pad">
        <vertical-field name="Category" class="col-12 col-md-6 col-lg-3 col-xxl-2">
          <dropdown-wrapper [formControlName]="'category'" [dropdownConfig]="categoryDropdown" [placeholder]="'Category'"></dropdown-wrapper>
        </vertical-field>
      </div>
      <textarea-wrapper class="tall-textarea" [ignoreReadonlyMode]="true" [formControlName]="'text'"> </textarea-wrapper>
      <div *ngIf="newCommentFG.invalid && newCommentFG.touched">
        <span class="error-label">Comment must be longer</span>
      </div>
      <div class="new-comment-footer">
        <checkbox-wrapper [ignoreReadonlyMode]="true" [formControlName]="'shared'" [valueMask]="{ true: 'Y', false: 'N' }" [label]="'Shared'"> </checkbox-wrapper>
        <button style="margin-left: auto" type="button" (click)="toggleNewComment()" kendoButton>Discard</button>
        <button type="button" (click)="submitNewComment()" kendoButton>
          {{ _readonlyMode ? 'Save' : 'Add' }}
        </button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!newCommentVisible && createAuthorized" style="display: flex; justify-content: flex-end">
    <button type="button" (click)="toggleNewComment()" kendoButton>Add Comment</button>
  </div>
</ng-container>
