import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { intFormat } from 'src/lib/commonTypes';
import { ListLayout } from 'src/lib/views';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { YN } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'flex-layout-popup',
  templateUrl: './flex-layout-popup.component.html',
})
export class FlexLayoutPopupComponent implements ModalFormComponent<ListLayoutForm> {
  form: TypedFormGroup<ListLayoutForm>;
  popup = true;
  originalLayout?: any;
  userId!: number;
  isFlex: boolean;

  intFormat = intFormat();

  constructor() {
    this.form = new TypedFormGroup<ListLayoutForm>({
      name: new UntypedFormControl('', Validators.required),
      shared: new UntypedFormControl(YN.N),
      saveAsNew: new UntypedFormControl(),
      id: new UntypedFormControl(null),
      makeDefault: new UntypedFormControl(YN.N),
      groupsExpanded: new UntypedFormControl(0),
    });
  }

  ngOnInit(): void {}

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  clickSaveAsNew() {
    markFormGroupTouched(this.form);
    this.form.patchValue({ saveAsNew: true });
    this.allowSubmit();
    return this.submit();
  }

  clickModify() {
    markFormGroupTouched(this.form);
    this.allowSubmit();
    this.form.patchValue({ saveAsNew: false });
    this.allowSubmit();
    return this.submit();
  }

  submit() {
    return this.form.value;
  }

  prefillForm(v) {
    if (!!v) {
      this.form.patchValue(v);
    }
  }
}

export type ListLayoutForm = Pick<ListLayout, 'name' | 'id' | 'shared' | 'groupsExpanded'> & {
  saveAsNew: boolean;
  makeDefault: YN;
};
