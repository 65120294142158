<kendo-multiselect
  #multiselect
  [readonly]="readonly"
  [data]="filteredData"
  [filterable]="true"
  [ngClass]="{ readonly: readonly }"
  [valueField]="'value'"
  [textField]="'label'"
  [(ngModel)]="_value"
  [valuePrimitive]="true"
  [placeholder]="placeholder"
  (removeTag)="tagRemoved($event)"
  [itemDisabled]="itemDisabled || neverDisable"
  (open)="onOpen()"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  (filterChange)="handleFilter($event)"
>
</kendo-multiselect>
