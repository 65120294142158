import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarComponent } from '@progress/kendo-angular-dateinputs';
import { debounceTime } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { Permissions } from 'src/lib/componentPermissions';
import { dollarAmountFormat, toBradyUTCDate } from 'src/lib/helperFunctions';
import { DynamiteMarketPriceSetId, MarketDataView } from 'src/lib/newBackendTypes/marketDataView';
import { toLocalDate, toUTCDate } from 'src/lib/toUTCDate';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@UntilDestroy()
@Component({
  selector: 'market-valuation-date-selector',
  templateUrl: './market-valuation-date-selector.component.html',
  styleUrls: ['./market-valuation-date-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@Permissions('MarketValuationDateComponent', [endpoints.listMarketClosingPrices])
export class MarketValuationDateComponent implements OnInit, OnDestroy, ModalFormComponent<MarketDateResult> {
  @ViewChild('calendar')
  calendar!: CalendarComponent;

  @Input()
  marketValuationId!: number;

  _highlights: Map<string, string> = new Map();
  @Input()
  set highlights(highlights: { date: Date; name: string }[]) {
    this._highlights = new Map();
    for (const highlight of highlights) {
      this._highlights.set(highlight.date.toLocaleDateString(), highlight.name);
    }
  }

  @Input()
  isPremiumMarket!: boolean;

  prices: { [key: string]: number };

  value: Date;

  popup = true;

  currencyCode: string;

  constructor(private spinner: SpinnerService, private thalosApi: ThalosApiService, private commonData: CommonDataService) {
    this.prices = {};
  }

  ngOnInit(): void {
    const valuation = this.commonData.staticMarketValuations.value.find((v) => v.valuationId === this.marketValuationId);

    const currency = this.commonData.staticCurrencies.value.find((c) => c.id === valuation?.currencyId);

    this.currencyCode = currency?.code;
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.calendar.activeViewDateChange.pipe(debounceTime(250), untilDestroyed(this)).subscribe((date) => {
      this.fetchNewDates(date);
    });
  }

  fetchNewDates(date: Date) {
    //get new dates
    let date1 = new Date(date);
    let date2 = new Date(date);
    date1.setMonth(date1.getMonth() - 1);
    date2.setMonth(date2.getMonth() + 1);

    //check if data already exists for these dates
    if (!!this.getPrice(date1) && !!this.getPrice(date2)) return;

    const start = toBradyUTCDate(toUTCDate(date1));
    const end = toBradyUTCDate(toUTCDate(date2));
    const priceSetId = this.isPremiumMarket ? DynamiteMarketPriceSetId.MWTI : undefined;

    const rid = this.spinner.startRequest(randomFetchSynonym() + ' market values', 0, true, true);
    this.thalosApi
      .rpc<ListResponse<MarketDataView>>(
        endpoints.listMarketClosingPrices,
        {
          filters: {
            marketValuationId: this.marketValuationId,
            bradyMarketDate: [start, end],
            priceSetId,
          },
        },
        { list: [], count: 0 }
      )
      .subscribe((res) => {
        this.spinner.completeRequest(rid);
        for (let price of res.list) {
          const date = new Date(price.marketDate);
          if (date) {
            this.prices[toLocalDate(date).toLocaleDateString()] = price.marketPrice;
          }
        }
      });
  }

  getPrice(d: Date) {
    const price = this.prices[d.toLocaleDateString()];
    const decimals = price < 100 ? 4 : 2;
    return price ? `${dollarAmountFormat(price, decimals)}` : '';
  }

  getHighlightName(d: Date) {
    if (!this._highlights || !(d instanceof Date)) return undefined;
    return this._highlights.get(d.toLocaleDateString());
  }

  prefillForm() {}

  allowSubmit() {
    return !!this.value && !!this.prices[this.value.toLocaleDateString()];
  }
  submit() {
    return { date: this.value, value: this.prices[this.value.toLocaleDateString()] };
  }
}

export type MarketDateResult = {
  date: Date;
  value: number;
};
