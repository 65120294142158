import * as _ from 'lodash';
import { from } from 'rxjs';
import { PayOrderEntityComponent, PayOrderForm } from 'src/app/+modules/+accounting/pay-order/pay-order-entity/pay-order-entity.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC, getUniqueArray, removeLineBreaks } from 'src/lib/helperFunctions';
import { ListResponse } from 'src/lib/ListResponse';
import {
  BankAccount,
  CommonUnits,
  Contact,
  CreateVoucherRequest,
  EntryOperation,
  InvoiceCompoundType,
  InvoiceEntrySource,
  InvoiceStatus,
  InvoiceTypesFromCompoundType,
  SourceEntityType,
  YN,
} from 'src/lib/newBackendTypes';
import { OpenPayableItem, PayOrderHeader, PayOrderLineType, UpsertPayOrderLineRequest, UpsertPayOrderRequest } from 'src/lib/newBackendTypes/payOrder';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { QueryFilters } from 'src/lib/generics';

export type CreatePayOrderPrefill = {
  counterparty: Contact;
  items: OpenPayableItem[];
  listBankAccounts: BankAccount[];
};

const createPayOrderPrefill: prefillCallback<CreatePayOrderPrefill> = (delegate, id, colum, data) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const payableIds = Array.isArray(id) ? id : [id];
      const entryTypes = data ? data.map((item) => item.entryType || item.ENTRY_TYPE).filter((entryType) => entryType !== undefined) : undefined;
      const uniqueEntryTypes = entryTypes && entryTypes.length > 0 ? getUniqueArray(entryTypes) : undefined;
      const filters: QueryFilters<OpenPayableItem> = { entryId: payableIds, entryType: uniqueEntryTypes ?? undefined };

      const payableItemsResponse = await api.run<ListResponse<OpenPayableItem>>(endpoints.listOpenPayableItems, { filters: filters });

      const items = payableItemsResponse.list;
      if (items.length < 1) return 'No items selected or available to be paid';

      const counterpartyId = items[0].counterpartyId;
      if (items.some((item) => item.counterpartyId !== counterpartyId)) {
        return 'All items selected should belong to the same counterparty';
      }

      const currencyId = items[0].currencyId;
      if (items.some((item) => item.currencyId !== currencyId)) {
        return 'All items selected must have the same currency';
      }

      const counterparty = (await api.run(endpoints.getContact, {
        filters: { id: counterpartyId },
      })) as Contact;
      if (!counterparty) return 'Unable to fetch information for the counterparty';

      const bankAccountResponse = await api.run<ListResponse<BankAccount>>(endpoints.listBankAccounts, { filters: { id: { not: 0 } } }, null);

      const listBankAccounts = bankAccountResponse.list;

      return { counterparty, items, listBankAccounts };
    })()
  );
};

const openPayOrderForm: openFormCallback<Partial<PayOrderForm>, PayOrderHeader> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm(PayOrderEntityComponent, {
    title: `Create Pay Order: ${prefill.counterparty.displayName}`,
    prefillValue: prefill,
    height: '98%',
    width: '98%',
    hideDefaultActions: true,
    initializer: (c) => {
      c.showSaveButtonsInPopup = true;
      c.popUpOptions = {
        ...c.popUpOptions,
        blockRedirect: true,
        reloadInPlace: (e) => {
          if (!e) return null;
          let entity = Array.isArray(e) ? e[0] : e;
          return {
            ...entity,
          };
        },
      };
      c.popupCallback = (response) => {
        if (!!response) {
          c.selectorApi.close();
        }
      };
    },
  });
};

export const submitPayOrder: submitFormCallback<Partial<PayOrderForm>, PayOrderHeader> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PayOrderHeader>((resolve, reject) => {
      (async () => {
        const request = await createPayOrderRequest(delegate, form);

        const payOrder = await api.run<PayOrderHeader>(endpoints.createPayOrder, request, null);
        if (payOrder) return resolve(payOrder);
        return reject('Unknown result. Please check if the Pay Order was created and try again if necessary.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Pay Order successfully created: \n Number: ${res.payOrderNumber}</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createPayOrderPreset: DynamicFormConstant<Partial<PayOrderForm>, PayOrderHeader> = {
  allowMultipleRows: true,
  title: 'Create Pay Order',
  value: DynamicFormType.CREATE_PAY_ORDER,
  label: 'Create Pay Order',
  entityType: SourceEntityType.PAYABLE_ITEM_KEY,
  getPrefill: createPayOrderPrefill,
  openForm: openPayOrderForm,
  submitForm: submitPayOrder,
  endpoints: [endpoints.listOpenPayableItems, endpoints.getContact, endpoints.listBankAccounts, endpoints.createPayOrder, endpoints.createVoucher],
};

export const createPayOrderRequest = async (delegate, form) => {
  const api = delegate.getService('api');
  const cleanLines: UpsertPayOrderLineRequest[] = [];
  for (const formLine of form.payOrderLines) {
    let requestLine: UpsertPayOrderLineRequest;
    switch (formLine.type) {
      case PayOrderLineType.BANK_FEE:
        if (_.round(formLine.amount, 2) == 0) continue;

        const companyBankFeeAccounts = bankFeeAccounts[form.ourAccount.ownerCode];
        if (!companyBankFeeAccounts) throw new Error(`Bank Fee accounts unavailable for company ${form.ourAccount.ownerCode}`);
        const bankFeeAccount = companyBankFeeAccounts[form.ourAccount.currKey];
        if (!bankFeeAccount) throw new Error(`Bank Fee accounts unavailable for currency ${form.ourAccount.ownerCode}`);

        const voucherRequest: CreateVoucherRequest = {
          bankAccountId: form.ourAccount.id,
          companyId: form.ourAccount.ownerCode,
          counterpartyId: form.counterparty.id,
          ...InvoiceTypesFromCompoundType(formLine.amount < 0 ? InvoiceCompoundType.SUPPLIER_CREDIT : InvoiceCompoundType.SUPPLIER_DEBIT),
          dueDate: form.payCreationDate,
          entryOperation: EntryOperation.NORMAL,
          entrySource: InvoiceEntrySource.MANUAL,
          externalReference: '',
          freeText: form.description,
          invoiceInformation: '',
          invoiceTitle: 'Thalos WT Bank Fee',
          invoiceStatus: InvoiceStatus.TO_BE_VERIFIED,
          ourReference: formLine.payComment,
          payOtherInstructions: '',
          printDate: getTodayUTC(),
          vatExtrasKey: 0,
          vatRateDate: getTodayUTC(),
          yourReference: '',
          lines: [
            {
              linePosition: 1,
              amount: Math.abs(formLine.amount),
              anlCorrectQuantity: 0,
              artPrice: Math.abs(formLine.amount),
              artQuantity: 1,
              artText: formLine.payComment,
              bookQuantity: YN.Y,
              accKey: bankFeeAccount, // Funds transfers receives (60634)
              externalRef: '',
              lineBlobValue: 'Thalos Bank Fee',
              unitKey: CommonUnits.EA,
              priceUnitKey: CommonUnits.EA,
              vatExtrasKey: 0,
            },
          ],
          keywords: [],
          paymentTermId: 113610,
        };

        const voucher = await api.run(endpoints.createVoucher, voucherRequest, null, {
          blockRedirect: true,
        });

        requestLine = {
          type: PayOrderLineType.VOUCHER,
          amount: formLine.amount,
          payComment: formLine.payComment,
          voucherKey: voucher.id,
          payOrderId: formLine.payOrderId,
          payOrderLineId: formLine.payOrderLineId,
        };
        break;
      case PayOrderLineType.MANUAL:
        requestLine = {
          type: formLine.type,
          amount: formLine.amount,
          payComment: formLine.payComment,
          accountId: formLine.account.id,
          payOrderId: formLine.payOrderId,
          payOrderLineId: formLine.payOrderLineId,
        };
        break;
      case PayOrderLineType.VOUCHER:
        requestLine = {
          type: formLine.type,
          amount: formLine.amount,
          payComment: formLine.payComment,
          voucherKey: formLine.voucherKey,
          payOrderId: formLine.payOrderId,
          payOrderLineId: formLine.payOrderLineId,
        };
        break;
      case PayOrderLineType.PAYMENT:
        requestLine = {
          type: formLine.type,
          payComment: formLine.payComment,
          paymentEntryId: formLine.paymentEntryId,
          payOrderId: formLine.payOrderId,
          payOrderLineId: formLine.payOrderLineId,
        };
        break;
    }

    cleanLines.push(requestLine);
  }

  const description = removeLineBreaks(form.description);

  const request: UpsertPayOrderRequest = {
    id: form.id,
    ourAccountCode: form.ourAccount.id,
    description,
    checkNumber: form.checkNumber,
    defaultInfoPayLineKey: form.contactBankInformation.id,
    payCreationDate: form.payCreationDate,
    payOrderStatus: form.payOrderStatus,
    lines: cleanLines,
  };
  return request;
};

// TODO Improve the way we obtain the Gl Account to post the Bank Fee
// This map holds the accKey organized by companyId and then by currencyId
export const bankFeeAccounts = {
  2: {
    2: 27021679,
    3: 120379,
    4: 27021682,
  },
  240: {
    3: 124656,
  },
  2222: {
    3: 121520,
  },
  2230: {
    3: 129207,
  },
  6558: {
    3: 24987674,
  },
};
