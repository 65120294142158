<kendo-multiselect
  class="readonly chip-no-actions"
  [(ngModel)]="_value"
  [readonly]="true"
  [valueField]="'shipmentId'"
  #dropdown
  [ngClass]="{ readonly: readonly }"
  [textField]="'shipmentId'"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  [placeholder]="placeholder || 'Shipments'"
>
  <ng-template kendoMultiSelectTagTemplate let-dataItem>
    <span class="template">{{ dataItem.containerMarks }} - {{ dataItem.shipmentId }}</span>
  </ng-template>
</kendo-multiselect>
