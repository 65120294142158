import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'src/lib/MenuItem';

@Component({
  selector: 'roco-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input()
  data: MenuItem[];

  @Input()
  forceClose: boolean;

  constructor() {}

  ngOnInit(): void {}
}
