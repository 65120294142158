import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'roco-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  errorMessage: string;
  authMessage: Observable<string>;
  error = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private msalService: MsalService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService
  ) {
    this.authenticationService.userChangeEvent.subscribe((account) => {
      if (account !== null && !this.router.url.includes('error') && !this.error) {
        // this.router.navigate(['/'])
      }
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    if (this.cookieService.get('autoLogin') !== 'no') {
    }
    if (this.router.url.includes('error')) {
      this.error = true;
      this.router.navigate(['./'], { queryParams: {} });
    }
  }

  ngOnDestroy() {}

  login() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0 && !this.error) {
      if (!this.msalService.instance.getActiveAccount()) {
        this.msalService.instance.setActiveAccount(accounts[0]);
      }
      this.router.navigate(['./']);
      return;
    }
    this.authenticationService.login();
  }
}
