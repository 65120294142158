<div class="d-flex" style="flex-direction: column; width: 250px">
  {{ flexPrompts?.length }}
  <ng-container *ngIf="!!_flexPrompts?.length && !!columns?.length; else elseTemplate">
    <ng-container *ngFor="let prompt of _flexPrompts">
      <ng-container [ngSwitch]="prompt.type">
        <!--PROMPT-->
        <ng-container *ngSwitchCase="3">
          <!--SINGLE VALUE-->
          <ng-container *ngIf="prompt.typeConfiguration.operator !== 11 && prompt.typeConfiguration.operator !== 12; else elseTemplateSimpleMulti">
            <ng-container [ngSwitch]="prompt.typeConfiguration.valueType">
              <ng-container *ngSwitchCase="1">
                <vertical-field [name]="prompt.fieldLabel">
                  <input-wrapper [ignoreReadonlyMode]="true" [formControl]="prompt.formControl" [placeholder]="prompt.label"></input-wrapper>
                </vertical-field>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <vertical-field [name]="prompt.fieldLabel">
                  <numerictextbox-wrapper
                    [ignoreReadonlyMode]="true"
                    [formControl]="prompt.formControl"
                    [placeholder]="prompt.label"
                    [format]="universalNumberFormat"
                    [decimals]="4"
                  ></numerictextbox-wrapper>
                </vertical-field>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <vertical-field [name]="prompt.fieldLabel">
                  <datepicker-wrapper [formControl]="prompt.formControl" [placeholder]="prompt.label"></datepicker-wrapper>
                </vertical-field>
              </ng-container>
              <ng-container *ngSwitchCase="4">
                <vertical-field [name]="prompt.fieldLabel">
                  <datepicker-wrapper [formControl]="prompt.formControl" [placeholder]="prompt.label"></datepicker-wrapper>
                </vertical-field>
              </ng-container>
              <ng-container *ngSwitchDefault> </ng-container>
            </ng-container>
          </ng-container>
          <!--Multiselect-->
          <ng-template #elseTemplateSimpleMulti>
            <vertical-field [name]="prompt.fieldLabel">
              <custom-multiselect [formControl]="prompt.formControl" [placeholder]="prompt.label"></custom-multiselect>
            </vertical-field>
          </ng-template>
        </ng-container>
        <!--DYNAMIC LIST-->
        <ng-container *ngSwitchCase="4">
          <ng-container *ngIf="prompt.typeConfiguration.operator !== 11 && prompt.typeConfiguration.operator !== 12; else elseTemplateMulti">
            <vertical-field [name]="prompt.fieldLabel">
              <dropdown-wrapper [formControl]="prompt.formControl" [placeholder]="prompt.label" [dropdownConfig]="prompt.config"></dropdown-wrapper>
            </vertical-field>
          </ng-container>
          <ng-template #elseTemplateMulti>
            <vertical-field [name]="prompt.fieldLabel">
              <multiselect-wrapper [formControl]="prompt.formControl" [placeholder]="prompt.label" [dropdownConfig]="prompt.config"></multiselect-wrapper>
            </vertical-field>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault> Invalid Filter </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #elseTemplate> No Filter data found </ng-template>
</div>
