export type ATACargoTrackingData = {
  bookingId: number;
  bookingNumber: number;
  operatorId: number;
  operatorName: string;
  shippingLineId: number;
  shippingLineName: string;
  unloadingPortId: number;
  unloadingPortName: string;
  unloadingPortETA: number | null;
  finalDestinationId: number;
  finalDestinationName: string;
  finalDestinationETA: number | null;
  docsToClientDate: number;
  ctsAssignment: number | null;
  cargoArrivalDate: number | null;
  carrierBookingNumber: string;
  carrierTrackingUrl: string | null;
};

export enum BookingTrackingOption {
  ROUTINE_TRACKING = 1,
  ARRIVAL_CONFIRMATION = 2,
}
