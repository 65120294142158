<closable-form [formGroup]="form" [entityId]="getTabTitle()" [sourceEntityType]="sourceEntityType" class="widescreen" [nextEntity]="nextEntity" [previousEntity]="previousEntity" [icon]="PayOrderIcon">
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field name="Counterparty" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'counterparty'" [dropdownConfig]="contactDropdown" [readonly]="isReadOnly"></dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Payment Instructions" class="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown
          [formControlName]="'contactBankInformation'"
          [data]="contactBankAccountDropdown"
          [ngClass]="{
            readonly: isReadOnly || !form.value.counterparty || !contactBankAccountDropdown
          }"
          [readonly]="isReadOnly || !form.value.counterparty || !contactBankAccountDropdown"
          [valueField]="'id'"
          [textField]="'contactBankInformationIden'"
          [secondaryTextField]="'beneficiaryBankName'"
        >
        </simple-dropdown>
      </vertical-field>
      <vertical-field name="Pay Order Date" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'payCreationDate'" [readonly]="isReadOnly"> </datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Pay From Account" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
        <kendo-combobox
          [data]="bankAccountDropdown"
          [formControlName]="'ourAccount'"
          [ngClass]="{ readonly: isReadOnly || !form.value.counterparty }"
          [readonly]="isReadOnly || !form.value.counterparty"
          [valueField]="'id'"
          [textField]="'iden'"
        >
        </kendo-combobox>
      </vertical-field>
      <vertical-field name="Status" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" *ngIf="!!entity">
        <kendo-combobox
          [data]="payOrderStatus"
          [formControlName]="'payOrderStatus'"
          [ngClass]="{ readonly: checkReadOnly }"
          [readonly]="checkReadOnly"
          [valuePrimitive]="true"
          [valueField]="'value'"
          [textField]="'label'"
        >
        </kendo-combobox>
      </vertical-field>
      <vertical-field name="Check Number" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" *ngIf="!!entity && !!isCheck">
        <input-wrapper [formControlName]="'checkNumber'" [readonly]="checkReadOnly"> </input-wrapper>
      </vertical-field>
      <vertical-field name="Confirmation Number" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" *ngIf="!!entity && !!isWire">
        <input-wrapper [formControlName]="'fedRef'" [readonly]="true"></input-wrapper>
      </vertical-field>
      <vertical-field name="Payment Number" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" *ngIf="!!entity && !!isBooked">
        <div class="linksContainer">
          <a entityLink [entityId]="paymentId" [entityType]="paymentEntityType" class="keyLink">{{ paymentNumber }}</a>
          <a entityLink [entityId]="paymentId" [entityType]="paymentEntityType" [newTab]="true">
            <i class="k-icon k-i-hyperlink-open" style="color: rgba(0, 0, 0, 0.85); height: 1.2em"></i>
          </a>
        </div>
      </vertical-field>
    </div>
    <div class="row">
      <vertical-field name="Description" class="col-xl-6 col-lg-6 col-12">
        <textarea-wrapper [formControlName]="'description'" [readonly]="isReadOnly"></textarea-wrapper>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Lines'">
    <pay-order-lines-component
      [formControlName]="'payOrderLines'"
      [payCreationDate]="payCreationDate"
      [companyId]="companyId"
      [currencyId]="currencyId"
      [counterpartyId]="contactId"
      [readonly]="isReadOnly"
      [linesEditable]="linesEditable"
      [totalAmount]="totalAmount"
      [isBooked]="isBooked"
      [isOpen]="isOpen"
    >
    </pay-order-lines-component>
    <div class="row" *ngIf="!!entity && !isBooked">
      <vertical-field name="Original Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-10">
        <numerictextbox-wrapper
          class="vertical-field-1 w-50;"
          style="flex-grow: 1"
          [formControlName]="'totalAmount'"
          [format]="{ style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 2 }"
          [readonly]="true"
        >
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
    </div>
  </collapsible-card>
  <lazy-card [title]="'Documents'" [icon]="DocumentsIcon" *ngIf="documentsAuthorized" #deferDocuments>
    <microsoft-entity-documents
      *ngIf="deferDocuments.loaded"
      #documentsComponent
      formControlName="documents"
      [entityId]="entity?.id"
      [entityType]="sourceEntityType"
      [entity]="entity"
      [showSpinner]="true"
      #lazy
      [entityPath]="entity?.payOrderNumber"
    >
    </microsoft-entity-documents>
  </lazy-card>
  <div class="container-button" [ngClass]="{ 'always-show': showSaveButtonsInPopup }">
    <button class="container-button__button btn btn-sm btn-danger" type="button" *ngIf="!!entity && deleteAuthorized && !popup && isOpen" (click)="clickDelete()">Delete</button>
    <button class="container-button__button btn btn-sm btn-success" type="button" *ngIf="saveAuthorized" [disabled]="!!requestsPending.length || form.status === pendingStatus" (click)="clickSave()">
      Save
    </button>
    <button *ngIf="bookeable && !popup" class="container-button__button btn btn-sm btn-warning" type="button" cyData="submit" (click)="clickBooking()" [disabled]="requestsPending?.length">
      Book
    </button>
    <button
      *ngIf="unbookeable && isBooked && !popup"
      class="container-button__button btn btn-sm btn-warning"
      type="button"
      cyData="submit"
      (click)="clickUnbooking()"
      [disabled]="requestsPending?.length"
    >
      Unbook
    </button>
  </div>
</closable-form>
<entity-footer [entity]="entity"></entity-footer>
