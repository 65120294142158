import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { Observable, Subscriber } from 'rxjs';
import { SelectorApi, SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { dateColumn, enumLabelFormatter, getContextMenuItems } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { warehouseDropdown } from 'src/lib/commonTypes';
import { Permissions } from 'src/lib/componentPermissions';
import { laterThanValidator } from 'src/lib/genericValidators';
import { endpointAuthorizationSubscription, endpointsAuthorized, markFormGroupTouched } from 'src/lib/helperFunctions';
import { Contact, CreateWarehouseMovementRequest, PropertyDocument, WarehouseMovement, WarehouseMovementType, WarehouseMovementTypes } from 'src/lib/newBackendTypes';
import { toLocalDate } from 'src/lib/toUTCDate';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@UntilDestroy()
@Component({
  selector: 'warehouse-movements',
  templateUrl: './warehouse-movements.component.html',
  styleUrls: ['./warehouse-movements.component.scss'],
})
@Permissions('Warehouse Movements', [endpoints.listWarehouseMovements, endpoints.listContacts])
export class WarehouseMovementsComponent implements OnInit, OnDestroy, SelectorComponent<WarehouseMovement[]> {
  popup = true;
  popupObservable: Observable<WarehouseMovement[]>;
  popupSubscriber: Subscriber<WarehouseMovement[]>;

  @Input()
  shipment!: PropertyDocument;

  get valueDate() {
    if (!this.shipment) return null;
    return toLocalDate(this.shipment.valueDate);
  }

  get shipmentId() {
    return this.shipment.id;
  }

  @Input()
  movements: WarehouseMovement[];

  gridOptions: GridOptions;

  nextWarehouseMustBe: WarehouseMovementType | null;
  latestDate: Date;

  warehouseMovementTypes = WarehouseMovementTypes;

  form: TypedFormGroup<WarehouseMovementForm>;

  warehouseDropdown = warehouseDropdown();

  authorized: endpointsAuthorized;

  selectorApi: SelectorApi;

  get createAuthorized() {
    return this.authorized[endpoints.createWarehouseMovement];
  }

  constructor(private api: ThalosApiService, private spinnerService: SpinnerService, store: Store) {
    this.gridOptions = {
      domLayout: 'normal',
      defaultColDef: {
        sortable: false,
        width: 150,
      },
      columnDefs: [
        { field: 'warehouseAlf.lastName', headerName: 'Warehouse' },
        dateColumn('movementDate', 'Date'),
        {
          field: 'movementType',
          headerName: 'Type',
          valueFormatter: enumLabelFormatter(WarehouseMovementTypes),
        },
        { field: 'notes', width: 200 },
        { field: 'operator.displayName', headerName: 'Created By' },
      ],
      getContextMenuItems: getContextMenuItems(this.deleteWarehouseMovementMenuItem()),
    };

    this.popupObservable = new Observable((sub) => {
      sub.next(this.movements);
      this.popupSubscriber = sub;
    });

    this.form = new TypedFormGroup<WarehouseMovementForm>({
      warehouse: new UntypedFormControl(null, Validators.required),
      movementDate: new UntypedFormControl(null),
      movementType: new UntypedFormControl(null, Validators.required),
      notes: new UntypedFormControl(''),
    });

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit(): void {
    if (!this.movements) {
      this.movements = [];
      this.fetchMovements();
    } else {
      this.resetForm();
    }

    this.gridOptions.popupParent = this.popup ? document.querySelector('.k-dialog') : document.querySelector('body');
  }

  ngOnDestroy(): void {
    this.popupSubscriber.unsubscribe();
  }

  fetchMovements() {
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Warehouse Movements');
    this.api.rpc<ListResponse<WarehouseMovement>>(endpoints.listWarehouseMovements, { shipmentId: this.shipmentId }, { list: [], count: 0 }).subscribe((response) => {
      this.movements = response.list.sort(warehouseMovementSorter());
      this.popupSubscriber.next(this.movements);
      this.resetForm();
      this.spinnerService.completeRequest(rid);
    });
  }

  onGridReady(event) {}

  getNextWarehouseMovementType(movements: WarehouseMovement[]): number {
    const result = movements.reduce((prev, curr) => prev + curr.movementType, 0);
    if (result !== 1 && result !== 0) return null;
    return result === 1 ? -1 : 1;
  }

  resetForm() {
    this.nextWarehouseMustBe = this.getNextWarehouseMovementType(this.movements);
    let warehouse: Contact = null;
    if (this.nextWarehouseMustBe === WarehouseMovementType.OUT) warehouse = this.movements.sort(warehouseMovementSorter())[this.movements.length - 1]?.warehouseAlf ?? null;

    const highestDateInMs = Math.max(...this.movements.map((m) => new Date(m.movementDate).getTime()));
    const earliestDate: Date = highestDateInMs ? new Date(highestDateInMs) : null;
    this.form.reset({ notes: '', movementType: this.nextWarehouseMustBe, warehouse });
    const dateValidators = [Validators.required];

    if (earliestDate) dateValidators.push(laterThanValidator(earliestDate, 'Cannot be earlier than last Warehouse Movement'));
    if (this.shipment) dateValidators.push(laterThanValidator(new Date(this.shipment.valueDate), 'Cannot be earlier than Shipment Creation Date'));

    this.form.get('movementDate').setValidators(dateValidators);

    const domLayout = this.movements.length > 10 ? 'normal' : 'autoHeight';
    if (this.gridOptions.api) {
      this.gridOptions.api.setDomLayout(domLayout);
    } else {
      this.gridOptions.domLayout = domLayout;
    }
  }

  clickCreateWarehouseMovement() {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    this.createWarehouseMovement();
  }

  createWarehouseMovement() {
    if (!this.createAuthorized) return;
    const createWarehouseMovementRequest: CreateWarehouseMovementRequest = {
      shipmentId: this.shipmentId,
      movementType: this.form.value.movementType,
      movementDate: this.form.value.movementDate,
      notes: this.form.value.notes,
      warehouseAlfCode: this.form.value.warehouse!.id,
    };

    const rid = this.spinnerService.startRequest('Creating Warehouse Movement');
    this.api.rpc<WarehouseMovement[]>(endpoints.createWarehouseMovement, createWarehouseMovementRequest, null).subscribe((response) => {
      this.spinnerService.completeRequest(rid);
      if (response) {
        this.movements = response.sort(warehouseMovementSorter());
        this.popupSubscriber.next(this.movements);
        this.resetForm();
        this.selectorApi.close();
        this.spinnerService.completeRequest(rid);
      }
    });
  }

  preselectItems(warehouseMovements: WarehouseMovement[]) {
    this.movements = warehouseMovements.sort(warehouseMovementSorter());
    const domLayout = this.movements.length > 10 ? 'normal' : 'autoHeight';
    if (this.gridOptions.api) {
      this.gridOptions.api.setDomLayout(domLayout);
    } else {
      this.gridOptions.domLayout = domLayout;
    }
  }

  deleteWarehouseMovementMenuItem() {
    return (params: GetContextMenuItemsParams) => {
      let data: WarehouseMovement = params.node?.data;
      if (!data) return;
      if (!this.authorized[endpoints.deleteWarehouseMovement]) return;

      if (this.shipment && data.movementDate && new Date(data.movementDate).getTime() < new Date(this.shipment.valueDate).getTime()) {
        return;
      }

      return [
        {
          name: 'Delete',
          action: () => this.deleteWarehouseMovement(data),
        },
      ];
    };
  }

  deleteWarehouseMovement(warehouseMovement: WarehouseMovement) {
    let rid = this.spinnerService.startRequest('Deleting Warehouse Movement');
    this.api.rpc<WarehouseMovement[]>(endpoints.deleteWarehouseMovement, { id: warehouseMovement.id }, null).subscribe((response) => {
      this.spinnerService.completeRequest(rid);
      if (response) {
        this.movements = response.sort(warehouseMovementSorter());
        this.popupSubscriber.next(this.movements);
        this.resetForm();
        this.spinnerService.completeRequest(rid);
      }
    });
  }
}

export function warehouseMovementSorter() {
  return (a: WarehouseMovement, b: WarehouseMovement): number => {
    let date1 = new Date(a.movementDate);
    let date2 = new Date(b.movementDate);

    let diff = date1.getMilliseconds() - date2.getMilliseconds();
    return diff || a.id - b.id;
  };
}

type WarehouseMovementForm = Pick<WarehouseMovement, 'movementDate' | 'movementType' | 'notes'> & {
  warehouse: Contact;
};
