<kendo-combobox
  #dropdown
  [data]="filteredData"
  [filterable]="true"
  [valueField]="'value'"
  [ngClass]="{ readonly: readonly }"
  [textField]="'label'"
  [(ngModel)]="_value"
  [valuePrimitive]="true"
  [placeholder]="placeholder"
  [suggest]="true"
  (open)="onOpen()"
  [readonly]="readonly"
  (blur)="onBlur()"
  (valueChange)="onValueChange(value)"
  (selectionChange)="onSelectionChange($event)"
></kendo-combobox>
