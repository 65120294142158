import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StoreSubscription } from '../../../lib/StoreSubscription';
import { Action, ReducerMap, REDUCERS_TOKEN, State } from '../reducers';

@Injectable()
export class Store {
  private reducers: ReducerMap;
  private state: State;
  private subscribers: BehaviorSubject<any>[] = [];
  private actionSubs: Map<string, BehaviorSubject<any>[] | undefined> = new Map();

  constructor(@Inject(REDUCERS_TOKEN) reducers) {
    this.reducers = reducers;
    this.setState({ type: '*' });
  }

  /** Snapshot of the state at a given time */
  private get value() {
    return _.cloneDeep(this.state);
  }

  public dispatch(action: { type: string; payload?: any }) {
    if (environment.name === 'LOCAL' || environment.name === 'DEV') console.debug('**DISPATCHED ACTION**', action);
    this.setState(action, this.state);
  }

  public subscribe<T>(selectorFn: (state: State) => T, actions?: string[]): StoreSubscription<T> {
    const bs = new BehaviorSubject<any>(this.state);
    const $ = bs.asObservable().pipe(
      map(selectorFn),
      distinctUntilChanged((a, b) => _.isEqual(a, b))
    );
    if (actions !== undefined) {
      actions.forEach((type) => {
        const subs = this.actionSubs.has(type) ? this.actionSubs.get(type) : [];
        this.actionSubs.set(type, [...subs, bs]);
      });
      const unsubscribe = () => {
        actions.forEach((type) => {
          this.actionSubs.set(
            type,
            this.actionSubs.get(type).filter((sub) => sub !== bs)
          );
        });
      };
      return { $, unsubscribe };
    } else {
      const unsubscribe = () => (this.subscribers = this.subscribers.filter((sub) => sub !== bs));
      this.subscribers = [...this.subscribers, bs];
      return { $, unsubscribe };
    }
    //if (environment.name === 'LOCAL' || environment.name === 'DEV') console.warn('New Subscription: ', this.subscribers);
  }

  /**
   * @function Snapshot returns selected state once synchronously without creating an observable
   * @param selectorFn extract desired data from state
   */
  public snapshot<T>(selectorFn: (state: State) => T): T {
    return selectorFn(this.value);
  }

  private setState(action: Action, state?: State) {
    this.state = this.reduce(action, state);
    const value = Object.freeze(this.value);
    this.subscribers.forEach((subscriber) => subscriber.next(value));
    if (this.actionSubs.has(action.type)) this.actionSubs.get(action.type).forEach((subscriber) => subscriber.next(value));
  }

  private reduce(action: Action, state: State): State {
    const newState = {} as State;
    for (const prop in this.reducers) {
      newState[prop] = this.reducers[prop](action, state ? state[prop] : state);
    }
    return newState;
  }
}
