import { EnumLabels, Subset } from '../generics';
import { ContractLine } from './contractLine';
import { ContractListView } from './contractListView';
import { YN } from './enums';
import { FiscalCompany } from './fiscalCompany';
import { InvoiceHeader } from './invoice';
import { Item } from './item';
import { NetPositionAccount } from './netPositionAccount';
import { NetPositionHeader } from './netPositionHeader';
import { NetPositionLines } from './netPositionLine';
import { PropertyDocument } from './propertyDocument';

export enum NetPositionOperations {
  CONVERT_DRAFT_TO_CONTRACT = 1,
  UPDATE_CONTRACT = 2,
  INVOICE_PURCHASE_TICKET_FIXED = 3,
  INVOICE_PURCHASE_TICKET_PROV = 4,
  CREATE_CLIENT_INVOICE_FIXED = 5,
  CREATE_CLIENT_INVOICE_PROV = 6,
  REVERSE_PURCHASE_TICKET = 7,
  REVERSE_CLIENT_INVOICE = 8,
  CREATE_PRICE_FIXATION = 9,
  APPLY_PRICE_FIXATION = 10,
  UNAPPLY_PRICE_FIXATION = 11,
  FUTURES_CREATION = 12,
  UNLINK_POSITION_LINE = 13,
  INITIAL_BALANCE = 14,
  PRICE_OPERATION = 15,
  FUTURES_DELETION = 16,
  FUTURES_UPDATE = 17,
  CONTRACT_CANCELLATION = 18,
  DELETE_PRICE_FIXATION = 19,
  UPDATE_PRICE_FIXATION = 20,
  UPDATE_VOUCHER = 21,
  NIGHT_ROLL_FWD = 22,
  PHYSICAL_ONLY_OPERATION = 23,
  FUTURE_UPDATE_REVERSAL = 24,
  CONTRACT_UPDATE_REVERSAL = 25,
  VOUCHER_UPDATE_REVERSAL = 26,
  FIXATION_UPDATE_REVERSAL = 27,
  PHYSICAL_ONLY_UPDATE = 28,
  MANUAL_UPDATE = 29,
  JOURNAL_CORRECTION = 30,
  JOURNAL_CORRECTION_REVERSAL = 31,
  MANUAL_CREATION = 32,
}

export const NetPositionOperationsLabels: EnumLabels<NetPositionOperations> = [
  { value: NetPositionOperations.CONVERT_DRAFT_TO_CONTRACT, label: 'Convert draft to contract' },
  { value: NetPositionOperations.UPDATE_CONTRACT, label: 'Update contract' },
  { value: NetPositionOperations.INVOICE_PURCHASE_TICKET_FIXED, label: 'Invoice PTicket at Fixed' },
  { value: NetPositionOperations.INVOICE_PURCHASE_TICKET_PROV, label: 'Invoice PTicket at Provisional' },
  { value: NetPositionOperations.CREATE_CLIENT_INVOICE_FIXED, label: 'Create Client Invoice at Fixed' },
  { value: NetPositionOperations.CREATE_CLIENT_INVOICE_PROV, label: 'Create Client Invoice at Provisional' },
  { value: NetPositionOperations.REVERSE_PURCHASE_TICKET, label: 'Reverse of PTicket' },
  { value: NetPositionOperations.REVERSE_CLIENT_INVOICE, label: 'Reverse of Client Invoice' },
  { value: NetPositionOperations.CREATE_PRICE_FIXATION, label: 'Create Price Fixation' },
  { value: NetPositionOperations.APPLY_PRICE_FIXATION, label: 'Apply Price Fixation' },
  { value: NetPositionOperations.UNAPPLY_PRICE_FIXATION, label: 'Unapply Price Fixation' },
  { value: NetPositionOperations.FUTURES_CREATION, label: 'Create Future Contract' },
  { value: NetPositionOperations.UNLINK_POSITION_LINE, label: 'Unlink Net Position Line' },
  { value: NetPositionOperations.INITIAL_BALANCE, label: 'Initial Balance Entry' },
  { value: NetPositionOperations.PRICE_OPERATION, label: 'Price Operation Entry' },
  { value: NetPositionOperations.FUTURES_DELETION, label: 'Future Contract deleted' },
  { value: NetPositionOperations.FUTURES_UPDATE, label: 'Future Contract updated' },
  { value: NetPositionOperations.CONTRACT_CANCELLATION, label: 'Contract Canceled' },
  { value: NetPositionOperations.DELETE_PRICE_FIXATION, label: 'Price Fixation deleted' },
  { value: NetPositionOperations.UPDATE_PRICE_FIXATION, label: 'Price Fixation updated' },
  { value: NetPositionOperations.UPDATE_VOUCHER, label: 'Voucher updated' },
  { value: NetPositionOperations.NIGHT_ROLL_FWD, label: 'Night Roll Forward' },
  { value: NetPositionOperations.PHYSICAL_ONLY_OPERATION, label: 'Physical Only operation' },
  { value: NetPositionOperations.FUTURE_UPDATE_REVERSAL, label: 'Rev Future operation for update' },
  { value: NetPositionOperations.CONTRACT_UPDATE_REVERSAL, label: 'Rev Contract operation for update' },
  { value: NetPositionOperations.VOUCHER_UPDATE_REVERSAL, label: 'Rev Voucher operation for update' },
  { value: NetPositionOperations.FIXATION_UPDATE_REVERSAL, label: 'Rev Fixation operation for update' },
  { value: NetPositionOperations.PHYSICAL_ONLY_UPDATE, label: 'Physical Only updated' },
  { value: NetPositionOperations.MANUAL_UPDATE, label: 'Manual Update' },
  { value: NetPositionOperations.JOURNAL_CORRECTION, label: 'Journal correction' },
  { value: NetPositionOperations.JOURNAL_CORRECTION_REVERSAL, label: 'Journal reversal for correction' },
  { value: NetPositionOperations.MANUAL_CREATION, label: 'Manual Creation' },
];

export type GetNetPositionEntriesRequest = {
  filters?: {
    doi?: number | Date;
    initialDate?: number | Date;
    materialFamilyId: number;
    hedgeableType?: string;
    companyId: number;
    beforeDate?: YN;
    lineFilters?: {
      isValidOperation?: YN;
      contractId?: number;
      elementId?: number;
      shipmentId?: number;
      invoiceId?: number;
      futureId?: number;
      fixationId?: number;
      itemId?: number;
    };
  };
};

export type CreateNetPositionRequest = Subset<NetPositionHeader, 'valueDate' | 'operation' | 'description' | 'hedgeableType' | 'productId' | 'companyId'> & {
  lines: NetPositionLines[];
  company?: FiscalCompany;
};

export type NetPositionJournalEntriesForm = Subset<
  NetPositionLines,
  'fixation' | 'futureContract' | 'quantity' | 'comments',
  'accountId' | 'contractId' | 'elementId' | 'shipmentId' | 'invoiceId' | 'fixationId' | 'futureId' | 'itemId'
> & {
  contract?: ContractListView;
  linePosition: number;
  metalUnitPercentage?: number;
  quantityUnitId?: number;
  physicalUnits?: number;
  account?: NetPositionAccount;
  shipment?: PropertyDocument;
  element?: ContractLine;
  item?: Item;
  invoice?: InvoiceHeader;
  id: number | null;
};
