import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { AdvancePayment, DueDateType } from './advancePayments';
import { Contact } from './contact';
import { PhysicalContract, ShipmentPeriodTypes } from './contract';
import { Currency } from './currency';
import { YN } from './enums';
import { PaymentTerm } from './paymentTerm';

export enum AdvanceType {
  ADVANCE_PAYMENT_GUARANTEE = 7,
  BID_BOND = 6,
  CLIENT_ADVANCE = 9,
  COUNTER_GUARANTEE = 8,
  FCR_INVOICES = 17,
  GUARANTEE = 4,
  PERFORMANCE_BOND_GUARANTEE = 5,
  PAYMENT_ADVANCE = 3,
  STANDBY_GUARANTEE = 15,
}

export const AdvancesTypes = enumOptionsFromEnum(AdvanceType);

export type Advance = {
  id: number;
  counterpartyId: number;
  bankId: number | null;
  companyId: number;
  counterpartyReference: string | null;
  contractId: number;
  currencyId: number;
  deliveryTermId: number | null;
  deliveryType: ShipmentPeriodTypes | null;
  productId: number;
  valueDate: Date;
  dueDate: Date;
  archived: YN;
  number: number;
  operatorId: number | null;
  termId: number;
  originalAmount: number;
  totalAmount: number;
  contractAmount: number;
  type: AdvanceType;
  automaticAmount: YN;
  dueDateType: DueDateType;
  estimatedPaymentDate: Date;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updatedDate: Date | null;
  paymentTerm?: PaymentTerm;
  deliveryTerm?: PaymentTerm;
  bank?: Contact;
  counterparty?: Contact;
  company?: Contact;
  operator?: Contact;
  currency?: Currency;
  contract?: PhysicalContract;
  advancePayments?: AdvancePayment[];
};

export type CreateAdvanceRequest = Pick<Advance, 'valueDate' | 'automaticAmount' | 'dueDate' | 'dueDateType'> & { contractId: number; amount: number };

export type UpdateAdvanceRequest = Subset<Advance, 'id', 'archived'> & Partial<CreateAdvanceRequest>;

export type CreateAdvancePaymentRequest = {
  id: number;
  bankId: number;
  accountId: number;
  valueDate: string;
  amount: number;
  fees: {
    budgetElementId: number;
    amount: number;
  }[];
  paymentsToApply: {
    paymentEntryId: number;
    amount: number;
  }[];
  vouchersToApply: {
    voucherId: number;
    amount: number;
  }[];
};

export type LinkUnlinkAdvancePaymentRequest = Pick<Advance, 'id'> & {
  paymentEntrysToLink: {
    paymentEntryId: number;
    entryText?: string;
    externalRef?: string;
  }[];
};
