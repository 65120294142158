import { Subset } from '../generics';
import { WaTermDateLine } from './waTermDateLine';
import { WaTermLine } from './waTermLine';

export type WaTermDate = {
  id: number;
  label: string;
  termDateLines?: WaTermDateLine[];
  termLines?: WaTermLine[];
};

export enum PaymentTermDate {
  CARGO_DELIVERY = 3,
  LC_OBL = 4,
  DOCS_TO_CUSTOMER = 5,
  BL_DATE = 6,
  CONTRACT_DATE = 7,
  CARGO_ARRIVAL = 9,
  CARGO_ARRIVAL_FOR_LC = 12,
  DOCS_FROM_SUPPLIER = 14,
  CARGO_RELEASE = 15,
  FIXATION = 16,
  DOCS_UPLOAD = 17,
  DRAFT_DOCS_TO_CUSTOMER = 19,
}

export type UpsertPaymentTermDateRequest = Subset<WaTermDate, 'label', 'id'> & {
  lines: UpsertPaymentTermDateLines[];
};

export type UpsertPaymentTermDateLines = Subset<WaTermDateLine, 'termDateId' | 'columnName' | 'adjustmentDays' | 'weight', 'id'> & {
  isUpdated: boolean;
};

export type PaymentTermDateForm = Subset<WaTermDate, 'label', 'id'> & {
  lines?: PaymentTermDateLinesForm[];
};

export type PaymentTermDateLinesForm = Subset<WaTermDateLine, 'columnName' | 'adjustmentDays' | 'weight', 'termDateId' | 'id'> & {
  linePosition: number;
  isUpdated: boolean;
};

export const upsertPaymentTermDate = async (formVal: PaymentTermDateForm) => {
  const request: UpsertPaymentTermDateRequest = {
    id: formVal.id ?? null,
    label: formVal.label,
    lines: formVal.lines
      ? formVal.lines.map((line) => ({
          termDateId: line.termDateId ?? null,
          id: line.id ?? null,
          columnName: line.columnName,
          adjustmentDays: line.adjustmentDays ?? 0,
          weight: line.weight,
          isUpdated: line.isUpdated,
        }))
      : [],
  };
  return request;
};
