import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { AgGridModule } from 'ag-grid-angular';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ServiceOrderInvoiceComponent } from '../+modules/+core/containers/service-order-invoice/service-order-invoice.component';
import { ServiceOrderComponent } from '../+modules/+core/containers/service-order/service-order.component';
import { DocumentTemplateComponent } from '../+modules/+it/containers/document-template/document-template.component';
import { ContractReviewComponent } from '../+modules/+logistics/contract-review/contract-review.component';
import { PurchaseInvoiceQueueComponent } from '../+modules/+metal-control/purchase-invoice-queue/purchase-invoice-queue.component';
import { ServiceOrderFinderComponent } from '../+modules/+metal-control/service-order-finder/service-order-finder.component';
import { SimpleFormulaComponent } from '../+modules/+trading/containers/contract-line/simple-formula/simple-formula.compnent';
import { AutoCompleteEditor } from './aggrid/autocompleteeditor/AutoCompleteEditor';
import { DatePickerEditor } from './aggrid/datepickereditor/DatePickerEditor';
import { DebitCreditRenderer } from './aggrid/debitcreditrenderer/DebitCreditRenderer';
import { StatusBarSettingsPanel } from './aggrid/statusbarsettingspanel/StatusBarSettingsPanel';
import { StatusBarSpacerPanel } from './aggrid/statusbarspacerpanel/StatusBarSpacerPanel';
import { StatusBarTotalizerPanel } from './aggrid/statusbartotalizerpanel/StatusBarTotalizerPanel';
import { ValidationStatusPanel } from './aggrid/validationstatuspanel/ValidationStatusPanel';
import { ApproverDropdownComponent } from './approver-dropdown/approver-dropdown.component';
import { BlankFormComponent } from './blank-form/blank-form.component';
import { BookingLinksComponent } from './booking-links/booking-links.component';
import { BookingSplitComponent } from './booking-split/booking-split.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ClosableFormComponent } from './closable-form/closable-form.component';
import { CollapsibleCardComponent } from './collapsible-card/collapsible-card.component';
import { ColorPickerToolbarComponent } from './color-picker-toolbar/color-picker-toolbar.component';
import { CommentsPopupComponent } from './comments-popup/comments-popup.component';
import { ContainerFinderComponent } from './container-finder/container-finder.component';
import { ContractClausePopupComponent } from './contract-clause-popup/contract-clause-popup.component';
import { ContractLineEditorComponent } from './contract-line-editor/contract-line-editor.component';
import { DateCalculatorComponent } from './date-calculator/date-calculator.component';
import { DocumentVersionMenuComponent } from './document-version-menu/document-version-menu.component';
import { HighlightOnDragoverDirective } from './drag-highlight/drag-highlight.directive';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { EntityApprovalsComponent } from './entity-approvals/entity-approvals.component';
import { EntityCommentsComponent } from './entity-comments/entity-comments.component';
import { EntityDocumentsComponent } from './entity-documents/entity-documents.component';
import { EntityFooterComponent } from './entity-footer/entity-footer.component';
import { EntityLinkDirective } from './entity-link/entity-link.component';
import { EntityTagsComponent } from './entity-tags/entity-tags.component';
import { EntityTasksComponent } from './entity-tasks/entity-tasks.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FileSelectWidgetComponent } from './file-select-widget/file-select-widget.component';
import { FlexFilterPopupComponent } from './flex-filter-popup/flex-filter-popup.component';
import { FlexLayoutPopupComponent } from './flex-layout-popup/flex-layout-popup.component';
import { BudgetElementDropdownComponent } from './form-elements/budget-category-dropdown/budget-category-dropdown.compoment';
import { CheckboxWrapperComponent } from './form-elements/checkbox-wrapper/checkbox-wrapper.component';
import { ChecklistEntryComponent } from './form-elements/checklist-entry/checklist-entry.component';
import { ContractDropdownComponent } from './form-elements/contract-dropdown/contract-dropdown.component';
import { CustomMultiselectComponent } from './form-elements/custom-multiselect/custom-multiselect.component';
import { DatepickerWrapperComponent } from './form-elements/datepicker-wrapper/datepicker-wrapper.component';
import { DiscrepancyStatusDropdownComponent } from './form-elements/discrepancy-status-dropdown/discrepancy-status-dropdown.component';
import { DropdownWrapperComponent } from './form-elements/dropdown-wrapper/dropdown-wrapper.component';
import { FormulaInputComponent } from './form-elements/formula-input/formula-input.component';
import { InputWrapperComponent } from './form-elements/input-wrapper/input-wrapper.component';
import { MarketValuationDropdownComponent } from './form-elements/market-valuation-dropdown/market-valuation-dropdown.component';
import { MultiselectWrapperComponent } from './form-elements/multiselect-wrapper/multiselect-wrapper.component';
import { NullableMultiselectWrapperComponent } from './form-elements/multiselect-wrapper/nullable-multiselect-wrapper.component';
import { NumerictextboxWrapperComponent } from './form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { ShipmentMultiselectComponent } from './form-elements/shipment-multiselect/shipment-multiselect.component';
import { SimpleDropdownComponent } from './form-elements/simple-dropdown/simple-dropdown.component';
import { SimpleMultiselectComponent } from './form-elements/simple-multiselect/simple-multiselect.component';
import { TextAreaWrapperComponent } from './form-elements/textarea-wrapper/textarea-wrapper.component';
import { UserGroupDropdownComponent } from './form-elements/user-group-dropdown/user-group-dropdown.component';
import { UserGroupMultiselectComponent } from './form-elements/user-group-multiselect/user-group-multiselect.component';
import { WeightTicketDropdownComponent } from './form-elements/weight-ticket-dropdown/weight-ticket-dropdown.component';
import { FormErrorLabelComponent } from './form-error-label/form-error-label.component';
import { FormListComponent } from './form-list/form-list.component';
import { FormTableComponent } from './form-table/form-table.component';
import { HorizontalFieldCenterComponent } from './forms/horizontal-field-left/horizontal-field-left.component';
import { HorizontalFieldComponent } from './forms/horizontal-field/horizontal-field.component';
import { InlineFieldComponent } from './forms/inline-field/inline-field.component';
import { TableFieldComponent } from './forms/table-field/table-field.component';
import { VerticalFieldComponent } from './forms/vertical-field/vertical-field.component';
import { GridAsyncDropdownEditorComponent } from './grid-components/async-dropdown/async-dropdown.component';
import { CustomGridHeaderComponent } from './grid-components/custom-header/custom-header.component';
import { CustomStatusPanelComponent } from './grid-components/custom-status-panel/custom-status-panel.component';
import { GridDatepickerEditorComponent } from './grid-components/datepicker/datepicker.component';
import { GridNumericInputComponent } from './grid-components/numeric-input/numeric-input.component';
import { GridStaticDropdownEditorComponent } from './grid-components/static-dropdown/static-dropdown.component';
import { GridTextareaEditorComponent } from './grid-components/text-area/textarea.component';
import { GridTextEditorComponent } from './grid-components/text-input/text-input.component';
import { HideIfReadonlyModeDirective } from './hide-if-readonly-mode/hide-if-readonly-mode.component';
import { InvoicePopupComponent } from './invoice-popup/invoice-popup.component';
import { ItemComponent } from './item/item.component';
import { LazyCardComponent } from './lazy-card/lazy-card.component';
import { MarketValuationDateComponent } from './market-valuation-date-selector/market-valuation-date-selector.component';
import { MathCalculatorComponent } from './math-calculator/math-calculator.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { MicrosoftEntityDocumentsComponent } from './microsoft-entity-documents/microsoft-entity-documents.component';
import { MicrosoftFileBrowserComponent } from './microsoft-file-browser/microsoft-file-browser.component';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { ModalWindowHostDirective } from './modal-window/modal-window.directive';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationComponent } from './notification-center/notification/notification.component';
import { AmountCurrencyPipe } from './pipes/amount-currency.pipe';
import { ApprovalCodePipe } from './pipes/approval-code.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { EnumLabelPipe } from './pipes/enum-label.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { QuantityUnitPipe } from './pipes/quantity-unit.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { PrintDocumentComponent } from './print-document/print-document.component';
import { ColorPickerPromptComponent } from './prompts/color-picker/color-picker-prompt.component';
import { ComboboxPromptComponent } from './prompts/combobox-prompt/combobox-prompt.component';
import { NumericTextboxPromptComponent } from './prompts/numerictextbox-prompt/numerictextbox-prompt.component';
import { SimpleDropdownPromptComponent } from './prompts/simple-dropdown-prompt/simple-dropdown-prompt.component';
import { TextPromptComponent } from './prompts/text-prompt/text-prompt.component';
import { TextAreaPromptComponent } from './prompts/textarea-prompt/textarea-prompt.component';
import { RateFinderComponent } from './rate-finder/rate-finder.component';
import { SharepointVersionMenuComponent } from './sharepoint-version-menu/sharepoint-version-menu.component';
import { ShipmentFinderComponent } from './shipment-finder/shipment-finder.component';
import { ShipmentMergeComponent } from './shipment-merge/shipment-merge.component';
import { ShipmentSplitComponent } from './shipment-split/shipment-split.component';
import { ShipmentTableComponent } from './shipment-table/shipment-table.component';
import { SplitbuttonWrapperComponent } from './spitbutton-wrapper/splitbutton-wrapper.component';
import { TaskComponent } from './task/task.component';
import { VesselComponent } from './vessel/vessel.component';
import { WarehouseMovementsComponent } from './warehouse-movements/warehouse-movements.component';
import { WeightTicketsComponent } from './weight-tickets/weight-tickets.component';
import { DropdownGraphqlComponent } from './form-elements/dropdown-graphql/dropdown-graphql.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { CountdownModule } from 'ngx-countdown';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { MultiselectGraphqlComponent } from './form-elements/multiselect-graphql/multiselect-graphql.component';
import { InputFieldLoaderComponent } from './input-field-loader/input-field-loader.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { EditorWrapperComponent } from './form-elements/editor-wrapper/editor-wrapper.component';
import { CustomOverlayLoader } from './aggrid/customoverlayloader/CustomOverlayLoader';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ComboBoxModule,
    DropDownListModule,
    ContextMenuModule,
    MultiSelectModule,
    InputsModule,
    DateInputsModule,
    ButtonsModule,
    IndicatorsModule,
    LabelModule,
    NgxJsonViewerModule,
    AgGridModule,
    DialogsModule,
    DragDropModule,
    LayoutModule,
    TreeListModule,
    NgxFilesizeModule,
    ProgressBarModule,
    AngularDualListBoxModule,
    ColorPickerModule,
    ChartsModule,
    CountdownModule,
    TooltipModule,
    EditorModule,
  ],
  declarations: [
    FormulaInputComponent,
    CollapsibleCardComponent,
    HorizontalFieldComponent,
    VerticalFieldComponent,
    HorizontalFieldCenterComponent,
    InputWrapperComponent,
    TextAreaWrapperComponent,
    CheckboxWrapperComponent,
    DropdownWrapperComponent,
    DropdownGraphqlComponent,
    MultiselectWrapperComponent,
    MultiselectGraphqlComponent,
    NullableMultiselectWrapperComponent,
    ClosableFormComponent,
    FormTableComponent,
    TableFieldComponent,
    InlineFieldComponent,
    SplitbuttonWrapperComponent,
    NumerictextboxWrapperComponent,
    FormListComponent,
    FormTableComponent,
    TableFieldComponent,
    SimpleDropdownComponent,
    DatepickerWrapperComponent,
    NotificationCenterComponent,
    NotificationComponent,
    MenuComponent,
    MenuItemComponent,
    EntityApprovalsComponent,
    ApproverDropdownComponent,
    ErrorDialogComponent,
    EntityCommentsComponent,
    ShipmentFinderComponent,
    WeightTicketsComponent,
    WeightTicketDropdownComponent,
    ContractDropdownComponent,
    EntityDocumentsComponent,
    FileSelectWidgetComponent,
    DocumentVersionMenuComponent,
    CommentsPopupComponent,
    ShipmentMultiselectComponent,
    BudgetElementDropdownComponent,
    MarketValuationDropdownComponent,
    DiscrepancyStatusDropdownComponent,
    ContractClausePopupComponent,
    FilePreviewComponent,
    ComboboxPromptComponent,
    InvoicePopupComponent,
    CustomMultiselectComponent,
    FormErrorLabelComponent,
    PurchaseInvoiceQueueComponent,
    ServiceOrderFinderComponent,
    ChecklistEntryComponent,
    ChecklistComponent,
    ServiceOrderInvoiceComponent,
    VesselComponent,
    ContainerFinderComponent,
    BlankFormComponent,
    ItemComponent,
    ServiceOrderComponent,
    NumericTextboxPromptComponent,
    SimpleMultiselectComponent,
    FlexFilterPopupComponent,
    SimpleDropdownPromptComponent,
    FlexLayoutPopupComponent,
    ShipmentSplitComponent,
    ShipmentTableComponent,
    SimpleDropdownPromptComponent,
    ShipmentMergeComponent,
    BookingSplitComponent,
    TextPromptComponent,
    ModalWindowComponent,
    EntityTasksComponent,
    TaskComponent,
    DocumentTemplateComponent,
    PrintDocumentComponent,
    ColorPickerToolbarComponent,
    ColorPickerPromptComponent,
    UserGroupDropdownComponent,
    UserGroupMultiselectComponent,
    WarehouseMovementsComponent,
    TextAreaPromptComponent,
    DynamicFormComponent,
    BookingLinksComponent,
    GridAsyncDropdownEditorComponent,
    GridStaticDropdownEditorComponent,
    GridTextEditorComponent,
    GridDatepickerEditorComponent,
    GridTextareaEditorComponent,
    GridNumericInputComponent,
    RateFinderComponent,
    EntityFooterComponent,
    ContractReviewComponent,
    CustomStatusPanelComponent,
    CustomGridHeaderComponent,
    ContractLineEditorComponent,
    SimpleFormulaComponent,
    MicrosoftFileBrowserComponent,
    EntityTagsComponent,
    LazyCardComponent,
    ApprovalCodePipe,
    MinuteSecondsPipe,
    AmountCurrencyPipe,
    QuantityUnitPipe,
    EnumLabelPipe,
    UnitPipe,
    CurrencyPipe,
    HideIfReadonlyModeDirective,
    HighlightOnDragoverDirective,
    ModalWindowHostDirective,
    EntityLinkDirective,
    MicrosoftEntityDocumentsComponent,
    SharepointVersionMenuComponent,
    DateCalculatorComponent,
    MathCalculatorComponent,
    MarketValuationDateComponent,
    CurrencyPipe,
    AutoCompleteEditor,
    DebitCreditRenderer,
    StatusBarTotalizerPanel,
    DatePickerEditor,
    ValidationStatusPanel,
    StatusBarSettingsPanel,
    StatusBarSpacerPanel,
    DropdownGraphqlComponent,
    InputFieldLoaderComponent,
    EditorWrapperComponent,
    CustomOverlayLoader,
  ],
  exports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ComboBoxModule,
    DropDownListModule,
    MultiSelectModule,
    InputsModule,
    DateInputsModule,
    ButtonsModule,
    IndicatorsModule,
    LabelModule,
    NgxJsonViewerModule,
    DragDropModule,
    LayoutModule,
    ProgressBarModule,
    FormulaInputComponent,
    CollapsibleCardComponent,
    HorizontalFieldComponent,
    HorizontalFieldCenterComponent,
    VerticalFieldComponent,
    InlineFieldComponent,
    InputWrapperComponent,
    TextAreaWrapperComponent,
    CheckboxWrapperComponent,
    DropdownWrapperComponent,
    DropdownGraphqlComponent,
    MultiselectWrapperComponent,
    MultiselectGraphqlComponent,
    NullableMultiselectWrapperComponent,
    AgGridModule,
    ClosableFormComponent,
    FormTableComponent,
    TableFieldComponent,
    ShipmentFinderComponent,
    DialogsModule,
    SplitbuttonWrapperComponent,
    NumerictextboxWrapperComponent,
    FormListComponent,
    FormTableComponent,
    TableFieldComponent,
    SimpleDropdownComponent,
    DatepickerWrapperComponent,
    NotificationCenterComponent,
    NotificationComponent,
    MenuComponent,
    MenuItemComponent,
    EntityApprovalsComponent,
    ApproverDropdownComponent,
    WeightTicketsComponent,
    WeightTicketDropdownComponent,
    EntityCommentsComponent,
    ContractDropdownComponent,
    EntityDocumentsComponent,
    FileSelectWidgetComponent,
    DocumentVersionMenuComponent,
    CommentsPopupComponent,
    ShipmentMultiselectComponent,
    BudgetElementDropdownComponent,
    MarketValuationDropdownComponent,
    DiscrepancyStatusDropdownComponent,
    ContractClausePopupComponent,
    FilePreviewComponent,
    ComboboxPromptComponent,
    InvoicePopupComponent,
    CustomMultiselectComponent,
    FormErrorLabelComponent,
    PurchaseInvoiceQueueComponent,
    ServiceOrderFinderComponent,
    ChecklistEntryComponent,
    ChecklistComponent,
    ServiceOrderInvoiceComponent,
    VesselComponent,
    ContainerFinderComponent,
    BlankFormComponent,
    ItemComponent,
    ServiceOrderComponent,
    NumericTextboxPromptComponent,
    SimpleMultiselectComponent,
    FlexFilterPopupComponent,
    SimpleDropdownPromptComponent,
    FlexLayoutPopupComponent,
    ShipmentSplitComponent,
    ShipmentTableComponent,
    SimpleDropdownPromptComponent,
    ShipmentMergeComponent,
    BookingSplitComponent,
    TextPromptComponent,
    ModalWindowComponent,
    EntityTasksComponent,
    TaskComponent,
    DocumentTemplateComponent,
    PrintDocumentComponent,
    ColorPickerToolbarComponent,
    ColorPickerPromptComponent,
    UserGroupDropdownComponent,
    UserGroupMultiselectComponent,
    WarehouseMovementsComponent,
    TextAreaPromptComponent,
    DynamicFormComponent,
    BookingLinksComponent,
    GridAsyncDropdownEditorComponent,
    GridStaticDropdownEditorComponent,
    GridTextEditorComponent,
    GridDatepickerEditorComponent,
    GridTextareaEditorComponent,
    GridNumericInputComponent,
    RateFinderComponent,
    EntityFooterComponent,
    ContractReviewComponent,
    CustomStatusPanelComponent,
    CustomGridHeaderComponent,
    ContractLineEditorComponent,
    SimpleFormulaComponent,
    MicrosoftFileBrowserComponent,
    EntityTagsComponent,
    LazyCardComponent,
    ApprovalCodePipe,
    MinuteSecondsPipe,
    AmountCurrencyPipe,
    QuantityUnitPipe,
    EnumLabelPipe,
    UnitPipe,
    CurrencyPipe,
    HideIfReadonlyModeDirective,
    HighlightOnDragoverDirective,
    ModalWindowHostDirective,
    EntityLinkDirective,
    MicrosoftEntityDocumentsComponent,
    SharepointVersionMenuComponent,
    DateCalculatorComponent,
    MathCalculatorComponent,
    MarketValuationDateComponent,
    CurrencyPipe,
    DatePickerEditor,
    AutoCompleteEditor,
    AngularDualListBoxModule,
    ColorPickerModule,
    ChartsModule,
    CountdownModule,
    TooltipModule,
    InputFieldLoaderComponent,
    EditorModule,
    EditorWrapperComponent,
    CustomOverlayLoader,
  ],
})
export class SharedModule {}
