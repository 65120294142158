import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { ChunkType, PropertyDocument, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, createFormCallback, prefillCallback, submitFormCallback } from './types';
import _ from 'lodash';

const inventoryWriteOffPrefill: prefillCallback<GenerateInventoryWriteOffPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const shipmentsResponse = await api.run<ListResponse<PropertyDocument>>(endpoints.listShipments, { filters: { id: Array.isArray(id) ? id : [id] } }, null);
      return { shipments: shipmentsResponse.list };
    })()
  );
};

const inventoryWriteOffCheck: checkPrefillCallback<GenerateInventoryWriteOffPrefill> = (delegate, id, prefill) => {
  if (!(prefill && prefill.shipments.length > 0)) return of(false);
  for (const shipment of prefill.shipments) {
    if (shipment.status === ChunkType.WRITTEN_OFF) return of(`Shipment ${shipment.id} has already been written off.`);
  }
  return of(true);
};

const inventoryWriteOffForm: createFormCallback<GenerateInventoryWriteOffPrefill, GenerateInventoryWriteOffForm> = (delegate, id, prefill) => {
  const form: (FormElementConfig<GenerateInventoryWriteOffForm> | FormElementConfig<GenerateInventoryWriteOffForm>[])[] = [
    {
      type: 'Label',
      text: 'Note: This method will remove this shipment from the Sales Contract and discharge from Inventory at cost.',
    },
    {
      type: 'Label',
      text: `Shipment(s) ID(s): ${prefill.shipments ? prefill.shipments.map((s: PropertyDocument) => s.id).join(', ') : 'Unknown'}`,
    },
    {
      type: 'Date',
      field: 'valueDate',
      label: 'Value Date',
      validator: [Validators.required],
      startingValue: new Date(),
    },
    _fe('comment', 'Comment', 'TextArea', '', [Validators.required]),
  ];
  return form;
};

const submitInventoryWriteOff: submitFormCallback<GenerateInventoryWriteOffPrefill, GenerateInventoryWriteOffForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  const router = delegate.getService('router');

  return from(
    new Promise<GenerateInventoryWriteOffResponse[] | null>((resolve, reject) => {
      (async () => {
        const invoicesInfo: GenerateInventoryWriteOffResponse[] = [];
        for (const shipment of prefill.shipments) {
          const request: GenerateInventoryWriteOffRequest = {
            valueDate: result.valueDate,
            comment: result.comment,
            shipmentId: shipment.id,
          };

          try {
            invoicesInfo.push(await api.run<GenerateInventoryWriteOffResponse>(endpoints.generateInventoryWriteOff, request, null));
          } catch (err) {
            return reject(err);
          }
        }
        return resolve(invoicesInfo);
      })();
    })
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const invoicesInfo = res.filter((invoiceInfo) => !_.isNil(invoiceInfo));
          if (invoicesInfo.length > 0) {
            // Checks if invoicesInfo has more than one response, so it shows a list of the invoices created per shipment
            if (invoicesInfo.length > 1) {
              return prompt.htmlDialog(
                'Success',
                `<div style="white-space: pre">Inventory Write Off successfully generated: \n\n${invoicesInfo
                  .map((invoiceInfo) => `- Invoice ${invoiceInfo.invoiceNumber} - Shipment ID ${invoiceInfo.shipmentId}`)
                  .join(`\n`)}</div>`
              );
            } else {
              // Else if invoicesInfo has only one response, it redirects to the invoice created
              router.navigate([`portal/accounting/vouchers/${invoicesInfo[0].invoiceId}`]);
            }
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Inventory Write Off was generated and try again if necessary.</div>`);
      })
  );
};

export const inventoryWriteOffPreset: DynamicFormConstant<GenerateInventoryWriteOffPrefill, GenerateInventoryWriteOffForm> = {
  allowMultipleRows: true,
  checkPrefill: inventoryWriteOffCheck,
  createForm: inventoryWriteOffForm,
  entityType: SourceEntityType.CHUNK_KEY,
  getPrefill: inventoryWriteOffPrefill,
  label: 'Inventory Write Off',
  submitForm: submitInventoryWriteOff,
  title: 'Inventory Write Off',
  value: DynamicFormType.INVENTORY_WRITE_OFF,
  endpoints: [endpoints.listShipments, endpoints.generateInventoryWriteOff],
  width: 400,
};

export type GenerateInventoryWriteOffPrefill = {
  shipments: Subset<PropertyDocument, 'id' | 'status'>[];
};
export type GenerateInventoryWriteOffForm = Omit<GenerateInventoryWriteOffRequest, 'shipmentId'>;
export type GenerateInventoryWriteOffRequest = {
  valueDate: Date;
  comment: string;
  shipmentId: number;
};
export type GenerateInventoryWriteOffResponse = {
  invoiceId: number;
  invoiceNumber: number;
  shipmentId: string;
};
