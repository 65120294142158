import { PropertyDocument } from './propertyDocument';
import { YN } from './enums';

export enum DirectLinkType {
  SELF = 0,
  DIRECT_LINK = 1,
  INDIRECT_LINK = 2,
}
export type PreviousDocument = {
  prevDocKey: number;
  prevChunkKey: number;
  nextChunkKey: number;
  directLink: DirectLinkType | null;
  prevNetWeight: number;
  prevGrossWeight: number | null;
  prevBags: number | null;
  nextNetWeight: number;
  nextGrossWeight: number | null;
  nextBags: number | null;
  netWeightLeft: number | null;
  grossWeightLeft: number | null;
  bagsLeft: number | null;
  updatedBy: number | null;
  updateDate: Date | null;
  createdBy: number | null;
  creationDate: Date | null;
  needAnalyticalCorrection: YN;
  journalKey: number | null;
  wrJournalKey: number | null;
  wrNeedAnlCorrection: YN;
  parentShipment?: PropertyDocument;
  childShipment?: PropertyDocument;
};
