import { Component, forwardRef } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { DropdownConfig, ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { AssignTagsRequest, Tag } from 'src/lib/newBackendTypes/tag';
import { SubEntityContainer } from 'src/lib/SubEntityContainer';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@UntilDestroy()
@Component({
  selector: 'entity-tags',
  templateUrl: './entity-tags.component.html',
  providers: [{ provide: SubEntityContainer, useExisting: forwardRef(() => EntityTagsComponent) }],
})
export class EntityTagsComponent extends SubEntityContainer<Tag> {
  tags: Tag[];
  originalTags: Tag[];
  authorized: endpointsAuthorized;

  tagSelect: UntypedFormControl;
  tagDropdown: DropdownConfig<Tag>;

  constructor(route: ActivatedRoute, controlDir: NgControl, store: Store, private api: ThalosApiService, private spinner: SpinnerService) {
    super(route, controlDir, store);

    this.tagSelect = new UntypedFormControl(null);
    this.tagSelect.valueChanges.pipe(untilDestroyed(this)).subscribe((res: Tag) => {
      if (res !== null && !_.isNil(this.tags.length)) {
        const index = this.tags.findIndex((t) => t.id === res.id);

        if (index === -1) {
          this.tags.push(res);
        } else {
          this.tags.splice(index, 1);
        }
        setTimeout(() => {
          this.tagSelect.setValue(null);
        });
      }
    });

    endpointAuthorizationSubscription(store, this);

    this.tags = [];
    this.originalTags = [];
  }

  ngOnInit() {
    this.tagDropdown = new DropdownConfig({
      listProcedure: endpoints.listTags,
      valueField: 'id',
      labelField: 'name',
      additionalFilters: { entityType: this.entityType },
    });
    super.ngOnInit();
  }

  loadSubForm() {
    if (this.entityId && this.authorized[endpoints.listTagAssignments] && this.authorized[endpoints.listTags]) {
      let rid = this.spinner.startRequest(randomFetchSynonym() + ' Tags', 0, false, !this.showSpinner);
      this.api.rpc<ListResponse<Tag>>(endpoints.listTagAssignments, { entityId: this.entityId, entityType: this.entityType }, { list: [], count: 0 }).subscribe((res) => {
        this.spinner.completeRequest(rid);
        this.tags = res.list;
        this.originalTags = [...res.list];
        this.getFormControl()?.markAsUntouched();
      });
    }
  }

  save(entityId: number) {
    if (!this.authorized[endpoints.assignTags] || !this.authorized[endpoints.listTagAssignments]) return of([]);
    const original = this.originalTags.length > 0 ? this.originalTags.map((t) => t.id).sort() : [];
    const updated = this.tags.length > 0 ? this.tags.map((t) => t.id).sort() : [];
    if (_.isEqual(original, updated)) {
      return of(this.tags);
    }
    const request: AssignTagsRequest = {
      tagIds: this.tags.map((t) => t.id),
      entityId,
      entityType: this.entityType,
    };

    return this.api.rpc<Tag[]>(endpoints.assignTags, request, []);
  }

  onRemove(event: Tag) {
    const index = this.tags.indexOf(event);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  markAsTouched() {}

  get assignAuthorized() {
    return this.authorized[endpoints.assignTags];
  }
}
