import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GetContextMenuItemsParams, GetMainMenuItemsParams, GridOptions, GridReadyEvent, MenuItemDef, SelectionChangedEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { Store } from 'src/app/core/services/store.service';
import { defaultComplexGrid, dynamicFormItem, enumLabelFormatter, getContextMenuItems, gotoMenu, gotoMenuItem, gridDateFormatter, selectionColumn } from 'src/lib/agGridFunctions';
import { inventoryWriteOffPreset } from 'src/lib/flex/forms/inventoryWriteOff';
import { reverseInventoryWriteOffPreset } from 'src/lib/flex/forms/reverseInventoryWriteOff';
import { endpointsAuthorized } from 'src/lib/helperFunctions';
import { ChunkType, ChunkTypes, MetalControlStatus, PropertyDocument, ShipmentFinderResult, SourceEntityType } from 'src/lib/newBackendTypes';
import { ContractIcon, DiscrepancyIcon, ExternalLinkIcon, ServiceOrderIcon } from 'src/lib/uiConstants';
import { StatusBarTotalizerPanel } from '../aggrid/statusbartotalizerpanel/StatusBarTotalizerPanel';

@Component({
  selector: 'shipment-table',
  templateUrl: './shipment-table.component.html',
  styleUrls: ['./shipment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentTableComponent implements OnInit {
  gridOptions: GridOptions;
  popup = false;
  readonly = false;
  quickFilterText: string = '';

  authorized: endpointsAuthorized;

  private _shipments: ShipmentFinderResult[];

  @Input()
  public get shipments() {
    return this._shipments;
  }
  public set shipments(value: ShipmentFinderResult[]) {
    this._shipments = value;
    this.totalWeight.netWeight = this._shipments.reduce((acc, shipment) => (acc += shipment.netWeight), 0);
  }

  @Output()
  selectionChanged: EventEmitter<number[]> = new EventEmitter();

  @Output()
  shipmentsInTable: EventEmitter<ShipmentsEmitData> = new EventEmitter();

  @Input()
  set selectedShipments(val: number[]) {
    this._selectedShipments = [...val];
    if (!this.gridOptions.api) return;

    this._setSelectedShipments(val);
  }
  private _selectedShipments: number[];

  /**
   * Used to calculate the status row totalizers
   */
  public totalWeight: { netWeight: number | false } = { netWeight: 0 };

  private _setSelectedShipments(val: number[]) {
    this.changingSelectionFromExternalSource = true;
    this.gridOptions.api.deselectAll();

    for (let s of val) {
      let node = this.gridOptions.api.getRowNode(`${s}`);

      if (node && !node.isSelected()) {
        node.setSelected(true, false, true);
      }
    }

    this.changingSelectionFromExternalSource = false;
  }

  changingSelectionFromExternalSource: boolean = false;

  constructor(private dataFormatter: DataFormattingService, private delegate: DelegateService, private router: Router, store: Store) {
    this.shipments = [];
    this.gridOptions = {
      ...defaultComplexGrid(delegate, 'id'),
      domLayout: 'autoHeight',
      onGridReady: this.onGridReady(),
      rowSelection: 'multiple',
      suppressRowClickSelection: false,
      getRowId: (params) => params.data.id,
      columnDefs: [
        selectionColumn(() => !this.readonly, true),
        { field: 'id' },
        {
          field: 'documentName',
          width: 560,
        },
        {
          field: 'status',
          headerName: 'Type',
          valueFormatter: enumLabelFormatter(ChunkTypes),
        },
        {
          field: 'marks',
          width: 200,
        },
        {
          field: 'netWeight',
          valueFormatter: this.dataFormatter.gridUnitFormatter('unitId'),
          cellStyle: { 'text-align': 'right' },
          aggFunc: 'sum',
        },
        {
          field: 'grossWeight',
          valueFormatter: this.dataFormatter.gridUnitFormatter('unitId'),
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'finalWeight',
          valueFormatter: this.dataFormatter.gridUnitFormatter('unitId'),
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'container.booking.number',
          headerName: 'Booking #',
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'purchaseElement.contract.counterparty.displayName',
          headerName: 'Supplier',
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'purchaseElement.contract.number',
          headerName: 'Purch Contract #',
          width: 160,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'purchaseDelivery.invoice.number',
          headerName: 'Purch Ticket #',
          valueGetter: (params) => {
            if (params.data.purchaseDelivery.invoice.number === 0) return null;
            else return params.data.purchaseDelivery.invoice.number;
          },
          width: 140,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'purchaseDelivery.invoice.invoiceDate',
          headerName: 'Purch Ticket Date',
          valueFormatter: gridDateFormatter(),
          width: 160,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'purchaseDelivery.invoice.creationDate',
          headerName: 'Purch Ticket Created',
          valueGetter: (params) => {
            if (params.data.purchaseDelivery.invoice.creationDate === '1900-01-01T05:00:00.000Z') return null;
            else return params.data.purchaseDelivery.invoice.creationDate;
          },
          valueFormatter: gridDateFormatter(),
          width: 180,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'saleElement.contract.counterparty.displayName',
          headerName: 'Customer',
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'saleElement.contract.number',
          headerName: 'Sale Contract #',
          width: 150,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'saleDelivery.invoice.number',
          headerName: 'Sale Invoice #',
          valueGetter: (params) => {
            if (params.data.saleDelivery.invoice.number === 0) return null;
            else return params.data.saleDelivery.invoice.number;
          },
          width: 140,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'saleDelivery.invoice.invoiceDate',
          headerName: 'Sale Invoice Date',
          valueFormatter: gridDateFormatter(),
          width: 160,
          cellStyle: { 'text-align': 'right' },
        },
        {
          field: 'saleDelivery.invoice.creationDate',
          headerName: 'Sale Invoice Created',
          valueGetter: (params) => {
            if (params.data.saleDelivery.invoice.creationDate === '1900-01-01T05:00:00.000Z') return null;
            else return params.data.saleDelivery.invoice.creationDate;
          },
          valueFormatter: gridDateFormatter(),
          width: 180,
          cellStyle: { 'text-align': 'right' },
        },
      ],
      onSelectionChanged: this.onSelectionChange(),
      getContextMenuItems: getContextMenuItems(
        gotoMenu(
          gotoMenuItem(
            delegate,
            'Purchase Contract',
            (shipment: PropertyDocument) => shipment.purchaseElement?.contract?.id,
            SourceEntityType.CONTRACT_KEY,
            'get',
            (shipment: PropertyDocument) => shipment.purchaseElement?.contract?.number,
            false,
            ContractIcon
          ),
          gotoMenuItem(
            delegate,
            'Sale Contract',
            (shipment: PropertyDocument) => shipment.saleElement?.contract?.id,
            SourceEntityType.CONTRACT_KEY,
            'get',
            (shipment: PropertyDocument) => shipment.saleElement?.contract?.number,
            false,
            ContractIcon
          ),
          (params: GetContextMenuItemsParams) => {
            if (!(params && params.node && params.node.data && params.node.data.container && params.node.data.container.booking)) return [];
            return gotoMenuItem(delegate, `Booking ${params.node.data.container.booking.number}`, () => params.node.data.container.booking.id, SourceEntityType.FREIGHT_BOOKING_KEY, 'get')(params);
          },
          this.goToServiceOrdersAndDiscrepancies(),
          this.goToDiscrepancies()
        ),
        {
          name: 'Forms',
          menuItems: [
            dynamicFormItem(this.delegate, inventoryWriteOffPreset, 'id', 'id', undefined, undefined, undefined, { status: { not: [ChunkType.WRITTEN_OFF] } }),
            dynamicFormItem(this.delegate, reverseInventoryWriteOffPreset, 'id', 'id', undefined, undefined, undefined, {
              status: ChunkType.WRITTEN_OFF,
              mcStatus: MetalControlStatus.WRITTEN_OFF,
              selDeliveryId: { not: [null] },
            }),
          ],
        },
        this.removeLineOption()
      ),
      statusBar: {
        statusPanels: [
          {
            statusPanel: StatusBarTotalizerPanel,
            align: 'left',
            statusPanelParams: {
              totalRow: this.totalWeight,
              labels: [{ key: 'netWeight', text: 'Weight Sum', decimals: 3 }],
            },
          },
        ],
      },
    };
  }

  ngOnInit(): void {}

  goToServiceOrdersAndDiscrepancies() {
    return (params: GetContextMenuItemsParams) => {
      const data = params.node.data;
      const lookup = this.delegate.getService('entityLookup');
      const existsServiceOrder = lookup.entityPathExists('list', SourceEntityType.SERVICE_ORDER_KEY);
      const linkServiceOrder = existsServiceOrder ? lookup.getLink(SourceEntityType.SERVICE_ORDER_KEY, 'list') : null;
      const existsDiscrepancy = lookup.entityPathExists('list', SourceEntityType.DISCREPANCY_ID);
      const linkDiscrepancy = existsDiscrepancy ? lookup.getLink(SourceEntityType.DISCREPANCY_ID, 'list') : null;
      const menu: MenuItemDef[] = [];

      if (data.serviceOrders && data.serviceOrders.length > 0) {
        menu.push({
          name: `Service Orders List ${data.id}`,
          icon: `<i class="${ServiceOrderIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
          action: () => {
            localStorage.setItem('shipmentId', JSON.stringify(data.id));
            this.router.navigate([`${linkServiceOrder}`]);
          },
          subMenu: [
            {
              name: 'New Tab',
              icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
              action: () => {
                localStorage.setItem('shipmentId', JSON.stringify(data.id));
                window.open(`${linkServiceOrder}`);
              },
            },
          ],
        });
      }

      if (data.discrepancies && data.discrepancies.length > 0) {
        menu.push({
          name: `Discrepancies List ${data.id}`,
          icon: `<i class="${DiscrepancyIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
          action: () => {
            localStorage.setItem('shipmentId', JSON.stringify(data.id));
            this.router.navigate([`${linkDiscrepancy}`]);
          },
          subMenu: [
            {
              name: 'New Tab',
              icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
              action: () => {
                localStorage.setItem('shipmentId', JSON.stringify(data.id));
                window.open(`${linkDiscrepancy}`);
              },
            },
          ],
        });
      }
      return menu;
    };
  }

  goToDiscrepancies() {
    return (params: GetContextMenuItemsParams) => {
      const data = params.node.data;
      const lookup = this.delegate.getService('entityLookup');
      const existsDiscrepancy = lookup.entityPathExists('get', SourceEntityType.DISCREPANCY_ID);
      const linkDiscrepancy = existsDiscrepancy ? lookup.getLink(SourceEntityType.DISCREPANCY_ID, 'get') : null;
      const menu: MenuItemDef[] = [];
      if (data.discrepancies && data.discrepancies.length > 0) {
        for (const item of data.discrepancies) {
          menu.push({
            name: `Discrepancy ${item.id}`,
            icon: `<i class="${DiscrepancyIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
            action: () => {
              this.router.navigate([`${linkDiscrepancy}/${item.id}`]);
            },
            subMenu: [
              {
                name: 'New Tab',
                icon: `<i class="${ExternalLinkIcon}" style="font-size: 13px; padding-left: 2px;"></i>`,
                action: () => {
                  window.open(`${linkDiscrepancy}/${item.id}`);
                },
              },
            ],
          });
        }
      }
      return menu;
    };
  }

  removeLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      const selectedNodes = params.api.getSelectedNodes();
      const options = [];
      if (!!selectedNodes.length) {
        options.push({
          name: `Remove Selected ${selectedNodes.length > 1 ? 'Shipments' : 'Shipment'} `,
          action: () => {
            let shipmentsToDelete: number[] = [];
            let shipmentsFinder: ShipmentFinderResult[] = [];
            for (const selectedNode of selectedNodes) {
              shipmentsToDelete.push(selectedNode.data.id);
              const index = this.shipments.findIndex((row) => row.shipmentId === selectedNode.data.id);
              if (index < 0) continue;
              this.shipments.splice(index, 1);
              this.totalWeight.netWeight = this.shipments.reduce((acc, shipment) => (acc += shipment.netWeight), 0);
            }
            shipmentsFinder = this.shipments;
            this.gridOptions.api.setRowData(this.shipments);
            this.shipmentsInTable.emit({ shipmentsFinder, shipmentsToDelete });
          },
        });
      }
      return options;
    };
  }

  onGridReady() {
    return (event: GridReadyEvent) => {
      if (!!this.popup) {
        this.gridOptions.api.setDomLayout('normal');
      }

      if (this.readonly) {
        this.gridOptions.columnApi.setColumnVisible('checkbox', false);
      }

      if (this._selectedShipments) this._setSelectedShipments(this._selectedShipments);
    };
  }

  onSelectionChange() {
    return (params: SelectionChangedEvent) => {
      if (this.changingSelectionFromExternalSource) return;
      let shipments: PropertyDocument[] = this.gridOptions.api.getSelectedRows();

      if (
        !_.isEqual(
          shipments.map((s) => s.id),
          this._selectedShipments
        )
      ) {
        this.selectionChanged.emit(shipments.map((s) => s.id));
      }
    };
  }

  autosizeColumns() {
    if (this.gridOptions.columnApi) this.gridOptions.columnApi.autoSizeAllColumns(false);
  }

  quickFilter(text: string) {
    if (this.gridOptions.api) this.gridOptions.api.setQuickFilter(text);
  }

  resetFilters() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setFilterModel({});
      this.quickFilterText = '';
      this.quickFilter(this.quickFilterText);
      this.gridOptions.columnApi!.resetColumnState();
    }
  }
}

export type ShipmentsEmitData = {
  shipmentsFinder: ShipmentFinderResult[];
  shipmentsToDelete: number[];
};
