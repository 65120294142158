import { Directive, OnInit } from '@angular/core';
import {
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  PaginationChangedEvent,
  RowDataUpdatedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { CustomGridHeaderComponent } from 'src/app/shared/grid-components/custom-header/custom-header.component';
import { IStateful } from './hasState';

@Directive()
export abstract class BaseList implements IStateful<GridState>, OnInit {
  /**
   * Bound to quick filter input with ngModel.  When changed, calls quick filter method in ag-grid.  Used for global search within grid.
   */
  quickFilterText: string;

  /**
   * Ag-Grid column state to be stored in local state and recalled.
   */
  columnState: ColumnState[];

  /**
   * Ag-Grid filter state to be stored in local state and recalled.
   */
  filterModel?: any;

  /**
   * Filter model loaded from state.  If not undefined, will be applied.
   */
  initialFilterModel?: any;

  /**
   * When to apply the intial filters.  If 'gridReady', filters will be applied on filter
   */
  filterApplyEvent: 'dataLoaded' | 'gridReady' = 'gridReady';

  /**
   * Filter model loaded from state.  If not undefined, will be applied.
   */
  initialColumnState?: ColumnState[];

  /**
   * Grid options for the ag-grid instance.  Will be set to the return value of initialize grid
   */
  gridOptions!: GridOptions;

  /**
   * The set of active data with grid filters applied to be stored in local state
   */
  filteredData: any[];

  constructor() {
    this.quickFilterText = '';
  }

  /**
   * Angular hook.
   */
  ngOnInit() {
    this.gridOptions = {
      /**
       * The custom grid header component.  Exists so we can change ctrl+click behavior.
       */
      components: { agColumnHeader: CustomGridHeaderComponent },
      defaultColDef: {
        filterParams: { newRowsAction: 'keep' },
        enableRowGroup: true,
      },
      onRowDataChanged: (event: RowDataUpdatedEvent) => {
        if (this.initialFilterModel && this.filterApplyEvent === 'dataLoaded') {
          event.api.setFilterModel(this.initialFilterModel);
          this.initialFilterModel = null;
        }
      },
      onPaginationChanged: (event: PaginationChangedEvent) => {
        this.columnState = event.columnApi.getColumnState();
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        this.filterModel = event.api.getFilterModel();
      },
      onSortChanged: (event: SortChangedEvent) => {
        this.columnState = event.columnApi.getColumnState();
      },
      onColumnResized: (event: ColumnResizedEvent) => {
        this.columnState = event.columnApi.getColumnState();
      },
      onColumnMoved: (event: ColumnMovedEvent) => {
        this.columnState = event.columnApi.getColumnState();
      },
      onColumnVisible: (event: ColumnVisibleEvent) => {
        this.columnState = event.columnApi.getColumnState();
      },
      ...this.initializeGrid(),
    };
  }

  /**
   * Returns grid options to be used with ag-grid.  Applied to this.gridOptions
   */
  abstract initializeGrid(): GridOptions;

  _loadState(state: GridState) {
    this.quickFilterText = state.quickFilter;
    this.initialColumnState = state.columns;
    this.initialFilterModel = state.filterModel;
  }

  _saveState(): GridState {
    return {
      quickFilter: this.quickFilterText,
      columns: this.columnState,
      data: this.filteredData,
      filterModel: this.filterModel,
    };
  }

  onGridReady(event: { api: GridApi; columnApi: ColumnApi }) {
    if (this.initialColumnState) {
      event.columnApi.applyColumnState({ state: this.initialColumnState, applyOrder: true });
    }
    if (this.quickFilterText) {
      this.quickFilter(this.quickFilterText);
    }
    if (this.initialFilterModel && this.filterApplyEvent === 'gridReady') {
      event.api.setFilterModel(this.initialFilterModel);
      this.initialFilterModel = null;
    }
  }

  quickFilter(text: string) {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setQuickFilter(text);
    }
  }

  getSortedFilteredRows(api: GridApi) {
    let filtered: any[] = [];
    api.getRenderedNodes().map((n) => {
      if (!!n.data) {
        filtered.push(n.data);
      }
    });
    this.filteredData = filtered;
  }

  resetFilters() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setFilterModel({});
      this.quickFilterText = '';
      this.quickFilter(this.quickFilterText);
    }
  }

  autosizeColumns() {
    if (this.gridOptions?.columnApi) {
      this.gridOptions.columnApi.autoSizeAllColumns(false);
    }
  }
}

export type GridState = {
  columns?: ColumnState[];
  filterModel?: any;
  quickFilter?: string;
  data?: any[];
};
