<div [formGroup]="form">
  <vertical-field name="Original Date">
    <datepicker-wrapper [formControlName]="'fromDate'" [ignoreReadonlyMode]="true"></datepicker-wrapper>
  </vertical-field>
  <vertical-field name="Add Days">
    <numerictextbox-wrapper [formControlName]="'days'" [format]="intFormat" [ignoreReadonlyMode]="true" #first></numerictextbox-wrapper>
  </vertical-field>
  <vertical-field name="Result">
    <datepicker-wrapper [formControlName]="'toDate'" [readonly]="true"></datepicker-wrapper>
  </vertical-field>
</div>
