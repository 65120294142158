<form class="form-container" [formGroup]="form">
  <div class="row">
    <vertical-field name="Value Date" class="col-12">
      <datepicker-wrapper [formControlName]="'valueDate'"></datepicker-wrapper>
    </vertical-field>
    <vertical-field name="Invalid Date" class="col-12">
      <datepicker-wrapper [formControlName]="'invalidDate'" [readonly]="!invalidDate"></datepicker-wrapper>
    </vertical-field>
    <vertical-field *ngIf="invalidDateWarning.show" class="col-12" [showLabel]="false">
      <div class="row flex-column">
        <div class="warning-label"><i class="fas fa-info-circle"></i> {{ invalidDateWarning.text }}.</div>
      </div>
    </vertical-field>
  </div>
</form>
