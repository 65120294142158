import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized, toBradyUTCDate } from 'src/lib/helperFunctions';
import { MarketHoliday } from 'src/lib/newBackendTypes';
import { PromptService } from '../../services/prompt.service';
import { Store } from '../../services/store.service';
import { ThalosApiService } from '../../services/thalos-api.service';

@UntilDestroy()
@Component({
  selector: 'thalos-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
  currentTime: Date;

  holidayMessages: string[] = [];
  holidayMessageIndex = 0;
  holidayMessageTooltip = '';
  clockIntervalTimer;
  messageIntervalTimer;

  authorized: endpointsAuthorized;

  constructor(private api: ThalosApiService, private promptService: PromptService, store: Store) {
    this.currentTime = new Date();
    this.clockIntervalTimer = setInterval(this.clockInterval(), 1000);
    this.messageIntervalTimer = setInterval(this.messageInterval(), 4000);
    endpointAuthorizationSubscription(store, this).subscribe(() => {
      this.updateCurrentHolidays();
    });

    this.holidayMessages = [''];
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  clockInterval() {
    return () => {
      this.currentTime.setSeconds(this.currentTime.getSeconds() + 1);
    };
  }

  messageInterval() {
    return () => {
      this.holidayMessageIndex++;
      if (this.holidayMessageIndex >= this.holidayMessages.length) this.holidayMessageIndex = 0;
    };
  }

  clickUp() {
    this.holidayMessageIndex--;
    if (this.holidayMessageIndex < 0) this.holidayMessageIndex = this.holidayMessages.length - 1;
    clearInterval(this.messageIntervalTimer);
    this.messageIntervalTimer = setInterval(this.messageInterval(), 4000);
  }

  clickDown() {
    this.holidayMessageIndex++;
    if (this.holidayMessageIndex >= this.holidayMessages.length) this.holidayMessageIndex = 0;
    clearInterval(this.messageIntervalTimer);
    this.messageIntervalTimer = setInterval(this.messageInterval(), 4000);
  }

  updateCurrentHolidays() {
    if (!this.authorized[endpoints.listMarketHolidays]) {
      this.holidayMessages = [];
      this.holidayMessageIndex = null;
      this.holidayMessageTooltip = '';
      return;
    }
    let d = new Date();
    let bradyDate = toBradyUTCDate(d);
    this.api.rpc<ListResponse<MarketHoliday>>(endpoints.listMarketHolidays, { filters: { date: bradyDate } }, { list: [], count: 0 }).subscribe((holidayRes) => {
      this.holidayMessages = [];

      for (let holiday of holidayRes.list) {
        if (holiday.market) {
          let str = `${holiday.market.name} is closed for ${holiday.description}`;
          this.holidayMessages.push(str);
        }
      }
      this.holidayMessageIndex = 0;
      this.holidayMessageTooltip = this.holidayMessages.join('\n');
    });
  }

  clickMobileAlert() {
    this.promptService.textDialog('Holidays In Effect', this.holidayMessageTooltip);
  }
}
