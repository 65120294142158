import { from, of } from 'rxjs';
import { AdvanceForm, AdvancePrefill, CreateAdvanceComponent } from 'src/app/+modules/+accounting/create-advance/create-advance.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { toBradyUTCDate } from 'src/lib/helperFunctions';
import { PaymentTerm, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { Advance, AdvanceType, UpdateAdvanceRequest } from 'src/lib/newBackendTypes/advance';
import { toUnit } from 'src/lib/unitConversions';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const updateAdvancePrefillForm: prefillCallback<UpdateAdvancePrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const commonData = delegate.getService('commonData');

      const advance = await api.run<Advance>(endpoints.getAdvance, { filters: { id } });
      const paymentTerm = await api.run<PaymentTerm>(endpoints.getPaymentTerm, { filters: { id: advance.contract.paymentTermId } });

      // Get Contract Units
      const quantityUnit = commonData.staticUnits.value.find((u) => u.unitId === advance.contract.quantityUnitId);
      const priceUnit = commonData.staticUnits.value.find((u) => u.unitId === advance.contract.priceUnitId);
      const currency = commonData.staticCurrencies.value.find((c) => c.id === advance.contract.currencyId);

      let contractAmount = 0;
      for (const line of advance.contract.lines) {
        const priceUnitQuantity = toUnit(line.quantity, quantityUnit, priceUnit);
        // Calculate contract amount by summing the line's amount
        contractAmount += line.price * priceUnitQuantity;
      }

      const isAdvanceTermLine = paymentTerm.termLines.find((line) => line.isAdvance === YN.Y);

      // Calculated amount for Advance
      const amount = parseFloat(Math.max((contractAmount * advance.contract.advanceValue) / 100, isAdvanceTermLine?.termFixedAmount ?? 0).toFixed(2));
      let overrideAmount = advance.advancePayments.reduce((total, ap) => total + ap.paymentEntry.amount, 0);
      if (advance.automaticAmount === YN.N) {
        if (overrideAmount === 0) overrideAmount = advance.totalAmount;
        if (overrideAmount < 0) overrideAmount = -overrideAmount;
      }

      return {
        paymentTermName: advance.paymentTerm.name,
        currency,
        price: contractAmount,
        valueDate: advance.valueDate,
        dueDateType: advance.dueDateType,
        dueDate: advance.dueDate,
        contractDate: toBradyUTCDate(advance.contract.date),
        automaticAmount: advance.automaticAmount,
        amount,
        overrideAmount,
        type: advance.type,
      };
    })()
  );
};

export const updateAdvancePrefillCheck: checkPrefillCallback<UpdateAdvancePrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  return of((prefill.type && [AdvanceType.CLIENT_ADVANCE, AdvanceType.PAYMENT_ADVANCE].includes(prefill.type)) || 'This Letter of Credit is not an Advance');
};

export const updateAdvanceForm: openFormCallback<AdvancePrefill, AdvanceForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<AdvanceForm, CreateAdvanceComponent, AdvancePrefill>(CreateAdvanceComponent, {
    title: 'Update Advance',
    prefillValue: prefill,
    maxWidth: 300,
  });
};

export const submitUpdateAdvance: submitFormCallback<AdvancePrefill, AdvanceForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');

  const request: UpdateAdvanceRequest = {
    id,
    valueDate: form.valueDate,
    dueDateType: form.dueDateType,
    dueDate: form.dueDate,
    automaticAmount: form.automaticAmount,
    amount: form.amount,
  };

  return api.rpc<Advance>(endpoints.updateAdvance, request, null);
};

export const updateAdvancePreset: DynamicFormConstant<AdvancePrefill, AdvanceForm> = {
  allowMultipleRows: false,
  checkPrefill: updateAdvancePrefillCheck,
  openForm: updateAdvanceForm,
  endpoints: [endpoints.getAdvance, endpoints.getPaymentTerm, endpoints.updateAdvance],
  entityType: SourceEntityType.ADVANCE_KEY,
  getPrefill: updateAdvancePrefillForm,
  label: 'Update Advance',
  submitForm: submitUpdateAdvance,
  title: 'Update Advance',
  value: DynamicFormType.UPDATE_ADVANCE,
  width: 300,
};

export type UpdateAdvancePrefill = AdvancePrefill & {
  type: AdvanceType;
};
