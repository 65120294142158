import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { BankAccount } from './account';
import { BankFeeAttribution, ContactBankInformation, PaymentInformationType } from './contactBankInformation';
import { AccountOption, AccountType, GlAccountLeaves } from './glAccount';
import { InvoiceHeader, InvoiceStatus } from './invoice';
import { PaymentEntry, PaymentHeader } from './payment';

export enum PayOrderStatus {
  OPENED = 1,
  WAITING = 2,
  PROCESSED = 3,
  BOOKED = 4,
}
export const PayOrderStatuses = enumOptionsFromEnum(PayOrderStatus);
export const PayOrderStatusesFilteredBooked = enumOptionsFromEnum(PayOrderStatus, [PayOrderStatus.BOOKED]);

export enum PayOrderType {
  NONE = 0, // there are few
  CHECK = 1,
  WIRE_ACH = 3,
}
export const PayOrderTypes = enumOptionsFromEnum(PayOrderType);

export type PayOrderHeader = {
  id: number;
  payOrderNumber: number | null;
  description: string | null;
  payOrderStatus: PayOrderStatus;
  payOrderType: PayOrderType;
  companyId: number | null;
  defExecDate: number | null;
  checkNumber: number | null;
  bankCode: number | null;
  isSent: string | null;
  totalAmount: number | null;
  fedRef: string | null;
  sendFee: number; // 0 = NO; 1 = YES
  payCreationDate: Date | null;
  versionNb: number;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  bookingPaymentId: number | null;
  payOrderLines?: PayOrderLine[];
  bookingPayment?: PaymentHeader;
};
export type PayOrderHeaderList = Omit<PayOrderHeader, 'payCreationDate'> & {
  payCreationDate: number;
};

export type PayOrderLine = {
  payOrderId: number;
  payOrderLineId: number;
  payInstrument: PaymentInformationType | null;
  accountNb: string | null;
  name: string | null;
  address1: string | null;
  address2: string | null;
  postCode: string | null;
  city: string | null;
  countryKey: number;
  indName: string | null;
  indAddress1: string | null;
  indAddress2: string | null;
  indPostCode: string | null;
  indCity: string | null;
  indCountry: string | null;
  indCountryKey: number | null;
  /**
   * @deprecated TO-DO issue-3327: remove column
   */
  indBankAccountNb: string | null;
  swiftCode: string | null;
  swiftYN: string | null;
  salaryPay: string | null;
  bankFeeAttribution: BankFeeAttribution;
  otherInstructions: string | null;
  foreignBankId: string | null;
  ourAccountCode: number;
  balanceAmount: number;
  amountToApply: number;
  amount: number;
  payComment: string | null;
  payExecDate: number | Date | null;
  account: GlAccountLeaves;
  accountId: number;
  voucherKey: number | null;
  /**
   * @deprecated TO-DO issue-3327: remove column
   */
  defInfoKey: number | null;
  defaultInfoPayLineKey: number;
  amountInAccountCurr: number | null;
  currencyId: number;
  intAccount: string | null;
  intName: string | null;
  intAddress1: string | null;
  intAddress2: string | null;
  intPostCode: string | null;
  intCity: string | null;
  intCountry: string | null;
  intSwiftCode: string | null;
  interCompanyId: number | null;
  beneficiaryState: string | null;
  paymentId: number | null;
  paymentEntryId: number | null;
  payOrderHeader?: PayOrderHeader;
  paymentInfo?: ContactBankInformation;
  paymentEntry?: PaymentEntry | null;
  ourAccount?: BankAccount;
  voucher?: InvoiceHeader;
  type?: PayOrderLineType;
  glAccount?: GlAccountLeaves;
};

export type PayOrderLineForm = Subset<
  PayOrderLine,
  'amount' | 'payComment',
  'voucherKey' | 'accountId' | 'payOrderLineId' | 'payOrderId' | 'paymentEntryId' | 'payExecDate' | 'type' | 'paymentEntry' | 'voucher' | 'balanceAmount' | 'paymentInfo' | 'ourAccount'
> & {
  companyId?: number;
  entryText: string;
  lineNumber?: number;
  glAccount?: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  account?: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
};

export enum PayOrderLineType {
  MANUAL = 0,
  VOUCHER = 1,
  PAYMENT = 2,
  BANK_FEE = 3,
}

export const payOrderLineTypes = enumOptionsFromEnum(PayOrderLineType);

export type UpsertPayOrderHeaderRequest = Subset<PayOrderHeader, 'description' | 'payCreationDate', 'id' | 'checkNumber' | 'payOrderStatus'> & {
  defaultInfoPayLineKey: number | null;
  ourAccountCode: number | null;
}; //this is only going to be sent once in the header, but it for all the entries

export type UpsertPayOrderLineManualRequest = { type: PayOrderLineType.MANUAL } & Subset<PayOrderLine, 'amount' | 'payComment' | 'accountId', 'payOrderId' | 'payOrderLineId'>;
export type UpsertPayOrderLineVoucherRequest = { type: PayOrderLineType.VOUCHER } & Subset<PayOrderLine, 'amount' | 'payComment' | 'voucherKey', 'payOrderId' | 'payOrderLineId'>;
export type UpsertPayOrderLinePaymentRequest = { type: PayOrderLineType.PAYMENT } & Subset<PayOrderLine, 'payComment' | 'paymentEntryId', 'payOrderId' | 'payOrderLineId'>;
export type UpsertPayOrderLineRequest = UpsertPayOrderLineManualRequest | UpsertPayOrderLineVoucherRequest | UpsertPayOrderLinePaymentRequest;
export type UpsertPayOrderRequest = UpsertPayOrderHeaderRequest & {
  lines: UpsertPayOrderLineRequest[];
};

export type OpenPayableItem = {
  entryType: EntryType;
  entryId: number;
  entryTypeLabel: string;
  entryReference: string;
  valueDate: number;
  counterpartyId: number;
  counterpartyName: string;
  entryTitle: string;
  externalRef: string;
  originalAmount: number;
  originalBaseAmount: number;
  balanceAmount: number;
  balanceBaseAmount: number;
  entryStatus: InvoiceStatus;
  currencyId: number;
  accountId: number;
  accountIdenLong: string;
  accountType: AccountType;
  accountOption: AccountOption;
  companyId: number;
  yourReference: string;
};

export enum PayOrderEntryType {
  MANUAL = 'Manual',
  VOUCHER = 'Voucher',
  PAYMENT = 'Payment',
  BANK_FEE = 'Bank Fee',
}

export enum EntryType {
  INV = 'INV',
  PMT = 'PMT',
}
