<form [formGroup]="form">
  <div class="d-flex" style="flex-direction: column">
    <vertical-field name="Name">
      <input-wrapper [formControlName]="'name'"></input-wrapper>
    </vertical-field>
    <vertical-field name="Default Groups Expanded">
      <numerictextbox-wrapper [formControlName]="'groupsExpanded'" [format]="intFormat" [min]="0" [max]="10" [decimals]="0"></numerictextbox-wrapper>
    </vertical-field>
    <vertical-field name="Shared">
      <checkbox-wrapper [formControlName]="'shared'" [valueMask]="{ true: 'Y', false: 'N' }"></checkbox-wrapper>
    </vertical-field>
    <vertical-field name="Make Default">
      <checkbox-wrapper [formControlName]="'makeDefault'" [valueMask]="{ true: 'Y', false: 'N' }"> </checkbox-wrapper>
    </vertical-field>
  </div>
</form>
