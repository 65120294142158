import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GridOptions, SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { defaultComplexGrid, getContextMenuItems, gridDateFormatter } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { budgetElementDropdown, counterpartyDropdown } from 'src/lib/commonTypes';
import { Permissions } from 'src/lib/componentPermissions';
import { QueryFilters } from 'src/lib/generics';
import { ContactPrimitive, PropertyDocument, YN } from 'src/lib/newBackendTypes';
import { ServiceOrder } from 'src/lib/newBackendTypes/serviceOrder';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';

export function reduceFormatter<A = unknown, T extends number | string = string>(callbackfn: (previousValue: T, currentValue: A, currentIndex: number, array: A[]) => T, initialValue: T) {
  return (params: ValueFormatterParams) => {
    if (!Array.isArray(params.value)) return params.value;
    return params.value.reduce(callbackfn, initialValue);
  };
}

export function mapAndJoinFormatter<A = unknown, U extends number | string = string>(separator: string, callbackfn: (value: A, index: number, array: A[]) => U, thisArg?: any) {
  return (params: ValueFormatterParams) => {
    if (!Array.isArray(params.value)) return params.value;
    return params.value.map(callbackfn, thisArg).join(separator);
  };
}

@Component({
  selector: 'metal-control-service-order-finder',
  templateUrl: './service-order-finder.component.html',
  styleUrls: ['./service-order-finder.component.scss'],
})
@Permissions('Service Order Finder', [endpoints.listServiceOrders])
export class ServiceOrderFinderComponent implements OnInit, SelectorComponent<ServiceOrder[]> {
  popup: boolean;
  popupObservable: Observable<ServiceOrder[]>;
  popupSubscriber: Observer<ServiceOrder[]>;
  preselectItems(items: ServiceOrder[]) {
    this.preselectedServiceOrders = items;
  }

  @Input()
  preselectedServiceOrders?: ServiceOrder[];

  gridOptions: GridOptions;
  data: ServiceOrder[];

  filterForm: TypedFormGroup<ServiceOrderFilters>;

  counterpartyDropdown = counterpartyDropdown();
  budgetElementDropdown = budgetElementDropdown();

  preselectedCounterparty?: ContactPrimitive;

  @Input()
  readonly: boolean = false;

  firstCall = true;

  constructor(private api: ThalosApiService, private formatter: DataFormattingService, private spinnerService: SpinnerService, private delegate: DelegateService) {
    this.filterForm = new TypedFormGroup<ServiceOrderFilters>({
      budgetElement: new UntypedFormControl(),
      contact: new UntypedFormControl(),
    });
    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'id'),
      domLayout: 'autoHeight',
      rowDeselection: true,
      getRowHeight: (params) => {
        return params.node.group ? 45 : undefined;
      },
      columnDefs: [
        {
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: false,
          width: 50,
          cellStyle: (params) => {
            return this.readonly ? { 'pointer-events': 'none' } : null;
          },
        },
        { headerName: 'Counterparty', field: 'contact.displayName', filter: 'agTextColumnFilter' },
        {
          headerName: 'Element',
          field: 'budgetElement.name',
          filter: 'agTextColumnFilter',
          width: 150,
        },
        {
          headerName: 'Amount',
          field: 'orderPrice',
          valueFormatter: this.formatter.gridCurrencyFormatter('currencyId'),
          filter: 'agNumberColumnFilter',
          type: 'rightAligned',
        },
        { headerName: 'Reference', field: 'orderReference', filter: 'agTextColumnFilter' },
        {
          headerName: 'Date',
          field: 'orderDate',
          valueFormatter: gridDateFormatter(),
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Containers',
          field: 'shipments',
          valueFormatter: mapAndJoinFormatter<PropertyDocument>(`\n`, (shipment) => `${shipment.containerMarks} - ${shipment.id}`),
          autoHeight: true,
          width: 180,
          cellStyle: { 'white-space': 'pre' },
        },
      ],
      onSelectionChanged: this.selectionChange(),
      isRowSelectable: this.isRowSelectable(),
      getContextMenuItems: getContextMenuItems(),
    };
    this.data = [];

    this.popupObservable = new Observable((observer) => {
      this.popupSubscriber = observer;
    });
  }

  onGridReady(_) {
    if (this.popup) {
      this.gridOptions!.api.setDomLayout('normal');
      this.gridOptions!.api.setPopupParent(document.querySelector('.k-dialog'));
    }
    if (this.preselectedServiceOrders) {
      for (let so of this.preselectedServiceOrders) {
        let node = this.gridOptions.api.getRowNode(`${so.id}`);
        if (!!node) {
          node.setSelected(true);
        }
      }
    }
  }

  ngOnInit(): void {
    if (this.preselectedCounterparty) {
      this.filterForm.patchValue({ contact: this.preselectedCounterparty });
    }

    if (this.preselectedCounterparty) {
      this.fetchServiceOrders();
    } else {
      this.firstCall = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preselectedServiceOrders'] && !this.popup) {
      this.data = this.preselectedServiceOrders || [];
    }
  }

  fetchServiceOrders() {
    if (this.readonly) return;
    let filters: QueryFilters<ServiceOrder> = {
      completelyInvoiced: YN.N,
    };

    let values = this.filterForm.value;

    if (!values.budgetElement && !values.contact) {
    }

    if (values.budgetElement) {
      filters.budgetElementKey = values.budgetElement.id;
    }
    if (values.contact) {
      filters.counterpartyId = values.contact.id;
    }

    let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Service Orders');
    this.api
      .rpc<ListResponse<ServiceOrder>>(endpoints.listServiceOrders, { filters }, { list: [], count: 0 })
      .pipe(map((res) => res.list))
      .subscribe((serviceOrders) => {
        this.spinnerService.completeRequest(rid);
        let selectedServiceOrders: ServiceOrder[];
        if (this.data !== null) {
          selectedServiceOrders = this.gridOptions.api.getSelectedRows();
        }

        if (this.firstCall && this.preselectedServiceOrders) {
          for (let so of this.preselectedServiceOrders) {
            if (!selectedServiceOrders.some((match) => match.id === so.id)) {
              selectedServiceOrders.push(so);
            }
          }
        }

        if (!!selectedServiceOrders) {
          this.gridOptions.onRowDataChanged = () => {
            this.firstCall = false;
            for (let serviceOrder of selectedServiceOrders) {
              const row = this.gridOptions.api.getRowNode(`${serviceOrder.id}`);
              if (row) row.setSelected(true);
            }
            this.gridOptions.onRowDataChanged = null;
          };

          for (let serviceOrder of selectedServiceOrders) {
            let index = serviceOrders.findIndex((so) => serviceOrder.id === so.id);
            if (index >= 0) serviceOrders.splice(index, 1);
            serviceOrders.unshift(serviceOrder);
          }
          if (this.preselectedServiceOrders) {
            for (let serviceOrder of this.preselectedServiceOrders) {
              let index = serviceOrders.findIndex((so) => serviceOrder.id === so.id);
              if (index >= 0) serviceOrders.splice(index, 1);
              serviceOrders.unshift(serviceOrder);
            }
          }
        }
        this.data = serviceOrders;
      });
  }
  selectionChange() {
    return (event: SelectionChangedEvent) => {
      if (!!this.popupSubscriber) {
        this.popupSubscriber.next(this.gridOptions.api.getSelectedRows());
      }
    };
  }

  isRowSelectable() {
    return (_) => !this.readonly;
  }
}

type ServiceOrderFilters = Pick<ServiceOrder, 'budgetElement'> & {
  contact?: ContactPrimitive;
};
