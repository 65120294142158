import { ContractClass } from './contract';
import { FiscalCompany } from './fiscalCompany';
import { NetPositionLines } from './netPositionLine';
import { Product } from './product';

export enum NetPositionOperations {
  CONVERT_DRAFT_TO_CONTRACT = 1,
  UPDATE_CONTRACT = 2,
  INVOICE_PURCHASE_TICKET_FIXED = 3,
  INVOICE_PURCHASE_TICKET_PROV = 4,
  CREATE_CLIENT_INVOICE_FIXED = 5,
  CREATE_CLIENT_INVOICE_PROV = 6,
  REVERSE_PURCHASE_TICKET = 7,
  REVERSE_CLIENT_INVOICE = 8,
  CREATE_PRICE_FIXATION = 9,
  APPLY_PRICE_FIXATION = 10,
  UNAPPLY_PRICE_FIXATION = 11,
  FUTURES_CREATION = 12,
  UNLINK_POSITION_LINE = 13,
  INITIAL_BALANCE = 14,
  PRICE_OPERATION = 15,
  FUTURES_DELETION = 16,
  FUTURES_UPDATE = 17,
  CONTRACT_CANCELLATION = 18,
  DELETE_PRICE_FIXATION = 19,
  UPDATE_PRICE_FIXATION = 20,
  UPDATE_VOUCHER = 21,
  NIGHT_ROLL_FWD = 22,
  PHYSICAL_ONLY_OPERATION = 23,
  FUTURE_UPDATE_REVERSAL = 24,
  CONTRACT_UPDATE_REVERSAL = 25,
  VOUCHER_UPDATE_REVERSAL = 26,
  FIXATION_UPDATE_REVERSAL = 27,
}

export type NetPositionHeader = {
  id: number;
  valueDate: Date;
  entryDate: Date;
  operation: NetPositionOperations;
  description: string;
  hedgeableType: ContractClass | null;
  productId: number;
  createdBy: number | null;
  updatedBy: number | null;
  updateDate: Date | null;
  companyId: number;
  netPositionLines?: NetPositionLines[];
  articleType?: Product;
  company?: FiscalCompany;
};
