import { InvoiceHeader } from './invoice';
import { PropertyDocument } from './propertyDocument';

export class Delivery {
  deliveryKey!: number;
  invoiceKey!: number | null;
  creditNoteKey!: number | null;
  interestCreditNoteKey!: number | null;
  deliveryType!: number | null;
  saleQuantity!: number | null;
  numberOfBags!: number | null;
  netWeight!: number | null;
  grossWeight!: number | null;
  weightUnit!: number | null;
  journalKey!: number | null;
  wrPaddingJournalKey!: number | null;
  modifiedBy!: string | null;
  modifyDate!: number | null;
  versionNb!: number | null;
  percentInvoice!: number;
  saleShipments?: PropertyDocument[];
  purchaseShipments?: PropertyDocument[];
  invoice?: InvoiceHeader;
}
