import { Component, OnInit } from '@angular/core';
import { ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
  selector: 'color-picker-prompt',
  templateUrl: './color-picker-prompt.component.html',
})
export class ColorPickerPromptComponent implements OnInit {
  public label: string;
  value: string;
  hexColors: string[] = [
    '#F2F2F2',
    '#CCFFFF',
    '#CCECFF',
    '#FFCCFF',
    '#FFE1F0',
    '#FFE6CD',
    '#FFFFCC',
    '#CCFFCC',
    '#D9D9D9',
    '#7DFFFF',
    '#85D6FF',
    '#FF99FF',
    '#FFA7D3',
    '#FFCC99',
    '#FFFF99',
    '#99FF99',
    '#A6A6A6',
    '#00F4EE',
    '#61CAFF',
    '#FF66FF',
    '#FF6699',
    '#FF9966',
    '#FFFF4B',
    '#00FF00',
    '#808080',
    '#33CCCC',
    '#007BB8',
    '#CC00CC',
    '#FF0066',
    '#FF6600',
    '#FCF600',
    '#33CC33',
  ];
  public chromeControl = new ColorPickerControl().setColorPresets(this.hexColors);

  constructor() {
    this.value = null;
  }

  ngOnInit(): void {}
}
