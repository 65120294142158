import { map } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { BaseDiscrepancy, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { Subset } from 'src/lib/generics';

export const discrepancyArchiveUnarchivePrefillCallback: prefillCallback<BaseDiscrepancy> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<BaseDiscrepancy>(endpoints.getDiscrepancy, { filters: { id } }, null);
};

const openDiscrepancyArchiveUnarchiveForm: openFormCallback<BaseDiscrepancy, YN> = (delegate, id, prefill) => {
  const text = prefill.archived === YN.N ? `Discrepancy will be Archived. Continue?` : `Discrepancy will be Unarchived. Continue?`;

  const prompt = delegate.getService('prompt');

  return prompt.simpleConfirmation(`Update Discrepancy`, text).pipe(map((answer) => (answer ? prefill.archived : 'Close')));
};

export const archiveUnarchiveDiscrepancyCallback: submitFormCallback<BaseDiscrepancy, YN> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const request: ArchiveUnarchiveDiscrepancyRequest = {
    id,
    archived: form === YN.N ? YN.Y : YN.N,
  };

  return api.rpc<BaseDiscrepancy>(endpoints.archiveUnarchiveDiscrepancy, request, null);
};

export const archiveUnarchiveDiscrepancyPreset: DynamicFormConstant<BaseDiscrepancy, YN> = {
  value: DynamicFormType.ARCHIVE_UNARCHIVE_DISCREPANCY,
  entityType: SourceEntityType.DISCREPANCY_ID,
  label: 'Archive/Unarchive Discrepancy',
  title: 'Archive/Unarchive Discrepancy',
  width: 200,
  endpoints: [endpoints.getDiscrepancy, endpoints.archiveUnarchiveDiscrepancy],
  getPrefill: discrepancyArchiveUnarchivePrefillCallback,
  openForm: openDiscrepancyArchiveUnarchiveForm,
  submitForm: archiveUnarchiveDiscrepancyCallback,
};

type ArchiveUnarchiveDiscrepancyRequest = Subset<BaseDiscrepancy, 'id' | 'archived'>;
