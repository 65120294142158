import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GetContextMenuItemsParams, GridOptions, MenuItemDef, RowDoubleClickedEvent, RowNode } from 'ag-grid-community';
import * as _ from 'lodash';
import { concat, lastValueFrom, of } from 'rxjs';
import { switchMap, tap, toArray } from 'rxjs/operators';
import { TaskComponent, TaskForm } from 'src/app/shared/task/task.component';
import { ListResponse } from 'src/lib';
import { dateColumn, defaultComplexGrid, getContextMenuItems, selectionColumn } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized, getToday } from 'src/lib/helperFunctions';
import { Contact, PartialContact, YN } from 'src/lib/newBackendTypes';
import { SourceEntityTypeEntityNameMap } from 'src/lib/newBackendTypes/entityType';
import { Task, UpdateTaskRequest } from 'src/lib/newBackendTypes/task';
import { ExternalLinkIcon, getIconClassFromEntityType, randomFetchSynonym } from 'src/lib/uiConstants';
import { State } from '../../reducers';
import { DelegateService } from '../../services/delegate-service.service';
import { EntityLookupService } from '../../services/entity-lookup.service';
import { SelectorPopupService, SelectorResult } from '../../services/selector-popup.service';
import { SpinnerService } from '../../services/spinner.service';
import { Store } from '../../services/store.service';
import { ThalosApiService } from '../../services/thalos-api.service';

@UntilDestroy()
@Component({
  selector: 'user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss'],
})
export class UserTasksComponent implements OnInit, OnDestroy {
  gridOptions: GridOptions;

  tasks: Task[];

  userId: number;
  authorized: endpointsAuthorized;
  get listTasksAuthorized() {
    return this.authorized[endpoints.listTasks];
  }

  refreshTimer;

  constructor(
    private store: Store,
    private api: ThalosApiService,
    private entityLookupService: EntityLookupService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private selectorService: SelectorPopupService,
    private delegate: DelegateService
  ) {
    endpointAuthorizationSubscription(store, this);
    this.tasks = [];
    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'id'),
      getRowStyle: this.getRowStyle(),
      columnDefs: [
        selectionColumn(),
        { field: 'readableName', headerName: 'Source' },
        { field: 'taskTitle', headerName: 'Title', width: 550, cellStyle: this.taskTitleStyle() },
        { field: 'taskText', headerName: 'Description', width: 550, cellStyle: this.taskTitleStyle() },
        { field: 'taskPriority', headerName: 'Priority', width: 115 },
        { field: 'sourceClient.displayName', headerName: 'Counterparty', width: 150 },
        {
          ...dateColumn('dueDate'),
          cellStyle: this.dueDateStyle(),
          sort: 'asc',
          initialSort: 'asc',
        },
      ],
      getContextMenuItems: getContextMenuItems(this.getGotoTaskItem(), this.updateTaskMenuItem(), this.markCompleteTaskMenuItem()),
      onRowDoubleClicked: this.rowDoubleClicked(),
      sideBar: false,
    };

    this.userId = this.store.snapshot((state: State) => state.user?.user?.id);

    this.fetchTasks();

    this.refreshTimer = setInterval(() => {
      this.fetchTasks();
    }, 300000);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
  }

  onGridReady() {}

  getGotoTaskItem() {
    return (params: GetContextMenuItemsParams): MenuItemDef | null => {
      const data: Task = params?.node?.data;
      if (!data || typeof data !== 'object') return null;

      const entityType = data.sourceId;
      const entityId = data.sourceKey1;

      const routeExists = this.entityLookupService.getEntityExists(entityType);
      const name = SourceEntityTypeEntityNameMap[entityType] ?? 'Task Target';

      if (!routeExists || !entityId) return null;

      let icon: string;
      const iconClass = getIconClassFromEntityType(entityType);
      if (entityType) icon = `<i class="${iconClass}" style="padding-left: 2px; font-size: 13px">`;

      return {
        name: `Go To ${name}`,
        icon,
        action: () => {
          this.entityLookupService.gotoEntity(entityType, entityId);
        },
        subMenu: [
          {
            name: 'New Tab',
            icon: `<i class="${ExternalLinkIcon}" style="padding-left: 2px; font-size: 13px">`,
            action: () => {
              const link = this.entityLookupService.getLink(entityType);
              window.open(link + '/' + entityId);
            },
          },
        ],
      };
    };
  }

  getRowStyle() {
    return (params?: { node?: RowNode }) => {
      if (!params?.node?.data) {
        return {};
      } else {
        const task: Task = params.node.data;
        let backgroundColor: string;
        if (task.accomplished === YN.Y) backgroundColor = '#0F9';
        return { 'background-color': backgroundColor };
      }
    };
  }

  refresh() {
    setInterval(() => {
      this.fetchTasks();
    }, 300000);
    this.fetchTasks();
  }

  fetchTasks() {
    if (!this.userId) return;
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Tasks');
    this.api.rpc<ListResponse<Task>>(endpoints.pendingTasks, { assigneeId: this.userId }, { list: [], count: 0 }).subscribe((res) => {
      this.spinnerService.completeRequest(rid);
      this.tasks = res.list;
    });
  }

  quickFilter($event) {
    this.gridOptions.api.setQuickFilter($event.target.value);
  }

  markCompleteTaskMenuItem() {
    return (params: GetContextMenuItemsParams) => {
      if (!this.authorized[endpoints.updateTask]) return;
      const selected = params.api.getSelectedRows().filter((r) => !!r);
      if (!!selected.length) {
        const status = new Set(selected.map((t) => t.accomplished));
        if (status.size !== 1) return [];
        const action = status.has(YN.Y) ? YN.N : YN.Y;
        const name = action === YN.N ? 'Mark Selected Task(s) Incomplete' : 'Mark Selected Task(s) Complete';
        return {
          name,
          action: () => this.markTaskCompletion(selected, action),
        };
      }
      if (params?.node?.data) {
        const task: Task = params.node.data;

        return {
          name: task.accomplished === YN.Y ? 'Mark Incomplete' : 'Mark Complete',
          action: () => this.markTaskCompletion([task], task.accomplished === YN.Y ? YN.N : YN.Y),
        };
      }
      return null;
    };
  }

  markTaskCompletion(tasks: Task[], value: YN) {
    const requests: UpdateTaskRequest[] = tasks.map((t) => ({
      id: t.id,
      accomplished: value,
    }));
    const rid = this.spinnerService.startRequest('Updating Task');
    concat(...requests.map((r) => this.api.rpc<Task>(endpoints.updateTask, r, null)))
      .pipe(toArray())
      .subscribe((res) => {
        this.spinnerService.completeRequest(rid);

        if (res.every((t) => !!t)) {
          this.dialogService.open({
            title: 'Task(s) Updated',
            content: 'Task(s) have been successfully updated',
          });
        }
        this.fetchTasks();
      });
  }

  updateTaskMenuItem() {
    return (params: GetContextMenuItemsParams) => {
      if (!this.authorized[endpoints.updateTask] && !this.authorized[endpoints.getTask]) return;
      if (params?.node?.data) {
        const task: Task = params.node.data;

        return {
          name: 'Edit',
          action: () => this.updateTask(task),
        };
      }
      return null;
    };
  }

  updateTask(task: Task) {
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Task');
    this.api
      .rpc<Task>(endpoints.getTask, { filters: { id: task.id } }, null)
      .pipe(
        switchMap(async (task) => {
          const missingContactIds: number[] = [];

          if (task.updatedBy && !task.updator) missingContactIds.push(task.updatedBy);
          if (task.createdBy && !task.creator) missingContactIds.push(task.createdBy);
          if (missingContactIds.length > 0) {
            const contacts = await lastValueFrom(this.api.rpc<PartialContact[]>(endpoints.getContactNames, { contactIds: missingContactIds }, []));
            if (task.updatedBy && !task.updator) task.updator = contacts.find((c) => c.id === task.updatedBy) as Contact;
            if (task.createdBy && !task.creator) task.creator = contacts.find((c) => c.id === task.createdBy) as Contact;
          }

          return task;
        }),
        tap(() => {
          this.spinnerService.completeRequest(rid);
        }),
        switchMap((task) => {
          if (!task) return of(null);

          return this.selectorService.openForm<TaskForm, TaskComponent>(TaskComponent, {
            title: 'Edit Task',
            width: 300,
            maxWidth: 600,
            prefillValue: {
              ...task,
              assignees: (task.taskAssignees || []).flatMap((a) => a.assignee ?? []),
            },
            submitButtonText: 'Save',
          });
        })
      )
      .subscribe((res: SelectorResult<TaskForm>) => {
        if (res === 'Close' || res === null) {
          return;
        } else {
          const request: UpdateTaskRequest = {
            id: task.id,
          };
          if (res.accomplished !== task.accomplished) request.accomplished = res.accomplished;
          if (res.taskTitle !== task.taskTitle) request.taskTitle = res.taskTitle;
          if (res.taskText !== task.taskText) request.taskText = res.taskText;
          if (res.dueDate !== task.dueDate) request.dueDate = res.dueDate;
          if (
            !_.isEqual(
              (task.taskAssignees || []).map((a) => a.assigneeAlfCode),
              res.assignees.map((c) => c.id)
            )
          ) {
            request.assignees = res.assignees.map((c) => c.id);
          }
          if (res.taskPriority !== task.taskPriority) request.taskPriority = res.taskPriority;

          const rid = this.spinnerService.startRequest('Updating Task');
          this.api.rpc<Task>(endpoints.updateTask, request, null).subscribe((res) => {
            this.spinnerService.completeRequest(rid);

            if (!!res) {
              this.dialogService.open({
                title: 'Task Updated',
                content: 'Task has been successfully updated',
              });
              this.fetchTasks();
            }
          });
        }
      });
  }

  dueDateStyle() {
    return (params: any) => {
      const task: Task = params?.node?.data;

      if (!task) return null;
      else return task.accomplished === YN.N && task.dueDate && new Date(task.dueDate) < getToday() ? { 'font-weight': 'bold', color: '#FF3333' } : { 'font-weight': 'normal', color: 'black' };
    };
  }

  taskTitleStyle() {
    return (params: any) => {
      const task: Task = params?.node?.data;

      if (!task) return null;
      else if (task.accomplished === YN.N && task.dueDate && new Date(task.dueDate) < getToday()) return { 'font-weight': 'bold', 'text-decoration': 'none', color: '#FF3333' };
      else return task.accomplished === YN.Y ? { 'text-decoration': 'line-through', 'font-weight': 'normal', color: 'black' } : { 'text-decoration': 'none', 'font-weight': 'normal', color: 'black' };
    };
  }

  rowDoubleClicked() {
    return (params: RowDoubleClickedEvent) => {
      if (!this.authorized[endpoints.updateTask]) return;
      const task: Task = params?.data;

      if (!!task) this.updateTask(task);
    };
  }
}
