import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { PropertyDocument, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

export const shipmentPrefill: prefillCallback<PropertyDocument> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<PropertyDocument>(endpoints.getShipment, { filters: { id } }, null, {
    blockRedirect: true,
  });
};

export const updateRefReceivedForm: createFormCallback<PropertyDocument, Pick<PropertyDocument, 'refReceived'>> = (delegate, id, prefill) => {
  return [_fe('refReceived', 'QP Notes', 'Text')];
};

export const updateRefReceivedCallback: submitFormCallback<PropertyDocument, Pick<PropertyDocument, 'refReceived'>> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');

  return api.rpc<PropertyDocument>(endpoints.updateRefReceived, { shipmentId: id, refReceived: form.refReceived }, null);
};

export const updateRefReceivedPreset: DynamicFormConstant<PropertyDocument, Pick<PropertyDocument, 'refReceived'>> = {
  getPrefill: shipmentPrefill,
  createForm: updateRefReceivedForm,
  submitForm: updateRefReceivedCallback,
  title: 'Edit QP Notes',
  value: DynamicFormType.UPDATE_REFERENCE_RECIEVED,
  label: 'Edit QP Notes',
  endpoints: [endpoints.updateRefReceived, endpoints.getShipment],
  entityType: SourceEntityType.CHUNK_KEY,
  allowMultipleRows: false,
};
