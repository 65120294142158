import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { NumberFormatOptions } from '@progress/kendo-intl';
import { SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { MathCalculatorComponent } from '../../math-calculator/math-calculator.component';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'numerictextbox-wrapper',
  templateUrl: './numerictextbox-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => NumerictextboxWrapperComponent) }],
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
})
export class NumerictextboxWrapperComponent extends FormElementComponent {
  @ViewChild('input', { static: false })
  input: NumericTextBoxComponent;
  @Input()
  min?: number;
  @Input()
  max?: number;
  @Input()
  decimals?: number;
  @Input()
  format?: string | NumberFormatOptions;
  @Input()
  fieldType?: NumericTextBoxType;

  @Input('showCalculator')
  showCalculator: boolean = false;

  get decimalFormat(): string {
    let minimumFractionDigits: number;
    let maximumFractionDigits: number;
    minimumFractionDigits = this.format && typeof this.format === 'object' ? this.format.minimumFractionDigits || 0 : 0;
    maximumFractionDigits = this.format && typeof this.format === 'object' ? this.format.maximumFractionDigits || this.decimals || 0 : this.decimals || 1;
    return `1.${minimumFractionDigits}-${maximumFractionDigits}`;
  }

  constructor(controlDir: NgControl, store: Store, private selector: SelectorPopupService) {
    super(controlDir, store);
  }

  focus() {
    if (this.input) {
      setTimeout(() => {
        this.input.focus();
      });
    }
  }

  openCalculator() {
    this.selector
      .openForm(MathCalculatorComponent, {
        title: 'Expression Calculator',
        initializer: (c) => {
          c.form.patchValue({ expression: this.value });
          c.outputType = 'number';
        },
        width: 400,
        height: 265,
      })
      .subscribe((res) => {
        if (res !== 'Close' && typeof res === 'number') {
          this.value = res;
        }
        this.input.focus();
      });
  }
}

export enum NumericTextBoxType {
  AMOUNT = 1,
  PRICE = 2,
  QUANTITY = 3,
  PERCENTAGE = 4,
}
