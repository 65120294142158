import { from, map } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { Item, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const itemPrefillCallback: prefillCallback<Item> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const item = await api.run<Item>(endpoints.getItem, { filters: { id } }, null);

      return item;
    })()
  );
};

const openArchiveUnarchiveItemForm: openFormCallback<Item, YN> = (delegate, id, prefill) => {
  const text = prefill.archived === YN.N ? `Item will be Archived. Continue?` : `Item will be Unarchived. Continue?`;

  const prompt = delegate.getService('prompt');

  return prompt.simpleConfirmation(`Update Item`, text).pipe(map((answer) => (answer ? prefill.archived : 'Close')));
};

export const archiveUnarchiveItemCallback: submitFormCallback<Item, YN> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const request = {
    id,
  };
  const endpoint = form === YN.N ? endpoints.archiveItem : endpoints.unarchiveItem;

  return api.rpc<Item>(endpoint, request, null);
};

export const archiveUnarchiveItemPreset: DynamicFormConstant<Item, YN> = {
  value: DynamicFormType.ARCHIVE_UNARCHIVE_ITEM,
  entityType: SourceEntityType.ITEM_KEY,
  label: 'Archive/Unarchive Item',
  title: 'Archive/Unarchive Item',
  width: 200,
  endpoints: [endpoints.getItem, endpoints.archiveItem, endpoints.unarchiveItem],
  getPrefill: itemPrefillCallback,
  openForm: openArchiveUnarchiveItemForm,
  submitForm: archiveUnarchiveItemCallback,
};
