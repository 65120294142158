<div *ngIf="invoices?.length !== 0" class="invoice">
  <div *ngFor="let invoice of invoices" class="invoice-line">
    <div class="line-header">
      <div [ngClass]="{ provisional: invoice.fixedPrice === 'N' }">{{ invoice.marks }} ({{ invoice.shipmentId }})</div>
      <div *ngIf="invoice.fixedPrice === 'N'">PROVISIONAL</div>
    </div>
    <div class="line-body">
      <div>
        <i>{{ invoice.netWeight }}</i> {{ invoice.quantityUnit }}
      </div>
      <div>x</div>
      <div>
        <i>{{ invoice.unitPriceString }}</i> {{ invoice.currency }}/{{ invoice.priceUnit }}
      </div>
      <div>=</div>
      <div>
        <i>{{ invoice.totalPriceString }}</i> {{ invoice.currency }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="invoices?.length === 0">No invoice data found</div>
