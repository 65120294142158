import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'thalos-text-dialog',
  template: `<div class="thalos-text-dialog">{{ text }}</div>`,
  styles: [
    `
      .thalos-text-dialog {
        white-space: pre-wrap;
        text-align: center;
      }
    `,
  ],
})
export class TextDialogComponent implements OnInit {
  @Input()
  text!: string;

  constructor() {}

  ngOnInit(): void {}
}
