import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  CellClassRules,
  ColDef,
  ColumnMenuTab,
  ColumnState,
  ExcelStyle,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  IAggFuncParams,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import moment from 'moment';
import { combineLatest, firstValueFrom, Observable, Observer } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { UPDATE_ANCHOR_POINT } from 'src/app/core/reducers/actions';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { FlexService } from 'src/app/core/services/flex.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { DebitCreditRenderer } from 'src/app/shared/aggrid/debitcreditrenderer/DebitCreditRenderer';
import { ListResponse } from 'src/lib';
import {
  basicNumberFormatter,
  copyCell,
  dateFormatter,
  dateGetter,
  dateGridComparator,
  defaultComplexGrid,
  defaultListComplexGrid,
  dynamicFormItem,
  enumLabelFormatter,
  enumValueGetter,
  getContextMenuItems,
  getMainMenuItems,
  getServiceOrderMenuItem,
  gotoMenu,
  gotoMenuItem,
  gridDateGetter,
  percentFormatter,
  uniqueValuesAggregator,
} from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { BaseList, GridState } from 'src/lib/BaseList';
import { newTabWithData } from 'src/lib/broadcastChannelHelpers';
import { MiscFeature } from 'src/lib/feature';
import { DynamicFormPresets } from 'src/lib/flex/flexDynamicForm';
import { ListViewFilter } from 'src/lib/flex/flexFilterQueries';
import { printDocumentPreset } from 'src/lib/flex/forms/printDocument';
import { endpointAuthorizationSubscription, endpointsAuthorized, fromBradyDateOrNull, getTodayUTC } from 'src/lib/helperFunctions';
import { IRoutable } from 'src/lib/isRoutable';
import { aggType, avgAggregate, ColumnOption, CustomAggFunction, maxAggregate, minAggregate, staticMapper, sumAggregate, UtilsService } from 'src/lib/layoutsUtils';
import { SourceEntityType, SourceEntityTypeEntityNameMap, YN } from 'src/lib/newBackendTypes';
import { FlexViewIcon, randomFetchSynonym } from 'src/lib/uiConstants';
import {
  agGridColumnMenuMap,
  FilterStrategy,
  IDListColumn,
  ListColumn,
  ListColumnMenu,
  ListColumnType,
  ListColumnVisibility,
  ListDateGroupBehavior,
  ListDateGroupBehaviors,
  ListFilterGroup,
  ListFilterType,
  ListLayout,
  ListView,
  ViewType,
} from 'src/lib/views';

@UntilDestroy()
@Component({
  selector: 'flex-view',
  templateUrl: './flex-view.component.html',
  styleUrls: ['./flex-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlexComponent extends BaseList implements OnInit, OnDestroy, IRoutable {
  /**
   * The active rows in the table.  Null if the view has not been loaded yet.
   */
  data: any[] | null;

  gridReadyObserver: Observer<any>;
  /**
   * Fires when ag-grid fires the grid ready event
   */
  gridReady: Observable<any>;

  /**
   * The Flex View entity
   */
  flexView: ListView;

  /**
   *
   */
  hasUserFilters: boolean;

  /**
   * The quick filter input used for the search bar in the upper right of the table.
   */
  quickFilterControl: UntypedFormControl;

  /**
   * The layout select dropdown.
   */
  layoutControl: UntypedFormControl;

  /**
   * The current user id
   */
  userId: number;

  /**
   * The layouts for the current Flex View.  Selected via the dropdown.
   */
  layouts: ListLayout[];

  /**
   * This observable fires when a new flex view is loaded so the tab title can be changed to the name of the view.
   */
  onTitleChange: Observable<string>;
  private titleChangeObservers: Observer<string>[] = [];

  /**
   * Authorized endpoints for the current user
   */
  authorized: endpointsAuthorized;

  /**
   * Options for each column set by the user
   *
   * groupBy: For dates, group by day of week, week, month or year instead of calendar day
   * backgroundColor: Sets the background color of the column.
   * negativeRed: If Y, negative numbers are displayed in red
   * pastDatesRed: If Y, dates earlier than today are displayed in red
   * displayUnit: For weight columns, converts to a different unit
   * displayCurrency: For amount columns, overrides the currency
   */
  columnOptions: { [key: string]: ColumnOption } = {};

  /**
   * A set of aggregate functions available for each column
   *
   * Built using the agg function builder
   */
  customAggFunctions: CustomAggFunction;
  /**
   * List of hotkey combinations to be unsubscribed from when this component is destroyed
   */
  localHotkeys: Hotkey[] = [];

  get editFlexViewAuthorized() {
    return this.entityLookupService.featureExists(MiscFeature.EDIT_FLEX_VIEW);
  }

  /**
   * The most recently used filter configuration for the Flex View.
   * This filter is reloaded by the state manager when the Flex View is navigated to with the close button in a form,
   * or when the page is reloaded.
   *
   * It contains information about the selected filter group, whether the filtering was done by the user, the applied filters,
   * and the filtered values for display purposes.
   */
  recentFlexFilter?: { selectedFilterGroup: ListFilterGroup | null | undefined; isUserFiltering: boolean; filters: ListViewFilter<any> | null | undefined; filteredValues: string } = {
    selectedFilterGroup: undefined,
    isUserFiltering: false,
    filters: null,
    filteredValues: '',
  };

  /**
   * The css class for the font awesome icon displayed in the header
   */
  FlexViewIcon = FlexViewIcon;

  /**
   * An object containing styles for excel export.  Passed by reference to exportToExcel and populated at time of export.
   */
  excelStyles: ExcelStyle[] = [];

  // Indicates if it is being filtered by the user
  isUserFiltering: boolean = false;

  // Values ​​by which the user is filtering
  selectedFilterName: string = '';

  constructor(
    private spinnerService: SpinnerService,
    private flexService: FlexService,
    private route: ActivatedRoute,
    private api: ThalosApiService,
    private dialogService: DialogService,
    private router: Router,
    private entityLookupService: EntityLookupService,
    private store: Store,
    private delegate: DelegateService,
    private layoutUtils: UtilsService,
    private dataFormatter: DataFormattingService,
    private hotKeys: HotkeysService
  ) {
    super();

    endpointAuthorizationSubscription(store, this);

    flexService.$emitter.subscribe(() => {
      this.getFlexViewData(false);
    });

    this.userId = this.store.snapshot((state) => state.user.user)?.id;

    this.gridOptions = {};
    this.data = null;
    this.layouts = [];

    this.gridReady = new Observable((observer) => {
      this.gridReadyObserver = observer;
    });

    this.onTitleChange = new Observable((o) => {
      this.titleChangeObservers.push(o);
    });

    this.quickFilterControl = new UntypedFormControl();
    this.quickFilterControl.valueChanges.pipe(untilDestroyed(this), debounceTime(250)).subscribe((val) => {
      if (this.gridOptions.api) {
        if (val !== '') this.gridOptions.api.deselectAll();
        this.gridOptions.api.setQuickFilter(val || '');
      }
    });

    this.layoutControl = new UntypedFormControl();
    //check for changes in te layout and apply, fill the columnoptions whit changes
    this.layoutControl.valueChanges.pipe(untilDestroyed(this)).subscribe((layout: ListLayout) => {
      if (!!layout) {
        const filters = JSON.parse(layout.layoutFilters);
        let raw: (ColumnState & { thalosFlexOptions?: ColumnOption })[] = JSON.parse(layout.columnState);
        this.layoutUtils.selectColor(null, null);
        this.gridOptions.groupDefaultExpanded = layout.groupsExpanded ?? 1;
        this.columnOptions = {};
        if (this.flexView) {
          for (let c of this.flexView.columns) {
            this.columnOptions[c.field] = {};
          }
        }
        let columnState: ColumnState[] = [];
        for (let r of raw) {
          if (!r.colId) continue;
          const flexColumn = this.flexView?.columns?.find((c) => c.field === r.colId);
          r.aggFunc = flexColumn?.typeConfiguration?.allowAggregation === YN.Y ? r.aggFunc : null;
          let options = r.thalosFlexOptions ?? {};
          this.columnOptions[r.colId] = options;
          if (options.backgroundColor) {
            this.layoutUtils.selectColor(r.colId, options.backgroundColor);
          }
          columnState.push(r);
        }
        this.layoutUtils.layoutApplyChanges(this.gridOptions, columnState, filters);
      }
    });

    //set color value for column when changed, but first check for value difference to avoid infinite callback loop
    this.layoutUtils.colorSelected.pipe(untilDestroyed(this)).subscribe((selection) => {
      if (!this.columnOptions[selection.columnId]) return;
      if (!selection.columnId || this.columnOptions[selection.columnId]?.backgroundColor === selection.color) return;
      this.columnOptions[selection.columnId].backgroundColor = selection.color;
      if (this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    });

    //setup hotkey shortcuts
    let refreshKey = new Hotkey(['ctrl+r', 'meta+r'], (ev, c) => {
      if (!this.flexView) return false;
      let dialog = document.querySelector('.k-dialog');
      let activeElement = document.activeElement as HTMLElement;
      if (!!dialog) return false;
      if (!!activeElement && activeElement != document.body) (document.activeElement as HTMLElement).blur();
      this.getFlexViewData(false);
      return false;
    });
    let selectAllKey = new Hotkey(['ctrl+a', 'meta+a'], (ev, c) => {
      if (!this.flexView || !this.gridOptions?.api) return false;
      if (this.gridOptions.api) {
        if (this.gridOptions.api.getSelectedNodes().length > 0) {
          this.gridOptions.api.deselectAll();
        } else {
          this.gridOptions.api.selectAllFiltered();
        }
      }

      return false;
    });
    this.hotKeys.add([refreshKey, selectAllKey]);
    this.localHotkeys.push(refreshKey, selectAllKey);
  }

  /**
   *
   * @returns The grid options for the primary ag-grid table, excluding the column definitions and context menu which are built dynamically from the Flex View entity
   */
  initializeGrid(): GridOptions {
    const listLayout = defaultListComplexGrid(this.delegate);
    return {
      ...defaultComplexGrid(this.delegate),
      ...listLayout,
      excelStyles: this.excelStyles,
      onRowDataChanged: this.onSelectionChanged(),
      onSelectionChanged: this.onSelectionChanged(),
      onColumnRowGroupChanged: this.onSelectionChanged(),
      onRowDoubleClicked: this.onRowDoubleClicked(),
      defaultColDef: {
        ...listLayout.defaultColDef,
        cellStyle: this.columnStyle(),
        autoHeaderHeight: true,
      },
      autoGroupColumnDef: {
        ...listLayout.autoGroupColumnDef,
        cellStyle: (params) => {
          let base = this.columnStyle();
          return { ...base(params), 'font-weight': 'bold' };
        },
      },
    };
  }

  /**
   * @description Angular Hook.  Fired after component is loaded and route data is available.  Use Flex View to perform setup
   */
  ngOnInit(): void {
    super.ngOnInit();
    let rid = this.spinnerService.startRequest('Loading Flex View');
    //subscribe to both grid-ready and route data
    //this event will fire if another flex view is loaded from the menu without reloading ther component
    combineLatest([
      this.gridReady,
      this.route.data.pipe(
        filter((data) => data.flex && data.flex.flexView),
        map((data) => data.flex)
      ),
    ]).subscribe(async ([ready, { flexView }]: [any, { flexView: ListView; data: any[] }]) => {
      this.spinnerService.completeRequest(rid);

      //Set the current Flex View, reset all options
      this.flexView = flexView;
      this.columnOptions = {};
      this.customAggFunctions = {};

      for (let c of flexView.columns) {
        this.columnOptions[c.field] = {};
        this.customAggFunctions[c.field] = {};
      }
      //When a flex view is loaded, change the title
      this.titleChangeObservers.forEach((o) => o.next(this.getTabTitle()));

      if (!flexView) return;
      this.gridOptions.api.setColumnDefs(flexView.columns.sort((a, b) => a.defaultOrder - b.defaultOrder).map(this.agGridColumnMapper(flexView, this.gridOptions.api)));

      this.gridOptions.defaultExcelExportParams.processCellCallback = ({ column, columnApi, api, value, node, context }) => {
        const flexColumn = this.flexView?.columns?.find((c) => c.field === column.getColId());
        if (flexColumn && flexColumn.type === ListColumnType.DATE) {
          return dateToExcelDate(value);
        }
        if (flexColumn && flexColumn.type === ListColumnType.ENUM) {
          const enumObject = flexColumn.typeConfiguration.enumValues;
          const matching = enumObject.find((l) => l.value == value);
          if (!!matching) return matching.label;
          return `${value}`;
        }
        return value;
      };
      this.gridOptions.defaultExcelExportParams.sheetName = flexView.name;
      this.gridOptions.defaultExcelExportParams.fileName = flexView.name;
      this.gridOptions.getMainMenuItems = getMainMenuItems(
        this.layoutUtils.getDeselectAllOption(),
        this.layoutUtils.expandToLevelOption(),
        this.layoutUtils.getColorPickerContextOption(this.columnOptions),
        this.setDateGroupOption(),
        {
          name: 'Formatting',
          menuItems: [this.setNegativeNumbersRedOption(), this.setPastDatesRedOption(), this.getDisplayUnitOption()],
        }
      );
      this.gridOptions.getContextMenuItems = getContextMenuItems(
        {
          replaceDefault: (params) => [
            'copy',
            'copyWithHeaders',
            copyCell()(params),
            'paste',
            'separator',
            'autoSizeAll',
            'contractAll',
            'resetColumns',
            'separator',
            this.layoutUtils.filterByValue(this.flexView, this.gridOptions)(params),
            'separator',
            {
              name: 'Export',
              subMenu: ['csvExport', this.layoutUtils.exportToExcel(this.flexView, this.columnOptions, this.excelStyles)(params)],
            },
          ],
        },
        this.layoutUtils.getDeselectAllOption(),
        this.layoutUtils.expandToLevelOption(),
        gotoMenu(...this.getGotoItems()),
        {
          name: 'Printing',
          menuItems: [...this.getPrintItems()],
        },
        {
          name: 'Forms',
          menuItems: [...this.getDynamicFormItems()],
        },
        {
          name: 'Column Options',
          menuItems: [
            this.layoutUtils.getColorPickerContextOption(this.columnOptions),
            this.setDateGroupOption(),
            {
              name: 'Formatting',
              menuItems: [this.setNegativeNumbersRedOption(), this.setPastDatesRedOption(), this.getDisplayUnitOption()],
            },
          ],
        }
      );

      if (!flexView.filterGroups || flexView.filterGroups.length === 0 || flexView.filterStrategy === FilterStrategy.NO_FILTER) {
        this.hasUserFilters = false;
      } else {
        let defaultFilter = flexView.filterGroups.find((fg) => fg.isDefault === YN.Y);
        if (
          flexView.filterStrategy === FilterStrategy.PROMPT_USER ||
          (!!defaultFilter && defaultFilter.rows && defaultFilter.rows.some((r) => r.type === ListFilterType.PROMPT_USER || r.type === ListFilterType.DYNAMIC_LIST))
        ) {
          this.hasUserFilters = true;
        } else {
          this.hasUserFilters = false;
        }
      }

      this.getFlexViewData(false);

      this.layouts = flexView.layouts.filter((l) => l.userId === this.userId || l.shared === YN.Y);
      if (this.layouts.length > 0) {
        let defaultLayout = this.layouts.find((l) => {
          return l.layoutDefaults && l.layoutDefaults.find((d) => d.userId === this.userId);
        });
        if (!defaultLayout) {
          defaultLayout = this.layouts.find((l) => l.default === YN.Y);
        }
        if (!defaultLayout) {
          defaultLayout = this.layouts[0];
        }
        defaultLayout.name = `${defaultLayout.name} (Default)`;
        this.gridOptions.groupDefaultExpanded = defaultLayout.groupsExpanded ?? 0;
        this.columnOptions = {};
        for (let c of flexView.columns) {
          this.columnOptions[c.field] = {};
        }
        let raw: (ColumnState & { thalosFlexOptions?: ColumnOption })[] = JSON.parse(defaultLayout.columnState);
        this.layoutUtils.selectColor(null, null);
        let columnState: ColumnState[] = [];
        for (let r of raw) {
          if (!r.colId) continue;
          const flexColumn = this.flexView?.columns?.find((c) => c.field === r.colId);
          r.aggFunc = flexColumn?.typeConfiguration?.allowAggregation === YN.Y ? r.aggFunc : null;
          let options = r.thalosFlexOptions ?? {};
          this.columnOptions[r.colId] = options;
          if (options.backgroundColor) {
            this.layoutUtils.selectColor(r.colId, options.backgroundColor);
          }
          columnState.push(r);
        }

        let rid = this.spinnerService.startRequest('Applying Layout');
        setTimeout(() => {
          if (!this.layoutControl.value || !this.layouts.some((l) => l.id === this.layoutControl.value.id)) {
            this.layoutControl.setValue(defaultLayout, { emitEvent: false, onlySelf: true });
          }
          if (!this.initialColumnState) {
            this.gridOptions.columnApi.applyColumnState({ state: columnState, applyOrder: true });
          } else {
            this.gridOptions.columnApi.applyColumnState({
              state: this.initialColumnState,
              applyOrder: true,
            });
          }
          // Sorting layouts alphabetically
          this.layouts.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.gridOptions.api.redrawRows();
          this.gridOptions.api.setFilterModel(JSON.parse(this.layoutControl.value.layoutFilters));
          this.spinnerService.completeRequest(rid);
        });
      }
    });
  }

  /**
   *
   * @param resetFilters If true, prompt user for filters.  Otherwise, use most recent filter, or prompt user if none exists.
   *
   * @description Builds flex filter query and fetches data using current Flex View.
   */
  async getFlexViewData(resetFilters: boolean = true): Promise<void> {
    //If flex view does not exist, we cannot fetch data
    if (!this.flexView) return;
    // If resetFilters is true or no recent filters exist, rebuild the filter query
    const getFlexFilter = resetFilters || this.recentFlexFilter?.filters === null ? await this.flexService.getFlexFilterQuery(this.flexView) : this.recentFlexFilter;
    // Handles the case where there are missing filters
    if (getFlexFilter.filters === null) {
      this.dialogService.open({
        content: 'Missing filters, click Set Filters to retrieve data',
        title: 'Error',
      });
      return null;
    }

    //Store filters as most recent
    this.recentFlexFilter = getFlexFilter;
    this.isUserFiltering = getFlexFilter.isUserFiltering;
    this.selectedFilterName = getFlexFilter.filteredValues;

    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' data');
    //fetch data
    const data = await firstValueFrom(
      this.api.rpc<ListResponse<any>>(endpoints.getFlexViewData, { id: this.flexView.id, filters: getFlexFilter.filters }, { list: [], count: 0 }, { blockRedirect: true }).pipe(
        map((res) => res.list),
        map((res) => {
          //for date columns, transform them all now so later operations are cheaper.
          const transformers: ((row: any) => Partial<any>)[] = [];
          for (const c of this.flexView.columns) {
            if (c.type === ListColumnType.DATE) transformers.push((row) => ({ [c.field]: dateGetter(row[c.field]) }));
          }
          return res.map((d) => {
            return { ...d, ...transformers.reduce((p, t) => ({ ...p, ...t(d) }), {}) };
          });
        })
      )
    );
    this.spinnerService.completeRequest(rid);
    //load data
    this.data = data;
  }

  /**
   * Turn off all the hotkey combinations
   */
  ngOnDestroy(): void {
    this.localHotkeys.forEach((hk) => {
      this.hotKeys.remove(hk);
    });
  }

  /**
   * Callback function for when ag-grid has been initialized.  Set global aggregate functions and fire grid ready event.
   */
  onGridReady(event) {
    this.gridOptions.api.addAggFunc('_sum', this.globalAggFunction('_sum'));
    this.gridOptions.api.addAggFunc('_avg', this.globalAggFunction('_avg'));
    this.gridOptions.api.addAggFunc('_max', this.globalAggFunction('_max'));
    this.gridOptions.api.addAggFunc('_min', this.globalAggFunction('_min'));
    this.gridReadyObserver.next(true);
  }

  /**
   * Creates a new layout preset based on changes the user has made to the ag-grid layout
   */
  clickSaveLayout(): void {
    this.layoutUtils.saveLayout(this.flexView, this.columnOptions, this.layoutControl, this.layouts, this.gridOptions, ViewType.flexView);
  }

  /**
   * Opens the current Flex View as an editable form if the user has permission to do so.
   */
  gotoFlexView() {
    if (!this.flexView || !this.editFlexViewAuthorized) return;

    let route = this.entityLookupService.getFeatureRoute(MiscFeature.EDIT_FLEX_VIEW)!.link;

    this.router.navigate([route, this.flexView.id]);
  }

  /**
   * Uses the current Flex View to create the Go To menu items for navigation.
   *
   * @returns An array of Menu Items
   */
  getGotoItems(): ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] {
    let items: ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] = [];

    for (let c of this.flexView?.columns || []) {
      if ((c.type === ListColumnType.ID || c.type === ListColumnType.COMPANY) && c.typeConfiguration?.entityType && this.entityLookupService.getEntityExists(c.typeConfiguration?.entityType)) {
        let entityType = c.typeConfiguration.entityType;
        let title = SourceEntityTypeEntityNameMap[entityType];

        let labelColumnId = c.typeConfiguration.entityLabelColumnField;
        let labelColumn = labelColumnId ? this.flexView?.columns.find((c) => c.id === labelColumnId) : null;

        let func = gotoMenuItem(this.delegate, title, c.field, entityType, 'get', labelColumn?.field ?? c.field);
        items.push(func);

        if (entityType === SourceEntityType.CHUNK_KEY && this.entityLookupService.entityPathExists('create', SourceEntityType.SERVICE_ORDER_KEY)) {
          let serviceOrderFunc = getServiceOrderMenuItem(this.delegate, c.name, c.field);
          items.push(serviceOrderFunc);
        }
      }
    }
    return items;
  }

  /**
   *
   * @returns Context Menu presets selected for each column in the Flex View
   */
  getDynamicFormItems(): ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] {
    let items: ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] = [];

    for (let c of this.flexView?.columns || []) {
      if (c.type === ListColumnType.ID && c.typeConfiguration?.entityType && c.typeConfiguration.dynamicForms?.length > 0) {
        let entityType = c.typeConfiguration.entityType;
        let labelColumnId = c.typeConfiguration.entityLabelColumnField;
        let labelColumn = labelColumnId ? this.flexView?.columns.find((c) => c.id === labelColumnId) : null;
        for (let df of c.typeConfiguration.dynamicForms) {
          let preset = DynamicFormPresets.find((dfp) => dfp.value === df);
          if (preset === undefined) continue;
          const typeMatch = preset.entityType === entityType || preset.entityType === null;
          const authorized = preset.endpoints === undefined || preset.endpoints.every((e) => this.authorized[e]);
          if (typeMatch && authorized) {
            items.push(
              dynamicFormItem(
                this.delegate,
                preset,
                c.field,
                labelColumn?.field,
                (res: any, params: GetContextMenuItemsParams) => {
                  if (!!res) {
                    this.getFlexViewData(false);
                  }
                },
                undefined,
                c
              )
            );
          }
        }
      }
    }
    return items;
  }

  getPrintItems(): ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] {
    let items: ((params: GetContextMenuItemsParams) => MenuItemDef | MenuItemDef[])[] = [];
    if (!this.flexView) return;
    for (let c of this.flexView?.columns || []) {
      if (c.type === ListColumnType.ID && c.typeConfiguration?.entityType && c.typeConfiguration?.entityType !== SourceEntityType.CHUNK_KEY) {
        let labelColumnId = c.typeConfiguration.entityLabelColumnField;
        let labelColumn = labelColumnId ? this.flexView?.columns.find((c) => c.id === labelColumnId) : null;
        const authorized = printDocumentPreset.endpoints.every((e) => this.authorized[e]);
        if (authorized) {
          items.push(
            dynamicFormItem(
              this.delegate,
              printDocumentPreset,
              c.field,
              labelColumn?.field,
              (res: any, params: GetContextMenuItemsParams) => {
                if (!!res) {
                  this.getFlexViewData(false);
                }
              },
              undefined,
              c
            )
          );
        }
      }
    }
    return items;
  }
  /**
   * As of 12/14/21, the only functionality tied to double clicking a row is navigating to an the entity associated with the primary ID column
   * set for the Flex View.  If no primary ID is designated, nothing happens.
   *
   * @returns callback function for a row being double clicked.
   */
  onRowDoubleClicked() {
    return (params: RowDoubleClickedEvent) => {
      if (!this.flexView) return;
      if (!params.node?.data) return;

      let primaryColumn: IDListColumn = this.flexView.columns.find((c) => c.type === ListColumnType.ID && c.typeConfiguration?.primaryId === YN.Y) as IDListColumn;
      if (!primaryColumn) return;

      if (!this.entityLookupService.entityPathExists('get', primaryColumn.typeConfiguration.entityType)) {
        return;
      }

      let id = params.node.data[primaryColumn.field];
      if (!id) return;

      let labelField = this.flexView.columns.find((c) => c.id === primaryColumn.typeConfiguration.entityLabelColumnField)?.field;
      let label = labelField && params.node.data[labelField];
      if (!label) label = `${id}`;

      let link = this.entityLookupService.getLink(primaryColumn.typeConfiguration.entityType, 'get') + '/' + params.node.data[primaryColumn.field];

      let data: any[] = [];
      let map: { [key: number]: true } = {};
      params.api.forEachNodeAfterFilterAndSort((n) => {
        let row = n.data;
        if (!row) return;
        let id = row[primaryColumn.field];
        if (!id || typeof id !== 'number') return;
        let label = labelField && row[labelField];
        if (!label) label = `${id}`;

        if (!map[id]) {
          map[id] = true;
          data.push({ id, label });
        }
      });

      if (params.event['metaKey'] || params.event['ctrlKey']) {
        newTabWithData(link, {
          url: this.router.url,
          state: {
            ...this._saveState(),
            dataLabelAccessor: 'label',
            dataSourceEntityType: primaryColumn.typeConfiguration,
            dataAccessor: 'id',
            data,
          },
        });
      } else {
        this.store.dispatch({
          type: UPDATE_ANCHOR_POINT,
          payload: {
            dataLabelAccessor: 'label',
            dataSourceEntityType: primaryColumn.typeConfiguration,
            dataAccessor: 'id',
            data,
          },
        });
        this.router.navigate([link]);
      }
    };
  }

  /**
   * Deletes the current layout if the user has permission to do so.
   */
  deleteLayout(): void {
    this.layoutUtils.deleteLayout(this.layoutControl, this.layouts, ViewType.flexView);
  }

  /**
   * Reload the current layout preset, undoing any unsaved changes the user has made, such as resizing columns and changing local filters.
   */
  reloadLayout() {
    this.layoutControl.setValue(this.layoutControl.value);
  }

  /**
   *
   * @returns The title of the current flex view
   */
  getTabTitle() {
    return this.flexView?.name ?? 'Flex';
  }

  _loadState(
    state: GridState & {
      layout?: ListLayout;
      flexFilter: { selectedFilterGroup: ListFilterGroup | undefined | null; isUserFiltering: boolean; filters: ListViewFilter<any>; filteredValues: string };
    }
  ) {
    this.quickFilterControl.setValue(state.quickFilter ?? '');
    this.initialColumnState = state.columns;

    this.recentFlexFilter = state.flexFilter;
    if (state.layout) {
      this.layoutControl.setValue(state.layout);
    }
    if (state.filterModel) {
      this.initialFilterModel = state.filterModel;
    }
  }

  _saveState(): GridState & {
    layout?: ListLayout;
    flexFilter: { selectedFilterGroup: ListFilterGroup | undefined | null; isUserFiltering: boolean; filters: ListViewFilter<any>; filteredValues: string };
  } {
    return {
      ...super._saveState(),
      layout: this.layoutControl.value,
      quickFilter: this.quickFilterControl.value,
      flexFilter: this.recentFlexFilter,
    };
  }

  /**
   * Clears local filters, quick filter, and column size/order of the grid.
   */
  resetFilters() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setFilterModel({});
      this.quickFilterText = '';
      this.quickFilter(this.quickFilterText);
    }
  }

  /**
   * The menu item allows the user to change the group behavior for dates.
   *  BY_DATE - Default, calendar day.
   *  BY_WEEK - The week of the year, 1-52
   *  BY_MONTH - Month of the year, 0-11
   *  BY_YEAR - The full year
   *  BY_QUARTER - The quarter of the year, 1-4
   *
   * @returns The callback function for the date group dropdown
   */
  setDateGroupOption() {
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef => {
      let colId = params?.column?.getColId();
      if (!colId) return;
      let col = this.flexView.columns.find((c) => c.field === colId);
      if (!col || col.type !== ListColumnType.DATE) return;
      let startingType = this.columnOptions[colId]?.groupBy ?? ListDateGroupBehavior.BY_DATE;

      return {
        name: 'Select Date Group Type',
        icon: `<span class="ag-icon ag-icon-calendar" unselectable="on" role="presentation"></span>`,
        subMenu: ListDateGroupBehaviors.map((d) => {
          return {
            name: d.label,
            checked: startingType === d.value,
            action: () => {
              if (this.columnOptions[colId]) {
                this.columnOptions[colId].groupBy = d.value;

                this.gridOptions.api.refreshClientSideRowModel();
              }
            },
          };
        }),
      };
    };
  }

  /**
   * The menu item allows the user to make any negative numbers in the column display in red.
   *
   * @returns The callback function for red negative numbers
   */
  setNegativeNumbersRedOption() {
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef => {
      let colId = params?.column?.getColId();
      if (!colId) return;
      let col = this.flexView.columns.find((c) => c.field === colId);
      if (
        !col ||
        ![ListColumnType.NUMBER, ListColumnType.AMOUNT, ListColumnType.DEBIT_CREDIT, ListColumnType.NUMBER, ListColumnType.WEIGHT, ListColumnType.AMOUNT_UNIT, ListColumnType.PRICE_UNIT].includes(
          col.type
        )
      )
        return;
      let startingValue = this.columnOptions[colId]?.negativeRed ?? YN.N;

      return {
        name: 'Mark negative numbers in red',
        cssClasses: ['c-red'],
        checked: startingValue === YN.Y,
        action: () => {
          if (this.columnOptions[colId]) {
            this.columnOptions[colId].negativeRed = startingValue === YN.Y ? YN.N : YN.Y;

            let rid = this.spinnerService.startRequest('Redrawing Rows');
            setTimeout(() => {
              this.gridOptions.api.redrawRows();
              this.spinnerService.completeRequest(rid);
            });
          }
        },
      };
    };
  }

  /**
   * The menu item allows the user to make any past dates in the column display in red.  As an example, for expired due dates to be clearly distinguished.
   *
   * @returns The callback function for red past dates
   */
  setPastDatesRedOption() {
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef => {
      let colId = params?.column?.getColId();
      if (!colId) return;
      let col = this.flexView.columns.find((c) => c.field === colId);
      if (!col || ![ListColumnType.DATE].includes(col.type)) return;
      let startingValue = this.columnOptions[colId]?.pastDatesRed ?? YN.N;

      return {
        name: 'Mark past dates in red',
        cssClasses: ['c-red'],
        checked: startingValue === YN.Y,
        action: () => {
          if (this.columnOptions[colId]) {
            this.columnOptions[colId].pastDatesRed = startingValue === YN.Y ? YN.N : YN.Y;

            params.api.redrawRows();
          }
        },
      };
    };
  }

  /**
   * A menu item that allows the user to designate an alternate Unit to display a column's amount in.
   * The amounts will be converted to the selected unit.  Only allowed on ListColumnType.WEIGHT columns.
   *
   * @returns The callback function for the display unit menu item
   */
  getDisplayUnitOption() {
    const prompt = this.delegate.getService('prompt');
    const commonData = this.delegate.getService('commonData');
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef | MenuItemDef[] => {
      let colId = params?.column?.getColId();
      if (!colId) return [];
      let col = this.flexView.columns.find((c) => c.field === colId);
      if (!col || ![ListColumnType.WEIGHT].includes(col.type)) return [];

      let startingUnit = this.columnOptions[colId]?.displayUnit;

      return {
        name: startingUnit ? `Display as Unit - ${startingUnit?.code}` : `Display as Unit`,
        action: () => {
          prompt
            .simpleDropdownPrompt(
              'Select Unit',
              'Unit',
              commonData.staticFilteredUnits.value.map((u) => u),
              'code',
              'unitId',
              startingUnit
            )
            .subscribe((res) => {
              if (res !== 'Close') {
                if (!this.columnOptions[colId]) {
                  this.columnOptions[colId] = {};
                }

                this.columnOptions[colId].displayUnit = res;

                let rid = this.spinnerService.startRequest('Redrawing Rows');
                setTimeout(() => {
                  this.gridOptions.api.refreshClientSideRowModel('aggregate');
                  this.gridOptions.api.redrawRows();
                  this.spinnerService.completeRequest(rid);
                });
              }
            });
        },
      };
    };
  }

  /**
   *
   * @param flexView The current flex view
   * @param api the grid api
   * @returns A map function to transform a flex view column into an ag-grid column definition
   */
  agGridColumnMapper(flexView: ListView, api: GridApi) {
    api.addAggFunc('unique', uniqueValuesAggregator());
    let columns = flexView.columns;
    let today = getTodayUTC();
    return (flexColumn: ListColumn, index: number): ColDef => {
      let baseCellStyle = this.columnStyle();
      if (flexColumn.visibility === ListColumnVisibility.ALWAYS_HIDE) return { hide: true, suppressColumnsToolPanel: true };

      //DEFAULT
      const def: ColDef = {};

      def.headerName = flexColumn.name;
      def.resizable = true;
      def.field = flexColumn.field;
      def.colId = flexColumn.field;
      def.width = flexColumn.defaultWidth;
      def.enableRowGroup = flexColumn.allowGrouping === YN.Y;
      if (flexColumn.allowGrouping === YN.Y) {
        def.valueGetter = (params: ValueGetterParams) => params.data?.[flexColumn.field] ?? '';
      }
      def.enableValue = flexColumn.typeConfiguration.allowAggregation === YN.Y;
      def.hide = flexColumn.visibility === ListColumnVisibility.HIDE;
      def.suppressColumnsToolPanel = flexColumn.visibility === ListColumnVisibility.ALWAYS_SHOW;
      def.filterParams = { newRowsAction: 'keep' };

      def.cellClass = `excel-export-${def.colId}`;

      let cellClassRules: CellClassRules = {};

      def.menuTabs =
        (flexColumn.menuConfiguration.menus || [])
          .sort((a, b) => {
            if (a === ListColumnMenu.FILTER) return -1;
            if (a === ListColumnMenu.COLUMN) return 1;
            return 0;
          })
          .map((m) => agGridColumnMenuMap[m] as ColumnMenuTab) || [];

      switch (flexColumn.type) {
        case ListColumnType.TEXT:
          def.filter = 'agTextColumnFilter';
          def.allowedAggFuncs = ['first', 'last', 'count', 'unique'];
          break;
        case ListColumnType.NUMBER:
          def.filter = 'agNumberColumnFilter';
          def.cellStyle = (params) => {
            let negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            let color = negativeRed === YN.Y && params?.value < 0 ? 'red' : undefined;

            let base = baseCellStyle(params);
            return { color, ...base };
          };
          if (flexColumn.typeConfiguration.percentage === YN.Y) {
            cellClassRules['text-align-right'] = () => true;
            def.valueFormatter = percentFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
          } else {
            def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
          }
          def.allowedAggFuncs = ['sum', 'avg', 'max', 'min', 'first', 'last', 'count', 'unique'];
          break;
        case ListColumnType.DATE:
          def.cellStyle = (params) => {
            let value: Date | null = params?.value;
            let color: string;
            if (!!value && !!value?.getTime && !isNaN(value?.getTime())) {
              let datesRed = this.columnOptions[flexColumn.field]?.pastDatesRed ?? YN.N;
              color = datesRed === YN.Y && value < today ? 'red' : undefined;
            }
            let base = baseCellStyle(params);
            return { color, ...base };
          };
          def.valueFormatter = (params: ValueFormatterParams) => {
            if (params.node.group && params.value?.toString() && params.value?.toString()?.length < 10) {
              return params.value;
            }
            return dateFormatter(params.value);
          };
          //set filter format on dates
          def.filterValueGetter = gridDateGetter(flexColumn.field);
          def.filter = 'agDateColumnFilter';
          def.keyCreator = (v) => {
            let groupBy = this.columnOptions[flexColumn.field]?.groupBy ?? ListDateGroupBehavior.BY_DATE;
            let d: Date | null = v.value;
            if (!d) return '';
            let str: string;
            switch (groupBy) {
              case ListDateGroupBehavior.BY_YEAR:
                str = `${moment(d).format('YYYY')}`;
                break;
              case ListDateGroupBehavior.BY_MONTH:
                str = `${moment(d).utc().format('MMM YYYY')}`;
                break;
              case ListDateGroupBehavior.BY_WEEK:
                str = `${moment(d).format('YYYY [W]ww')}`;
                break;
              case ListDateGroupBehavior.BY_QUARTER:
                str = `${moment(d).format('YYYY [Q]Q')}`;
                break;
              default:
                str = dateFormatter(d);
            }
            return str;
          };
          def.comparator = dateGridComparator();
          def.filterParams = {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = cellValue;
              if (dateAsString == null) return -1;
              var dateParts = dateAsString.split('/');
              var cellDate = new Date(`${dateParts[2]}/${dateParts[0]}/${dateParts[1]}`);
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
          };
          def.allowedAggFuncs = ['first', 'last', 'count', 'max', 'min'];
          break;
        case ListColumnType.ENUM:
          def.valueFormatter = enumLabelFormatter(flexColumn.typeConfiguration.enumValues);
          def.filterValueGetter = enumValueGetter(flexColumn.field, flexColumn.typeConfiguration.enumValues);
          def.allowedAggFuncs = ['first', 'last', 'count'];
          def.filter = 'agSetColumnFilter';
          break;
        case ListColumnType.WEIGHT:
          def.filter = 'agNumberColumnFilter';
          cellClassRules['text-align-right'] = () => true;
          def.cellStyle = (params) => {
            const negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            const value = params?.node?.footer ? params?.value?.amount : params?.node?.group ? params?.node?.aggData?.[flexColumn.field]?.amount : params?.value;
            const color = negativeRed === YN.Y && value < 0 ? 'red' : undefined;
            const base = baseCellStyle(params);
            return { ...base, color };
          };
          def.allowedAggFuncs = [];
          if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
            if (!!flexColumn.typeConfiguration.staticUnit) {
              this.registerAggFunction(
                flexColumn.field,
                '_sum',
                this.layoutUtils.aggFunctionBuilder(
                  staticMapper({ unit: flexColumn.typeConfiguration.staticUnit }),
                  sumAggregate(),
                  this.layoutUtils.staticUnitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_avg',
                this.layoutUtils.aggFunctionBuilder(
                  staticMapper({ unit: flexColumn.typeConfiguration.staticUnit }),
                  avgAggregate(),
                  this.layoutUtils.staticUnitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_max',
                this.layoutUtils.aggFunctionBuilder(
                  staticMapper({ unit: flexColumn.typeConfiguration.staticUnit }),
                  maxAggregate(),
                  this.layoutUtils.staticUnitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_min',
                this.layoutUtils.aggFunctionBuilder(
                  staticMapper({ unit: flexColumn.typeConfiguration.staticUnit }),
                  minAggregate(),
                  this.layoutUtils.staticUnitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              def.allowedAggFuncs = ['_sum', '_avg', '_max', '_min'];
              def.valueFormatter = this.layoutUtils.unitFormatter(
                { unit: flexColumn.typeConfiguration.staticUnit },
                flexColumn.typeConfiguration.decimals,
                this.columnOptions,
                flexColumn.typeConfiguration.useGrouping
              );

              const unit = this.layoutUtils.unitFromTag(flexColumn.typeConfiguration.staticUnit);
              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unit, null),
              };
            }
          } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
            let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
            let unitColumn = columns.find((c) => c.id === unitColumnField);
            if (unitColumn) {
              this.registerAggFunction(
                flexColumn.field,
                '_sum',
                this.layoutUtils.aggFunctionBuilder(
                  this.layoutUtils.unitColumnAggMapper(flexColumn.field, unitColumn.field),
                  sumAggregate(),
                  this.layoutUtils.unitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_avg',
                this.layoutUtils.aggFunctionBuilder(
                  this.layoutUtils.unitColumnAggMapper(flexColumn.field, unitColumn.field),
                  avgAggregate(),
                  this.layoutUtils.unitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_max',
                this.layoutUtils.aggFunctionBuilder(
                  this.layoutUtils.unitColumnAggMapper(flexColumn.field, unitColumn.field),
                  maxAggregate(),
                  this.layoutUtils.unitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              this.registerAggFunction(
                flexColumn.field,
                '_min',
                this.layoutUtils.aggFunctionBuilder(
                  this.layoutUtils.unitColumnAggMapper(flexColumn.field, unitColumn.field),
                  minAggregate(),
                  this.layoutUtils.unitAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions)
                )
              );
              def.allowedAggFuncs = ['_sum', '_avg', '_max', '_min'];
              def.valueFormatter = this.layoutUtils.unitFormatter({ unitField: unitColumn.field }, flexColumn.typeConfiguration.decimals, this.columnOptions, flexColumn.typeConfiguration.useGrouping);

              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumnField, null),
              };
            }
          } else {
            def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
            def.allowedAggFuncs = ['sum', 'max', 'min', 'avg'];
          }
          break;
        case ListColumnType.AMOUNT:
          def.filter = 'agNumberColumnFilter';
          cellClassRules['text-align-right'] = () => true;
          def.cellStyle = (params) => {
            let negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            let color = negativeRed === YN.Y && params?.value < 0 ? 'red' : undefined;
            let base = baseCellStyle(params);
            return { color, ...base };
          };
          def.allowedAggFuncs = [];
          if ('staticCurrency' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticCurrency) {
            const staticCurrency = this.layoutUtils.currencyFromTag(flexColumn.typeConfiguration.staticCurrency);
            if (flexColumn.type === ListColumnType.AMOUNT) {
              def.valueFormatter = this.layoutUtils.gridStaticCurrencyFormatter(staticCurrency, flexColumn.typeConfiguration.decimals ?? 2, flexColumn.typeConfiguration.useGrouping);
            } else {
              def.cellRendererParams = { currency: staticCurrency.code };
            }
            const aggFormatter = this.layoutUtils.currencyAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions);
            this.registerAggFunction(flexColumn.field, '_sum', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: staticCurrency.code }), sumAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_avg', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: staticCurrency.code }), avgAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_max', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: staticCurrency.code }), maxAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_min', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: staticCurrency.code }), minAggregate(), aggFormatter));
            def.allowedAggFuncs = [`_sum`, `_max`, `_min`, `_avg`];

            cellClassRules = {
              ...cellClassRules,
              ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, staticCurrency),
            };
          } else if ('currencyColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.currencyColumnField) {
            let currencyColumnField = flexColumn.typeConfiguration.currencyColumnField;
            let currencyColumn = columns.find((c) => c.id === currencyColumnField);
            if (currencyColumn) {
              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, currencyColumn.field),
              };
              def.valueFormatter = this.layoutUtils.gridCurrencyFormatter(currencyColumn.field, flexColumn.typeConfiguration.decimals ?? 2, flexColumn.typeConfiguration.useGrouping);
            }
            const aggFormatter = this.layoutUtils.currencyAggFormatter(flexColumn.typeConfiguration.decimals, this.columnOptions);
            this.registerAggFunction(flexColumn.field, '_sum', this.layoutUtils.aggFunctionBuilder(staticMapper({}), sumAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_avg', this.layoutUtils.aggFunctionBuilder(staticMapper({}), avgAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_max', this.layoutUtils.aggFunctionBuilder(staticMapper({}), maxAggregate(), aggFormatter));
            this.registerAggFunction(flexColumn.field, '_min', this.layoutUtils.aggFunctionBuilder(staticMapper({}), minAggregate(), aggFormatter));
            def.allowedAggFuncs = [`_sum`, `_max`, `_min`, `_avg`];
          } else {
            def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
            def.allowedAggFuncs = ['sum', 'max', 'min', 'avg'];
          }
          break;
        case ListColumnType.DEBIT_CREDIT:
          def.cellRenderer = DebitCreditRenderer;

          def.filter = 'agNumberColumnFilter';
          def.cellStyle = (params) => {
            let negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            let color = negativeRed === YN.Y && params?.value < 0 ? 'red' : undefined;
            let base = baseCellStyle(params);
            return { color, ...base };
          };
          def.allowedAggFuncs = [];
          if ('staticCurrency' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticCurrency) {
            const staticCurrency = this.layoutUtils.currencyFromTag(flexColumn.typeConfiguration.staticCurrency);
            def.cellRendererParams = { currency: staticCurrency.code };
            this.registerAggFunction(flexColumn.field, '_sum', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: flexColumn.typeConfiguration.staticCurrency }), sumAggregate()));
            this.registerAggFunction(flexColumn.field, '_avg', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: flexColumn.typeConfiguration.staticCurrency }), avgAggregate()));
            this.registerAggFunction(flexColumn.field, '_max', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: flexColumn.typeConfiguration.staticCurrency }), maxAggregate()));
            this.registerAggFunction(flexColumn.field, '_min', this.layoutUtils.aggFunctionBuilder(staticMapper({ currency: flexColumn.typeConfiguration.staticCurrency }), minAggregate()));
            def.allowedAggFuncs = [`_sum`, `_max`, `_min`, `_avg`];

            cellClassRules = {
              ...cellClassRules,
              ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, staticCurrency),
            };
          } else if ('currencyColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.currencyColumnField) {
            let currencyColumnField = flexColumn.typeConfiguration.currencyColumnField;
            let currencyColumn = columns.find((c) => c.id === currencyColumnField);
            if (currencyColumn) {
              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, currencyColumn.field),
              };
              def.cellRendererParams = { currencyField: currencyColumn.field };
            }
            this.registerAggFunction(flexColumn.field, '_sum', this.layoutUtils.aggFunctionBuilder(staticMapper({}), sumAggregate()));
            this.registerAggFunction(flexColumn.field, '_avg', this.layoutUtils.aggFunctionBuilder(staticMapper({}), avgAggregate()));
            this.registerAggFunction(flexColumn.field, '_max', this.layoutUtils.aggFunctionBuilder(staticMapper({}), maxAggregate()));
            this.registerAggFunction(flexColumn.field, '_min', this.layoutUtils.aggFunctionBuilder(staticMapper({}), minAggregate()));
            def.allowedAggFuncs = [`_sum`, `_max`, `_min`, `_avg`];
          } else {
            def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
            def.allowedAggFuncs = ['sum', 'max', 'min', 'avg'];
          }
          break;
        case ListColumnType.AMOUNT_UNIT:
          def.filter = 'agNumberColumnFilter';
          cellClassRules['text-align-right'] = () => true;
          def.cellStyle = (params) => {
            let negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            let color = negativeRed === YN.Y && params?.value < 0 ? 'red' : undefined;
            let base = baseCellStyle(params);
            return { color, ...base };
          };
          def.allowedAggFuncs = [];
          def.enableValue = false;
          if ('staticCurrency' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticCurrency) {
            const staticCurrency = this.dataFormatter.currencyFromTag(flexColumn.typeConfiguration.staticCurrency);
            if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
              //static unit + static currency
              const staticUnit = this.dataFormatter.unitFromTag(flexColumn.typeConfiguration.staticUnit);
              def.valueFormatter = this.dataFormatter.gridStaticAmountCurrencyPerUnitFormatter(
                staticCurrency,
                staticUnit,
                flexColumn.typeConfiguration.decimals,
                flexColumn.typeConfiguration.useGrouping
              );
              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, staticUnit, staticCurrency),
              };
              //
            } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              //dynamic unit + static currency
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (unitColumn) {
                def.valueFormatter = this.dataFormatter.gridAmountStaticCurrencyPerUnitFormatter(
                  unitColumn.field,
                  flexColumn.typeConfiguration.staticCurrency,
                  flexColumn.typeConfiguration.decimals,
                  flexColumn.typeConfiguration.useGrouping
                );

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, staticCurrency),
                };
              }
              //
            } else {
              //no unit + static currency
              def.valueFormatter = this.dataFormatter.gridStaticCurrencyFormatter(staticCurrency, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, staticCurrency),
              };
              //
            }
          } else if ('currencyColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.currencyColumnField) {
            let currencyColumnField = flexColumn.typeConfiguration.currencyColumnField;
            let currencyColumn = columns.find((c) => c.id === currencyColumnField);

            if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
              if (currencyColumn) {
                const staticUnit = this.dataFormatter.unitFromTag(flexColumn.typeConfiguration.staticUnit);

                def.valueFormatter = this.dataFormatter.gridAmountCurrencyPerStaticUnitFormatter(
                  staticUnit,
                  currencyColumn.field,
                  flexColumn.typeConfiguration.decimals,
                  flexColumn.typeConfiguration.useGrouping
                );

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, staticUnit, currencyColumn.field),
                };
              }
            } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (currencyColumn && unitColumn) {
                def.valueFormatter = this.dataFormatter.gridAmountCurrencyPerUnitFormatter(
                  unitColumn.field,
                  currencyColumn.field,
                  flexColumn.typeConfiguration.decimals,
                  flexColumn.typeConfiguration.useGrouping
                );

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, currencyColumn.field),
                };
              }
            } else {
              if (currencyColumn) {
                def.valueFormatter = this.dataFormatter.gridCurrencyFormatter(currencyColumn.field, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, currencyColumn.field),
                };
              }
            }
          } else {
            if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
              const staticUnit = this.dataFormatter.unitFromTag(flexColumn.typeConfiguration.staticUnit);
              def.valueFormatter = this.dataFormatter.gridStaticUnitFormatter(staticUnit, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, staticUnit, null),
              };
            } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (unitColumn) {
                def.valueFormatter = this.dataFormatter.gridUnitFormatter(unitColumn.field, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, null),
                };
              }
            } else {
              def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
            }
          }
          break;
        case ListColumnType.PRICE_UNIT:
          def.filter = 'agNumberColumnFilter';
          cellClassRules['text-align-right'] = () => true;
          def.cellStyle = (params) => {
            let negativeRed = this.columnOptions[flexColumn.field]?.negativeRed ?? YN.N;
            let color = negativeRed === YN.Y && params?.value < 0 ? 'red' : undefined;
            let base = baseCellStyle(params);
            return { color, ...base };
          };
          def.allowedAggFuncs = [];
          def.enableValue = false;
          if ('staticCurrency' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticCurrency) {
            const staticCurrency = this.dataFormatter.currencyFromTag(flexColumn.typeConfiguration.staticCurrency);
            if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
              //static unit + static currency
              const staticUnit = this.dataFormatter.unitFromTag(flexColumn.typeConfiguration.staticUnit);
              def.valueFormatter = this.dataFormatter.gridStaticPriceCurrencyPerUnitFormatter(
                staticCurrency,
                staticUnit,
                flexColumn.typeConfiguration.decimals,
                flexColumn.typeConfiguration.useGrouping
              );
              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, staticUnit, staticCurrency),
              };
              //
            } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              //dynamic unit + static currency
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (unitColumn) {
                def.valueFormatter = this.dataFormatter.gridStaticPriceCurrencyPerUnitFormatter(
                  flexColumn.typeConfiguration.staticCurrency,
                  unitColumn.field,
                  flexColumn.typeConfiguration.decimals,
                  flexColumn.typeConfiguration.useGrouping
                );

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, staticCurrency),
                };
              }
              //
            } else {
              //no unit + static currency
              def.valueFormatter = this.dataFormatter.gridStaticCurrencyFormatter(staticCurrency, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, staticCurrency),
              };
              //
            }
          } else if ('currencyColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.currencyColumnField) {
            let currencyColumnField = flexColumn.typeConfiguration.currencyColumnField;
            let currencyColumn = columns.find((c) => c.id === currencyColumnField);

            if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (currencyColumn && unitColumn) {
                def.valueFormatter = this.dataFormatter.gridPriceCurrencyPerUnitFormatter(
                  unitColumn.field,
                  currencyColumn.field,
                  flexColumn.typeConfiguration.decimals,
                  flexColumn.typeConfiguration.useGrouping
                );

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, currencyColumn.field),
                };
              }
            } else {
              if (currencyColumn) {
                def.valueFormatter = this.dataFormatter.gridCurrencyFormatter(currencyColumn.field, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, null, currencyColumn.field),
                };
              }
            }
          } else {
            if ('staticUnit' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.staticUnit) {
              const staticUnit = this.dataFormatter.unitFromTag(flexColumn.typeConfiguration.staticUnit);
              def.valueFormatter = this.dataFormatter.gridStaticUnitFormatter(staticUnit, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);

              cellClassRules = {
                ...cellClassRules,
                ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, staticUnit, null),
              };
            } else if ('unitColumnField' in flexColumn.typeConfiguration && !!flexColumn.typeConfiguration.unitColumnField) {
              let unitColumnField = flexColumn.typeConfiguration.unitColumnField;
              let unitColumn = columns.find((c) => c.id === unitColumnField);
              if (unitColumn) {
                def.valueFormatter = this.dataFormatter.gridUnitFormatter(unitColumn.field, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
                cellClassRules = {
                  ...cellClassRules,
                  ...this.layoutUtils.assignExcelStylesToColumn(flexColumn, unitColumn.field, null),
                };
              }
            } else {
              def.valueFormatter = basicNumberFormatter(flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.decimals, flexColumn.typeConfiguration.useGrouping);
            }
          }
          break;
        case ListColumnType.ID: {
          def.filter = 'agNumberColumnFilter';
          def.allowedAggFuncs = ['max', 'min', 'first', 'last', 'count', 'unique'];
          break;
        }
      }

      def.cellClassRules = cellClassRules;

      return def;
    };
  }

  /**
   *
   * @returns The callback function that handles selection changes in ag-grid
   *
   * @description When rows are selected, the aggregate functions for that column are fired manually.  The data is then pinned
   * to the bottom row, so the total of the selected rows is available.
   */
  onSelectionChanged() {
    return (params: SelectionChangedEvent | RowDataUpdatedEvent) => {
      const selected = params.api.getSelectedNodes().filter((n) => !n.group);
      if (selected.length) {
        let pinnedRow: any = { 'ag-Grid-AutoColumn': 'Selected Totals' };
        const columns = params.columnApi.getColumns();
        for (let c of columns) {
          if (!c.isAllowValue()) continue;
          let agg = c.getAggFunc() ?? '_sum';
          let field = c.getColDef().colId;
          if (typeof agg === 'string' && this.customAggFunctions[field][agg]) {
            const values = selected.map((n) => n.data?.[field]);
            pinnedRow[c.getColId()] = this.customAggFunctions[field][agg]({
              ...params,
              rowNode: { childrenAfterFilter: selected, group: false }, //pretend to be parent node
              colDef: params.api.getColumnDef(field),
              column: params.columnApi.getColumn(field),
              context: null,
              data: null,
              values,
            });
          } else if (c.getColDef()?.allowedAggFuncs?.includes('sum')) {
            pinnedRow[c.getColId()] = sumAggregate()(
              selected.map((n) => n.data?.[field]),
              {}
            );
          }
        }
        params.api.setPinnedBottomRowData([pinnedRow]);
      } else params.api.setPinnedBottomRowData([]);
    };
  }

  /**
   * Register a function to be applied to a column with @function globalAggFunction
   *
   * @param colId The column id, (FlexColumn.field)
   * @param type Type of the agg function
   * @param func function to bind
   */
  registerAggFunction(colId: string, type: aggType, func: (params: IAggFuncParams) => any) {
    if (!this.customAggFunctions) this.customAggFunctions = {};
    if (!this.customAggFunctions[colId]) this.customAggFunctions[colId] = {};
    this.customAggFunctions[colId][type] = func;
  }

  /**
   *
   *
   * @param type Type of agg function
   *
   * @returns An ag-grid aggregate function that returns a custom user defined function
   * This is done to create column specific aggregate function using the same name
   */
  globalAggFunction(type: aggType) {
    return (params: IAggFuncParams) => {
      let colId = params.column?.getColId();
      if (colId && this.customAggFunctions[colId]?.[type]) {
        let func = this.customAggFunctions[colId]?.[type];
        return func(params);
      }
      return null;
    };
  }

  /**
   * The base style options for the column.
   *
   * @todo replace this with a more strongly typed alternative.
   *
   * @returns The column style callback function
   */
  columnStyle() {
    return (params) => {
      //get custom color value
      if (params?.colDef?.colId) {
        let colorValue = this.columnOptions[params.colDef.colId]?.backgroundColor;
        if (colorValue) {
          let rgba = hexToRGB(colorValue, 0.5);
          return { 'background-color': rgba, 'font-weight': 'bold', cursor: 'pointer' };
        }
      }
      if (params?.node?.rowPinned === 'bottom') {
        return { 'font-weight': 'bold', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    };
  }
}

export function hexToRGB(hex, alpha) {
  try {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  } catch (e) {
    return 'rgba(255,255,255,1)';
  }
}

export function dateToExcelDate(dateInput: string | Date | number) {
  let date: Date;

  if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else if (typeof dateInput === 'number') {
    date = fromBradyDateOrNull(dateInput);
  } else {
    date = dateInput;
  }

  if (!date || isNaN(date?.getTime())) {
    return null;
  }
  const returnDateTime = 25569.0 + date.getTime() / (1000 * 60 * 60 * 24);
  return returnDateTime.toString().substr(0, 5);
}
