import { Injectable } from '@angular/core';
import { NotificationRef, NotificationService as KendoNotificationService, NotificationSettings } from '@progress/kendo-angular-notification';

@Injectable()
export class NotificationService {
  constructor(private notificationService: KendoNotificationService) {}

  public show(content: string): NotificationRef;
  public show(content: string, type: 'success' | 'info' | 'error' | 'warning' | 'none'): NotificationRef;
  public show(content: NotificationSettings): NotificationRef;
  public show(content: string | NotificationSettings, type: 'success' | 'info' | 'error' | 'warning' | 'none' = 'success'): NotificationRef {
    if (typeof content === 'string') {
      content = {
        content,
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: type, icon: true },
        hideAfter: 3000,
      };
    }

    return this.notificationService.show(content);
  }
}

export enum ToastStyle {
  NONE = 'none',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}
