import { from, map, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const reconcilePaymentPrefillCallback: prefillCallback<PaymentHeader> = (delegate, id) => {
  let api = delegate.getService('api');
  return api.rpc<PaymentHeader>(endpoints.getPayment, { filters: { id } }, null);
};

const reconcilePaymentCheck: checkPrefillCallback<PaymentHeader> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to Reconcile/Un-reconcile: payment not found');
  return of(true);
};

const reconcilePaymentForm: openFormCallback<PaymentHeader, YN> = (delegate, id, prefill) => {
  const prompt = delegate.getService('prompt');
  const text = `Payment number: ${prefill ? prefill.documentReference : 'Unknown'}, ${prefill.reconciled === YN.N ? 'will be Reconcile' : 'will be Un-reconcile'}. Continue?`;
  return prompt.simpleConfirmation(`Update Payment ${prefill ? prefill.documentReference : 'Unknown'}`, text).pipe(map((res) => (res ? prefill.reconciled : 'Close')));
};

const reconcilePaymentSubmit: submitFormCallback<PaymentHeader, YN> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader>((resolve, reject) => {
      (async () => {
        const request: ReconcilePaymentRequest = {
          id: id,
          reconciled: result === YN.Y ? YN.N : YN.Y,
        };
        const updatePayment = await api.run<PaymentHeader>(endpoints.reconcilePayment, request, null);
        if (updatePayment) return resolve(updatePayment);
        return reject('Unknown result. Please check if the Payment was Reconciled/Un-reconciled updated and try again if necessary.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Payment ${res.id} successfully ${res.reconciled === YN.Y ? 'Reconciled' : 'Un-reconciled'}.</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const reconcilePaymentPreset: DynamicFormConstant<PaymentHeader, YN> = {
  value: DynamicFormType.RECONCILE_PAYMENT,
  label: 'Reconcile/Un-reconcile Payment',
  title: 'Reconcile/Un-reconcile Payment',
  endpoints: [endpoints.getPayment, endpoints.reconcilePayment],
  entityType: SourceEntityType.PAYMENT_KEY,
  getPrefill: reconcilePaymentPrefillCallback,
  openForm: reconcilePaymentForm,
  checkPrefill: reconcilePaymentCheck,
  submitForm: reconcilePaymentSubmit,
  allowMultipleRows: false,
  width: 400,
};

export type ReconcilePaymentRequest = Pick<PaymentHeader, 'id' | 'reconciled'>;
