import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { NumerictextboxWrapperComponent } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { counterpartyDropdown, paymentTermDropdown } from 'src/lib/commonTypes';
import { ApplyItemsToVoucherPrefill, VoucherItemsForm, VoucherPaymentsCreditForm } from 'src/lib/flex/forms/applyItemsToVoucher';
import { getTodayUTC, getVoucherSign, markFormGroupTouched } from 'src/lib/helperFunctions';
import { CompoundTypeFromInvoiceTypes, InvoiceCompoundTypes } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'accounting-voucher-payments-credits',
  templateUrl: './voucher-payments-credits.component.html',
  styleUrls: ['./voucher-payments-credits.component.scss'],
})
export class VoucherPaymentsCreditsComponent implements ModalFormComponent<VoucherPaymentsCreditForm, ApplyItemsToVoucherPrefill> {
  @ViewChild('amountField', { static: false })
  amountField: NumerictextboxWrapperComponent;
  popup: boolean = true;

  form: TypedFormGroup<VoucherPaymentsCreditForm>;
  paymentTermDropdown = paymentTermDropdown();
  counterpartyDropdown = counterpartyDropdown();

  voucherTypes = InvoiceCompoundTypes;

  totalAmount: number | null;
  currencyId: number | null;

  amountFormat: NumberFormatOptions;

  constructor(private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<VoucherPaymentsCreditForm>({
      counterparty: new UntypedFormControl(null),
      iden: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      valueDate: new UntypedFormControl(getTodayUTC()),
      invoiceDate: new UntypedFormControl(null),
      amount: new UntypedFormControl(null),
      dueDate: new UntypedFormControl(null),
      paymentTerm: new UntypedFormControl(null),
      itemsLines: new UntypedFormControl([{ lineNumber: 1 }], []),
    });
  }

  ngAfterViewInit(): void {
    if (this.form && this.form.touched) {
      this.amountField.focus();
    }
  }

  prefillForm(data: ApplyItemsToVoucherPrefill) {
    let lineNumber = 1;
    this.currencyId = data.item.account.currencyId;
    this.amountFormat = this.formatter.getAmountFormat(this.currencyId);
    this.totalAmount = getVoucherSign(data.item.documentType, data.item.voucherType) * -data.item.amount;
    this.form.patchValue({
      counterparty: data.item.counterparty,
      iden: data.item.iden,
      type: CompoundTypeFromInvoiceTypes(data.item.voucherType, data.item.documentType),
      invoiceDate: data.item.invoiceDate,
      amount: this.totalAmount,
      dueDate: data.item.dueDate,
      itemsLines: data.openItems.map((openItem) => {
        const line: VoucherItemsForm = {
          lineNumber: lineNumber++,
          entryType: openItem.entryType,
          entryTitle: openItem.entryTitle,
          balanceAmount: this.formatter.roundAmount(openItem.balanceAmount, openItem.currencyId),
          amountToApply: this.formatter.roundAmount(openItem.balanceAmount, openItem.currencyId),
          entryReference: openItem.entryReference,
          valueDate: openItem.valueDate,
          entryId: openItem.entryId,
          currencyId: openItem.currencyId,
          accountId: openItem.accountId,
          balanceBaseAmount: openItem.balanceBaseAmount,
          ourReference: openItem.ourReference,
        };
        return line;
      }),
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): VoucherPaymentsCreditForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    return this.form.value;
  }
}
