import { enumOptionsFromEnum } from '../helperFunctions';

export type PackingType = {
  packingKey: number;
  packingName: string;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
};

/**
 * @deprecated
 */
export enum CommonPackingTypes {
  BALES = 113470,
  BAGS = 114470,
  BILLETS = 114477,
  BOXES = 114478,
  BRIQUETTES = 114481,
  BUNDLES = 114482,
  COILS = 114483,
  DRUMS = 114484,
  INGOTS = 114485,
  LOOSE = 114486,
  MAXI_BAGS = 114487,
  OTHER = 114488,
  SHEETS = 114489,
  SMALL_SACKS = 114490,
  SOWS = 114491,
  SPOOLS = 114492,
  TOTES = 114493,
  UNITS = 114494,
  WOOD_PALLETS = 114495,
  WOOD_SKIDS = 114496,
  BULK = 130398,
  SUPER_SACKS = 141746,
  ROLLS = 274147,
  PIECES = 1853958,
  PACKAGES = 1868202,
  //CRATES = 1868202,
  LOT = 17222934,
  BIG_BAGS = 22540326,
}

/**
 * @deprecated
 */
export const PackingTypes = enumOptionsFromEnum(CommonPackingTypes);
