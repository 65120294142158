import { DialogAction, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { CustomDialogResult } from 'src/app/core/services/selector-popup.service';
import { handlePrintResponse } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { PrintDocumentComponent, PrintDocumentForm } from 'src/app/shared/print-document/print-document.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Document, PrintDocumentResponse, ReservedInvoice, SourceEntityType, StorageTypes } from 'src/lib/newBackendTypes';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { simpleConfirmation } from '../flexDynamicForm';
import { DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';

const generateDraftInvoiceCallback: submitFormCallback<never> = (delegate, id) => {
  const api = delegate.getService('api');
  const dialog = delegate.getService('dialog');
  const spinner = delegate.getService('spinner');
  const res = api.rpc<ReservedInvoice>(endpoints.getReservedInvoice, { bookingId: id }, null);
  res.subscribe((reservedInvoice) => {
    if (reservedInvoice) {
      const actions: DialogAction[] = [{ text: 'Close' }, { text: 'Print Document' }];
      dialog
        .open({
          title: 'Success',
          content: 'Invoice Number successfully reserved',
          actions,
        })
        .result.subscribe((result) => {
          if (!(result instanceof DialogCloseResult)) {
            if (result.text === 'Print Document') {
              const rid = spinner.startRequest(randomFetchSynonym() + ' Documents');
              api
                .rpc<ListResponse<Document>>(
                  endpoints.listDocuments,
                  {
                    filters: {
                      entityType: SourceEntityType.RESERVED_INVOICE_ID,
                      entityId: reservedInvoice.bookingId,
                    },
                  },
                  null
                )
                .subscribe((documentRes) => {
                  spinner.completeRequest(rid);
                  if (documentRes) {
                    const selector = delegate.getService('selector');
                    selector
                      .openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
                        title: 'Generate Document',
                        submitButtonText: 'Generate',
                        initializer: (p: PrintDocumentComponent) => {
                          p.documents = documentRes.list;
                          p.entityName = `D${reservedInvoice.number}`;
                          p.entityType = SourceEntityType.RESERVED_INVOICE_ID;
                        },
                        maxWidth: 400,
                      })
                      .subscribe((popupResult) => {
                        if (popupResult !== 'Close') {
                          let request = {
                            packetId: popupResult.packet?.id,
                            outputName: popupResult.saveAs,
                            savingLocation: popupResult.location === -1 ? null : popupResult.location,
                            entityId: reservedInvoice.bookingId,
                          };
                          let rid = spinner.startRequest('Generating Document');
                          api.rpc<PrintDocumentResponse>(endpoints.generateDocumentsFromPacket, request, null, { blockRedirect: true }).subscribe((packet) => {
                            spinner.completeRequest(rid);

                            const actions: CustomDialogResult[] = [{ text: 'Close' }];
                            if (/Win/.test(navigator.platform) || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
                              actions.push({ text: 'Open File', themeColor: 'primary' });
                            }
                            dialog
                              .open({
                                title: 'Success',
                                content: 'File generated successfully',
                                actions,
                              })
                              .result.subscribe((action) => {
                                if ((action as DialogAction).text === 'Open File') {
                                  handlePrintResponse(delegate, packet);
                                }
                              });
                          });
                        }
                      });
                  }
                });
            }
          }
        });
    }
  });
  return res;
};

export const generateDraftInvoicePreset: DynamicFormConstant<any> = {
  value: DynamicFormType.GENERATE_DRAFT_INVOICE,
  label: 'Generate Draft Invoice',
  endpoints: [endpoints.getReservedInvoice, endpoints.listDocuments, endpoints.generateDocumentsFromPacket],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  title: 'Generate Draft Invoice',
  width: 350,
  submitForm: generateDraftInvoiceCallback,
  openForm: simpleConfirmation(`Generate Draft Invoice`, `Are you sure you want to generate a Draft Invoice for this Booking?\nThis will assign a reserved invoice number`, {
    confirmText: 'Yes',
    cancelText: 'No',
  }),
};
