<div class="clock-wrapper">
  <div class="holiday-slider">
    <span *ngIf="holidayMessages.length > 0">{{ holidayMessages[holidayMessageIndex] }}</span>
  </div>
  <ng-container *ngIf="holidayMessages.length > 1">
    <div class="message-count">({{ holidayMessageIndex + 1 }}/{{ holidayMessages.length }})</div>
    <div class="d-flex arrow-controls">
      <div class="arrow-button" (click)="clickUp()"><i class="k-icon k-i-arrow-60-up"></i></div>
      <div class="arrow-button" (click)="clickDown()"><i class="k-icon k-i-arrow-60-down"></i></div>
    </div>
  </ng-container>
</div>
<div class="mobile-alert" *ngIf="holidayMessages.length >= 1" [title]="holidayMessageTooltip" (click)="clickMobileAlert()">
  <i class="k-icon k-i-warning"></i>
</div>
