import { WithOptional } from '../generics';
import { ClauseTypes } from './contract';
import { Product } from './product';

export enum ClauseType {
  GENERAL = 120193,
  LC = 14902012,
}

export type BaseClauseTemplate = {
  id: number;
  typeId: ClauseTypes;
  name: string;
  clauseText: string;
  traderId: number | null;
  productId: number | null;
  counterpartyId: number | null;
};

export type ClauseTemplate = BaseClauseTemplate & {
  product?: Product;
  //TODO
  //trader?: Contact
  //counterparty?: Contact
};
export type CreateClauseTemplateRequest = WithOptional<Omit<BaseClauseTemplate, 'id'>, 'traderId' | 'productId' | 'counterpartyId'>;
export type UpdateClauseTemplateRequest = Pick<BaseClauseTemplate, 'id'> & Partial<Omit<BaseClauseTemplate, 'id'>>;
