import { Component, OnInit } from '@angular/core';
import { DropdownConfig } from 'src/lib';

@Component({
  selector: 'combobox-prompt',
  templateUrl: './combobox-prompt.component.html',
})
export class ComboboxPromptComponent<T> implements OnInit {
  public config: DropdownConfig<T>;
  public label: string;
  readonly value: T;

  constructor() {
    this.value = null;
  }

  ngOnInit(): void {}
}
