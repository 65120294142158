import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';

@Injectable()
export class LoginReturnGuard implements CanActivate {
  constructor(public router: Router, public msalService: MsalService) {}

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    if (this.msalService.instance.getActiveAccount() && !route.queryParams?.error) {
      let target = sessionStorage.getItem('thalos-url-target');
      if (!!target && target.length > 1) {
        sessionStorage.removeItem('thalos-url-target');
        this.router.navigate([target]);
        return of(false);
      } else {
        this.router.navigate(['/']);
        return of(false);
      }
    }
    return of(true);
  }
}
