import { AfterViewInit, Directive, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { SET_READONLY_MODE } from 'src/app/core/reducers/actions';
import { Store } from 'src/app/core/services/store.service';
import { StoreSubscription } from 'src/lib/StoreSubscription';
import { UnguardedFormElementComponent } from '../unguarded-form-element/unguarded-form-element.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class FormElementComponent extends UnguardedFormElementComponent implements OnDestroy, AfterViewInit {
  @Input()
  set readonly(val) {
    this._readonly = val;
  }
  get readonly(): boolean {
    return this._readonly || (!this.ignoreReadonlyMode && this._readonlyMode);
  }
  protected _readonly: boolean;
  protected _readonlyMode: boolean;

  @Input()
  ignoreReadonlyMode: boolean = false;

  @Input()
  loader: boolean = false;

  @HostBinding('hidden')
  hidden?: boolean;

  @Output()
  onHiddenChanged?: EventEmitter<boolean> = new EventEmitter();

  storeSubscription: StoreSubscription<boolean>;

  constructor(controlDir: NgControl, protected store: Store) {
    super(controlDir);

    this.readonly = false;
    this._readonlyMode = false;
  }

  ngAfterViewInit(): void {
    this.storeSubscription = this.store.subscribe((state) => state.form.readonlyMode, [SET_READONLY_MODE]);
    this.storeSubscription.$.pipe(untilDestroyed(this)).subscribe((state) => {
      if (this._readonlyMode != state) {
        setTimeout((_) => {
          this._readonlyMode = state;
        });
      }
    });
  }

  refreshPermissions(state) {
    if (state.form.viewingProtectedForm) {
      let procedureId = state.form.protectedFormProcedureId;
      let authProcedure = state.user.userEndpoints.find((endpoint) => endpoint.id === procedureId);
      if (authProcedure) {
        if (authProcedure.hiddenFields && authProcedure.hiddenFields.includes(this.thalosFieldPath)) {
          this.setHidden(true);
        } else {
          this.setHidden(false);
        }
        if (authProcedure.readonlyFields && authProcedure.readonlyFields.includes(this.thalosFieldPath)) {
          this.readonly = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
      this.storeSubscription = null;
    }
  }

  public get thalosFieldPath() {
    let control = this.controlDir.control;

    let path = this.getPath(control);
    return path;
  }

  private getPath(control: AbstractControl): string {
    if (!control.parent) {
      return '';
    } else {
      let parent = control.parent;
      let path: string = '';
      if (parent instanceof UntypedFormGroup) {
        let keys = Object.keys(parent.controls);
        path = keys.find((key) => parent.get(key) === control);
      }

      if (!!parent.parent && !!path) {
        return `${this.getPath(parent)}.${path}`;
      } else if (!!parent.parent) {
        return this.getPath(parent);
      } else return path;
    }
  }

  public setHidden(value: boolean) {
    if (!!this.hidden !== !!value) {
      this.onHiddenChanged.emit(value);
      setTimeout((_) => (this.hidden = value));
    }
  }

  public getFormControl() {
    return this.controlDir.control;
  }
}
