<div class="frame-container">
  <router-outlet></router-outlet>
</div>
<ng-container *ngIf="!!showSpinner">
  <div *ngIf="showSpinner | async" [ngClass]="{ background: (spinner | async)?.background }">
    <div class="k-i-loading large-spinner"></div>
    <div class="spinner-text">
      <div>{{ (spinner | async)?.text }}</div>
      <div *ngIf="progress !== null">
        <kendo-progressbar [value]="progress" [animation]="true" [label]="progressLabel" style="border: 1px solid #333"></kendo-progressbar>
      </div>
    </div>
  </div>
</ng-container>
