import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  template: `
    <div class="ag-overlay-loading-center" role="presentation">
      <div role="presentation" class="k-i-loading large-spinner" style="width: 100px; height: 80px; position: relative; margin: 0 auto;"></div>
      <div *ngIf="params?.loadingMessage" aria-live="polite" aria-atomic="true" style="width: 100px;">
        {{ params.loadingMessage }}
      </div>
    </div>
  `,
})
export class CustomOverlayLoader implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loadingMessage?: string };

  agInit(params: ILoadingOverlayParams & { loadingMessage?: string }): void {
    this.params = params;
  }
}
