<closable-form
  [formGroup]="form"
  title="Service Order"
  [entityId]="entity?.id"
  class="widescreen"
  [sourceEntityType]="sourceEntityType"
  [nextEntity]="nextEntity"
  [previousEntity]="previousEntity"
  [icon]="ServiceOrderIcon"
>
  <collapsible-card title="General">
    <div class="row">
      <vertical-field [name]="'Expense Type'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'expenseType'" [data]="expenseTypes" [textField]="'label'" [valueField]="'value'" [valuePrimitive]="true" [readonly]="isReadonly"> </simple-dropdown>
      </vertical-field>
      <vertical-field [name]="'Date'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'orderDate'" [readonly]="isReadonly"> </datepicker-wrapper>
      </vertical-field>
      <vertical-field [name]="'Counterparty'" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'contact'" [dropdownConfig]="contactDropdown" [readonly]="isReadonly"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field [name]="'Reference'" class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
        <input-wrapper [formControlName]="'orderReference'"></input-wrapper>
      </vertical-field>
      <vertical-field [name]="'Product'" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'productId'" [data]="commonData.staticProducts | async" [textField]="'name'" [valueField]="'productId'" [valuePrimitive]="true" [readonly]="isReadonly">
        </simple-dropdown>
      </vertical-field>
      <vertical-field [name]="'Container Type'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown
          [formControlName]="'containerTypeId'"
          [data]="commonData.staticContainerTypes | async"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
          [readonly]="isReadonly"
        >
        </simple-dropdown>
      </vertical-field>
      <vertical-field [name]="'Voucher Number'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" *ngIf="!!entity">
        <div class="linksContainer">
          <div class="keyLink" *ngIf="voucherKey === 'N/A' || !viewVoucherAuthorized">
            {{ voucherNumber }}
          </div>
          <a entityLink [entityId]="voucherKey" [entityType]="voucherEntityType" class="keyLink" *ngIf="voucherKey !== 'N/A' && viewVoucherAuthorized">{{ voucherNumber }}</a>
          <a entityLink [entityId]="voucherKey" [entityType]="voucherEntityType" [newTab]="true" *ngIf="voucherKey !== 'N/A' && viewVoucherAuthorized">
            <i class="k-icon k-i-hyperlink-open" style="color: rgba(0, 0, 0, 0.85); height: 1.2em"></i>
          </a>
        </div>
      </vertical-field>
    </div>
    <div class="form-divider-line"></div>
    <div class="row">
      <vertical-field [name]="'Category'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'service'" [dropdownConfig]="serviceConfig"></dropdown-wrapper>
      </vertical-field>
      <vertical-field [name]="'Origin'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-graphql [formControlName]="'origin'" [dropdownConfig]="cityConfig" [readonly]="isReadonly"> </dropdown-graphql>
      </vertical-field>
      <vertical-field [name]="'Destination'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-graphql [formControlName]="'destination'" [dropdownConfig]="cityConfig" [readonly]="isReadonly"> </dropdown-graphql>
      </vertical-field>
      <vertical-field [name]="'Description'" class="col-xl-4 col-lg-6 col-md-8 col-12">
        <textarea-wrapper [formControlName]="'orderComment'"> </textarea-wrapper>
      </vertical-field>
    </div>
    <ng-container *ngFor="let line of linesFormArray.controls; let i = index">
      <div class="form-divider-line"></div>
      <div class="row" [formGroup]="line">
        <vertical-field [name]="'Budget Element'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
          <dropdown-wrapper [formControlName]="'budgetElement'" [dropdownConfig]="budgetElementConfig" [readonly]="isReadonly"> </dropdown-wrapper>
        </vertical-field>
        <vertical-field [name]="'Quantity'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
          <numerictextbox-wrapper
            style="width: 40%"
            (blur)="onQuantityBlur(line)"
            [formControlName]="'genericQuantity'"
            [readonly]="isReadonly"
            [min]="0"
            [format]="quantityFormat"
            [decimals]="line.value.byPacking === 'Y' ? 0 : 3"
          >
          </numerictextbox-wrapper>
          <simple-dropdown
            [formControlName]="'byPacking'"
            [data]="byPackingOptions"
            [placeholder]="'Per...'"
            [readonly]="isReadonly"
            [textField]="'label'"
            [valueField]="'value'"
            [valuePrimitive]="true"
            style="width: 60%; padding-left: 10px"
          >
          </simple-dropdown>
        </vertical-field>
        <vertical-field [name]="'Unit Price'" class="col-xl-1 col-lg-2 col-md-3 col-sm-6">
          <numerictextbox-wrapper
            (blur)="onUnitPriceBlur(line)"
            [formControlName]="'orderUnitPrice'"
            [format]="{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 3 }"
            [decimals]="3"
            [readonly]="isReadonly"
          >
          </numerictextbox-wrapper>
        </vertical-field>
        <vertical-field [name]="'Order Price'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
          <numerictextbox-wrapper
            (blur)="onOrderPriceBlur(line)"
            [formControlName]="'orderPrice'"
            [fieldType]="2"
            [format]="dollarFormat"
            [decimals]="2"
            [readonly]="isReadonly"
          ></numerictextbox-wrapper>
          <simple-dropdown
            [formControlName]="'currencyId'"
            [data]="commonData?.staticCurrencies | async"
            [readonly]="isReadonly"
            [textField]="'code'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            style="width: 130px; padding-left: 10px"
            (blur)="calculatePriceTotals()"
          ></simple-dropdown>
        </vertical-field>
        <vertical-field class="col-2 col-md-1">
          <button style="width: 28px; flex-grow: 0" kendoButton [icon]="'trash'" type="button" (click)="removeServiceOrder(i)" *ngIf="form.get('lines').length > 1 && !entity"></button>
        </vertical-field>
      </div>
    </ng-container>
    <ng-container *ngIf="!entity">
      <div class="form-divider-line"></div>
      <div class="row" style="flex-direction: row-reverse">
        <div class="col-2 col-xl-5 d-flex">
          <button (click)="addServiceOrderLine()" kendoButton type="button" style="margin-left: auto" hideIfReadonlyMode>Add Line</button>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col d-flex" style="align-items: center" *ngIf="form.value.lines?.length > 1 && !!totalCurrencyId">
          <div class="total-label">Price Total: {{ totalOrderPrice | amountCurrency : totalCurrencyId }}</div>
        </div>
      </div>
    </ng-container>
  </collapsible-card>
  <collapsible-card title="Shipments" *ngIf="shipmentsAuthorized" [icon]="ShipmentIcon">
    <form-error-label [control]="form.get('shipments')"></form-error-label>
    <thalos-shipment-finder [readonly]="true" [preselectedShipments]="form.value.shipments" [completelyInvoiced]="isReadonly"> </thalos-shipment-finder>
    <div *ngIf="!entity || entity?.completelyInvoiced === 'N'" class="d-flex">
      <div style="margin-right: auto; display: flex; flex-wrap: wrap; align-items: center">
        <div class="total-label">Total Net: {{ totalNetWeight | quantityUnit : 'MT' }}</div>
        <!-- <div class="total-label">Supplier Final: {{totalSupplierFinalWeight | quantityUnit:'MT'}}</div> -->
        <!-- <div class="total-label">Customer Final: {{totalCustomerFinalWeight | quantityUnit:'MT'}}</div> -->
      </div>
      <div>
        <button type="button" kendoButton (click)="selectShipments()" hideIfReadonlyMode>Select Shipments</button>
      </div>
    </div>
  </collapsible-card>
  <lazy-card [title]="'Comments'" [icon]="CommentsIcon" [isOpen]="false" *ngIf="commentsAuthorized" #deferComments>
    <entity-comments *ngIf="deferComments.loaded" [entityId]="entity?.id" [entityType]="sourceEntityType" formControlName="comments" [showSpinner]="true" #lazy> </entity-comments>
  </lazy-card>
  <lazy-card [title]="'Documents'" [icon]="DocumentsIcon" *ngIf="documentsAuthorized" #deferDocuments>
    <microsoft-entity-documents
      *ngIf="deferDocuments.loaded"
      #documentsComponent
      formControlName="documents"
      [entityId]="entity?.id"
      [entityType]="sourceEntityType"
      [entity]="entity"
      [showSpinner]="true"
      #lazy
      [entityPath]="entity?.id"
    >
    </microsoft-entity-documents>
  </lazy-card>
  <lazy-card [title]="'Tasks'" [icon]="TasksIcon" *ngIf="!!entity && tasksAuthorized" [isOpen]="false" #deferTasks>
    <entity-tasks *ngIf="deferTasks.loaded" [entityId]="entity?.id" [entityType]="sourceEntityType" [showSpinner]="true" #lazy></entity-tasks>
  </lazy-card>
  <div class="container-button" [ngClass]="{ 'always-show': showSaveButtonsInPopup }">
    <button
      *ngIf="!!entity && deleteAuthorized && entity?.completelyInvoiced !== 'Y' && !popup"
      type="button"
      style="width: 75px"
      (click)="clickDelete()"
      class="container-button__button btn btn-sm btn-danger"
      [disabled]="requestsPending?.length"
    >
      Delete
    </button>
    <button *ngIf="saveAuthorized" type="button" cyData="submit" style="width: 75px" class="container-button__button btn btn-sm btn-success" (click)="clickSave()" [disabled]="requestsPending?.length">
      Save
    </button>
    <button
      *ngIf="createAuthorized && !!entity"
      type="button"
      cyData="submit"
      style="width: 125px"
      class="container-button__button btn btn-sm btn-success"
      (click)="clickSave({ forceCreate: true })"
      [disabled]="requestsPending?.length"
    >
      Save as New
    </button>
    <button
      *ngIf="invoiceable && !popup"
      type="button"
      cyData="submit"
      style="width: 125px"
      class="container-button__button btn btn-sm btn-success"
      (click)="clickSaveAndInvoice()"
      [disabled]="requestsPending?.length"
    >
      Save And Invoice
    </button>
    <button
      *ngIf="entity?.completelyInvoiced == 'Y' && reverseInvoiceAuthorized"
      type="button"
      cyData="submit"
      style="width: 125px"
      class="container-button__button btn btn-sm btn-warning"
      (click)="reverseVoucher()"
      [disabled]="requestsPending?.length"
    >
      Reverse Voucher
    </button>
  </div>
</closable-form>
<entity-footer [entity]="entity"></entity-footer>
