import { from, lastValueFrom, switchMap } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { PremiumFixationList } from 'src/lib/newBackendTypes/PremiumFixation';
import { simpleConfirmation } from '../flexDynamicForm';
import { DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';

const deletePremiumFixation: submitFormCallback<void> = (delegate, premiumFixationId) => {
  const api = delegate.getService('api');

  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PremiumFixationList | null>((resolve, reject) => {
      (async () => {
        try {
          return resolve(await lastValueFrom(api.rpc<PremiumFixationList>(endpoints.deletePremiumFixation, { filters: { id: premiumFixationId } }, null)));
        } catch (err) {
          return reject(err);
        }
      })();
    })
  ).pipe(
    switchMap((res) => {
      if (!!res && !!res.id) {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Premium Fixation successfully deleted</div>`);
      } else {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to delete Premium Fixation</div>`);
      }
    })
  );
};

export const deletePremiumFixationPreset: DynamicFormConstant<void> = {
  allowMultipleRows: false,
  title: 'Delete Premium Fixation',
  value: DynamicFormType.DELETE_PREMIUM_FIXATION,
  label: 'Delete Premium Fixation',
  entityType: SourceEntityType.PREMIUM_FIXATION_KEY,
  openForm: simpleConfirmation(`Delete Premium Fixation`, `Are you sure you want to delete this premium fixation?`, { confirmText: 'Yes', cancelText: 'No' }),
  submitForm: deletePremiumFixation,
  endpoints: [endpoints.deletePremiumFixation],
  width: 350,
};
