import { YN } from './enums';
import { PaymentTerm } from './paymentTerm';

export class WaTermGroup {
  termGroupKey: number;
  termGroupName: string;
  groupFormula: string;
  secured: YN | null;
  releaseTreshold: string | null;
  paymentTerms?: PaymentTerm[];
}
