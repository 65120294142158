import { Validators } from '@angular/forms';
import { concat, of } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { ContractForm } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { contactDropdown, paymentTermDropdown } from 'src/lib/commonTypes';
import { ContractPricingToContractFormPricing, PhysicalContract, SourceEntityType, UpdatePhysicalContractRequest } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import _ from 'lodash';

/**
 *
 * @param delegate
 * @param id
 * @returns Partial Contract, only returns prefill value if one contract is selected
 */

export const contractsPrefill: prefillCallback<ContractForm> = (delegate, id) => {
  if (!(id && id.length > 0)) return of('Contract ID(s) not found');
  const api = delegate.getService('api');

  return id.length === 1
    ? api.rpc<PhysicalContract>(endpoints.getContract, { filters: { id: id[0] } }, null).pipe(
        map((contract) => {
          if (!contract) return of('Contract not found');
          return {
            ...contract,
            paymentTerm: !_.isNil(contract.paymentTerm) ? contract.paymentTerm : null,
            incoterm: !_.isNil(contract.incotermId) ? contract.incoterm : null,
            company: !_.isNil(contract.companyId) ? contract.company : null,
            counterparty: !_.isNil(contract.counterpartyId) ? contract.counterparty : null,
            lines: (contract.lines || []).map((line) => {
              return {
                ...line,
                pricing: ContractPricingToContractFormPricing(line),
              };
            }),
          };
        })
      )
    : of({});
};

export const createExecutiveEditContractForm: createFormCallback<ContractForm & { incotermId: number }> = (delegate, id, prefill) => {
  const commonData = delegate.getService('commonData');
  const incoterms = commonData.staticIncoterms.value;
  id = Array.from(new Set(id));
  return [
    [
      _fe('counterparty', 'Counterparty', contactDropdown(), null, id.length <= 1 ? Validators.required : undefined),
      _fe('paymentTerm', 'Payment Term', paymentTermDropdown(), null, id.length <= 1 ? Validators.required : undefined),
      _fe('incotermId', 'Incoterm', { data: incoterms, valueField: 'id', labelField: 'name' }, null, id.length <= 1 ? Validators.required : undefined),
    ],
  ];
};

export const submitEditContractsExec: submitFormCallback<ContractForm & { incotermId: number }> = (delegate, id, form, prefill) => {
  if (!(id && id.length > 0)) return of(null);
  const api = delegate.getService('api');

  id = Array.from(new Set(id));
  return concat(
    ...(id as number[]).map((i) => {
      const request: UpdatePhysicalContractRequest = {
        id: i,
        paymentTermId: form.paymentTerm ? form.paymentTerm.id : id.length === 1 ? null : undefined,
        incotermId: form.incotermId ?? (id.length === 1 ? null : undefined),
        counterpartyId: form.counterparty ? form.counterparty.id : id.length === 1 ? null : undefined,
      };
      return api.rpc<PhysicalContract>(endpoints.updateContractManagement, request, null);
    })
  ).pipe(toArray());
};

export const editContractsExecPreset: DynamicFormConstant<ContractForm & { incotermId: number }> = {
  value: DynamicFormType.EDIT_CONTRACTS_EXEC,
  label: 'Edit Contracts Executive',
  title: 'Edit Contract Terms',
  endpoints: [endpoints.getContract, endpoints.updateContractManagement, endpoints.listContacts, endpoints.listPaymentTerms, endpoints.listIncoterms],
  entityType: SourceEntityType.CONTRACT_KEY,
  allowMultipleRows: true,
  width: 700,
  getPrefill: contractsPrefill,
  createForm: createExecutiveEditContractForm,
  submitForm: submitEditContractsExec,
};
