export enum YesNo {
  YES = 'Yes',
  NO = 'No',
}
export enum YN {
  Y = 'Y',
  N = 'N',
}

export const ynData: { label: string; value: YN }[] = [
  {
    label: YesNo.YES,
    value: YN.Y,
  },
  {
    label: YesNo.NO,
    value: YN.N,
  },
];
