import { ServiceOrderWithShipmentFinderResults } from 'src/app/+modules/+core/resolvers/service-order.resolver';
import { Currency } from 'src/app/shared/form-elements/formula-input/formula-input.component';
import { BudgetElement, City, Contact, Container, InvoiceDocumentType, YN } from '.';
import { Base, EnumLabels, Subset } from '../generics';
import { ContainerType } from './containerType';
import { BaseDiscrepancy, VoucherType } from './discrepancy';
import { Product } from './product';
import { PropertyDocument } from './propertyDocument';
import { Service } from './service';
import { ServiceOrderHistory } from './serviceOrderHistory';

export enum CommonBanks {
  WELLS_FARGO = 23,
  BBH = 226,
  BANCOMER = 2228,
  HSBC_BANK_FL = 2238,
  HSBC_MEXICO = 3635,
  AMERANT_BANK = 6236,
}

export enum Duration {
  DAY = 1,
  WEEK = 7,
  FORTNIGHT = 15,
  MONTH = 30,
}
export enum ExpenseType {
  PURCHASE = 1,
  SALE = 2,
  GLOBAL = 3,
}

export const durations: EnumLabels<Duration> = [
  { label: 'Day', value: Duration.DAY },
  { label: 'Week', value: Duration.WEEK },
  { label: 'Fortnight', value: Duration.FORTNIGHT },
  { label: 'Month', value: Duration.MONTH },
];

export const expenseTypes: EnumLabels<ExpenseType> = [
  { label: 'Purchase', value: ExpenseType.PURCHASE },
  { label: 'Sale', value: ExpenseType.SALE },
  { label: 'Global', value: ExpenseType.GLOBAL },
];

export type ServiceOrderResponse = {
  id: number;
  companyId: number;
  counterpartyId: number;
  budgetElementKey: number;
  serviceKey: number | null;
  originId: number;
  destinationId: number;
  orderReference: string;
  orderComment: string;
  orderDate: Date;
  orderPrice: number;
  currencyId: number;
  orderQuantity: number;
  numberOfDays: number;
  bankId: CommonBanks;
  expirationDate: Date;
  orderUnitPrice: number;
  productId: number;
  completelyInvoiced: YN;
  freeDays: number;
  duration: Duration;
  versionNb: number;
  contractNumbers: string;
  containerTypeId: number;
  byPacking: YN;
  grossWeight: YN;
  numberOfPacking: number;
  expenseType: ExpenseType;
  estimatedPaymentDate: Date;
  excludeFromCashForecast: YN;
  modifiedBy: string;
  lastUpdate: Date;
  operatorContactCode: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number;
  updateDate: Date | null;
  overagePenaltyConfirmed: YN;
  validated: YN;
  validatedBy: number;
  validateDate: Date | null;
  supplierAsBaseDiscrepancy?: BaseDiscrepancy;
  customerAsBaseDiscrepancy?: BaseDiscrepancy;
  contact?: Contact;
  company?: Contact;
  budgetElement?: BudgetElement;
  service?: Service;
  origin?: City;
  destination?: City;
  currency?: Currency;
  bank?: Contact;
  product?: Product;
  bmt?: ContainerType;
  shipments?: PropertyDocument[];
  additionalServiceOrders: ServiceOrder[];
  serviceOrderHistories: ServiceOrderHistory[];
};

export type ServiceOrderLine = {
  id: number;
  orderUnitPrice: number;
  orderPrice: number;
  budgetElement?: BudgetElement;
  budgetElementKey: number;
  orderQuantity: number;
  byPacking: YN;
  currencyId: number;
  numberOfPacking: number;
};

export type ServiceOrder = ServiceOrderResponse & {
  lines?: ServiceOrderLine[];
};

export function serviceOrderLineFromServiceOrder(so: ServiceOrder | ServiceOrderWithShipmentFinderResults): ServiceOrderLine {
  return {
    id: so.id,
    orderUnitPrice: so.orderUnitPrice,
    orderPrice: so.orderPrice,
    budgetElementKey: so.budgetElementKey,
    budgetElement: so.budgetElement,
    byPacking: so.byPacking,
    currencyId: so.currencyId,
    numberOfPacking: so.numberOfPacking,
    orderQuantity: so.orderQuantity,
  };
}

export function collapseServiceOrders(so: ServiceOrderResponse, additionalServiceOrders: ServiceOrder[]): ServiceOrder {
  return {
    ...so,
    lines: [serviceOrderLineFromServiceOrder(so), ...additionalServiceOrders.map((s) => serviceOrderLineFromServiceOrder(s))],
  };
}

export interface CreateServiceOrderRequest
  extends Subset<
    Base<ServiceOrder>,
    | 'counterpartyId'
    | 'budgetElementKey' // budget element (element)
    | 'originId' // place 1
    | 'destinationId' // place 2
    | 'orderReference' // reference
    | 'orderPrice' // total price
    | 'currencyId' // currency by total price
    | 'byPacking',
    | 'expenseType' // type (purchase/sale/global)
    | 'orderDate' // send
    | 'orderComment' // send
    | 'companyId' // dont send
    | 'numberOfPacking' // if byPacking = Y, send this
    | 'orderQuantity' // if byPacking = N, send this
    | 'serviceKey' // category, send, nullable
    | 'productId' // dont send
    | 'containerTypeId'
  > {
  // dont send
  shipments?: Subset<PropertyDocument, 'id'>[]; // [{ id: 12345 }, { id: 456789 }, ....]
}

export interface UpdateServiceOrderRequest
  extends Subset<
    ServiceOrder,
    'id',
    | 'counterpartyId'
    | 'budgetElementKey'
    | 'originId'
    | 'destinationId'
    | 'orderReference'
    | 'orderDate'
    | 'orderPrice'
    | 'currencyId'
    | 'orderQuantity'
    | 'byPacking'
    | 'expenseType'
    | 'orderComment'
    | 'numberOfPacking'
    | 'serviceKey'
    | 'productId'
    | 'containerTypeId'
  > {
  shipments?: Subset<PropertyDocument, 'id'>[];
}

export interface InvoiceServiceOrderRequest {
  id: ServiceOrder['id'] | ServiceOrder['id'][];
  voucherType?: VoucherType;
  documentType?: InvoiceDocumentType;
  invoiceDate?: Date;
  dueDate?: Date;
  termKey?: number;
  bankAccountId?: number;
  deliveryDate?: Date | null;
  yourReference?: string;
  keywords?: {
    keywordKey: number;
    keywordValue: string;
    linePosition?: number | null;
  }[];
}

export interface CreateInvoicedServiceOrderRequest extends CreateServiceOrderRequest {}
export interface CreateInvoicedServiceOrderRequest extends Omit<InvoiceServiceOrderRequest, 'id'> {}

export interface CreateBatchServiceOrderRequest
  extends Subset<Base<ServiceOrder>, 'counterpartyId' | 'budgetElementKey' | 'orderReference' | 'currencyId' | 'byPacking' | 'orderDate' | 'serviceKey' | 'containerTypeId' | 'orderComment'> {
  requestSource: string;
  batchEntries: BatchServiceOrderEntries[];
}
export interface BatchServiceOrderEntries {
  container: Container;
  containerId: number;
  containerNumber: string;
  shipmentId?: number;
  amount: number;
}
export interface BatchServiceOrderResponse {
  args: {
    counterpartyId: number;
    budgetElementKey: number;
    orderReference: string;
    orderPrice: number;
    currencyId: number;
    byPacking: YN;
    orderDate: Date;
    orderComment: string;
    numberOfPacking: number;
    serviceKey: number | null;
    shipments: { id: number }[];
    originId: number | null;
    destinationId: number | null;
    productId: number;
    containerTypeId: number;
  };
}
