<form [formGroup]="form">
  <div class="row justify-content-center" style="max-width: 500px">
    <p class="text-center"><b>Are you sure you want to reverse/delete this voucher?</b></p>
    <br>
    <p class="text-center">The current Permanent Operations Date is {{permDateOperations}} and Permanent Accounting Date is {{permDateAccounting}}</p>
    <vertical-field class="col-12 d-flex justify-content-center">
      <checkbox-wrapper [formControlName]="'forceReversal'" label="Force Reversal"> </checkbox-wrapper>
    </vertical-field>
  </div>
</form>
