<div [formGroup]="form">
  <div class="row">
    <vertical-field name="Value Date" class="col-xxl-1 col-lg-2 col-md-3 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'valueDate'"></datepicker-wrapper>
    </vertical-field>
  </div>
  <form-table [formArray]="form.get('childShipments')">
    <ng-template #tableHeader>
      <div class="col-xl-5 col-lg-6 col-md-6 col-12">
        <div class="row">
          <div class="col-form-label-sm col-md-4 col-sm-6 col-12">Status</div>
          <div class="col-form-label-sm col-md-4 col-sm-6 col-12">Gross</div>
          <div class="col-form-label-sm col-md-4 col-sm-6 col-12">Net</div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-5 col-12">
        <div class="row">
          <div class="col-form-label-sm col-6">Packing</div>
          <div class="col-form-label-sm col-6">Packing Quantity</div>
        </div>
      </div>
      <div class="col-md-1">
        <button type="button" kendoButton tabindex="-1" class="invisible" [icon]="'trash'"></button>
      </div>
    </ng-template>
    <ng-template #tableRow let-shipment="element" let-i="i">
      <div class="col-xl-5 col-lg-6 col-md-6 col-12">
        <div class="row">
          <table-field [name]="'status'" class="col-md-4 col-sm-6 col-12">
            <simple-dropdown [formControl]="shipment.get('status')" [data]="chunkTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
          </table-field>
          <table-field [name]="'Gross Weight'" class="col-md-4 col-sm-6 col-12">
            <numerictextbox-wrapper [fieldType]="3" [format]="weightFormat" [formControl]="shipment.get('grossWeight')" [decimals]="4" [ignoreReadonlyMode]="true" class="horizontal-input-with-btn">
            </numerictextbox-wrapper>
            <div class="unit-label horizontal-btn">{{ unit?.code }}</div>
          </table-field>
          <table-field [name]="'Net Weight'" class="col-md-4 col-sm-6 col-12">
            <numerictextbox-wrapper
              [readonly]="form?.value?.id"
              [format]="weightFormat"
              [formControl]="shipment.get('netWeight')"
              [decimals]="4"
              [ignoreReadonlyMode]="true"
              (blur)="onNetBlur(shipment)"
              class="horizontal-input-with-btn"
            >
            </numerictextbox-wrapper>
            <div class="unit-label horizontal-btn">{{ unit?.code }}</div>
          </table-field>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-5 col-12">
        <form class="row" style="margin: 0 -10px" *ngFor="let packing of shipment.get('packingTypes').controls; let i = index" [formGroup]="packing">
          <table-field name="Packing Type" class="col-6">
            <simple-dropdown
              class="horizontal-input-with-btn"
              [formControlName]="'packingTypeId'"
              [data]="commonData.staticPackingTypes | async"
              [valueField]="'packingKey'"
              [textField]="'packingName'"
              [valuePrimitive]="true"
            ></simple-dropdown>
          </table-field>
          <table-field name="Quantity" class="col-6">
            <numerictextbox-wrapper class="horizontal-input-with-btn" [fieldType]="3" [format]="intFormat" [formControlName]="'quantity'" [decimals]="0" [ignoreReadonlyMode]="true">
            </numerictextbox-wrapper>
            <button
              class="non-button horizontal-btn"
              tabindex="-1"
              *ngIf="shipment.get('packingTypes').controls.length - 1 === i && !!packing.value.packingTypeId"
              style="margin: auto 2px; cursor: pointer"
              (click)="addPacking(shipment)"
              type="submit"
            >
              <i class="fas fa-plus-circle"></i>
            </button>
            <i *ngIf="shipment.get('packingTypes').controls.length > 1" tabindex="-1" style="margin: auto 2px; cursor: pointer" class="k-icon k-i-trash" (click)="removePacking(i, shipment)"></i>
          </table-field>
        </form>
      </div>
      <table-field class="col-md-1">
        <div style="text-align: right" *ngIf="form.get('childShipments').controls.length > 1">
          <button style="max-width: 32px" [icon]="'trash'" kendoButton type="button" (click)="removeChild(i)"></button>
        </div>
      </table-field>
    </ng-template>
    <ng-template #extraTableRow>
      <div class="row thick-border-top footer-row" style="min-height: 30px">
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="row">
            <table-field class="col-md-4 col-sm-6 col-12"> Total </table-field>
            <table-field [name]="'Total Gross'" class="col-md-4 col-sm-6 col-12">
              <span [ngClass]="{ success: totalGrossMatch, warning: !totalGrossMatch }">{{ totalGross | quantityUnit }}/{{ sourceShipment?.grossWeight | quantityUnit : unit }}</span>
            </table-field>
            <table-field [name]="'Total Net'" class="col-md-4 col-sm-6 col-12">
              <span [ngClass]="{ success: totalNetMatch, error: !totalNetMatch }"> {{ totalNet | quantityUnit }}/{{ sourceShipment?.netWeight | quantityUnit : unit }}</span>
            </table-field>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-5 col-12">
          <form class="row" style="margin: 0 -10px" *ngFor="let total of packingTotals; let i = index">
            <table-field name="Packing Type" class="col-6">
              {{ total.packingTypeName }}
            </table-field>
            <table-field name="Quantity" class="col-6">
              {{ total.quantity }}
            </table-field>
          </form>
        </div>
      </div>
      <div class="row footer-row" style="min-height: 30px">
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="row">
            <table-field class="col-md-4 col-sm-6 col-12"> Remaining </table-field>
            <table-field [name]="'Remaining Gross'" class="col-md-4 col-sm-6 col-12">
              <span>{{ sourceShipment?.grossWeight - totalGross | quantityUnit : unit }}</span>
            </table-field>
            <table-field [name]="'Remaining Net'" class="col-md-4 col-sm-6 col-12">
              <span> {{ sourceShipment?.netWeight - totalNet | quantityUnit : unit }}</span>
            </table-field>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #tableFooter>
      <form-error-label [control]="form"></form-error-label>
      <button type="button" kendoButton (click)="addChild()" style="margin-left: auto">Add Shipment</button>
    </ng-template>
  </form-table>
  <div class="row" style="padding: 10px 0px">
    <checkbox-wrapper [label]="'Replace subshipments in booking(s)'" [formControlName]="'linkChildShipment'" class="col-md-4 col-sm-6 col-12"></checkbox-wrapper>
  </div>
</div>
