import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ConfigKey, ThalosConfiguration } from 'src/lib/newBackendTypes/configuration';
import { ThalosApiService } from './thalos-api.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  private configuration: { [key in ConfigKey]?: string };

  configLoaded: BehaviorSubject<boolean>;

  public CONFIG_MISSING: boolean;

  constructor(api: ThalosApiService) {
    this.configLoaded = new BehaviorSubject(null);
    api.rpc<ListResponse<ThalosConfiguration>>(endpoints.listConfigurations, { filters: {} }, { list: [], count: 0 }).subscribe((res) => {
      this.configuration = {};
      for (let c of res.list) {
        this.configuration[c.key] = c.value;
      }
      const integrityVerified = requiredConfigKeys.every((k) => this.configuration[k]);
      this.CONFIG_MISSING = !integrityVerified;
      this.configLoaded.next(integrityVerified);
    });
  }

  get(key: ConfigKey) {
    return this.configuration[key];
  }

  loaded() {
    if (this.configLoaded.closed) return of(this.configLoaded.value);
    return this.configLoaded.asObservable().pipe(
      filter((v) => v !== null),
      first()
    );
  }
}

const requiredConfigKeys = [ConfigKey.ROOT_FOLDER_URL, ConfigKey.SITE_ID, ConfigKey.DRIVE_ID];
