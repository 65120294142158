import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { lastValueFrom } from 'rxjs';
import { MicrosoftFileBrowserComponent } from 'src/app/shared/microsoft-file-browser/microsoft-file-browser.component';
import { StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { State } from '../../reducers';
import { TOGGLE_DEBUG_MODE } from '../../reducers/actions';
import { PromptService } from '../../services/prompt.service';
import { SpinnerService } from '../../services/spinner.service';
import { Store } from '../../services/store.service';
import { ThalosApiService } from '../../services/thalos-api.service';

@Component({
  selector: 'roco-debug',
  templateUrl: './debug.component.html',
})
export class DebugComponent implements OnInit {
  debugMode: StoreSubscription<boolean>;
  state: StoreSubscription<any>;
  activeForm: () => UntypedFormGroup;

  sharepointLink: string;

  constructor(
    private store: Store,
    private hotkeysService: HotkeysService,
    private api: ThalosApiService,
    private spinnerService: SpinnerService,
    private promptService: PromptService,
    public dialog: DialogService,
    public msal: MsalService
  ) {
    this.debugMode = this.store.subscribe((state: State) => state.layout.debugMode, [TOGGLE_DEBUG_MODE]);

    this.hotkeysService.add(
      new Hotkey(['meta+shift+d', 'alt+shift+d'], (event: KeyboardEvent): boolean => {
        this.store.dispatch({ type: TOGGLE_DEBUG_MODE });
        return false; // Prevent bubbling
      })
    );

    this.state = this.store.subscribe((state: State) => {
      this.activeForm = state.form.activeForm;
      return {
        ...state,
        form: {
          ...state.form,
          activeForm: !!state.form.activeForm ? { ...state.form.activeForm(), controls: null } : null,
        },
      };
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.debugMode.unsubscribe();
    this.state.unsubscribe();
  }

  throwFakeError(delay: number) {
    setTimeout(() => {
      throw new Error('Test');
    }, delay);
  }

  async throw400Error() {
    await lastValueFrom(this.api.rpc<any>(endpoints.mockError, { code: 400 }, {}));
  }

  async throw500Error() {
    await lastValueFrom(this.api.rpc<any>(endpoints.mockError, { code: 500 }, {}));
  }

  async throwValidationError() {
    await lastValueFrom(this.api.rpc<any>(endpoints.createContact, { wrong: 'string' }, {}));
  }

  startSpinner1() {
    let rId: string;
    setTimeout(() => {
      rId = this.spinnerService.startRequest('DEBUG_1');
    }, 2000);

    setTimeout(() => {
      this.spinnerService.completeRequest(rId);
    }, 3500);
  }

  startSpinner2() {
    let rId: string;
    setTimeout(() => {
      rId = this.spinnerService.startRequest('DEBUG_2');
    }, 1000);

    setTimeout(() => {
      this.spinnerService.completeRequest(rId);
    }, 8000);
  }

  printForm() {
    if (this.activeForm) {
      console.log(this.activeForm());
    }
  }

  getFormValidationErrors() {
    if (this.activeForm) {
      const form = this.activeForm();
      Object.keys(form.controls).forEach((key) => {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  }

  openDialog() {
    this.promptService.textDialog('Test', 'line1\nline2');
  }

  sharepoint() {
    this.dialog.open({
      title: 'File Browser',
      content: MicrosoftFileBrowserComponent,
      maxHeight: '90%',
      maxWidth: '95%',
    });
  }
}
