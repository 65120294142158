<div class="d-flex">
  <kendo-multiselect
    #dropdown
    [data]="filteredOptions"
    [filterable]="true"
    [valueField]="'cn'"
    [textField]="'cn'"
    [(ngModel)]="_value"
    [placeholder]="placeholder"
    (open)="onOpen()"
    [loading]="fetching"
    [readonly]="readonly"
    (blur)="onBlur()"
    (valueChange)="onValueChange(value)"
    (paste)="pasteHandler($event)"
  >
  </kendo-multiselect>
  <button *ngIf="copyBtn" kendoButton type="button" [icon]="'copy'" title="Copy" class="ms-2" (click)="copyAuthorizedGroups()"></button>
</div>
