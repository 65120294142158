import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { WaTermDate } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getPaymentTermDateListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<WaTermDate> = {
    where: { label: { contains: new VariableType('label') } },
    orderBy: [{ label: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getPaymentTermDateListVariablesGraphql() {
  const variables: GraphqlVariables<WaTermDate> = { label: 'String' };
  return variables;
}

export const listPaymentTermDateGraphqlRequest = (args: GraphqlRequest<WaTermDate>): GraphqlArgsRequest<WaTermDate> => {
  return {
    __aliasFor: ExistingGraphql.waTermDates,
    __args: args,
    id: true,
    label: true,
  };
};
