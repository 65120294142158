import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { BankAccount } from './account';
import { Contact } from './contact';
import { BankFeeAttribution, ContactBankInformation } from './contactBankInformation';
import { Delivery } from './delivery';
import { VoucherType } from './discrepancy';
import { YN } from './enums';
import { FiscalCompany } from './fiscalCompany';
import { GlAccountLeaves } from './glAccount';
import { InvoiceDocumentType } from './invoiceDocumentType';
import { InvoiceKeyword } from './invoiceKeyword';
import { InvoiceLine } from './invoiceLine';
import { JournalHeader } from './journal';
import { PaymentEntry } from './payment';
import { PaymentTerm } from './paymentTerm';
import { PayOrderLine } from './payOrder';
import { PropertyDocument } from './propertyDocument';

export type SaleInvoiceData = {
  invoiceId: number;
  shipmentId: number;
  marks: string;
  invoiceNumber: number;
  fixedPrice: YN;
  grossWeight: number;
  netWeight: number;
  quantityUnit: string;
  unitPrice: number;
  priceUnit: string;
  totalPrice: number;
  currency: string;
};
export type basePurchaseInvoice = {
  id: number;
  counterpartyId: number;
  invoiceDate: string;
  accountingDate: string;
  vateCode: string;
  applyAdvancePayment: YN;
  applyCN: YN;
  provisionalOnWeight: YN;
  provisionalOnPrice: YN;
  printAsPerModel: YN;
  invoiceRate: number;
  invoiceAmount: number;
  invoiceQuantity: number;
  currencyId: number;
  unitId: number;
  totalAmount: number;
};
export type PurchaseInvoice = basePurchaseInvoice & {
  counterparty?: Contact;
  shipments?: PropertyDocument[];
};
export type InvoicePurchaseTicketRequest = {
  invoiceDate: Date;
  accountingDate: number; // valueDate
  invoiceReference: string;
  shipments: { id: number; purchasePrice: number; purchaseAmount: number; buyPriceUnitId: number }[];
  serviceOrders: { id: number }[];
};
export enum InvoiceStatus {
  TEMPORARY = 1,
  SENT = 2,
  TO_BE_SENT = 3,
  DUE = 4,
  PARTIALLY_PAID = 5,
  PRINTED = 6,
  CANCELLED = 7,
  PAID = 8,
  PERMANENT = 9,
  TO_BE_VERIFIED = 10,
  REVERSED = 11,
}

export const InvoiceStatuses = enumOptionsFromEnum(InvoiceStatus);

export const PaidInvoiceStatuses: InvoiceStatus[] = [InvoiceStatus.PAID, InvoiceStatus.PARTIALLY_PAID];

export enum EntryOperation {
  NORMAL = 1,
  EXTERNAL = 2,
  MONTH_CONS = 3,
  DAILY_CONS = 4,
  AUXILIARY = 5,
  EXCH_DIFF = 6,
  INITIAL_BALANCES = 7,
  EXP_AND_REV_TRANS = 8,
  NET_INCOME_TRANSFERS = 9,
  TO_REVERSE = 10,
  REVERSED_ITEM = 11,
  REVERSE = 12,
  INTEREST = 13,
  INTERCO_CONSO = 14,
}
export const EntryOperations = enumOptionsFromEnum(EntryOperation);

export enum InvoiceEntrySource {
  MANUAL = 0,
  AUTOMATION = 1,
}
export const InvoiceEntrySources = enumOptionsFromEnum(InvoiceEntrySource);

export enum InvoiceCompoundType {
  CUSTOMER_INVOICE = 1,
  CUSTOMER_CREDIT = 2,
  CUSTOMER_DEBIT = 3,
  SUPPLIER_INVOICE = 4,
  SUPPLIER_CREDIT = 5,
  SUPPLIER_DEBIT = 6,
  INVENTORY_WRITE_OFF = 7,
}
export const InvoiceCompoundTypes = enumOptionsFromEnum(InvoiceCompoundType);
export function InvoiceTypesFromCompoundType(type: InvoiceCompoundType): { voucherType: VoucherType; documentType: InvoiceDocumentType } {
  switch (type) {
    case InvoiceCompoundType.CUSTOMER_INVOICE:
      return { voucherType: VoucherType.INVOICE, documentType: InvoiceDocumentType.CUSTOMER };
    case InvoiceCompoundType.CUSTOMER_CREDIT:
      return { voucherType: VoucherType.CREDIT, documentType: InvoiceDocumentType.CUSTOMER };
    case InvoiceCompoundType.CUSTOMER_DEBIT:
      return { voucherType: VoucherType.DEBIT, documentType: InvoiceDocumentType.CUSTOMER };
    case InvoiceCompoundType.SUPPLIER_INVOICE:
      return { voucherType: VoucherType.INVOICE, documentType: InvoiceDocumentType.SUPPLIER };
    case InvoiceCompoundType.SUPPLIER_CREDIT:
      return { voucherType: VoucherType.CREDIT, documentType: InvoiceDocumentType.SUPPLIER };
    case InvoiceCompoundType.SUPPLIER_DEBIT:
      return { voucherType: VoucherType.DEBIT, documentType: InvoiceDocumentType.SUPPLIER };
    default:
      throw new Error('Should be impossible');
  }
}

export function getVoucherLabel(voucherInfo: { voucherType: VoucherType; documentType?: InvoiceDocumentType }) {
  let documentType = '';
  switch (voucherInfo.documentType) {
    case InvoiceDocumentType.CUSTOMER:
      documentType = 'Customer ';
      break;
    case InvoiceDocumentType.SUPPLIER:
      documentType = 'Supplier ';
      break;
  }

  switch (voucherInfo.voucherType) {
    case VoucherType.CREDIT:
      return documentType + 'Credit Note';
    case VoucherType.DEBIT:
      return documentType + 'Debit Note';
    case VoucherType.INVOICE:
      return documentType + 'Invoice';
    default:
      return documentType + 'Voucher';
  }
}

export function CompoundTypeFromInvoiceTypes(voucherType: VoucherType, documentType: InvoiceDocumentType): InvoiceCompoundType {
  switch (documentType) {
    case InvoiceDocumentType.CUSTOMER:
      switch (voucherType) {
        case VoucherType.INVOICE:
          return InvoiceCompoundType.CUSTOMER_INVOICE;
        case VoucherType.CREDIT:
          return InvoiceCompoundType.CUSTOMER_CREDIT;
        case VoucherType.DEBIT:
          return InvoiceCompoundType.CUSTOMER_DEBIT;
        case VoucherType.WRITE_OFF:
          return InvoiceCompoundType.INVENTORY_WRITE_OFF;
      }
    case InvoiceDocumentType.SUPPLIER:
      switch (voucherType) {
        case VoucherType.INVOICE:
          return InvoiceCompoundType.SUPPLIER_INVOICE;
        case VoucherType.CREDIT:
          return InvoiceCompoundType.SUPPLIER_CREDIT;
        case VoucherType.DEBIT:
          return InvoiceCompoundType.SUPPLIER_DEBIT;
      }
    default:
      throw new Error('Should be impossible');
  }
}

export type InvoiceHeader = {
  id: number;
  companyId: number;
  /**
   * @deprecated
   */
  fiscalYearNumber: number;
  documentType: InvoiceDocumentType;
  number: number;
  invoiceInformation: string | null;
  invoiceDate: Date;
  invoiceStatus: InvoiceStatus;
  deliveryDate: Date | null;
  isInvoicePrinted: YN | null;
  invoiceTitle: string;
  ourReference: string;
  yourReference: string;
  counterpartyId: number;
  accountId: number;
  totalLines: number;
  amount: number;
  baseAmount: number;
  entryOperation: EntryOperation;
  entrySource: InvoiceEntrySource;
  externalReference: string;
  journalHeader: JournalHeader;
  journalKey: number;
  freeText: string | null;
  modifiedBy: string | null;
  modifyDate: Date | null;
  versionNb: number | null;
  vatExtrasKey: number; // Removed null type and null transformer. Agreed w/ AP. All new Vouchers should have VatExtrasKey
  bankAccountId: number | null;
  dueDate: Date;
  vatRateDate: Date;
  defaultInfoPayLineKey: number | null;
  payComment: string;
  payRef: string;
  payCostAttribType: BankFeeAttribution;
  payOtherInstructions: string;
  payExecDate: Date | null; //OPTIONAL
  printDate: Date | null; // OPTIONAL. This is the Item Date in Finnaccount's Voucher Header
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  voucherType: VoucherType;
  revInvoiceKey: number | null;
  iden: string;
  invoiceSentDate: Date | null;
  materialInvoice: YN;
  paymentTermId?: number;
  fiscalCompany?: FiscalCompany;
  deliveries?: Delivery[];
  paymentInfo?: ContactBankInformation;
  counterparty?: Contact;
  // journalHeader?: JournalHeader
  // extra?: Extra
  bankAccount?: BankAccount;
  account?: GlAccountLeaves;
  reversalVoucher?: InvoiceHeader;
  reversedVoucher?: InvoiceHeader;
  invoiceLines?: InvoiceLine[];
  // invoiceAddOns?: InvoiceAddOn[]
  paymentTerm?: PaymentTerm;
  invoiceKeywords?: InvoiceKeyword[];
  isDeleted?: boolean;
  ivaDeclarationDate: Date | null;
  paymentEntries?: PaymentEntry[] | null;
  payOrderLines?: PayOrderLine[] | null;
};

export interface CreateVoucherHeader
  extends Subset<
    InvoiceHeader,
    | 'companyId'
    | 'documentType'
    | 'invoiceInformation'
    | 'invoiceStatus'
    | 'invoiceTitle'
    | 'ourReference'
    | 'yourReference'
    | 'counterpartyId'
    | 'entrySource'
    | 'externalReference'
    | 'freeText'
    | 'vatExtrasKey'
    | 'bankAccountId'
    | 'dueDate'
    | 'vatRateDate'
    | 'payOtherInstructions'
    | 'printDate'
    | 'voucherType'
    | 'entryOperation',
    'invoiceDate' | 'baseAmount' | 'payExecDate' | 'revInvoiceKey' | 'accountId' | 'deliveryDate' | 'invoiceSentDate' | 'ivaDeclarationDate' | 'paymentTermId'
  > {
  budgetElementId?: number;
}

export type CreateVoucherLine = Subset<
  InvoiceLine,
  'linePosition' | 'artText' | 'artPrice' | 'artQuantity' | 'vatExtrasKey' | 'amount' | 'externalRef' | 'unitKey' | 'priceUnitKey' | 'lineBlobValue' | 'bookQuantity' | 'anlCorrectQuantity',
  'accKey' | 'artReference' | 'productId' | 'shipmentId' | 'contractId'
> & { lineNote?: string; budgetElementId?: number };

export interface CreateVoucherKeyword extends Subset<InvoiceKeyword, 'keywordKey' | 'keywordValue', 'linePosition'> {}

export interface CreateVoucherRequest extends CreateVoucherHeader {
  lines: CreateVoucherLine[];
  keywords: CreateVoucherKeyword[];
}

export type CreateClientInvoiceHeaderRequest = Subset<InvoiceHeader, 'counterpartyId' | 'invoiceDate' | 'dueDate' | 'number'>;
export type CreateClientInvoiceProvisionalLineRequest = {
  type: 'Provisional';
  shipmentId: number;
  quantity: number;
  materialPrice: number | null;
  linePrice: number;
  lineTotal: number;
  formula: string;
  priceUnitId: number;
  priceCurrencyId: number;
  collateralPercentage: number | null;
  collateralAmount: number | null;
  lineNote: string;
  fxRate: number | null;
  marketDate: Date | null;
  marketPrice: number | null;
  premiumMarketPrice: number | null;
  marketValuationId: number | null;
  premiumMarketValuationId: number | null;
};

export type CreateClientInvoiceFixedLineRequest = {
  type: 'Fixed';
  shipmentId: number;
  quantity: number;
  linePrice: number;
  lineTotal: number;
  formula: string;
  priceUnitId: number;
  priceCurrencyId: number;
  lineNote: string;
};

export type CreateClientInvoiceRequest = CreateClientInvoiceHeaderRequest & {
  lines: (CreateClientInvoiceProvisionalLineRequest | CreateClientInvoiceFixedLineRequest)[];
  termId: number;
  numberOfDecimals: number;
};

export type UpdateVoucherLineRequest = Subset<
  InvoiceLine,
  'linePosition',
  'artText' | 'externalRef' | 'artPrice' | 'artQuantity' | 'amount' | 'unitKey' | 'priceUnitKey' | 'artReference' | 'productId' | 'shipmentId' | 'contractId'
> & { lineNote?: string };
export type UpdateVoucherRequest = Subset<
  InvoiceHeader,
  'id',
  | 'counterpartyId'
  | 'accountId'
  | 'printDate'
  | 'invoiceDate'
  | 'deliveryDate'
  | 'invoiceInformation'
  | 'invoiceStatus'
  | 'invoiceTitle'
  | 'ourReference'
  | 'freeText'
  | 'externalReference'
  | 'payComment'
  | 'payRef'
  | 'vatExtrasKey'
  | 'bankAccountId'
  | 'dueDate'
  | 'yourReference'
  | 'vatRateDate'
  | 'payOtherInstructions'
  | 'payExecDate'
  | 'invoiceSentDate'
  | 'ivaDeclarationDate'
  | 'paymentTermId'
> & {
  lines?: UpdateVoucherLineRequest[];
  termId?: number;
};

export type UpsertVoucherKeywordsRequest = Subset<InvoiceKeyword, 'keywordKey' | 'invoiceId' | 'linePosition', 'keywordValue'>;

export type SetInvoiceSentDateRequest = Pick<InvoiceHeader, 'id'> & { invoiceSentDate: Date | string | null };

export type PayableItem = InvoiceHeader | PaymentEntry;
