import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { City } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';
import { getBasicGraphqlArgs } from '../graphQlFunctions';

export function getCityListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<City> = {
    where: { id: { not: { equals: 0 } }, name: { contains: new VariableType('name') } },
    orderBy: [{ name: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getCityListVariablesGraphql() {
  const variables: GraphqlVariables<City> = { name: 'String' };
  return variables;
}

export const basicCityGraphqlRequest = (args: GraphqlRequest<City>): GraphqlArgsRequest<City> => {
  return { __aliasFor: ExistingGraphql.places, __args: args, id: true, name: true };
};

export const listCityGraphqlRequest: GraphqlArgsRequest<City> = {
  ...basicCityGraphqlRequest(getBasicGraphqlArgs()),
  id: true,
  name: true,
  country: {
    code: true,
  },
};

export const listCustomCityList = (args: Omit<GraphqlArgsRequest<City>, '__aliasFor' | '__args'>, take: number = 20): GraphqlArgsRequest<City> => {
  return { ...basicCityGraphqlRequest(getCityListArgsGraphql(take)), ...args };
};
