import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { DropdownConfig } from 'src/lib';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { TypedFormGroup } from 'src/lib/typedForms';
import { PromptService } from 'src/app/core/services/prompt.service';
import { GraphqlDropdownConfig } from 'src/lib/graphql/GraphqlDropdownConfig';
import { NumericTextBoxType } from '../form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';

@UntilDestroy()
@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent<SubmitType, PrefillType = SubmitType> implements OnInit, OnDestroy, ModalFormComponent<SubmitType, PrefillType> {
  popup = true;

  form: UntypedFormGroup;
  prefillValue: Partial<PrefillType>;

  constructor(private prompt: PromptService) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});

    for (let el of this._formElements) {
      this.addControl(el);
    }

    if (this.prefillValue) {
      this.form.patchValue(this.prefillValue);
    }
  }

  ngOnDestroy(): void {}

  addControl<T>(element: FormElement<T>, parent?: UntypedFormGroup) {
    if (!parent) {
      parent = this.form;
    }
    if (element.type === 'Space') return;
    if (element.type === 'Line') {
      for (let e of element.formElements) {
        this.addControl(e, parent);
      }
    } else if (element.type === 'SubForm') {
      let a = new UntypedFormArray([]);
      if (element.validator) {
        a.setValidators(element.validator);
      }
      parent.addControl(element.field as string, a);
      if (element.startingValue && Array.isArray(element.startingValue)) {
        for (let _ in element.startingValue) {
          this.addSubFormLine(a, element);
        }
        a.patchValue(element.startingValue);
      }
    } else if (element.type === 'Label') {
      //Do nothing
    } else {
      let c = new UntypedFormControl(element.startingValue ?? (element.type === 'Text' || element.type === 'TextArea' ? '' : null));
      if (element.validator) c.setValidators(element.validator);
      if (element.validatorFactory) c.addValidators(element.validatorFactory(parent as TypedFormGroup<T>));
      parent.addControl(element.field.toString(), c);
      if (!!element.getHidden) {
        parent.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
          element.hidden = element.getHidden(parent as TypedFormGroup<T>);
        });
      }
      if (!!element.getReadonly) {
        parent.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
          element.readonly = element.getReadonly(parent as TypedFormGroup<T>);
        });
      }
    }
  }

  addSubFormLine<T>(arr: UntypedFormArray, element: FormElement<T>) {
    if (element.type !== 'SubForm') return;
    if (!arr) return;
    let fg = new UntypedFormGroup({});

    arr.push(fg);
    for (let e of element.formElements) {
      this.addControl(e, fg);
    }
    let midChange = false;
    if (element.listener) {
      fg.valueChanges
        .pipe(
          untilDestroyed(this),
          filter(() => midChange === false),
          tap(() => (midChange = true)),
          map(() => fg),
          tap(element.listener)
        )
        .subscribe(() => (midChange = false));
    }
  }

  removeSubFormLine(arr: UntypedFormArray, index: number) {
    this.prompt.deleteConfirmation('Remove Line').subscribe((result) => {
      if (result) {
        arr.removeAt(index);
      }
    });
  }

  prefillForm(partial: Partial<PrefillType>) {
    this.prefillValue = partial;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit() {
    return this.form.value;
  }

  private _formElements: FormElement<SubmitType>[] = [];

  set formElements(elements: (FormElementConfig<SubmitType> | FormElementConfig<SubmitType>[])[]) {
    this._formElements = elements.flatMap((el) => (Array.isArray(el) ? { type: 'Line', formElements: el } : el ?? []));
  }
}

type BaseFieldConfig<T> = {
  field: keyof T;
  label: string;
  validator?: ValidatorFn | ValidatorFn[];
  validatorFactory?: (fg: TypedFormGroup<T>) => ValidatorFn | ValidatorFn[];
  startingValue?: any;
  readonly?: boolean;
  hidden?: boolean;
  getHidden?: (fg: TypedFormGroup<T>) => boolean;
  getReadonly?: (fg: TypedFormGroup<T>) => boolean;
  ignoreReadonlyMode?: boolean;
};

type SingleFormFieldConfig<T> = BaseFieldConfig<T> &
  (
    | { type: 'DynamicDropdown'; config: DropdownConfig<any> }
    | (SimpleDropdownConfig<any> & { type: 'StaticDropdown' })
    | { type: 'DynamicGraphqlDropdown'; config: GraphqlDropdownConfig<any> }
    | { type: 'DynamicMultiselect'; config: DropdownConfig<any> }
    | { type: 'DynamicGraphqlMultiselect'; config: GraphqlDropdownConfig<any> }
    | (SimpleMultiselectConfig<any> & { type: 'StaticMultiselect' })
    | { type: 'Text' }
    | { type: 'Number'; format: NumberFormatOptions; decimals: number; unitLabel?: string; fieldType?: NumericTextBoxType }
    | { type: 'Date' }
    | { type: 'Checkbox'; valueMask: { true: any; false: any }; inLineLabel?: boolean }
    | { type: 'TextArea' }
  );

export type SingleFormElementConfig<T> = SingleFormFieldConfig<T> | { type: 'Space' } | { type: 'Label'; text: string; style?: object };

export type FormArrayConfig<T> = BaseFieldConfig<T> & {
  type: 'SubForm';
  formElements: FormElement<T[keyof T]>[];
  listener: (v: TypedFormGroup<T[keyof T]>) => void;
};

export type FormElementConfig<T> = SingleFormElementConfig<T> | FormArrayConfig<T>;

type FormElement<T> = FormElementConfig<T> | { type: 'Line'; formElements: FormElementConfig<T>[] } | { type: 'Space' };

type SimpleDropdownConfig<T> = { data: T[]; labelField: keyof T; valueField: keyof T; secondaryTextField?: string };
type SimpleMultiselectConfig<T extends { label: string; value: any }> = { data: T[] };

type NumberDecimalFormat = {
  useGrouping?: boolean;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  unitLabel?: string;
  fieldType?: NumericTextBoxType;
};

//_fe factory

export type FormElementArg<T = any, K extends keyof T = any> = T[K] extends Date
  ? 'Date'
  : T[K] extends Array<object>
  ? (DropdownConfig<any> | GraphqlDropdownConfig<any> | SimpleMultiselectConfig<any>) & { array: true }
  : T[K] extends object
  ? DropdownConfig<any> | GraphqlDropdownConfig<any>
  : T[K] extends string
  ? 'Text' | 'TextArea' | { true: T[K]; false: T[K]; inLineLabel?: boolean } | SimpleDropdownConfig<any>
  : T[K] extends boolean
  ? { true: T[K]; false: T[K]; inLineLabel?: boolean }
  : T[K] extends number
  ? NumberDecimalFormat | SimpleDropdownConfig<any>
  : DropdownConfig<any> | GraphqlDropdownConfig<any> | NumberDecimalFormat | 'Text' | SimpleDropdownConfig<any> | 'Date' | 'TextArea';

function isFormElementTextInput(el: FormElementArg): el is 'Text' {
  return el === 'Text';
}

function isFormElementDynDropdown(el: FormElementArg): el is DropdownConfig<any> {
  return typeof el === 'object' && !!el && 'listProcedure' in el && 'labelField' in el && 'valueField' in el;
}

function isFormElementDynGraphqlDropdown(el: FormElementArg): el is GraphqlDropdownConfig<any> {
  return typeof el === 'object' && !!el && 'graphQlName' in el && 'labelField' in el && 'valueField' in el;
}

function isFormElementNumber(el: FormElementArg): el is NumberDecimalFormat {
  return typeof el === 'object' && !!el && 'minimumFractionDigits' in el && 'maximumFractionDigits' in el;
}

function isFormElementStaticDropdown(el: FormElementArg): el is SimpleDropdownConfig<unknown> {
  return typeof el === 'object' && !!el && 'data' in el && 'labelField' in el && 'valueField' in el && Array.isArray(el.data);
}

function isFormElementDateInput(el: FormElementArg): el is 'Date' {
  return el === 'Date';
}

function isFormElementTextArea(el: FormElementArg): el is 'TextArea' {
  return el === 'TextArea';
}

function isFormElementDynamicMultiselect(el: FormElementArg): el is DropdownConfig<any> & { array: true } {
  return typeof el === 'object' && !!el && 'listProcedure' in el && 'labelField' in el && 'valueField' in el && 'array' in el && el.array;
}

function isFormElementDynamicGraphqlMultiselect(el: FormElementArg): el is GraphqlDropdownConfig<any> & { array: true } {
  return typeof el === 'object' && !!el && 'graphQlName' in el && 'labelField' in el && 'valueField' in el && 'array' in el && el.array;
}

function isFormElementStaticMultiselect(el: FormElementArg): el is SimpleMultiselectConfig<{ label: string; value: any }> & { array: true } {
  return typeof el === 'object' && !!el && 'data' in el && Array.isArray(el.data) && 'array' in el && el.array;
}

function isFormElementCheckbox(el: FormElementArg): el is { true: any; false: any } {
  return typeof el === 'object' && !!el && 'true' in el && 'false' in el;
}

/**
 *
 * @param field
 * @param label
 * @param el
 * @param validator
 * @param startingValue
 * @returns
 */

export function _fe<T, K extends keyof T>(
  field: K,
  label: string,
  el: FormElementArg<T, K>,
  startingValue?: T[K] | null,
  validator?: ValidatorFn | ValidatorFn[],
  readonly?: boolean,
  ignoreReadonlyMode?: boolean
): SingleFormFieldConfig<T> {
  if (isFormElementTextInput(el)) {
    return {
      type: 'Text',
      field,
      label,
      validator,
      startingValue,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementDynamicMultiselect(el)) {
    return {
      type: 'DynamicMultiselect',
      field,
      label,
      validator,
      readonly,
      config: el,
      ignoreReadonlyMode,
    };
  } else if (isFormElementDynamicGraphqlMultiselect(el)) {
    return {
      type: 'DynamicGraphqlMultiselect',
      field,
      label,
      validator,
      readonly,
      config: el,
      ignoreReadonlyMode,
    };
  } else if (isFormElementStaticMultiselect(el)) {
    return {
      type: 'StaticMultiselect',
      field,
      label,
      validator,
      startingValue,
      data: el.data,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementDynDropdown(el)) {
    return {
      type: 'DynamicDropdown',
      field,
      label,
      config: el,
      validator,
      startingValue,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementDynGraphqlDropdown(el)) {
    return {
      type: 'DynamicGraphqlDropdown',
      field,
      label,
      config: el,
      validator,
      startingValue,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementStaticDropdown(el)) {
    return {
      type: 'StaticDropdown',
      field,
      label,
      validator,
      startingValue,
      labelField: el.labelField,
      valueField: el.valueField,
      secondaryTextField: el.secondaryTextField,
      data: el.data,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementNumber(el)) {
    return {
      type: 'Number',
      field,
      label,
      format: {
        maximumFractionDigits: el.maximumFractionDigits,
        minimumFractionDigits: el.minimumFractionDigits,
        useGrouping: el.useGrouping,
      },
      decimals: el.maximumFractionDigits,
      validator,
      startingValue,
      readonly,
      unitLabel: el.unitLabel,
      ignoreReadonlyMode,
      fieldType: el.fieldType,
    };
  } else if (isFormElementDateInput(el)) {
    return {
      type: 'Date',
      field,
      label,
      validator,
      startingValue,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementTextArea(el)) {
    return {
      type: 'TextArea',
      field,
      label,
      validator,
      startingValue,
      readonly,
      ignoreReadonlyMode,
    };
  } else if (isFormElementCheckbox(el)) {
    return {
      type: 'Checkbox',
      field,
      label,
      validator,
      startingValue,
      readonly,
      valueMask: el,
      ignoreReadonlyMode,
      inLineLabel: el.inLineLabel,
    };
  }
  return null;
}

export function _fa<T, K extends keyof T>(
  field: K,
  label: string,
  formElements: T[K] extends ArrayLike<any> ? (SingleFormElementConfig<T[K][number]> | SingleFormElementConfig<T[K][number]>[])[] : never,
  startingValue?: T[K] | null,
  validator?: ValidatorFn,
  listener?: T[K] extends ArrayLike<any> ? (fg: TypedFormGroup<T[K][number]>) => void : never
): FormArrayConfig<T> {
  return {
    type: 'SubForm',
    field,
    label,
    formElements: formElements.map((fe) => {
      if (Array.isArray(fe)) {
        return {
          type: 'Line',
          formElements: fe,
        };
      }
      return fe;
    }),
    startingValue,
    listener,
    validator,
  };
}

export function _blank(): FormElement<any> {
  return {
    type: 'Space',
  };
}
