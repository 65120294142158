import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DriveItem, DriveItemVersion } from 'microsoft-graph';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, last, map, switchMap, tap } from 'rxjs/operators';
import { MicrosoftFileBrowserComponent } from 'src/app/shared/microsoft-file-browser/microsoft-file-browser.component';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { ConfigKey } from 'src/lib/newBackendTypes/configuration';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { GlobalConfigService } from './global-config.service';
import { PromptService } from './prompt.service';
import { SelectorPopupService } from './selector-popup.service';
import { SpinnerService } from './spinner.service';
import { dateGreaterThanOrSameAs, getAYearAgo } from 'src/lib/helperFunctions';

/**
 * Will replace with configuration
 */
export const entityFolderIdMap: { [key in SourceEntityType]?: string } = {
  [SourceEntityType.FREIGHT_BOOKING_KEY]: 'Bookings',
  [SourceEntityType.ALF_CODE]: 'Contacts',
  [SourceEntityType.CONTRACT_KEY]: 'Contracts',
  [SourceEntityType.DISCREPANCY_ID]: 'Discrepancies',
  [SourceEntityType.ADVANCE_KEY]: 'Financial Instruments',
  [SourceEntityType.JOURNAL_ID]: 'Journals',
  [SourceEntityType.PAYMENT_KEY]: 'Payments',
  [SourceEntityType.SERVICE_ORDER_KEY]: 'Service Orders',
  [SourceEntityType.INVOICE_KEY]: 'Vouchers',
  [SourceEntityType.PAY_ORDER_KEY]: 'Pay Orders',
  [SourceEntityType.THALOS_LETTER_OF_CREDIT]: 'Letters of Credit',
};

const blobSize = 10485760;

@Injectable({
  providedIn: 'root',
})
export class SharePointService {
  public baseUrl: string;
  public rootFolderUrl: string;

  constructor(private http: HttpClient, private spinner: SpinnerService, private popup: SelectorPopupService, private prompt: PromptService, config: GlobalConfigService) {
    this.baseUrl = `https://graph.microsoft.com/v1.0/sites/${config.get(ConfigKey.SITE_ID)}/drives/${config.get(ConfigKey.DRIVE_ID)}`;
    this.rootFolderUrl = `${config.get(ConfigKey.ROOT_FOLDER_URL)}`;
  }

  getFilesForEntity(entityType: SourceEntityType, entityPath: string | number): Observable<{ items: DriveItem[]; parent: DriveItem | null }> {
    const entityName = entityFolderIdMap[entityType];

    if (entityName === undefined) {
      console.warn(`Unable to get SharePoint docs: unknown entity type ${entityType}`);
      return of({ items: [], parent: null });
    }
    if (!entityPath) {
      console.warn(`Unable to get SharePoint docs: invalid entity path ${entityPath}`);
      return of({ items: [], parent: null });
    }

    const url = `${this.baseUrl}/root:/${entityName}\/${entityPath}:/`;
    return this.http.get(url).pipe(
      switchMap((folder) => {
        if (!folder) {
          return of({ items: [], parent: null });
        }
        return this.http.get(`${url}children`).pipe(
          map((childrenResult: { value: DriveItem[] }) => {
            if (!!childrenResult?.value) {
              return { items: childrenResult.value, parent: folder };
            } else return { items: [], parent: null };
          })
        );
      }),
      catchError((err) => {
        console.error(err);
        return of({ items: [], parent: null });
      })
    );
  }

  getEntityFolder(entityType: SourceEntityType, entityPath: string | number): Observable<DriveItem | null> {
    const entityName = entityFolderIdMap[entityType];

    if (entityName === undefined) {
      console.warn(`Unable to get SharePoint docs: unknown entity type ${entityType}`);
      return of(null);
    }
    if (!entityPath) {
      console.warn(`Unable to get SharePoint docs: invalid entity path ${entityPath}`);
      return of(null);
    }

    const url = `${this.baseUrl}/root:/${entityName}\/${entityPath}:/`;
    return this.http.get<DriveItem>(url).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  getRootFiles(skipTokenUrl?: string): Observable<{ value: DriveItem[]; '@odata.nextLink': string }> {
    const url = skipTokenUrl || `${this.baseUrl}/root/children?$top=100`;

    let rId: string = this.spinner.startRequest(randomFetchSynonym());
    return this.http.get(url).pipe(
      catchError((e) => {
        this.spinner.completeRequest(rId);
        return e;
      }),
      map((res: { value: DriveItem[]; '@odata.nextLink': string }) => {
        this.spinner.completeRequest(rId);
        return res;
      })
    );
  }

  fetchChildren(item: DriveItem) {
    if (!item.id) return of([]);
    const url = `${this.baseUrl}/items/${item.id}/children?$top=999999&$orderby=name desc`;

    let rId: string = this.spinner.startRequest(randomFetchSynonym());
    return this.http.get(url).pipe(
      catchError((e) => {
        this.spinner.completeRequest(rId);
        return e;
      }),
      map((res: { value: DriveItem[] }) => {
        this.spinner.completeRequest(rId);
        return res.value.sort((a, b) => b.createdDateTime.localeCompare(a.createdDateTime)).filter((item) => dateGreaterThanOrSameAs(item.createdDateTime, getAYearAgo()));
      })
    );
  }

  createFolder(parent: DriveItem, name: string) {
    const url = `${this.baseUrl}/items/${parent.id}/children`;

    let rId: string = this.spinner.startRequest('Creating Folder');

    const newFolder: DriveItem & any = {
      name,
      folder: {},
      '@microsoft.graph.conflictBehavior': 'fail',
    };

    return this.http.post(url, newFolder).pipe(
      catchError((e) => {
        this.spinner.completeRequest(rId);
        return e;
      }),
      map((res: DriveItem) => {
        this.spinner.completeRequest(rId);
        return res;
      })
    );
  }

  createEntityFolder(entityType: SourceEntityType, entityPath: string | number) {
    const entityName = entityFolderIdMap[entityType];
    const entityUrl = `${this.baseUrl}/root:/${entityName}:/`;

    const newFolder: DriveItem & any = {
      name: `${entityPath}`,
      folder: {},
      '@microsoft.graph.conflictBehavior': 'fail',
    };

    return this.http.post(`${entityUrl}children`, newFolder).pipe(
      catchError((e) => {
        console.error(e);

        if (e?.status === 409) {
          const url = `${this.baseUrl}/root:/${entityName}\/${entityPath}:/`;
          return this.http.get(url).pipe(
            catchError((e) => {
              console.error(e);
              this.displayError(e);
              return of(null);
            })
          );
        }

        return of(null);
      }),
      map((res: DriveItem) => {
        return res;
      })
    );
  }

  uploadFile(folderId: string, file: File, spinnerText?: string, existingFileId?: string) {
    const url = existingFileId ? `${this.baseUrl}/items/${existingFileId}/createUploadSession` : `${this.baseUrl}/items/${folderId}:/${file.name}:/createUploadSession`;

    let rid = this.spinner.startRequest(spinnerText, 0, false, !spinnerText);
    return this.http
      .post<{ uploadUrl: string }>(url, {
        name: file.name,
        fileSize: file.size,
        '@microsoft.graph.conflictBehavior': 'rename',
      })
      .pipe(
        catchError((err) => {
          this.spinner.completeRequest(rid);
          return of(null);
        }),
        switchMap((res: { uploadUrl: string }) => {
          if (!!res) {
            let cursor = 0;
            let chunk = 0;
            const expectedChunks = file.size / blobSize;
            return of(false).pipe(
              expand((erroredOut) => {
                if (erroredOut) return EMPTY;
                if (chunk > 0 && expectedChunks > 1) {
                  this.spinner.updateRequest(rid, {
                    progress: Math.floor((chunk / expectedChunks) * 100),
                  });
                }
                if (cursor < file.size) {
                  const start = cursor;
                  const end = Math.min(cursor + blobSize - 1, file.size - 1);
                  let blob = file.slice(start, end + 1);
                  cursor = end + 1;
                  chunk++;
                  return this.http
                    .put(res.uploadUrl, blob, {
                      headers: {
                        'Content-Range': `bytes ${start}-${end}/${file.size}`,
                      },
                    })
                    .pipe(
                      catchError((err) => {
                        console.error(err);
                        return of(true);
                      }),
                      map((res) => (res ? false : true))
                    );
                } else {
                  return EMPTY;
                }
              }),
              last()
            );
          } else return of(null);
        }),
        switchMap((res) => {
          if (!existingFileId || res === null) return of(res);

          const url = `${this.baseUrl}/items/${existingFileId}`;
          return this.http.patch(url, { name: file.name }).pipe(
            catchError((e) => {
              return of(res);
            }),
            map((nameChange) => {
              return of(res);
            })
          );
        }),
        tap((file) => {
          this.spinner.completeRequest(rid);
        })
      );
  }

  deleteFile(driveItem: DriveItem) {
    const url = `${this.baseUrl}/items/${driveItem.id}/`;

    let rId: string = this.spinner.startRequest(randomFetchSynonym());
    return this.http.delete(url).pipe(
      map((res: any) => {
        this.spinner.completeRequest(rId);
        return true;
      }),
      catchError((e) => {
        this.displayError(e);
        this.spinner.completeRequest(rId);
        return of(null);
      })
    );
  }

  displayError(response: HttpErrorResponse) {
    this.prompt.textDialog(`Error: ${response?.statusText || response.status}`, `${response?.error?.error?.message || response?.message}`);
  }

  selectFile() {
    return this.popup.open<DriveItem, MicrosoftFileBrowserComponent>(MicrosoftFileBrowserComponent, { title: 'Select upload location' });
  }

  getVersionsOfFile(driveItem: DriveItem): Observable<{ value: DriveItemVersion[] }> {
    const url = `${this.baseUrl}/items/${driveItem.id}/versions`;

    return this.http.get<{ value: DriveItemVersion[] }>(url).pipe(
      catchError((err) => {
        console.error(err);

        return of({ value: [] });
      })
    );
  }

  downloadVersion(driveItem: DriveItem, version: string) {
    const url = `${this.baseUrl}/items/${driveItem.id}/versions/${version}/content`;

    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  humanReadableSharepointFolderPath(webUrl: string) {
    const root = this.rootFolderUrl;

    const folderPath = decodeURI(webUrl.replace(root, ''));
    return folderPath;
  }
}
