import { Route } from '@angular/router';
import { FlexComponent } from 'src/app/+modules/+flex/flex/containers/flex-view.component';
import { FlexResolver } from 'src/app/+modules/+flex/flex/resolvers/flex.resolver';
import { ListView, ListViewResponse } from '../views/listView';

export function newFlexRoute(flexView: ListViewResponse | ListView, path: string): Route {
  return {
    path,
    resolve: { flex: FlexResolver },
    data: { flexView },
    component: FlexComponent,
  };
}
