<div class="m-3" *ngIf="debugMode.$ | async">
  <button kendoButton type="button" (click)="printForm()">Active Form</button>
  <button kendoButton type="button" (click)="getFormValidationErrors()">Active Form Validations</button>
  <button kendoButton type="button" (click)="throwFakeError(0)">Error</button>
  <button kendoButton type="button" (click)="throwFakeError(1500)">Delayed Error</button>
  <button kendoButton type="button" (click)="throw400Error()">400 Error</button>
  <button kendoButton type="button" (click)="throw500Error()">500 Error</button>
  <button kendoButton type="button" (click)="throwValidationError()">Validation Error</button>
  <button kendoButton type="button" (click)="startSpinner1()">Spinner 1</button>
  <button kendoButton type="button" (click)="startSpinner2()">Spinner 2</button>
  <button kendoButton type="button" (click)="openDialog()">Dialog</button>
  <button kendoButton type="button" (click)="sharepoint()">Sharepoint</button>
  <ngx-json-viewer [json]="state.$ | async" [expanded]="false"></ngx-json-viewer>
</div>
