import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss'],
})
export class FormTableComponent implements OnInit {
  @Input()
  formArray?: UntypedFormArray | any[];

  @Input()
  formGroup?: UntypedFormGroup;

  @Input()
  noDataMessage: string;

  @Input()
  title?: string;

  @Input()
  filter?: (any) => boolean;

  @ContentChild('tableRow', { static: false })
  tableRowTmpl: TemplateRef<any>;

  @ContentChild('extraTableRow', { static: false })
  extraTableRowTmpl: TemplateRef<any>;

  @ContentChild('tableHeader', { static: false })
  tableHeaderTmpl: TemplateRef<any>;

  @ContentChild('tableFooter', { static: false })
  tableFooterTmpl: TemplateRef<any>;

  objectKeys;

  constructor() {
    this.objectKeys = Object.keys;
  }

  ngOnInit(): void {
    if (this.formArray && this.formGroup) {
      throw new Error('You cannot define both a formArray and formGroup');
    }
  }

  get empty() {
    if (!!this.formArray) {
      return this.formArray.length === 0;
    }
    if (!!this.formGroup) {
      return Object.keys(this.formGroup).length === 0;
    }
    return true;
  }

  get formArrayControls() {
    if (this.formArray instanceof UntypedFormArray) return this.formArray.controls;
    else return this.formArray;
  }
}
