import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ContainerForm } from 'src/app/+modules/+logistics/container/container.component';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { intFormat } from 'src/lib/commonTypes';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Booking, ShipmentPurchaseSaleData, StorageTypes } from 'src/lib/newBackendTypes';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { pairwise, startWith } from 'rxjs/operators';
import { AnyContainerForm } from 'src/app/+modules/+logistics/booking-base/booking-base.component';
import { DocumentRow, isThalosDoc } from '../microsoft-entity-documents/microsoft-entity-documents.component';

@UntilDestroy()
@Component({
  selector: 'logistics-booking-split',
  templateUrl: './booking-split.component.html',
  styleUrls: ['./booking-split.component.scss'],
})
export class BookingSplitComponent implements OnInit, OnDestroy, ModalFormComponent<SplitBookingForm> {
  @Input()
  originalBooking!: Omit<Booking, 'containers'>;

  @Input()
  set containers(containers: AnyContainerForm[]) {
    this._containers = containers.map((c) => {
      return { ...c, totalWeight: this.totalWeight(c) };
    });
  }

  @Input()
  documents: DocumentRow[];

  _containers!: (AnyContainerForm & { totalWeight: string })[];

  form: TypedFormGroup<SplitBookingForm>;

  popup = true;

  bookingNumbers: { value: number; label: string }[];

  intFormat = intFormat();

  pathOptions: { value: '*Skip*' | '*Create*' | number; label: string }[];

  originalBookingNumber!: number;

  constructor() {
    this.form = new TypedFormGroup<SplitBookingForm>({
      numberOfBookings: new UntypedFormControl(null, [Validators.required, this.bookingAllocationValidator()]),
      containerLinks: new TypedFormArray<{
        container: ContainerForm;
        bookingNumber: number;
        purchaseFolder: string;
      }>([]),
    });

    this.bookingNumbers = [];

    this.form
      .get('numberOfBookings')
      .valueChanges.pipe(untilDestroyed(this), startWith(null), pairwise())
      .subscribe(([prev, curr]) => {
        if (prev !== curr) this.setBookingNumberOptions(curr);
      });
    this.pathOptions = [];
  }

  ngOnInit(): void {
    for (let c of this._containers) {
      let saleContracts: ShipmentPurchaseSaleData[] = 'shipments' in c ? (c.shipments || []).flatMap((s) => s.contractData ?? []) : [];

      let fg = new TypedFormGroup<ContainerLinkForm>({
        container: new UntypedFormControl(c),
        bookingNumber: new UntypedFormControl(null, Validators.required),
        purchaseFolder: new UntypedFormControl(null),
        items: new UntypedFormControl('N/A'),
        saleContracts: new UntypedFormControl('shipments' in c ? saleContracts.map((c) => `${c.saleContractNumber} - ${c.customerName}`).join('\n') : 'N/A'),
      });
      (this.form.get('containerLinks') as UntypedFormArray).push(fg);

      fg.get('bookingNumber')
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe(() => {
          this.form.get('numberOfBookings').updateValueAndValidity();
        });

      fg.get('purchaseFolder').setValidators((c: AbstractControl) => {
        let bookingIndex = this.bookingNumbers.findIndex((b) => b.value === fg.value.bookingNumber);
        if (bookingIndex !== this.bookingNumbers.length - 1) {
          return Validators.required(c);
        }

        return null;
      });

      fg.get('bookingNumber').setValidators((c: AbstractControl) => {
        let bookingIndex = this.bookingNumbers.findIndex((b) => b.value === c.value);
        if (bookingIndex !== this.bookingNumbers.length - 1 && fg.value.container?.shipments?.some((s) => s.selDeliveryId > 0)) {
          return { custom: 'Invoiced shipment can only be assigned to original Booking' };
        }
        return null;
      });

      if ('shipments' in c) {
        let s: Set<string> = new Set();
        for (let i of (c.shipments ?? []).flatMap((s) => s.item?.name ?? [])) {
          s.add(i);
        }

        fg.patchValue({ items: Array.from(s).join(',\n') });
      }
    }

    if (this.documents) {
      this.pathOptions = this.documents.flatMap((d) => {
        if (isThalosDoc(d) && (d.storageType === StorageTypes.FILESYSTEM_FOLDER || d.storageType === StorageTypes.URL)) {
          return { value: d.id, label: d.fileName ?? d.fsPath };
        }
        return [];
      });
    }
    this.pathOptions.unshift({ value: '*Skip*', label: 'Skip' });
    this.pathOptions.unshift({ value: '*Create*', label: 'Create New' });
  }

  ngOnDestroy(): void {}

  setBookingNumberOptions(val: number | null) {
    (this.form.get('containerLinks') as UntypedFormArray).controls.forEach((c: TypedFormGroup<{ container: ContainerForm; bookingNumber: number }>) => {
      c.patchValue({ bookingNumber: null });
    });
    this.bookingNumbers = [];
    if (!val || val <= 0) return;
    for (let i = 1; i < val; i++) {
      this.bookingNumbers.push({ value: i, label: `${i}` });
    }
    this.bookingNumbers.push({ value: val, label: `${val} (Original)` });

    this.originalBookingNumber = val;
  }

  prefillForm() {}

  submit() {
    return this.form.value;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  bookingAllocationValidator() {
    return (control: AbstractControl) => {
      if (control.value === null) return null;
      if (!this.form) return null;
      const n: number = control.value;

      if (n <= 0 || n > this._containers.length) return { custom: 'Number of Bookings must between 1 and the number of containers' };

      for (let i = 1; i <= n; i++) {
        let match = this.form.value.containerLinks.some((l) => l.bookingNumber === i);

        if (!match) return { custom: `All created Bookings must have at least one container` };
      }

      return null;
    };
  }

  totalWeight(c: AnyContainerForm) {
    if (!('totals' in c)) return 'N/A';
    if (!c || !c.totals || c.totals.length === 0) return '?';

    let mt = c.totals.find((t) => t.unit && t.unit.code === 'MT');
    if (!!mt) return `${mt.net} ${mt.unit.code}`;

    let first = c.totals.find((t) => !!t.unit);
    if (!!first) `${first.net} ${first.unit.code}`;

    return `?`;
  }
}

export type SplitBookingForm = {
  numberOfBookings: number;
  containerLinks: ContainerLinkForm[];
};

export type ContainerLinkForm = {
  container: ContainerForm;
  bookingNumber: number;
  purchaseFolder: '*Skip*' | '*Create*' | number | null;
  items: string;
  saleContracts: string;
};
