<ng-container *ngIf="!!empty">
  <div class="row no-hover">
    <div class="col text-center">{{ noDataMessage }}</div>
  </div>
  <div class="row justify-content-end">
    <ng-container *ngTemplateOutlet="tableFooterTmpl"> </ng-container>
  </div>
</ng-container>
<div class="form-table" *ngIf="!empty">
  <div class="mobile-header header row">
    {{ title }}
  </div>
  <div class="header row">
    <ng-container *ngTemplateOutlet="tableHeaderTmpl"> </ng-container>
  </div>
  <div class="body">
    <ng-container *ngIf="!!formArray">
      <ng-container *ngFor="let control of formArrayControls; let i = index">
        <div class="row" *ngIf="!filter || !!filter(control)">
          <ng-container *ngTemplateOutlet="tableRowTmpl; context: { element: control, i: i }"> </ng-container>
        </div>
      </ng-container>
      <ng-container *ngTemplateOutlet="extraTableRowTmpl; context: {}"> </ng-container>
    </ng-container>

    <ng-container *ngIf="!!formGroup">
      <div class="row" *ngFor="let key of objectKeys(formGroup.controls)">
        <ng-container *ngTemplateOutlet="tableRowTmpl; context: { element: formGroup.controls[key], i: key }"> </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="footer row justify-content-end">
    <ng-container *ngTemplateOutlet="tableFooterTmpl"> </ng-container>
  </div>
</div>
