import { from, of } from 'rxjs';
import { VoucherSearchPaymentsComponent } from 'src/app/+modules/+accounting/voucher/voucher-search-payments/voucher-search-payments.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentEntry } from 'src/lib/newBackendTypes/payment';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const invoiceSearchPaymentsPrefill: prefillCallback<SearchPaymentsPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const paymentEntriesResponse = await api.run<ListResponse<PaymentEntry>>(endpoints.listPaymentEntries, { filters: { voucherId: id } }, null);
      const entries = paymentEntriesResponse.list;

      return { entries };
    })()
  );
};

const invoiceSearchPaymentsCheck: checkPrefillCallback<SearchPaymentsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.entries.length === 0) return of('Not payments found for this invoice.');
  return of(true);
};

const invoiceSearchPaymentsForm: openFormCallback<SearchPaymentsPrefill> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<SearchPaymentsPrefill, VoucherSearchPaymentsComponent>(VoucherSearchPaymentsComponent, {
    title: `Invoice ${prefill.entries[0].voucher.number} - Payments`,
    width: '80%',
    prefillValue: prefill,
    hideDefaultActions: true,
  });
};

const invoiceSearchPaymentsCallback: submitFormCallback<SearchPaymentsPrefill> = (delegate, id, form, prefill) => {
  return null;
};

export const invoiceSearchPaymentsPreset: DynamicFormConstant<SearchPaymentsPrefill> = {
  allowMultipleRows: false,
  getPrefill: invoiceSearchPaymentsPrefill,
  openForm: invoiceSearchPaymentsForm,
  checkPrefill: invoiceSearchPaymentsCheck,
  submitForm: invoiceSearchPaymentsCallback,
  endpoints: [endpoints.listPaymentEntries],
  entityType: SourceEntityType.INVOICE_KEY,
  label: 'Search Payments',
  title: 'Search Payments',
  value: DynamicFormType.INVOICE_SEARCH_PAYMENTS,
};

export type SearchPaymentsPrefill = {
  entries: PaymentEntry[];
};
