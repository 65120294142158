import { from, of } from 'rxjs';
import { ReverseVoucherForm, VoucherReverseComponent } from 'src/app/+modules/+accounting/voucher/voucher-reverse/voucher-reverse.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { FiscalCompany, InvoiceHeader, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { environment } from 'src/environments/environment';
import { dateFormatter } from 'src/lib/agGridFunctions';

const reverseVoucherPrefill: prefillCallback<ReverseVoucherPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const voucher = await api.run<InvoiceHeader>(endpoints.getVoucher, { filters: { id } }, null);
      const fiscalCompany = await api.run<FiscalCompany>(endpoints.getFiscalCompany, { filters: { companyId: voucher.companyId } }, null);
      return { voucher, permDateOperations: fiscalCompany.permDateOperations, permDateAccounting: fiscalCompany.permDateAccounting };
    })()
  );
};

const reverseVoucherCheck: checkPrefillCallback<ReverseVoucherPrefill> = (delegate, id, prefill) => {
  if (!prefill || !prefill.voucher) return of(false);
  if (prefill.voucher.reversalVoucher) return of('Unable to reverse voucher: this voucher has already been reversed');
  return of(true);
};

const reverseVoucherForm: openFormCallback<ReverseVoucherPrefill, ReverseVoucherForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<ReverseVoucherForm, VoucherReverseComponent>(VoucherReverseComponent, {
    title: `Reverse Voucher: ${prefill.voucher.number}`,
    initializer: (c) => {
      c.voucher = prefill.voucher;
      c.permDateOperations = dateFormatter(prefill.permDateOperations);
      c.permDateAccounting = dateFormatter(prefill.permDateAccounting);
    },
    prefillValue: prefill.voucher,
  });
};

const reverseVoucherCallback: submitFormCallback<ReverseVoucherPrefill, ReverseVoucherRequest> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  const router = delegate.getService('router');

  return from(
    new Promise<InvoiceHeader>((resolve, reject) => {
      (async () => {
        const reverseVoucher = await api.run<InvoiceHeader>(endpoints.reverseVoucher, { ...form, environmentName: environment.name }, null);
        if (reverseVoucher) return resolve(reverseVoucher);
        return reject('An error occurred, the voucher was not reversed.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Voucher successfully reversed. \nNumber: ${res.number}</div>`).subscribe((result) => {
          if (result) {
            const path = router.url.split(`${id}`)[0];
            if (res.isDeleted) {
              router.navigate([path]);
            } else {
              router.navigate([router.url]);
            }
          }
        });
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const reverseVoucherPreset: DynamicFormConstant<ReverseVoucherPrefill, ReverseVoucherForm> = {
  allowMultipleRows: true,
  checkPrefill: reverseVoucherCheck,
  openForm: reverseVoucherForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: reverseVoucherPrefill,
  label: 'Reverse Voucher',
  submitForm: reverseVoucherCallback,
  title: 'Reverse Voucher',
  value: DynamicFormType.REVERSE_VOUCHER,
  endpoints: [endpoints.getVoucher, endpoints.getFiscalCompany, endpoints.reverseVoucher],
  width: 600,
};

export type ReverseVoucherPrefill = {
  voucher: InvoiceHeader;
  permDateOperations: Date;
  permDateAccounting: Date;
};
export type ReverseVoucherRequest = { id: number; forceReversal?: boolean; environmentName?: string };
