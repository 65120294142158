import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { FutureMatching } from 'src/lib/newBackendTypes/futureMatching';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const reverseFutureMatchingPrefill: prefillCallback<ReverseFutureMatchingPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const reversableIds = Array.isArray(id) ? id : [id];
      const reversableItemsResponse = await api.run<ListResponse<FutureMatching>>(endpoints.listFuturMatchings, { filters: { futurMatchKey: reversableIds } }, null);
      const items = reversableItemsResponse.list;
      return { items };
    })()
  );
};

const reverseFutureMatchingCheck: checkPrefillCallback<ReverseFutureMatchingPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  const entryIdsToMatch = new Set(prefill.items);
  if (entryIdsToMatch.size < 1) return of('Unable to Reverse Future Matching: You need at least 1 Future Matching to reverse');
  if (prefill.items.length !== entryIdsToMatch.size) return of('Unable to Reverse Future Matching: some entries are not available');
  for (const item of prefill.items) {
    if (!item.purchaseFuturKey) return of(`Unable to Reverse Future Matching: Future Matching ${item.futurMatchKey} without purchase contract`);
    if (!item.saleFuturKey) return of(`Unable to Reverse Future Matching: Future Matching ${item.futurMatchKey} without sale contract`);
  }
  return of(true);
};

const reverseFutureMatchingForm: createFormCallback<ReverseFutureMatchingPrefill, ReverseFutureMatchingRequest> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Are you sure you want to Reverse Future Matching to ID(s): ${prefill.items ? prefill.items.map((item) => item.futurMatchKey).join(', ') + '?' : 'Unknown'}`,
    },
  ];
};

const reverseFutureMatchingCallback: submitFormCallback<ReverseFutureMatchingPrefill, ReverseFutureMatchingRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<FutureMatching[]>((resolve, reject) => {
      (async () => {
        const reversableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
        let futureMatchIds: number[] = [];
        for (let item of reversableItems) {
          futureMatchIds.push(item.futurMatchKey);
        }

        const request: ReverseFutureMatchingRequest = {
          futurMatchKeys: futureMatchIds,
        };

        const reversedMatching = await api.run<FutureMatching[]>(endpoints.reverseFuturMatching, request, null);
        if (reversedMatching) return resolve(reversedMatching);
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Future Matching(s) successfully reversed \nKey(s):\n${response.map((match) => `- ${match.futurMatchKey}`).join(`\n`)}</div>`
            );
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">$Unknown result. Please check if the Future Matching was reversed and try again if necessary.</div>`);
      })
  );
};

export const reverseFutureMatchingPreset: DynamicFormConstant<ReverseFutureMatchingPrefill, ReverseFutureMatchingRequest> = {
  allowMultipleRows: true,
  checkPrefill: reverseFutureMatchingCheck,
  createForm: reverseFutureMatchingForm,
  entityType: SourceEntityType.FUTURE_MATCHING,
  getPrefill: reverseFutureMatchingPrefill,
  label: 'Reverse Future Matching',
  submitForm: reverseFutureMatchingCallback,
  title: 'Reverse Future Matching',
  value: DynamicFormType.REVERSE_FUTURE_MATCHING,
  endpoints: [endpoints.listFuturMatchings, endpoints.reverseFuturMatching],
  width: 600,
};

export type ReverseFutureMatchingPrefill = { items: FutureMatching[] };

export type ReverseFutureMatchingRequest = { futurMatchKeys: number[] };
