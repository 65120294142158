import { Validators } from '@angular/forms';
import { DialogResult } from '@progress/kendo-angular-dialog';
import { from, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { CommonSteelTypeGroups, Item, SourceEntityType, UpdateItemRequest, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, createFormCallback, submitFormCallback } from './types';
import { updateItemPrefill } from './updateItem';

const updateItemMetalUnitPercentageForm: createFormCallback<Item, ItemMUPForm> = (delegate, id, prefill) => {
  const type = prefill.groupTypeId === CommonSteelTypeGroups.DEFAULT ? 'Standard Item' : 'Client Item';

  return [
    { type: 'Label', text: `${type}: ${prefill && prefill.name ? prefill.name : 'Unknown'}`, style: { 'margin-bottom': '5px' } },
    _fe('metalUnitPercentage', 'Metal Unit Percentage', { minimumFractionDigits: 0, maximumFractionDigits: 4, useGrouping: false, fieldType: 4 }, prefill.metalUnitPercentage, Validators.required),
  ];
};

const updateItemMetalUnitPercentageCheck: checkPrefillCallback<Item> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.archived === YN.Y) return of('Unable to update Item MUP: Item is archived.');
  return of(true);
};

const submitUpdateItemMetalUnitPercentage: submitFormCallback<Item, ItemMUPForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        const request: UpdateItemRequest = {
          id,
          reportingGroupId: prefill.reportingGroupId,
          analyticGroupId: prefill.analyticGroupId,
          metalUnitPercentage: result.metalUnitPercentage,
        };
        const updateItem = await api.run<Item>(endpoints.updateItem, request, null);
        if (updateItem) return resolve(updateItem);
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Item MUP updated successfuly.</div>`);
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Item MUP was updated and try again if necessary.</div>`);
      })
  );
};

export const updateItemMetalUnitPercentagePreset: DynamicFormConstant<Item, ItemMUPForm> = {
  allowMultipleRows: false,
  createForm: updateItemMetalUnitPercentageForm,
  getPrefill: updateItemPrefill,
  checkPrefill: updateItemMetalUnitPercentageCheck,
  entityType: SourceEntityType.ITEM_KEY,
  label: 'Update Metal Unit Percentage',
  submitForm: submitUpdateItemMetalUnitPercentage,
  title: 'Update Metal Unit Percentage',
  value: DynamicFormType.UPDATE_ITEM_MUP,
  endpoints: [endpoints.updateItem],
  width: 400,
};

export type ItemMUPForm = Pick<Item, 'id' | 'metalUnitPercentage'>;
