import { Component, OnInit } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { entityIdFormat } from 'src/lib/commonTypes';
import { Booking } from 'src/lib/newBackendTypes';

@Component({
  selector: 'logistics-booking-lookup',
  templateUrl: './booking-lookup.component.html',
})
export class BookingLookupComponent implements OnInit {
  constructor(private api: ThalosApiService, private spinnerService: SpinnerService) {
    this._selectedBooking = new BehaviorSubject(null);
    this.selectedBooking = this._selectedBooking.pipe(filter((b) => !!b));
    this.numberFormat = entityIdFormat();
  }

  bookingNumber: number;
  numberFormat: NumberFormatOptions;

  private _selectedBooking: BehaviorSubject<Booking>;
  selectedBooking: Observable<Booking>;

  ngOnInit(): void {}

  getBooking() {
    if (!this.bookingNumber) return;
    let rid = this.spinnerService.startRequest('Searching');
    this.api
      .rpc<Booking>(endpoints.getBooking, { filters: { number: this.bookingNumber } }, null, {
        hideErrorPopup: false,
      })
      .subscribe((booking) => {
        this.spinnerService.completeRequest(rid);
        if (booking) {
          this._selectedBooking.next(booking);
        }
      });
  }
}
