<form class="d-flex" style="height: 100%; flex-direction: column" [formGroup]="form">
  <div class="row">
    <vertical-field [name]="'Status'" class="col-lg-3 col-md-3 col-sm-6 col-12">
      <simple-dropdown [formControlName]="'chunkType'" [data]="chunkTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
    </vertical-field>
    <vertical-field name="Value Date" class="col-lg-2 col-md-3 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'valueDate'"></datepicker-wrapper>
    </vertical-field>
    <div class="col-md-5 col-12">
      <form class="row" style="margin: 0 -10px" *ngFor="let packing of packingTypesFormArray.controls; let i = index" [formGroup]="packing">
        <vertical-field name="Packing Type" class="col-sm-6 col-12">
          <simple-dropdown
            class="vertical-input-dropdown-with-btn"
            [formControlName]="'packingTypeId'"
            [data]="commonData.staticPackingTypes | async"
            [valueField]="'packingKey'"
            [textField]="'packingName'"
            [valuePrimitive]="true"
          ></simple-dropdown>
        </vertical-field>
        <vertical-field name="Quantity" class="col-sm-6 col-12">
          <numerictextbox-wrapper class="vertical-input-dropdown-with-btn" [fieldType]="3" [format]="intFormat" [formControlName]="'quantity'" [decimals]="0" [ignoreReadonlyMode]="true">
          </numerictextbox-wrapper>
          <button
            class="non-button"
            tabindex="-1"
            *ngIf="packingTypesFormArray.controls.length - 1 === i && !!packing.value.packingTypeId"
            style="margin: auto 2px; cursor: pointer"
            (click)="addPacking()"
            type="submit"
          >
            <i class="fas fa-plus-circle"></i>
          </button>
          <i *ngIf="packingTypesFormArray.controls.length - 1 !== i" tabindex="-1" style="margin: auto 2px; cursor: pointer" class="k-icon k-i-trash" (click)="removePacking(i)"></i>
        </vertical-field>
      </form>
    </div>
  </div>
  <div class="row" style="padding: 10px 0px">
    <checkbox-wrapper [label]="'Replace subshipments in booking(s)'" [formControlName]="'linkChildShipment'" class="col-md-4 col-sm-6 col-12"></checkbox-wrapper>
  </div>
</form>
