import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { PaymentTerm, YN } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getPaymentTermListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<PaymentTerm> = {
    where: { archived: { equals: YN.N }, name: { contains: new VariableType('name') } },
    orderBy: [{ name: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getPaymentTermListVariablesGraphql() {
  const variables: GraphqlVariables<PaymentTerm> = { name: 'String' };
  return variables;
}

export const listPaymentTermGraphqlRequest = (args: GraphqlRequest<PaymentTerm>): GraphqlArgsRequest<PaymentTerm> => {
  return {
    __aliasFor: ExistingGraphql.paymentTerms,
    __args: args,
    id: true,
    name: true,
  };
};
