<form [formGroup]="form">
  <div class="row" style="max-width: 550px">
    <vertical-field name="QP Status" class="col-md-4 col-sm-6 col-12">
      <simple-dropdown [data]="qpStatuses" [valuePrimitive]="true" [valueField]="'value'" [textField]="'label'" formControlName="qpStatus"> </simple-dropdown>
    </vertical-field>
    <vertical-field name="Notes" class="col-12">
      <textarea-wrapper [formControlName]="'notes'" [showCounter]="showCounter" [maxlength]="maxlength"> </textarea-wrapper>
    </vertical-field>
  </div>
</form>
