import { from, lastValueFrom, map, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { InvoiceHeader, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const voucherPrefillCallback: prefillCallback<InvoiceHeader> = (delegate, id) => {
  let api = delegate.getService('api');
  id = Array.from(new Set(id));
  return api.rpc<InvoiceHeader>(endpoints.getVoucher, { filters: { id: id[0] } }, null);
};

const setIVADeclarationDateForm: createFormCallback<SetIVADeclarationDatePrefill> = (delegate, id) => {
  return [_fe('ivaDeclarationDate', 'Invoice IVA Declaration Date On', 'Date', null, [], false)];
};

const setIVADeclarationDateSubmit: submitFormCallback<SetIVADeclarationDatePrefill> = (delegate, ids, result) => {
  if (!ids || ids.length === 0) return of(null);
  let api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  if (!Array.isArray(ids)) ids = [ids];
  ids = Array.from(new Set(ids));

  return from(
    Promise.all(
      ids.map((id) =>
        lastValueFrom(
          api.rpc<InvoiceHeader>(
            endpoints.setIVADeclarationDate,
            {
              id,
              ivaDeclarationDate: result.ivaDeclarationDate,
            },
            null
          )
        )
      )
    )
  ).pipe(
    map(() =>
      prompt.htmlDialog('Success', `<div style="white-space: pre">IVA Declaration Date successfully updated.</div>`).pipe(
        map((result) => {
          return result || 'Close';
        })
      )
    )
  );
};

export const setIVADeclarationDatePreset: DynamicFormConstant<SetIVADeclarationDatePrefill> = {
  allowMultipleRows: true,
  createForm: setIVADeclarationDateForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: voucherPrefillCallback,
  label: 'Set IVA Declaration Date',
  submitForm: setIVADeclarationDateSubmit,
  title: 'Set IVA Declaration Date',
  value: DynamicFormType.SET_IVA_DECLARATION_DATE,
  endpoints: [endpoints.getVoucher, endpoints.setIVADeclarationDate],
  width: 400,
};

export type SetIVADeclarationDatePrefill = Pick<InvoiceHeader, 'ivaDeclarationDate'>;
