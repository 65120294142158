import { DialogResult } from '@progress/kendo-angular-dialog';
import { from } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ItemComponent, ItemForm } from 'src/app/shared/item/item.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { CommonSteelTypeGroups, Item, SourceEntityType, UpdateItemRequest } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const updateItemPrefill: prefillCallback<Item> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const item = await api.run<Item>(endpoints.getItem, { filters: { id } }, null);

      return item;
    })()
  );
};

const updateItemForm: openFormCallback<Item, ItemForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  const type = prefill.groupTypeId === CommonSteelTypeGroups.DEFAULT ? 'royce' : 'client';
  return selector.openForm<ItemForm, ItemComponent>(ItemComponent, {
    title: type === 'client' ? 'Update Client Item' : 'Update Standard Item',
    prefillValue: prefill,
    initializer: (c) => {
      c.type = type;
    },
    maxWidth: 400,
  });
};

const submitUpdateItem: submitFormCallback<Item, ItemForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        const request: UpdateItemRequest = {
          id,
          name: result.name,
          reportingGroupId: result.reportingGroup ? result.reportingGroup.id : null,
          analyticGroupId: result.analyticGroup ? result.analyticGroup.id : null,
          metalUnitPercentage: result.metalUnitPercentage,
        };
        const updateItem = await api.run<Item>(endpoints.updateItem, request, null);
        if (updateItem) return resolve(updateItem);
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Item updated successfuly.</div>`);
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Item was updated and try again if necessary.</div>`);
      })
  );
};

export const updateItemPreset: DynamicFormConstant<Item, ItemForm> = {
  allowMultipleRows: false,
  openForm: updateItemForm,
  entityType: SourceEntityType.ITEM_KEY,
  getPrefill: updateItemPrefill,
  label: 'Update Item',
  submitForm: submitUpdateItem,
  title: 'Update Item',
  value: DynamicFormType.UPDATE_ITEM,
  endpoints: [endpoints.getItem, endpoints.updateItem],
  width: 400,
};
