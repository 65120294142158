import { Component, OnInit } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TypedFormControl } from 'src/lib/typedForms';

@Component({
  selector: 'text-prompt',
  templateUrl: './text-prompt.component.html',
  styleUrls: ['./text-prompt.component.scss'],
})
export class TextPromptComponent implements OnInit {
  formControl: TypedFormControl<string>;

  public label: string;
  get value() {
    return this.formControl.value;
  }
  set value(v: string) {
    this.formControl.setValue(v);
  }

  set validators(v: ValidatorFn | ValidatorFn[]) {
    this.formControl.setValidators(v);
    this.formControl.markAsTouched();
  }

  get valid() {
    return this.formControl.valid;
  }

  constructor() {
    this.formControl = new TypedFormControl('');
  }

  ngOnInit(): void {}
}
