import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
})
export class FormListComponent implements OnInit {
  @Input()
  formArray: UntypedFormArray;

  @Input()
  noDataMessage: string;

  @ContentChild('lineContent', { static: false })
  lineContentTmpl: TemplateRef<any>;

  @ContentChild('listFooter', { static: false })
  listFooterTmpl: TemplateRef<any>;

  @ContentChild('lineHeader', { static: false })
  lineHeaderTmpl: TemplateRef<any>;

  @ContentChild('listHeader', { static: false })
  listHeaderTmpl: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
