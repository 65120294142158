import { DialogResult } from '@progress/kendo-angular-dialog';
import { round } from 'lodash';
import { from, of } from 'rxjs';
import { CreatePriceFixationComponent } from 'src/app/+modules/+hedging/create-price-fixation/create-price-fixation.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC, toBradyUTCDate } from 'src/lib/helperFunctions';
import { Contact, ContractType, PhysicalContract, SourceEntityType } from 'src/lib/newBackendTypes';
import { ContractLineFixationData } from 'src/lib/newBackendTypes/contractLineFixationData';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { ApplyResponse, ApplyUnapplyPriceFixationRequest, CreatePriceFixationRequest, FixationSendEmailOption, PcPriceFixing } from 'src/lib/newBackendTypes/priceFixation';
import { ShipmentFixations } from 'src/lib/newBackendTypes/shipmentFixations';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, prefillCallback, submitFormCallback } from './types';

const createPriceFixationPrefill: prefillCallback<CreatePriceFixationPrefill> = (delegate, id, column) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const contractLineResponse = await api.run<ListResponse<ContractLineFixationData>>(endpoints.listContractLineFixationData, { filters: { id } });
      const contractLine = contractLineResponse.list[0];
      const currencyConversions = await api.run<ExchangeRateInfo[]>(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) });

      const shipmentsResponse = await api.run<ListResponse<ShipmentFixations>>(endpoints.listShipmentFixations, { filters: { elementKey: id } });
      const shipments = shipmentsResponse.list;

      const contract = await api.run<PhysicalContract>(endpoints.getContract, { filters: { number: contractLine.contractNumber } });
      const counterparty = contract.counterparty;

      return { contractLine, shipments, currencyConversions, counterparty };
    })()
  );
};

const createPriceFixationCheck: checkPrefillCallback<CreatePriceFixationPrefill> = (delegate, id, prefill) => {
  const formatter = delegate.getService('dataFormatter');
  if (!prefill) return of(false);
  const quantity = formatter.roundQuantity(prefill.contractLine.quantity * (1 + prefill.contractLine.tolerance / 100), prefill.contractLine.contractQtyUnitKey);
  let sumQuantityFixed = formatter.roundQuantity(prefill.contractLine.sumQuantityFixed, prefill.contractLine.contractQtyUnitKey);
  if (sumQuantityFixed >= quantity) return of('Unable to add a manual fixation: contract line has already been fully fixed.');
  return of(true);
};

const createPriceFixationForm: openFormCallback<CreatePriceFixationPrefill, PriceFixationForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<PriceFixationForm, CreatePriceFixationComponent, CreatePriceFixationPrefill>(CreatePriceFixationComponent, {
    title: `Create Price Fixation For Contract: ${prefill ? prefill.contractLine.contractNumber + '/' + prefill.contractLine.lineNumber : 'Unknown'}`,
    prefillValue: prefill,
    maxWidth: '840px',
  });
};

const submitPriceFixation: submitFormCallback<CreatePriceFixationPrefill, PriceFixationForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        const request: CreatePriceFixationRequest = {
          contractId: prefill.contractLine.contractId,
          valuationId: prefill.contractLine.valuationId,
          quantityFixed: result.quantityFixed,
          fixDate: result.fixDate,
          termPrice: result.termPrice,
          fixedContractPrice: result.fixedContractPrice,
          elementNumber: prefill.contractLine.lineNumber,
          leftOverFutQtyComment: result.leftOverFutQtyComment,
          fixationRate: result.fixationRate,
          metalUnits: result.metalUnits,
          metalUnitsPercentage: result.metalUnitsPercentage,
          sendEmail: result.applyShipmentsIds && result.applyShipmentsIds.length > 0 ? FixationSendEmailOption.DO_NOT_SEND_EMAIL : result.sendEmail,
        };
        const fixationResponse = await api.run<PcPriceFixing & { contractNumber: number; contractUnitAbbr: string }>(endpoints.createPriceFixation, request, null);
        if (fixationResponse) return resolve(fixationResponse);
        return reject('Unknown result. Please check if the contract line was fixed and try again if necessary.');
      })();
    })
      .then(async (res: PcPriceFixing & { contractNumber: number; contractUnitAbbr: string }) => {
        if (result.applyShipmentsIds.length !== 0) {
          const applyRequest: ApplyUnapplyPriceFixationRequest = {
            id: res.id,
            shipmentIdArr: result.applyShipmentsIds,
            metalUnits: result.metalUnits,
            metalUnitsPercentage: result.metalUnitsPercentage,
            sendEmail: result.sendEmail,
          };
          const applyFixationResponse = await api.run<ListResponse<ApplyResponse>>(endpoints.applyPriceFixations, applyRequest, null);
          if (applyFixationResponse && applyFixationResponse.list) {
            const shipments = applyFixationResponse.list.map((list) => {
              if (!list.shipments) return null;
              return list.shipments.map((s) => `- ${s.id}`).join('\n');
            });
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractNumber + '/' + res.elementNumber} has been created (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd has been Applied to the following shipment(s): \n${shipments && shipments.length > 0 ? shipments.map((s) => `${s}`).join('\n') : 'Unknown'}</div>`
            );
          } else
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractNumber + '/' + res.elementNumber} has been created (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd could NOT be Applied to the following shipment(s): \n${applyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}</div>`
            );
        }
        return prompt.htmlDialog(
          'Success',
          `<div style="white-space: pre">The fixation for Contract ${res.contractNumber + '/' + res.elementNumber} has been created (${round(res.quantityFixed, 3)} ${res.contractUnitAbbr})</div>`
        );
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createPriceFixationPreset: DynamicFormConstant<CreatePriceFixationPrefill, PriceFixationForm> = {
  allowMultipleRows: false,
  checkPrefill: createPriceFixationCheck,
  openForm: createPriceFixationForm,
  entityType: SourceEntityType.CONTRACT_LINE_ID,
  getPrefill: createPriceFixationPrefill,
  label: 'Create Price Fixation',
  submitForm: submitPriceFixation,
  title: 'Create Price Fixation',
  value: DynamicFormType.CREATE_PRICE_FIXATION,
  endpoints: [
    endpoints.listContractLineFixationData,
    endpoints.getDailyExchangeRates,
    endpoints.getContract,
    endpoints.listShipmentFixations,
    endpoints.createPriceFixation,
    endpoints.applyPriceFixations,
  ],
  width: 840,
};

export type CreatePriceFixationPrefill = {
  contractLine: ContractLineFixationData;
  shipments: ShipmentFixations[];
  currencyConversions: ExchangeRateInfo[];
  counterparty: Contact;
};
export type PriceFixationForm = Omit<CreatePriceFixationRequest, 'valuationId' | 'contractId' | 'elementNumber'> & {
  contractType?: ContractType;
  market?: string;
  quantityToFixBreakdown?: string;
  fixedContractPrice: number;
  quantityToFix?: number;
  shipments?: ShipmentFixations[];
  applyShipmentsIds: number[];
  sendEmail: FixationSendEmailOption;
};
