import { Component, OnInit } from '@angular/core';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: '[custom-header]',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomGridHeaderComponent implements OnInit {
  name: string;

  params: IHeaderParams;

  sortIndex: number;
  sortDirection: string | null = '';
  hasFilter: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  agInit(params: IHeaderParams) {
    this.name = params.displayName;

    this.params = params;

    params.column.getSortIndex();

    params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));
    params.column.addEventListener('filterChanged', this.onFilterChanged.bind(this));
  }

  columnClicked(ev: Event) {
    if (!this.params) return;
    if (ev['altKey']) {
      if (!this.params.column.isAllowRowGroup()) return;
      const groupColumns = [...(this.params.columnApi.getRowGroupColumns() ?? [])];
      if (!this.params.column.isRowGroupActive()) {
        groupColumns.push(this.params.column);
      } else {
        let index = groupColumns.findIndex((c) => c === this.params.column);
        if (index > -1) {
          groupColumns.splice(index, 1);
        }
      }
      this.params.columnApi.setRowGroupColumns(groupColumns);

      const groupCol = this.params.columnApi.getColumn('ag-Grid-AutoColumn');
      if (!!groupCol) {
        this.params.columnApi.autoSizeColumn(groupCol, false);
      }
    } else {
      this.params.progressSort(!!ev['shiftKey']);
    }
  }

  menuClicked(ev: Event) {
    if (!this.params) return;
    this.params.showColumnMenu(ev.target as HTMLElement);
  }

  onSortChanged() {
    if (!this.params) return;
    this.sortDirection = this.params.column.getSort();
    if (this.params.column.isSorting() && this.params.columnApi.getColumnState().filter((cs) => cs.sort !== null).length > 1) {
      this.sortIndex = this.params.column.getSortIndex() + 1;
    } else {
      this.sortIndex = 0;
    }
  }

  onFilterChanged() {
    if (!this.params) return;
    this.hasFilter = this.params.column.isFilterActive();
  }

  getSortClass(dir: string) {
    if (this.sortDirection !== dir) return ['ag-hidden'];
    else return [];
  }
}
