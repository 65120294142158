import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CountdownConfig } from 'ngx-countdown';
import { SocketIOService, ThalosMessage } from 'src/app/core/services/socket.io.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'it-thalos-message-toast',
  templateUrl: './thalos-message-toast.component.html',
  styleUrls: ['./thalos-message-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThalosMessageToastComponent {
  thalosMessageData: ThalosMessage | null = null;
  config: CountdownConfig = {};

  sanitizedMessage: SafeHtml;

  constructor(private socketIOService: SocketIOService, private sanitizer: DomSanitizer) {
    this.thalosMessageData = this.socketIOService.thalosMessageData;
    this.sanitizedMessage = this.sanitizer.bypassSecurityTrustHtml(this.thalosMessageData.message);
    if (this.thalosMessageData.time) this.config.leftTime = this.thalosMessageData.time; // Unit: Seconds
  }
}
