import { EnumType, VariableType } from 'json-to-graphql-query';
import { CommonContactTypes, Contact, YN } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';
import { GraphqlVariables, GraphqlArgsRequest, GraphqlRequest } from '../graphqlRequestTypes';
import { getBasicGraphqlArgs } from '../graphQlFunctions';

export function getContactListArgsGraphql(contactTypes?: CommonContactTypes[], take: number = 20, skipArchived: YN = YN.Y, companyId?: number) {
  const args: GraphqlRequest<Contact> = {
    where: { id: { not: { equals: 0 } }, displayName: { contains: new VariableType('displayName') } },
    orderBy: [{ displayName: new EnumType('asc') }],
  };
  if (skipArchived === YN.Y) args.where.archived = { not: { equals: skipArchived } };
  if (contactTypes && contactTypes.length > 0) args.where.contactTypeAssignments = { some: { contactType: { is: { id: { in: contactTypes } } } } };
  if (companyId) args.where.fiscalCompanyId = { equals: companyId };

  return take === 0 ? args : { ...args, take };
}

export function getContactListVariablesGraphql() {
  const variables: GraphqlVariables<Contact> = { displayName: 'String' };
  return variables;
}

export const basicContactsGraphqlRequest = (args: GraphqlRequest<Contact>): GraphqlArgsRequest<Contact> => {
  return { __aliasFor: ExistingGraphql.alves, __args: args, id: true, displayName: true };
};

export const listContactGraphqlRequest: GraphqlArgsRequest<Contact> = {
  ...basicContactsGraphqlRequest(getBasicGraphqlArgs()),
  id: true,
  displayName: true,
  firstName: true,
  lastName: true,
  category: true,
  paymentFrequency: true,
  city: true,
  contactTypeAssignments: {
    contactType: {
      name: true,
    },
  },
  methods: {
    isDefault: true,
    value: true,
  },
  baseCountry: {
    name: true,
  },
  finOperator: {
    displayName: true,
  },
};

export const listContactVouchersGraphqlRequest: GraphqlArgsRequest<Contact> = {
  ...basicContactsGraphqlRequest(getContactListArgsGraphql()),
  id: true,
  displayName: true,
  payFromAccount: {
    id: true,
    iden: true,
    ownerCode: true,
  },
  receivablesAccount: {
    accKey: true,
    idenLong: true,
    companyId: true,
    currKey: true,
    accOption: true,
  },
  payablesAccount: {
    accKey: true,
    idenLong: true,
    companyId: true,
    currKey: true,
    accOption: true,
  },
};

export const listCustomContactsList = (
  args: Omit<GraphqlArgsRequest<Contact>, '__aliasFor' | '__args'>,
  contactTypes?: CommonContactTypes[],
  take: number = 20,
  skipArchived: YN = YN.Y,
  companyId?: number
): GraphqlArgsRequest<Contact> => {
  return { ...basicContactsGraphqlRequest(getContactListArgsGraphql(contactTypes, take, skipArchived, companyId)), ...args };
};
