<div [formGroup]="form" *ngIf="!!form">
  <ng-container *ngFor="let element of _formElements">
    <ng-container *ngTemplateOutlet="dynamicFormOuter; context: { $implicit: element, parent: form }"> </ng-container>
  </ng-container>
</div>

<ng-template #dynamicFormField let-el let-formControl="formControl">
  <ng-container [ngSwitch]="el.type">
    <ng-template [ngSwitchCase]="'Space'">
      <div class="col"></div>
    </ng-template>
    <ng-template [ngSwitchCase]="'Text'">
      <vertical-field [name]="el.label" class="col">
        <input-wrapper [formControl]="formControl" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"></input-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'TextArea'">
      <vertical-field [name]="el.label" class="col">
        <textarea-wrapper [formControl]="formControl" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"></textarea-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'Number'">
      <vertical-field [name]="el.label" class="col">
        <numerictextbox-wrapper
          [formControl]="formControl"
          style="flex-grow: 1"
          [fieldType]="el.fieldType"
          [decimals]="el.decimals"
          [format]="el.format"
          [readonly]="el.readonly"
          [ignoreReadonlyMode]="el.ignoreReadonlyMode"
        >
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0; width: auto">{{ el.unitLabel }}</div>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'Date'">
      <vertical-field [name]="el.label" class="col">
        <datepicker-wrapper [formControl]="formControl" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"></datepicker-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'DynamicDropdown'">
      <vertical-field [name]="el.label" class="col">
        <dropdown-wrapper [formControl]="formControl" [dropdownConfig]="el.config" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"> </dropdown-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'DynamicGraphqlDropdown'">
      <vertical-field [name]="el.label" class="col">
        <dropdown-graphql [formControl]="formControl" [dropdownConfig]="el.config" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"></dropdown-graphql>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'StaticDropdown'">
      <vertical-field [name]="el.label" class="col">
        <simple-dropdown
          [formControl]="formControl"
          [data]="el.data"
          [valueField]="el.valueField"
          [textField]="el.labelField"
          [valuePrimitive]="true"
          [readonly]="el.readonly"
          [ignoreReadonlyMode]="el.ignoreReadonlyMode"
          [secondaryTextField]="el.secondaryTextField"
        >
        </simple-dropdown>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'DynamicMultiselect'">
      <vertical-field [name]="el.label" class="col">
        <multiselect-wrapper [formControl]="formControl" [dropdownConfig]="el.config" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"> </multiselect-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'DynamicGraphqlMultiselect'">
      <vertical-field [name]="el.label" class="col">
        <multiselect-graphql [formControl]="formControl" [dropdownConfig]="el.config" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"> </multiselect-graphql>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'StaticMultiselect'">
      <vertical-field [name]="el.label" class="col">
        <simple-multiselect [formControl]="formControl" [data]="el.data" [readonly]="el.readonly" [ignoreReadonlyMode]="el.ignoreReadonlyMode"> </simple-multiselect>
      </vertical-field>
    </ng-template>
    <ng-template [ngSwitchCase]="'Checkbox'">
      <vertical-field [name]="!!el.inLineLabel ? '' : el.label" class="col d-flex justify-content-center" *ngIf="!el.hidden">
        <checkbox-wrapper
          class="d-flex justify-content-center"
          [label]="!!el.inLineLabel ? el.label : ''"
          [formControl]="formControl"
          [valueMask]="el.valueMask"
          [readonly]="el.readonly"
          [ignoreReadonlyMode]="el.ignoreReadonlyMode"
        >
        </checkbox-wrapper>
      </vertical-field>
    </ng-template>
    <ng-template *ngSwitchDefault> </ng-template>
  </ng-container>
</ng-template>

<ng-template #dynamicFormOuter let-element let-parent="parent">
  <ng-container *ngIf="element.type === 'Line'">
    <div class="row flex-column flex-md-row" style="flex-grow: 1">
      <ng-container *ngFor="let el of element.formElements">
        <ng-container *ngTemplateOutlet="dynamicFormField; context: { $implicit: el, formControl: parent.get(el.field) }"> </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="element.type === 'Label'">
    <div style="margin-left: 7px" [ngStyle]="element.style || {}">
      <span [innerHTML]="element.text"></span>
    </div>
  </ng-container>
  <ng-container *ngIf="element.type === 'SubForm'">
    <div class="form-divider-line"></div>
    <div>{{ element.label }}</div>
    <form-error-label [control]="parent.get(element.field)"></form-error-label>
    <div class="d-flex" style="width: 100%" *ngFor="let control of parent.get(element.field).controls; let i = index">
      <ng-container *ngFor="let subEl of element.formElements">
        <ng-container *ngTemplateOutlet="dynamicFormOuter; context: { $implicit: subEl, parent: control }"> </ng-container>
      </ng-container>
      <vertical-field style="flex-grow: 0; width: 30px">
        <button type="button" kendoButton (click)="removeSubFormLine(parent.get(element.field), i)" [icon]="'trash'"></button>
      </vertical-field>
    </div>
    <div class="d-flex" style="flex-direction: row-reverse">
      <button type="button" kendoButton (click)="addSubFormLine(parent.get(element.field), element)">Add {{ element.label }}</button>
    </div>
    <div class="form-divider-line"></div>
  </ng-container>
  <ng-container *ngIf="element.type !== 'Line' && element.type !== 'SubForm' && element.type !== 'Label'">
    <div class="row" [ngClass]="{ 'd-none': element.hidden }">
      <ng-container *ngTemplateOutlet="dynamicFormField; context: { $implicit: element, formControl: parent.get(element.field) }"> </ng-container>
    </div>
  </ng-container>
</ng-template>
