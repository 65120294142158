<kendo-numerictextbox
  class="numerictextbox-wrapper"
  [ngClass]="{
    'numerictextbox-wrapper--amount': fieldType === 1,
    'numerictextbox-wrapper--price': fieldType === 2,
    'numerictextbox-wrapper--quantity': fieldType === 3,
    'numerictextbox-wrapper--percentage': fieldType === 4,
    readonly: readonly
  }"
  [decimals]="decimals"
  [min]="min"
  [max]="max"
  [format]="format"
  [placeholder]="placeholder"
  [(ngModel)]="_value"
  [spinners]="false"
  [step]="0"
  [autoCorrect]="true"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  #input
  (scroll)="input.blur()"
  [readonly]="readonly"
>
</kendo-numerictextbox>
<button kendoButton [icon]="'calculator'" type="button" (click)="openCalculator()" *ngIf="showCalculator && !readonly"></button>
