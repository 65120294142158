import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { DatePickerComponent, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { fromBradyDateOrNull, toBradyUTCDate } from 'src/lib/helperFunctions';

@Component({
  selector: 'datepicker-editor',
  template: ` <kendo-datepicker
    #input
    style="height:26px"
    [style.width]="params.column.actualWidth + 'px'"
    [max]="max"
    [min]="min"
    [format]="format"
    [incompleteDateValidation]="true"
    [rangeValidation]="true"
    [twoDigitYearMax]="70"
    [(value)]="value"
    [placeholder]="'mm/dd/yy'"
    [formatPlaceholder]="'mm/dd/yy'"
  >
  </kendo-datepicker>`,
})
export class DatePickerEditor implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  min = new Date(2000, 0, 1);
  max = new Date(2099, 11, 31);
  format: FormatSettings = { displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy' };
  value: Date;

  public params: any;

  @ViewChild('input') input: DatePickerComponent;

  constructor() {}

  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this.input) this.input.focus();
    });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value instanceof Date ? this.params.value : fromBradyDateOrNull(this.params.value);
  }

  ngOnDestroy() {}

  getValue(): any {
    return toBradyUTCDate(this.value);
  }

  isPopup(): boolean {
    return true;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {}
}
