import { Subset } from '../generics';
import { City } from './city';
import { ContainerType } from './containerType';
import { Associations, PhysicalContract } from './contract';
import { ContractPricing, CreatePricingRequest, UpdatePricingRequest } from './contractPricing';
import { Country } from './country';
import { DeliveryType } from './deliveryType';
import { YN } from './enums';
import { Item } from './item';
import { MarketValuationHeader } from './marketValuation';
import { MetalControlGroup } from './metalControlGroup';
import { PcPriceFixing } from './priceFixation';
import { PropertyDocument } from './propertyDocument';

export enum ShipmentPeriodType {
  DELIVERY = 124028,
  SHIPMENT = 100106,
}

export enum LinePriceType {
  FIXED = 1,
  FORMULA = 2,
}

export type BaseContractLine = {
  id: number;
  lineNumber: number;
  originCountryId: number;
  contractId: number;
  associationId: Associations;
  itemId: number;
  quantity: number;
  hedgeableQuantity: number;
  quantityDeclared: number;
  finishQuantity: number;
  finished: YN;
  price: number;
  destinationId: number;
  incotermPlaceId: number;
  incotermId: number;
  marketPrice: number;
  modifiedBy: string;
  modifyDate: Date;
  versionNb: number;
  shipmentPeriodStartMonth: number;
  shipmentPeriodType: number;
  shipmentPeriodStart: Date;
  shipmentPeriodEnd: Date;
  transitTime: string | null;
  costPrice: number;
  premium: number;
  creationDate: Date;
  fullPremium: number;
  createdBy: number;
  updatedBy: number | null;
  updateDate: Date | null;
  containerTypeId: number;
  metalControlGroupId: number | null;
  priceCompletelyFixed: YN;
  clientItemId: number;
  priceType: LinePriceType;
  metalPercentage: number;
  givenFX: number | null;
  recoveryPercentage: number | null;
  mtmValuationId?: number | null;
  mtmDifferential?: number | null;
  hedgePrice?: number | null;
};

export type ContractLine = BaseContractLine & {
  pricing?: ContractPricing[];
  marketValuation?: MarketValuationHeader;
  containerTypes: ContainerType[];
  contract?: PhysicalContract;
  deliveryType?: DeliveryType;
  incotermPlace?: City;
  originCountry?: Country;
  item?: Item;
  clientItem?: Item;
  destination?: City;
  loadingPlace?: City;
  metalControlGroup?: MetalControlGroup;
  priceFixations?: PcPriceFixing[];
  shipmentsAsPurchase?: PropertyDocument[];
  shipmentsAsSale?: PropertyDocument[];
};

export type BaseLineRequest = Pick<
  BaseContractLine,
  | 'originCountryId'
  | 'itemId'
  | 'clientItemId'
  | 'quantity'
  | 'destinationId'
  | 'incotermPlaceId'
  | 'shipmentPeriodType'
  | 'shipmentPeriodStart'
  | 'shipmentPeriodEnd'
  | 'premium'
  | 'priceType'
  | 'metalControlGroupId'
  | 'metalPercentage'
> & {
  containerTypes: Pick<ContainerType, 'id'>[];
};

export type BaseUpdateLineRequest = Partial<BaseLineRequest> & Pick<ContractLine, 'id'>;

type CreateFixedPriceLineRequest = BaseLineRequest &
  Pick<ContractLine, 'price' | 'mtmValuationId' | 'mtmDifferential' | 'hedgePrice'> & {
    priceType: LinePriceType.FIXED;
  };

type CreateFormulaPriceLineRequest = BaseLineRequest &
  Pick<ContractLine, 'id' | 'mtmValuationId' | 'mtmDifferential' | 'hedgePrice'> & {
    priceType: LinePriceType.FORMULA;
    pricing: CreatePricingRequest[];
  };

type UpdateFixedPriceLineRequest = BaseUpdateLineRequest &
  Pick<ContractLine, 'price' | 'mtmValuationId' | 'mtmDifferential' | 'hedgePrice'> & {
    priceType: LinePriceType.FIXED;
  };

type UpdateFormulaPriceLineRequest = BaseUpdateLineRequest &
  Pick<ContractLine, 'id' | 'mtmValuationId' | 'mtmDifferential' | 'hedgePrice'> & {
    priceType: LinePriceType.FORMULA;
    pricing: (CreatePricingRequest | UpdatePricingRequest)[];
  };

export type CreateContractLineRequest = CreateFixedPriceLineRequest | CreateFormulaPriceLineRequest;
export type UpdateContractLineRequest = UpdateFixedPriceLineRequest | UpdateFormulaPriceLineRequest;

export type FinishContractLineRequest = Subset<BaseContractLine, 'id', 'finished'>;
