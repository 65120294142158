export class DocumentMetadata {
  documentId!: number;
  type!: MetadataType;
  value!: string;
  document?: Document;
}

export enum MetadataType {
  DOCUMENT_TYPE = 1,
  COMMENT = 2,
}
