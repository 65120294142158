import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { AccountOption, GlAccountView, YN } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getGlAccountListVoucherHeaderArgsGraphql(companyId: number, accountOption: AccountOption.SUPPLIER_ACCOUNT | AccountOption.CLIENT_ACCOUNT | null, take: number = 20) {
  const args: GraphqlRequest<GlAccountView> = {
    where: {
      accKey: { not: { equals: 0 } },
      companyId: { equals: companyId },
      accOption: { equals: accountOption },
      archived: { equals: YN.N },
      idenLong: { contains: new VariableType('idenLong') },
      leafAccount: { equals: YN.Y },
    },
    orderBy: [{ idenLong: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getGlAccountListVoucherLinesArgsGraphql(companyId: number, currKey: number, take: number = 20) {
  const args: GraphqlRequest<GlAccountView> = {
    where: {
      accKey: { not: { equals: 0 } },
      companyId: { equals: companyId },
      currKey: { equals: currKey },
      archived: { equals: YN.N },
      idenLong: { contains: new VariableType('idenLong') },
      leafAccount: { equals: YN.Y },
    },
    orderBy: [{ idenLong: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getGlAccountListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<GlAccountView> = {
    where: {
      accKey: { not: { equals: 0 } },
      archived: { equals: YN.N },
      idenLong: { contains: new VariableType('idenLong') },
      leafAccount: { equals: YN.Y },
    },
    orderBy: [{ idenLong: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getGlAccountListVariablesGraphql() {
  const variables: GraphqlVariables<GlAccountView> = { idenLong: 'String' };
  return variables;
}

export const listGlAccountGraphqlRequest = (args: GraphqlRequest<GlAccountView>): GraphqlArgsRequest<GlAccountView> => {
  return {
    __aliasFor: ExistingGraphql.glAccounts,
    __args: args,
    accKey: true,
    idenLong: true,
    companyId: true,
    currKey: true,
    accOption: true,
  };
};
