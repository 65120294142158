import { of } from 'rxjs';
import { ContractLineForm, getBaseLineRequest } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { ContractLineEditorComponent } from 'src/app/shared/contract-line-editor/contract-line-editor.component';
import { endpoints } from 'src/lib/apiEndpoints';
import {
  ContractClass,
  ContractFormPricingToUpdateContractPricing,
  ContractPricingToContractFormPricing,
  PhysicalContract,
  SourceEntityType,
  UpdateContractLineRequest,
  UpdatePhysicalContractRequest,
} from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const contractLineExecPrefill: prefillCallback<PhysicalContract> = (delegate, id) => {
  if (!id) return of('Contract Line Id not found');
  const api = delegate.getService('api');

  return api.rpc<PhysicalContract>(endpoints.getContract, { filters: { lineId: id } }, null);
};

export const openEditContractLineExecForm: openFormCallback<PhysicalContract, ContractLineForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  if (!prefill || !prefill.lines || !prefill.lines?.length) return of('Close');
  const line = prefill.lines.find((l) => l.id === id);
  if (!line) return of('Close');

  const commonData = delegate.getService('commonData');
  const units = commonData.staticUnits.value;
  const currencies = commonData.staticCurrencies.value;
  const products = commonData.staticProducts.value;
  const incoterms = commonData.staticIncoterms.value;

  const quantityUnit = units.find((u) => u.unitId === prefill.quantityUnitId) || null;
  const priceUnit = units.find((u) => u.unitId === prefill.priceUnitId) || null;
  const currency = currencies.find((c) => c.id === prefill.currencyId) || null;
  const premiumUnit = units.find((u) => u.unitId === prefill.premiumUnitId) || null;
  const premiumCurrency = currencies.find((c) => c.id === prefill.premiumUnitId) || null;

  const product = products.find((p) => p.productId === prefill.productId) || null;

  const incoterm = incoterms.find((i) => i.id === prefill.incotermId);

  return selector.openForm<ContractLineForm, ContractLineEditorComponent, Partial<ContractLineForm>>(ContractLineEditorComponent, {
    width: '90%',
    title: 'Edit Line',
    prefillValue: {
      ...line,
      pricing: ContractPricingToContractFormPricing(line),
    },
    initializer: (editor) => {
      editor.quantityUnit = quantityUnit;
      editor.priceUnit = priceUnit;
      editor.currency = currency;
      editor.premiumUnit = premiumUnit;
      editor.premiumCurrency = premiumCurrency;
      editor.product = product;
      editor.contractClass = prefill.class;
      editor.contractDate = prefill.date ? new Date(prefill.date) : null;
      editor.incoterm = incoterm;
    },
  });
};

export const submitLineExecForm: submitFormCallback<PhysicalContract, ContractLineForm> = (delegate, id, line, contract) => {
  const request: UpdatePhysicalContractRequest = {
    id: contract.id,
    lines: [
      {
        id,
        ...getBaseLineRequest(line),
        metalPercentage: contract.class === ContractClass.QP || contract.class === ContractClass.H ? line.metalPercentage : 100,
        ...ContractFormPricingToUpdateContractPricing(line.pricing, contract.class),
      } as UpdateContractLineRequest,
    ],
  };

  const api = delegate.getService('api');

  return api.rpc<PhysicalContract>(endpoints.updateContractManagement, request, null);
};

export const editLineExecPreset: DynamicFormConstant<PhysicalContract, ContractLineForm> = {
  value: DynamicFormType.EDIT_CONTRACT_LINE_EXEC,
  label: 'Edit Contract Line (Exec)',
  title: 'Edit Line',
  allowMultipleRows: false,
  endpoints: [endpoints.getContract, endpoints.updateContractManagement],
  width: 0,
  getPrefill: contractLineExecPrefill,
  openForm: openEditContractLineExecForm,
  submitForm: submitLineExecForm,
  entityType: SourceEntityType.CONTRACT_LINE_ID,
};
