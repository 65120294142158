import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isNumber, round } from 'lodash';
import { isDate } from 'moment';
import { compareDates, dateGreaterThanOrSameAs, formatDate, fromBradyDate, toBradyUTCDate } from './helperFunctions';

export function greaterThanOrEqualToValidator(lesserValueControl: AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    let lesserValue = lesserValueControl.value;
    let greaterValue = control.value;
    if (typeof lesserValue !== 'number' || typeof greaterValue !== 'number') return;
    return lesserValue > greaterValue ? { custom: msg } : null;
  };
}

export function laterThanValidator(earlierDateOrControl: Date | AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    if (!control.value) return;
    let date: Date;
    let earlierDate: Date;
    try {
      date = new Date(control.value);
      if (isDate(earlierDateOrControl)) {
        earlierDate = new Date(earlierDateOrControl as Date);
      } else {
        if (!earlierDateOrControl.value) return null;
        earlierDate = new Date(earlierDateOrControl.value);
      }
    } catch (e) {
      return { custom: 'Error parsing date' };
    }

    date.setUTCHours(0, 0, 0, 0);
    earlierDate.setUTCHours(0, 0, 0, 0);
    if (date < earlierDate) return { custom: msg };
    return null;
  };
}

export function laterThanNotIncludedValidator(earlierDateOrControl: Date | AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    if (!control.value) return;
    let date: Date;
    let earlierDate: Date;
    try {
      date = new Date(control.value);
      if (isDate(earlierDateOrControl)) {
        earlierDate = new Date(earlierDateOrControl as Date);
      } else {
        if (!earlierDateOrControl.value) return null;
        earlierDate = new Date(earlierDateOrControl.value);
      }
    } catch (e) {
      return { custom: 'Error parsing date' };
    }

    date.setUTCHours(0, 0, 0, 0);
    earlierDate.setUTCHours(0, 0, 0, 0);
    if (date <= earlierDate) return { custom: msg };
    return null;
  };
}

export function sameYearValidator(compareYearControl: number | Date | AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    if (!control.value) return;
    let date: Date;
    let dateCompare: Date;
    let controlYear: number;
    let compareYear: number;
    try {
      date = new Date(control.value);
      if (isDate(compareYearControl)) {
        dateCompare = new Date(compareYearControl as Date);
        compareYear = dateCompare.getUTCFullYear();
      } else if (isNumber(compareYearControl)) {
        compareYear = compareYearControl;
      } else {
        if (!compareYearControl.value) return null;
        if (isDate(compareYearControl.value)) {
          dateCompare = new Date(compareYearControl.value);
          compareYear = dateCompare.getUTCFullYear();
        } else if (isNumber(compareYearControl.value)) {
          compareYear = compareYearControl.value;
        }
      }
    } catch (e) {
      return { custom: 'Error parsing date' };
    }

    controlYear = date.getUTCFullYear();
    if (controlYear !== compareYear) return { custom: msg };
    return null;
  };
}

export function earlierThanValidator(laterDateOrControl: Date | AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    if (!control.value) return null;

    let date: Date;
    let laterDate: Date;

    try {
      date = new Date(control.value);
      if (isDate(laterDateOrControl)) {
        laterDate = new Date(laterDateOrControl as Date);
      } else {
        if (!laterDateOrControl.value) return null;
        laterDate = new Date(laterDateOrControl.value);
      }
    } catch (e) {
      return { custom: 'Error parsing date' };
    }

    date.setUTCHours(0, 0, 0, 0);
    laterDate.setUTCHours(0, 0, 0, 0);
    if (date >= laterDate) return { custom: msg };
    return null;
  };
}

export function earlierOrEqualThanValidator(laterDateOrControl: Date | AbstractControl, msg: string) {
  return (control: AbstractControl) => {
    if (!control.value) return null;

    let date: Date;
    let laterDate: Date;

    try {
      date = new Date(control.value);
      if (isDate(laterDateOrControl)) {
        laterDate = new Date(laterDateOrControl as Date);
      } else {
        if (!laterDateOrControl.value) return null;
        laterDate = new Date(laterDateOrControl.value);
      }
    } catch (e) {
      return { custom: 'Error parsing date' };
    }

    date.setUTCHours(0, 0, 0, 0);
    laterDate.setUTCHours(0, 0, 0, 0);
    if (date > laterDate) return { custom: msg };
    return null;
  };
}

export function isContainer(code: string): boolean {
  if (!code) return false;

  if (typeof code !== 'string') return false;

  // prettier-ignore
  const alphabet: { [letter: string]: number } = {
    'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19,
    'J': 20, 'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29,
    'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
  };

  code = code.toUpperCase();

  const invalidLenght = code.length !== 11;
  const isISOFormat = /^[A-Z]{4}\d{7}/.test(code);

  if (invalidLenght || !isISOFormat) return false;

  let sum = 0;
  const checkDigit = code.substr(10);

  code
    .substr(0, 10)
    .split('')
    .map((char: string, index: number) => {
      let n = Number(char);

      if (index < 4) n = alphabet[char];

      n *= Math.pow(2, index);

      sum += n;
    });

  sum %= 11;
  sum %= 10;

  return sum === Number(checkDigit);
}

export function isCloseToContainerNumberFormat(containerNumber: string | null): boolean {
  return !!containerNumber && /^[A-Za-z]{3}.*[0-9]{3}$/.test(containerNumber) && containerNumber.length >= 9 && containerNumber.length <= 13;
}

export type DateRangeValidatorOptions =
  | {
      monthsBefore?: number;
      monthsAfter: number;
      originalDate?: Date | string;
    }
  | {
      monthsBefore: number;
      monthsAfter?: number;
      originalDate?: Date | string;
    };

export function dateRangeValidator(options: DateRangeValidatorOptions) {
  return (control: AbstractControl) => {
    let dateValue: Date | null;
    let originalDate: Date | null;
    try {
      dateValue = control.value ? new Date(control.value) : null;
      originalDate = options.originalDate ? new Date(options.originalDate) : null;
    } catch (e) {
      return null;
    }
    if (dateValue === null) return null;
    if (!!dateValue && !!originalDate && compareDates(dateValue, originalDate)) return null;
    if (!!options.monthsBefore) {
      let monthsBefore = new Date();
      monthsBefore.setMonth(monthsBefore.getMonth() - options.monthsBefore);
      if (dateValue < monthsBefore) return { custom: `Date cannot be earlier than ${options.monthsBefore} months before today` };
    }
    if (!!options.monthsAfter) {
      let monthsAfter = new Date();
      monthsAfter.setMonth(monthsAfter.getMonth() + options.monthsAfter);
      if (dateValue > monthsAfter) return { custom: `Date cannot be later than ${options.monthsAfter} months after today` };
    }
    return null;
  };
}

export function andValidator(otherControls: AbstractControl[], errorMessage: string) {
  return (control: AbstractControl) => {
    if (!control.value) return;
    if (!otherControls || otherControls.length === 0) return;

    if (otherControls.every((c) => !!c.value)) return;

    return { custom: errorMessage };
  };
}

export function exclusiveOrValidator(otherControls: AbstractControl[], errorMessage: string) {
  return (control: AbstractControl) => {
    if (!otherControls || otherControls.length === 0) return;

    if (!!control.value || control.value === 0) {
      return otherControls.some((c) => !!c.value || c.value === 0) ? { custom: errorMessage } : null;
    }

    return otherControls.some((c) => !!c.value || c.value === 0) ? null : { custom: errorMessage };
  };
}

export function nandValidator(otherControls: AbstractControl[], errorMessage: string) {
  return (control: AbstractControl) => {
    if (!otherControls || otherControls.length === 0) return;

    if (!!control.value || control.value === 0) {
      return otherControls.some((c) => !!c.value || c.value === 0) ? { custom: errorMessage } : null;
    }

    return null;
  };
}

export function inclusiveOrValidator(text: string, ...otherControls: AbstractControl[]) {
  return (control: AbstractControl) => {
    if (!control.value || control.value.length === 0) {
      if (otherControls.some((c) => !!c.value && c.value.length !== 0)) {
        return null;
      } else {
        return { custom: text };
      }
    }
    return null;
  };
}

/**
 *
 * @param min Minimum number
 * @param inclusive Include minimum number in range, set to false for greater than, true for greater than or equal to
 */
export function minValidator(min: number, inclusive: boolean = true) {
  return (control: AbstractControl) => {
    if (!control.value && control.value !== 0) return null;

    if (inclusive && control.value < min) {
      return { custom: `Value must be greater than or equal to ${min}` };
    }
    if (!inclusive && control.value <= min) {
      return { custom: `Value must be greater than ${min}` };
    }
    return null;
  };
}

/**
 *
 * @param max Maximum number
 * @param inclusive Include maximum number in range, set to false for less than, true for less than or equal to
 */
export function maxValidator(max: number, inclusive: boolean = true) {
  return (control: AbstractControl) => {
    if (!control.value && control.value !== 0) return null;

    if (inclusive && control.value > max) {
      return { custom: `Value must be less than or equal to ${max}` };
    }
    if (!inclusive && control.value >= max) {
      return { custom: `Value must be less than ${max}` };
    }
    return null;
  };
}

/**
 *
 * @param min Min number of characters string can be
 */
export function minLengthValidator(min: number) {
  return (control: AbstractControl) => {
    let value = control.value;
    if (typeof value === 'number') value = control.value.toString();
    if (!value || value.length === undefined) return null;

    if (value.length < min) {
      return { custom: `Value must have min ${min} characters` };
    }
    return null;
  };
}

/**
 *
 * @param max Max number of characters string can be
 */
export function maxLengthValidator(max: number) {
  return (control: AbstractControl) => {
    let value = control.value;
    if (typeof value === 'number') value = control.value.toString();
    if (!value || value.length === undefined) return null;

    if (value.length > max) {
      return { custom: `Value must have no more than ${max} characters` };
    }
    return null;
  };
}

export function requiredNumeric() {
  return (control: AbstractControl) => {
    return control.value === 0 ? null : Validators.required(control);
  };
}

export function bradyDateValidator() {
  return (c: AbstractControl) => {
    if (!c.value) return;

    try {
      fromBradyDate(toBradyUTCDate(c.value));
      return null;
    } catch (e) {
      return { custom: e ?? 'Date out of range' };
    }
  };
}

export function conditionalValidators(check: (v?: any) => boolean, ...validators: ValidatorFn[]) {
  return (c: AbstractControl) => {
    if (!check(c.value)) return null;
    for (let v of validators) {
      let error = v(c);
      if (!!error) return error;
    }
    return null;
  };
}

export function switchValidators(check: () => boolean, trueValidators: ValidatorFn[], falseValidators: ValidatorFn[]) {
  return (c: AbstractControl) => {
    if (check()) {
      for (let v of trueValidators) {
        let error = v(c);
        if (!!error) return error;
      }
    } else {
      for (let v of falseValidators) {
        let error = v(c);
        if (!!error) return error;
      }
    }
  };
}

export function notValidator(message: string = 'Field must be blank') {
  return (c: AbstractControl) => {
    return !!c.value ? null : { custom: message };
  };
}

export function regexValidator(pattern: RegExp, text: string) {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!value) return null;
    const validationResult = pattern.test(value);
    return !validationResult ? { custom: text } : null;
  };
}

export function strictBankAddressValidator() {
  return regexValidator(/^[a-zA-Z0-9.,#'\-()\ \n\r]/, `Addresses can only have alphanumeric characters`);
}

export function specialCharactersValidator() {
  // Regex that allows only aphabetic and numeric characters, also commas, spaces and &
  return regexValidator(/^[a-zA-Z0-9\,&\s]+$/, 'Special characters are not allowed, for example: / . : * ? " < > |');
}

export function maxLengthIncludingNewLine(length: number) {
  return (control: AbstractControl) => {
    let string = ((control.value as string) || ``).replace(/[\n\r]/g, '  ');
    if (string.length > length) {
      return { maxLength: length };
    }
    return null;
  };
}

export function getErrorLabel(control: AbstractControl): string {
  let msg: string = null;
  if (control.hasError('custom')) {
    msg = control.errors['custom'] || 'Invalid';
  } else if (control.hasError('required')) {
    msg = 'This field is required';
  } else if (control.hasError('pattern')) {
    msg = 'Wrong format';
  } else if (control.hasError('nameTaken')) {
    msg = 'This name is in use';
  } else if (control.hasError('maxlength')) {
    msg = 'Must be ' + control.errors['maxlength']['requiredLength'] + ' characters or less';
  } else if (control.hasError('positive')) {
    msg = 'Please enter a positive number';
  } else if (control.hasError('greaterThanMin')) {
    msg = 'Max quantity must be greater or equal to min';
  } else if (control.hasError('email')) {
    msg = 'Please enter a valid email';
  } else if (control.hasError('phone')) {
    msg = 'Please enter a valid phone number';
  } else if (control.hasError('exclusiveOr')) {
    if (control.getError('exclusiveOr')['count'] === 0) {
      msg = 'Provide one of: ' + control.getError('exclusiveOr')['groupName'];
    } else {
      msg = 'Provide only one of: ' + control.getError('exclusiveOr')['groupName'];
    }
  } else if (control.hasError('inclusiveOr')) {
    if (control.getError('inclusiveOr')) {
      msg = control.getError('inclusiveOr');
    } else {
      msg = 'At least one of these fields is required';
    }
  } else if (control.hasError('partialError')) {
    msg = 'Please enter a completed formula';
  } else if (control.hasError('invalidError')) {
    msg = 'Please enter a valid formula';
  } else if (control.hasError('parenthesisMismatch')) {
    msg = 'Please make sure all parenthesis are closed';
  } else if (control.hasError('dateBeforeToday')) {
    msg = 'Please enter a valid date';
  } else if (control.hasError('startDateAfterEndDate')) {
    msg = 'End date must be later than start date';
  } else if (control.hasError('outOfRange')) {
    let max = control.getError('outOfRange').max;
    let min = control.getError('outOfRange').min;
    if (!!max && max === min) msg = `Value must be ${min}`;
    else if (!!max && !!min) msg = `Value must be between ${min} and ${max}`;
    else if (!!max) msg = `Value must be less than ${max}`;
    else if (!!min) msg = `Value must be greater than ${min}`;
    else msg = `Value is out of valid range`;
  } else if (control.hasError('passwordMismatch')) {
    msg = 'Passwords must match';
  } else if (control.hasError('compositeKeyMatch')) {
    msg = 'This combination of values is already taken';
  } else if (control.hasError('maxQuantityExceedsLine')) {
    msg = 'Min/Max quantity cannot exceed quantity of line';
  } else if (control.hasError('minLength')) {
    msg = `Value must contain at least ${control.getError('minLength')} characters`;
  } else if (control.hasError('maxLength')) {
    msg = `Value must contain ${control.getError('maxLength')} characters or less`;
  } else if (control.hasError('length')) {
    msg = `Value must contain exactly ${control.getError('length')} characters`;
  } else if (control.hasError('afterContractDate')) {
    msg = `Date cannot be earlier than contract date`;
  } else if (control.hasError('bradyAddress')) {
    msg = control.getError('bradyAddress');
  } else if (control.hasError('bradyFormula')) {
    msg = `Due to limitations in Brady, this is not a valid formula`;
  } else if (control.hasError('invalidJson')) {
    msg = 'Please enter valid JSON';
  } else if (control.hasError('mtmMarket')) {
    msg = 'MTM must include a market';
  } else if (control.hasError('max')) {
    msg = 'Input exceeds max value';
  } else if (control.hasError('min')) {
    msg = 'Input below min value';
  } else if (control.hasError('incompleteDate')) {
    msg = 'Date is incomplete';
  }

  return msg;
}

export function quantityToFixValidator(qtyToFix: number, contractUnitAbbr: string, qtyApplied?: number) {
  return (c: AbstractControl) => {
    if (!c.value && !qtyToFix) return;
    let qtyToFixRounded = round(qtyToFix, 3);
    if (c.value <= 0) {
      return { custom: 'Quantity must be more than 0' };
    }
    if (c.value > qtyToFixRounded) {
      return { custom: `Quantity must not be more than ${qtyToFixRounded + ' ' + contractUnitAbbr}` };
    }
    if (!!qtyApplied) {
      if (c.value < qtyApplied) {
        return { custom: `Quantity must be more than ${qtyApplied + ' ' + contractUnitAbbr}` };
      }
    }
    return null;
  };
}

export function metalUnitPercentageValidator() {
  return (c: AbstractControl) => {
    if (c.value === null) return;
    if (c.value <= 0) return { custom: 'MUP must be more than 0' };
    if (c.value > 100) return { custom: `MUP must not be more than 100` };
    return null;
  };
}

export function fixationRateValidator() {
  return (c: AbstractControl) => {
    if (c.value === null) return;
    if (c.value <= 0) {
      return { custom: 'Quantity must be more than 0' };
    }
    return null;
  };
}

export function materialInvoiceDateValidator(contractDate: Date) {
  return (c: AbstractControl) => {
    if (!c.value) return;
    if (!dateGreaterThanOrSameAs(c.value, contractDate)) return { custom: `Invoice Date can NOT be earlier than the Contract Date: ${formatDate(contractDate, true)}` };
  };
}
