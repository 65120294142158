import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { isDocument } from 'src/lib/helperFunctions';
import { Document } from 'src/lib/newBackendTypes/document';

@Component({
  selector: 'file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnInit {
  @Input()
  file: Document | File | null;

  path: any | null;
  type: 'image' | 'iframe' | 'unsupported' | null;
  zoom: 1;
  constructor(private domSanitizer: DomSanitizer, private fileUploadService: FileUploadService) {
    this.file = null;
    this.path = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['file']) {
      this.onFileChange();
    }
  }

  onFileChange() {
    this.zoom = 1;
    if (this.file === null) {
      this.type = null;
      this.path = null;
    } else if (isDocument(this.file)) {
      this.path = null;
      this.type = null;

      let fileName = this.file.fileName;
      let fileSegments = fileName.split('.');
      let mimeType: string;
      if (fileSegments.length > 1) {
        let extension = fileSegments[fileSegments.length - 1];

        if (extension === 'pdf') {
          mimeType = 'application/pdf';
        } else if (extension === 'jpeg' || extension === 'png' || extension === 'jpg') {
          mimeType = 'image/' + extension;
        } else {
          mimeType = '';
        }
      }
      this.fileUploadService.download(this.file.id).subscribe((data) => {
        let blob = new Blob([data]);
        let options: FilePropertyBag = {};
        if (!!mimeType) {
          options.type = mimeType;
        }
        let file = new File([blob], fileName, options);
        this.setFile(file);
      });
    } else {
      this.setFile(this.file);
    }
  }

  ngOnInit(): void {
    this.onFileChange();
  }

  setFile(file: File) {
    var type = file.type;
    if (type.match(/image\/*/) !== null) {
      this.type = 'image';
    } else if (type.match(/pdf\/*/) !== null) {
      this.type = 'iframe';
    } else {
      this.type = 'unsupported';
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.path = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
    };
  }

  get fileName() {
    if (this.file === null) return '';
    if (isDocument(this.file)) return this.file.fileName;
    return this.file.name;
  }

  zoomIn() {
    if (this.type !== 'image' || this.zoom > 4) return;
    this.zoom += 0.25;
  }
  zoomOut() {
    if (this.type !== 'image' || this.zoom <= 0.25) return;
    this.zoom -= 0.25;
  }
}
