import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'textarea-prompt',
  templateUrl: './textarea-prompt.component.html',
  styleUrls: ['./textarea-prompt.component.scss'],
})
export class TextAreaPromptComponent implements OnInit {
  public label: string;
  value: string;

  constructor() {
    this.value = '';
  }

  ngOnInit(): void {}
}
