import { Component, Input, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent, ItemArgs } from '@progress/kendo-angular-dropdowns';
import { filter } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { Store } from 'src/app/core/services/store.service';
import { Unit } from 'src/lib/newBackendTypes';
import { WeightTicket } from 'src/lib/newBackendTypes/weightTicket';
import { toLocalDate } from 'src/lib/toUTCDate';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'weight-ticket-dropdown',
  templateUrl: './weight-ticket-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => WeightTicketDropdownComponent) }],
})
export class WeightTicketDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  dropdown: ComboBoxComponent;

  @Input()
  data: WeightTicket[];

  @Input()
  chunkKey: number;

  @Input()
  requireNetWeight: number;

  @Input()
  placeholder: string = '';

  units: readonly Unit[];

  _value: WeightTicket & { label: string } = null;

  dropdownData: (WeightTicket & { label: string })[];
  filteredData: (WeightTicket & { label: string })[];

  setValue(value?: WeightTicket | null, external?: boolean) {
    super.setValue(value, external);
  }

  constructor(controlDir: NgControl, store: Store, private commonDataService: CommonDataService) {
    super(controlDir, store);
    this.units = [];
    this.commonDataService.staticUnits.pipe(filter((u) => !!u)).subscribe((units) => {
      this.units = units;

      this.updateDropdownData();
    });
  }

  ngOnInit(): void {
    if (this.chunkKey) {
    }

    this.updateDropdownData();
    this.handleFilter();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.requireNetWeight) {
      this.dropdown.itemDisabled = (context: ItemArgs) => {
        let item: WeightTicket = context.dataItem;
        return !item.netWeight;
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.updateDropdownData();
      this.handleFilter(this.dropdown?.text || '');
    }
  }

  updateDropdownData() {
    if (!this.data) this.dropdownData = [];
    else {
      this.dropdownData = this.data.map(this.addLabelToTicket).filter((wt) => wt.chunkKey === this.chunkKey);
      if (this._value) {
        if (!this.dropdownData.some((wt) => wt.chunkKey === this._value.chunkKey)) {
          if (this.dropdownData.length === 1 && !!this.dropdownData[0].netWeight) {
            //this.setValue(this.dropdownData[0])
          } else {
            this.setValue(null);
          }
        }
      } else {
        if (this.dropdownData.length === 1 && !!this.dropdownData[0].netWeight) {
          //this.setValue(this.dropdownData[0])
        }
      }
    }
  }

  handleFilter(text?: string) {
    let filteredData = this.dropdownData;

    if (text && text.length > 0) {
      filteredData = filteredData.filter((wt) => wt.label.includes(text));
    }
    this.filteredData = filteredData;
  }

  onOpen() {
    this.handleFilter();
  }

  addLabelToTicket = (wt: WeightTicket) => {
    return { ...wt, label: this.getLabelFromWeightTicket(wt) };
  };

  getLabelFromWeightTicket = (wt: WeightTicket) => {
    let date = toLocalDate(wt.date);
    let unit = this.units.find((u) => wt.unitId === u.unitId);
    let unitCode = unit ? `${unit.code}` : `(Unit ${wt.unitId})`;
    let label: string = !!wt.netWeight ? `${wt.netWeight}  ${unitCode} - ${date.toLocaleDateString()}` : `${wt.netWeight}  ${unitCode} - ${date.toLocaleDateString()}`;
    return label;
  };

  focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  comparator(a: WeightTicket, b: WeightTicket) {
    return a?.id === b?.id;
  }
}
