<div class="horizontal-form-field-left form-group row">
  <label class="col-lg-4 col-form-label col-form-label-sm d-flex justify-content-lg-start" [ngStyle]="{ visibility: !!noLabel ? 'hidden' : 'visible' }">
    {{ name }}<span *ngIf="required">*</span>
  </label>
  <div class="col-lg-8 d-flex field-content-container" style="flex-grow: 1">
    <ng-content></ng-content>
  </div>
  <div *ngIf="controlTouched" cyData="errorLabel" class="col-lg-8 offset-lg-4 error-label" [ngClass]="{ 'active-error': !!errorMessage }">
    {{ errorMessage }}
  </div>
</div>
