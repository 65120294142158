import { AfterViewInit, Component } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-intl';
import { AgEditorComponent } from 'ag-grid-angular';
import { AgGridCellEditor } from 'src/lib/agGridEditor';

@Component({
  selector: 'grid-numeric-input',
  templateUrl: 'numeric-input.component.html',
})
export class GridNumericInputComponent extends AgGridCellEditor implements AgEditorComponent, AfterViewInit {
  format: string | NumberFormatOptions;
  decimals: number;
  min: number;
  max: number;

  get value() {
    return this._value;
  }

  constructor() {
    super();
  }
}
