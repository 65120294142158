import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'thalos-html-dialog',
  template: `<div #region></div>`,
})
/**
 * @class HTMLDialog Component
 * A simple dialog notification that allows for direct html injection
 */
export class HTMLDialogComponent implements AfterViewInit {
  @ViewChild('region', { static: false })
  region: ElementRef;

  html: string;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.html && this.region) {
      this.region.nativeElement.innerHTML = this.html;
    }
  }
}
