import { Validators } from '@angular/forms';
import { from } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { OceanratesSurchargeDefinition, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';

const createSurchargeTypeForm: createFormCallback<CreateSurchargeTypeForm> = (delegate, id, prefill) => {
  return [_fe('name', 'Name', 'Text', '', Validators.required), _fe('group', 'Group', 'Text', '', Validators.required), _fe('notes', 'Notes', 'TextArea', '', undefined, false, true)];
};

const createSurchargeTypeCallback: submitFormCallback<CreateSurchargeTypeForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<OceanratesSurchargeDefinition>((resolve) => {
      (async () => {
        const request: CreateSurchargeTypeForm = {
          name: form.name,
          group: form.group,
          notes: form.notes ?? '',
        };
        const createSurchargeType = await api.run<OceanratesSurchargeDefinition>(endpoints.createSurchargeType, request, null);
        if (createSurchargeType) return resolve(createSurchargeType);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Surcharge Type ${res.name} created successfuly.</div>`);
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Surcharge Type was created and try again if necessary.</div>`);
      })
  );
};

export const createSurchargeTypePreset: DynamicFormConstant<CreateSurchargeTypeForm> = {
  allowMultipleRows: false,
  createForm: createSurchargeTypeForm,
  entityType: SourceEntityType.SURCHARGE_TYPE_KEY,
  label: 'Create Surcharge Type',
  submitForm: createSurchargeTypeCallback,
  title: 'Create Surcharge Type',
  value: DynamicFormType.CREATE_SURCHARGE_TYPE,
  endpoints: [endpoints.createSurchargeType],
  width: 400,
};

export type CreateSurchargeTypeForm = Subset<OceanratesSurchargeDefinition, 'name' | 'group', 'notes'>;
