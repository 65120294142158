import { FiscalCompany, PayOrderHeader } from '.';
import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { AnalyticalGroup } from './analyticalGroup';
import { Contact } from './contact';
import { GlAccountLeaves } from './glAccount';
import { InvoiceHeader } from './invoice';
import { ConsStatus, JournalHeader } from './journal';
import { YN } from './enums';

export enum PaymentStatus {
  TO_BE_VERIFIED = 4,
  PERMANENT = 2,
  TEMPORARY = 1,
}
export const PaymentStatuses = enumOptionsFromEnum(PaymentStatus);

export enum PaymentEntrySource {
  NONE = 0,
  AUTOMATION = 1,
  MATCHING = 3,
  INTERFACE = 4,
}
export const PaymentEntrySources = enumOptionsFromEnum(PaymentEntrySource);

export enum PaymentEntryOperation {
  NONE = 0,
  NORMAL = 1,
  EXTERNAL = 2,
  EXCHANGE_DIFFERENCE = 3,
  JOURNAL = 4,
  INITIAL_BALANCES = 7,
  ADVANCE = 8,
}
export const PaymentEntryOperations = enumOptionsFromEnum(PaymentEntryOperation);

export enum PaymentEntryMode {
  NONE = 0,
  MANUAL = 1,
  AUTOMATIC = 2,
}
export const PaymentEntryModes = enumOptionsFromEnum(PaymentEntryMode);

export type PaymentHeader = {
  accountId: number | null;
  amount: number;
  anlConsStatus: ConsStatus.IF_AUXILIARY;
  baseAmount: number;
  company?: FiscalCompany;
  companyId: number;
  consMode: 1;
  consStatus: ConsStatus.IF_AUXILIARY;
  createdBy: number;
  creationDate: Date;
  documentReference: number;
  documentType: DocumentType;
  entryDate: Date;
  entryMode: PaymentEntryMode;
  entryOperation: PaymentEntryOperation;
  entrySource: PaymentEntrySource | null;
  externalRef: string;
  /**
   * @deprecated
   */
  fiscalYear: number;
  glAccount?: GlAccountLeaves;
  id: number;
  itemDate: Date | null;
  journalHeader?: JournalHeader;
  journalId: number;
  lastOperationType: null;
  modifiedBy: string | null;
  modifyDate: Date | null;
  paymentEntries?: PaymentEntry[];
  payOrder?: PayOrderHeader;
  paymentStatus: PaymentStatus;
  paymentTitle: string | null;
  updateDate: Date | null;
  updatedBy: number | null;
  valueDate: Date;
  versionNb: number;
  reconciled: YN;
};

export type PaymentHeaderList = Omit<PaymentHeader, 'valueDate' | 'entryDate'> & {
  valueDate: number;
  entryDate?: number;
};

export type PaymentEntry = {
  accountId: number;
  amount: number;
  analyticalGroup?: AnalyticalGroup | null;
  auxPaymentEntryId: number | null;
  auxPaymentId: number | null;
  bankAccountId: number;
  baseAmount: number;
  cbnGroupId: number | null;
  counterparty?: Contact;
  counterpartyId: number | null;
  entryText: string;
  externalRef: string;
  /**
   * @deprecated
   */
  fiscalYear: number;
  glAccount?: GlAccountLeaves;
  iden: string | null;
  lineNumber: number;
  paymentEntryId: number;
  paymentId: number;
  phValueDate: Date | null;
  voucher: InvoiceHeader;
  voucherId: number | null;
  paymentHeader?: PaymentHeader;
  matchedPaymentEntry?: PaymentEntry | null;
};

export type CreatePaymentHeader = Subset<
  PaymentHeader,
  'paymentTitle' | 'valueDate' | 'externalRef' | 'amount' | 'entryDate' | 'baseAmount' | 'accountId',
  'paymentStatus' | 'entryMode' | 'entryOperation' | 'entrySource'
>;

export type UpsertPaymentEntry = Subset<
  PaymentEntry,
  'amount' | 'baseAmount' | 'entryText' | 'externalRef' | 'accountId',
  'counterpartyId' | 'auxPaymentEntryId' | 'auxPaymentId' | 'voucherId' | 'paymentId' | 'paymentEntryId'
>;

export type CreatePaymentRequest = CreatePaymentHeader & { entries: UpsertPaymentEntry[] };

export type UpdatePaymentHeaderRequest = Subset<PaymentHeader, 'id', 'valueDate' | 'entryDate' | 'accountId' | 'paymentStatus' | 'amount' | 'baseAmount' | 'externalRef' | 'paymentTitle'>;

export type UpdatePaymentRequest = UpdatePaymentHeaderRequest & { entries: UpsertPaymentEntry[] };

export type UpsertPaymentRequest = UpsertPaymentHeaderRequest & { entries: UpsertPaymentEntry[]; enableDifferentCurrencies?: boolean };

export type UpsertPaymentHeaderRequest = Subset<
  PaymentHeader,
  'paymentTitle' | 'externalRef' | 'baseAmount' | 'amount' | 'paymentStatus' | 'accountId' | 'entryDate' | 'valueDate',
  'entryMode' | 'entryOperation' | 'entrySource' | 'id'
>;
