import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GetGroupRowAggParams, GridReadyEvent } from 'ag-grid-community';
import { GridApi, GridOptions, ValueGetterParams } from 'ag-grid-enterprise';
import { debounceTime } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { dateColumn, dateFormatter, defaultComplexGrid, getContextMenuItems, gotoMenu, gotoMenuItem, priceColumn, quantityUnitColumn, staticUnitColumn } from 'src/lib/agGridFunctions';
import { ShipmentFixationSummaryPrefill } from 'src/lib/flex/forms/shipmentFixationSummary';
import { weightFormat } from 'src/lib/helperFunctions';
import { CommonUnits, SourceEntityType } from 'src/lib/newBackendTypes';
import { ShipmentFixationSummary } from 'src/lib/newBackendTypes/shipmentFixationSummary';

type ShipmentTableEntry = ShipmentFixationSummary & { netWeightMT: number };
@Component({
  selector: 'hedging-shipment-fixation-summary',
  templateUrl: './shipment-fixation-summary.component.html',
})
@UntilDestroy()
export class ShipmentFixationSummaryComponent implements ModalFormComponent<void, ShipmentFixationSummaryPrefill> {
  popup = true;
  shipments: ShipmentTableEntry[];
  quickFilterControl: UntypedFormControl;
  gridOptions: GridOptions;
  gridApi: GridApi | null = null;

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  constructor(private delegate: DelegateService, private commonData: CommonDataService, private formatter: DataFormattingService) {
    this.quickFilterControl = new UntypedFormControl();
    this.quickFilterControl.valueChanges.pipe(untilDestroyed(this), debounceTime(250)).subscribe((val) => {
      if (this.gridOptions.api) {
        if (val !== '') this.gridOptions.api.deselectAll();
        this.gridOptions.api.setQuickFilter(val || '');
      }
    });
    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'shipmentId'),
      getRowId: (params) => `${params.data.shipmentId}-${params.data.fixationId}`,
      groupHideOpenParents: true,
      autoGroupColumnDef: {
        minWidth: 150,
        cellRendererParams: {
          footerValueGetter: (params) => `Total ${dateFormatter(params.value)}`,
        },
      },
      getGroupRowAgg: this.groupRowAggregator(),
      columnDefs: [
        { field: 'fixationId', headerName: 'Fixation ID', hide: true },
        { field: 'contractId', headerName: 'Contract ID', hide: true },
        { field: 'contractNumber', headerName: 'Contract Number', hide: true },
        { field: 'elementId', headerName: 'Element ID', hide: true },
        {
          field: 'elementNumber',
          headerName: 'Element Number',
          hide: true,
          sort: 'asc',
          sortIndex: 1,
        },
        { field: 'bookingId', headerName: 'Booking ID', hide: true },
        { field: 'quantityUnitId', headerName: 'Quantity Unit ID', hide: true },
        { field: 'marketUnitId', headerName: 'Market Unit ID', hide: true },
        { field: 'marketCurrencyId', headerName: 'Market Currency ID', hide: true },
        { field: 'priceUnitId', headerName: 'Price Unit ID', hide: true },
        { field: 'priceCurrencyId', headerName: 'Price Currency ID', hide: true },
        { field: 'invoiceId', headerName: 'Invoice ID', hide: true },
        {
          ...dateColumn('fixDate', 'Fix Date'),
          rowGroup: true,
          sort: 'asc',
          hide: true,
          sortIndex: 0,
        },
        {
          headerName: 'Element',
          valueGetter: (params: ValueGetterParams) => (params.data ? `${params.data.contractNumber} / ${params.data.elementNumber}` : ''),
          width: 90,
        },
        { field: 'containerNumber', headerName: 'Container', width: 110 },
        { field: 'invoiceNumber', headerName: 'Invoice', width: 90 },
        { field: 'bookingNumber', headerName: 'Booking', width: 90 },
        {
          ...quantityUnitColumn(this.delegate.dataFormatter, 'quantityUnitId', 'netWeight', 'Net Weight'),
          hide: true,
          width: 110,
        },
        {
          ...staticUnitColumn(this.delegate.dataFormatter, CommonUnits.MT, 'netWeightMT', 'Net Weight (MT)'),
          width: 110,
        },
        {
          ...priceColumn(this.delegate.dataFormatter, 'marketUnitId', 'marketCurrencyId', 'marketPrice', 'Market Price', 4),
          width: 150,
        },
        { field: 'shipmentId', headerName: 'Shipment ID', width: 110 },
        { field: 'formula', headerName: 'Formula', width: 200 },
        {
          ...priceColumn(this.delegate.dataFormatter, 'priceUnitId', 'priceCurrencyId', 'walkPrice', 'Walk Price', 4),
          width: 150,
        },
        { ...dateColumn('blDate', 'BL Date'), width: 90 },
      ],
      getContextMenuItems: getContextMenuItems(
        gotoMenu(
          gotoMenuItem(this.delegate, 'Contract', 'contractId', SourceEntityType.CONTRACT_KEY, 'get', 'contractNumber'),
          gotoMenuItem(this.delegate, 'Invoice', 'invoiceId', SourceEntityType.INVOICE_KEY, 'get', 'invoiceNumber'),
          gotoMenuItem(this.delegate, 'Booking', 'bookingId', SourceEntityType.FREIGHT_BOOKING_KEY, 'get', 'bookingNumber')
        )
      ),
    };
  }

  groupRowAggregator() {
    return (params: GetGroupRowAggParams) => {
      const nodes = params.nodes;
      if (nodes.length === 0) return {};
      if (nodes[0].group) return {};

      let netWeight = 0;
      const groupedData = { [nodes[0].parent?.field]: nodes[0].parent?.key };

      for (const node of nodes) {
        const data: ShipmentTableEntry | null | undefined = node.data;
        if (!data) continue;
        const net = data.netWeightMT;
        if (!!net) {
          netWeight += net;
        }
      }

      return {
        netWeightMT: `${weightFormat(netWeight, 3)} MT`,
        ...groupedData,
      };
    };
  }

  autosizeColumns() {
    if (this.gridOptions?.columnApi) {
      this.gridOptions.columnApi.autoSizeAllColumns(false);
    }
  }

  resetFilters() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setFilterModel({});
      this.quickFilterControl.patchValue('');
      //this.quickFilter('')
    }
  }

  quickFilter(text: string) {
    if (this.gridOptions.api) {
      this.gridOptions.api.setQuickFilter(text);
    }
  }

  prefillForm(prefill: ShipmentFixationSummaryPrefill) {
    if (!!prefill) {
      this.shipments = (prefill.shipments || []).map((shipment) => {
        const unit = this.commonData.staticUnits.value.find((u) => u.unitId === shipment.quantityUnitId);
        if (!unit || !unit.unitFactors) return null;
        const factor = unit.unitFactors.find((f) => f.productId === shipment.productId);
        if (!factor || factor.factor === 0) return null;
        const netWeightMT = this.formatter.roundQuantity(shipment.netWeight * factor.factor, CommonUnits.MT);
        return {
          ...shipment,
          netWeightMT,
        };
      });
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    }
  }

  allowSubmit() {
    return true;
  }

  submit(): void {
    return;
  }
}
