import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { endpoints } from './apiEndpoints';
import { DropdownConfig } from './DropdownConfig';
import { BankAccount, BudgetElement, CommonContactTypes, Contact, ContactBankInformation, GlAccountView, PaymentTerm, YN } from './newBackendTypes';

export function intFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 0,
    useGrouping: true,
  };
}

export function entityIdFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 0,
    useGrouping: false,
  };
}

export function dollarFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: true,
  };
}

export function lbPriceFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
    useGrouping: true,
  };
}

export function mtWeightFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
    useGrouping: true,
  };
}

export function fixationRateFormat(): NumberFormatOptions {
  return {
    maximumFractionDigits: 8,
    minimumFractionDigits: 5,
    useGrouping: true,
  };
}

export function contactDropdown(archived: YN = YN.N, take: number = 20, contactTypes?: CommonContactTypes[]): DropdownConfig<Contact> {
  const dropdownConfig = new DropdownConfig<Contact>({
    listProcedure: endpoints.listContacts,
    valueField: 'id',
    labelField: 'displayName',
    additionalFilters: { id: { not: 0 } },
    orderBy: { fieldName: 'displayName', order: 'ASC' },
    take,
  });
  if (archived === YN.N) dropdownConfig.additionalFilters = { ...dropdownConfig.additionalFilters, archived };
  if (contactTypes && contactTypes.length > 0) dropdownConfig.additionalFilters = { ...dropdownConfig.additionalFilters, 'contactTypeAssignments.contactTypeId': contactTypes };
  return dropdownConfig;
}

export function employeeDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.EMPLOYEE]);
}

export function counterpartyDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.CUSTOMER, CommonContactTypes.SUPPLIER]);
}

export function agentDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.AGENT]);
}

export function warehouseDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.LOG_WAREHOUSE]);
}

export function traderDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.TRADER]);
}

export function operatorDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [CommonContactTypes.ROYCE_OPERATORS]);
}

export function logisticDropdown(archived: YN = YN.N, take: number = 20): DropdownConfig<Contact> {
  return contactDropdown(archived, take, [
    CommonContactTypes.LOG_CUSTOMS_BROKERS,
    CommonContactTypes.LOG_TRUCKING_COMPANY,
    CommonContactTypes.LOG_SHIPPING_COMPANY,
    CommonContactTypes.LOG_INSPECTIONS,
    CommonContactTypes.LOG_WAREHOUSE,
    CommonContactTypes.LOG_OTHER_VENDOR,
    CommonContactTypes.LOG_TERMINALS,
    CommonContactTypes.LOG_FUMIGATION,
  ]);
}

export function budgetElementDropdown(): DropdownConfig<BudgetElement> {
  return new DropdownConfig({
    listProcedure: endpoints.listBudgetElements,
    valueField: 'id',
    labelField: 'name',
    additionalFilters: { archived: YN.N },
    orderBy: { fieldName: 'name', order: 'ASC' },
  });
}

export function bankAccountDropdown(): DropdownConfig<BankAccount> {
  return new DropdownConfig<BankAccount>({
    listProcedure: endpoints.listBankAccounts,
    valueField: 'id',
    labelField: 'iden',
  });
}

export function bankDropdown(): DropdownConfig<Contact> {
  return new DropdownConfig<Contact>({
    listProcedure: endpoints.listBanks,
    valueField: 'id',
    labelField: 'displayName',
  });
}

export function contactBankAccountDropdown(): DropdownConfig<ContactBankInformation> {
  return new DropdownConfig<ContactBankInformation>({
    listProcedure: endpoints.listContactBankInformation,
    valueField: 'id',
    labelField: 'contactBankInformationIden',
  });
}

/**
 * // TODO: Fix it
 * @deprecated this should be filtered by company and fiscal year
 * @returns
 */
export function glAccountDropdown(): DropdownConfig<GlAccountView> {
  return new DropdownConfig<GlAccountView>({
    listProcedure: endpoints.listGlAccounts,
    valueField: 'accKey',
    labelField: 'idenLong',
    labelTransform: (glAccount) => glAccount.idenLong.replace(/ +/g, ' '),
  });
}

export function paymentTermDropdown(): DropdownConfig<PaymentTerm> {
  return new DropdownConfig({
    listProcedure: endpoints.listPaymentTerms,
    valueField: 'id',
    labelField: 'name',
    additionalFilters: { archived: YN.N },
  });
}

export function companyDropdown(): DropdownConfig<Contact> {
  return new DropdownConfig({
    listProcedure: endpoints.listCompanies,
    valueField: 'id',
    labelField: 'displayName',
  });
}

export const anyOrigin = 123460;

export function YNChecknbox() {
  return {
    true: YN.Y,
    false: YN.N,
  };
}

export enum CommonContacts {
  ROYCE = 2,
}
