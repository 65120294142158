export const environment = {
  production: false,
  title: 'Thalos UAT',
  productionHeader: false,
  name: 'STAGING',
  msalConfig: {
    clientId: '62040028-8505-4cc5-8e4f-c27b9a70ac1c',
    tenant: 'e02787e8-2d74-4fdb-b0e1-aaa6ebd66798',
    cacheLocation: 'localStorage',
    endpoints: {
      api: '62040028-8505-4cc5-8e4f-c27b9a70ac1c',
    },
    redirectUri: 'https://uat.thalos.roycetrading.com/login-return',
    logoutUri: 'https://uat.thalos.roycetrading.com/assets/logout.html',
    logoutInactiveUri: 'https://uat.thalos.roycetrading.com/assets/logout-inactive.html',
  },
  agGrid: {
    key: 'CompanyName=Royce Corporation,LicensedApplication=Royce Corporation ERP,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-027642,ExpiryDate=26_June_2023_[v2]_MTY4NzczNDAwMDAwMA==3adfe3917468fef699154b16b8c088d2',
  },
  apiHost: 'https://api.uat.roycetrading.com',
  devMode: false,
  timeoutDialogSeconds: 300,
  timeoutSeconds: 3600,
  broadcastChannel: 'thalos-new-tab',
};
