import { from, of } from 'rxjs';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { Booking, City, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { bookingListPrefillCallback, ListBookingsPrefill } from './updateBooking';
import { basicGraphqlCityDropdown } from 'src/lib/graphql/graphQlFunctions';

const updateBookingTrackingDatesCheck: checkPrefillCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.bookingResponses.length === 0) return of('No bookings to be updated.');
  return of(true);
};

const updateBookingTrackingDatesForm: createFormCallback<ListBookingsPrefill, updateBookingTrackingDatesForm> = (delegate, id, prefill) => {
  const bookings = ` ${prefill.bookingResponses.map((b) => b.number).join(', ')}`;
  const bookingList = prefill.bookingResponses;
  const form: (FormElementConfig<updateBookingTrackingDatesForm> | FormElementConfig<updateBookingTrackingDatesForm>[])[] = [
    { type: 'Label', text: `Booking(s): ${bookings}` },
    [
      _fe('transhipmentETD', 'Transhipment ETD', 'Date', bookingList.length > 1 ? null : bookingList[0].transhipmentETD),
      _fe('transhipmentPort', 'Transhipment Port', basicGraphqlCityDropdown(), bookingList.length > 1 ? null : bookingList[0].transhipmentPort),
    ],
    [
      _fe('finalDestinationETA', 'Final ETA', 'Date', bookingList.length > 1 ? null : bookingList[0].finalDestinationETA),
      _fe('unloadingPortETA', 'Unloading ETA', 'Date', bookingList.length > 1 ? null : bookingList[0].unloadingPortETA),
      _fe('cargoArrivalDate', 'Cargo Arrival (ATA)', 'Date', bookingList.length > 1 ? null : bookingList[0].cargoArrivalDate),
    ],
  ];
  if (prefill.bookingResponses.length > 1) {
    form.unshift({
      type: 'Label',
      text: 'Warning! Updating multiple bookings',
      style: { color: 'red', display: 'flex', 'justify-content': 'center' },
    });
  }
  return form;
};

const updateBookingTrackingDatesSubmit: submitFormCallback<ListBookingsPrefill, updateBookingTrackingDatesForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (let res of prefill.bookingResponses) {
          let request: updateBookingTrackingDatesRequest = {
            ...result,
            id: res.id,
            lastTrackingDate: getTodayUTC(),
            transhipmentPortId: result.transhipmentPort.id,
          };
          const updateBooking = await api.run<Booking>(endpoints.updateBooking, request, null);
          updateBookingResponses.push(updateBooking);
        }
        if (updateBookingResponses) return resolve(updateBookingResponses);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully updated: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were updated and try again if necessary.</div>`);
      })
  );
};

export const updateBookingTrackingDatesPreset: DynamicFormConstant<ListBookingsPrefill, updateBookingTrackingDatesForm> = {
  allowMultipleRows: true,
  value: DynamicFormType.UPDATE_BOOKING_TRACKING_DATES,
  label: 'Update Tracking Dates',
  title: 'Update Tracking Dates',
  endpoints: [endpoints.updateBooking, endpoints.getBooking],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  width: 600,
  checkPrefill: updateBookingTrackingDatesCheck,
  getPrefill: bookingListPrefillCallback,
  createForm: updateBookingTrackingDatesForm,
  submitForm: updateBookingTrackingDatesSubmit,
};

export type updateBookingTrackingDatesForm = Pick<Booking, 'cargoArrivalDate' | 'unloadingPortETA' | 'finalDestinationETA' | 'transhipmentETD'> & { transhipmentPort: Pick<City, 'id' | 'name'> };
export type updateBookingTrackingDatesRequest = updateBookingTrackingDatesForm & Pick<Booking, 'lastTrackingDate' | 'id' | 'transhipmentPortId'>;
