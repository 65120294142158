import { from, of } from 'rxjs';
import { AdvancePaymentComponent } from 'src/app/+modules/+accounting/advance-payment/advance-payment.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { BankAccount, BudgetElement, Contact, EntryType, OpenItem, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { Advance, AdvanceType, CreateAdvancePaymentRequest } from 'src/lib/newBackendTypes/advance';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, prefillCallback, submitFormCallback } from './types';

const createAdvancePaymentPrefill: prefillCallback<CreateAdvancePaymentPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const advanceItem = await api.run<Advance>(endpoints.getAdvance, { filters: { id } });

      const counterpartyId = advanceItem.counterpartyId;
      const counterpartyOpenItems = (await api.run(endpoints.listOpenItems, {
        filters: { counterpartyId: counterpartyId, entryType: [EntryType.INV, EntryType.PMT] },
      })) as ListResponse<OpenItem>;
      let openItems = counterpartyOpenItems.list;

      // Exclude PMTs Already linked to Advance
      openItems = openItems.filter((oi) => !(advanceItem.advancePayments && advanceItem.advancePayments.some((ai) => ai.paymentEntryId === oi.entryId && oi.entryType === EntryType.PMT)));

      return { advanceItem, openItems };
    })()
  );
};

const createAdvancePaymentCheck: checkPrefillCallback<CreateAdvancePaymentPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.advanceItem.type !== AdvanceType.CLIENT_ADVANCE) return of('Payments can only be entered for advances');
  if (prefill.advanceItem.archived === YN.Y) return of('This Advance is marked as closed and cannot be paid');
  if (!prefill.advanceItem.advancePayments) return of('Unable to check the payment status of this advance');
  return of(true);
};

const openAdvancePaymentForm: openFormCallback<CreateAdvancePaymentPrefill, CreateAdvancePaymentForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<CreateAdvancePaymentForm, AdvancePaymentComponent, CreateAdvancePaymentPrefill>(AdvancePaymentComponent, {
    title: `Create Advance Payment: ${prefill.advanceItem.number}`,
    width: '98%',
    prefillValue: prefill,
  });
};

const createAdvancePaymentCallback: submitFormCallback<CreateAdvancePaymentPrefill, CreateAdvancePaymentForm> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<PaymentHeader | null>((resolve, reject) => {
      (async () => {
        const request: CreateAdvancePaymentRequest = {
          id,
          valueDate: form.valueDate,
          bankId: form.bank.id,
          accountId: form.account.id,
          amount: form.amount,
          fees: form.fees.map((fee) => {
            return {
              budgetElementId: fee.budgetElement.id,
              amount: fee.amount,
            };
          }),
          paymentsToApply: form.openItemsLines
            .filter((openItem) => openItem.entryType === 'PMT')
            .map((openItem) => {
              return {
                paymentEntryId: openItem.entryId,
                amount: -openItem.amountToApply,
              };
            }),
          vouchersToApply: form.openItemsLines
            .filter((openItem) => openItem.entryType === 'INV')
            .map((openItem) => {
              return {
                voucherId: openItem.entryId,
                amount: -openItem.amountToApply,
              };
            }),
        };

        const advancePayment = await api.run<PaymentHeader>(endpoints.createAdvancePayment, request, null);
        if (advancePayment) return resolve(advancePayment);
      })();
    })
      .then((res) => {
        if (!!res) {
          const payment = res;
          if (Object.keys(payment).length === 0) {
            return prompt.htmlDialog('Error', `<div style="white-space: pre">An error occurred while creating Advance Payment.</div>`);
          }
          return prompt.htmlDialog(
            'Success',
            `<div style="white-space: pre">Advance Payment successfully created: \n<a href="/portal/accounting/payments/${payment.id}" target="_blank">${payment.documentReference}</a></div>`
          );
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createAdvancePaymentPreset: DynamicFormConstant<CreateAdvancePaymentPrefill, CreateAdvancePaymentForm> = {
  allowMultipleRows: false,
  getPrefill: createAdvancePaymentPrefill,
  checkPrefill: createAdvancePaymentCheck,
  openForm: openAdvancePaymentForm,
  submitForm: createAdvancePaymentCallback,
  endpoints: [endpoints.getAdvance, endpoints.listOpenItems, endpoints.createAdvancePayment],
  entityType: SourceEntityType.ADVANCE_KEY,
  label: 'Create Advance Payment',
  title: 'Create Advance Payment',
  value: DynamicFormType.CREATE_ADVANCE_PAYMENT,
  width: 700,
};

export type CreateAdvancePaymentForm = {
  requestedAmount: number;
  balancedAmount: number;
  amount: number;
  bank: Contact;
  account: BankAccount;
  fees: feesForm[];
  valueDate: string;
  openItemsLines: OpenItemsLineForm[];
};

export type CreateAdvancePaymentPrefill = {
  advanceItem: Advance;
  openItems: OpenItem[];
};

export type feesForm = {
  budgetElement: Partial<BudgetElement>;
  amount: number;
};

export type OpenItemsLineForm = Subset<
  OpenItem,
  'entryReference' | 'valueDate' | 'entryTitle' | 'balanceAmount' | 'entryType' | 'ourReference',
  'entryId' | 'currencyId' | 'accountId' | 'balanceBaseAmount'
> & {
  lineNumber?: number;
  amountToApply?: number;
};
