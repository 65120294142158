import { Component, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { Store } from 'src/app/core/services/store.service';
import { ShipmentFinderResult } from 'src/lib/newBackendTypes';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'shipment-multiselect',
  templateUrl: './shipment-multiselect.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => ShipmentMultiselectComponent) }],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .chip-no-actions {
        .k-chip-actions {
          display: none;
        }
      }
    `,
  ],
})
export class ShipmentMultiselectComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  dropdown: MultiSelectComponent;

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
  }

  ngOnInit(): void {}

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  comparator(a: ShipmentFinderResult[] | null, b: ShipmentFinderResult[] | null) {
    if (!a && !b) return true;
    if ((!a && !!b) || (!!a && !b)) return false;
    return _.isEqual(
      a.map((o) => o.shipmentId),
      b.map((o) => o.shipmentId)
    );
  }
}
