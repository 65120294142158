import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

export type StatusBarTotalizerPanelLabel = { text: string; key: string; decimals?: number };
export type StatusBarTotalizerPanelParams<T> = IStatusPanelParams & {
  totalRow: T;
  labels: StatusBarTotalizerPanelLabel[];
};
@Component({
  selector: 'status-bar-totalizer-panel',
  template: `<div class="ag-status-name-value">
    <ng-container *ngFor="let item of this.params.labels">
      <ng-container *ngIf="params.totalRow[item.key] !== null">
        <span ref="eLabel">{{ item.text }}</span
        >: <span ref="eValue" class="ag-status-name-value-value">{{ params.totalRow[item.key] | amountCurrency : '' : item.decimals }}</span
        >&nbsp;
      </ng-container>
    </ng-container>
  </div>`,
})
export class StatusBarTotalizerPanel<T> implements IStatusPanelAngularComp {
  params: StatusBarTotalizerPanelParams<T>;

  agInit(params: StatusBarTotalizerPanelParams<T>): void {
    this.params = params;
  }
}
