<kendo-combobox
  #dropdown
  [data]="filteredOptions"
  [filterable]="true"
  [valueField]="'cn'"
  [textField]="'cn'"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [suggest]="true"
  (open)="onOpen()"
  [loading]="fetching"
  [readonly]="readonly"
  (blur)="onBlur()"
  (valueChange)="onValueChange(value)"
>
</kendo-combobox>
