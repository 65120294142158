<div class="card rounded-0 form-list">
  <div class="row">
    <ng-container *ngTemplateOutlet="listHeaderTmpl"></ng-container>
  </div>
  <ul class="list-group list-group-flush">
    <li class="no-entries bank-information-entry list-group-item rounded-0 text-center" *ngIf="!formArray || formArray.controls.length === 0">
      {{ noDataMessage }}
    </li>
    <li class="list-group-item rounded-0" *ngFor="let control of formArray.controls; let i = index" [formGroup]="control">
      <ng-container *ngTemplateOutlet="lineHeaderTmpl; context: { element: control, i: i }"></ng-container>
      <ng-container *ngTemplateOutlet="lineContentTmpl; context: { element: control, i: i }"></ng-container>
    </li>
  </ul>
  <div class="card-footer text-right p-1">
    <ng-container *ngTemplateOutlet="listFooterTmpl"> </ng-container>
  </div>
</div>
