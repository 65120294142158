import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Observable, of, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { Store } from 'src/app/core/services/store.service';
import { ActiveMarketValuationHeader, MarketValuationHeader } from 'src/lib/newBackendTypes';
import { FormElementComponent } from '../form-element/form-element.component';
import { toBradyDate } from 'src/lib/helperFunctions';

@Component({
  selector: 'market-valuation-dropdown',
  templateUrl: './market-valuation-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => MarketValuationDropdownComponent) }],
})
export class MarketValuationDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  dropdown: ComboBoxComponent;

  @Input('premium')
  premium: boolean = false;

  @Input('priceValuationId')
  priceValuationId?: number;

  @Input('premiumValuationId')
  premiumValuationId?: number;

  @Input('existEntity')
  existEntity?: boolean;

  activeValuations: Observable<ActiveMarketValuationHeader[]>;

  constructor(controlDir: NgControl, store: Store, private commonDataService: CommonDataService) {
    super(controlDir, store);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    const all = this.premium ? this.commonDataService.staticActivePremiumValuations : this.commonDataService.staticActiveMarketValuations;
    setTimeout(() => {
      this.activeValuations = combineLatest([all, this.dropdown.filterChange.pipe(startWith(''))]).pipe(
        map(([activeValuations, text]: [ActiveMarketValuationHeader[], string]) => {
          let newActiveValuations: ActiveMarketValuationHeader[] = activeValuations;
          if (this.existEntity) {
            if (this.priceValuationId) newActiveValuations = this.checkAndAddExpiredValuation(activeValuations, this.priceValuationId);
            if (this.premiumValuationId) newActiveValuations = this.checkAndAddExpiredValuation(activeValuations, this.premiumValuationId, true);
          }
          return newActiveValuations.filter((value) => {
            return value.valuationName.toLocaleLowerCase().includes(text.toLocaleLowerCase());
          });
        })
      );
    });
  }

  public onOpen() {
    if (!!this.empty) {
      this.dropdown.filterChange.next('');
    }
  }

  public get empty() {
    return !this.value;
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  getLabelFromPrimitive(): Observable<string> {
    if (!this._value) return of('');
    if (!this.activeValuations) return of('');
    return this.activeValuations.pipe(
      map((arr: ActiveMarketValuationHeader[]) => arr.find((v) => v.valuationId === this._value)),
      map((v: ActiveMarketValuationHeader) => (v ? v.valuationName : ''))
    );
  }

  checkAndAddExpiredValuation(activeValuations: ActiveMarketValuationHeader[], valuationId: number, premium: boolean = false): ActiveMarketValuationHeader[] {
    const staticValuationsValues = premium ? this.commonDataService.staticPremiumValuations.value : this.commonDataService.staticMarketValuations.value;
    const existValuation = activeValuations.find((amv) => amv.valuationId === this.priceValuationId);
    if (!existValuation) {
      const marketValuation = staticValuationsValues.find((market) => market.valuationId === valuationId);
      if (marketValuation) return [...activeValuations, this.convertToActiveMarketValuation(marketValuation)];
    }
    return activeValuations;
  }

  convertToActiveMarketValuation(marketValuetion: MarketValuationHeader): ActiveMarketValuationHeader {
    return {
      valuationId: marketValuetion.valuationId,
      valuationName: marketValuetion.valuationName,
      firstNoticeDate: toBradyDate(marketValuetion.line.priceTerm.startDate),
      futureDate: toBradyDate(marketValuetion.line.priceTerm.endDate),
      lineType: marketValuetion.line.lineType,
      priceTermId: marketValuetion.line.priceTermId,
    };
  }
}
