import { dateFormatter } from '../agGridFunctions';
import { EnumLabels, Subset } from '../generics';
import { getTodayUTC, toBradyUTCDate } from '../helperFunctions';
import { City } from './city';
import { Contact } from './contact';
import { YN } from './enums';
import { Booking } from './freightBooking';

export enum RateSize {
  CONTAINER_20FT = 1,
  CONTAINER_40FT = 2,
  CONTAINER_40FTHC = 3,
  TRUCK_53FT = 4,
}

export const rateSizes: EnumLabels<RateSize> = [
  { value: RateSize.CONTAINER_20FT, label: "20'" },
  { value: RateSize.CONTAINER_40FT, label: "40'" },
  { value: RateSize.CONTAINER_40FTHC, label: "40'HC" },
  { value: RateSize.TRUCK_53FT, label: "53' Truck" },
];

export type OceanratesRate = {
  rateKey: number;
  name: string;
  shippingLineId: number | null;
  freightForwarderId?: number | null;
  effective: number;
  expire: number;
  agreement: string;
  notes: string | null;
  comments: string | null;
  freeDaysOrigin: number | null;
  freeDaysDestination: number | null;
  archived?: YN;
  segments?: OceanratesSegment[];
  booking?: Booking;
  shippingLine?: Contact | null;
  freightForwarder?: Contact | null;
};

export type OceanratesSegment = {
  segmentKey: number;
  rateKey: number;
  originId: number;
  portOfLoadingId: number;
  portOfDischargeId: number;
  destinationId: number;
  size: RateSize;
  cost: number;
  archived: YN;
  transitTime: number | null;
  notes: string | null;
  rate?: OceanratesRate;
  surcharges?: OceanratesSurcharge[];
  destination?: City;
  origin?: City;
  portOfLoading?: City;
  portOfDischarge?: City;
};

export type OceanRateLookupData = {
  rateId: number;
  name: string;
  shippingLineId: number | null;
  segmentId: number;
  originId: number;
  portOfLoadingId: number;
  portOfDischargeId: number;
  destinationId: number;
  size: number;
  cost: number;
  effectiveDate: number;
  expirationDate: number;
  agreement: string;
  notes: string;
  comments: string | null;
  rateSurcharges: number;
  segmentSurcharges: number;
  totalSurcharges: number;
  totalCost: number;
  shippingLineName: string | null;
  previousSegmentId: number | null;
  previousTotalCost: number | null;
  deltaCost: number | null;
  originName: string;
  destinationName: string;
  portOfLoadingName: string;
  portOfDischargeName: string;
  freightForwarderId: number | null;
  freightForwarderName: string | null;
  freeDaysOrigin: number | null;
  freeDaysDestination: number | null;
  archived: YN;
  transitTime: number | null;
};

export type OceanratesSurcharge = {
  surchargeKey: number;
  sdefinitionKey: number;
  rateKey: number;
  segmentKey?: number | null;
  cost: number;
  notes?: string | null;
  surcharge?: OceanratesSurchargeDefinition;
  segment?: OceanratesSegment;
};

export type SegmentsRatesUsage = {
  segmentKey: number;
  rateKey: number;
  originId: number;
  portOfLoadingId: number;
  portOfDischargeId: number;
  destinationId: number;
  size: number;
  cost: number;
  transitTime: number | null;
  notes: string | null;
  archived: YN;
  inUse: YN;
};

export type OceanratesTemplates = {
  id: number;
  name: string;
  shippingLineId: number;
  archived: YN;
};

export type OceanratesTemplateCities = {
  id: number;
  templateId: number;
  name: string | null;
  cityCodes: string | null;
};

export type OceanratesSurchargeDefinition = {
  sdefinitionKey: number;
  name: string;
  group: string;
  notes?: string | null;
  surcharges?: OceanratesSurcharge[];
};
export type CreateOceanRateRequest = Subset<
  OceanratesRate,
  'name' | 'effective' | 'expire',
  'agreement' | 'freeDaysOrigin' | 'freeDaysDestination' | 'shippingLineId' | 'freightForwarderId' | 'notes' | 'comments'
> & {
  segments: UpsertOceanSegmentRequest[];
};
export type UpdateOceanRateRequest = Subset<OceanratesRate, 'rateKey', 'archived'> & CreateOceanRateRequest & { sendEmail: YN };
export type UpsertOceanSegmentRequest = Subset<
  OceanratesSegment,
  'segmentKey' | 'rateKey' | 'originId' | 'portOfLoadingId' | 'portOfDischargeId' | 'destinationId' | 'size' | 'cost',
  'transitTime' | 'notes' | 'archived'
> & {
  isUpdated: boolean;
  surcharges?: UpsertOceanSurchargeRequest;
};
export type UpsertOceanSurchargeRequest = Pick<OceanratesSurcharge, 'surchargeKey' | 'sdefinitionKey' | 'rateKey' | 'cost' | 'segmentKey' | 'notes'>[];

export type OceanRateForm = Subset<OceanratesRate, 'agreement' | 'name' | 'freeDaysOrigin' | 'freeDaysDestination', 'rateKey' | 'notes' | 'shippingLine' | 'freightForwarder' | 'archived'> & {
  effective: Date;
  expire: Date;
  segments?: OceanRateSegmentForm[];
  surchargesDefinition?: OceanRateSurchargesDefinitionForm[];
  isUsed?: YN;
  sendEmail: YN;
};

export type OceanRateSegmentForm = Subset<
  OceanratesSegment,
  'cost' | 'size' | 'origin' | 'portOfLoading' | 'portOfDischarge' | 'destination',
  'rateKey' | 'segmentKey' | 'archived' | 'notes' | 'transitTime'
> & {
  linePosition?: number;
  isUsed?: YN;
  isUpdated: boolean;
  surcharges?: OceanRateSurchargeForm[];
};

export type OceanRateSurchargeForm = Subset<OceanratesSurcharge, 'cost' | 'surcharge', 'segmentKey' | 'rateKey' | 'surchargeKey'> & {
  isUsed?: YN;
  isUpdated: boolean;
  linePosition?: number;
};

export type OceanRateSurchargesDefinitionForm = Pick<OceanratesSurcharge, 'surcharge'> & {
  container20Ft: number;
  container40Ft: number;
  container40FtHC: number;
  linePosition?: number;
};

export type CreateOceanratesTemplateCities = Pick<OceanratesTemplateCities, 'name' | 'cityCodes' | 'templateId'>[];

export const upsertOceanRateRequest = async (formVal: OceanRateForm) => {
  const effective = toBradyUTCDate(formVal.effective);
  const expire = toBradyUTCDate(formVal.expire);

  const request: CreateOceanRateRequest | UpdateOceanRateRequest = {
    rateKey: formVal.rateKey ?? null,
    agreement: formVal.agreement ?? '',
    comments: '',
    notes: formVal.notes ?? '',
    effective,
    expire,
    name: formVal.name,
    freeDaysOrigin: formVal.freeDaysOrigin ?? null,
    freeDaysDestination: formVal.freeDaysDestination ?? null,
    archived: formVal.archived ? formVal.archived : YN.N,
    shippingLineId: formVal.shippingLine ? formVal.shippingLine.id : null,
    freightForwarderId: formVal.freightForwarder ? formVal.freightForwarder.id : null,
    sendEmail: formVal.sendEmail,
    segments: formVal.segments
      ? formVal.segments.map((segment) => {
          const segmentsSurcharges: OceanRateSurchargeForm[] = [];
          if (segment.surcharges && segment.surcharges.length > 0) {
            for (const surch of segment.surcharges) {
              if (surch.surcharge && surch.surcharge.sdefinitionKey && surch.cost) {
                segmentsSurcharges.push(surch);
              }
            }
          }
          return {
            originId: segment.origin.id,
            portOfLoadingId: segment.portOfLoading ? segment.portOfLoading?.id : segment.origin.id,
            portOfDischargeId: segment.portOfDischarge ? segment.portOfDischarge?.id : segment.destination.id,
            destinationId: segment.destination?.id,
            cost: segment.cost,
            size: segment.size,
            rateKey: segment.rateKey ?? null,
            segmentKey: segment.segmentKey ?? null,
            transitTime: segment.transitTime ?? null,
            notes: segment.notes ?? '',
            archived: segment.archived ? segment.archived : YN.N,
            isUpdated: segment.isUpdated ? segment.isUpdated : segmentsSurcharges.some((seg) => seg.isUpdated),
            surcharges:
              segmentsSurcharges && segmentsSurcharges.length > 0
                ? segmentsSurcharges.map((surcharge) => {
                    return {
                      surchargeKey: surcharge.surchargeKey ? surcharge.surchargeKey : undefined,
                      sdefinitionKey: surcharge.surcharge.sdefinitionKey,
                      rateKey: surcharge.rateKey ?? null,
                      segmentKey: surcharge.segmentKey ?? null,
                      cost: surcharge.cost,
                      notes: '',
                      isUpdated: surcharge.isUpdated,
                    };
                  })
                : [],
          };
        })
      : [],
  };
  return request;
};

export function getOceanRateFinderCustomData(segment: OceanRateLookupData) {
  let customSegmentLine: CustomRateFinderLine = {};

  const today = toBradyUTCDate(getTodayUTC());

  customSegmentLine.shippingAndFreightNames =
    segment.freightForwarderName && segment.shippingLineName && segment.shippingLineName !== segment.freightForwarderName
      ? `${segment.shippingLineName} (${segment.freightForwarderName})`
      : segment.freightForwarderName && !segment.shippingLineName
      ? `(${segment.freightForwarderName})`
      : segment.shippingLineName;

  customSegmentLine.originAndPOLNames =
    segment.originName && segment.portOfLoadingName && segment.originName !== segment.portOfLoadingName ? `${segment.originName} (${segment.portOfLoadingName})` : segment.originName;

  customSegmentLine.pODAndDestinationNames =
    segment.portOfDischargeName && segment.destinationName && segment.portOfDischargeName !== segment.destinationName
      ? `${segment.portOfDischargeName} (${segment.destinationName})`
      : segment.portOfDischargeName;

  customSegmentLine.expirationAndEfectiveDates =
    segment.effectiveDate && segment.expirationDate && segment.effectiveDate > today
      ? `${dateFormatter(segment.expirationDate)} (${dateFormatter(segment.effectiveDate)})`
      : `${dateFormatter(segment.expirationDate)}`;

  customSegmentLine.freeDaysOriginAndDestination =
    segment.freeDaysOrigin && segment.freeDaysDestination
      ? `${segment.freeDaysOrigin}/${segment.freeDaysDestination}`
      : !segment.freeDaysOrigin && segment.freeDaysDestination
      ? `-/${segment.freeDaysDestination}`
      : segment.freeDaysOrigin && !segment.freeDaysDestination
      ? `${segment.freeDaysOrigin}/-`
      : `-/-`;

  return customSegmentLine;
}

export type CustomRateFinderLine = {
  shippingAndFreightNames?: string;
  originAndPOLNames?: string;
  pODAndDestinationNames?: string;
  expirationAndEfectiveDates?: string;
  freeDaysOriginAndDestination?: string;
};
