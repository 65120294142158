import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { debounceTime } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { earlierThanValidator, greaterThanOrEqualToValidator, minValidator } from 'src/lib/genericValidators';
import { enumOptionsFromEnum, getTodayUTC, getTomorrowUTC, markFormGroupTouched } from 'src/lib/helperFunctions';
import { ChunkType, CommonPackingTypes, PropertyDocument, ShipmentPacking, Unit } from 'src/lib/newBackendTypes';
import { TypedFormArray, TypedFormControl, TypedFormGroup } from 'src/lib/typedForms';

@UntilDestroy()
@Component({
  selector: 'shipment-split',
  templateUrl: './shipment-split.component.html',
  styleUrls: ['./shipment-split.component.scss'],
})
export class ShipmentSplitComponent implements OnInit, OnDestroy, ModalFormComponent<SplitShipmentForm> {
  form: TypedFormGroup<SplitShipmentForm>;
  popup = true;

  @Input()
  sourceShipment: PropertyDocument;

  unit: Unit;

  chunkTypes = enumOptionsFromEnum(ChunkType, [ChunkType.WRITTEN_OFF]);
  weightFormat: NumberFormatOptions;
  intFormat: NumberFormatOptions;

  totalNet: number;
  totalGross: number;

  totalNetMatch = false;
  totalGrossMatch = false;

  packingTotals: (ShipmentPacking & { packingTypeName: string })[];

  constructor(private commonData: CommonDataService) {
    this.form = new TypedFormGroup<SplitShipmentForm>(
      {
        linkChildShipment: new UntypedFormControl(),
        childShipments: new TypedFormArray<NewChildShipment>([]),
        valueDate: new TypedFormControl<Date>(getTodayUTC(), [Validators.required, earlierThanValidator(getTomorrowUTC(), `Date must be today or earlier`)]),
      },
      this.totalNetWeightValidator()
    );

    this.weightFormat = { minimumFractionDigits: 0, maximumFractionDigits: 4, useGrouping: true };
    this.intFormat = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    this.addChild();

    this.totalNet = 0;
    this.totalGross = 0;
    this.packingTotals = [];

    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(400)).subscribe(() => {
      this.calculateGross();
      this.calculateNet();
      this.calculatePackingTotals();
    });
  }

  ngOnInit(): void {
    this.unit = this.sourceShipment ? this.commonData.staticUnits.value.find((u) => u.unitId === this.sourceShipment.unitId) : null;
  }

  ngOnDestroy(): void {}

  prefillForm(val: SplitShipmentForm) {
    (this.form.get('childShipments') as UntypedFormArray).clear();
    this.form.reset();

    for (let c of val.childShipments) {
      let childForm = this.addChild();
      (childForm.get('packingTypes') as UntypedFormArray).clear();
      for (let _ in c.packingTypes) {
        this.addPacking(childForm);
      }
      if (!c.packingTypes || c.packingTypes.length === 0) {
        this.addPacking(childForm);
      }
    }

    this.form.patchValue(val);
  }

  allowSubmit() {
    this.markAsTouched();
    this.calculateGross();
    this.calculateNet();
    this.calculatePackingTotals();

    if (!this.form.valid) return false;

    return this.form.valid;
  }

  submit() {
    return this.form.value;
  }

  addChild() {
    let fg = new TypedFormGroup<NewChildShipment>({
      netWeight: new TypedFormControl<number>(0, [Validators.required, minValidator(0, false)]),
      grossWeight: new TypedFormControl<number>(0),
      packingTypes: new TypedFormArray<ShipmentPacking>([]),
      status: new TypedFormControl<ChunkType>(null, [Validators.required]),
    });

    fg.get('grossWeight').setValidators([Validators.required, minValidator(0, false), greaterThanOrEqualToValidator(fg.get('netWeight'), 'Gross Weight must be greater than or equal to net weight')]);

    (this.form.get('childShipments') as UntypedFormArray).push(fg);
    this.addPacking(fg);

    return fg;
  }

  removeChild(index: number) {
    (this.form.get('childShipments') as UntypedFormArray).removeAt(index);
    this.calculateGross();
    this.calculateNet();
    this.calculatePackingTotals();
  }

  addPacking(shipmentForm: TypedFormGroup<NewChildShipment>) {
    let fg = new TypedFormGroup<Pick<ShipmentPacking, 'packingTypeId' | 'quantity'>>({
      packingTypeId: new TypedFormControl<number>(null),
      quantity: new TypedFormControl<number>(null),
    });

    fg.get('packingTypeId').setValidators([this.packingValidator(fg, shipmentForm), this.duplicatePackingTypeValidator(shipmentForm)]);
    fg.get('quantity').setValidators(this.packingValidator(fg, shipmentForm));

    (shipmentForm.get('packingTypes') as UntypedFormArray).push(fg);
  }

  removePacking(i: number, shipmentForm: TypedFormGroup<NewChildShipment>) {
    let arr = <UntypedFormArray>shipmentForm.get('packingTypes');
    arr.removeAt(i);
    this.calculatePackingTotals();
  }

  packingValidator(fg: TypedFormGroup<Pick<ShipmentPacking, 'packingTypeId' | 'quantity'>>, shipmentForm: TypedFormGroup<NewChildShipment>) {
    return (control) => {
      let i = (shipmentForm.get('packingTypes') as UntypedFormArray).controls.indexOf(fg);
      if (i === 0 || !!fg.value.packingTypeId || (fg.value.quantity !== null && fg.value.quantity !== undefined)) return Validators.required(control);
      return null;
    };
  }

  duplicatePackingTypeValidator(fg: TypedFormGroup<NewChildShipment>) {
    return (control: AbstractControl) => {
      let packingId: number = control.value;
      if (!packingId || !fg) return;

      let matching = fg.value.packingTypes.filter((pt) => pt.packingTypeId === packingId);

      return matching.length > 1 ? { custom: 'Duplicate packing types' } : null;
    };
  }

  markAsTouched() {
    markFormGroupTouched(this.form);
  }

  onNetBlur(fg: TypedFormGroup<NewChildShipment>) {
    fg.get('grossWeight').updateValueAndValidity();
  }

  totalNetWeightValidator() {
    return (control: TypedFormGroup<SplitShipmentForm>) => {
      if (!this.sourceShipment) return null;
      let value = control.value;

      let net = 0;
      for (let c of value.childShipments) {
        net += c.netWeight || 0;
      }

      let totalDelta = Math.abs(this.sourceShipment.netWeight - net);
      if (totalDelta >= 0.00001) {
        return {
          custom: `Total net weight must equal original net weight of ${this.sourceShipment.netWeight} ${this.unit.code} exactly`,
        };
      }
    };
  }

  calculateNet() {
    this.totalNet = this.form.value.childShipments.map((c) => c.netWeight).reduce((p, c) => p + c, 0);

    let totalDelta = Math.abs(this.sourceShipment.netWeight - this.totalNet);
    this.totalNetMatch = totalDelta < 0.00001;
  }
  calculateGross() {
    this.totalGross = this.form.value.childShipments.map((c) => c.grossWeight || 0).reduce((p, c) => p + c, 0);

    let totalDelta = Math.abs(this.sourceShipment.grossWeight - this.totalGross);
    this.totalGrossMatch = totalDelta < 0.00001;
  }

  calculatePackingTotals() {
    this.packingTotals = [];

    let packingData = this.form.value.childShipments.flatMap((s) => s.packingTypes);
    for (let pd of packingData) {
      if (!pd.packingTypeId || pd.quantity === null || pd.quantity === undefined) continue;
      const existing = this.packingTotals.find((total) => total.packingTypeId === pd.packingTypeId);
      if (existing !== undefined) {
        if (pd.packingTypeId === CommonPackingTypes.LOOSE) continue;
        existing.quantity += pd.quantity;
      } else {
        let type = this.commonData.staticPackingTypes.value.find((t) => t.packingKey === pd.packingTypeId);
        this.packingTotals.push({
          ...pd,
          packingTypeName: type ? type.packingName : pd.packingTypeId.toString(),
        });
      }
    }
  }
}

export type SplitShipmentForm = {
  childShipments: NewChildShipment[];
  valueDate: Date;
  linkChildShipment?: boolean;
};

export type NewChildShipment = Pick<PropertyDocument, 'netWeight' | 'grossWeight' | 'status'> & {
  packingTypes: {
    packingTypeId: number;
    quantity: number;
  }[];
};
