import { Component, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { combineLatest, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { DropdownConfig, ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { endpointAuthorizationSubscription, endpointsAuthorized, markFormGroupTouched } from 'src/lib/helperFunctions';
import { Contact } from 'src/lib/newBackendTypes';
import { Comment, CreateCommentRequest, UpdateCommentRequest } from 'src/lib/newBackendTypes/comment';
import { CommentCategory } from 'src/lib/newBackendTypes/commentCategory';
import { YN } from 'src/lib/newBackendTypes/enums';
import { SubEntityContainer } from 'src/lib/SubEntityContainer';
import { randomFetchSynonym } from 'src/lib/uiConstants';

type commentState = 'hide' | 'show' | 'short';

export type LocalEntityComment = Subset<Comment, 'id' | 'comment' | 'owner' | 'shared' | 'creationDate' | 'date' | 'categoryId' | 'noteCategory' | 'commentOwner', 'updateDate'>;
export type LocalComment = LocalEntityComment & {
  editing?: boolean;
  visibleText: string;
  croppedText: string;
  state: commentState;
  edited: boolean;
};

@UntilDestroy()
@Component({
  selector: 'entity-comments',
  templateUrl: './entity-comments.component.html',
  styleUrls: ['./entity-comments.component.scss'],
  providers: [{ provide: SubEntityContainer, useExisting: forwardRef(() => EntityCommentsComponent) }],
})
export class EntityCommentsComponent extends SubEntityContainer<LocalEntityComment> {
  currentUser: Contact;

  editCommentFG: UntypedFormGroup;

  newCommentFG: UntypedFormGroup;

  _value: LocalComment[] = [];

  newCommentVisible: boolean;
  visibleEditComment: boolean;

  loadingComments = false;

  categoryDropdown: DropdownConfig<CommentCategory>;

  getValue(): (CreateCommentRequest | UpdateCommentRequest)[] {
    return this._value
      .filter((c) => !c.id || !!c.edited)
      .map((c) => {
        if (!c.id) {
          return {
            type: this.entityType,
            entityId: this.entityId,
            categoryId: c.categoryId,
            comment: c.comment,
            shared: c.shared,
          };
        } else {
          return {
            id: c.id,
            comment: c.comment,
            categoryId: c.categoryId,
            shared: c.shared,
          };
        }
      });
  }

  authorized: endpointsAuthorized;

  setValue(comments: LocalEntityComment[]) {
    this.setComments(comments);
  }

  constructor(controlDir: NgControl, store: Store, route: ActivatedRoute, private dialogService: DialogService, private api: ThalosApiService, private spinnerService: SpinnerService) {
    super(route, controlDir, store);

    endpointAuthorizationSubscription(store, this);

    this.newCommentFG = new UntypedFormGroup({
      text: new UntypedFormControl('', [Validators.minLength(6)]),
      shared: new UntypedFormControl(),
      category: new UntypedFormControl(),
    });
    this.editCommentFG = new UntypedFormGroup({
      text: new UntypedFormControl('', [Validators.minLength(6), Validators.required]),
      shared: new UntypedFormControl(),
      category: new UntypedFormControl(),
    });

    this.currentUser = store.snapshot((state) => state.user.user);

    this.newCommentVisible = false;
    this.visibleEditComment = false;

    this.categoryDropdown = new DropdownConfig<CommentCategory>(endpoints.listCommentCategories, 'categoryName', 'noteCatKey');
  }

  ngOnInit() {
    this.getFormControl().setValidators(this.validate);
    super.ngOnInit();
  }

  refreshForm() {
    this._value = [];
    this.loadSubForm();
  }

  loadSubForm() {
    if (!!this.entityId && this.authorized[endpoints.listComments]) {
      let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Comments', undefined, false, !this.showSpinner);
      setTimeout(() => {
        this.loadingComments = true;
        this.api.rpc<ListResponse<LocalEntityComment>>(endpoints.listComments, { filters: { entityId: this.entityId, type: this.entityType } }, { list: [], count: 0 }).subscribe((res) => {
          setTimeout(() => {
            this.loadingComments = false;
            this.value = res.list;
            this.spinnerService.completeRequest(rid);
          });
        });
      });
    }
    this.newCommentVisible = false;
    this.visibleEditComment = false;
  }

  submitNewComment() {
    let { text, shared, category }: { text: string; shared: YN; category?: CommentCategory } = this.newCommentFG.value;

    if (this.newCommentFG.valid === false) {
      this.newCommentFG.markAsTouched();
      return;
    }

    const commentOwner = this.currentUser;

    let comment: LocalComment = {
      id: null,
      date: new Date(),
      owner: this.currentUser.id,
      commentOwner,
      shared,
      comment: text,
      categoryId: category?.noteCatKey ?? null,
      noteCategory: category,
      editing: false,
      updateDate: new Date(),
      creationDate: new Date(),
      visibleText: '',
      croppedText: '',
      state: 'show',
      edited: false,
    };

    this.trimText(comment);

    this._value.push(comment);
    this.newCommentVisible = false;

    this.onChange(this.value);
    if (this._readonlyMode && !!this.entityId) {
      let rid = this.spinnerService.startRequest('Saving comment');
      this.save(this.entityId).subscribe(() => {
        this.spinnerService.completeRequest(rid);
        this.refreshForm();
      });
    }
  }

  deleteComment(comment: LocalComment): Observable<LocalEntityComment> {
    if (comment.id) {
      let rId = this.spinnerService.startRequest('Deleting LocalEntityComment');
      return this.api.rpc(endpoints.deleteComment, { filters: { id: comment.id } }, null).pipe(
        tap((c) => {
          this.spinnerService.completeRequest(rId);
          if (!!c) {
            let index = this._value.indexOf(comment);
            this._value.splice(index, 1);
          }
        })
      );
    } else {
      let index = this._value.indexOf(comment);
      this._value.splice(index, 1);
      return of(comment);
    }
  }

  clickDelete(comment: LocalComment) {
    if (comment.owner !== this.currentUser.id) return;

    this.dialogService
      .open({
        content: 'Are you sure you wish to delete this comment?  This cannot be undone.',
        actions: [
          {
            text: 'Cancel',
          },
          {
            themeColor: 'primary',
            text: 'Delete',
          },
        ],
      })
      .result.subscribe(async (res) => {
        if (!(res instanceof DialogCloseResult) && res.text === 'Delete') {
          this.deleteComment(comment).subscribe();
        }
      });
  }

  saveComment(comment: LocalComment) {
    if (this.editCommentFG.valid === false) return;

    let { text, shared, category }: { text: string; shared: YN; category?: CommentCategory } = this.editCommentFG.value;

    //Shared toggle should not be visible anyway
    if (this.currentUser.id !== comment.owner) {
      shared = YN.N;
    }

    comment.comment = text;
    comment.shared = shared;
    comment.editing = false;
    comment.edited = true;
    comment.noteCategory = category;
    comment.categoryId = category?.noteCatKey ?? null;

    this.trimText(comment);

    this.visibleEditComment = false;

    this.onChange(this.value);
    if (this._readonlyMode && !!this.entityId) {
      let rid = this.spinnerService.startRequest('Saving comment');
      this.save(this.entityId).subscribe(() => {
        this.spinnerService.completeRequest(rid);
        this.refreshForm();
      });
    }
  }

  setComments(comments: LocalEntityComment[]) {
    if (comments)
      this._value = comments
        .sort((a, b) => {
          return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
        })
        .map(this.createLocal);
    else {
      this._value = [];
    }
  }

  createLocal = (comment: LocalEntityComment | LocalComment): LocalComment => {
    let local: LocalComment = {
      visibleText: '',
      croppedText: '',
      state: 'short',
      ...comment,
      editing: false,
      edited: false,
    };

    this.trimText(local);

    return local;
  };

  trimText(comment: LocalComment) {
    let visibleText: string;
    let croppedText: string = '';
    let state: commentState;
    visibleText = comment.comment
      .trim()
      .replace(/\n/g, '<br/>')
      .replace(/<br\/>(?: *(<br\/>)+)+/gm, '<br/><br/>');
    if (comment.comment.length > 600) {
      croppedText = visibleText.substr(0, 600) + '...';
      state = 'hide';
    } else {
      state = 'short';
    }

    comment.visibleText = visibleText;
    comment.croppedText = croppedText;
    comment.state = state;
  }

  toggleEditComment(comment: LocalComment) {
    if (comment.owner !== this.currentUser.id && comment.shared === 'N') return;
    if (this.visibleEditComment && comment.editing === false) {
      this.dialogService
        .open({
          content: 'You were previously editing a comment, are you sure you wish to discard your changes?',
          actions: [
            {
              text: 'Cancel',
            },
            {
              text: 'Discard',
              themeColor: 'primary',
            },
          ],
        })
        .result.subscribe((res) => {
          if (!(res instanceof DialogCloseResult) && res.text === 'Discard') {
            let c = this._value.find((comment) => comment.editing);
            if (!!c) c.editing = false;

            comment.editing = true;
            this.editCommentFG.setValue({
              text: comment.comment,
              shared: comment.shared,
              category: comment.noteCategory ?? null,
            });
            this.editCommentFG.markAsUntouched();
            this.editCommentFG.markAsPristine();
          }
        });
    } else if (comment.editing === false) {
      comment.editing = true;
      this.visibleEditComment = true;
      this.editCommentFG.setValue({
        text: comment.comment,
        shared: comment.shared,
        category: comment.noteCategory ?? null,
      });
      this.editCommentFG.markAsUntouched();
      this.editCommentFG.markAsPristine();
    } else {
      this.dialogService
        .open({
          content: 'Are you sure you wish to discard your changes?',
          actions: [
            {
              text: 'Cancel',
            },
            {
              text: 'Discard',
              themeColor: 'primary',
            },
          ],
        })
        .result.subscribe((res) => {
          if (!(res instanceof DialogCloseResult) && res.text === 'Discard') {
            comment.editing = false;
            this.visibleEditComment = false;
          }
        });
    }
  }

  toggleNewComment() {
    if (!this.newCommentVisible) {
      this.newCommentFG.setValue({
        text: '',
        shared: YN.Y,
        category: null,
      });
      this.newCommentFG.markAsUntouched();
      this.newCommentFG.markAsPristine();
      this.newCommentVisible = true;
    } else {
      this.dialogService
        .open({
          content: 'Are you sure you wish to discard your comment?',
          actions: [
            {
              text: 'Cancel',
            },
            {
              text: 'Discard',
              themeColor: 'primary',
            },
          ],
        })
        .result.subscribe((res) => {
          if (!(res instanceof DialogCloseResult) && res.text === 'Discard') {
            this.newCommentVisible = false;
          }
        });
    }
  }

  readMore(comment: LocalComment) {
    if (comment.state === 'short') return;
    if (comment.state === 'hide') {
      comment.state = 'show';
    } else {
      comment.state = 'hide';
    }
  }

  save(entityId: number) {
    this.markAsTouched();
    if (this.editCommentFG && this.editCommentFG.valid) {
      let c = this._value.find((v) => v.editing);
      if (!!c) this.saveComment(c);
    }
    if (this.newCommentVisible && this.newCommentFG.valid && !!this.newCommentFG.value.text) {
      this.submitNewComment();
    }
    //first see if existing comment is there
    let comments: (CreateCommentRequest | UpdateCommentRequest)[] = this.value;
    let observables: Observable<LocalEntityComment>[] = [];
    for (let c of comments) {
      if ((<UpdateCommentRequest>c).id) {
        if (this.updateAuthorized) {
          observables.push(this.api.rpc<LocalEntityComment>(endpoints.updateComment, c, null));
        }
      } else {
        if (this.createAuthorized) {
          observables.push(this.api.rpc<LocalEntityComment>(endpoints.createComment, { ...c, entityId }, null));
        }
      }
    }
    return observables.length > 0 ? combineLatest(observables) : of([]);
  }

  validate = (formControl: AbstractControl) => {
    return (this.newCommentVisible && !this.newCommentFG.valid) || (this.visibleEditComment && !this.editCommentFG.valid) ? { comments: true } : null;
  };

  markAsTouched() {
    markFormGroupTouched(this.newCommentFG);
    markFormGroupTouched(this.editCommentFG);
  }

  get createAuthorized(): boolean {
    return this.authorized[endpoints.createComment];
  }

  get updateAuthorized(): boolean {
    return this.authorized[endpoints.updateComment];
  }

  get deleteAuthorized(): boolean {
    return this.authorized[endpoints.deleteComment];
  }
}
