import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { amountCurrencyColumn, dateColumn, dynamicFormItem, getContextMenuItems, gotoMenu, gotoMenuItem } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType, Document, PrintDocumentResponse, StorageTypes } from 'src/lib/newBackendTypes';
import { LoCPresentations } from 'src/lib/newBackendTypes/letterOfCreditPresentation';
import { LetterOfCreditPresentationList } from 'src/lib/newBackendTypes/letterOfCreditPresentationList';
import { PrintDocumentComponent, PrintDocumentForm } from 'src/app/shared/print-document/print-document.component';
import { CustomDialogResult } from 'src/app/core/services/selector-popup.service';
import { DialogAction } from '@progress/kendo-angular-dialog';
import { handlePrintResponse } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { printPresentationPreset } from 'src/lib/flex/forms/printPresentation';

@Component({
  selector: 'letter-of-credit-presentations',
  templateUrl: './letter-of-credit-presentations.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LetterOfCreditPresentationsComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => LetterOfCreditPresentationsComponent),
    },
  ],
})
export class LetterOfCreditPresentationsComponent implements OnInit, OnDestroy {
  @Input()
  presentations: LetterOfCreditPresentationList[];

  @Output()
  createPresentation: EventEmitter<void> = new EventEmitter();

  @Output()
  openPresentation: EventEmitter<void> = new EventEmitter();

  gridOptions: GridOptions;

  createPresentationAuthorized: boolean;
  updatePresentationAuthorized: boolean;

  constructor(formatter: DataFormattingService, delegate: DelegateService, entityLookup: EntityLookupService) {
    let body = document.querySelector('body');

    this.createPresentationAuthorized = entityLookup.entityPathExists('create', SourceEntityType.LETTER_OF_CREDIT_PRESENTATION);
    this.updatePresentationAuthorized = entityLookup.entityPathExists('get', SourceEntityType.LETTER_OF_CREDIT_PRESENTATION);

    this.gridOptions = {
      domLayout: 'autoHeight',
      popupParent: body,
      defaultColDef: {
        width: 150,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      columnDefs: [
        { field: 'reference', headerName: 'Reference', width: 175 },
        { ...amountCurrencyColumn(formatter, 'currencyId', 'presentationAmount'), width: 200 },
        { field: 'term', headerName: 'Payment Term', width: 175 },
        { ...dateColumn('date'), headerName: 'Date' },
        { field: 'status', headerName: 'Status' },
        { field: 'invoiceNumber', headerName: 'Voucher' },
        { field: 'trackingNumber', headerName: 'Tracking Number' },
        { field: 'received', headerName: 'Received' },
        { ...dateColumn('receivedDate'), headerName: 'Received Date' },
        { ...dateColumn('maturityDate'), headerName: 'Maturity Date' },
      ],
      getContextMenuItems: getContextMenuItems(
        gotoMenu(
          gotoMenuItem(delegate, 'Invoice', 'invoiceId', SourceEntityType.INVOICE_KEY, 'get'),
          gotoMenuItem(delegate, 'Presentation', 'presId', SourceEntityType.LETTER_OF_CREDIT_PRESENTATION, 'get')
        ),
        {
          name: 'Forms',
          menuItems: [dynamicFormItem(delegate, printPresentationPreset, 'presId', 'reference')],
        }
      ),
      onRowDoubleClicked: this.doubleClickRow(),
    };
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onGridReady() {}

  clickCreatePresentation() {
    if (this.createPresentationAuthorized) {
      this.createPresentation.emit();
    }
  }

  doubleClickRow() {
    return (params: RowDoubleClickedEvent) => {
      if (!params.data || !this.updatePresentationAuthorized || !params.data.presentationId) return;

      this.openPresentation.emit(params.data);
    };
  }
}

export function printPresentation(delegate: DelegateService, presentation: LoCPresentations, documents: Document[]) {
  const selector = delegate.getService('selector');
  const api = delegate.getService('api');
  const spinner = delegate.getService('spinner');
  const dialog = delegate.getService('dialog');
  selector
    .openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
      title: 'Generate Document',
      submitButtonText: 'Generate',
      initializer: (p: PrintDocumentComponent) => {
        p.documents = documents;
        p.entityName = presentation.trackingNumber;
        p.entityType = SourceEntityType.LETTER_OF_CREDIT_PRESENTATION;
      },
      maxWidth: 400,
    })
    .subscribe((popupResult) => {
      if (popupResult !== 'Close') {
        const request = {
          packetId: popupResult.packet?.id,
          outputName: popupResult.saveAs,
          savingLocation: popupResult.location === -1 ? null : popupResult.location,
          entityId: presentation.id,
        };
        const rid = spinner.startRequest('Generating Document');
        api
          .rpc<PrintDocumentResponse>(endpoints.generateDocumentsFromPacket, request, null, {
            blockRedirect: true,
          })
          .subscribe((packet) => {
            spinner.completeRequest(rid);

            const actions: CustomDialogResult[] = [{ text: 'Close' }];
            if (/Win/.test(navigator.platform) || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
              actions.push({ text: 'Open File', themeColor: 'primary' });
            }
            dialog
              .open({
                title: 'Success',
                content: 'File generated successfully',
                actions,
              })
              .result.subscribe((action) => {
                if ((action as DialogAction).text === 'Open File') {
                  handlePrintResponse(delegate, packet);
                }
              });
          });
      }
    });
}
