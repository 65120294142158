<div [formGroup]="form" style="margin: 0 -16px">
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field name="Number" class="col-xxl-1 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <label class="col-form-label col-form-label-sm d-flex justify-content-lg-end col-form-label-offset">
          {{ type }}
        </label>
        <input-wrapper [formControlName]="'number'" placeholder="(auto)" (blur)="validateClientInvoiceNumber(form.value.number)"> </input-wrapper>
      </vertical-field>
      <vertical-field name="Counterparty" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'counterparty'" [dropdownConfig]="counterpartyDropdown" [readonly]="true"></dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Payment Term" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'paymentTerm'" [dropdownConfig]="paymentTermDropdown"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Invoice Date" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'invoiceDate'" [showCalculator]="true"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Due Date" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'dueDate'" [showCalculator]="true"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Number Of Decimals" class="col-xl-1 col-lg-2 col-md-2 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'numberOfDecimals'" [data]="numberOfDecimals" [textField]="'label'" [valuePrimitive]="true" [valueField]="'value'"> </simple-dropdown>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Shipments'">
    <client-invoice-shipments-component
      [formControlName]="'lines'"
      [currencyConversions]="currencyConversions"
      (emitTotalInvoice)="paymentsCreditsLines.getTotalInvoice($event)"
      [numberOfDecimals]="selectedNumberOfDecimals"
    >
    </client-invoice-shipments-component>
  </collapsible-card>
  <div class="row totals">
    <div class="container-footer col-6 no-gutters">
      <checkbox-wrapper [formControlName]="'oneShipmentPerInvoice'" [valueMask]="{ true: 'Y', false: 'N' }" [label]="'Create one invoice per shipment'"> </checkbox-wrapper>
    </div>
  </div>
  <collapsible-card [title]="'Apply Payments/Credits'">
    <client-invoice-payments-credits [formControlName]="'paymentsCreditsLines'" [oneShipmentPerInvoice]="form.value.oneShipmentPerInvoice" #paymentsCreditsLines> </client-invoice-payments-credits>
  </collapsible-card>
</div>
