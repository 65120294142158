import { DialogResult } from '@progress/kendo-angular-dialog';
import { from } from 'rxjs';
import { ManageQPComponent } from 'src/app/+modules/+hedging/manage-qp/manage-qp.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { ContractLine, ContractPricing, PhysicalContract, PhysicalPricingType, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const manageQPPrefill: prefillCallback<ManageQPPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const contractLine = await api.run<ContractLine>(endpoints.getContractLineData, {
        filters: { id: id },
      });
      return contractLine;
    })()
  );
};

const createManageQPForm: openFormCallback<ManageQPPrefill, ManageQPForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<ManageQPForm, ManageQPComponent, ManageQPPrefill>(ManageQPComponent, {
    title: `Manage QP For Contract: ${prefill ? prefill.contract.number + '/' + prefill.lineNumber : 'Unknown'}`,
    prefillValue: prefill,
  });
};

const submitQPNotes: submitFormCallback<ManageQPPrefill, ManageQPForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        try {
          let pricing: ContractPricing[];

          for (const price of prefill.pricing) {
            if (price.pricingType === PhysicalPricingType.PRICE) {
              pricing = [
                {
                  ...price,
                  qpStatus: result.qpStatus,
                  notes: result.notes,
                },
              ];
            }
          }

          const request: UpdateContractLine = {
            id: prefill.contract.id,
            lines: [
              {
                id,
                pricing,
              },
            ],
          };

          const updateContractResponse = await api.run<PhysicalContract>(endpoints.updateContract, request, null);
          return resolve(prompt.htmlDialog('Success', `<div style="white-space: pre">The QP for Contract ${updateContractResponse.number + '/' + prefill.lineNumber} have been updated.</div>`));
        } catch (err) {
          return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the contract QP was updated and try again if necessary.</div>`));
        }
      })();
    })
  );
};

export const manageQPPreset: DynamicFormConstant<ManageQPPrefill, ManageQPForm> = {
  allowMultipleRows: false,
  openForm: createManageQPForm,
  entityType: SourceEntityType.CONTRACT_LINE_ID,
  getPrefill: manageQPPrefill,
  label: 'Manage QP',
  submitForm: submitQPNotes,
  title: 'Manage QP',
  value: DynamicFormType.MANAGE_QP,
  endpoints: [endpoints.getContractLineData, endpoints.updateContract],
  width: 550,
};

export type ManageQPPrefill = Subset<ContractLine, 'id' | 'lineNumber' | 'contract' | 'pricing'>;

export type ManageQPForm = Omit<ManageQPPrefill, 'lineNumber'> & {
  qpStatus: number;
  notes: string;
};

type UpdateContractLine = {
  id: number;
  lines?: Subset<ContractLine, 'id', 'pricing'>[];
};
