import { map } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { GlAccountLeaves, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const glAccountPrefillCallback: prefillCallback<GlAccountLeaves> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<GlAccountLeaves>(endpoints.getGlAccount, { filters: { id } }, null);
};

const openGlAccountArchiveUnarchiveForm: openFormCallback<GlAccountLeaves, YN> = (delegate, id, prefill) => {
  const text = prefill.archived === YN.N ? `GL Account will be Archived. Continue?` : `GL Account will be Unarchived. Continue?`;

  const prompt = delegate.getService('prompt');

  return prompt.simpleConfirmation(`Update GL Account`, text).pipe(map((answer) => (answer ? prefill.archived : 'Close')));
};

export const archiveUnarchiveGlAccountCallback: submitFormCallback<GlAccountLeaves, YN> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const request = {
    id,
    archived: form === YN.N ? YN.Y : YN.N,
  };

  return api.rpc<GlAccountLeaves>(endpoints.archiveUnarchiveGlAccount, request, null);
};

export const archiveUnarchiveGlAccountPreset: DynamicFormConstant<GlAccountLeaves, YN> = {
  value: DynamicFormType.ARCHIVE_UNARCHIVE_GLACCOUNT,
  entityType: SourceEntityType.GL_ACCOUNT_LEAVES_ID,
  label: 'Archive/Unarchive GL Account',
  title: 'Archive/Unarchive GL Account',
  width: 200,
  endpoints: [endpoints.getGlAccount, endpoints.archiveUnarchiveGlAccount],
  getPrefill: glAccountPrefillCallback,
  openForm: openGlAccountArchiveUnarchiveForm,
  submitForm: archiveUnarchiveGlAccountCallback,
};
