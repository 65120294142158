import { of, tap } from 'rxjs';
import { printPresentation } from 'src/app/+modules/+accounting/letter-of-credit/letter-of-credit-presentations/letter-of-credit-presentations.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { LoCPresentations } from 'src/lib/newBackendTypes/letterOfCreditPresentation';
import { DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

export const presentationPrefill: prefillCallback<LoCPresentations> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<LoCPresentations>(endpoints.getLcPresentation, { filters: { id } }, null);
};

/**
 * @todo Make this generic
 */
export const printPresentationCallback: submitFormCallback<LoCPresentations> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  if (!form.id) return of(null);
  return api.rpc<ListResponse<Document>>(endpoints.listDocuments, { filters: { entityId: form.id, entityType: SourceEntityType.LETTER_OF_CREDIT_PRESENTATION } }, null).pipe(
    tap((docResponse) => {
      if (docResponse) {
        printPresentation(delegate, form, docResponse.list);
      }
    })
  );
};

export const printPresentationPreset: DynamicFormConstant<LoCPresentations> = {
  allowMultipleRows: false,
  endpoints: [endpoints.getLcPresentation, endpoints.listDocuments, endpoints.generateDocumentsFromPacket],
  entityType: SourceEntityType.LETTER_OF_CREDIT_PRESENTATION,
  getPrefill: presentationPrefill,
  label: 'Print docs',
  submitForm: printPresentationCallback,
  title: 'Print docs',
  value: DynamicFormType.PRINT_PRESENTATION,
  width: 300,
};
