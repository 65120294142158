import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { intFormat } from 'src/lib/commonTypes';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { TypedFormGroup } from 'src/lib/typedForms';
import { FormElementComponent } from '../form-elements/form-element/form-element.component';

@UntilDestroy()
@Component({
  selector: 'date-calculator',
  templateUrl: './date-calculator.component.html',
})
export class DateCalculatorComponent implements OnInit, OnDestroy, ModalFormComponent<Date> {
  @ViewChild('first', { static: false })
  first: FormElementComponent;

  form: TypedFormGroup<DateCalculatorForm>;

  popup = true;

  intFormat = intFormat();

  constructor() {
    this.form = new TypedFormGroup<DateCalculatorForm>({
      type: new UntypedFormControl('Days Since'),
      fromDate: new UntypedFormControl(null),
      days: new UntypedFormControl(0),
      toDate: new UntypedFormControl(null),
    });

    combineLatest([this.form.get('days').valueChanges, this.form.get('fromDate').valueChanges])
      .pipe(untilDestroyed(this))
      .subscribe(([days, fromDate]: [number, Date]) => {
        if (days === null || fromDate === null) {
          this.form.patchValue({ toDate: null });
        } else {
          const toDate = new Date(fromDate);
          toDate.setDate(toDate.getDate() + days);
          this.form.patchValue({ toDate });
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (this.first) {
      setTimeout(() => {
        this.first.focus();
      });
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);

    return !!this.form.value.toDate;
  }

  prefillForm(fromDate: Date) {
    this.form.patchValue({ fromDate });
  }

  submit() {
    return this.form.value.toDate;
  }
}

type DateCalculatorForm = {
  type: 'Days Since';
  fromDate: Date;
  days: number;
  toDate: Date;
};
