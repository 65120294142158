import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'input-wrapper',
  templateUrl: './input-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => InputWrapperComponent) }],
})
export class InputWrapperComponent extends FormElementComponent {
  @ViewChild('input', { static: false })
  input: ElementRef;

  _value: string | null = null;

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
  }

  @Input()
  maxLength?: number;

  @Input()
  type: 'color' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week' = 'text';

  getValue() {
    return typeof this._value === 'string' ? this._value.trim() : this._value;
  }

  public focus() {
    if (this.input) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      });
    }
  }

  comparator(a: string | null, b: string | null) {
    let trimmedA = typeof a === 'string' ? a.trim() : a;
    let trimmedB = typeof b === 'string' ? b.trim() : b;

    return trimmedA === trimmedB;
  }
}
