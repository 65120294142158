import { from, of } from 'rxjs';
import { ShipmentUpdateValueDateComponent, UpdateShipmentValueDateForm } from 'src/app/+modules/+logistics/shipment/shipment-update-value-date/shipment-update-value-date.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { PropertyDocument, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const shipmentPrefillCallback: prefillCallback<PropertyDocument> = (delegate, id) => {
  let api = delegate.getService('api');
  return api.rpc<PropertyDocument>(endpoints.getShipment, { filters: { id } }, null);
};

const updateShipmentValueDateCheck: checkPrefillCallback<PropertyDocument> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to update value date: shipment not found');
  return of(true);
};

const updateShipmetValueDateForm: openFormCallback<PropertyDocument, UpdateShipmentValueDateForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<UpdateShipmentValueDateForm, ShipmentUpdateValueDateComponent, PropertyDocument>(ShipmentUpdateValueDateComponent, {
    title: `Update Shipment: ${prefill.id} Value Date`,
    maxWidth: 400,
    prefillValue: prefill,
  });
};

const updateShipmentValueDateSubmit: submitFormCallback<Pick<PropertyDocument, 'valueDate'>> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<PropertyDocument>((resolve, reject) => {
      (async () => {
        const request: UpdateShipmentValueDateRequest = {
          id: id,
          valueDate: form.valueDate,
        };
        const updateShipment = await api.run<PropertyDocument>(endpoints.updateShipmentValueDate, request, null);
        if (updateShipment) return resolve(updateShipment);
        return reject('Unknown result. Please check if the Shipment Value Date was updated and try again if necessary.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Shipment ${res.id} Value Date successfully updated.</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const updateShipmentValueDatePreset: DynamicFormConstant<Pick<PropertyDocument, 'valueDate'>> = {
  value: DynamicFormType.UPDATE_SHIPMENT_VALUE_DATE,
  label: 'Update Value Date',
  title: 'Update Value Date',
  endpoints: [endpoints.getShipment, endpoints.updateShipmentValueDate],
  entityType: SourceEntityType.CHUNK_KEY,
  getPrefill: shipmentPrefillCallback,
  openForm: updateShipmetValueDateForm,
  checkPrefill: updateShipmentValueDateCheck,
  submitForm: updateShipmentValueDateSubmit,
  allowMultipleRows: false,
  width: 400,
};

export type UpdateShipmentValueDateRequest = Pick<PropertyDocument, 'id' | 'valueDate'>;
