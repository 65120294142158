import { endpoints } from 'src/lib/apiEndpoints';
import { Contact, UserAuthorizedCompanies } from 'src/lib/newBackendTypes';
import { UserGroup } from 'src/lib/newBackendTypes/userGroup';
import {
  LOADED_USER_ENDPOINTS,
  LOADED_USER_GRAPHQLS,
  LOGIN,
  LOGOUT,
  SET_AUTH_MESSAGE,
  SET_URL_TARGET,
  SET_USER,
  SET_USER_APPROVAL_TYPES,
  SET_USER_AUTHORIZED_COMPANIES,
  SET_USER_GROUPS,
} from './actions';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';

export class UserState {
  isLoggedIn: boolean = false;
  email: string = null;
  userEndpoints: endpoints[] = [];
  userGraphqls: ExistingGraphql[] = [];
  authorization: { readonly [key in endpoints]?: boolean } = {};
  graphqlAuthorization: { readonly [key in ExistingGraphql]?: boolean } = {};
  user: Contact;
  userApprovalTypes: number[] | null = null;
  userGroups: UserGroup[] = [];
  urlTarget: string = null;
  authMessage: string = null;
  authorizedCompanies: UserAuthorizedCompanies[];
}

const initialUserState = new UserState();

export function usersReducer(action, state = initialUserState): UserState {
  switch (action.type) {
    case LOGIN:
      const newUserState = new UserState();
      newUserState.email = action.payload.email;
      newUserState.isLoggedIn = true;
      return newUserState;
    case LOGOUT:
      return new UserState();
    case LOADED_USER_ENDPOINTS: {
      let userEndpoints: endpoints[] = action.payload ?? [];
      let authorization: { [key in endpoints]?: boolean } = {};
      for (let e of userEndpoints) {
        authorization[e] = true;
      }
      for (let e of Object.keys(endpoints)) {
        if (!authorization[e]) {
          authorization[e] = false;
        }
      }
      return {
        ...state,
        authorization,
        userEndpoints: action.payload,
      };
    }
    case LOADED_USER_GRAPHQLS: {
      const userGraphqls: ExistingGraphql[] = action.payload ?? [];
      const graphqlAuthorization: { [key in ExistingGraphql]?: boolean } = {};
      for (let e of userGraphqls) {
        graphqlAuthorization[e] = true;
      }
      for (const e of Object.keys(ExistingGraphql)) {
        if (!graphqlAuthorization[e]) {
          graphqlAuthorization[e] = false;
        }
      }
      return {
        ...state,
        graphqlAuthorization,
        userGraphqls: action.payload,
      };
    }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_APPROVAL_TYPES:
      return {
        ...state,
        userApprovalTypes: action.payload,
      };
    case SET_URL_TARGET:
      return {
        ...state,
        urlTarget: action.payload,
      };
    case SET_AUTH_MESSAGE:
      return {
        ...state,
        authMessage: action.payload,
      };
    case SET_USER_GROUPS: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case SET_USER_AUTHORIZED_COMPANIES: {
      return {
        ...state,
        authorizedCompanies: action.payload,
      };
    }
    default:
      return state;
  }
}

export class LoginAction {
  readonly type = LOGIN;
  constructor(public payload?: LoginActionPayload) {}
}

export type LoginActionPayload = {
  email: string;
  userRoles: RoleObject[];
  selectedRoleIndex: number;
};

// TODO Review RoleObject with current backend definition
export type RoleObject = {
  label: string;
  roleName: string;
  scopeValues: number[];
};
