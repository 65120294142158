import { Validators } from '@angular/forms';
import { from } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { WaConstelliumAppointment } from 'src/lib/newBackendTypes/waConstelliumAppointment';

const createAppointmentForm: createFormCallback<CreateAppointmentForm> = (delegate, id, prefill) => {
  return [
    [
      {
        type: 'TextArea',
        field: 'references',
        label: 'References',
        validator: [Validators.required],
      },
    ],
  ];
};

const createAppointmentCallback: submitFormCallback<CreateAppointmentForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<CreateAppointmentResponse>((resolve, reject) => {
      (async () => {
        const references: string[] = form.references.split('\n');
        const request: CreateAppointment = references.map((appointmentReference) => {
          return { elementKey: id, appointmentReference };
        });

        const createAppointment = await api.run<CreateAppointmentResponse>(endpoints.createLogisticsAppointments, request, null, {
          blockRedirect: true,
          hideErrorPopup: true,
        });

        if (createAppointment !== null) return resolve(createAppointment);
        return reject('Unknown result. Please check if the Appointment(s) were created and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const successMessage = 'Appointment(s) successfully created:';
          const errorMessage = 'The following Appointment(s) already exist. Could not create:';
          const successIds = res.success.map((ap) => `- ${ap.appointmentReference}`).join(`\n`);
          const failedIds = res.failed.map((apf) => `- ${apf}`).join(`\n`);
          if (res.success && res.success.length > 0 && res.failed && res.failed.length > 0)
            return prompt.htmlDialog('Warning', `<div style="white-space: pre">${successMessage} \n${successIds} \n\n${errorMessage} \n${failedIds}</div>`);
          if (res.success && res.success.length > 0 && !(res.failed && res.failed.length > 0))
            return prompt.htmlDialog('Success', `<div style="white-space: pre">${successMessage} \n${successIds} </div>`);
          if (!(res.success && res.success.length > 0) && res.failed && res.failed.length > 0) return prompt.htmlDialog('Error', `<div style="white-space: pre">${errorMessage} \n${failedIds}</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createAppointmentReferencePreset: DynamicFormConstant<CreateAppointmentForm> = {
  allowMultipleRows: false,
  createForm: createAppointmentForm,
  entityType: SourceEntityType.APPOINTMENTS,
  label: 'Create Appointment Reference',
  submitForm: createAppointmentCallback,
  title: 'Create Appointment Reference',
  value: DynamicFormType.CREATE_APPOINTMENT_REFERENCE,
  endpoints: [endpoints.createLogisticsAppointments],
  width: 400,
};

export type CreateAppointment = Pick<WaConstelliumAppointment, 'elementKey' | 'appointmentReference'>[];
export type CreateAppointmentForm = { references: string };
export type CreateAppointmentResponse = { success: WaConstelliumAppointment[]; failed: string[] };
