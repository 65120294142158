import { from, lastValueFrom, map, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { InvoiceHeader, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const voucherPrefillCallback: prefillCallback<InvoiceHeader> = (delegate, id) => {
  let api = delegate.getService('api');
  id = Array.from(new Set(id));
  return api.rpc<InvoiceHeader>(endpoints.getVoucher, { filters: { id: id[0] } }, null);
};

const setInvoiceSentDateForm: createFormCallback<Pick<InvoiceHeader, 'invoiceSentDate'>> = (delegate, id) => {
  return [_fe('invoiceSentDate', 'Invoice Sent On', 'Date', null, [], false)];
};

const setInvoiceSentDateSubmit: submitFormCallback<Pick<InvoiceHeader, 'invoiceSentDate'>> = (delegate, ids, result) => {
  if (!ids || ids.length === 0) return of(null);
  let api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  if (!Array.isArray(ids)) ids = [ids];
  ids = Array.from(new Set(ids));

  return from(
    Promise.all(
      ids.map((id) =>
        lastValueFrom(
          api.rpc<InvoiceHeader>(
            endpoints.setInvoiceSentDate,
            {
              id,
              invoiceSentDate: result.invoiceSentDate,
            },
            null
          )
        )
      )
    )
  ).pipe(
    map(() =>
      prompt.htmlDialog('Success', `<div style="white-space: pre">Invoice Sent Date successfully updated </div>`).pipe(
        map((result) => {
          return result || 'Close';
        })
      )
    )
  );
};

export const setInvoiceSentDatePreset: DynamicFormConstant<Pick<InvoiceHeader, 'invoiceSentDate'>> = {
  value: DynamicFormType.SET_INVOICE_SENT_DATE,
  label: 'Set Invoice Sent Date',
  title: 'Set Invoice Sent Date',
  endpoints: [endpoints.getVoucher, endpoints.setInvoiceSentDate],
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: voucherPrefillCallback,
  createForm: setInvoiceSentDateForm,
  submitForm: setInvoiceSentDateSubmit,
  allowMultipleRows: true,
  width: 400,
};
