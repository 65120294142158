import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogAction, DialogService } from '@progress/kendo-angular-dialog';
import { DriveItem as _driveItem } from 'microsoft-graph';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap, toArray } from 'rxjs/operators';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { PromptService } from 'src/app/core/services/prompt.service';
import { CustomDialogResult, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SharePointService } from 'src/app/core/services/share-point-service.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { downloadFile, endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { InnerFormGroupFormElement } from 'src/lib/InnerFormGroupFormElement';
import { cleanFileName, Document, DocumentVersion, Entity, fileNameValidator, getEntityName, PrintDocumentResponse, StorageTypes, validateFileName } from 'src/lib/newBackendTypes';
import { SubEntityContainer } from 'src/lib/SubEntityContainer';
import { toLocalDate } from 'src/lib/toUTCDate';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { FileSelectWidgetComponent } from '../file-select-widget/file-select-widget.component';
import { PrintDocumentComponent, PrintDocumentForm } from '../print-document/print-document.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'microsoft-entity-documents',
  templateUrl: './microsoft-entity-documents.component.html',
  styleUrls: ['./microsoft-entity-documents.component.scss'],
  providers: [
    { provide: SubEntityContainer, useExisting: forwardRef(() => MicrosoftEntityDocumentsComponent) },
    { provide: InnerFormGroupFormElement, useExisting: forwardRef(() => MicrosoftEntityDocumentsComponent) },
  ],
})
export class MicrosoftEntityDocumentsComponent extends SubEntityContainer<any> {
  @ViewChild('fileSelect', { static: false })
  fileSelect: FileSelectWidgetComponent;

  @Input()
  /**
   * Unique readable id of entity
   * Booking Number / Contract Number etc
   * Represents path of related folder in file system
   */
  entityPath?: string | number;

  @Input()
  entity?: Entity<any>;

  parentFolder: _driveItem | null;

  fileHover: boolean = false;
  fileHoverTimer: number;

  public baseUrl: string;
  activeData: DocumentRow[] = [];

  loading = false;
  changesMade = false;

  authorized: endpointsAuthorized;

  constructor(
    route: ActivatedRoute,
    controlDir: NgControl,
    store: Store,
    private delegate: DelegateService,
    private spinner: SpinnerService,
    private sharepoint: SharePointService,
    private api: ThalosApiService,
    private prompt: PromptService,
    private selector: SelectorPopupService,
    private dialog: DialogService,
    private upload: FileUploadService
  ) {
    super(route, controlDir, store);

    this.parentFolder = null;

    this.loadEvent = 'hybrid';

    this.baseUrl = this.sharepoint.baseUrl;

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit() {
    this.getFormControl().setValidators(this.validate());
    super.ngOnInit();
  }

  loadSubForm() {
    this.changesMade = false;
    this.loading = true;
    this.fetchDocuments(this.showSpinner).subscribe(() => {
      this.loading = false;
    });
  }

  fetchDocuments(background = false) {
    if (!this.entityId) return of(null);
    let rid;
    return of(null).pipe(
      tap(() => {
        rid = this.spinner.startRequest(randomFetchSynonym() + ' Files', undefined, background, !background);
        this.parentFolder = null;
      }),
      switchMap(() => {
        return combineLatest([
          this.sharepoint.getFilesForEntity(this.entityType, this.entityPath),
          this.api.rpc<ListResponse<Document>>(endpoints.listDocuments, { filters: { entityType: this.entityType, entityId: this.entityId } }, { list: [], count: 0 }),
        ]);
      }),
      tap(([{ items, parent }, thalosDocs]) => {
        this.spinner.completeRequest(rid);

        let activeData: DocumentRow[] = [];
        if (items && parent) {
          activeData.push(
            ...items.map((i) => ({
              ...i,
              thalosDisplayName: i.name,
              locationName: 'Entity Folder',
            }))
          );
          this.parentFolder = parent;
        }
        activeData.push(
          ...thalosDocs.list.map((d) => ({
            ...d,
            thalosDisplayName: d.fileName || d.fsPath,
            locationName: locationNameFromStorageType(d.storageType),
            uploadDate: toLocalDate(d.uploadDate).toString(),
          }))
        );

        this.activeData = activeData.sort(sortDocuments());
        this.publishChanges(true);
      })
    );
  }

  markAsTouched() {}

  save(entityId: number, operation: 'create' | 'update' | 'none', entityPath?: string | number) {
    const toUpload: LocalDoc[] = <LocalDoc[]>this.activeData.filter((d) => isLocalDoc(d));
    const toLink: DriveItem[] = <DriveItem[]>this.activeData.filter((d) => isDriveItem(d) && d.toLink && d.webUrl);
    const toReplace: (DriveItem | ThalosDocument)[] = <(DriveItem | ThalosDocument)[]>this.activeData.filter((d) => (isDriveItem(d) || isThalosDoc(d)) && !!d.toReplace);
    const currentLocation = this.parentFolder
      ? of(this.parentFolder.id)
      : operation === 'create'
      ? this.sharepoint.getEntityFolder(this.entityType, entityPath).pipe(
          map((res) => {
            return res?.id || null;
          })
        )
      : of(null);

    return currentLocation.pipe(
      switchMap((folderId) => {
        if (!folderId && operation === 'create') return of(false);
        if (toUpload.length === 0 && toReplace.length === 0) return of(true);
        const savedDocs: Observable<any>[] = [];

        for (let i in toUpload) {
          const document = toUpload[i];
          if (folderId) {
            //upload to new file system
            savedDocs.push(this.sharepoint.uploadFile(document.location || folderId, document.file, `Uploading ${parseInt(i) + 1}/${toUpload.length + toReplace.length}`));
          } else {
            //upload to old file system
            let rid: string;
            savedDocs.push(
              new Observable((sub) => {
                rid = this.spinner.startRequest(`Uploading ${parseInt(i) + 1}/${toUpload.length + toReplace.length}`);
                sub.next();
              }).pipe(
                switchMap((_) => this.upload.upload(document.file, { entityId, entityType: this.entityType })),
                tap((res) => {
                  if (typeof res === 'number') this.spinner.updateRequest(rid, { progress: res });
                }),
                filter((res) => typeof res !== 'number'),
                tap(() => {
                  this.spinner.completeRequest(rid);
                }),
                first()
              )
            );
          }
        }
        for (let i in toReplace) {
          const item = toReplace[i];
          if (isDriveItem(item) && !!folderId) {
            savedDocs.push(this.sharepoint.uploadFile(folderId, item.toReplace, `Uploading ${parseInt(i) + 1 + toUpload.length}/${toUpload.length + toReplace.length}`, item.id));
          } else if (isThalosDoc(item)) {
            let rid: string;
            savedDocs.push(
              new Observable((sub) => {
                rid = this.spinner.startRequest(`Uploading ${parseInt(i) + 1}/${toUpload.length + toReplace.length}`);
                sub.next();
              }).pipe(
                switchMap((_) =>
                  this.upload.upload(item.toReplace, {
                    entityId,
                    entityType: this.entityType,
                    documentId: item.id,
                  })
                ),
                tap((res) => {
                  if (typeof res === 'number') this.spinner.updateRequest(rid, { progress: res });
                }),
                filter((res) => typeof res !== 'number'),
                tap(() => {
                  this.spinner.completeRequest(rid);
                }),
                first()
              )
            );
          }
        }

        return concat(...savedDocs).pipe(
          toArray(),
          map((results) => results?.every((d) => !!d))
        );
      }),
      switchMap((uploadResults) => {
        if (toLink.length === 0 || !this.linkFolderAuthorized) return of(uploadResults);

        const linkedDocs: Observable<Document>[] = [];

        for (let l of toLink) {
          linkedDocs.push(
            this.api.rpc<Document>(
              endpoints.linkSharepointFile,
              {
                url: `${l.webUrl}`,
                fileName: this.sharepoint.humanReadableSharepointFolderPath(l.webUrl),
                entityType: this.entityType,
                entityId,
              },
              null
            )
          );
        }

        return concat(...linkedDocs).pipe(
          toArray(),
          map((results) => results?.every((d) => !!d) && uploadResults)
        );
      })
    );
  }

  itemClass(dataItem: DocumentRow, isExpanded: boolean) {
    let classes = 'fas';

    if ((<DriveItem>dataItem).folder || (<Document>dataItem).storageType === StorageTypes.URL) {
      classes += ' fa-folder';
    } else if ((<ThalosDocument>dataItem).storageType === StorageTypes.FILESYSTEM_FOLDER) {
      classes = 'far fa-folder';
    } else {
      const extension = isThalosDoc(dataItem) ? /(?:\.([^.]+))?$/.exec(dataItem.fileName)[1] : /(?:\.([^.]+))?$/.exec(dataItem.name)[1];

      if (extension === 'pdf') classes += ' fa-file-pdf';
      else if (extension === 'doc' || extension === 'docx') classes += ' fa-file-word';
      else if (extension === 'jpeg' || extension === 'png' || extension === 'jpg') classes += ' fa-file-image';
      else classes += ' fa-file';
    }
    return classes;
  }

  itemDoubleClicked(item: DocumentRow, version?: DocumentVersion | string) {
    if (!item) return;
    if (isDriveItem(item)) {
      if (typeof version === 'string') {
        this.sharepoint.downloadVersion(item, version).subscribe((file) => {
          downloadFile(file, item.name);
        });
      } else if (item.toReplace) {
        this.changeNameOfNewFile(item);
      } else if (item.webUrl) {
        window.open(item.webUrl);
      }
    } else if (isThalosDoc(item)) {
      if (item.toReplace) {
        this.changeNameOfNewFile(item);
      } else if (item.storageType === StorageTypes.URL) {
        window.open(item.fsPath);
      } else if (item.storageType === StorageTypes.FILESYSTEM_FOLDER) {
        this.openLinkedDox(item.fsPath);
      } else if (item.storageType === StorageTypes.DATABASE_FILE) {
        let documentVersion = !!version && typeof version !== 'string' ? version.version : undefined;
        let rid = this.spinner.startRequest('Downloading');
        this.upload.download(item.id, documentVersion).subscribe((res) => {
          this.spinner.completeRequest(rid);
          if (res) {
            downloadFile(res, item.fileName);
          }
        });
      }
    } else {
      this.changeNameOfNewFile(item);
    }
  }

  hasChildren = (item: DriveItem) => {
    return !!item?.folder?.childCount;
  };

  fetchChildren = (item: DriveItem) => {
    if (!item.id) return of([]);
    return this.sharepoint.fetchChildren(item).pipe(
      map((res) => {
        return res;
      })
    );
  };

  clickAddFile() {
    this.fileSelect.clickSelectFile();
  }

  clickLinkFolder() {
    this.sharepoint.selectFile().subscribe((folder) => {
      if (folder === 'Close') return;
      if (!folder.folder) {
        this.prompt.textDialog('Invalid Selection', 'Unable to link item. Selection is not a folder');
        return;
      }
      if (!!folder.id && folder.id === this.parentFolder) {
        this.prompt.textDialog('Invalid Selection', 'Unable to link item. Selected folder is the root for this entity and does not need to be linked to it.');
        return;
      }
      if (this.parentFolder && this.activeData.find((dr) => isDriveItem(dr) && dr.id === this.parentFolder)) {
        this.prompt.textDialog('Invalid Selection', 'Unable to link item. Selected folder is already associated with this entity.');
        return;
      }

      this.activeData = [
        ...this.activeData,
        {
          ...folder,
          thalosDisplayName: `[${this.sharepoint.humanReadableSharepointFolderPath(folder.webUrl)}]`,
          locationName: `[Sharepoint Link]`,
          toLink: true,
        },
      ];
      this.publishChanges();
    });
  }

  fileSelected(event: File[]) {
    if (!!event) {
      for (const f of event) {
        const file = new File([f], cleanFileName(f.name));
        const error: string | undefined = validateFileName(file.name);
        if (error) return this.prompt.textDialog('Invalid file', `${error}`);
        this.activeData = [
          ...this.activeData,
          {
            name: file.name,
            file,
            size: file.size,
            toUpload: true,
            locationName: '[Upload Here]',
            thalosDisplayName: `[${file.name}]`,
            error,
          },
        ];
        this.publishChanges();
      }
    }
  }

  fileSelectedRow(event: File[], item: DriveItem | ThalosDocument) {
    if (isThalosDoc(item) && item.storageType !== StorageTypes.DATABASE_FILE) return;
    if (!!event && event.length > 0) {
      const f = event[0];
      const file = new File([f], cleanFileName(f.name));
      const error: string | undefined = validateFileName(file.name);
      if (error) return this.prompt.textDialog('Invalid file', `${error}`);
      const newExt = /(?:\.([^.]+))?$/.exec(file.name)[1];
      const oldExt = /(?:\.([^.]+))?$/.exec(isDriveItem(item) ? item.name : item.fileName)[1];
      if (newExt !== oldExt) {
        this.prompt.textDialog('Unable to replace', 'The selected file must have the same extension as the original');
        return;
      }
      item.toReplace = file;
      item.thalosDisplayName = `[${file.name}]`;
      item.error = error;
      this.publishChanges();
    }
  }

  selectUploadLocation(doc: LocalDoc) {
    if (!this.linkFolderAuthorized) return;

    this.sharepoint.selectFile().subscribe((folder) => {
      if (folder !== 'Close') {
        if (!!folder?.folder) {
          doc.location = folder.id;
          if (folder.parentReference?.path) {
            const fullPath = folder.parentReference.path?.split('root:/');
            doc.locationName = fullPath[fullPath.length - 1] + '/' + folder.name;
          } else {
            doc.locationName = folder.name;
          }
        } else {
          doc.location = null;
          doc.locationName = 'Upload Here';
        }
      }
    });
  }

  removeItem(i: number) {
    const temp = [...this.activeData];
    temp.splice(i, 1);
    this.activeData = [...temp];
  }

  changeNameOfNewFile(item: DocumentRow) {
    if (!isLocalDoc(item) && !item.toReplace) return;
    const name = isLocalDoc(item) ? item.name : item!.toReplace.name;
    this.prompt.textPrompt('Enter File Name', 'Name', name, fileNameValidator()).subscribe((res) => {
      if (res) {
        if (isLocalDoc(item)) {
          item.name = res;
          item.file = new File([item.file], res);
          item.thalosDisplayName = `[${item.name}]`;
          item.error = undefined;
        } else {
          item.toReplace = new File([item.toReplace], res);
          item.error = undefined;
          item.thalosDisplayName = `[${item.toReplace.name}]`;
        }
        this.publishChanges();
      }
    });
  }

  openParentFolder() {
    if (this.parentFolder) {
      window.open(this.parentFolder.webUrl);
    }
  }

  deleteItem(index: number) {
    const item = this.activeData[index];
    if (!isDriveItem(item)) return;
    let rid;
    this.prompt
      .deleteConfirmation('Delete Document')
      .pipe(
        switchMap((answer) => {
          rid = this.spinner.startRequest('Deleting Document');
          return answer ? this.sharepoint.deleteFile(item) : of(null);
        })
      )
      .subscribe((result) => {
        if (rid) this.spinner.completeRequest(rid);
        if (!!result) {
          this.removeItem(index);
          this.publishChanges();
        }
      });
  }

  cancelItem(index: number) {
    const item = this.activeData[index];
    if (!isLocalDoc(item) && !item.toReplace && !(isDriveItem(item) && !!item.toLink)) return;

    this.prompt.deleteConfirmation(item['toReplace'] ? 'Cancel Upload New Version' : 'Cancel Attachment').subscribe((answer) => {
      if (answer) {
        if ((isDriveItem(item) || isThalosDoc(item)) && item.toReplace) {
          item.toReplace = undefined;
          item.thalosDisplayName = isDriveItem(item) ? `${item.name}` : `${item.fileName}` || `${item.fsPath}`;
          item.error = undefined;
        } else {
          this.removeItem(index);
        }
        this.publishChanges();
      }
    });
  }

  unlinkItem(index: number) {
    if (!this.unlinkDocumentAuthorized) return;
    const item = this.activeData[index];
    if (!isThalosDoc(item)) return;
    let rid;
    this.prompt
      .deleteConfirmation('Unlink Document')
      .pipe(
        switchMap((answer) => {
          if (!answer) return of(null);
          rid = this.spinner.startRequest('Unlinking Document');
          return this.api.rpc<Document>(endpoints.unlinkDocument, { documentId: item.id, entityType: this.entityType, entityId: this.entityId }, null);
        })
      )
      .subscribe((result) => {
        if (rid) this.spinner.completeRequest(rid);
        if (!!result) {
          this.removeItem(index);
          this.publishChanges();
        }
      });
  }

  clickSaveChanges() {
    if (!this.changesMade || !this.entityId || !this.entityPath) return;
    this.save(this.entityId, 'none').subscribe((res) => {
      if (!!res) {
        this.prompt.textDialog('Success', 'Documents saved successfully').subscribe(() => {
          this.loadSubForm();
        });
      }
    });
  }

  clickPrintDocument() {
    if (!this.entityId || !this.entityType || !this.generateDocAuthorized) return;
    let entityName = getEntityName(this.entityType, this.entity);
    this.selector
      .openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
        title: 'Generate Document',
        submitButtonText: 'Generate',
        initializer: (p: PrintDocumentComponent) => {
          p.documents = <Document[]>this.activeData.filter((d) => isThalosDoc(d));
          p.entityName = entityName;
          p.entityType = this.entityType;
        },
        maxWidth: 400,
      })
      .subscribe((popupResult) => {
        if (popupResult !== 'Close') {
          let request = {
            packetId: popupResult.packet?.id,
            outputName: popupResult.saveAs,
            savingLocation: popupResult.location === -1 ? null : popupResult.location,
            entityId: this.entityId,
            copies: popupResult.copies,
          };
          let rid = this.spinner.startRequest('Generating Document');
          this.api
            .rpc<PrintDocumentResponse>(endpoints.generateDocumentsFromPacket, request, null, {
              blockRedirect: true,
            })
            .subscribe((packet) => {
              this.spinner.completeRequest(rid);
              if (packet !== null) {
                const changes = this.activeData.filter((d) => d['toUpload'] || d['toLink']);
                const toReplace = <DriveItem[]>this.activeData.filter((d) => d['toReplace']);

                this.fetchDocuments(true).subscribe(() => {
                  this.activeData = [...this.activeData, ...changes];

                  for (let r of toReplace) {
                    let sharepointFile = this.activeData.find((d) => isDriveItem(d) && r.id === d.id);
                    if (sharepointFile) {
                      sharepointFile['toReplace'] = r['toReplace'];
                    }
                  }
                });

                const actions: CustomDialogResult[] = [{ text: 'Close' }];
                if (this.isWindows || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
                  actions.push({ text: 'Open File', themeColor: 'primary' });
                }
                this.dialog
                  .open({
                    title: 'Success',
                    content: 'File generated successfully',
                    actions,
                  })
                  .result.subscribe((action) => {
                    if ((action as DialogAction).text === 'Open File') {
                      this.handlePrintResponse(packet);
                    }
                    this.dialog
                      .open({
                        title: 'Success',
                        content: 'File generated successfully',
                        actions,
                      })
                      .result.subscribe((action) => {
                        if ((action as DialogAction).text === 'Open File') {
                          handlePrintResponse(this.delegate, packet);
                        }
                      });
                  });
              }
            });
        }
      });
  }

  private handlePrintResponse(packet: PrintDocumentResponse) {
    switch (packet.locationType) {
      case StorageTypes.DATABASE_FILE:
        if (packet.documentId !== null) {
          let rid = this.spinner.startRequest('Downloading');
          this.upload.download(packet.documentId).subscribe((res) => {
            this.spinner.completeRequest(rid);
            if (res) {
              downloadFile(res, packet.fileName);
            }
          });
        }
        break;
      case StorageTypes.URL:
        window.open(packet.fileName);
        break;
      case StorageTypes.FILESYSTEM_FOLDER:
      case StorageTypes.FILESYSTEM_FILE:
        if (!this.isWindows || !packet.fileName) break;
        this.openLinkedDox(packet.fileName);
        break;
      default:
        break;
    }
  }

  private openLinkedDox(path: string) {
    if (!this.isWindows) return;

    let baseUrl = path.replace(/\\/g, '/');

    let finalUrl = `linkeddox:${baseUrl}`;
    window.open(finalUrl);
  }

  get isWindows() {
    return /Win/.test(navigator.platform);
  }

  //#region Drop Handlers

  dropHandler(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    window.clearTimeout(this.fileHoverTimer);
    this.fileHover = false;

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          var file = event.dataTransfer.items[i].getAsFile();
          this.fileSelected([file]);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.files.length; i++) {
        let item = event.dataTransfer.files[i];
        if (item.kind === 'file') {
          var file = event.dataTransfer.files[i].getAsFile();
          this.fileSelected([file]);
        }
      }
    }
  }

  dragOverHandler(ev) {
    this.fileHover = true;

    window.clearTimeout(this.fileHoverTimer);
    this.fileHoverTimer = window.setTimeout(() => {
      this.fileHover = false;
    }, 10000);

    if (ev && ev.dataTransfer && ev.dataTransfer.dropEffect != 'copy') {
      ev.dataTransfer.dropEffect = 'copy';
    }

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    ev.preventDefault();
  }

  dragLeaveHandler(ev) {
    this.fileHover = false;
    window.clearInterval(this.fileHoverTimer);
  }

  getValue() {
    return this.activeData;
  }

  validate() {
    return () => {
      return this.activeData?.some((r) => isLocalDoc(r) && !!r.error) ? { error: true } : null;
    };
  }

  publishChanges(preserveUntouched?: boolean) {
    this.onChange(this.value);

    if (preserveUntouched) {
      this.getFormControl().markAsUntouched();
    }
  }

  //#endregion

  get linkFolderAuthorized() {
    return this.authorized[endpoints.linkSharepointFile] && (this.parentFolder || !this.entityId);
  }

  get unlinkDocumentAuthorized() {
    return this.authorized[endpoints.unlinkDocument];
  }

  get versionsAuthorized() {
    return this.authorized[endpoints.listDocumentVersions];
  }

  get generateDocAuthorized() {
    return !!this.entityId && this.authorized[endpoints.generateDocumentsFromPacket] && this.authorized[endpoints.listDocumentPackets];
  }

  get updateAuthorized() {
    return true;
  }

  get uploadAuthorized() {
    return true;
  }
}

type DriveItem = _driveItem & {
  thalosDisplayName: string;
  toReplace?: File;
  toLink?: boolean;
  error?: string;
  locationName: string;
};

type ThalosDocument = Document & {
  thalosDisplayName: string;
  locationName: string;
  toReplace?: File;
  error?: string;
};

export type DocumentRow = DriveItem | LocalDoc | ThalosDocument;

type LocalDoc = {
  name: string;
  file: File;
  size: number;
  toUpload: true;
  location?: string;
  locationName: string;
  thalosDisplayName: string;
  error?: string;
};

export function isDriveItem(doc: DocumentRow): doc is DriveItem {
  return !!doc && ('cTag' in doc || 'eTag' in doc || 'fileSystemInfo' in doc);
}

export function isThalosDoc(doc: DocumentRow): doc is ThalosDocument {
  return !!doc && 'storageType' in doc && 'id' in doc;
}

export function isLocalDoc(doc: DocumentRow): doc is LocalDoc {
  return !!doc && 'toUpload' in doc;
}

export function getNameFromDocumentRow(doc: DocumentRow) {
  if (isThalosDoc(doc)) return doc.fileName || doc.fsPath;
  return doc.name;
}

function locationNameFromStorageType(st: StorageTypes): string {
  switch (st) {
    case StorageTypes.FILESYSTEM_FILE:
      return 'File System';
    case StorageTypes.DATABASE_FILE:
      return 'Thalos';
    case StorageTypes.FILESYSTEM_FOLDER:
      return 'File System';
    default:
      return 'Sharepoint Link';
  }
}

function sortDocuments() {
  return (rowA: DocumentRow, rowB: DocumentRow) => {
    const nameA = getNameFromDocumentRow(rowA).toLowerCase().replace(/\\/g, '');
    const nameB = getNameFromDocumentRow(rowB).toLowerCase().replace(/\\/g, '');

    const aFolder = (isThalosDoc(rowA) && (rowA.storageType === StorageTypes.URL || rowA.storageType === StorageTypes.FILESYSTEM_FOLDER)) || (isDriveItem(rowA) && !!rowA.folder);
    const bFolder = (isThalosDoc(rowB) && (rowB.storageType === StorageTypes.URL || rowB.storageType === StorageTypes.FILESYSTEM_FOLDER)) || (isDriveItem(rowB) && !!rowB.folder);

    if ((aFolder && bFolder) || (!aFolder && !bFolder)) {
      return nameA > nameB ? 1 : -1;
    } else if (aFolder) {
      return -1;
    }
    return 1;
  };
}

export function handlePrintResponse(delegate: DelegateService, packet: PrintDocumentResponse) {
  const spinner = delegate.getService('spinner');
  const upload = delegate.getService('upload');
  switch (packet.locationType) {
    case StorageTypes.DATABASE_FILE:
      if (packet.documentId !== null) {
        let rid = spinner.startRequest('Downloading');
        upload.download(packet.documentId).subscribe((res) => {
          spinner.completeRequest(rid);
          if (res) {
            downloadFile(res, packet.fileName);
          }
        });
      }
      break;
    case StorageTypes.URL:
      window.open(packet.fileName);
      break;
    case StorageTypes.FILESYSTEM_FOLDER:
    case StorageTypes.FILESYSTEM_FILE:
    default:
      break;
  }
}
