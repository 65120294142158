<kendo-multiselect
  [allowCustom]="true"
  [valuePrimitive]="true"
  [(ngModel)]="_value"
  #multiselect
  [readonly]="readonly"
  [placeholder]="placeholder"
  (removeTag)="tagRemoved($event)"
  [ngClass]="{ readonly: readonly }"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
>
  <ng-template kendoMultiSelectNoDataTemplate>Start Typing</ng-template>
</kendo-multiselect>
