import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds',
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    if (typeof value !== 'number') return `${value}`;

    const minutes: number = Math.floor(value / 60);
    const seconds: number = value - minutes * 60;

    if (minutes > 0) return `${minutes}:${String(seconds).padStart(2, '0')}`;
    return `${seconds} seconds`;
  }
}
