import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ColDef,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridReadyEvent,
  NewValueParams,
  StatusPanelDef,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { round } from 'lodash';
import Mexp from 'math-expression-evaluator';
import { from, Observable } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { Store } from 'src/app/core/services/store.service';
import { AutoCompleteEditor } from 'src/app/shared/aggrid/autocompleteeditor/AutoCompleteEditor';
import { DebitCreditRenderer } from 'src/app/shared/aggrid/debitcreditrenderer/DebitCreditRenderer';
import { excelHeaderMap, ExcelHeaders, ImportExcelComponent, renameExcelKeys, selectItemFormToPopulate } from 'src/app/shared/aggrid/import/importExcel';
import { StatusBarTotalizerPanel } from 'src/app/shared/aggrid/statusbartotalizerpanel/StatusBarTotalizerPanel';
import { TableForm } from 'src/app/shared/aggrid/TableForm';
import { ValidationStatusPanel } from 'src/app/shared/aggrid/validationstatuspanel/ValidationStatusPanel';
import { DropdownConfig, ListResponse } from 'src/lib';
import { basicNumberFormatter, getContextMenuItems, gotoMenu, gotoMenuItem, quantityColumn } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { contactDropdown } from 'src/lib/commonTypes';
import { Subset } from 'src/lib/generics';
import { endpointAuthorizationSubscription, endpointsAuthorized, getVoucherSign } from 'src/lib/helperFunctions';
import { CommonContactTypes, Contact, ContactTypeAssignment, GlAccountLeaves, InvoiceHeader, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { PaymentEntry } from 'src/lib/newBackendTypes/payment';
import * as XLSX from 'xlsx';

export type PaymentLine = Subset<
  PaymentEntry,
  'lineNumber',
  'entryText' | 'externalRef' | 'amount' | 'baseAmount' | 'accountId' | 'counterparty' | 'counterpartyId' | 'voucherId' | 'paymentId' | 'paymentEntryId' | 'auxPaymentEntryId'
> & {
  glAccount?: Pick<GlAccountLeaves, 'id' | 'companyId' | 'currencyId' | 'accountType' | 'accountOption' | 'idenLong'>;
  voucherSign?: number;
  xr?: number;
  matchedPaymentEntry?: Partial<PaymentEntry>;
  voucher?: Partial<InvoiceHeader>;
};

@UntilDestroy()
@Component({
  selector: 'payments-lines-component',
  templateUrl: './payments-lines.component.html',
  styleUrls: ['./payments-lines.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PaymentsLinesComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PaymentsLinesComponent),
    },
  ],
})
export class PaymentsLinesComponent extends TableForm<PaymentLine> implements ImportExcelComponent<PaymentsExcelData> {
  /**
   * Used to calculate the status row totalizers
   */
  private totalRow: {
    amount: number | false;
    baseAmount: number | false;
    debits: number | false;
    credits: number | false;
  } = { amount: 0, baseAmount: 0, debits: 0, credits: 0 };

  /**
   * Flags status of the grid
   */
  private _readonly: boolean;

  @Input()
  set readonly(val: boolean) {
    this._readonly = val;
    if (this.gridApi) {
      const statusPanel: ValidationStatusPanel | undefined = this.gridApi.getStatusPanel('validationStatusPanel') as ValidationStatusPanel;
      if (!!statusPanel) {
        statusPanel.setReadonly(val);
      }
    }
    this.refreshDefinitions();
  }

  get readonly() {
    return this._readonly;
  }

  private baseCurrencyId: number | null = null;
  /**
   * Keeps track of the company ID passed as an input, used to filter the accounts dropdown
   */
  private _companyId: number | null = null;

  @Input()
  set companyId(companyId: number | null) {
    this._companyId = companyId;
    const company = (this.commonData.staticCompanies.value || []).find((c) => c.id === companyId);
    this.baseCurrencyId = company && company.fiscalCompany ? company.fiscalCompany.currKey : null;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get companyId() {
    return this._companyId;
  }

  /**
   * Keeps track of the currency ID passed as an input, used to filter the accounts dropdown
   */
  private _currencyId: number | null = null;

  @Input()
  set currencyId(currencyId: number | null) {
    this._currencyId = currencyId;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get currencyId() {
    return this._currencyId;
  }

  /**
   * Keeps track of the ehader base amount passed as an input, used to validate baseamount in different currencies
   */
  private _headerBaseAmount: number | null = null;
  @Input()
  set headerBaseAmount(headerBaseAmount: number | null) {
    this._headerBaseAmount = headerBaseAmount;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get headerBaseAmount() {
    return this._headerBaseAmount;
  }

  linesCurrencyId: number | null = null;

  @Input()
  exchangeRates: ExchangeRateInfo[] = [];

  /**
   * Any possible error messages or null if the grid is valid
   */
  errors: string[] = [];

  /**
   * Prevents updating the base amount when updating the amount progratically
   */
  private updateBaseAmount: boolean = true;
  /**
   * Prevents updating the amount when updating the XR progratically
   */
  private updateAmount: boolean = true;
  /**
   * Outputs for total amounts
   */
  @Output()
  totalAmounts = new EventEmitter<Totals>();
  @Output()
  linesCurrId = new EventEmitter<number>();

  /**
   * Keeps track of the action selected by the user to allow different entries currency
   */
  private _enableDifferentCurrencies: boolean = false;

  @Input()
  set enableDifferentCurrencies(enableDifferentCurrencies: boolean) {
    this._enableDifferentCurrencies = enableDifferentCurrencies;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get enableDifferentCurrencies() {
    return this._enableDifferentCurrencies;
  }

  onGridReady(event: GridReadyEvent) {
    if (!this.data) return;
    super.onGridReady(event);
    this.readonly = this._readonly;
    this.getTotalAmounts();
    this.validateAccountCompanies();
    this.validateAccountCurrencies();
    this.refreshDefinitions();
    this.calculateTotals();
    this.gridApi.refreshCells();
  }

  private refreshDefinitions() {
    const definitions = this.columnDefinitions;
    if (this.gridApi) {
      this.gridApi.setColumnDefs(definitions);
    }
  }

  get columnDefinitions(): ColDef[] {
    return [
      {
        field: 'lineNumber',
        headerName: '#',
        width: 75,
      },
      {
        field: 'counterparty',
        headerName: 'Counterparty',
        editable: !this.readonly,
        cellEditor: AutoCompleteEditor,
        width: 180,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'displayName',
          returnObject: true,
          asyncListConfig: contactDropdown(),
          columnDefs: [
            { headerName: 'Name', field: 'displayName' },
            { headerName: 'ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.counterpartyValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('counterparty', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            event.node.setDataValue('voucher', null);
            event.node.setDataValue('entryText', '');
          }
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        keyCreator: (params) => params.value.displayName,
        comparator: (valueA: Contact | null, valueB: Contact | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.displayName > valueB.displayName ? 1 : -1;
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !!params.data &&
            !params.value &&
            (!params.data.counterparty || params.data.counterparty.id === 0) &&
            params.data.glAccount &&
            (params.data.glAccount.accountOption === 10 || params.data.glAccount.accountOption === 11),
        },
        filter: 'agSetColumnFilter',
      },
      {
        field: 'voucher',
        headerName: 'Voucher',
        width: 220,
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly) return false;
          if (!params.data || !params.data.counterparty || params.data.counterparty.id === 0 || (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0)) return false;
          return true;
        },
        enableRowGroup: true,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 450,
          gridWidth: 450,
          propertyRendered: 'iden',
          returnObject: true,
          asyncListConfig: (row: PaymentLine) =>
            new DropdownConfig<InvoiceHeader>({
              listProcedure: endpoints.listVouchersBalance,
              labelField: 'iden',
              valueField: 'id',
              additionalFilters: {
                counterpartyId: row.counterparty ? row.counterparty.id : undefined,
              },
              orderBy: { fieldName: 'invoiceDate', order: 'DESC' },
              take: 0,
            }),
          columnDefs: [
            { headerName: 'ID', field: 'id', hide: true },
            { headerName: 'Number', field: 'iden', width: 150 },
            { headerName: 'Reference', field: 'ourReference', width: 150 },
            {
              headerName: 'Amount',
              field: 'amount',
              valueGetter: (params: ValueGetterParams) => getVoucherSign(params.data.documentType, params.data.voucherType) * -params.data.amount,
              cellRenderer: DebitCreditRenderer,
              cellRendererParams: { currencyField: 'account.currencyId' },
            },
          ],
        },
        valueFormatter: this.voucherValueFormatter,
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            event.node.setDataValue('voucherSign', event.newValue.documentType && event.newValue.voucherType ? getVoucherSign(event.newValue.documentType, event.newValue.voucherType) : undefined);
            event.node.setDataValue('entryText', event.newValue.iden || event.newValue.yourReference ? `${event.newValue.iden} / ${event.newValue.yourReference}` : '');
            event.node.setDataValue('glAccount', event.newValue.account ? event.newValue.account : null);
            event.node.setDataValue('amount', event.newValue.amount && event.data.voucherSign ? event.newValue.amount * event.data.voucherSign : null);
          }
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('voucher', null);
            params.node.setDataValue('entryText', null);
            return true;
          }
          return false;
        },
        keyCreator: (params) => params.value.iden,
        comparator: (valueA: InvoiceHeader | null, valueB: InvoiceHeader | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.iden > valueB.iden ? 1 : -1;
        },
        cellClassRules: {
          'locked-cell': (params) =>
            (!!params.data && !params.data.counterparty && !params.value) ||
            (params.data.counterparty && params.data.counterparty.id === 0) ||
            (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0),
          'invalid-cell': (params) =>
            this.data.some(
              (row) => row.lineNumber !== params.data.lineNumber && params.data.voucher && params.data.voucher.id && row.voucher && row.voucher.id && params.data.voucher.id === row.voucher.id
            ),
        },
        filter: 'agSetColumnFilter',
      },
      {
        field: 'matchedPaymentEntry',
        headerName: 'Match Payment Entry',
        width: 220,
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly) return false;
          if (!params.data || !params.data.counterparty || params.data.counterparty.id === 0 || (params.data.voucher && params.data.voucher.id !== 0)) return false;
          return true;
        },
        enableRowGroup: true,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 450,
          gridWidth: 450,
          propertyRendered: 'iden',
          returnObject: true,
          asyncListConfig: (row: PaymentLine) =>
            new DropdownConfig<PaymentEntry>({
              listProcedure: endpoints.listPaymentEntries,
              labelField: 'iden',
              valueField: 'paymentEntryId',
              additionalFilters: {
                counterpartyId: row.counterparty ? row.counterparty.id : undefined,
                voucherId: 0,
              },
              orderBy: { fieldName: 'phValueDate', order: 'DESC' },
              take: 0,
            }),
          columnDefs: [
            { headerName: 'ID', field: 'paymentEntryId', hide: true },
            { headerName: 'Reference', field: 'iden', width: 150 },
            { headerName: 'Entry Text', field: 'entryText', width: 150 },
            {
              headerName: 'Amount',
              field: 'amount',
              cellRenderer: DebitCreditRenderer,
              cellRendererParams: { currencyField: 'glAccount.currencyId' },
            },
          ],
        },
        valueFormatter: this.paymentEntryValueFormatter,
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            event.node.setDataValue('entryText', event.newValue.entryText ? event.newValue.entryText : '');
            event.node.setDataValue('glAccount', event.newValue.glAccount ? event.newValue.glAccount : null);
            event.node.setDataValue('amount', event.newValue.amount ? -event.newValue.amount : null);
            event.node.setDataValue('baseAmount', event.newValue.baseAmount ? -event.newValue.baseAmount : null);
          }
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('matchedPaymentEntry', null);
            params.node.setDataValue('entryText', null);
            return true;
          }
          return false;
        },
        keyCreator: (params) => params.value.iden,
        comparator: (valueA: InvoiceHeader | null, valueB: InvoiceHeader | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.iden > valueB.iden ? 1 : -1;
        },
        cellClassRules: {
          'locked-cell': (params) =>
            (!!params.data && !params.data.counterparty && !params.value) || (params.data.counterparty && params.data.counterparty.id === 0) || (params.data.voucher && params.data.voucher.id !== 0),
          'invalid-cell': (params) =>
            this.data.some(
              (row) => row.lineNumber !== params.data.lineNumber && params.data.voucher && params.data.voucher.id && row.voucher && row.voucher.id && params.data.voucher.id === row.voucher.id
            ),
        },
        filter: 'agSetColumnFilter',
      },
      {
        field: 'entryText',
        headerName: 'Description',
        width: 250,
        editable: true,
        cellClassRules: {
          'invalid-cell': (params) => !!params.data && !params.data.entryText && !params.value,
        },
        filter: 'agTextColumnFilter',
      },
      {
        field: 'externalRef',
        headerName: 'Line External Reference',
        width: 200,
        editable: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'voucherSign',
        hide: true,
      },
      {
        field: 'glAccount',
        headerName: 'Account',
        width: 250,
        enableRowGroup: true,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 320,
          propertyRendered: 'idenLong',
          returnObject: true,
          asyncListConfig: new DropdownConfig<GlAccountLeaves>({
            listProcedure: endpoints.listGlAccounts,
            labelField: 'idenLong',
            valueField: 'id',
            additionalFilters: { companyId: this.companyId, currencyId: !this.enableDifferentCurrencies && !!this.currencyId ? this.currencyId : undefined, archived: YN.N },
            postFilter: (v: GlAccountLeaves) => (this.linesCurrencyId !== null && !this.enableDifferentCurrencies ? v.currencyId === this.linesCurrencyId : v.currencyId !== null),
            orderBy: { fieldName: 'idenLong', order: 'ASC' },
          }),
          columnDefs: [{ headerName: 'Account Name', field: 'idenLong' }],
        },
        valueFormatter: this.accountValueFormatter,
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly || !this.companyId) return false;
          if (((params.data.voucher && params.data.voucher.id !== 0) || params.data.matchedPaymentEntry) && params.data.glAccount) return false;
          return true;
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete' || params.event.key === 'Backspace') {
            if (!params.data.voucher && !params.data.matchedPaymentEntry) params.node.setDataValue('glAccount', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue && event.newValue.currencyId) {
            this.xrValueSetter(this.data);
          }

          event.api.redrawRows({ rowNodes: [event.node] });
        },
        keyCreator: (params) => params.value.idenLong,
        comparator: (valueA: GlAccountLeaves | null, valueB: GlAccountLeaves | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.idenLong > valueB.idenLong ? 1 : -1;
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !params.value ||
            !params.value.companyId ||
            (this.companyId && params.value.companyId !== this.companyId) ||
            !this.validateAccountCompanies() ||
            !this.validateAccountCurrencies() ||
            (!this.enableDifferentCurrencies && !!this.currencyId && this.currencyId !== params.data.glAccount.currencyId),
          'locked-cell': (params) => (params.value && params.data && ((params.data.voucher && params.data.voucher.id) || params.data.matchedPaymentEntry)) || !this.companyId,
        },
        filter: 'agSetColumnFilter',
      },
      {
        field: 'amount',
        headerName: 'Amount',
        width: 170,
        ...quantityColumn(),
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly) return false;
          if (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0 && params.data.amount) return false;
          return true;
        },
        cellRenderer: DebitCreditRenderer,
        cellRendererParams: { currencyField: 'glAccount.currencyId' },
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }

          if (isNaN(value)) {
            params.data.amount = params.newValue;
            if (
              (!params.data || !params.data.glAccount || !params.data.glAccount.currencyId || params.data.glAccount.currencyId === this.baseCurrencyId) &&
              params.data.baseAmount === params.oldValue
            ) {
              this.updateBaseAmount = false;
              if (!this.updateBaseAmount) params.node.setDataValue('baseAmount', null);
              this.updateBaseAmount = true;
            }
            return params.oldValue !== params.newValue;
          } else {
            if (params.data && params.data.glAccount && params.data.glAccount.currencyId) {
              if (this.updateBaseAmount) params.node.setDataValue('baseAmount', this.formatter.roundAmount(value * params.data.xr, this.baseCurrencyId));
            }
            params.data.amount = this.formatter.roundAmount(value, params.data?.account?.currencyId);
            return parseFloat(params.oldValue) !== value;
          }
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            (!!params.data && !!params.data.baseAmount && !params.value) ||
            (params.data.amount && params.data.baseAmount && Math.sign(params.data.amount) !== Math.sign(params.data.baseAmount)) ||
            (params.data.voucher &&
              params.data.voucher.id !== 0 &&
              (this.formatter.roundAmount(Math.abs(params.data.amount), params.data?.account?.currencyId) >
                this.formatter.roundAmount(Math.abs(params.data.voucher.amount * params.data.voucherSign), params.data?.account?.currencyId) ||
                Math.sign(params.data.amount) !== Math.sign(params.data.voucher.amount * params.data.voucherSign))),
          'locked-cell': (params) => params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId,
        },
      },
      {
        field: 'xr',
        headerName: 'XR',
        valueFormatter: basicNumberFormatter(6, 6, YN.Y),
        ...quantityColumn(),
        width: 100,
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly) return false;
          if (
            !params.data ||
            !params.data.glAccount ||
            params.data.glAccount.currencyId === this.baseCurrencyId ||
            (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0 && params.data.xr)
          )
            return false;
          return true;
        },
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }

          if (isNaN(value)) {
            params.data.xr = params.newValue;
            if (!params.data || !params.data.glAccount || !params.data.glAccount.currencyId || params.data.glAccount.currencyId === this.baseCurrencyId) {
              this.updateBaseAmount = false;
              if (!this.updateBaseAmount) params.node.setDataValue('baseAmount', null);
              this.updateBaseAmount = true;
            }
            return params.oldValue !== params.newValue;
          } else {
            if (params.data && params.data.glAccount && params.data.glAccount.currencyId && params.data.amount) {
              this.updateAmount = false;
              if (this.updateBaseAmount) params.node.setDataValue('baseAmount', params.data.amount ? this.formatter.roundAmount(value * params.data.amount, this.baseCurrencyId) : null);
              this.updateAmount = true;
            }
            params.data.xr = value;
            return parseFloat(params.oldValue) !== value;
          }
        },
        cellClassRules: {
          'invalid-cell': (params) => params.value < 0,
          'locked-cell': (params) =>
            !params.data ||
            !params.data.glAccount ||
            params.data.glAccount.currencyId === this.baseCurrencyId ||
            (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0),
        },
      },
      {
        field: 'baseAmount',
        headerName: 'Base Amount',
        width: 170,
        aggFunc: 'sum',
        ...quantityColumn(),
        editable: (params: EditableCallbackParams) => {
          if (!!this.readonly) return false;
          if (params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0 && params.data.baseAmount) return false;
          return true;
        },
        cellRenderer: DebitCreditRenderer,
        cellRendererParams: { currency: this.baseCurrencyId },
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          if (isNaN(value)) {
            params.data.baseAmount = params.newValue;
            if (this.updateBaseAmount) params.node.setDataValue('amount', null);
            return params.oldValue !== params.newValue;
          } else {
            if (params.data && params.data.glAccount && params.data.glAccount.currencyId && params.newValue) {
              this.updateBaseAmount = false;
              if (!this.updateAmount) params.node.setDataValue('amount', params.data.amount ? params.data.amount : null);
              if (this.updateAmount) params.node.setDataValue('amount', params.data.xr ? this.formatter.roundAmount(value / params.data.xr, params.data.glAccount.currencyId) : null);
              this.updateBaseAmount = true;
            }
            params.data.baseAmount = this.formatter.roundAmount(value, this.baseCurrencyId);
            return parseFloat(params.oldValue) != value;
          }
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            typeof params.value !== 'number' ||
            (!!params.data && !!params.data.amount && !params.value) ||
            (params.data.amount && params.data.baseAmount && Math.sign(params.data.amount) !== Math.sign(params.data.baseAmount)) ||
            (params.data.voucher && params.data.voucher.id !== 0 && Math.sign(params.data.baseAmount) !== Math.sign(params.data.voucher.baseAmount * params.data.voucherSign)),
          'locked-cell': (params) => params.data.matchedPaymentEntry && params.data.matchedPaymentEntry.paymentEntryId !== 0,
        },
      },
    ];
  }

  authorized: endpointsAuthorized;
  companies: Map<number, Contact>;

  constructor(store: Store, private commonData: CommonDataService, private delegate: DelegateService, private formatter: DataFormattingService) {
    super();
    this.companies = new Map();
    this.commonData.staticCompanies.value.forEach((company) => this.companies.set(company.id, company));

    const statusPanels: StatusPanelDef[] = [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: StatusBarTotalizerPanel,
        align: 'left',
        statusPanelParams: {
          totalRow: this.totalRow,
          labels: [
            { key: 'amount', text: 'Amount' },
            { key: 'baseAmount', text: 'Base Amount' },
            { key: 'credits', text: 'T. Credits' },
            { key: 'debits', text: 'T. Debits' },
          ],
        },
      },
      {
        statusPanel: ValidationStatusPanel,
        align: 'right',
        key: 'validationStatusPanel',
        statusPanelParams: {
          errors: this.errors,
          readonly: this.readonly,
        },
      },
    ];
    this.gridOptions = {
      getRowId: (params) => params.data.lineNumber,
      domLayout: 'normal',
      suppressAggFuncInHeader: true,
      animateRows: false,
      getRowStyle: (params) => (params?.node?.group ? { 'font-weight': 'bold' } : {}),
      groupIncludeFooter: true,
      groupDefaultExpanded: 1,
      groupSuppressAutoColumn: false,
      groupUseEntireRow: false,
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 135,
      },
      stopEditingWhenCellsLoseFocus: true,
      columnDefs: this.columnDefinitions,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      getContextMenuItems: getContextMenuItems(
        gotoMenu(
          gotoMenuItem(this.delegate, 'Linked Voucher', 'voucherId', SourceEntityType.INVOICE_KEY, 'get'),
          gotoMenuItem(this.delegate, 'Linked Payment', 'auxPaymentId', SourceEntityType.PAYMENT_KEY, 'get')
        ),
        this.getDeselectAllOption(),
        'separator',
        this.addLineOption(),
        this.deleteLineOption()
      ),
      statusBar: {
        statusPanels,
      },
    };
    endpointAuthorizationSubscription(store, this);
  }

  writeValue(lines: PaymentLine[]): void {
    if (!lines) return;
    this.data = lines;
    this.getTotalAmounts();
    this.calculateTotals();
  }

  getDeselectAllOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (!!params.api.getSelectedNodes().length) {
        return {
          name: 'Deselect All',
          action: () => {
            params.api.deselectAll();
          },
        };
      }
      return [];
    };
  }

  addNewLine($event) {
    if ($event) return this.addLine();
    return;
  }

  addLine() {
    if (this.gridApi) {
      const maxPosition = this.data.reduce((position, existing) => Math.max(position, existing.lineNumber), 0) || 0;
      const row: PaymentLine = {
        lineNumber: maxPosition + 1,
      };
      this.data.push(row);
      this.gridApi.setRowData(this.data);
      this.calculateTotals();
      this.onDataChanged();
      return row;
    }
  }

  addLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (this.readonly) return [];
      return [
        {
          name: `Add Line`,
          action: this.addLine.bind(this),
        },
      ];
    };
  }

  deleteLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (this.readonly) return [];
      const selectedNodes = params.api.getSelectedNodes();
      const thisNode = (params as GetContextMenuItemsParams).node;
      const options = [];
      if (!!thisNode && (selectedNodes.length !== 1 || !selectedNodes.includes(thisNode))) {
        options.push({
          name: `Delete This Line`,
          action: () => {
            const index = this.data.findIndex((row) => row.lineNumber === thisNode.data.lineNumber);
            if (index < 0) return;
            this.data.splice(index, 1);
            this.gridApi.setRowData(this.data);
            this.calculateTotals();
            this.getTotalAmounts();
            this.onDataChanged();
          },
        });
      }
      if (!!selectedNodes.length) {
        options.push({
          name: `Delete Selected ${selectedNodes.length > 1 ? 'Lines' : 'Line'} `,
          action: () => {
            for (const selectedNode of selectedNodes) {
              const index = this.data.findIndex((row) => row.lineNumber === selectedNode.data.lineNumber);
              if (index < 0) continue;
              this.data.splice(index, 1);
            }
            this.gridApi.setRowData(this.data);
            this.onDataChanged();
            this.getTotalAmounts();
            this.onDataChanged();
          },
        });
      }
      return options;
    };
  }

  onCellValueChanged(event: NewValueParams) {
    this.calculateTotals();
    this.onDataChanged();
  }

  onDataChanged() {
    if (typeof this.onChange === 'function') {
      this.onChange(
        this.data.map((line): PaymentLine => {
          return {
            lineNumber: line.lineNumber,
            counterpartyId: line.counterparty ? line.counterparty.id : null,
            counterparty: line.counterparty,
            externalRef: line.externalRef || '',
            voucherId: line.voucher ? line.voucher.id : null,
            voucher: line.voucher,
            accountId: line.glAccount ? line.glAccount.id : null,
            glAccount: line.glAccount,
            amount: line.amount,
            baseAmount: line.baseAmount,
            entryText: line.entryText,
            voucherSign: line.voucher ? line.voucherSign : undefined,
            xr: line.glAccount ? line.xr : undefined,
            paymentEntryId: line.paymentEntryId,
            paymentId: line.paymentId,
            matchedPaymentEntry: line.matchedPaymentEntry,
            auxPaymentEntryId: line.matchedPaymentEntry ? line.matchedPaymentEntry.paymentEntryId : null,
          };
        })
      );
    }
    if (typeof this.onValidationChange === 'function') {
      this.onValidationChange();
    }
    this.validateAccountCurrencies();
    this.validateAccountCompanies();
    this.getTotalAmounts();
  }

  accountValueFormatter(row: { data: PaymentLine }) {
    if (row && row.data && row.data.glAccount && row.data.glAccount.idenLong) return row.data.glAccount.idenLong;
    return null;
  }

  voucherValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.iden) return params.value.iden;
    return '';
  }

  paymentEntryValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.iden) return params.value.iden;
    return '';
  }

  counterpartyValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.displayName) return params.value.displayName;
    return '';
  }

  xrValueSetter(data: PaymentLine[]) {
    for (const row of data) {
      const xr = (this.exchangeRates || []).find((xr) => (row.glAccount ? xr.baseCurrencyId === row.glAccount.currencyId && xr.targetCurrencyId === this.baseCurrencyId : undefined));
      if (row.glAccount) {
        if (xr) {
          row.xr = xr.factor;
        } else if (row.glAccount.currencyId === this.baseCurrencyId) {
          row.xr = 1;
        } else {
          row.xr = null;
        }
        row.baseAmount = row.amount && row.xr ? this.formatter.roundAmount(row.amount * row.xr, this.baseCurrencyId) : null;
      }
    }
  }

  getTotalAmounts() {
    let amount = this.data.reduce((p, c) => {
      let adjusted: number;
      adjusted = c.amount;
      return (adjusted || 0) + p;
    }, 0);
    let baseAmount = this.data.reduce((p, c) => {
      let adjusted: number;
      adjusted = c.baseAmount;
      return (adjusted || 0) + p;
    }, 0);
    this.totalRow.amount = amount;
    this.totalRow.baseAmount = baseAmount;
    this.totalAmounts.emit({ amount, baseAmount });
  }

  validateAccountCurrencies() {
    if (!this.enableDifferentCurrencies) {
      if (this.data.length > 0) {
        const firstLine = this.data[0];
        this.linesCurrencyId = firstLine.glAccount ? firstLine.glAccount.currencyId : null;
        this.linesCurrId.emit(this.linesCurrencyId);
        if (!firstLine.glAccount) return true;
        const hasSameCurrencies = !this.data.some((line) => line.glAccount && line.glAccount.currencyId !== firstLine.glAccount.currencyId);
        if (!hasSameCurrencies) this.linesCurrId.emit(null);
        return hasSameCurrencies;
      }
      return true;
    } else {
      this.linesCurrencyId = null;
      this.linesCurrId.emit(this.linesCurrencyId);
      return true;
    }
  }

  validateAccountCompanies() {
    if (this.data.length > 0) {
      const firstLine = this.data[0];
      if (!firstLine.glAccount) return true;
      return !this.data.some((line) => line.glAccount && line.glAccount.companyId !== firstLine.glAccount.companyId);
    }
    return true;
  }

  validate(control: AbstractControl): ValidationErrors {
    this.errors.splice(0, this.errors.length);
    if (!(control.value instanceof Array)) {
      this.errors.push('Invalid line values');
    } else {
      let count = 0;
      let sum = 0;
      let baseAmountSum = 0;
      let voucherIds = new Set<number>();
      for (const line of control.value as PaymentLine[]) {
        if (!line.lineNumber) continue;
        const pos = line.lineNumber;
        if ((!line.counterparty || line.counterparty.id === 0) && line.glAccount && (line.glAccount.accountOption === 10 || line.glAccount.accountOption === 11))
          this.errors.push(`Missing Counterparty on line #${pos}`);
        if (!line.entryText) this.errors.push(`Missing Description on line #${pos}`);
        if (line.voucher && line.voucher.id !== 0) {
          if (voucherIds.has(line.voucher.id)) {
            this.errors.push(`Voucher must be different for all lines`);
          } else {
            voucherIds.add(line.voucher.id);
          }
        }
        if (!line.glAccount) {
          this.errors.push(`Missing Account on line #${pos}`);
        } else {
          if (this.companyId && line.glAccount.companyId !== this.companyId) this.errors.push(`Account company on line #${pos} must be the same to Header Account Company`);
          if (!this.validateAccountCurrencies()) this.errors.push(`Account's currencies must be the same for all lines`);
          if (!this.validateAccountCompanies()) this.errors.push(`Account's companies must be the same for all lines`);
          if (!this.enableDifferentCurrencies && !!this.currencyId && this.currencyId !== line.glAccount.currencyId) this.errors.push(`Account's currencies must be the same as the Header Account`);
        }

        const amount = line.amount ? this.formatter.roundAmount(line.amount, line?.glAccount?.currencyId) : 0;
        const baseAmount = line.baseAmount ? this.formatter.roundAmount(line.baseAmount, this.baseCurrencyId) : 0;
        if (!amount && amount === 0) this.errors.push(`Missing Amount on line #${pos}`);
        if (line.xr && line.xr < 0) this.errors.push(`XR on line #${pos} cannot be zero`);
        if (!baseAmount && baseAmount === 0) this.errors.push(`Missing Base Amount on line #${pos}`);
        if (amount === 0 && baseAmount !== 0) this.errors.push(`Amount on line #${pos} cannot be zero`);
        if (baseAmount === 0 && amount !== 0) this.errors.push(`Base Amount on line #${pos} cannot be zero`);
        if (Math.sign(amount) !== Math.sign(baseAmount)) this.errors.push(`Amount and Base Amount on line #${pos} must have the same sign`);
        if (line.voucher && line.voucher.id) {
          const voucherAmount = this.formatter.roundAmount(line.voucher.amount * line.voucherSign, line?.glAccount?.currencyId);
          const voucherBaseAmount = this.formatter.roundAmount(line.voucher.baseAmount * line.voucherSign, this.baseCurrencyId);
          if (Math.sign(amount) !== Math.sign(voucherAmount)) this.errors.push(`Amount and Voucher Amount on line #${pos} must have the same sign`);
          if (Math.abs(amount) > Math.abs(voucherAmount)) this.errors.push(`Amount on line #${pos} cannot exceed the Voucher Amount`);
          if (Math.sign(baseAmount) !== Math.sign(voucherBaseAmount)) this.errors.push(`Base Amount and Voucher Base Amount on line #${pos} must have the same sign`);
        }
        if (amount !== 0) count++;
        sum += amount ? amount : null;
        baseAmountSum += baseAmount ? baseAmount : null;
      }
      if (count < 1) this.errors.push(`The line should have at least one valid line`);
      if (round(sum, 2) != 0 && !this.currencyId && !this.enableDifferentCurrencies) this.errors.push(`The payment Amounts are not balanced, set a Header Account`);
      if (round(baseAmountSum, 2) != 0 && !this.currencyId && this.enableDifferentCurrencies) this.errors.push(`The payment Base Amounts are not balanced, set a Header Account`);
      const headerBaseAmount = round(this.headerBaseAmount, 2);
      const sumBAmount = round(baseAmountSum, 2);
      if (Math.sign(headerBaseAmount) !== Math.sign(sumBAmount)) {
        if (headerBaseAmount + sumBAmount !== 0) this.errors.push(`Sum of Lines Base Amount and header Base Amount must be zero`);
        if (Math.sign(headerBaseAmount) === 0 && headerBaseAmount + sumBAmount !== 0) this.errors.push(`Sum of Lines Base Amount and header Base Amount must be zero`);
      }
      if (Math.sign(headerBaseAmount) === Math.sign(sumBAmount) && Math.sign(sumBAmount) !== 0) this.errors.push(`Header Base Amount must have different sign to Sum of Lines Base Amount`);
    }
    return !!this.errors && this.errors.length > 0 ? { custom: this.errors[0] } : null;
  }

  calculateTotals() {
    if (this.gridApi && this.data) {
      // Calculate the total debits, total credits and total of all amount lines
      this.totalRow.debits = 0;
      this.totalRow.credits = 0;
      this.data.reduce((totals, line) => {
        if (totals.debits !== false && totals.credits !== false) {
          const value = typeof line.amount === 'number' ? line.amount : !line.amount ? 0 : parseFloat(line.amount);
          if (isNaN(value)) {
            totals.debits = false;
            totals.credits = false;
            return totals;
          }
          if (value < 0) {
            totals.credits += value;
          } else {
            totals.debits += value;
          }
        }
        return totals;
      }, this.totalRow);

      this.gridApi.refreshCells({ columns: ['amount', 'baseAmount'] });
    }
  }

  importExcel(event: any) {
    const prompt = this.delegate.getService('prompt');
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length > 1) return prompt.htmlDialog('Error', `<div style="white-space: pre">Cannot use multiple files</div>`);
    if (target.files.length === 0) return prompt.htmlDialog('Error', `<div style="white-space: pre">No files selected</div>`);
    this.convertData(target.files[0]);
  }

  // read the raw data and convert it to a JSON
  convertData(importFile) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(importFile);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data: PaymentsExcelData[] = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // Data will be logged in array format containing objects
      /* populate ag grid mapping data */
      const resultData = this.populateExcelData(data);
      resultData.subscribe((data) => {
        this.writeValue(data);
        this.onDataChanged();
      });
    };
  }

  populateExcelData(data: PaymentsExcelData[]): Observable<PaymentLine[]> {
    const api = this.delegate.getService('api');
    const spinner = this.delegate.getService('spinner');
    const prompt = this.delegate.getService('prompt');
    return from(
      new Promise<PaymentLine[]>((resolve, reject) => {
        (async () => {
          let rid = spinner.startRequest('Drawing Data');
          let lineNumber = 1;
          let result: PaymentLine[] = [];
          const counterpartyMap = new Map<string, Contact>();
          const voucherMap = new Map<number, InvoiceHeader>();
          const matchedPaymentMap = new Map<string, PaymentEntry>();
          const glAccountMap = new Map<string, GlAccountLeaves>();
          for (let item of data) {
            const mappedItem = renameExcelKeys(item, excelHeaderMap);
            item.lineNumber = lineNumber++;
            let lineCounterparty: Contact | undefined = undefined;
            let lineVoucher: InvoiceHeader | undefined = undefined;
            let lineMatchedPayment: PaymentEntry | undefined = undefined;
            let lineGlAccount: GlAccountLeaves | undefined = undefined;
            if (mappedItem.voucherNumber && mappedItem.matchedPaymentEntryIden) {
              spinner.completeRequest(rid);
              return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Cannot set Voucher and Payment Entry in the same entry on line #${item.lineNumber}</div>`));
            }
            if (mappedItem.counterpartyName !== undefined) {
              lineCounterparty = counterpartyMap.get(mappedItem.counterpartyName);
              item.counterparty = lineCounterparty;
              if (lineCounterparty === undefined) {
                const counterpartiesResponse = await api.run<ListResponse<Contact>>(endpoints.listContacts, { filters: { displayName: `%${mappedItem.counterpartyName}%` } }, null);
                if (counterpartiesResponse.list.length === 1) {
                  lineCounterparty = counterpartiesResponse.list[0];
                  counterpartyMap.set(lineCounterparty.displayName, lineCounterparty);
                  item.counterparty = lineCounterparty;
                } else if (counterpartiesResponse.list.length > 1) {
                  spinner.completeRequest(rid);
                  await new Promise((resolve) => {
                    const formResponse = selectItemFormToPopulate<Contact & { useForFollowing?: boolean }>(
                      this.delegate,
                      counterpartiesResponse.list,
                      `Select the Counterparty for Counterparty Name: ${mappedItem.counterpartyName} on line #${item.lineNumber}`,
                      'id',
                      'displayName',
                      'Counterparties',
                      'useForFollowing'
                    );
                    formResponse.subscribe((formResult) => {
                      if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify Counterparty: ${mappedItem.counterpartyName}</div>`));
                      lineCounterparty = counterpartiesResponse.list.find((item) => item.id === formResult.id);
                      if (formResult.useForFollowing) {
                        data.forEach((entry) => {
                          if (entry[ExcelHeaders.COUNTERPARTY] === mappedItem.counterpartyName) entry[ExcelHeaders.COUNTERPARTY] = mappedItem.counterpartyName;
                        });
                        counterpartyMap.set(mappedItem.counterpartyName, lineCounterparty);
                      }
                      item.counterparty = lineCounterparty;
                      rid = spinner.startRequest('Drawing Data');
                      return resolve(lineCounterparty);
                    });
                  });
                } else {
                  spinner.completeRequest(rid);
                  return prompt.htmlDialog('Error', `<div style="white-space: pre">Counterparty: ${mappedItem.counterpartyName} not found on line #${item.lineNumber}</div>`);
                }
              }
            }
            if (mappedItem.voucherNumber !== undefined) {
              lineVoucher = voucherMap.get(mappedItem.voucherNumber);
              item.voucher = lineVoucher;
              if (lineVoucher === undefined) {
                const vouchersResponse = await api.run<ListResponse<InvoiceHeader>>(
                  endpoints.listVouchersBalance,
                  {
                    filters: {
                      counterpartyId: item.counterparty.id,
                      number: `%${mappedItem.voucherNumber}%`,
                    },
                  },
                  null
                );
                if (vouchersResponse.list.length === 1) {
                  lineVoucher = vouchersResponse.list[0];
                  voucherMap.set(lineVoucher.number, lineVoucher);
                  item.voucher = lineVoucher;
                } else if (vouchersResponse.list.length > 1) {
                  spinner.completeRequest(rid);
                  await new Promise((resolve) => {
                    const formResponse = selectItemFormToPopulate<InvoiceHeader & { useForFollowing?: boolean }>(
                      this.delegate,
                      vouchersResponse.list,
                      `Select the Voucher for Voucher Number: ${mappedItem.voucherNumber} on line #${item.lineNumber}`,
                      'number',
                      'iden',
                      'Vouchers',
                      'useForFollowing'
                    );
                    formResponse.subscribe((formResult) => {
                      if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify voucher: ${mappedItem.voucherNumber}</div>`));
                      lineVoucher = vouchersResponse.list.find((item) => item.number === formResult.number);
                      if (formResult.useForFollowing) {
                        data.forEach((entry) => {
                          if (entry[ExcelHeaders.VOUCHER] === mappedItem.voucherNumber) entry[ExcelHeaders.VOUCHER] = mappedItem.voucherNumber;
                        });
                        voucherMap.set(mappedItem.voucherNumber, lineVoucher);
                      }
                      item.voucher = lineVoucher;
                      rid = spinner.startRequest('Drawing Data');
                      return resolve(lineVoucher);
                    });
                  });
                } else {
                  spinner.completeRequest(rid);
                  return prompt.htmlDialog('Error', `<div style="white-space: pre">Voucher Number: ${mappedItem.voucherNumber} not found on line #${item.lineNumber}.</div>`);
                }
              }
            }
            if (mappedItem.matchedPaymentIden !== undefined) {
              lineMatchedPayment = matchedPaymentMap.get(mappedItem.matchedPaymentIden);
              item.matchedPaymentEntry = lineMatchedPayment;
              if (lineMatchedPayment === undefined) {
                const matchedPaymentsResponse = await api.run<ListResponse<PaymentEntry>>(
                  endpoints.listPaymentEntries,
                  {
                    filters: {
                      counterpartyId: item.counterparty.id,
                      iden: `%${mappedItem.matchedPaymentIden}%`,
                    },
                  },
                  null
                );
                if (matchedPaymentsResponse.list.length === 1) {
                  lineMatchedPayment = matchedPaymentsResponse.list[0];
                  matchedPaymentMap.set(lineMatchedPayment.iden, lineMatchedPayment);
                  item.matchedPaymentEntry = lineMatchedPayment;
                } else if (matchedPaymentsResponse.list.length > 1) {
                  spinner.completeRequest(rid);
                  await new Promise((resolve) => {
                    const formResponse = selectItemFormToPopulate<PaymentEntry & { useForFollowing?: boolean }>(
                      this.delegate,
                      matchedPaymentsResponse.list,
                      `Select the Payment entry for Payment Entry Iden: ${mappedItem.matchedPaymentIden} on line #${item.lineNumber}`,
                      'iden',
                      'iden',
                      'Payment Entries',
                      'useForFollowing'
                    );
                    formResponse.subscribe((formResult) => {
                      if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify Payment Entry: ${mappedItem.matchedPaymentIden}</div>`));
                      lineMatchedPayment = matchedPaymentsResponse.list.find((item) => item.iden === formResult.iden);
                      if (formResult.useForFollowing) {
                        data.forEach((entry) => {
                          if (entry[ExcelHeaders.MATCHPAYMENTENTRY] === mappedItem.matchedPaymentIden) entry[ExcelHeaders.MATCHPAYMENTENTRY] = mappedItem.matchedPaymentIden;
                        });
                        matchedPaymentMap.set(mappedItem.matchedPaymentIden, lineMatchedPayment);
                      }
                      item.matchedPaymentEntry = lineMatchedPayment;
                      rid = spinner.startRequest('Drawing Data');
                      return resolve(lineMatchedPayment);
                    });
                  });
                } else {
                  spinner.completeRequest(rid);
                  return prompt.htmlDialog('Error', `<div style="white-space: pre">Payment Entry: ${mappedItem.matchedPaymentIden} not found on line #${item.lineNumber}</div>`);
                }
              }
            }
            if (mappedItem.accountCode !== undefined) {
              lineGlAccount = glAccountMap.get(mappedItem.accountCode);
              item.glAccount = lineGlAccount;
              if (lineGlAccount === undefined) {
                const accountsResponse = await api.run<ListResponse<GlAccountLeaves>>(
                  endpoints.listGlAccounts,
                  {
                    filters: {
                      companyId: item.counterparty.fiscalCompanyId,
                      archived: YN.N,
                      accountCode: `%${mappedItem.accountCode}%`,
                    },
                  },
                  null
                );
                if (accountsResponse.list.length === 1) {
                  lineGlAccount = accountsResponse.list[0];
                  glAccountMap.set(lineGlAccount.idenLong, lineGlAccount);
                  item.glAccount = lineGlAccount;
                } else if (accountsResponse.list.length > 1) {
                  spinner.completeRequest(rid);
                  await new Promise((resolve) => {
                    const formResponse = selectItemFormToPopulate<GlAccountLeaves & { useForFollowing?: boolean }>(
                      this.delegate,
                      accountsResponse.list,
                      `Select the Account for Account Code: ${mappedItem.accountCode} on line #${item.lineNumber}`,
                      'id',
                      'idenLong',
                      'Accounts',
                      'useForFollowing'
                    );
                    formResponse.subscribe((formResult) => {
                      if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify Account: ${mappedItem.accountCode}</div>`));
                      lineGlAccount = accountsResponse.list.find((item) => item.id === formResult.id);
                      if (formResult.useForFollowing) {
                        data.forEach((entry) => {
                          if (entry[ExcelHeaders.ACCOUNT] === mappedItem.accountCode) entry[ExcelHeaders.ACCOUNT] = mappedItem.accountCode;
                        });
                        glAccountMap.set(mappedItem.accountCode, lineGlAccount);
                      }
                      item.glAccount = lineGlAccount;
                      rid = spinner.startRequest('Drawing Data');
                      return resolve(lineGlAccount);
                    });
                  });
                } else {
                  spinner.completeRequest(rid);
                  return prompt.htmlDialog('Error', `<div style="white-space: pre">Account: ${mappedItem.accountCode} not found on line #${item.lineNumber}</div>`);
                }
              }
            }

            let contacType: ContactTypeAssignment[] = [];
            if (item.counterparty) {
              contacType = item.counterparty.contactTypeAssignments;
              if (contacType.some((item) => item.contactTypeId === CommonContactTypes.SUPPLIER) && !contacType.some((item) => item.contactTypeId === CommonContactTypes.CUSTOMER)) {
                item.glAccount = item.counterparty.payablesAccount as GlAccountLeaves;
              } else {
                item.glAccount = item.counterparty.receivablesAccount as GlAccountLeaves;
              }
            }
            if (mappedItem.amount) {
              item.amount = this.formatter.roundAmount(mappedItem.amount, item?.glAccount?.currencyId);
            }
            if (mappedItem.baseAmount) {
              item.baseAmount = this.formatter.roundAmount(mappedItem.baseAmount, this.baseCurrencyId);
            }
            if (item.voucher) {
              item.glAccount = item.glAccount ? item.glAccount : item.voucher.account;
              item.entryText = item.entryText ? item.entryText : `${item.voucher.iden} / ${item.voucher.yourReference}`;
              item.voucherSign = getVoucherSign(item.voucher.documentType, item.voucher.voucherType);
              if (item.amount && item.voucherSign)
                item.amount = item.amount
                  ? this.formatter.roundAmount(item.amount, item?.glAccount?.currencyId)
                  : this.formatter.roundAmount(item.voucherSign * item.voucher.amount, item?.glAccount?.currencyId);
            }
            if (item.matchedPaymentEntry) {
              item.glAccount = item.glAccount ? item.glAccount : item.matchedPaymentEntry.glAccount;
              item.entryText = item.entryText ? item.entryText : item.matchedPaymentEntry.entryText;
              item.amount = item.amount
                ? this.formatter.roundAmount(item.amount, item?.glAccount?.currencyId)
                : this.formatter.roundAmount(-item.matchedPaymentEntry.amount, item?.glAccount?.currencyId);
              item.baseAmount = item.baseAmount
                ? this.formatter.roundAmount(item.baseAmount, this.baseCurrencyId)
                : this.formatter.roundAmount(-item.matchedPaymentEntry.baseAmount, this.baseCurrencyId);
            }
            if (item.glAccount) {
              const xr = (this.exchangeRates || []).find((xr) => (item.glAccount ? xr.baseCurrencyId === item.glAccount.currencyId && xr.targetCurrencyId === this.baseCurrencyId : undefined));
              if (xr) {
                item.xr = xr.factor;
              } else if (item.glAccount.currencyId === this.baseCurrencyId) {
                item.xr = 1;
              } else {
                item.xr = null;
              }
              item.baseAmount = item.amount && item.xr ? this.formatter.roundAmount(item.amount * item.xr, this.baseCurrencyId) : null;
            }
            if (mappedItem.description) {
              item.entryText = mappedItem.description;
            }
            if (mappedItem.externalRef) {
              item.externalRef = mappedItem.externalRef;
            }
            result.push(item);
          }
          spinner.completeRequest(rid);
          return resolve(result);
        })();
      })
    );
  }
}

export type Totals = { amount: number; baseAmount: number };

type PaymentsExcelData = Pick<
  PaymentLine,
  'counterparty' | 'voucher' | 'voucherSign' | 'matchedPaymentEntry' | 'entryText' | 'externalRef' | 'glAccount' | 'amount' | 'xr' | 'baseAmount' | 'lineNumber'
>;
