<div class="row" [formGroup]="form">
  <vertical-field name="Title" class="col-12">
    <textarea-wrapper [formControlName]="'taskTitle'" [ignoreReadonlyMode]="true"></textarea-wrapper>
  </vertical-field>
  <vertical-field name="Description" class="col-12">
    <textarea-wrapper [formControlName]="'taskText'" [ignoreReadonlyMode]="true"></textarea-wrapper>
  </vertical-field>
  <vertical-field name="Priority" class="col-12">
    <simple-dropdown [formControlName]="'taskPriority'" [data]="taskPriorities" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [ignoreReadonlyMode]="true"></simple-dropdown>
  </vertical-field>
  <vertical-field name="Due Date" class="col-12">
    <datepicker-wrapper [formControlName]="'dueDate'" [ignoreReadonlyMode]="true"></datepicker-wrapper>
  </vertical-field>
  <vertical-field name="Complete" class="col-12" *ngIf="!!form.value.id">
    <checkbox-wrapper [formControlName]="'accomplished'" [valueMask]="{ true: 'Y', false: 'N' }" [ignoreReadonlyMode]="true"></checkbox-wrapper>
  </vertical-field>
  <vertical-field name="Assignees" class="col-12">
    <multiselect-wrapper [formControlName]="'assignees'" [dropdownConfig]="employeeDropdown" [ignoreReadonlyMode]="true"></multiselect-wrapper>
  </vertical-field>
  <div class="d-flex col-12" *ngIf="form.value.id" style="flex-direction: column">
    <div *ngIf="createdByLabel">{{ createdByLabel }}</div>
    <div *ngIf="updatedByLabel">{{ updatedByLabel }}</div>
  </div>
</div>
