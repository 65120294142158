import { Subset } from '../generics';
import { YN } from './enums';
import { PropertyDocument } from './propertyDocument';

export type CheckListItem = {
  id: number;
  name: string;
  type: CheckListValueType;
  archived: YN;
};
export type CheckListEntry = {
  itemId: number;
  shipmentId: number;
  value: string;
  checkListItem?: CheckListItem;
  shipment?: PropertyDocument;
};
export enum CheckListValueType {
  YN = 1,
  NUMBER = 2,
  STRING = 3,
  NEGATIVE_STRING = 4,
}

export interface CreateCheckListEntryRequest extends Subset<CheckListEntry, 'itemId' | 'shipmentId' | 'value'> {}
export type UpdateCheckListEntryRequest = Subset<CreateCheckListEntryRequest, 'itemId' | 'shipmentId'> & Partial<Pick<CreateCheckListEntryRequest, 'value'>>;
