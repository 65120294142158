<kendo-combobox
  #dropdown
  [data]="filteredData || []"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [ngClass]="{ readonly: readonly }"
  [readonly]="readonly"
  (filterChange)="handleFilter($event)"
  [filterable]="true"
  [suggest]="true"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  (open)="onOpen()"
  [valuePrimitive]="valuePrimitive || !valueField"
  [placeholder]="placeholder"
  (open)="onOpen()"
>
  <ng-template *ngIf="!!secondaryTextField" kendoComboBoxItemTemplate let-dataItem>
    <div class="mb-1">
      <div class="">{{ dataItem[textField] }}</div>
      <div class="small" [innerHTML]="dataItem[secondaryTextField]"></div>
    </div>
  </ng-template>
</kendo-combobox>
