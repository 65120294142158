import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { numberWithCommas } from 'src/lib/helperFunctions';
import { YN } from 'src/lib/newBackendTypes';

@Component({
  selector: 'debit-credit-renderer',
  template: `
    <container-element [ngSwitch]="type">
      <ng-container *ngSwitchCase="'value'">
        <div class="container">
          <span class="type">{{ prefix }}</span>
          <span class="value">{{ value }}</span>
          <span class="currency">{{ currency || '' }}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'empty'">
        {{ empty }}
      </ng-container>
      <ng-container *ngSwitchDefault> Error </ng-container>
    </container-element>
  `,
  styleUrls: ['./DebitCreditRenderer.scss'],
})
export class DebitCreditRenderer implements ICellRendererAngularComp {
  private value: string | null | false = null;
  currency: string | null = null;
  prefix: 'C' | 'D' | '' = '';
  empty = '';

  constructor(private delegate: DelegateService) {}
  get type() {
    if (this.value === null) return 'empty';
    if (typeof this.value === 'string') return 'value';
    return 'error';
  }

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(
    params: ICellRendererParams & {
      currencyField?: string;
      currency?: number | string;
      decimals?: number;
      separator?: YN;
      emptyValue?: string;
    }
  ) {
    const value = params.valueFormatted ? params.valueFormatted : params.value;
    if (params.emptyValue !== undefined) this.empty = params.emptyValue;
    if (!value && value !== 0) {
      this.value = null;
      this.prefix = '';
    } else {
      const number = parseFloat(value);
      if (isNaN(number)) {
        this.value = false;
        this.prefix = '';
      } else {
        this.currency = null;
        this.prefix = number < 0 ? 'C' : number > 0 ? 'D' : '';
        let formatted = Math.abs(number).toFixed(params.decimals || 2);
        if (params.separator !== YN.N) {
          this.value = numberWithCommas(formatted);
        } else {
          this.value = formatted.toString();
        }
        if (params.currencyField) {
          const currency = params.currencyField.split('.').reduce((o, i) => {
            return o !== null && typeof o === 'object' && !!o[i] ? o[i] : null;
          }, params.data);
          if (!currency) {
            this.currency = null;
          } else if (typeof currency === 'string') {
            this.currency = currency;
          } else if (typeof currency === 'number') {
            const currencyObject = this.delegate.commonData.staticCurrencies.value.find((c) => c.id === currency);
            this.currency = currencyObject ? currencyObject.code : null;
          }
        }
        if (this.currency === null && !!params.currency) {
          if (typeof params.currency === 'string') {
            this.currency = params.currency;
          } else if (typeof params.currency === 'number') {
            const currencyObject = this.delegate.commonData.staticCurrencies.value.find((c) => c.id === params.currency);
            this.currency = currencyObject ? currencyObject.code : null;
          }
        }
      }
    }
    return true;
  }
}
