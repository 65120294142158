import { EnumLabels } from '../generics';
import { PaymentTermDate, WaTermDate } from './waTermDate';

export type WaTermDateLine = {
  id: number;
  termDateId: PaymentTermDate;
  columnName: TermDateLineColumn;
  adjustmentDays: number;
  weight: number;
  termDate?: WaTermDate;
};

export enum TermDateLineColumn {
  APPOINTMENT_DATE = 'APPOINTMENT_DATE',
  BL_DATE = 'BL_DATE',
  CARGO_RELEASED_DATE = 'CARGO_RELEASED_DATE',
  CONFIRMED_DELIVERY_DATE = 'CONFIRMED_DELIVERY_DATE',
  CONTRACT_DATE = 'CONTRACT_DATE',
  CUST_DOCS_UPLOADED = 'CUST_DOCS_UPLOADED',
  DELIVERY_DATE = 'DELIVERY_DATE',
  DESTINATION_ETA_DATE = 'DESTINATION_ETA_DATE',
  DOCS_FAX_TO_CLIENT_DATE = 'DOCS_FAX_TO_CLIENT_DATE',
  DOCS_RCVD_FROM_SUPP_DATE = 'DOCS_RCVD_FROM_SUPP_DATE',
  DOCS_TO_CLIENT_DATE = 'DOCS_TO_CLIENT_DATE',
  INV_DATE = 'INV_DATE',
  LC_DOCUMENTS_COMPLETE = 'LC_DOCUMENTS_COMPLETE',
  ORIGINAL_BL_RCVD_DATE = 'ORIGINAL_BL_RCVD_DATE',
  UNLOADING_ETA_DATE = 'UNLOADING_ETA_DATE',
}

export const terDateLineColumns: EnumLabels<TermDateLineColumn> = [
  { label: 'Appointment Date (FB)', value: TermDateLineColumn.APPOINTMENT_DATE },
  { label: 'BL Date (FB)', value: TermDateLineColumn.BL_DATE },
  { label: 'Cargo ATA (FB)', value: TermDateLineColumn.CONFIRMED_DELIVERY_DATE },
  { label: 'Cargo Release (FB)', value: TermDateLineColumn.CARGO_RELEASED_DATE },
  { label: 'Contract Date (PC)', value: TermDateLineColumn.CONTRACT_DATE },
  { label: 'Cust Docs Uploaded (FB)', value: TermDateLineColumn.CUST_DOCS_UPLOADED },
  { label: 'Delivery Date (FB)', value: TermDateLineColumn.DELIVERY_DATE },
  { label: 'Destination ETA (FB)', value: TermDateLineColumn.DESTINATION_ETA_DATE },
  { label: 'Docs to Customer (FB)', value: TermDateLineColumn.DOCS_TO_CLIENT_DATE },
  { label: 'Draft Docs to Customer (FB)', value: TermDateLineColumn.DOCS_FAX_TO_CLIENT_DATE },
  { label: 'Invoice Date (SI)', value: TermDateLineColumn.INV_DATE },
  { label: 'Latest of LC Date (LC) and OBL received (FB)', value: TermDateLineColumn.LC_DOCUMENTS_COMPLETE },
  { label: 'Original BL Received (FB)', value: TermDateLineColumn.ORIGINAL_BL_RCVD_DATE },
  { label: 'Supplier Docs Received (FB)', value: TermDateLineColumn.DOCS_RCVD_FROM_SUPP_DATE },
  { label: 'Unloading ETA (FB)', value: TermDateLineColumn.UNLOADING_ETA_DATE },
];
