import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { BankAccount } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getAccountListVoucherArgsGraphql(companyId: number, take: number = 20) {
  const args: GraphqlRequest<BankAccount> = {
    where: { id: { not: { equals: 0 } }, ownerCode: { equals: companyId }, iden: { contains: new VariableType('iden') } },
    orderBy: [{ iden: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getAccountListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<BankAccount> = {
    where: { id: { not: { equals: 0 } }, iden: { contains: new VariableType('iden') } },
    orderBy: [{ iden: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getAccountListVariablesGraphql() {
  const variables: GraphqlVariables<BankAccount> = { iden: 'String' };
  return variables;
}

export const listAccountGraphqlRequest = (args: GraphqlRequest<BankAccount>): GraphqlArgsRequest<BankAccount> => {
  return {
    __aliasFor: ExistingGraphql.accounts,
    __args: args,
    id: true,
    iden: true,
    ownerCode: true,
  };
};
