import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { bradyDateValidator, conditionalValidators } from 'src/lib/genericValidators';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { PayOrderHeader, PayOrderStatus, PayOrderType } from 'src/lib/newBackendTypes/payOrder';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const createBookingPayOrderPrefill: prefillCallback<BookPayOrderPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const payOrdersIds = Array.isArray(id) ? id : [id];
      let api = delegate.getService('api');

      const payOrdersResponse = (await api.run(endpoints.listPayOrders, {
        filters: { id: payOrdersIds },
      })) as ListResponse<PayOrderHeader>;
      const items = payOrdersResponse.list;
      return { items };
    })()
  );
};

const createBookingPayOrderCheck: checkPrefillCallback<BookPayOrderPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  for (let item of prefill.items) {
    if (item.payOrderStatus === PayOrderStatus.BOOKED) return of('Unable to book pay order(s): some pay order has already been booked.');
    if (item.payOrderStatus === PayOrderStatus.OPENED) return of('Unable to book pay order(s): you cannot book a Pay Order in the Open status.');
    if (!item.payOrderLines) return of('Unable to book pay order(s): some pay order(s) lines are not available.');
    if (item.payOrderLines.length < 1) return of('Unable to book pay order(s): some pay order does not have any lines.');
    if (prefill.items.length > 1 && item.payOrderType === PayOrderType.CHECK) return of('Unable to book multiple Check Pay Orders.');
  }
  return of(true);
};

const createBookingPayOrderForm: createFormCallback<BookPayOrderPrefill, BookPayOrderForm> = (delegate, id, prefill) => {
  const payOrders = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
  const isCheckRequired: boolean = payOrders.some((item) => item.payOrderType === PayOrderType.CHECK);

  return [
    {
      type: 'Label',
      text: `Book Pay Order(s): ${prefill ? 'ID(s): ' + payOrders.map((item) => item.id).join(', ') : 'Unknown'}`,
    },
    [
      {
        type: 'Date',
        field: 'valueDate',
        label: 'Value Date',
        validator: [Validators.required, bradyDateValidator()],
        startingValue: new Date(),
      },
    ],
    [
      {
        ..._fe(
          'checkNumber',
          'Check Number',
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: false,
          },
          payOrders[0].checkNumber,
          [],
          true
        ),
        readonly: !isCheckRequired,
        validatorFactory: () => conditionalValidators(() => isCheckRequired, Validators.required),
      },
    ],
  ];
};

const createBookingPayOrderCallback: submitFormCallback<BookPayOrderPrefill, BookPayOrderForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader[]>((resolve, reject) => {
      (async () => {
        if (prefill.items.length === 0) throw new Error('No pay orders to be booked');

        const bookPayOrderResponses: PaymentHeader[] = [];

        for (const item of prefill.items) {
          const request: BookPayOrderRequest = {
            valueDate: result.valueDate,
            checkNumber: result.checkNumber || undefined,
            id: item.id,
          };
          const booking = await api.run<PaymentHeader>(endpoints.bookPayOrder, request, null);
          bookPayOrderResponses.push(booking);
        }

        if (bookPayOrderResponses) return resolve(bookPayOrderResponses);
        return reject('Unknown result. Please check if the pay order(s) were booked and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Pay Order(s) successfully booked: \n${response.map((payment) => (payment ? `- ${payment.documentReference}` : null)).join(`\n`)}</div>`
            );
          }
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createBookingPayOrderPreset: DynamicFormConstant<BookPayOrderPrefill, BookPayOrderRequest> = {
  allowMultipleRows: true,
  checkPrefill: createBookingPayOrderCheck,
  createForm: createBookingPayOrderForm,
  entityType: SourceEntityType.PAY_ORDER_KEY,
  getPrefill: createBookingPayOrderPrefill,
  label: 'Book Pay Order',
  submitForm: createBookingPayOrderCallback,
  title: 'Book Pay Order',
  value: DynamicFormType.CREATE_BOOKING_PAY_ORDER,
  endpoints: [endpoints.listPayOrders, endpoints.bookPayOrder],
  width: 400,
};

export type BookPayOrderPrefill = {
  items: PayOrderHeader[];
};
export type BookPayOrderForm = { valueDate?: Date; checkNumber?: number | null };
export type BookPayOrderRequest = { id: number; valueDate?: Date; checkNumber?: number | null };
