import { DialogResult } from '@progress/kendo-angular-dialog';
import { from, of } from 'rxjs';
import { UnapplyFixationFromInvoiceComponent } from 'src/app/+modules/+hedging/unapply-fixation-from-invoice/unapply-fixation-from-invoice.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType, VoucherList } from 'src/lib/newBackendTypes';
import { ApplyUnapplyFixationToInvoicePrefill, ApplyUnapplyPriceFixationRequest, UnapplyResponse } from 'src/lib/newBackendTypes/priceFixation';
import { ShipmentsAndFixationsFromInvoice } from 'src/lib/newBackendTypes/shipmentsAndFixationsFromInvoice';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const unapplyFixationFromInvoicePrefill: prefillCallback<ApplyUnapplyFixationToInvoicePrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const invoiceResponse = await api.run<ListResponse<VoucherList>>(endpoints.listVouchers, {
        filters: { invoiceId: id },
      });
      const invoiceInformation = invoiceResponse.list;

      const shipmentsResponse = await api.run<ListResponse<ShipmentsAndFixationsFromInvoice>>(endpoints.getShipmentsAndFixationsFromInvoice, { filters: { invoiceId: id } });
      const shipments = shipmentsResponse.list;

      return { invoiceInformation, shipments };
    })()
  );
};

const unapplyFixationFromInvoiceCheck: checkPrefillCallback<ApplyUnapplyFixationToInvoicePrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  let countFixed = 0;
  let shipments = prefill.shipments;

  for (const shipment of shipments) {
    if (shipment.appliedFixationId !== null) countFixed += 1;
  }

  if (countFixed === 0) return of('Unable to unapply fixation(s) from invoice: there are no shipments available to unapply.');
  return of(true);
};

const unapplyFixationFromInvoiceForm: openFormCallback<ApplyUnapplyFixationToInvoicePrefill, UnapplyFixationFromInvoiceForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<UnapplyFixationFromInvoiceForm, UnapplyFixationFromInvoiceComponent, ApplyUnapplyFixationToInvoicePrefill>(UnapplyFixationFromInvoiceComponent, {
    title: `Unapply Fixation From Invoice`,
    prefillValue: prefill,
  });
};

const submitUnapplyFixationFromInvoice: submitFormCallback<ApplyUnapplyFixationToInvoicePrefill, UnapplyFixationFromInvoiceForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        try {
          let request: ApplyUnapplyPriceFixationRequest[] = [];
          for (const data of result.shipmentsToUnfix) {
            const shipment = {
              id: data.id,
              shipmentIdArr: data.shipmentIdArr,
            };
            request.push(shipment);
          }
          const unapplyFixationFromInvoiceResponse = await api.run<ListResponse<UnapplyResponse>>(endpoints.unapplyPriceFixations, request, null);
          if (unapplyFixationFromInvoiceResponse.list) {
            const shipments = unapplyFixationFromInvoiceResponse.list.map((list) => {
              if (!list.shipmentIdArr) return null;
              return list.shipmentIdArr.map((s) => `- ${s}`).join('\n');
            });
            return resolve(
              prompt.htmlDialog(
                'Success',
                `<div style="white-space: pre">The fixation(s) were unapplied from the following shipment(s): \n${
                  shipments && shipments.length > 0 ? shipments.map((s) => `${s}`).join('\n') : 'Unknown'
                }</div>`
              )
            );
          }
        } catch (error) {
          return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the fixation(s) were unapplied and try again if necessary.</div>`));
        }
      })();
    })
  );
};

export const unapplyFixationFromInvoicePreset: DynamicFormConstant<ApplyUnapplyFixationToInvoicePrefill, UnapplyFixationFromInvoiceForm> = {
  allowMultipleRows: false,
  checkPrefill: unapplyFixationFromInvoiceCheck,
  openForm: unapplyFixationFromInvoiceForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: unapplyFixationFromInvoicePrefill,
  label: 'Unapply Fixation From Invoice',
  submitForm: submitUnapplyFixationFromInvoice,
  title: 'Unapply Fixation From Invoice',
  value: DynamicFormType.UNAPPLY_FIXATION_FROM_INVOICE,
  endpoints: [endpoints.listVouchers, endpoints.getShipmentsAndFixationsFromInvoice, endpoints.unapplyPriceFixations],
  width: 400,
};

export type UnapplyFixationFromInvoiceForm = {
  shipments?: ShipmentsAndFixationsFromInvoice[];
  shipmentsToUnfix: ApplyUnapplyPriceFixationRequest[];
};
