import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { NumerictextboxWrapperComponent } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { bankDropdown, budgetElementDropdown } from 'src/lib/commonTypes';
import { AdvancePaymentEntryForm, LinkUnlinkAdvancePaymentForm, LinkUnlinkAdvancePaymentPrefill } from 'src/lib/flex/forms/linkUnlinkAdvancePayment';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Contact, EntryType } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'advance-payment-manager',
  templateUrl: './advance-payment-manager.component.html',
  styleUrls: ['./advance-payment-manager.component.scss'],
})
export class AdvancePaymentManagerComponent implements ModalFormComponent<LinkUnlinkAdvancePaymentForm, LinkUnlinkAdvancePaymentPrefill> {
  @ViewChild('amountField', { static: false })
  amountField: NumerictextboxWrapperComponent;
  popup: boolean = true;

  form: TypedFormGroup<LinkUnlinkAdvancePaymentForm>;

  currencyId: number | null;
  counterparty: Contact;
  requestedAmount: number | null;

  bankDropdown = bankDropdown();
  budgetElementDropdown = budgetElementDropdown();

  amountFormat: NumberFormatOptions;

  constructor(private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<LinkUnlinkAdvancePaymentForm>({
      requestedAmount: new UntypedFormControl(null),
      openPaymentsLines: new UntypedFormControl([], []),
      advancePaymentEntries: new UntypedFormControl([], []),
    });
  }

  ngAfterViewInit(): void {
    if (this.form && this.form.touched) {
      this.amountField.focus();
    }
  }

  prefillForm(data: LinkUnlinkAdvancePaymentPrefill) {
    this.currencyId = data.advanceItem.currencyId;
    this.requestedAmount = data.advanceItem.totalAmount;
    this.counterparty = data.advanceItem.counterparty;
    this.amountFormat = this.formatter.getAmountFormat(this.currencyId);

    const advancePaymentEntries: AdvancePaymentEntryForm[] = [];
    advancePaymentEntries.push(
      ...data.advancePaymentEntries.map((pe) => {
        const line: AdvancePaymentEntryForm = {
          entryType: EntryType.PMT,
          amount: pe.amount,
          externalRef: pe.externalRef,
          entryText: pe.entryText,
          phValueDate: pe.phValueDate,
          paymentEntryId: pe.paymentEntryId,
          isLinkedAdvancePaymentEntry: true,
        };
        return line;
      }),
      ...data.openPaymentEntries.map((openPayment) => {
        const line: AdvancePaymentEntryForm = {
          entryType: EntryType.PMT,
          amount: openPayment.amount,
          externalRef: openPayment.externalRef,
          entryText: openPayment.entryText,
          phValueDate: openPayment.phValueDate,
          paymentEntryId: openPayment.paymentEntryId,
          isLinkedAdvancePaymentEntry: false,
        };
        return line;
      })
    );

    this.form.patchValue({
      requestedAmount: data.advanceItem.totalAmount,
      advancePaymentEntries,
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): LinkUnlinkAdvancePaymentForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    return this.form.value;
  }

  getRequestedAmount() {
    return this.formatter.roundAmount(this.requestedAmount, this.currencyId);
  }
}
