import { Component, Input, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';
/**
 * @class Simple combobox wrapper that filters data locally
 */
@Component({
  selector: 'simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => SimpleDropdownComponent) }],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  dropdown: ComboBoxComponent;

  @Input()
  set data(val: any[]) {
    if (!val) {
      this._data = [];
    } else {
      this._data = val.map((e) => e);
    }
  }

  get data() {
    return this._data;
  }
  _data?: any[];

  @Input()
  textField?: string;

  @Input()
  valueField?: string;

  @Input()
  valuePrimitive: boolean = false;

  @Input()
  secondaryTextField?: string;

  valueWasMissing: boolean;

  filteredData: string[];

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this.filteredData = [];
    this.data = [];
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (!!this.data) {
      this.checkIfValueIsInOptions();
    }
    if (!!this.textField) {
      this.dropdown.textField = this.textField;
    }
    if (!!this.valueField) {
      this.dropdown.valueField = this.valueField;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['data']) {
      this.checkIfValueIsInOptions();
    }
  }

  handleFilter(text) {
    let filtered = this.data
      ? this.data.filter((value) => {
          if (typeof value !== 'string' && typeof value !== 'number' && typeof value[this.textField] !== 'string') return false;
          return typeof value === 'string' || typeof value === 'number'
            ? `${value}`.toLocaleLowerCase().includes(text.toLocaleLowerCase())
            : value[this.textField].toLocaleLowerCase().includes(text.toLocaleLowerCase());
        })
      : [];
    setTimeout(() => {
      this.filteredData = filtered;
    });
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  checkIfValueIsInOptions() {
    if (!this.data) return;
    if (!this.value) {
      this.handleFilter('');
      return;
    }
    if (!this.data.some(this._valueInOptions)) {
      if (this.valueField && this.valuePrimitive) {
        this.data.push({
          [this.valueField]: this.value,
          [this.textField]: `${this.value} (invalid)`,
          isInvalidSimpleDropdownOption: true,
        });
      } else {
        this.data.push(this.value);
      }
      this.valueWasMissing = true;
    } else {
      if (this.valueWasMissing) {
        setTimeout(() => {
          let temp = this._value;
          this._value = null;
          setTimeout(() => {
            this._value = temp;
            this.handleFilter(this.dropdown.text);
          });
        });
      }
      this.valueWasMissing = false;
    }
    this.handleFilter(this.dropdown?.text || '');
  }

  _valueInOptions = (element) => {
    if (!this.valueField) return element === this.value;
    if (this.valuePrimitive) return element[this.valueField] === this.value && !element?.isInvalidSimpleDropdownOption;
    return element[this.valueField] === this.value[this.valueField];
  };

  getLabelFromPrimitive() {
    if (!this.valuePrimitive) return '';
    if (!this._value) return '';
    if (!this.data) return '';
    let data = this.data.find((d) => d[this.valueField] === this._value);
    if (data) return data[this.textField];
    return this._value;
  }

  comparator(a, b) {
    if (this.valuePrimitive || !this.valueField) return a === b;
    return a?.[this.valueField] === b?.[this.valueField];
  }
}
