import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SocketIOService } from 'src/app/core/services/socket.io.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListNotifications } from 'src/lib/newBackendTypes/notificationTypes/listNotifications';
import { NotificationStatus, UpdateNotificationStatusRequest } from 'src/lib/newBackendTypes/notificationTypes/notificationsStatus';

@Component({
  selector: 'roco-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnChanges {
  @Input()
  notifications: ListNotifications[];

  @Input()
  allNotificationsCounter: number;

  isAllNotificationsCountGreater: boolean = false;

  constructor(protected api: ThalosApiService, private socketIOService: SocketIOService, private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['notifications'].currentValue !== changes['notifications'].previousValue) this.isAllNotificationsCountGreater = this.allNotificationsCounter > this.notifications.length;
  }

  markAllAsRead() {
    const notificationStatusIdArr: number[] = [];
    const user = this.store.snapshot((state) => state.user.user);

    for (const notification of this.notifications) {
      if (!(notification && notification.status === NotificationStatus.SENT)) continue;
      const id = notification.statusId;
      notificationStatusIdArr.push(id);

      markNotificationsVisuallyAsRead(this.socketIOService, notification.transactionId, true);
    }

    if (user && notificationStatusIdArr.length > 0) {
      const request: UpdateNotificationStatusRequest = {
        status: NotificationStatus.READ,
        userId: user.id,
        notificationStatusIdArr,
      };

      this.api.run<NotificationStatus[]>(endpoints.updateNotificationStatus, request, null);
    }
  }
}

export function markNotificationsVisuallyAsRead(socketIOService: SocketIOService, transactionId: string, markAllAsRead: boolean, initial?: boolean) {
  if (transactionId) {
    const notificationWrapper: HTMLElement = document.querySelector(`[class*='${transactionId}']`);
    if (notificationWrapper) {
      const markAsReadBtn: HTMLElement = notificationWrapper.querySelector("[class*='notification-read-button']");
      if (markAsReadBtn) {
        notificationWrapper.classList.remove('new');
        markAsReadBtn.style.display = 'none';

        let notificationsNotReadCount: number | null = socketIOService.notificationsNotReadCount;

        if (markAllAsRead) notificationsNotReadCount = null;
        if (!markAllAsRead && !initial) notificationsNotReadCount -= 1;

        socketIOService.notificationsNotReadCount = notificationsNotReadCount;
        socketIOService.notificationsInfo.emit({ notifications: socketIOService.notifications, notificationsNotReadCount });
      }
    }
  }
}
