import { Comment } from './comment';
import { YN } from './enums';

export type CommentCategory = {
  noteCatKey: number;
  categoryName: string | null;
  categoryDesc: string | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  uniqueComment: YN;
  notes?: Comment[];
};

export enum CommentCategories {
  TELEX_RELEASE = 470687,
  MANUFACTURER_INFO_FOR_ALL_DOCUMENTS = 1277380,
  INVOICE = 7232713,
  INFORMATION_ABOUT_COMMISSIONS = 7234901,
  COLLECTION = 16704123,
  SHIPPING_DOC_REMINDER = 20683004,
  INVOICE_REVERSAL = 23623894,
  DIT = 23853077,
  MTM_DIFF_UPDATE = 25087603,
  HEDGING = 25087604,
  COLLATERAL_AR = 25087605,
}
