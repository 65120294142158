<div [formGroup]="form" style="margin: 0" *ngIf="prefill">
  <div class="row">
    <vertical-field [name]="'Counterparty'" class="col-6">
      <div style="margin-left: 10px">{{ prefill.counterpartyShortName }}</div>
    </vertical-field>
    <vertical-field [name]="'Trader'" class="col-6">
      <div style="margin-left: 10px">{{ prefill.traderShortName }}</div>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field [name]="'Contract Number'" class="col-6">
      <div style="margin-left: 10px">
        {{ prefill.contractType == ContractType.PURCHASE ? 'MP' : 'MS' }}
        {{ prefill.contractNumber }} / {{ prefill.elementNumber }}
      </div>
    </vertical-field>
    <vertical-field [name]="'Counterpary Reference'" class="col-6">
      <div style="margin-left: 10px">{{ prefill.counterpartyReference }}</div>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field [name]="'Contract Quantity'" class="col-6">
      <div style="margin-left: 10px">
        {{ prefill.contractQuantity | number : '1.2-4' }}
        {{ quantityUnit ? quantityUnit.code : '??' }}
        &plusmn; {{ prefill.tolerance }}%
      </div>
    </vertical-field>
    <vertical-field [name]="'Shipped Quantity'" class="col-6">
      <div style="margin-left: 10px">
        {{ prefill.shippedQuantity | number : '1.2-4' }}
        {{ quantityUnit ? quantityUnit.code : '??' }}
      </div>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field [name]="'Premium Formula'" class="col-12">
      <div style="margin-left: 10px">
        {{ premiumFormula }}
      </div>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field name="Fixation Date" class="col-6">
      <datepicker-wrapper [formControlName]="'fixationDate'" [showCalculator]="false"></datepicker-wrapper>
    </vertical-field>
    <vertical-field name="Fixation Quantity" class="col-6">
      <numerictextbox-wrapper [formControlName]="'quantity'" [fieldType]="3" [readonly]="true" [min]="0" [format]="quantityFormat" [decimals]="4"> </numerictextbox-wrapper>
      <div class="unit-label">{{ quantityUnit ? quantityUnit.code : '??' }}</div>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field name="Market Price" class="col-6">
      <numerictextbox-wrapper [formControlName]="'marketPrice'" [fieldType]="2" [min]="0" [format]="priceFormat"> </numerictextbox-wrapper>
      <div class="unit-label" style="white-space: nowrap">{{ priceCurrency ? priceCurrency.code : '??' }} / {{ priceUnit ? priceUnit.code : '??' }}</div>
    </vertical-field>
    <vertical-field name="Fixed Premium" class="col-6">
      <numerictextbox-wrapper [formControlName]="'premium'" [fieldType]="2" [min]="0" [format]="priceFormat" [readonly]="true"> </numerictextbox-wrapper>
      <div class="unit-label" style="white-space: nowrap">{{ priceCurrency ? priceCurrency.code : '??' }} / {{ priceUnit ? priceUnit.code : '??' }}</div>
    </vertical-field>
  </div>
</div>
