import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ReverseVoucherRequest } from 'src/lib/flex/forms/reverseVoucher';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { InvoiceHeader } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';
import { VoucherEntryLine } from '../voucher-lines.component';
import { InvoiceForm } from '../voucher.component';

@Component({
  selector: 'accounting-voucher-reverse',
  templateUrl: './voucher-reverse.component.html',
})
export class VoucherReverseComponent implements OnInit, ModalFormComponent<ReverseVoucherRequest> {
  form: TypedFormGroup<ReverseVoucherRequest>;
  popup = true;
  popupObservable: Observable<ReverseVoucherRequest>;
  popupObserver: Observer<ReverseVoucherRequest>;

  @Input()
  voucher: Partial<InvoiceHeader> | (InvoiceForm & VoucherEntryLine);

  @Input()
  permDateOperations: string;

  @Input()
  permDateAccounting: string;

  constructor() {
    this.form = new TypedFormGroup<ReverseVoucherRequest>({
      id: new UntypedFormControl(),
      forceReversal: new UntypedFormControl(false),
    });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  ngOnInit(): void {}

  prefillForm(row: ReverseVoucherForm) {
    const request = {
      id: row.id ? row.id : row.invoiceId,
      forceReversal: this.form.value.forceReversal,
    };
    this.form.patchValue(request);
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): ReverseVoucherRequest {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    let formVal = this.form.value;
    return {
      id: formVal.id,
      forceReversal: formVal.forceReversal,
    };
  }
}

export type ReverseVoucherForm = Partial<ReverseVoucherRequest> & { invoiceId?: number };
