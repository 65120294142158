import { DialogResult } from '@progress/kendo-angular-dialog';
import { from, of } from 'rxjs';
import { DeletePriceFixationComponent } from 'src/app/+modules/+hedging/delete-price-fixation/delete-price-fixation.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { DeletePriceFixationRequest, PcPriceFixing } from 'src/lib/newBackendTypes/priceFixation';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const deletePriceFixationPrefill: prefillCallback<DeletePriceFixationPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const priceFixation = await api.run<PcPriceFixing & { isFixationApplied: boolean }>(endpoints.getPriceFixation, { filters: { id: id } });
      return priceFixation;
    })()
  );
};

const deletePriceFixationCheck: checkPrefillCallback<DeletePriceFixationPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.isFixationApplied) return of('Unable to delete fixation: it is already applied to a shipment.');
  return of(true);
};

const deletePriceFixationForm: openFormCallback<DeletePriceFixationPrefill, DeletePriceFixationForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<DeletePriceFixationForm, DeletePriceFixationComponent>(DeletePriceFixationComponent, {
    title: `Delete Price Fixation For Contract : ${prefill ? `${prefill.contractLine.contract.number}/${prefill.elementNumber}` : 'Unknown'}`,
    prefillValue: prefill,
  });
};

const submitDeletePriceFixation: submitFormCallback<DeletePriceFixationPrefill, DeletePriceFixationForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        try {
          const request: DeletePriceFixationRequest = {
            id: prefill.id,
          };
          const deleteFixationResponse = await api.run<PcPriceFixing & { contractNumber: number }>(endpoints.deletePriceFixation, { filters: request }, null);
          return resolve(
            prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">The fixation for Contract ${deleteFixationResponse.contractNumber + '/' + deleteFixationResponse.elementNumber} has been deleted.</div>`
            )
          );
        } catch (err) {
          return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the contract line fixation was deleted and try again if necessary.</div>`));
        }
      })();
    })
  );
};

export const deletePriceFixationPreset: DynamicFormConstant<DeletePriceFixationPrefill, DeletePriceFixationForm> = {
  allowMultipleRows: false,
  checkPrefill: deletePriceFixationCheck,
  openForm: deletePriceFixationForm,
  entityType: SourceEntityType.PRICE_FIXING_KEY,
  getPrefill: deletePriceFixationPrefill,
  label: 'Delete Price Fixation',
  submitForm: submitDeletePriceFixation,
  title: 'Delete Price Fixation',
  value: DynamicFormType.DELETE_PRICE_FIXATION,
  endpoints: [endpoints.getPriceFixation, endpoints.deletePriceFixation],
  width: 400,
};

export type DeletePriceFixationPrefill = Pick<PcPriceFixing, 'id' | 'elementNumber' | 'contractLine'> & {
  isFixationApplied: boolean;
};
export type DeletePriceFixationForm = DeletePriceFixationRequest;
