<closable-form
  class="widescreen"
  [formGroup]="form"
  [entityId]="getTabTitle()"
  [sourceEntityType]="sourceEntityType"
  [entityKey]="entity?.id"
  [nextEntity]="nextEntity"
  [previousEntity]="previousEntity"
  [icon]="PaymentIcon"
>
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field [name]="'Company'" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12">
        <simple-dropdown
          [formControlName]="'companyId'"
          [data]="companies"
          [valuePrimitive]="true"
          [valueField]="'id'"
          [textField]="'displayName'"
          [readonly]="isReadOnly"
          [(ngModel)]="companyIdField"
        ></simple-dropdown>
      </vertical-field>
      <vertical-field name="Number" class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12" *ngIf="!!entity">
        <input-wrapper [formControlName]="'documentReference'" [readonly]="true"></input-wrapper>
      </vertical-field>
      <vertical-field name="Entry Date" class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'entryDate'" [readonly]="disableEntryDate"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Value Date" class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'valueDate'" [readonly]="isReadOnly"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Entry Source" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'entrySource'" [data]="entrySources" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Entry Operation" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'entryOperation'" [data]="entryOperations" [readonly]="isReadOnly" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"> </simple-dropdown>
      </vertical-field>
      <vertical-field name="Reconciled" class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12" *ngIf="entity">
        <simple-dropdown [data]="ynData" valueField="value" textField="label" [valuePrimitive]="true" [formControlName]="'reconciled'" [readonly]="true"> </simple-dropdown>
      </vertical-field>
      <vertical-field [name]="'Journal Number'" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" *ngIf="!!entity">
        <div class="linksContainer">
          <a entityLink [entityId]="journalKey" [entityType]="journalEntityType" class="keyLink">{{ journalIndex }}</a>
          <a entityLink [entityId]="journalKey" [entityType]="journalEntityType" [newTab]="true">
            <i class="k-icon k-i-hyperlink-open" style="color: rgba(0, 0, 0, 0.85); height: 1.2em"></i>
          </a>
        </div>
      </vertical-field>
    </div>
    <div class="form-divider-line"></div>
    <div class="row">
      <vertical-field name="Entry Mode" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'entryMode'" [data]="entryModes" [readonly]="true" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Status" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'paymentStatus'" [data]="paymentStatuses" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="isReadOnly"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Account" class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'glAccount'" [dropdownConfig]="glAccountDropdown" [readonly]="isReadOnly || !companyIdField"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Title" class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
        <input-wrapper [formControlName]="'paymentTitle'"></input-wrapper>
      </vertical-field>
      <vertical-field name="External Reference" class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
        <input-wrapper [formControlName]="'externalRef'"></input-wrapper>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Lines'" [addLineButton]="true" (emitAddLine)="paymentsLines.addNewLine($event)">
    <div class="row justify-content-end mb-3">
      <checkbox-wrapper [(ngModel)]="enableDifferentCurrencies" [ngModelOptions]="{ standalone: true }" [label]="'Enable different currencies?'" [readonly]="isMultipleCurrenciesReadonly">
      </checkbox-wrapper>
    </div>
    <payments-lines-component
      [formControlName]="'paymentEntries'"
      [companyId]="companyId"
      [exchangeRates]="exchangeRates"
      [currencyId]="currencyId"
      [headerBaseAmount]="baseAmountField._value"
      [readonly]="isReadOnly"
      [enableDifferentCurrencies]="enableDifferentCurrencies"
      (totalAmounts)="getTotalAmounts($event)"
      (linesCurrId)="getLinesCurrId($event)"
      #paymentsLines
    >
    </payments-lines-component>
    <div class="row">
      <vertical-field name="Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12" *ngIf="amountAndXrReadonly">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="amount" [fieldType]="1" [format]="currencyFormat" [readonly]="amountAndXrReadonly" #amountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
      <vertical-field name="XR" class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12" *ngIf="amountAndXrReadonly">
        <numerictextbox-wrapper
          class="vertical-field-1 w-50;"
          style="flex-grow: 1"
          formControlName="xr"
          [format]="{
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
            useGrouping: false
          }"
          [readonly]="amountAndXrReadonly"
          #xrField
        >
        </numerictextbox-wrapper>
      </vertical-field>
      <vertical-field *ngIf="!amountAndXrReadonly" class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
        <label class="col-form-label col-form-label-sm d-flex text-wrap"> This payment has one or more currencies different from the account currency. </label>
      </vertical-field>
      <vertical-field name="Base Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="baseAmount" [fieldType]="1" [format]="currencyFormat" [readonly]="true" #baseAmountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ baseCurrencyId | currency }}</div>
      </vertical-field>
    </div>
  </collapsible-card>
  <lazy-card [title]="'Documents'" [icon]="DocumentsIcon" *ngIf="documentsAuthorized" #deferDocuments>
    <microsoft-entity-documents
      *ngIf="deferDocuments.loaded"
      #documentsComponent
      formControlName="documents"
      [entityId]="entity?.id"
      [entityType]="sourceEntityType"
      [entity]="entity"
      [showSpinner]="true"
      #lazy
      [entityPath]="entity?.id"
    >
    </microsoft-entity-documents>
  </lazy-card>
  <lazy-card [title]="'Comments'" [icon]="CommentsIcon" [isOpen]="false" *ngIf="commentsAuthorized" #deferComments>
    <entity-comments *ngIf="deferComments.loaded" [entityId]="entity?.id" [entityType]="sourceEntityType" formControlName="comments" [showSpinner]="true" #lazy> </entity-comments>
  </lazy-card>
  <div class="container-button" [ngClass]="{ 'always-show': showSaveButtonsInPopup }">
    <button class="container-button__button btn btn-sm btn-danger" type="button" *ngIf="!!entity && deleteAuthorized && !isBooked" (click)="clickDelete()">Delete</button>
    <button class="container-button__button btn btn-sm btn-success" type="button" *ngIf="saveAuthorized" [disabled]="!!requestsPending.length || form.status === pendingStatus" (click)="clickSave()">
      Save
    </button>
    <button *ngIf="entity && !popup" class="container-button__button btn btn-sm btn-warning" type="button" cyData="submit" (click)="clickReconcilePayment()" [disabled]="requestsPending?.length">
      {{ entity.reconciled === 'N' ? 'Reconcile' : 'Unreconcile' }}
    </button>
  </div>
</closable-form>
<entity-footer [entity]="entity"></entity-footer>
