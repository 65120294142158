import { Directive, Input, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { Store } from 'src/app/core/services/store.service';
import { InnerFormGroupFormElement } from './InnerFormGroupFormElement';
import { SourceEntityType } from './newBackendTypes';

@UntilDestroy()
@Directive()
export abstract class SubEntityContainer<T /**@deprecated */> extends InnerFormGroupFormElement {
  @Input()
  entityId: number;

  @Input()
  entityType: SourceEntityType;

  @Input()
  autoSave: boolean = true;

  @Input()
  field?: string;

  @Input()
  popup: boolean = false;

  @Input()
  loadEvent: 'ngOnChanges' | 'route' | 'hybrid' = 'route';

  routeLoaded = false;

  @Input()
  showSpinner: boolean = false;

  willDeload: boolean;

  constructor(private route: ActivatedRoute, controlDir: NgControl, store: Store) {
    super(controlDir, store);

    this.willDeload = false;
  }

  ngOnInit() {
    if (this.popup) {
      this.loadSubForm();
    }

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      if (this.popup || this.loadEvent === 'ngOnChanges' || !data['entity'] || this.willDeload) return;
      let idFields: string[] = data['idFields'] || ['id'];
      if (!idFields || idFields.length === 0) {
        console.warn('Sub Entity is being used in a route that does not have idFields set');
        return;
      }
      if (idFields.length > 1) {
        console.warn('Sub Entity is not compatible with parent entities that have composite primary keys');
        return;
      }
      let entityId = data.entity[idFields[0]];
      if (entityId) {
        if (!isNaN(entityId)) {
          const newId = parseInt(entityId);
          if ((this.entityId && this.entityId === newId && this.loadEvent === 'hybrid' && this.routeLoaded) || this.loadEvent === 'route') {
            this.entityId = newId;
            this.loadSubForm();
          }
          this.routeLoaded = true;
        }
      } else {
        console.warn('Sub Entity is being used in a route that does not have the entityId defined properly');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadEvent === 'route') return;
    if (this.willDeload) return;

    if (changes['entityId']) {
      this.loadSubForm();
    }
  }

  abstract loadSubForm();

  abstract save(entityId: number, operation: 'create' | 'update', entityPath?: string): Observable<any>;

  pending() {
    return false;
  }

  getFormStatusChange(): Observable<string | null> {
    return of(null);
  }
}
