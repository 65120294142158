import { enumValues } from '../generics';
import { YN } from './enums';

export type ThalosConfiguration = {
  key: ConfigKey;
  value: string;
  description: string;
  exposed: YN;
};

export enum ConfigKey {
  FRONTEND_URL = 'GLOBAL.FRONTEND_URL',
  CORS_URLS = 'GLOBAL.CORS_URLS',
  MT_PER_CONTAINER = 'CONTRACTS.MT_PER_CONTAINER',
  CONTACT_TYPES = 'CONTACTS.CONTACT_TYPES',
  LC_PAYMENT_TERM_GROUP = 'CONTRACTS.LC_PAYMENT_TERM_GROUP',
  MT_UNIT_CODE = 'UNITS.MT_UNIT_CODE',
  SITE_ID = 'SHAREPOINT.SITE_ID',
  DRIVE_ID = 'SHAREPOINT.DRIVE_ID',
  ROOT_FOLDER_URL = 'SHAREPOINT.ROOT_FOLDER_URL',
  AZURE_TENANT_ID = 'AZURE.TENANT_ID',
  AZURE_CLIENT_ID = 'AZURE.CLIENT_ID',
  AZURE_CLIENT_SECRET = 'AZURE.CLIENT_SECRET',
  SMTP_SERVER = 'SMTP.SERVER',
  SMTP_PORT = 'SMTP.PORT',
  SMTP_USERNAME = 'SMTP.USERNAME',
  SMTP_PASSWORD = 'SMTP.PASSWORD',
  SMTP_FROM = 'SMTP.FROM',
  CONTRACT_PRINTOUT_PDF = 'CONTRACT_PRINTOUT_PDF',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
  CLIENT_INVOICE_PRINTOUT_PDF = 'CLIENT_INVOICE_PRINTOUT_PDF',
  PAY_ORDER_PRINTOUT_PDF = 'PAY_ORDER_PRINTOUT_PDF',
  WAVG_SALES_NUMBER_OF_MONTHS_AGO = 'WAVG_SALES_NUMBER_OF_MONTHS_AGO',
  FIXATION_EMAIL_SCHEDULE = 'FIXATION_EMAIL_SCHEDULE',
  JOB_QUEUE_ALERT_EMAIL_SCHEDULE = 'JOB_QUEUE_ALERT_EMAIL_SCHEDULE',
  JOB_QUEUE_ALERT_EMAIL_TIME = 'JOB_QUEUE_ALERT_EMAIL_TIME',
  JOBS_METRICS_SCHEDULED_REPORT = 'JOBS_METRICS_SCHEDULED_REPORT',
  SET_MUP_VALUES_SCHEDULED = 'SET_MUP_VALUES_SCHEDULED',
  ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_DEV = 'ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_DEV',
  ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_UAT = 'ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_UAT',
  ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_PROD = 'ENDPOINT_STATS_RETENTION_POLICY_SCHEDULE_PROD',
}

export const ConfigKeys = enumValues(ConfigKey).map((k) => {
  return {
    value: k,
    label: k,
  };
});
