<kendo-calendar #calendar class="big-calendar" [(ngModel)]="value" [bottomView]="'month'" [topView]="'month'">
  <ng-template kendoCalendarMonthCellTemplate let-date>
    <!-- {{ date.getDate()}} -->
    <span class="date-cell">
      <div class="highlight-label">{{ getHighlightName(date) }}</div>
      <div>{{ date.getDate() }}</div>
      <div class="price-label">{{ getPrice(date) }}</div>
    </span>
  </ng-template>
</kendo-calendar>
