import { from } from 'rxjs';
import { VoucherPaymentsCreditsComponent } from 'src/app/+modules/+accounting/voucher/voucher-payments-credits/voucher-payments-credits.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { ListResponse } from 'src/lib/ListResponse';
import { InvoiceCompoundType, InvoiceHeader, OpenItem, PaymentTerm, SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { ApplyItemsToVoucherRequest } from './createClientInvoice';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const applyItemsToVoucherPrefill: prefillCallback<ApplyItemsToVoucherPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const invoiceItemResponse = (await api.run(endpoints.listVouchersBalance, {
        filters: { id: id },
      })) as ListResponse<InvoiceHeader>;
      const item = invoiceItemResponse.list[0];

      const counterpartyId = item.counterpartyId;
      const counterpartyOpenItems = (await api.run(endpoints.listOpenItems, {
        filters: { counterpartyId: counterpartyId },
      })) as ListResponse<OpenItem>;
      const openItems = counterpartyOpenItems.list;

      return { item, openItems };
    })()
  );
};

const openApplyItemsToVoucherForm: openFormCallback<ApplyItemsToVoucherPrefill, VoucherPaymentsCreditForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<VoucherPaymentsCreditForm, VoucherPaymentsCreditsComponent, ApplyItemsToVoucherPrefill>(VoucherPaymentsCreditsComponent, {
    title: `Apply Payments/Credits: ${prefill.item.counterparty.displayName}`,
    width: '98%',
    prefillValue: prefill,
  });
};

const applyItemsToVoucherCallback: submitFormCallback<ApplyItemsToVoucherPrefill, VoucherPaymentsCreditForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader>((resolve, reject) => {
      (async () => {
        const paymentsCreditsLines = form.itemsLines;
        let applyItemsRequest: ApplyItemsToVoucherRequest;
        if (paymentsCreditsLines.length > 0) {
          applyItemsRequest = {
            voucherId: prefill.item.id,
            valueDate: form.valueDate,
            entryDate: getTodayUTC(),
            entryIds: paymentsCreditsLines.map((item) => {
              return {
                type: item.entryType,
                id: item.entryId,
                amount: item.amountToApply,
              };
            }),
          };
        } else {
          return prompt.htmlDialog('Error', `<div style="white-space: pre">No entries selected to apply.</div>`);
        }
        const applyItems = await api.run<PaymentHeader>(endpoints.applyItemsToVoucher, applyItemsRequest, null);
        if (applyItems) return resolve(applyItems);
        return reject('Unknown result. Please check if the Payment/Credit(s) were applied and try again if necessary.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Payment/Credit(s) successfully applied to Voucher: \nNumber: ${res.documentReference}</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const applyItemsToVoucherPreset: DynamicFormConstant<ApplyItemsToVoucherPrefill, VoucherPaymentsCreditForm> = {
  allowMultipleRows: false,
  getPrefill: applyItemsToVoucherPrefill,
  openForm: openApplyItemsToVoucherForm,
  submitForm: applyItemsToVoucherCallback,
  endpoints: [endpoints.listVouchersBalance, endpoints.applyItemsToVoucher, endpoints.listOpenItems],
  entityType: SourceEntityType.INVOICE_KEY,
  label: 'Apply Payments/Credits',
  title: 'Apply Payments/Credits',
  value: DynamicFormType.APPLY_ITEMS_TO_VOUCHER,
};

export type ApplyItemsToVoucherPrefill = {
  item: InvoiceHeader;
  openItems: OpenItem[];
};

export type VoucherPaymentsCreditForm = Pick<InvoiceHeader, 'counterparty' | 'iden' | 'invoiceDate' | 'dueDate' | 'amount'> & {
  valueDate: Date;
  paymentTerm?: PaymentTerm;
  type: InvoiceCompoundType;
  itemsLines?: VoucherItemsForm[];
};

export type VoucherItemsForm = Subset<
  OpenItem,
  'entryReference' | 'valueDate' | 'entryTitle' | 'balanceAmount' | 'entryType' | 'ourReference',
  'entryId' | 'currencyId' | 'accountId' | 'balanceBaseAmount'
> & {
  lineNumber?: number;
  amountToApply?: number;
};
