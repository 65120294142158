import { YN } from './enums';
import { PaymentTerm } from './paymentTerm';
import { WaTermDate } from './waTermDate';

export class WaTermLine {
  id: number;
  paymentTermId: number;
  termDateId: number;
  daysAdjusted: number;
  termPercentage: number;
  termFixedAmount: number;
  isDefault: YN;
  isAdvance: string | null;
  paymentTerm?: PaymentTerm;
  termDate?: WaTermDate;
}
