import { map } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { PhysicalContract, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

export const contractPrefillCallback: prefillCallback<PhysicalContract> = (delegate, id) => {
  const api = delegate.getService('api');

  return api.rpc<PhysicalContract>(endpoints.getContract, { filters: { id } }, null);
};

const openContractArchiveForm: openFormCallback<PhysicalContract, YN> = (delegate, id, prefill) => {
  const text = prefill.archived === YN.N ? `Contract will be Archived. Continue?` : `Contract will be Unarchived. Continue?`;

  const prompt = delegate.getService('prompt');

  return prompt.simpleConfirmation(`Update Contract`, text).pipe(map((answer) => (answer ? prefill.archived : 'Close')));
};

export const archiveContractCallback: submitFormCallback<PhysicalContract, YN> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const request = {
    contractId: id,
  };
  const endpoint = form === YN.N ? endpoints.archiveContract : endpoints.unarchiveContract;

  return api.rpc<PhysicalContract>(endpoint, request, null);
};

export const archiveContractPreset: DynamicFormConstant<PhysicalContract, YN> = {
  value: DynamicFormType.ARCHIVE_CONTRACT,
  entityType: SourceEntityType.CONTRACT_KEY,
  label: 'Archive/Unarchive Contract',
  title: 'Archive/Unarchive Contract',
  width: 200,
  endpoints: [endpoints.getContract, endpoints.archiveContract, endpoints.unarchiveContract],
  getPrefill: contractPrefillCallback,
  openForm: openContractArchiveForm,
  submitForm: archiveContractCallback,
};
