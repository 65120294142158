import { Contact, YN } from 'src/lib/newBackendTypes';
import { Subset } from '../generics';

export type UserAuthorizedCompanies = {
  id: number;
  contactId: number;
  companyId: number;
  isDefault: YN;
  contact?: Contact;
  company?: Contact;
};

export type UpsertUserAuthorizedCompaniesRequest = Pick<UserAuthorizedCompanies, 'contactId'> & {
  lines: UpsertUserAuthorizedCompaniesLinesRequest[];
  username: string;
};

export type UpsertUserAuthorizedCompaniesLinesRequest = Subset<UserAuthorizedCompanies, 'companyId' | 'contactId' | 'isDefault', 'id'>;

export type UserAuthorizedCompaniesEntity = Pick<UserAuthorizedCompanies, 'contact' | 'contactId'> & { username: string; lines: UserAuthorizedCompaniesLine[] };

export type UserAuthorizedCompaniesForm = Omit<UserAuthorizedCompaniesEntity, 'contactId'>;

export type UserAuthorizedCompaniesLine = Pick<UserAuthorizedCompanies, 'companyId' | 'id' | 'isDefault'> & { linePosition: number };

export const upsertUserAuthorizedCompaniesRequest = async (formVal: UserAuthorizedCompaniesEntity) => {
  const lines: UserAuthorizedCompaniesLine[] = [];
  if (formVal.lines && formVal.lines.length > 0) {
    for (const line of formVal.lines) if (line.companyId && line.isDefault) lines.push(line);
  }
  const request: UpsertUserAuthorizedCompaniesRequest = {
    contactId: formVal.contact.id,
    username: formVal.username.trim().toUpperCase(),
    lines: lines.map((line) => ({
      id: line.id ?? null,
      companyId: line.companyId,
      isDefault: line.isDefault,
      contactId: formVal.contact.id,
    })),
  };
  return request;
};
