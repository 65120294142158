import { Pipe, PipeTransform } from '@angular/core';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { Currency } from 'src/lib/newBackendTypes';

@Pipe({
  name: 'currency',
  pure: true,
})
export class CurrencyPipe implements PipeTransform {
  constructor(private dataFormatter: DataFormattingService) {}

  transform(currencyOrCurrencyId: Currency | number | string): any {
    let currency = this.dataFormatter.currencyFromTag(currencyOrCurrencyId);
    if (!!currency) return currency.code;

    return currencyOrCurrencyId;
  }
}
