import { DialogResult } from '@progress/kendo-angular-dialog';
import { from, of } from 'rxjs';
import { ApplyFixationToInvoiceComponent } from 'src/app/+modules/+hedging/apply-fixation-to-invoice/apply-fixation-to-invoice.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { Contact, PhysicalContract, SourceEntityType, VoucherList } from 'src/lib/newBackendTypes';
import { ApplyResponse, ApplyUnapplyFixationToInvoicePrefill, ApplyUnapplyPriceFixationRequest, FixationSendEmailOption } from 'src/lib/newBackendTypes/priceFixation';
import { ShipmentsAndFixationsFromInvoice } from 'src/lib/newBackendTypes/shipmentsAndFixationsFromInvoice';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const applyFixationToInvoicePrefill: prefillCallback<ApplyUnapplyFixationToInvoicePrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const invoiceResponse = await api.run<ListResponse<VoucherList>>(endpoints.listVouchers, {
        filters: { invoiceId: id },
      });
      const invoiceInformation = invoiceResponse.list;

      const shipmentsResponse = await api.run<ListResponse<ShipmentsAndFixationsFromInvoice>>(endpoints.getShipmentsAndFixationsFromInvoice, { filters: { invoiceId: id } });
      const shipments = shipmentsResponse.list;

      let counterparty: Contact | undefined = undefined;
      if (invoiceResponse && invoiceResponse.list.length > 0) {
        const contract = await api.run<PhysicalContract>(endpoints.getContract, { filters: { number: invoiceResponse.list[0].contractNumber } });
        counterparty = contract.counterparty;
      }

      return { invoiceInformation, shipments, counterparty };
    })()
  );
};

const applyFixationToInvoiceCheck: checkPrefillCallback<ApplyUnapplyFixationToInvoicePrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  let countFixed = 0;
  let shipments = prefill.shipments;

  for (const shipment of shipments) {
    if (shipment.appliedFixationId !== null) countFixed += 1;
  }

  if (countFixed !== 0) return of('Unable to apply fixation(s) to invoice: one or many shipments have already been applied.');
  if (prefill.shipments.length === 0) return of('Unable to apply fixation(s) to invoice: there are no shipments available to apply.');
  return of(true);
};

const applyFixationToInvoiceForm: openFormCallback<ApplyUnapplyFixationToInvoicePrefill, ApplyFixationToInvoiceForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<ApplyFixationToInvoiceForm, ApplyFixationToInvoiceComponent, ApplyUnapplyFixationToInvoicePrefill>(ApplyFixationToInvoiceComponent, {
    title: `Apply Fixation To Invoice`,
    prefillValue: prefill,
    maxWidth: '90%',
    minWidth: '56.7%',
  });
};

const submitApplyFixationToInvoice: submitFormCallback<ApplyUnapplyFixationToInvoicePrefill, ApplyFixationToInvoiceForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        try {
          let request: ApplyUnapplyPriceFixationRequest[] = [];
          for (const data of result.shipmentsToFix) {
            const shipment: ApplyUnapplyPriceFixationRequest = {
              id: data.id,
              shipmentIdArr: data.shipmentIdArr,
              sendEmail: result.sendEmail,
            };
            request.push(shipment);
          }
          const applyFixationToInvoiceResponse = await api.run<ListResponse<ApplyResponse>>(endpoints.applyPriceFixations, request, null);
          if (applyFixationToInvoiceResponse.list) {
            const shipments = applyFixationToInvoiceResponse.list.map((list) => {
              if (!list.shipments) return null;
              return list.shipments.map((s) => `- ${s.id}`).join('\n');
            });
            return resolve(
              prompt.htmlDialog(
                'Success',
                `<div style="white-space: pre">The fixation(s) were applied to the following shipment(s): \n${
                  shipments && shipments.length > 0 ? shipments.map((s) => `${s}`).join('\n') : 'Unknown'
                }</div>`
              )
            );
          }
        } catch (error) {
          return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the fixation(s) were applied and try again if necessary.</div>`));
        }
      })();
    })
  );
};

export const applyFixationToInvoicePreset: DynamicFormConstant<ApplyUnapplyFixationToInvoicePrefill, ApplyFixationToInvoiceForm> = {
  allowMultipleRows: false,
  checkPrefill: applyFixationToInvoiceCheck,
  openForm: applyFixationToInvoiceForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: applyFixationToInvoicePrefill,
  label: 'Apply Fixation To Invoice',
  submitForm: submitApplyFixationToInvoice,
  title: 'Apply Fixation To Invoice',
  value: DynamicFormType.APPLY_FIXATION_TO_INVOICE,
  endpoints: [endpoints.listVouchers, endpoints.getShipmentsAndFixationsFromInvoice, endpoints.getContract, endpoints.applyPriceFixations],
  width: 400,
};

export type ApplyFixationToInvoiceForm = {
  shipments?: ShipmentsAndFixationsFromInvoice[];
  shipmentsToFix: ApplyUnapplyPriceFixationRequest[];
  sendEmail: FixationSendEmailOption;
};
