<form [formGroup]="form">
  <div class="row">
    <div>
      <label class="linksContainer">Payment Term: {{ paymentTermName }}</label>
      <label class="linksContainer">Contract Value: {{ price }} {{ currencyCode }}</label>
      <br />
    </div>
    <vertical-field name="Proforma Invoice Date">
      <datepicker-wrapper [formControlName]="'valueDate'"></datepicker-wrapper>
    </vertical-field>
    <vertical-field name="Due Date Type">
      <simple-dropdown
        type="text"
        [formControlName]="'dueDateType'"
        [data]="dueDateTypeLabels"
        [valueField]="'value'"
        [textField]="'label'"
        [valuePrimitive]="true"
        (ngModelChange)="dueDateValidators($event)"
      >
      </simple-dropdown>
    </vertical-field>
    <div *ngIf="isContractDate">
      <label class="linksContainer">Contract Date = {{ contractDateFormatted }}</label>
      <label class="linksContainer">Due Date = Contract Date + 5 days</label>
      <br />
    </div>
    <vertical-field name="Due Date" *ngIf="!isLoadingETD">
      <datepicker-wrapper [formControlName]="'dueDate'" [readonly]="isContractDate"></datepicker-wrapper>
    </vertical-field>
    <vertical-field name="Automatic Amount" class="d-flex justify-content-center">
      <checkbox-wrapper class="d-flex justify-content-center" [formControlName]="'automaticAmount'" [valueMask]="{ true: 'Y', false: 'N' }" (ngModelChange)="requestedAmountValidators($event)">
      </checkbox-wrapper>
    </vertical-field>
    <vertical-field name="Requested Amount" *ngIf="form.get('automaticAmount').value === 'Y'">
      <numerictextbox-wrapper [formControlName]="'amount'" [fieldType]="1" [format]="amountFormat" readonly="true"> </numerictextbox-wrapper>
      <div class="unit-label" style="flex-grow: 0">{{ currencyCode }}</div>
    </vertical-field>
    <vertical-field name="Requested Amount" *ngIf="form.get('automaticAmount').value === 'N'">
      <numerictextbox-wrapper [formControlName]="'overrideAmount'" [fieldType]="1" [format]="amountFormat"> </numerictextbox-wrapper>
      <div class="unit-label" style="flex-grow: 0">{{ currencyCode }}</div>
    </vertical-field>
  </div>
</form>
