import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isObservable, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { environment } from 'src/environments/environment';
import { StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { State } from '../reducers';
import { SET_ACTIVE_FORM } from '../reducers/actions';
import { FormState } from '../reducers/form';
import { Store } from './store.service';
import { channelId } from '../components/app/app.component';
import { isArray } from 'lodash';

export type rpcOptions = {
  optionalParams?: string[];
  hideErrorPopup?: boolean;
  blockRedirect?: boolean;
};

export type errorHandlerOptions<T> = rpcOptions & {
  environmentName: string;
  endpoint: endpoints | string;
  defaultValue: T;
};

@UntilDestroy()
@Injectable()
export class ThalosApiService implements OnDestroy {
  authorized: endpointsAuthorized;

  activeForm: () => UntypedFormGroup;
  storeSubscription: StoreSubscription<FormState>;

  constructor(private http: HttpClient, private dialogService: DialogService, private router: Router, private store: Store) {
    this.storeSubscription = this.store.subscribe((store: State) => store.form, [SET_ACTIVE_FORM]);
    this.storeSubscription.$.pipe(untilDestroyed(this)).subscribe((formState: FormState) => {
      this.activeForm = formState.activeForm;
    });

    endpointAuthorizationSubscription(store, this);
  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
  }

  rpc<T>(endpoint: endpoints, args: any, defaultValue: T | null): Observable<T>;
  rpc<T, D>(endpoint: endpoints, args: any, defaultValue: D, options?: rpcOptions): Observable<T | D>;
  rpc<T>(endpoint: endpoints, args: any, defaultValue: T | null, options?: rpcOptions): Observable<T>;
  rpc<T, D>(endpoint: endpoints, args: any, defaultValue: D, options?: rpcOptions): Observable<T | D> {
    if (args.filters) {
      for (let key in args.filters) {
        let val = args.filters[key];
        if (typeof val === 'string') {
          val = val.replace(/\*/g, '%');
          args.filters[key] = val;
        }
      }
    }

    if (environment.apiHost === null || environment.apiHost === undefined) {
      this.dialogService.open({
        title: 'Config error',
        content: 'Request could not be sent. API host not configured',
      });
      return;
    }

    let uri = `${environment.apiHost}${endpoint}`;
    if (options) {
      if (options.optionalParams) {
        for (let p of options.optionalParams) {
          uri += `/${p}`;
        }
      }
    }
    return this.http.post<T>(uri, { args, channelId }).pipe(catchError((err) => this.handleError(err, { environmentName: environment.name, endpoint, defaultValue, ...options }, args)));
  }

  async run<T>(endpoint: endpoints, args: any, defaultValue?: T | null, options?: rpcOptions): Promise<T> {
    if (args.filters) {
      for (let key in args.filters) {
        let val = args.filters[key];
        if (typeof val === 'string') {
          val = val.replace(/\*/g, '%');
          args.filters[key] = val;
        }
      }
    }

    if (environment.apiHost === null || environment.apiHost === undefined) {
      this.dialogService.open({
        title: 'Config error',
        content: 'Request could not be sent. API host not configured',
      });
      return;
    }

    let uri = `${environment.apiHost}${endpoint}`;
    if (options) {
      if (options.optionalParams) {
        for (let p of options.optionalParams) {
          uri += `/${p}`;
        }
      }
    }
    const request = this.http.post<T>(uri, { args, channelId }).pipe(catchError((err) => this.handleError(err, { environmentName: environment.name, endpoint, defaultValue, ...options }, args)));
    if (isObservable(request)) {
      return lastValueFrom(request);
    }
  }

  public handleError<T>(error: HttpErrorResponse | msalError, options: errorHandlerOptions<T>, payload?: any) {
    console.log({ error });
    if (isMsalError(error)) {
      console.log('Msal Error', error.errorCode, { error });

      return of(options.defaultValue);
    }
    if (!options.blockRedirect && (error.status === 0 || (error.status >= 500 && error.status < 600)) && (!this.activeForm || (this.activeForm().pristine && !this.activeForm().pending))) {
      let requestId = error.headers.get('X-Request-Id');
      let url = '/error/' + error.status;

      let queryParams: any = {};
      if (requestId) queryParams.requestId = requestId;
      if (options.endpoint) queryParams.endpoint = options.endpoint;
      if (isThalos500Error(error)) queryParams.message = error.error.message;
      this.router.navigate([url], { queryParams }).then((res) => {
        console.log('error redirect ', res);
      });
    }
    if (options.hideErrorPopup !== true && ((error.status !== 0 && error.status < 500) || !!options.blockRedirect || (this.activeForm && (this.activeForm().dirty || this.activeForm().pending)))) {
      this.showErrorMessage(error, options.endpoint).subscribe((sendReport) => {
        if (sendReport) this.sendErrorReport(error, options.environmentName, options.endpoint, payload, true);
      });
    }
    if (isThalos500Error(error) && error.status >= 500 && error.status < 600) {
      this.sendErrorReport(error, options.environmentName, options.endpoint, payload, false);
    }
    if (options.defaultValue !== undefined) {
      return of(options.defaultValue);
    }

    return throwError(() => error);
  }

  private showErrorMessage(error: HttpErrorResponse, endpoint: endpoints | string) {
    let message: string = '';
    let requestId: string = '';

    if (isValidationError(error.error)) {
      message = 'There was a problem with your request';
    } else if (error.status === 0) {
      message = 'The server is unavailable or is not responding, please contact your administrator';
    } else if (error.error && typeof error.error === 'string') message = error.error;
    else if (error.error && typeof error.error === 'object' && error.error.message) {
      message = error.error.message;
    } else if (error.error && typeof error.error === 'object' && error.error.errors && isArray(error.error.errors)) {
      message = error.error.errors.map((error) => `- ${error.message}`).join(`\n`);
    } else if (typeof error.message === 'string') {
      message = error.message;
    } else {
      if (error.status >= 400 && error.status < 500) message = `There was a problem with your request`;
      else message = `Thalos has encountered an error`;
    }

    let errorFooter: string = '';

    if (error.headers) {
      requestId = error.headers.get('X-Request-Id');
      if (requestId) errorFooter += `\nRequest Id: ${requestId}`;
    }

    errorFooter += `\nStatus: ${error.status}`;

    if (endpoint) {
      errorFooter += `\nEndpoint: ${endpoint}`;
    }

    try {
      const dialogRef = this.dialogService.open({
        title: 'An error has occured',
        content: ErrorDialogComponent,
        actions:
          this.authorized[endpoints.reportError] && endpoint !== endpoints.reportError && (error.status < 500 || error.status > 599)
            ? [{ text: 'Send Report' }, { text: 'Close', themeColor: 'primary' }]
            : [{ text: 'Close', themeColor: 'primary' }],
      });
      const errorDialog: ErrorDialogComponent = dialogRef.content.instance;

      errorDialog.error = message;
      errorDialog.errorFooter = errorFooter;
      if (isValidationError(error.error)) {
        errorDialog.validationErrors = error.error.errors;
      }

      return dialogRef.result.pipe(
        map((res) => {
          return !(res instanceof DialogCloseResult) && res.text === 'Send Report';
        })
      );
    } catch (error) {
      console.log('Could not open error dialog');
      return of(false);
    }
  }

  sendErrorReport(error: HttpErrorResponse, environmentName: string, endpoint: endpoints | string, args: any, showDialog: boolean) {
    if (endpoint === endpoints.reportError) return;
    let requestId = '';
    let errorCode: number = error.status;
    let errorMessage = '';

    if (error.error && typeof error.error === 'string') {
      errorMessage = error.error;
    } else if (error.error && typeof error.error === 'object' && error.error.message) {
      errorMessage = error.error.message;
    } else if (typeof error.message === 'string') {
      errorMessage = error.message;
    }

    if (error.headers) {
      requestId = error.headers.get('X-Request-Id');
    }

    let payload = '';
    if (args) {
      try {
        payload = JSON.stringify(args);
      } catch (e) {
        payload = '';
      }
    }

    this.rpc<any>(endpoints.reportError, { environmentName, requestId, endpoint, errorCode, errorMessage, payload }, null, { blockRedirect: true }).subscribe((sent) => {
      if (showDialog) {
        if (!!sent && sent.status === 'Sent') {
          this.dialogService.open({
            title: 'Report',
            content: 'Error report sent',
          });
        } else if (!!sent) {
          this.dialogService.open({
            title: 'Error',
            content: 'Error report not sent. Error reporting not configured',
          });
        }
      }
    });
  }

  rpcWithResponse<T>(endpoint: endpoints, args: any, defaultValue: T | null, otherOptions?: any): Observable<HttpResponse<T>>;
  rpcWithResponse<T, D>(endpoint: endpoints, args: any, defaultValue: D, options?: rpcOptions, otherOptions?: any): Observable<HttpResponse<T | D>>;
  rpcWithResponse<T>(endpoint: endpoints, args: any, defaultValue: T | null, options?: rpcOptions, otherOptions?: any): Observable<HttpResponse<T>>;
  rpcWithResponse<T, D>(endpoint: endpoints, args: any, defaultValue: D, options?: rpcOptions, otherOptions: any = {}): Observable<HttpResponse<T | D>> {
    if (args.filters) {
      for (let key in args.filters) {
        let val = args.filters[key];
        if (typeof val === 'string') {
          val = val.replace(/\*/g, '%');
          args.filters[key] = val;
        }
      }
    }

    if (environment.apiHost === null || environment.apiHost === undefined) {
      this.dialogService.open({
        title: 'Config error',
        content: 'Request could not be sent. API host not configured',
      });
      return;
    }

    let uri = `${environment.apiHost}${endpoint}`;
    if (options) {
      if (options.optionalParams) {
        for (let p of options.optionalParams) {
          uri += `/${p}`;
        }
      }
    }
    return this.http.post<T>(uri, { args, channelId }, { observe: 'response', ...otherOptions }).pipe(catchError((err) => of(err)));
  }
}

interface msalError {
  errorCode: string;
  errorMessage: string;
}

interface validationError {
  message: string;
  errors: {
    cause: string;
    path: string;
  }[];
}

interface thalos500Error {
  message: string;
  error: { message: string };
  status: number;
  url: string;
}

function isMsalError(error): error is msalError {
  return typeof error === 'object' && typeof error.errorCode === 'string' && typeof error.errorMessage === 'string';
}

export function isValidationError(error): error is validationError {
  return typeof error === 'object' && typeof error.message === 'string' && Array.isArray(error.errors);
}

export function isThalos500Error(error): error is thalos500Error {
  return typeof error === 'object' && typeof error.error === 'object' && typeof error.error.message === 'string';
}
