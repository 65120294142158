import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { PartialContact } from 'src/lib/newBackendTypes';
import { BaseEntity } from 'src/lib/newBackendTypes/entity';
import { toUTCDate } from 'src/lib/toUTCDate';

@Component({
  selector: 'entity-footer',
  templateUrl: './entity-footer.component.html',
  styleUrls: ['./entity-footer.component.scss'],
})
export class EntityFooterComponent implements OnInit {
  @Input()
  entity?: BaseEntity;

  updatedBy: number | string | null = null;
  updateDate: Date | null = null;
  createdBy: number | null = null;
  creationDate: Date | null = null;
  updateContact: PartialContact | null = null;
  creationContact: PartialContact | null = null;
  createLabel: string | null = null;
  updateLabel: string | null = null;

  constructor(private api: ThalosApiService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.entity) {
      this.updatedBy = null;
      this.updateDate = null;
      this.createdBy = null;
      this.creationDate = null;
      this.updateContact = null;
      this.creationContact = null;
      this.createLabel = null;
      this.updateLabel = null;
    } else {
      let existingContacts = [this.creationContact, this.updateContact].filter((c) => !!c);
      let contactIds: number[] = [this.entity.createdBy, this.entity.updatedBy].filter((id) => !!id);
      let obsv: Observable<PartialContact[]> =
        contactIds?.length > 0
          ? contactIds.every((id) => existingContacts.some((ec) => ec.id === id))
            ? of(existingContacts)
            : this.api.rpc<PartialContact[]>(endpoints.getContactNames, { contactIds }, [])
          : of([]);

      obsv.subscribe((res) => {
        const createdByContact = res.find((c) => c.id === this.entity.createdBy);
        const updatedByContact = res.find((c) => c.id === this.entity.updatedBy);
        if (createdByContact) {
          this.creationDate = this.entity.creationDate ? toUTCDate(this.entity.creationDate) : this.entity.createdDate ? toUTCDate(this.entity.createdDate) : null;
          this.createLabel = null;
          this.createdBy = this.entity.createdBy;
          this.creationContact = createdByContact;
          this.createLabel = `Created`;
          if (this.creationDate) {
            this.createLabel += ` on ${this.creationDate?.toLocaleString('en-US', {
              timeZone: 'UTC',
            })}`;
          }

          this.createLabel += ` by ${createdByContact.displayName}`;
        } else {
          this.createdBy = null;
          this.creationDate = null;
          this.creationContact = null;
          this.createLabel = null;
        }
        if ('updateDate' in this.entity && 'updatedBy' in this.entity && this.entity) {
          if (this.entity.updatedBy && this.entity.updateDate) {
            this.updateDate = this.entity.updateDate ? toUTCDate(this.entity.updateDate) : null;

            this.updateLabel = null;
            this.updatedBy = this.entity.updatedBy;
            this.updateContact = updatedByContact;
            this.updateLabel = `Updated`;
            if (this.updateDate) {
              this.updateLabel += ` on ${this.updateDate?.toLocaleString('en-US', {
                timeZone: 'UTC',
              })}`;
            }

            this.updateLabel += ` by ${updatedByContact.displayName}`;
          } else {
            this.updateContact = null;
            this.updateDate = null;
            this.updateLabel = null;
            this.updatedBy = null;
          }
        } else {
          if (this.entity.modifiedBy && this.entity.modifyDate) {
            this.updateDate = this.entity.modifyDate ? toUTCDate(this.entity.modifyDate) : null;

            this.updateLabel = null;
            this.updatedBy = this.entity.modifiedBy;
            this.updateLabel = `Modified`;
            if (this.updateDate) {
              this.updateLabel += ` on ${this.updateDate?.toLocaleString('en-US', {
                timeZone: 'UTC',
              })}`;
            }

            this.updateLabel += ` by ${this.updatedBy}`;
          } else {
            this.updateContact = null;
            this.updateDate = null;
            this.updateLabel = null;
            this.updatedBy = null;
          }
        }
      });
    }
  }
}
