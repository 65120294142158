import { from, of } from 'rxjs';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { Booking, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { bookingListPrefillCallback, ListBookingsPrefill } from './updateBooking';

const updateBookingStatusCheck: checkPrefillCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.bookingResponses.length === 0) return of('No bookings to be updated.');
  return of(true);
};

const updateBookingStatusForm: createFormCallback<ListBookingsPrefill, updateBookingStatusForm> = (delegate, id, prefill) => {
  const bookings = ` ${prefill.bookingResponses.map((b) => b.number).join(', ')}`;
  const form: (FormElementConfig<updateBookingStatusForm> | FormElementConfig<updateBookingStatusForm>[])[] = [
    { type: 'Label', text: `Booking(s): ${bookings}` },
    _fe('description', 'Status', 'TextArea', prefill.bookingResponses[0].description),
  ];
  if (prefill.bookingResponses.length > 1) {
    form.unshift({
      type: 'Label',
      text: 'Warning! Updating multiple bookings',
      style: { color: 'red', display: 'flex', 'justify-content': 'center' },
    });
  }
  return form;
};

const updateBookingStatusSubmit: submitFormCallback<ListBookingsPrefill, updateBookingStatusForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (let res of prefill.bookingResponses) {
          let request: updateBookingStatusRequest = {
            id: res.id,
            description: result.description,
            lastTrackingDate: getTodayUTC(),
          };
          const updateBooking = await api.run<Booking>(endpoints.updateBooking, request, null);
          updateBookingResponses.push(updateBooking);
        }
        if (updateBookingResponses) return resolve(updateBookingResponses);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully updated: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were updated and try again if necessary.</div>`);
      })
  );
};

export const updateBookingStatusPreset: DynamicFormConstant<ListBookingsPrefill, updateBookingStatusForm> = {
  allowMultipleRows: true,
  value: DynamicFormType.BOOKING_STATUS,
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  label: 'Update Status',
  title: 'Update Status',
  getPrefill: bookingListPrefillCallback,
  checkPrefill: updateBookingStatusCheck,
  createForm: updateBookingStatusForm,
  submitForm: updateBookingStatusSubmit,
  endpoints: [endpoints.updateBooking],
  width: 500,
};

export type updateBookingStatusForm = Pick<Booking, 'description'>;

export type updateBookingStatusRequest = Pick<Booking, 'id' | 'description' | 'lastTrackingDate'>;
