import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { GetContextMenuItemsParams, GridOptions, GridReadyEvent, MenuItemDef, RowNode, ValueGetterParams } from 'ag-grid-community';
import { combineLatest, lastValueFrom } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { FINISH_REQUEST, START_REQUEST } from 'src/app/core/reducers/actions';
import { RequestSpinner } from 'src/app/core/reducers/layout';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { DropdownConfig, StoreSubscription } from 'src/lib';
import { getContextMenuItems, gridDateFormatter } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { earlierThanValidator, inclusiveOrValidator } from 'src/lib/genericValidators';
import { basicGraphqlCityDropdown } from 'src/lib/graphql/graphQlFunctions';
import { getTomorrowUTC } from 'src/lib/helperFunctions';
import { PropertyDocument, Unit } from 'src/lib/newBackendTypes';
import { CreateWeightTicketRequest, WeightTicket, WeightTicketSource, WeightTicketSourceOptions, WeightTicketSourceUserOptions } from 'src/lib/newBackendTypes/weightTicket';
import { TypedFormGroup } from 'src/lib/typedForms';

@UntilDestroy()
@Component({
  selector: 'thalos-weight-tickets',
  templateUrl: './weight-tickets.component.html',
  styleUrls: ['./weight-tickets.component.scss'],
})
export class WeightTicketsComponent implements OnInit, OnDestroy {
  gridOptions: GridOptions;

  data: WeightTicket[] | null;
  shipments: (PropertyDocument & { label?: string })[];

  numberFormat: NumberFormatOptions = {
    useGrouping: false,
  };

  ticketForm: TypedFormGroup<WeightTicketForm>;

  unitDropdown: DropdownConfig<Unit>;
  locationDropdown = basicGraphqlCityDropdown();

  requestsPending: readonly RequestSpinner[];

  weightTicketSourceOptions = WeightTicketSourceUserOptions;

  storeSub: StoreSubscription<RequestSpinner[]>;

  constructor(private api: ThalosApiService, private store: Store, private spinnerService: SpinnerService, private dialogService: DialogService) {
    this.unitDropdown = new DropdownConfig({
      valueField: 'unitId',
      labelField: 'code',
      listProcedure: endpoints.listUnits,
    });

    this.ticketForm = new TypedFormGroup<WeightTicketForm>(
      {
        shipment: new UntypedFormControl(null, Validators.required),
        date: new UntypedFormControl(null, [Validators.required, earlierThanValidator(getTomorrowUTC(), 'Date must be no later than today')]),
        netWeight: new UntypedFormControl(null),
        grossWeight: new UntypedFormControl(null),
        place: new UntypedFormControl(null, Validators.required),
        unit: new UntypedFormControl(null, Validators.required),
        source: new UntypedFormControl(null, Validators.required),
      },
      (fg: UntypedFormGroup) => {
        for (let key in fg.controls) {
          if (fg.controls[key].value) return null;
        }
        return { required: true };
      }
    );

    let netWeightControl: UntypedFormControl = <UntypedFormControl>this.ticketForm.get('netWeight');
    let grossWeightControl: UntypedFormControl = <UntypedFormControl>this.ticketForm.get('grossWeight');
    let text = 'Net and/or Gross Weight is required';

    let netWeightRangeValidator = (control: UntypedFormControl) => {
      if (grossWeightControl.value === null || control.value === null) return null;
      return control.value > grossWeightControl.value ? { custom: 'Net weight must be less than or equal to gross' } : null;
    };
    let grossWeightRangeValidator = (control: UntypedFormControl) => {
      if (netWeightControl.value === null || control.value === null) return null;
      return control.value < netWeightControl.value ? { custom: 'Gross weight must be greater than or equal to net' } : null;
    };

    netWeightControl.setValidators([Validators.min(0), inclusiveOrValidator(text, grossWeightControl), netWeightRangeValidator]);
    grossWeightControl.setValidators([Validators.min(0), inclusiveOrValidator(text, netWeightControl), grossWeightRangeValidator]);

    let netUpdating = false;
    let grossUpdating = false;
    netWeightControl.valueChanges
      .pipe(
        filter((_) => !netUpdating),
        untilDestroyed(this)
      )
      .subscribe(() => {
        netUpdating = true;
        grossWeightControl.updateValueAndValidity({ onlySelf: true });
        grossWeightControl.markAsTouched();
        netUpdating = false;
      });
    grossWeightControl.valueChanges
      .pipe(
        filter((_) => !grossUpdating),
        untilDestroyed(this)
      )
      .subscribe(() => {
        grossUpdating = true;
        netWeightControl.updateValueAndValidity({ onlySelf: true });
        netWeightControl.markAsTouched();
        grossUpdating = false;
      });

    combineLatest([this.ticketForm.get('source').valueChanges, this.ticketForm.get('shipment').valueChanges])
      .pipe(untilDestroyed(this))
      .subscribe(([source, shipment]: [WeightTicketSource, PropertyDocument]) => {
        if (this.ticketForm.get('place').untouched) {
          if (source === WeightTicketSource.CUSTOMER && !!shipment?.destination) {
            this.ticketForm.patchValue({ place: shipment.destination });
          } else if (source === WeightTicketSource.SUPPLIER && !!shipment?.origin) {
            this.ticketForm.patchValue({ place: shipment.origin });
          } else {
            this.ticketForm.patchValue({ place: null });
          }
        }
      });

    this.data = null;

    this.gridOptions = {
      onGridReady: this.onGridReady,
      defaultColDef: {
        resizable: true,
        width: 100,
      },
      columnDefs: [
        { headerName: 'id', field: 'id', width: 75 },
        { headerName: 'Shipment', valueGetter: this.shipmentValueGetter, width: 180 },
        { headerName: 'Source', valueGetter: this.sourceValueGetter, width: 100 },
        { headerName: 'Date', field: 'date', valueFormatter: gridDateFormatter(), width: 87 },
        { headerName: 'Location', field: 'place.name' },
        {
          headerName: 'Net Weight',
          valueGetter: this.netWeightGetter,
          cellStyle: { textAlign: 'right' },
          width: 105,
        },
        {
          headerName: 'Gross Weight',
          valueGetter: this.grossWeightGetter,
          cellStyle: { textAlign: 'right' },
          width: 113,
        },
        {
          headerName: 'Tare',
          valueGetter: this.tareGetter,
          cellStyle: { textAlign: 'right' },
          width: 100,
        },
      ],
      getContextMenuItems: this.getContextMenuItems,
    };

    this.requestsPending = [];
    this.storeSub = this.store.subscribe((state) => state.layout.requestsPending, [START_REQUEST, FINISH_REQUEST]);
    this.storeSub.$.pipe(untilDestroyed(this)).subscribe((requests) => {
      this.requestsPending = requests;
    });
  }

  getContextMenuItems = (params: GetContextMenuItemsParams) => {
    let items: (string | MenuItemDef)[] = getContextMenuItems('separator')(params);

    if (items.length > 0) items = items.concat([{ name: 'Delete Ticket', action: () => this.deleteTicket(params.node) }]);

    return items;
  };

  deleteTicket = async (row: RowNode) => {
    let ticket: WeightTicket = row.data;

    this.dialogService
      .open({
        title: 'Delete Discrepancy',
        content: 'Are you sure you wish to delete this weight ticket? This cannot be undone',
        actions: [
          {
            text: 'Cancel',
          },
          {
            themeColor: 'primary',
            text: 'Delete',
          },
        ],
      })
      .result.pipe(filter((res) => !(res instanceof DialogCloseResult) && res.text === 'Delete'))
      .subscribe(async () => {
        let deleted = false;
        if (ticket.id) {
          let rid = this.spinnerService.startRequest('Deleting weight ticket');
          deleted = await lastValueFrom(
            this.api.rpc<WeightTicket>(endpoints.deleteWeightTicket, { filters: { id: ticket.id } }, null).pipe(
              tap((_) => {
                this.spinnerService.completeRequest(rid);
              }),
              map((res) => !!res)
            )
          );
        }
        if (deleted || !ticket.id) {
          this.gridOptions.api.removeItems([row]);
        }
      });
  };

  ngOnDestroy() {
    this.storeSub.unsubscribe();
  }

  netWeightGetter = (params: ValueGetterParams) => {
    let wt: WeightTicket = params.data;
    if (!wt.netWeight) return '';
    if (!wt.unit) return wt.netWeight;
    return `${wt.netWeight} ${wt.unit.code}`;
  };

  grossWeightGetter = (params: ValueGetterParams) => {
    let wt: WeightTicket = params.data;
    if (!wt.grossWeight) return '';
    if (!wt.unit) return wt.grossWeight;
    return `${wt.grossWeight} ${wt.unit.code}`;
  };

  tareGetter = (params: ValueGetterParams) => {
    let wt: WeightTicket = params.data;
    if (!wt.grossWeight || !wt.netWeight) return '';
    let tare = (wt.grossWeight - wt.netWeight).toFixed(3);
    if (!wt.unit) return tare;
    return `${tare} ${wt.unit.code}`;
  };

  shipmentValueGetter = (params: ValueGetterParams) => {
    let wt: WeightTicket = params.data;
    if (!wt.shipment) return wt.chunkKey || '';
    let chunkKey = wt.chunkKey || '';
    let containerMarks = wt.shipment.containerMarks || '';
    return `${containerMarks || ''}${containerMarks && chunkKey ? ' - ' : ''}${chunkKey || ''}`;
  };

  sourceValueGetter = (params: ValueGetterParams) => {
    let wt: WeightTicket = params.data;
    let source = WeightTicketSourceOptions.find((o) => o.value === wt.source);
    if (source) return source.label;
    return wt.source || 'None';
  };

  ngOnInit(): void {
    if (this.shipments === undefined) this.shipments = [];
    if (this.data === null) this.data = [];
  }

  onGridReady(event: GridReadyEvent) {
    event.api.sizeColumnsToFit();
  }

  async createTicket() {
    for (let control in this.ticketForm.controls) {
      this.ticketForm.get(control).updateValueAndValidity({ emitEvent: true });
      this.ticketForm.get(control).markAsTouched();
    }

    if (this.ticketForm.valid !== true) {
      return;
    }

    if (this.requestsPending.length > 0) {
      return;
    }

    let rId = this.spinnerService.startRequest('Saving Weight Ticket');

    let data = this.ticketForm.value;

    let request: CreateWeightTicketRequest = {
      chunkKey: data.shipment ? data.shipment.id : null,
      date: data.date,
      grossWeight: data.grossWeight,
      placeId: data.place ? data.place.id : null,
      netWeight: data.netWeight,
      unitId: data.unit.unitId,
      source: data.source,
    };

    let newTicket = await lastValueFrom(this.api.rpc<WeightTicket>(endpoints.createWeightTicket, request, null));

    this.spinnerService.completeRequest(rId);

    setTimeout(() => {
      if (newTicket) {
        this.data = this.data.concat([newTicket]);
        this.ticketForm.reset();
        this.ticketForm.markAsUntouched();
        this.ticketForm.markAsPristine();
      }
    });
  }

  shipmentLabelMapper(shipment: PropertyDocument): PropertyDocument & { label: string } {
    let containerMarks = shipment.containerMarks;
    let chunkKey = `${shipment.id || ''}`;
    return {
      ...shipment,
      label: `${containerMarks || ''}${containerMarks && chunkKey ? ' - ' : ''}${chunkKey || ''}`,
    };
  }

  public setShipments(shipments: PropertyDocument[]) {
    this.shipments = shipments.map(this.shipmentLabelMapper);
  }
}

type WeightTicketForm = Pick<WeightTicket, 'netWeight' | 'grossWeight' | 'place' | 'unit' | 'source'> & {
  date?: Date;
  shipment?: PropertyDocument & { label: string };
};
