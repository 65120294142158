import { Directive, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { CellEditingStoppedEvent, CellValueChangedEvent, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Directive()
export abstract class TableForm<DataType> implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  gridOptions: GridOptions = {};
  data: DataType[] = [];
  gridApi: GridApi;
  columnApi: ColumnApi;
  touched = false;
  disabled = false;
  onTouched = () => {};
  onChange = (value: DataType[]) => {};
  onValidationChange = () => {};

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  registerOnValidatorChange(onValidationChange: () => any): void {
    this.onValidationChange = onValidationChange;
  }

  registerOnChange(onChange: (value: DataType[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => any): void {
    this.onTouched = onTouched;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (!this.touched && typeof this.onTouched === 'function') {
      this.touched = true;
      this.onTouched();
    }
    this.gridApi.setFocusedCell(event.rowIndex, event.colDef.field);
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    if (typeof this.onChange === 'function') {
      this.onChange(this.data);
    }
    if (typeof this.onValidationChange === 'function') {
      this.onValidationChange();
    }
  }

  writeValue(data: DataType[]): void {
    this.data = data;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  abstract validate(control: AbstractControl): ValidationErrors;

  autosizeColumns() {
    if (!!this.columnApi) {
      this.columnApi.autoSizeAllColumns(false);
    }
  }
}
