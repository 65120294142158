import { EnumLabels } from '../generics';

export enum InvoiceDocumentType {
  CUSTOMER = 1,
  SUPPLIER = 2,
}

export const InvoiceDocumentTypes: EnumLabels<InvoiceDocumentType> = [
  { label: 'Customer', value: InvoiceDocumentType.CUSTOMER },
  { label: 'Supplier', value: InvoiceDocumentType.SUPPLIER },
];
