export class MarketDataView {
  marketId: number;
  priceTermId: number;
  marketValuationId: number | null;
  bradyMarketDate: number;
  marketDate: number;
  marketPrice: number;
}

export type DynamiteMarketPrice = {
  Id: number;
  PriceSetId: DynamiteMarketPriceSetId;
  EffectiveDate: Date;
  Period: DynamiteMarketPricePeriod | null;
  Date: Date | null;
  Bid: number;
  Ask: number;
  Month: Date | null;
};

export enum DynamiteMarketPricePeriod {
  CASH = 'CASH',
  LOWEST = 'LOWEST',
  SPOT = 'SPOT',
  ON = 'ON',
  W1 = '1W',
  W2 = '2W',
  M1 = '1M',
  M2 = '2M',
  M3 = '3M',
  M4 = '4M',
  M5 = '5M',
  M6 = '6M',
  M7 = '7M',
  M8 = '8M',
  M9 = '9M',
  M10 = '10M',
  M11 = '11M',
  M12 = '12M',
}

export enum DynamiteMarketPriceSetId {
  MWTI = 3,
}
