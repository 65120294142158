import { Component, OnInit } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { NumericTextBoxType } from '../../form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';

@Component({
  selector: 'thalos-numerictextbox-prompt',
  templateUrl: './numerictextbox-prompt.component.html',
})
export class NumericTextboxPromptComponent implements OnInit {
  public config: NumberFormatOptions;
  public label: string;
  value: number;
  public unitLabel: string;
  public fieldType: NumericTextBoxType;

  constructor() {
    this.value = null;
  }

  ngOnInit(): void {}
}
