import { JournalHeader } from './journal';

export class PramvJournalKey {
  pramvKey: number;
  selDeliveryKey: number | null;
  buyDeliveryKey: number | null;
  pramvJournalKey: number | null;
  wrPramvJournalKey: number | null;
  buyChunkKey: number | null;
  selChunkKey: number | null;
  journalHeader?: JournalHeader;
}
