import { FiscalCompany } from './fiscalCompany';
import { NetPositionLines } from './netPositionLine';

export enum NetPositionAccountsEnum {
  UNSHIPPED_MP_FIX = 1,
  UNSHIPPED_MP_PROV = 2,
  UNSHIPPED_MS_FIX = 3,
  UNSHIPPED_MS_PROV = 4,
  TRADING_BOOK = 5,
  FUTURES_TO_BE_DONE = 6,
  FUTURES = 7,
  INVENTORY_FIX = 8,
  INVENTORY_PROV = 9,
  PTICKETS_AT_FIX = 10,
  PTICKETS_AT_PROV = 11,
  INVOICES_AT_PROV = 12,
  INVOICES_AT_FIX = 13,
  FIXATIONS_TO_APPLY = 14,
  PHYSICAL_INIT_BALANCE = 15,
  NET_POSITION_INIT_BALANCE = 16,
  FUTURES_ADMIN_BOOK = 17,
  ARBITRAGE_BOOK = 18,
  MISPH_BOOK = 19,
  SPREAD_BOOK = 20,
  UNWRITTEN_BOOK = 21,
  NON_HEDGE_POSITION = 22,
  ROLLOVER_BOOK = 23,
}

export type NetPositionAccount = {
  id: number;
  code: NetPositionAccountsEnum;
  companyId: number;
  description: string;
  physicalWorld: number;
  netPositionWorld: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  accountType: NetPositionAccountsTypesEnum;
  accountGroup: string;
  netPositionLines?: NetPositionLines[];
  company?: FiscalCompany;
};
export enum NetPositionAccountsTypesEnum {
  CONTRACT = 1,
  FUTURES_TO_BE_DONE = 2,
  FUTURES = 3,
  INVENTORY = 4,
  VOUCHER = 5,
  FIXATIONS_TO_APPLY = 6,
  ADMIN = 7,
  INITIAL_BALANCE = 8,
}
