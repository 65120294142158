import { EnumLabels } from '../generics';
import { YN } from './enums';

export type PurchaseInvoiceQueueResult = {
  shipmentId: number;
  containerNumber: string;
  contractId: number;
  contractNumber: number;
  contractDate: number;
  counterpartyName: string;
  counterpartyId: number;
  traderName: string;
  grossWeight: number;
  grossWeightMT: number;
  netWeight: number;
  netWeightMT: number;
  contractQuantityUnitId: number;
  quantityUnitId: number;
  incotermId: number;
  bookingid: number | null;
  bookingNumber: number | null;
  supplierDocsRecievedDate: number | null;
  originCountryName: string;
  loadingPlaceName: string;
  destinationPlaceName: string;
  contractProductId: number;
  productId: number;
  itemName: string;
  purchasePriceFixed: YN;
  purchasePrice: number;
  purchasePriceUnitId: number;
  purchasePriceCurrencyId: number;
  purchaseAmount: number;
  duplicateInvoicingAlert: string;
  domesticDeliveryDate: number | null;
};

export const PriceFixedTypes: EnumLabels<YN> = [
  { label: 'Provisional', value: YN.N },
  { label: 'Fixed', value: YN.Y },
];
