import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { CalendarView, DatePickerComponent, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { CalendarType } from '@progress/kendo-angular-dateinputs/calendar/models/type';
import { SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { compareDates, getTodayUTC } from 'src/lib/helperFunctions';
import { toLocalDate, toUTCDate } from 'src/lib/toUTCDate';
import { DateCalculatorComponent } from '../../date-calculator/date-calculator.component';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'datepicker-wrapper',
  templateUrl: `./datepicker-wrapper.component.html`,
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => DatepickerWrapperComponent) }],
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
})
export class DatepickerWrapperComponent extends FormElementComponent {
  @Input('showCalculator')
  showCalculator: boolean = false;
  @Input()
  format?: FormatSettings = { displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy' };
  @Input()
  activeView?: CalendarView = 'month';
  @Input()
  calendarType?: CalendarType = 'infinite';

  min = new Date(2000, 0, 1);
  max = new Date(2099, 11, 31);

  @ViewChild('datepicker', { static: false })
  datepicker: DatePickerComponent;

  innerControl: UntypedFormControl = new UntypedFormControl();

  constructor(controlDir: NgControl, store: Store, private selector: SelectorPopupService) {
    super(controlDir, store);
  }

  setValue(value: any) {
    let d: Date = null;
    if (!!value) {
      d = toLocalDate(value);
    }
    this.innerControl.setValue(d);
    this._value = d;

    let formControl = this.getFormControl();
    if (formControl && typeof value === 'string' && this.onChange) {
      let touched = formControl.touched;
      this.onChange(this.value);
      if (!touched) {
        formControl.markAsUntouched();
        formControl.markAsPristine();
      }
    }
  }

  getValue(): any {
    let val = this.innerControl.value;
    this._value = val;
    if (!!val && typeof val !== 'string') {
      val = toUTCDate(val);
    }
    return val;
  }

  ngOnInit() {
    if (this._value && typeof this._value === 'string') {
      setTimeout(() => [(this.value = this._value)]);
    }

    let fc = this.getFormControl();
    /**
     * Ensure built in validation won't be overwritten
     */
    let setValidators = fc.setValidators.bind(fc);

    fc.setValidators([fc.validator, this.validate.bind(this)].filter((v) => !!v));

    fc.setValidators = (newValidator) => {
      setValidators([newValidator].flatMap((f) => f ?? []).concat(this.validate.bind(this)));
    };
  }

  public focus() {
    if (this.datepicker) {
      setTimeout(() => {
        this.datepicker.focus();
      });
    }
  }

  comparator(a, b) {
    return compareDates(a, b) && !this.innerControl.errors;
  }

  validate() {
    return this.innerControl.errors;
  }

  openCalculator() {
    this.selector
      .openForm(DateCalculatorComponent, {
        title: 'Date Calculator',
        initializer: (c) => {
          c.form.patchValue({ fromDate: this.value || getTodayUTC() });
        },
        width: 200,
        height: 300,
      })
      .subscribe((newDate) => {
        if (newDate !== 'Close') {
          this.value = newDate;
        }
        this.datepicker.focus();
      });
  }
}
