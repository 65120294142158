import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SET_ANCHOR_POINT } from 'src/app/core/reducers/actions';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { Store } from 'src/app/core/services/store.service';
import { MiscFeature } from 'src/lib/feature';
import { SourceEntityType, SourceEntityTypeEntityNameMap } from 'src/lib/newBackendTypes';

@Directive({
  selector: '[entityLink]',
})
export class EntityLinkDirective implements OnInit {
  @Input()
  entityType?: SourceEntityType;

  @Input()
  altFeature?: MiscFeature;

  @Input()
  entityId: number;

  @Input()
  operation: 'get' | 'list' | 'create' = 'get';

  @Input()
  newTab: boolean = false;

  @Input()
  clearAnchorPoint?: boolean;

  @Output()
  navigate: EventEmitter<any> = new EventEmitter();

  @Output()
  nextOrPreviusEntityNavigate = new EventEmitter<{ entityKey: number }>();

  valid: boolean = false;

  constructor(private elementRef: ElementRef, private entityLookup: EntityLookupService, private router: Router, private store: Store) {}

  ngOnInit(): void {
    if ((this.entityType || this.altFeature) && this.entityId) {
      if (this.altFeature) {
        this.valid = this.entityLookup.featureExists(this.altFeature);
      } else {
        this.valid = this.entityLookup.entityPathExists(this.operation, this.entityType);
      }
      if (this.valid && this.entityType) {
        let entityName = SourceEntityTypeEntityNameMap[this.entityType];
        let title = this.newTab ? `Open ${entityName} in New Tab` : `Go To ${entityName}`;
        this.elementRef.nativeElement.title = title;
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(ev) {
    if (this.valid) {
      this.navigate.emit();

      if (this.clearAnchorPoint) {
        this.store.dispatch({ type: SET_ANCHOR_POINT, payload: null });
      }
      if (this.altFeature) {
        let featureRoute = this.entityLookup.getFeatureRoute(this.altFeature);
        if (featureRoute?.link) {
          if (this.newTab) {
            window.open(`${featureRoute.link}/${this.entityId}`);
          } else {
            this.router.navigate([featureRoute.link, this.entityId]);
          }
        }
      } else {
        setTimeout(() => {
          this.entityLookup.gotoEntity(this.entityType, this.entityId, this.operation, (ev.metaKey || ev.ctrlKey || this.newTab) ?? {});
          this.nextOrPreviusEntityNavigate.emit({ entityKey: this.entityId });
        });
      }
    }
  }
}
