<form-table [formArray]="approvalForm" noDataMessage="Click Add Approval or save form to generate">
  <ng-template #tableHeader>
    <div class="col-form-label col-form-label-sm col-lg-2 col-md-3">Type</div>
    <div class="col-form-label col-form-label-sm col-lg-2 col-md-2">Status</div>
    <div class="col-form-label col-form-label-sm col-lg-2 col-md-3" *ngIf="createAuthorized || updateAuthorized">Actions</div>
    <div class="col-form-label col-form-label-sm col-lg-2 col-md-3">Approvers</div>
  </ng-template>
  <ng-template #tableRow let-approval="element" let-i="i">
    <table-field name="Type" class="col-lg-2 col-md-3">
      <dropdown-wrapper [formControl]="approval.get('approvalType')" [dropdownConfig]="approvalTypeConfig" [readonly]="!!approval.get('id').value" [ignoreReadonlyMode]="true"> </dropdown-wrapper>
    </table-field>
    <table-field name="Status" class="col-lg-2 col-md-2">
      {{ approval.value.status | approvalCode }}
    </table-field>
    <table-field name="Actions" class="col-lg-2 col-md-3" *ngIf="createAuthorized || updateAuthorized">
      <simple-dropdown
        [formControl]="approval.get('action')"
        [data]="approval.value.actionsAvailable"
        [valueField]="'value'"
        [textField]="'label'"
        [valuePrimitive]="true"
        [readonly]="approval.get('id').value && !updateAuthorized"
        [ignoreReadonlyMode]="true"
      >
      </simple-dropdown>
    </table-field>
    <table-field name="Approvers" class="col-lg-2 col-md-3">
      <span [hidden]="showApproverDropdown(approval?.value)">
        {{ approval.value.approvedBy?.displayName }}
      </span>
      <approver-dropdown
        [hidden]="!showApproverDropdown(approval?.value)"
        [status]="approval.value.status"
        [approvedBy]="approval.value.approvedBy"
        [formControl]="approval.get('approvalRequests')"
        [approvalType]="approval.value.approvalType"
        [readonly]="approverDropdownReadonly(approval?.value)"
        [ignoreReadonlyMode]="true"
      >
      </approver-dropdown>
    </table-field>
    <div class="col-md-1">
      <button *ngIf="!approval?.value?.id || deleteAuthorized" icon="trash" kendoButton type="button" (click)="deleteApproval(i)"></button>
    </div>
  </ng-template>
  <ng-template class="d-flex" style="flex-direction: row-reverse" #tableFooter>
    <button type="button" kendoButton (click)="addApproval()" *ngIf="createAuthorized" style="width: 95px">Add Approval</button>
    <button type="button" kendoButton (click)="clickSaveChanges()" *ngIf="entityId && (updateAuthorized || createAuthorized) && _readonlyMode && approvalForm.dirty && !!approvalForm.controls?.length">
      Save Changes
    </button>
  </ng-template>
</form-table>
