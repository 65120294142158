import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { NetPositionJournalDatesPrefill } from 'src/lib/flex/forms/updateNetPositionJournalDates';
import { conditionalValidators, laterThanValidator } from 'src/lib/genericValidators';

import { dateGreaterThanOrSameAs, formatDate, markFormGroupTouched } from 'src/lib/helperFunctions';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'risk-management-net-position-journal-dates',
  templateUrl: './net-position-journal-dates.component.html',
})
export class NetPositionJournalDatesComponent implements ModalFormComponent<NetPositionJournalValueDateForm, NetPositionJournalDatesPrefill> {
  form: TypedFormGroup<NetPositionJournalValueDateForm>;
  popup = true;
  permDate: Date;
  invalidDate: Date | undefined;

  constructor() {
    this.form = new TypedFormGroup<NetPositionJournalValueDateForm>({
      valueDate: new UntypedFormControl(null, Validators.required),
      invalidDate: new UntypedFormControl(null),
    });
  }

  addValidations() {
    this.form.get('valueDate').setValidators([Validators.required, laterThanValidator(this.permDate, `Value Date must be equal or greater than Perm Date NP: ${formatDate(this.permDate, true)}`)]);
    this.form.get('invalidDate').setValidators(conditionalValidators(() => !!this.invalidDate, Validators.required));
  }

  prefillForm(data: NetPositionJournalDatesPrefill) {
    if (!!data) this.form.patchValue(data);
    this.permDate = new Date(data.company?.permDateNetPosition);
    this.invalidDate = data.invalidDate ? new Date(data.invalidDate) : undefined;

    this.addValidations();
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): NetPositionJournalValueDateForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    return { valueDate: formVal.valueDate, invalidDate: formVal.invalidDate };
  }

  get invalidDateWarning() {
    const invalidDate = this.form.get('invalidDate').value;
    return { text: `Invalid Date must be equal or greater than Perm Date NP: ${formatDate(this.permDate, true)}`, show: !dateGreaterThanOrSameAs(invalidDate, this.permDate) && !!this.invalidDate };
  }
}

export type NetPositionJournalValueDateForm = Pick<NetPositionJournalDatesPrefill, 'valueDate' | 'invalidDate'>;
