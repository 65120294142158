import { Unit, YN } from './newBackendTypes';

export function toMetricTons(value: number, unit: Unit): number | null {
  if (unit.indivisible === YN.Y) {
    throw new Error('Tried to convert a unit that cannot be converted');
  }
  if (!unit.unitFactors || unit.unitFactors.length === 0) return null;
  let unitFactor = unit.unitFactors[0];
  return unitFactor.factor * value;
}

export function toUnit(value: number, unit: Unit, targetUnit: Unit): number | null {
  if (unit.indivisible === YN.Y || targetUnit.indivisible === YN.Y) {
    throw new Error('Tried to convert a unit that cannot be converted');
  }
  if (!unit.unitFactors || unit.unitFactors.length === 0 || !targetUnit.unitFactors || targetUnit.unitFactors.length === 0) return null;

  let mt = toMetricTons(value, unit);

  let targetFactor = targetUnit.unitFactors[0];
  return mt / targetFactor.factor;
}
