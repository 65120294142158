import { Injectable, TemplateRef } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColorPickerPromptComponent } from 'src/app/shared/prompts/color-picker/color-picker-prompt.component';
import { ComboboxPromptComponent } from 'src/app/shared/prompts/combobox-prompt/combobox-prompt.component';
import { NumericTextboxPromptComponent } from 'src/app/shared/prompts/numerictextbox-prompt/numerictextbox-prompt.component';
import { SimpleDropdownPromptComponent } from 'src/app/shared/prompts/simple-dropdown-prompt/simple-dropdown-prompt.component';
import { DropdownConfig } from 'src/lib';
import { TextDialogComponent } from '../components/text-dialog/text-dialog.component';
import { TextPromptComponent } from 'src/app/shared/prompts/text-prompt/text-prompt.component';
import { TextAreaPromptComponent } from 'src/app/shared/prompts/textarea-prompt/textarea-prompt.component';
import { HTMLDialogComponent } from '../components/html-dialog/html-dialog.component';
import { ValidatorFn } from '@angular/forms';
import { CustomDialogResult } from './selector-popup.service';
import { NumericTextBoxType } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';

@Injectable({
  providedIn: 'root',
})
export class PromptService {
  constructor(private dialogService: DialogService) {}

  comboboxPrompt<T>(title: string, label: string, config: DropdownConfig<T>) {
    let dialog = this.dialogService.open({
      title,
      content: ComboboxPromptComponent,
      actions: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    let comp: ComboboxPromptComponent<T> = dialog.content.instance;
    comp.config = config;
    comp.label = label;

    return dialog.result.pipe(
      map((res) => {
        if (res instanceof DialogCloseResult || res.text !== 'Submit') return null;

        return comp.value;
      })
    );
  }

  numericPrompt(title: string, label: string, config: NumberFormatOptions, startingValue?: number, unitLabel?: string, fieldType?: NumericTextBoxType) {
    let dialog = this.dialogService.open({
      title,
      content: NumericTextboxPromptComponent,
      width: 300,
      height: 175,
      actions: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    let comp: NumericTextboxPromptComponent = dialog.content.instance;
    comp.config = config;
    comp.label = label;
    comp.unitLabel = unitLabel;
    comp.fieldType;
    if (startingValue) comp.value = startingValue;

    return dialog.result.pipe(
      map((res) => {
        if (res instanceof DialogCloseResult || res.text !== 'Submit') return null;

        return comp.value;
      })
    );
  }

  textPrompt(title: string, label: string, startingValue?: string, validators?: ValidatorFn | ValidatorFn[]) {
    let comp: TextPromptComponent;

    let dialog = this.dialogService.open({
      title,
      content: TextPromptComponent,
      width: 350,
      height: 215,
      preventAction: (ev) => {
        return !(ev instanceof DialogCloseResult) && ev.text === 'Submit' && !comp?.valid;
      },
      actions: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    comp = dialog.content.instance;
    comp.label = label;
    if (startingValue) comp.value = startingValue;
    if (validators) comp.validators = validators;

    return dialog.result.pipe(
      map((res) => {
        if (res instanceof DialogCloseResult || res.text !== 'Submit') return null;

        return comp.value;
      })
    );
  }

  textareaPrompt(title: string, label: string, startingValue?: string) {
    let dialog = this.dialogService.open({
      title,
      content: TextAreaPromptComponent,
      width: '90%',
      maxWidth: 600,
      maxHeight: 475,
      actions: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    let comp: TextPromptComponent = dialog.content.instance;
    comp.label = label;
    if (startingValue) comp.value = startingValue;

    return dialog.result.pipe(
      map((res) => {
        if (res instanceof DialogCloseResult || res.text !== 'Submit') return null;

        return comp.value;
      })
    );
  }

  simpleDropdownPrompt<T>(title: string, label: string, data: T[], labelField: keyof T, valueField: keyof T, defaultValue?: T): Observable<T | 'Close' | null> {
    let dialog = this.dialogService.open({
      title,
      content: SimpleDropdownPromptComponent,
      actions: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    let comp: SimpleDropdownPromptComponent<T> = dialog.content.instance;
    (comp.data = data), (comp.labelField = labelField);
    comp.valueField = valueField;
    comp.label = label;

    if (defaultValue) comp.value = defaultValue;

    return dialog.result.pipe(
      map((res) => {
        if (res instanceof DialogCloseResult || res.text !== 'Submit') return 'Close';

        return comp.value;
      })
    );
  }

  simpleConfirmation<C = void>(
    title: string,
    content: string | { new (...any): C },
    options?: {
      confirmText?: string;
      initializer?: (c: C) => void;
      width?: number | string;
      height?: number | string;
      cancelText?: string;
      maxWidth?: number | string;
    }
  ): Observable<boolean> {
    let dialog = this.dialogService.open({
      width: options?.width,
      height: options?.height,
      maxHeight: options?.maxWidth,
      title,
      content: typeof content === 'string' ? TextDialogComponent : content,
      actions: [
        {
          text: options?.cancelText || 'Cancel',
        },
        {
          text: options?.confirmText || 'Confirm',
          themeColor: 'primary',
          primary: true,
        },
      ],
    });

    if (typeof content === 'string') {
      let c: TextDialogComponent = dialog.content.instance;
      c.text = content;
    }

    if (options?.initializer && typeof content !== 'string') {
      options?.initializer(dialog.content.instance);
    }

    return dialog.result.pipe(
      map((res: CustomDialogResult) => {
        return !(res instanceof DialogCloseResult || !res.primary);
      })
    );
  }

  deleteConfirmation(title: string): Observable<boolean> {
    return this.simpleConfirmation(title, 'This action cannot be undone. Are you sure?', {
      confirmText: 'Delete',
    });
  }

  textDialog(title: string, text: string) {
    let dialog = this.dialogService.open({
      title,
      content: TextDialogComponent,
    });

    let comp: TextDialogComponent = dialog.content.instance;
    comp.text = text;

    return dialog.result;
  }

  colorPickerPrompt(title: string, label: string, startingValue?: string) {
    const actions: { text: string; themeColor?: string; primary?: boolean }[] = [
      {
        text: 'Cancel',
      },
    ];
    if (!!startingValue) {
      actions.push({
        text: 'Clear',
      });
    }
    actions.push({
      text: 'Set',
      themeColor: 'primary',
      primary: true,
    });
    let dialog = this.dialogService.open({
      title,
      content: ColorPickerPromptComponent,
      width: 240,
      actions,
    });

    let comp: ColorPickerPromptComponent = dialog.content.instance;
    comp.label = label;
    comp.value = startingValue || '#000000';

    return dialog.result.pipe(
      map((res) => {
        if ('text' in res && res.text === 'Clear') return false;
        if (res instanceof DialogCloseResult || res.text !== 'Set') return null;
        return comp.value;
      })
    );
  }

  htmlDialog(title: string, content: string, actions?: any[] | TemplateRef<any> | DialogAction[]) {
    let dialog = this.dialogService.open({
      title,
      content: HTMLDialogComponent,
      actions,
    });
    let comp: HTMLDialogComponent = dialog.content.instance;
    comp.html = content;

    return dialog.result;
  }
}
