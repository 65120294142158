import { Injectable } from '@angular/core';
import { dateGreaterThan, getToday } from 'src/lib/helperFunctions';
import {
  AdvanceTypes,
  ApprovalStatus,
  CommonApprovalTypes,
  ContractClass,
  ContractType,
  Currency,
  DraftLine,
  LinePriceType,
  MarketValuationHeader,
  PhysicalDraft,
  PhysicalDraftExpense,
} from 'src/lib/newBackendTypes';
import { toUTCDate } from 'src/lib/toUTCDate';
import { BusinessLogicService } from './business-logic-service';
import { CommonDataService } from './common-data.service';

const requiredTraderApprovals: CommonApprovalTypes[] = [CommonApprovalTypes.DIT, CommonApprovalTypes.CONTACT_APPROVAL];
const requiredCounterpartyApprovals: CommonApprovalTypes[] = [
  CommonApprovalTypes.DIT,
  CommonApprovalTypes.CONTACT_APPROVAL,
  CommonApprovalTypes.WORLD_COMPLIANCE,
  CommonApprovalTypes.FINANCE_APPROVAL,
  CommonApprovalTypes.TRADING,
];

@Injectable()
export class ConvertContractValidationService {
  constructor(private businessLogicService: BusinessLogicService, private commonData: CommonDataService) {}

  requiredDraftFields: { [field in keyof PhysicalDraft]?: string } = {
    companyId: 'Company',
    traderId: 'Trader',
    counterpartyId: 'Counterparty',
    type: 'Type',
    incotermId: 'Incoterm',
    class: 'Class',
    productId: 'Product',
    date: 'Date',
    paymentTermId: 'Payment Term',
    expectedNumberOfLoads: 'Expected Number Of Loads',
    tolerance: 'Tolerance',
    currencyId: 'Price Currency',
    quantityUnitId: 'Quantity Unit',
    priceUnitId: 'Price Unit',
    advanceType: 'Advance Type',
    estimatedMargin: 'Estimated Margin',
  };

  requiredLineFields: { [field in keyof DraftLine]?: string } = {
    itemId: 'Item',
    quantity: 'Quantity',
    shipmentPeriodType: 'Shipment Period Type',
    shipmentPeriodStart: 'Shipment Period Start',
    shipmentPeriodEnd: 'Shipment Period End',
    incotermPlaceId: 'Incoterm Place',
    originCountryId: 'Material Origin',
    destinationId: 'Destination',
    priceType: 'Price Type',
  };

  requiredExpenseFields: { [field in keyof PhysicalDraftExpense]?: string } = {
    budgetElementId: 'Budget Element',
    amount: 'Amount',
    currencyId: 'Currency',
    byPacking: 'Price',
  };

  async validateDraft(draft: PhysicalDraft): Promise<(string | string[])[] | false> {
    let errors: (string | string[])[] = [];

    //All required data is available
    if (draft.lines === undefined) {
      errors.push(`Cannot find draft line data`);
    }
    if (draft.expenses === undefined) {
      errors.push(`Cannot find draft expense data`);
    }
    if (draft.keywords === undefined) {
      errors.push(`Cannot find draft keyword data`);
    }
    if (draft.clauses === undefined) {
      errors.push(`Cannot find clause data`);
    }
    if (draft.class === ContractClass.H || draft.class === ContractClass.QP) {
      if (!draft.lines || draft.lines.some((l) => l.mtmValuationId === undefined)) {
        errors.push(`Cannot find line mtm data`);
      }
    }
    if (draft.trader === undefined && !!draft.traderId) {
      errors.push(`Cannot find trader data`);
    } else if (!!draft.traderId && draft.trader.approvals === undefined) {
      errors.push(`Cannot find trader approval data`);
    }
    if (!!draft.counterpartyId && draft.counterparty === undefined) {
      errors.push(`Cannot find counterparty data`);
    } else if (!!draft.counterpartyId && draft.counterparty.approvals === undefined) {
      errors.push(`Cannot find counterparty approval data`);
    }

    if (
      draft.lines &&
      draft.lines.some((l) => {
        return l.priceType === LinePriceType.FORMULA && l.pricing === undefined;
      })
    ) {
      errors.push(`Cannot find pricing data`);
    }

    let units = this.commonData.staticUnits.getValue();
    let quantityUnit = draft.quantityUnitId ? units.find((u) => draft.quantityUnitId === u.unitId) : null;
    if (!!draft.quantityUnitId && (!quantityUnit || !quantityUnit.unitFactors || quantityUnit.unitFactors.length === 0)) {
      errors.push(`Cannot find unit data for quantityUnit`);
    }

    if (errors.length > 0) {
      errors.unshift(`Cannot validate draft, data is missing`);
      return errors;
    }

    let valuations = this.commonData.staticMarketValuations.getValue() || [];
    let premiumValuations = this.commonData.staticPremiumValuations.getValue() || [];
    let approvalTypes = this.commonData.staticApprovalTypes.getValue() || [];

    //Account for contract dates being off due to UTC timezone
    let today = toUTCDate(getToday());
    let contractDate = draft.date ? new Date(draft.date) : null;

    //required fields
    for (let field in this.requiredDraftFields) {
      let value = draft[field];
      if (value === null) {
        errors.push(`${this.requiredDraftFields[field]} is required`);
      }
    }

    //conditionally required fields
    if (draft.advanceType !== AdvanceTypes.NO_ADVANCE) {
      if (draft.advanceValue === null) {
        errors.push('Advance Value is required');
      }
    }
    if (
      draft.class !== ContractClass.NH &&
      draft.lines.some((l) => {
        return l.premium !== 0;
      })
    ) {
      if (draft.premiumUnitId === null) {
        errors.push('Premium Unit is required');
      }
    }

    if (!!contractDate && dateGreaterThan(today, contractDate)) {
      errors.push(`Contract date cannot be earlier than today`);
    }

    if (draft.type === ContractType.SALE && draft.collateral === null) {
      errors.push(`Collateral is required`);
      if (draft.collateral < 0 || draft.collateral > 100) {
        errors.push(`Collateral must be between 0 and 100`);
      }
    }

    //approvals
    if (!!draft.traderId) {
      let missingTraderApprovals = requiredTraderApprovals.filter((at) => !draft.trader.approvals.some((a) => a.approvalTypeId === at && a.status === ApprovalStatus.Y));
      if (missingTraderApprovals.length > 0) {
        let missingApprovalNames = missingTraderApprovals.map((a) => {
          let type = approvalTypes.find((at) => at.id === a);
          return type ? type.name : `Approval Type ${a}`;
        });
        errors.push(`Trader is not an approved Contact, missing the following approvals:`);
        errors.push(missingApprovalNames);
      }
    }
    if (!!draft.counterpartyId) {
      let missingCounterpartyApprovals = requiredCounterpartyApprovals.filter((at) => !draft.counterparty.approvals.some((a) => a.approvalTypeId === at && a.status === ApprovalStatus.Y));
      if (missingCounterpartyApprovals.length > 0) {
        let missingApprovalNames = missingCounterpartyApprovals.map((a) => {
          let type = approvalTypes.find((at) => at.id === a);
          return type ? type.name : `Approval Type ${a}`;
        });
        errors.push(`Counterparty is not an approved Contact, missing the following approvals:`);
        errors.push(missingApprovalNames);
      }
    }

    //lines
    if (draft.lines.length <= 0) {
      errors.push(`At least one line is required`);
    }
    for (let i in draft.lines) {
      let lineErrors: string[] = [];
      let line = draft.lines[i];

      for (let field in this.requiredLineFields) {
        let value = line[field];
        if (value === null) {
          lineErrors.push(`${this.requiredLineFields[field]} is required`);
        }
      }

      if (!!contractDate && dateGreaterThan(contractDate, new Date(line.shipmentPeriodStart))) {
        lineErrors.push(`Shipment Start Date cannot be earlier than Contract Date`);
      }
      if (line.shipmentPeriodStart && line.shipmentPeriodEnd && dateGreaterThan(new Date(line.shipmentPeriodStart), new Date(line.shipmentPeriodEnd))) {
        lineErrors.push(`Shipment End Date cannot be earlier than Shipment Start Date`);
      }

      if (!line.containerTypes || line.containerTypes.length === 0) {
        lineErrors.push(`At least one Container Type is required`);
      }

      if (draft.type === ContractType.SALE && draft.class && draft.class !== ContractClass.NH) {
        if (line.metalPercentage === null) {
          lineErrors.push(`Metal Unit Percentage is required`);
        }
      }
      if (draft.class === ContractClass.H || draft.class === ContractClass.QP) {
        if (line.mtmValuationId === null) {
          lineErrors.push(`MTM is required`);
        } else {
          let mtmMarket: MarketValuationHeader = line.marketValuation || this.commonData.staticMarketValuations.value.find((v) => v.valuationId === line.mtmValuationId);
          let currency: Currency = this.commonData.staticCurrencies.value.find((c) => c.id === draft.currencyId);
          if (!!mtmMarket && currency) {
            if (mtmMarket.currencyId !== currency.id && line.givenFX === null) {
              lineErrors.push(`Foreign Exchange Rate is required`);
            }
          }
        }
      }
      if (line.priceType === LinePriceType.FIXED) {
        if (line.price === null || line.price <= 0) {
          lineErrors.push(`Price Amount is required and must be greater than 0`);
        }
        if (draft.class === ContractClass.H || draft.class === ContractClass.QP) {
          if (line.hedgePrice === null || line.hedgePrice === 0) {
            lineErrors.push(`Hedge Price is required and must be greater than 0`);
          }
        }
      }
      if (line.priceType === LinePriceType.FORMULA) {
        if (draft.class === ContractClass.NH) {
          lineErrors.push(`Line cannot be Formula if class is No Hedge`);
        }
        if (line.pricing.length > 2) {
          lineErrors.push(`Line has more than 2 pricing lines`);
        }
        if (line.pricing.length === 0) {
          lineErrors.push(`Line has no pricing lines`);
        }
        let markets: number = 0;
        let premium: number = 0;
        for (let pdp of line.pricing) {
          if (pdp.percentage < 0) {
            lineErrors.push(`Market percentage cannot be negative`);
          }
          if (pdp.marketValuationId === null) {
            lineErrors.push(`Missing Valuation Id in price line`);
          } else {
            let market = valuations.find((v) => v.valuationId === pdp.marketValuationId);
            if (market !== undefined) {
              markets++;

              if (draft.class === ContractClass.QP) {
                if (pdp.qpStartDate === null) {
                  lineErrors.push(`QP Start Date is required`);
                } else if (!!contractDate && dateGreaterThan(contractDate, new Date(pdp.qpStartDate))) {
                  lineErrors.push(`QP Start Date cannot be earlier than Contract date`);
                }
                if (pdp.qpEndDate === null) {
                  lineErrors.push(`QP End Date is required`);
                }
                if (pdp.qpStartDate && pdp.qpEndDate && dateGreaterThan(new Date(pdp.qpStartDate), new Date(pdp.qpEndDate))) {
                  lineErrors.push(`QP Start Date cannot be later than QP End Date`);
                }
                if (pdp.qpStatus === null) {
                  lineErrors.push(`QP Status is required`);
                }
              }
            } else {
              let market = premiumValuations.find((p) => p.valuationId === pdp.marketValuationId);
              if (market !== undefined) {
                premium++;
              }
            }
          }
        }
        if (markets === 0) {
          lineErrors.push(`Market is required`);
        }
        if (markets > 1) {
          lineErrors.push(`Invalid market used for premium`);
        }
        if (premium > 1) {
          lineErrors.push(`Invalid market used for non-premium price line`);
        }
      }

      if (lineErrors.length > 0) {
        errors.push(`Line ${line.lineNumber}:`);
        errors.push(lineErrors);
      }
    }
    for (let i in draft.expenses) {
      let expense = draft.expenses[i];
      let expenseErrors: string[] = [];
      for (let field in this.requiredExpenseFields) {
        let value = expense[field];
        if (value === null) {
          expenseErrors.push(`${this.requiredExpenseFields[field]} is required`);
        }
      }
      if (expenseErrors.length > 0) {
        errors.push(`Expense ${i}:`);
        errors.push(expenseErrors);
      }
    }

    for (let i = draft.clauses.length - 1; i >= 0; i--) {
      const c = draft.clauses[i];
      if (draft.clauses.findIndex((dc) => dc.type === c.type) !== i) {
        errors.push(`Duplicate clause types`);
      }
    }

    if (draft.quantityUnitId) {
      let totalQuantity = draft.lines.map((l) => l.quantity * quantityUnit.unitFactors[0].factor).reduce((prev, curr, i, arr) => prev + (curr || 0), 0);
      if (totalQuantity > 10000) {
        errors.push(`Abnormally high total quantity exceeding 10000 MT`);
      } else if (totalQuantity <= 0) {
        errors.push(`Quantity cannot be 0`);
      }
    }

    if (draft.expectedNumberOfLoads < 1) {
      errors.push('Number of Loads must be at least 1');
    } else {
      let { max, min } = this.businessLogicService.getNumberOfLoadsRange(draft);
      if (draft.expectedNumberOfLoads > max || draft.expectedNumberOfLoads < min) {
        errors.push('The number of loads is not within an acceptable range');
      }
    }

    if (errors.length > 0) return errors;
    else return false;
  }
}
