import { ChunkType } from './container';
import { ContractClass } from './contract';

export class ShipmentPurchaseSaleData {
  shipmentId: number;
  chunkType: ChunkType;
  purchaseChunkKey: number | null;
  purchaseDeclarationKey: number | null;
  purchaseContractKey: number | null;
  purchaseContractNumber: number | null;
  purchaseContractClass: ContractClass | null;
  supplierAlfCode: number | null;
  supplierName: string | null;
  purchaseInvoiceKey: number | null;
  purchaseInvoiceNumber: number | null;
  purchasePaymentTerm: string | null;
  saleChunkKey: number | null;
  saleDeclarationKey: number | null;
  saleContractKey: number | null;
  saleContractNumber: number | null;
  saleContractClass: ContractClass | null;
  customerAlfCode: number | null;
  customerName: string | null;
  saleInvoiceKey: number | null;
  saleInvoiceNumber: number | null;
  purchaseElementKey: number;
  supplierIncoterm: string | null;
  supplierIncotermPlace: string | null;
  saleElementKey: number;
  customerIncoterm: string | null;
  customerIncotermPlace: string | null;
  salePaymentTerm: string | null;
}
