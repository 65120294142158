import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { AdvanceType, UpdateAdvanceRequest } from 'src/lib/newBackendTypes/advance';
import { Advance } from 'src/lib/newBackendTypes/advance';
import { simpleConfirmation } from '../flexDynamicForm';
import { advancePrefill } from './printAdvance';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, submitFormCallback } from './types';
import { of } from 'rxjs';
import _ from 'lodash';

export const confirmMarkAdvance: openFormCallback<Advance> = (delegate, id, prefill, column) => {
  const title = prefill.archived === YN.Y ? `Mark as Open` : `Mark as Closed`;
  const content = prefill.archived === YN.Y ? `This Advance is currently closed.  Do you wish to mark as open?` : `This Advance is currently open.  Do you wish to mark as closed?`;

  return simpleConfirmation<Advance>(title, content)(delegate, id, prefill, column);
};

const markAdvanceCheck: checkPrefillCallback<Advance> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.archived === YN.Y && _.isNil(prefill.dueDate)) return of("This Advance can NOT be open because it doesn't have a Due Date");
  return of((prefill.type && [AdvanceType.CLIENT_ADVANCE, AdvanceType.PAYMENT_ADVANCE].includes(prefill.type)) || 'This Letter of Credit is not an Advance');
};

export const markAdvanceCallback: submitFormCallback<Advance> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');

  const request: UpdateAdvanceRequest = {
    id,
    archived: prefill.archived === YN.Y ? YN.N : YN.Y,
  };

  return api.rpc<Advance>(endpoints.updateAdvance, request, null);
};

export const markAdvancePreset: DynamicFormConstant<Advance> = {
  allowMultipleRows: false,
  checkPrefill: markAdvanceCheck,
  openForm: confirmMarkAdvance,
  endpoints: [endpoints.getAdvance, endpoints.updateAdvance],
  entityType: SourceEntityType.ADVANCE_KEY,
  getPrefill: advancePrefill,
  label: 'Open/Close Advance',
  submitForm: markAdvanceCallback,
  title: 'Open/Close Advance',
  value: DynamicFormType.MARK_ADVANCE,
  width: 300,
};
