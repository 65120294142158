import { Subset } from '../generics';
import { PhysicalContract } from './contract';
import { PhysicalDraft } from './draft';
import { YN } from './enums';

export enum CarriageType {
  BUYER = 'B',
  SELLER = 'S',
}
export enum IncotermInvoicingType {
  ORIGIN = 'O',
  DELIVERED = 'D',
}
export type Incoterm = {
  id: number;
  name: string;
  sequenceNumber: number | null;
  carriage: YN;
  insuranceIncluded: string | null;
  includeInCotation: string | null;
  titleTransferDocType: number | null;
  interestDuringTransit: number | null;
  invoicingType: IncotermInvoicingType;
  contracts?: PhysicalContract[];
  drafts?: PhysicalDraft[];
};
export type CreateIncotermRequest = Subset<Incoterm, 'carriage' | 'name', 'invoicingType'>;
export type UpdateIncotermRequest = Pick<Incoterm, 'id'> & Partial<CreateIncotermRequest>;
