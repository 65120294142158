<div class="ag-cell-label-container ag-header-cell-sortable">
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" (click)="menuClicked($event)">
    <span class="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span>
  </span>
  <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on" (click)="columnClicked($event)">
    <span ref="eText" class="ag-header-cell-text">{{ name }}</span>
    <span ref="eFilter" [ngClass]="{ 'ag-hidden': !hasFilter }" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"
      ><span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span
    ></span>
    <span ref="eSortOrder" [ngClass]="{ 'ag-hidden': !sortIndex }" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true">{{ sortIndex }}</span>
    <span ref="eSortAsc" [ngClass]="getSortClass('asc')" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"
      ><span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span
    ></span>
    <span ref="eSortDesc" [ngClass]="getSortClass('desc')" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"
      ><span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span
    ></span>
  </div>
</div>
