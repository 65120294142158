import { SourceEntityType } from './entityType';
import { enumValues } from '../generics';

export enum DocumentType {
  BUSINESS_LICENSE = 'Business License',
  TAX_ID_W9 = 'Tax ID/W-9',
  COMMERCIAL_REFERENCE = 'Commerical Reference',
  FINAL_BENEFICIARY_LIST = 'Final Beneficiary List',
  ISO_CERTIFICATE = 'ISO Certificate',
  CONTACT_FORM = 'Contact Form',
  PAYMENT_INFORMATION = 'Payment Information',
  BL_TEMPLATE = 'BL Template',

  //Discrepancy
  WEIGHT_TICKET = 'Weight Ticket',
  ANALYSIS = 'Analysis',
  EVIDENCE_PICTURES = 'Evidence Pictures',

  //Contract
  SIGNED_CONTRACT = 'Signed Contract',

  //Booking
  PACKING_LIST = 'PACKING_LIST',
  VGM = 'VGM',
  CONTAINER_EMPTY = 'Container - Empty',
  CONTAINER_25 = 'Container - 25%',
  CONTAINER_50 = 'Container - 50%',
  CONTAINER_75 = 'Container - 75%',
  CONTAINER_100 = 'Container - 100%',
  CONTAINER_SEALS = 'Container Seals',
  SUPPL_BOOKING_REQ = 'Suppl Booking Req',
  CARRIER_BOOKING_REQ = 'Carrier Booking Req',

  // Service Order
  BL_DRAFT = 'BL Draft',
  INVOICE = 'Invoice',
  EMAIL = 'E-mail',

  OTHER = 'Other',
}

export const DocumentTypes = enumValues(DocumentType);

export const ContactDocumentTypes: DocumentType[] = [
  DocumentType.BUSINESS_LICENSE,
  DocumentType.TAX_ID_W9,
  DocumentType.COMMERCIAL_REFERENCE,
  DocumentType.FINAL_BENEFICIARY_LIST,
  DocumentType.ISO_CERTIFICATE,
  DocumentType.CONTACT_FORM,
  DocumentType.PAYMENT_INFORMATION,
  DocumentType.BL_TEMPLATE,
  DocumentType.OTHER,
];

export const DiscrepancyDocumentTypes: DocumentType[] = [DocumentType.WEIGHT_TICKET, DocumentType.ANALYSIS, DocumentType.EVIDENCE_PICTURES, DocumentType.OTHER];

export const ContractDocumentTypes: DocumentType[] = [DocumentType.SIGNED_CONTRACT, DocumentType.OTHER];

export const BookingDocumentTypes: DocumentType[] = [
  DocumentType.PACKING_LIST,
  DocumentType.VGM,
  DocumentType.CONTAINER_EMPTY,
  DocumentType.CONTAINER_25,
  DocumentType.CONTAINER_50,
  DocumentType.CONTAINER_75,
  DocumentType.CONTAINER_100,
  DocumentType.CONTAINER_SEALS,
  DocumentType.OTHER,
  DocumentType.SUPPL_BOOKING_REQ,
  DocumentType.CARRIER_BOOKING_REQ,
];

export const ServiceOrderDocumentTypes: DocumentType[] = [DocumentType.INVOICE, DocumentType.BL_DRAFT, DocumentType.EMAIL, DocumentType.OTHER];

export const EntityDocumentTypeMap: Map<SourceEntityType, DocumentType[]> = new Map([
  [SourceEntityType.ALF_CODE, ContactDocumentTypes],
  [SourceEntityType.FREIGHT_BOOKING_KEY, BookingDocumentTypes],
  [SourceEntityType.CONTRACT_KEY, ContractDocumentTypes],
  [SourceEntityType.DISCREPANCY_ID, DiscrepancyDocumentTypes],
  [SourceEntityType.SERVICE_ORDER_KEY, ServiceOrderDocumentTypes],
]);

export type DocumentData = {
  documentKey: number;
  documentName: string;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  isSelected: string | null;
  useInDcb: string | null;
  printingType: number | null;
  templatePath: string | null;
  typeId: number | null;
};
