import { endpoints } from './apiEndpoints';

export type PostFilter = (value: any, index: number, array: any[]) => boolean;

export interface IDropdownConfig<T> {
  listProcedure: endpoints;
  labelField: keyof T;
  valueField: keyof T;
  secondaryLabelFields?: (entry: T) => string;
  labelTransform?: (entry: T) => string;
  relationFilters?: any[];
  additionalFilters?: any;
  postFilter?: PostFilter;
  take?: number;
  orderBy?: { fieldName: keyof T; order: 'ASC' | 'DESC' };
  newItems?: { items: Partial<T>[]; atStart: boolean };
}

function isDropdownConfig<T>(config: any): config is IDropdownConfig<T> {
  return !!config && !!config.labelField && !!config.valueField && !!config.listProcedure;
}

export class DropdownConfig<T> {
  listProcedure: endpoints;
  labelField: keyof T;
  valueField: keyof T;
  secondaryLabelFields?: (entry: T) => string;
  labelTransform?: (entry: T) => string;
  relationFilters?: any[];
  additionalFilters?: any;
  postFilter?: PostFilter;
  take?: number;
  orderBy?: { fieldName: keyof T; order: 'ASC' | 'DESC' };
  newItems?: { items: Partial<T>[]; atStart: boolean };

  /**
   * @param config An object containing options for the dropdown config
   * @param listProcedure The api endpoint for the list data
   * @param labelField The property to display
   * @param valueField The unique key property to identify each result by
   * @param relationFilters
   * @param additionalFilters
   * @param postFilter A callback function to filter the results after they have been retrieved from the backend
   * @param labelTransform A callback function that accepts the object and returns a string to display
   * @param secondaryLabelFields A callback function that accepts the object and returns a string to display
   * @param newItems An array type of T with hardcoded data to push into response array
   */
  constructor(config: IDropdownConfig<T>);
  constructor(listProcedure: endpoints, labelField: keyof T, valueField: keyof T);
  constructor(
    listProcedure: endpoints,
    labelField: keyof T,
    valueField: keyof T,
    relationFilters?: any[],
    additionalFilters?: any,
    postFilter?: PostFilter,
    secondaryLabelFields?: (T) => string,
    labelTransform?: (T) => string,
    take?: number,
    newItems?: { items: Partial<T>[]; atStart: boolean }
  );
  constructor(
    listProcedureOrConfig: endpoints | IDropdownConfig<T>,
    labelField?: keyof T,
    valueField?: keyof T,
    relationFilters?: any[],
    additionalFilters?: any,
    postFilter?: PostFilter,
    secondaryLabelFields?: (T) => string,
    labelTransform?: (T) => string,
    take: number = 20,
    newItems?: { items: Partial<T>[]; atStart: boolean }
  ) {
    if (isDropdownConfig<T>(listProcedureOrConfig)) {
      this.listProcedure = listProcedureOrConfig.listProcedure;
      this.labelField = listProcedureOrConfig.labelField;
      this.valueField = listProcedureOrConfig.valueField;
      this.secondaryLabelFields = listProcedureOrConfig.secondaryLabelFields;
      this.orderBy = listProcedureOrConfig.orderBy;
      this.relationFilters = listProcedureOrConfig.relationFilters || null;
      this.additionalFilters = listProcedureOrConfig.additionalFilters || null;
      this.postFilter = listProcedureOrConfig.postFilter;
      this.labelTransform = listProcedureOrConfig.labelTransform;
      this.take = listProcedureOrConfig.take ?? 20;
      this.newItems = listProcedureOrConfig.newItems;
    } else {
      this.listProcedure = listProcedureOrConfig;
      this.labelField = labelField;
      this.valueField = valueField;
      this.secondaryLabelFields = secondaryLabelFields;
      this.relationFilters = relationFilters || null;
      this.additionalFilters = additionalFilters || null;
      this.postFilter = postFilter;
      this.labelTransform = labelTransform;
      this.take = take;
      this.newItems = newItems;
    }
  }
}
