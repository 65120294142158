import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import * as fontObserver from 'fontfaceobserver';
import { combineLatest, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { endpoints } from 'src/lib/apiEndpoints';
import { CommonDataService } from '../services/common-data.service';
import { DynamicMenusService } from '../services/dynamic-menu.service';
import { GlobalConfigService } from '../services/global-config.service';
import { Store } from '../services/store.service';

@Injectable()
export class MasterResolver implements Resolve<any> {
  constructor(private commonData: CommonDataService, private config: GlobalConfigService, private store: Store, private menu: DynamicMenusService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userEndpoints = this.store.snapshot((state) => state.user.userEndpoints);
    const admin = userEndpoints.includes(endpoints.listConfigurationsAdmin);
    let font = new fontObserver('font-awesome');
    let promise = font?.load();
    if (promise && promise.then && typeof promise.then === 'function') {
      return combineLatest([
        from(promise),
        this.commonData.dataLoaded(),
        this.config.loaded().pipe(
          tap((integrityVerified) => {
            if (!integrityVerified) {
              if (!admin) {
                window.open('assets/error.html?errorCode=config', '_self');
              } else {
                this.menu.adminConfigMode();
              }
            }
          }),
          map((integrityVerified) => integrityVerified || admin)
        ),
      ]);
    }

    return this.commonData.dataLoaded();
  }
}
