import { broadcastChannel, channelId } from 'src/app/core/components/app/app.component';

export function newTabWithData(path: string, data: any) {
  let channel;
  try {
    channel = new BroadcastChannel(broadcastChannel);
  } catch (e) {
    channel = null;
  }
  if (channel) {
    let closed = false;
    channel.onmessage = (ev) => {
      console.log(path, ev.data.path);
      if (
        ev.data?.message === 'Init' &&
        ev.data?.id !== channelId &&
        ev.data.path &&
        //remove leading and trailing slashes to avoid mismatch
        ev.data.path.replace(/^\/|\/$/g, '') === path.replace(/^\/|\/$/g, '')
      ) {
        channel.postMessage({ message: 'PersistData', data, id: channelId, target: ev.data?.id });
        channel.close();
        closed = true;
      }
    };
    setTimeout(() => {
      if (!closed) {
        closed = true;
        channel.close();
      }
    }, 10000);
  }

  window.open(path);
}
