import { Pipe, PipeTransform } from '@angular/core';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { Unit } from 'src/lib/newBackendTypes';

@Pipe({
  name: 'quantityUnit',
  pure: true,
})
export class QuantityUnitPipe implements PipeTransform {
  constructor(private dataFormatter: DataFormattingService) {}

  transform(quantity: number, unit: Unit | number | string): string {
    return this.dataFormatter.quantityUnitFormatter(quantity, unit);
  }
}
