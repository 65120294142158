import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Nullable } from './generics';

export class TypedFormGroup<T, Y extends formMask<T> = formMask<T>> extends UntypedFormGroup {
  constructor(controls: { [key in keyof T]: Y[key] }, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  patchValue(value: { [key in keyof T]?: T[key] }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }
}

type formMask<T> = {
  [key in keyof T]: AbstractControl;
};

export class TypedFormArray<T, Y extends formMask<T> = formMask<T>> extends UntypedFormArray {
  patchValue(value: Partial<T>[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }

  constructor(controls: TypedFormGroup<T, Y>[], validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  push(control: TypedFormGroup<T, Y>) {
    super.push(control);
  }
}

export class TypedFormControl<T> extends UntypedFormControl {
  setValue(
    value: Nullable<T>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    super.setValue(value, options);
  }

  patchValue(
    value: Partial<Nullable<T>>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    super.patchValue(value, options);
  }
}

export enum FormControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
}
