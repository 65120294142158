import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { Permissions } from 'src/lib/componentPermissions';
import { dollarAmountFormat } from 'src/lib/helperFunctions';
import { SaleInvoiceData } from 'src/lib/newBackendTypes/invoice';
import { randomFetchSynonym } from 'src/lib/uiConstants';

@Component({
  selector: 'invoice-popup',
  templateUrl: './invoice-popup.component.html',
  styleUrls: ['./invoice-popup.component.scss'],
})
@Permissions('Invoice Popup', [endpoints.getShipmentInvoiceInformation])
export class InvoicePopupComponent implements OnInit {
  invoiceId: number;
  invoices: InvoiceConverted[] = [];

  constructor(private api: ThalosApiService, private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    if (this.invoiceId) {
      let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Invoice Data');
      this.api.rpc<ListResponse<SaleInvoiceData>>(endpoints.getShipmentInvoiceInformation, { filters: { invoiceId: this.invoiceId } }, null).subscribe((val) => {
        const res = val.list;
        for (const i of res) {
          this.invoices.push({
            ...i,
            unitPriceString: dollarAmountFormat(i.unitPrice),
            totalPriceString: dollarAmountFormat(i.totalPrice),
          });
        }
        this.spinnerService.completeRequest(rid);
      });
    }
  }
}

export type InvoiceConverted = SaleInvoiceData & {
  unitPriceString: string;
  totalPriceString: string;
};
