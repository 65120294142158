import { AfterViewInit, Component } from '@angular/core';
import { DropdownConfig } from 'src/lib';
import { AgGridCellEditor } from 'src/lib/agGridEditor';

@Component({
  selector: 'grid-async-dropdown-editor',
  templateUrl: 'async-dropdown.component.html',
})
export class GridAsyncDropdownEditorComponent<T> extends AgGridCellEditor implements AfterViewInit {
  get value() {
    let labelField = this.config.labelField;
    return this._value ? this._value[labelField] : '';
  }

  config!: DropdownConfig<T>;

  isPopup() {
    return true;
  }
}
