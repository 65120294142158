import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListQuery } from 'src/lib/generics';
import { ListResponse } from 'src/lib/ListResponse';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { SpinnerService } from '../services/spinner.service';
import { ThalosApiService } from '../services/thalos-api.service';

@Injectable()
export class EntityListResolver implements Resolve<any> {
  constructor(private api: ThalosApiService, private spinnerService: SpinnerService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let endpoint: endpoints = route.data.endpoint;

    if (!endpoint) return [];

    let args: ListQuery<any> = { filters: {}, take: 0 };

    if (route.data.relations) {
      args['relations'] = route.data.relations;
    }
    if (route.data.filters) {
      args['filters'] = route.data.filters;
    }

    if (route.data.orderBy) {
      args.orderBy = route.data.orderBy;
    }

    let rId = this.spinnerService.startRequest(randomFetchSynonym() + ' Data');
    let data = await lastValueFrom(this.api.rpc<ListResponse<any>>(endpoint, args, { count: 0, list: [] }));
    this.spinnerService.completeRequest(rId);

    let res = [];
    if (data && data.list) res = data.list;

    return res;
  }
}
