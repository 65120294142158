import { ListColumn, ListColumnResponse } from './listColumn';
import { ListViewUserGroupAssignment } from '../flex/flexViewUserGroupAssignment';
import { enumOptionsFromEnum } from '../helperFunctions';
import { SystemObject } from '../newBackendTypes/systemObject';
import { ListLayout } from './listLayout';
import { ListLayoutDefault } from './listLayoutDefault';
import { ListFilterGroup, ListFilterGroupResponse } from './listFilters';

export type ListViewResponse = {
  id: number;
  name: string;
  viewName: string;
  menuLocation: string;
  gridConfiguration: string;
  filterStrategy: FilterStrategy;
  schemaName: string;
  menuOrder: number | null;
  note: string;
  columns?: ListColumnResponse[];
  filterGroups?: ListFilterGroupResponse[];
  view?: SystemObject;
  layouts?: ListLayout[];
  layoutDefaults?: ListLayoutDefault[];
};

export type ListView = Omit<ListViewResponse, 'gridConfiguration' | 'columns' | 'filterGroups'> & {
  gridConfiguration: ListGridConfig;
  columns: ListColumn[];
  filterGroups: ListFilterGroup[];
  defaultLayout?: ListLayout;
  authorizedGroups?: ListViewUserGroupAssignment[];
};

export type ListGridConfig = {
  toolbars: ListToolbar[];
};

export enum ListToolbar {
  COLUMNS = 1,
  FILTERS = 2,
}
export const ListToolbars = enumOptionsFromEnum(ListToolbar);

export enum FilterStrategy {
  NO_FILTER = 1,
  USE_DEFAULT = 2,
  PROMPT_USER = 3,
}
export const FilterStrategies = enumOptionsFromEnum(FilterStrategy);

export type CreateFlexViewRequest = Omit<ListViewResponse, 'columns' | 'filterGroups' | 'id'>;
export type UpdateFlexViewRequest = Partial<CreateFlexViewRequest> & Pick<ListViewResponse, 'id'>;
