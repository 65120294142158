import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Mexp from 'math-expression-evaluator';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { conditionalValidators } from 'src/lib/genericValidators';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { TypedFormGroup } from 'src/lib/typedForms';
import { FormElementComponent } from '../form-elements/form-element/form-element.component';

@UntilDestroy()
@Component({
  selector: 'math-calculator',
  templateUrl: './math-calculator.component.html',
})
export class MathCalculatorComponent implements OnInit, OnDestroy, ModalFormComponent<number | string | null, MathCalcForm> {
  @ViewChild('first', { static: false })
  first: FormElementComponent;

  outputType: 'number' | 'string' | null = null;

  form: TypedFormGroup<MathCalcForm>;

  popup = true;

  isInvalid = false;

  constructor() {
    this.form = new TypedFormGroup<MathCalcForm>({
      expression: new UntypedFormControl(''),
      output: new UntypedFormControl(
        '',
        conditionalValidators(() => this.outputType !== null, Validators.required, this.expressionValidator())
      ),
    });

    this.form
      .get('expression')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((v: string) => {
        if (v === null) {
          this.form.patchValue({ output: null });
        } else {
          const value = v.toString();
          let output;

          try {
            output = Mexp.eval(value.toLocaleLowerCase());
          } catch (e) {
            if (typeof e === 'string') output = e;
            else output = 'Not a number';
          }

          this.form.patchValue({ output });
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (this.first) {
      setTimeout(() => {
        this.first.focus();
      });
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  expressionValidator() {
    return (c: AbstractControl) => {
      if (this.isInvalid) {
        return { error: true };
      }
      if (this.outputType === 'number') {
        return isNaN(c.value) ? { custom: 'Not a number' } : null;
      }
      return null;
    };
  }

  prefillForm(v) {
    this.form.patchValue(v);
  }

  submit() {
    return this.form.value.output;
  }
}

export type MathCalcForm = {
  expression: string;
  output: string;
};
