import { Component, ContentChildren, EventEmitter, QueryList, forwardRef } from '@angular/core';
import { CollapsibleCardComponent } from '../collapsible-card/collapsible-card.component';

@Component({
  selector: 'lazy-card',
  templateUrl: '../collapsible-card/collapsible-card.component.html',
  styleUrls: ['../collapsible-card/collapsible-card.component.scss'],
  providers: [{ provide: CollapsibleCardComponent, useExisting: forwardRef(() => LazyCardComponent) }],
})
export class LazyCardComponent extends CollapsibleCardComponent {
  @ContentChildren('lazy', { emitDistinctChangesOnly: false })
  lazyContent: QueryList<ILazyContent>;

  loaded = false;

  set isOpen(v: boolean) {
    this._isOpen = v;
    if (v && !this.loaded) {
      this.loaded = true;
      this.load.emit(true);
      this.lazyContent.forEach((c) => {
        if (!!c && 'willDeload' in c) {
          c.willDeload = false;
        }
      });
    } else if (!v && this.loaded) {
      this.lazyContent.forEach((c) => {
        if (!!c && 'willDeload' in c) {
          c.willDeload = true;
        }
      });
    }
  }
  get isOpen() {
    return this._isOpen;
  }

  load: EventEmitter<boolean> = new EventEmitter();

  reset() {
    this.isOpen = false;
    this.loaded = false;
    this.load.emit(false);
  }

  ngOnInit(): void {}

  constructor() {
    super();

    this._isOpen = false;
  }
}

export class ILazyContent {
  willDeload: boolean;
}
