import { Component, Input, ViewChild, Output, forwardRef, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { UserGroup } from 'src/lib/newBackendTypes';
import { FormElementComponent } from '../form-element/form-element.component';
import { ListFilterGroupUserGroupAssignment } from 'src/lib/flex/flexFilterGroupUserGroupAssignment';

@UntilDestroy()
@Component({
  selector: 'user-group-multiselect',
  templateUrl: './user-group-multiselect.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => UserGroupMultiselectComponent) }],
})
export class UserGroupMultiselectComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  private dropdown: MultiSelectComponent;

  @Input() copyBtn: boolean = false;

  @Output()
  authorizedGroups = new EventEmitter<{ groups: { cn: string }[] }>();

  filteredOptions: UserGroup[];
  filterObservable: BehaviorSubject<string>;

  fetching = false;

  constructor(controlDir: NgControl, store: Store, private api: ThalosApiService) {
    super(controlDir, store);

    this.filterObservable = new BehaviorSubject('');
    this.filteredOptions = [];
  }

  ngOnInit(): void {
    if (!!this.value) this.value = this.value;

    this.initFilterSubscriber().subscribe((list) => {
      this.filteredOptions = list;
    });
  }

  protected initFilterSubscriber() {
    return this.filterObservable.asObservable().pipe(
      filter((text) => text !== null),
      switchMap((text: string) => {
        this.fetching = true;
        return this.api.rpc<UserGroup[]>(endpoints.findGroups, { query: text === '' ? '*' : `*${text}*` }, []);
      }),
      catchError((error) => {
        this.fetching = false;

        throw error;
      }),
      tap(() => {
        this.fetching = false;
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.dropdown)
      this.dropdown.filterChange
        .asObservable()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged((a, b) => a === b),
          debounceTime(300)
        )
        .subscribe((text) => {
          this.handleFilter(text);
        });
  }

  protected handleFilter(event) {
    this.filterObservable.next(event);
    return this.filterObservable;
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  comparator(a: any[] | null, b: any[] | null) {
    if (!a && !b) return true;
    if ((!a && !!b) || (!!a && !b)) return false;
    return _.isEqual(
      a.map((o) => o['cn']),
      b.map((o) => o['cn'])
    );
  }

  copyAuthorizedGroups() {
    const authorizedGroupsControl = this.value;
    if (authorizedGroupsControl && authorizedGroupsControl.length > 0) {
      const authorizedGroups = authorizedGroupsControl.map((item: ListFilterGroupUserGroupAssignment) => item.cn);
      navigator.clipboard.writeText(authorizedGroups);
    } else {
      navigator.clipboard.writeText('');
    }
  }

  pasteHandler(event: ClipboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    const authorizedGroups: { cn: string }[] = [];
    const clipboardData = event.clipboardData.getData('Text');
    if (clipboardData === '') return;
    const splitData = clipboardData.trim().split(',');
    splitData.forEach((item) => authorizedGroups.push({ cn: item }));
    this.authorizedGroups.emit({ groups: authorizedGroups });
  }
}
