import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { PayOrderHeader, PayOrderStatus } from 'src/lib/newBackendTypes/payOrder';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import _ from 'lodash';

const unbookingPayOrderPrefill: prefillCallback<UnbookPayOrderPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const payOrdersIds = Array.isArray(id) ? id : [id];
      let api = delegate.getService('api');

      const payOrdersResponse = (await api.run(endpoints.listPayOrders, {
        filters: { id: payOrdersIds },
      })) as ListResponse<PayOrderHeader>;
      const items = payOrdersResponse.list;
      return { items };
    })()
  );
};

const unbookingPayOrderCheck: checkPrefillCallback<UnbookPayOrderPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  for (let item of prefill.items) {
    if (item.payOrderStatus !== PayOrderStatus.BOOKED) return of('Unable to unbook pay order(s): some pay order has already been unbooked.');
    if (!item.payOrderLines) return of('Unable to unbook pay order(s): some pay order(s) lines are not available.');
    if (item.payOrderLines.length < 1) return of('Unable to unbook pay order(s): some pay order does not have any lines.');
  }
  return of(true);
};

const unbookingPayOrderForm: createFormCallback<UnbookPayOrderPrefill, UnbookPayOrderRequest> = (delegate, id, prefill) => {
  const payOrders = Array.isArray(prefill.items) ? prefill.items : [prefill.items];

  return [
    {
      type: 'Label',
      text: `Unbook Pay Order(s): ${prefill ? 'ID(s): ' + payOrders.map((item) => item.id).join(', ') : 'Unknown'}`,
    },
  ];
};

const unbookingPayOrderCallback: submitFormCallback<UnbookPayOrderPrefill, UnbookPayOrderRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader[]>((resolve, reject) => {
      (async () => {
        if (prefill.items.length === 0) throw new Error('No pay orders to be unbooked');

        const unbookPayOrderResponses: PaymentHeader[] = [];
        for (const item of prefill.items) {
          const request: UnbookPayOrderRequest = {
            id: item.id,
          };

          try {
            unbookPayOrderResponses.push(await api.run<PaymentHeader>(endpoints.unbookPayOrder, request, null));
          } catch (err) {
            return reject(err);
          }
        }
        if (unbookPayOrderResponses) return resolve(unbookPayOrderResponses);
        return reject('Unknown result. Please check if the pay order(s) were unbooked and try again if necessary.');
      })();
    })
      .then((res) => {
        if (!!res && Array.isArray(res) && res.length > 0) {
          const payments = res.filter((item) => !_.isNil(item));
          if (payments.length > 0)
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">Pay Order(s) successfully unbooked: \n Payment(s) # deleted: \n${payments.map((payment) => `- ${payment.documentReference}`).join(`\n`)}</div>`
            );
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const unbookingPayOrderPreset: DynamicFormConstant<UnbookPayOrderPrefill, UnbookPayOrderRequest> = {
  allowMultipleRows: true,
  checkPrefill: unbookingPayOrderCheck,
  createForm: unbookingPayOrderForm,
  entityType: SourceEntityType.PAYABLE_ITEM_KEY,
  getPrefill: unbookingPayOrderPrefill,
  label: 'Unbook Pay Order',
  submitForm: unbookingPayOrderCallback,
  title: 'Unbook Pay Order',
  value: DynamicFormType.UNBOOKING_PAY_ORDER,
  endpoints: [endpoints.listPayOrders, endpoints.unbookPayOrder],
  width: 400,
};

export type UnbookPayOrderPrefill = {
  items: PayOrderHeader[];
};
export type UnbookPayOrderRequest = { id: number };
