import { ContractLine, FutureMarket, MarketValuationHeader, MarketValuationLine, PropertyDocument, VoucherList, YN } from '.';
import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { FxRatesFixation } from './fxRatesFixation';
import { InvoiceHeader } from './invoice';
import { ServiceOrder } from './serviceOrder';
import { Contact } from './contact';
import { ShipmentsAndFixationsFromInvoice } from './shipmentsAndFixationsFromInvoice';

export class PcPriceFixingLink {
  fixationId: number;
  allocationId: number | null;
  shipmentId: number | null;
  quantityFixed: number;
  lastUpdate: Date | null;
  modifiedBy: string | null;
  versionNb: number | null;
  muQtyFixed: number | null;
  phQtyFixed: number | null;
  fixation?: PcPriceFixing;
  shipment?: PropertyDocument;
}

export enum FixationEmailStatus {
  NOT_EMAIL_REQUIRED = 1,
  EMAIL_REQUIRED = 2,
  EMAIL_SENT = 3,
}

export type PcPriceFixing = {
  id: number;
  contractId: number;
  elementNumber: number | null;
  declarationKey: null;
  chunkKey: number | null;
  fixDate: Date;
  autoFix: YN;
  fixed: YN;
  discard: YN;
  pricePercentage: number | null;
  bradyQuantityFixed: number;
  quantityDeclared: number;
  marketKey: number;
  priceTermKey: number;
  valuationId: number;
  promptDate: Date;
  fixedPrice: number | null;
  termPrice: number;
  fullPremium: number | null;
  fixedContractPrice: number;
  exchangeRate: number | null;
  numberOfLots: number | null;
  fixedBy: string | null;
  unitConversion: number | null;
  unitRate: number | null;
  fixType: number | null;
  currRate: number | null;
  fxPrice: number | null;
  fxPremium: number | null;
  manualFixationMode: number | null;
  useLeftOverFutureQty: YN | null;
  leftOverFutQtyComment: string | null;
  quantityFixed: number | null;
  createdBy: number;
  createdAt: Date;
  updatedBy: number | null;
  updatedAt: Date | null;
  emailStatus: FixationEmailStatus;
  metalUnits: number | null;
  metalUnitsPercentage: number | null;
  links?: PcPriceFixingLink[];
  // netPositionLines?: NetPositionLines[];
  futureMarket?: FutureMarket;
  marketValuation?: MarketValuationHeader;
  marketValuationLine?: MarketValuationLine;
  contractLine?: ContractLine;
  fxRateFixation?: FxRatesFixation;
};

export enum FixationSendEmailOption {
  SCHEDULED_CONFIRMATION = 1,
  EXPRESS_CONFIRMATION = 2,
  DO_NOT_SEND_EMAIL = 3,
}

export const FixationSendEmailOptions = enumOptionsFromEnum(FixationSendEmailOption);

export type CreatePriceFixationRequest = Subset<
  PcPriceFixing,
  'contractId' | 'valuationId' | 'quantityFixed' | 'fixDate' | 'termPrice' | 'elementNumber' | 'leftOverFutQtyComment' | 'fixedContractPrice' | 'metalUnits' | 'metalUnitsPercentage'
> & { fixationRate?: number; sendEmail?: FixationSendEmailOption };
export type UpdatePriceFixationRequest = Subset<
  PcPriceFixing,
  'id',
  'quantityFixed' | 'fixDate' | 'termPrice' | 'leftOverFutQtyComment' | 'fixedContractPrice' | 'metalUnits' | 'metalUnitsPercentage'
> & { fixationRate?: number };
export type DeletePriceFixationRequest = Subset<PcPriceFixing, 'id'>;
export type ApplyUnapplyPriceFixationRequest = { id: number; shipmentIdArr: number[]; metalUnits?: number; metalUnitsPercentage?: number; sendEmail?: FixationSendEmailOption };
export type ApplyResponse = {
  fixationLinkArr: PcPriceFixingLink[];
  shipments: PropertyDocument[];
  voucherArr: InvoiceHeader[];
};
export type UnapplyResponse = {
  id: number;
  affectedServiceOrders: Pick<ServiceOrder, 'id' | 'orderPrice' | 'currencyId'>[];
  shipmentIdArr: PropertyDocument[];
};
export type ApplyUnapplyFixationToInvoicePrefill = {
  invoiceInformation: VoucherList[];
  shipments: ShipmentsAndFixationsFromInvoice[];
  counterparty?: Contact;
};
