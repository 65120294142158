import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { lastValueFrom, of } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { DropdownConfig, ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { conditionalValidators } from 'src/lib/genericValidators';
import { markFormGroupTouchedAsync } from 'src/lib/helperFunctions';
import { CommonSteelTypeGroups, Item, QualityType } from 'src/lib/newBackendTypes';
import { ItemAnalyticGroup } from 'src/lib/newBackendTypes/itemAnalyticGroup';
import { ItemReportingGroup } from 'src/lib/newBackendTypes/itemReportingGroup';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'thalos-item',
  templateUrl: './item.component.html',
})
export class ItemComponent implements ModalFormComponent<ItemForm, Item> {
  popup = true;
  form: TypedFormGroup<ItemForm>;
  type: 'client' | 'royce' | 'internal';

  qualityDropdown = new DropdownConfig<QualityType>({
    listProcedure: endpoints.listQualityTypes,
    valueField: 'qualityTypeKey',
    labelField: 'qualityTypeName',
    postFilter: (qualityType) => {
      return qualityType.qualityTypeKey !== 0;
    },
  });
  reportingGroupDropdown = new DropdownConfig<ItemReportingGroup>({
    listProcedure: endpoints.listItemReportingGroups,
    valueField: 'id',
    labelField: 'name',
  });
  analyticGroupDropdown = new DropdownConfig<ItemAnalyticGroup>({
    listProcedure: endpoints.listItemAnalyticGroups,
    valueField: 'id',
    labelField: 'name',
  });

  constructor(private api: ThalosApiService) {
    this.form = new TypedFormGroup<ItemForm>({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      qualityType: new UntypedFormControl(null, Validators.required),
      reportingGroup: new UntypedFormControl(null),
      analyticGroup: new UntypedFormControl(null),
      metalUnitPercentage: new UntypedFormControl(null),
    });
  }

  ngOnInit(): void {
    const groupTypeId = this.type === 'royce' ? CommonSteelTypeGroups.DEFAULT : CommonSteelTypeGroups.CLIENT;
    this.form.get('name').setAsyncValidators([this.uniqueNameAndGroupValidator(groupTypeId, this.form.value.id)]);

    const requiredIfRoyceItem = conditionalValidators(() => this.type === 'royce', Validators.required);
    this.form.get('reportingGroup').setValidators([requiredIfRoyceItem]);
    this.form.get('analyticGroup').setValidators([requiredIfRoyceItem]);
  }

  async allowSubmit() {
    await lastValueFrom(markFormGroupTouchedAsync(this.form));
    return this.form.valid;
  }

  prefillForm(item: Item) {
    this.form.patchValue(item);
  }

  submit() {
    return this.form.value;
  }

  uniqueNameAndGroupValidator(groupTypeId: number, existingId?: number): (AbstractControl) => Promise<ValidationErrors> {
    return async (control: AbstractControl) => {
      const name: string = control.value;
      if (!name) return of(null);
      const response = await this.api.run<ListResponse<Item>>(endpoints.listItems, { filters: { name } }, { count: 0, list: [] });
      const list = response.list;
      const listFiltered = list.filter(
        (item) => (!!existingId && item.id !== existingId && item.groupTypeId === groupTypeId && item.name === name) || (!existingId && item.groupTypeId === groupTypeId && item.name === name)
      );
      return !!listFiltered.length ? { custom: 'This name already exists' } : null;
    };
  }
}

export type ItemForm = Pick<Item, 'name' | 'id' | 'qualityType' | 'reportingGroup' | 'analyticGroup' | 'metalUnitPercentage'>;
