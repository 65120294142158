import { AccIntegrationByOriginHeader } from './accIntegrationByOriginHeader';
import { ContactTypeAssignment } from './contactTypeAssignment';

export enum CommonContactTypes {
  ADMINISTRATIVE = 119230,
  AGENT = 113496,
  AUXILIARY = 100091,
  BANK = 119234,
  BROKERAGE = 119232,
  CLAIMS = 50553675,
  CONSIGNEE = 27024325,
  CUSTOMER = 20115267,
  DIT = 50553676,
  DOMESTIC = 101580,
  EMPLOYEE = 20115272,
  EXCLUSIVE_AGENT = 23297843,
  EXTERNAL_STAFF = 27024326,
  FACG = 50553672,
  HEDGING = 50557466,
  INTERNATIONAL = 118425,
  INTRACOMPANY = 101582,
  LOG_CUSTOMS_BROKERS = 118317,
  LOG_FUMIGATION = 119251,
  LOG_INSPECTIONS = 118323,
  LOG_OTHER_VENDOR = 118366,
  LOG_SHIPPING_COMPANY = 118321,
  LOG_TERMINALS = 118420,
  LOG_TRUCKING_COMPANY = 118319,
  LOG_TRANSPORT_INTERNATIONAL = 50696673,
  LOG_WAREHOUSE = 118325,
  LOGISTICS = 50553673,
  MATERIAL = 1909212,
  MET_CONTROL = 50553674,
  METAL_CONTROL = 50557303,
  NOTIFY_PARTY = 27024208,
  OTHER_VENDORS = 20153377,
  RISK_MANAGMENT = 10603540,
  ROYCE_FINANCE_ACCOUNTING = 119258,
  ROYCE_HEDGING = 119254,
  ROYCE_OPERATORS = 113498,
  TRADER = 113494,
  SUPPLIER = 20115265,
}

export enum ContactTypes {
  TRADER = 'Trader',
}

export type ContactType = {
  id: number;
  name: string;
  ownerAlfCode: number;
  allowRead: string;
  contactTypeAssignments?: ContactTypeAssignment[];
  accountIntegrationHeaders?: AccIntegrationByOriginHeader[];
};
export type CreateContactTypeRequest = Pick<ContactType, 'name'>;
export type UpdateContactTypeRequest = Pick<ContactType, 'id'> & Partial<CreateContactTypeRequest>;
