import { Subset } from '../generics';
import { DocumentPacketTemplate } from './documentPacket';
import { SourceEntityType } from './entityType';

export class DocumentTemplate {
  id: number;
  url: string;
  name: string;
  sourceEntityTypeId: SourceEntityType;
  type: DocumentTemplateType;
  packetAssignments?: DocumentPacketTemplate[];
}

export enum DocumentTemplateType {
  PDF = 'PDF',
  EXCELOPENXML = 'EXCELOPENXML',
  WORDOPENXML = 'WORDOPENXML',
}

export type CreateDocumentTemplateRequest = Subset<DocumentTemplate, 'url' | 'name' | 'sourceEntityTypeId' | 'type'>;
export type UpdateDocumentTemplateRequest = Subset<DocumentTemplate, 'id', 'url' | 'name' | 'sourceEntityTypeId' | 'type'>;
