import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'table-field',
  templateUrl: './table-field.component.html',
  styleUrls: ['./table-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableFieldComponent extends FormFieldComponent implements OnInit {
  @Input()
  forceLabel: boolean = false;

  constructor() {
    super();
  }
}
