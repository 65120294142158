import { Validators } from '@angular/forms';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { PropertyDocument, SourceEntityType } from 'src/lib/newBackendTypes';
import { createFormCallback, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const setSalePriceForm: createFormCallback<Pick<PropertyDocument, 'sellPrice'>> = (delegate, id) => {
  return [_fe('sellPrice', 'Sale Price', { minimumFractionDigits: 2, maximumFractionDigits: 4, fieldType: 2 }, null, [Validators.required])];
};

const setSalePriceCallback: submitFormCallback<Pick<PropertyDocument, 'sellPrice'>> = (delegate, id, result) => {
  let api = delegate.getService('api');

  return api.rpc<PropertyDocument>(endpoints.setSalePrice, { shipmentId: id, price: result.sellPrice }, null);
};

const setSalePricePrefill: prefillCallback<PropertyDocument> = (delegate, id) => {
  let api = delegate.getService('api');

  return api.rpc<PropertyDocument>(endpoints.getShipment, { filters: { id } }, null);
};

export const setSalePricePreset = {
  label: 'Set Sale Price',
  value: DynamicFormType.UPDATE_SHIPMENT_SALE_PRICE,
  entityType: SourceEntityType.CHUNK_KEY,
  width: 300,
  createForm: setSalePriceForm,
  submitForm: setSalePriceCallback,
  getPrefill: setSalePricePrefill,
  endpoints: [endpoints.setSalePrice, endpoints.getShipment],
};
