import { Pipe, PipeTransform } from '@angular/core';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { Currency } from 'src/lib/newBackendTypes';

@Pipe({
  name: 'amountCurrency',
  pure: true,
})
export class AmountCurrencyPipe implements PipeTransform {
  constructor(private dataFormatter: DataFormattingService) {}

  transform(amount: number, currency: Currency | number | string, decimalPlaces?: number): string {
    return this.dataFormatter.amountCurrencyFormatter(amount, currency, decimalPlaces);
  }
}
