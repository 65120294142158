import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roco-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent implements OnInit {
  public error: string = 'placeholder';
  errorFooter: string;
  validationErrors: { path: string; cause: string }[];

  constructor() {}

  ngOnInit(): void {}
}
