import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SplitButtonComponent } from '@progress/kendo-angular-buttons';
import { map } from 'rxjs/operators';
import { ListFilterService } from 'src/app/core/services/list-filter.service';
import { endpoints } from 'src/lib/apiEndpoints';

@Component({
  selector: 'splitbutton-wrapper',
  templateUrl: './splitbutton-wrapper.component.html',
  styleUrls: ['./splitbutton-wrapper.component.scss'],
})
export class SplitbuttonWrapperComponent implements OnInit {
  @ViewChild('dropdown', { static: false })
  dropdown: SplitButtonComponent;

  @Input()
  endpoint: endpoints;

  @Input()
  textField: string;

  @Input()
  itemDisabled?: (any) => boolean;

  @Output()
  buttonClick?: EventEmitter<any> = new EventEmitter();

  options: any[];

  constructor(private listFilterService: ListFilterService) {
    this.options = [];
  }

  ngOnInit(): void {
    this.listFilterService
      .listData(this.endpoint, { filters: {}, take: 0 })
      .pipe(map((res) => res.list))
      .subscribe((list) => {
        this.options = list;
      });
  }

  click(event: any) {
    this.buttonClick.emit(event);
  }

  mainButtonClick() {
    this.dropdown.togglePopupVisibility();
  }
}
