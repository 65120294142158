import { LetterOfCredit } from './letterOfCredit';

export class RedClausePayment {
  lcId: number | null;
  id: number | null;
  dueDate: Date;
  paymentAmount: number;
  paymentQuantity: number;
  estimatedPaymentDate: Date;
  paymentUnitPrice: number;
  paymentId: number | null;
  modifiedBy: string;
  modifyDate: Date;
  versionNb: number;
  linkedPayment: number | null;
  redLcPaymentIdBack: number | null;
  advance?: LetterOfCredit;
}
