import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Route } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListResponse, StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListViewResponse } from 'src/lib/views';
import { newFlexRoute } from 'src/lib/flex/flexRoutes';
import { MenuItem } from 'src/lib/MenuItem';
import { UserGroupsEnum, FlexViewIcon, FolderIcon } from 'src/lib/uiConstants';
import { CALCULATED_DYNAMIC_MENUS, LOADED_MISSING_ENDPOINTS, LOADED_MISSING_GRAPHQLS, LOADED_USER_ENDPOINTS, LOADED_USER_GRAPHQLS, SET_FLAT_MENU } from '../reducers/actions';
import { EntityLookupService } from './entity-lookup.service';
import { Store } from './store.service';
import { ThalosApiService } from './thalos-api.service';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';

@UntilDestroy()
@Injectable()
export class DynamicMenusService implements OnDestroy {
  userEndpoints: endpoints[];
  userGraphqls: ExistingGraphql[];
  fullMenu: MenuItem[];
  flatMenu: MenuItem[];
  public flexViews: ListViewResponse[];

  get entityLookup() {
    return this.injector.get(EntityLookupService);
  }
  get store() {
    return this.injector.get(Store);
  }
  get api() {
    return this.injector.get(ThalosApiService);
  }

  storeSubscription: StoreSubscription<{ userEndpoints: endpoints[]; fullMenu: MenuItem[]; userGraphqls: ExistingGraphql[] }>;

  constructor(private injector: Injector) {
    this.userEndpoints = [];
    this.userGraphqls = [];
    this.fullMenu = [];
    this.flatMenu = [];

    this.storeSubscription = this.store.subscribe(
      (state) => ({ userEndpoints: state.user.userEndpoints, fullMenu: state.layout.fullMenu, userGraphqls: state.user.userGraphqls }),
      [LOADED_USER_ENDPOINTS, CALCULATED_DYNAMIC_MENUS, LOADED_USER_GRAPHQLS]
    );
    this.storeSubscription.$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.userEndpoints = state.userEndpoints;
      this.userGraphqls = state.userGraphqls;
      this.fullMenu = state.fullMenu;
    });
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }

  async buildPermittedMenu(): Promise<Route[]> {
    this.flatMenu = [];
    const flexViews = this.userEndpoints.some((ue) => ue === endpoints.listFlexViews)
      ? (await lastValueFrom(this.api.rpc<ListResponse<ListViewResponse>>(endpoints.listFlexViews, { filters: {} }, { count: 0, list: [] }))).list
      : [];
    const menuItems = _.cloneDeep(this.fullMenu);
    const portalRoutes: Route[] = [];
    this.flexViews = flexViews;
    for (const fv of flexViews) {
      const menuNames = fv.menuLocation.split('/');

      if (menuNames.length === 0) continue;

      const workingMenuNames = menuNames.map((n) => n);

      let currentDir: MenuItem[] = menuItems;
      while (workingMenuNames.length > 0) {
        const current = workingMenuNames.shift();

        if (workingMenuNames.length > 0) {
          let newFolder: MenuItem;
          newFolder = currentDir.find((mi) => mi.title.toLocaleLowerCase() === current.toLocaleLowerCase());
          if (!newFolder) {
            newFolder = {
              title: current,
              hidden: false,
              regexPath: new RegExp(`/portal/flex`),
              requirements: [],
              children: [],
              icon: FolderIcon,
            };
            currentDir.push(newFolder);
          } else if (!newFolder.children) {
            newFolder.children = [];
          }
          currentDir = newFolder.children;
        } else {
          const sanitizedString = fv.menuLocation.replace(/ /g, '-').toLowerCase();
          const fullPath = `/portal/${sanitizedString}`;
          const mItem: MenuItem = {
            title: current,
            hidden: false,
            regexPath: new RegExp(fullPath + '$'),
            link: `${fullPath}`,
            requirements: [endpoints.getFlexViewData, endpoints.getFlexView, endpoints.listFlexFilterGroups, endpoints.listFlexViewLayouts],
            flexView: fv.id,
            order: fv.menuOrder,
            icon: FlexViewIcon,
            children: [],
          };
          currentDir.push(mItem);
          const route = newFlexRoute(fv, sanitizedString);
          portalRoutes.push(route);
        }
      }
    }

    const userEndpoints = this.userEndpoints.map((ep) => {
      return ep;
    });

    const userGraphqls = this.userGraphqls.map((ep) => {
      return ep;
    });

    const permittedMenu = this._builPermittedMenu(menuItems, userEndpoints, userGraphqls);

    this.store.dispatch({
      type: CALCULATED_DYNAMIC_MENUS,
      payload: permittedMenu,
    });
    this.store.dispatch({
      type: SET_FLAT_MENU,
      payload: this.flatMenu,
    });
    return portalRoutes;
  }

  private _builPermittedMenu(menuItems: MenuItem[], userEndpoints: string[], userGraphqls: string[]): MenuItem[] {
    const permittedItems: MenuItem[] = [];

    if (environment.devMode) return menuItems;
    for (const item of menuItems) {
      let permittedChildren: MenuItem[] = [];

      const isPermitted = this.checkRequirements(item.requirements, userEndpoints) && this.checkRequirements(item.graphQlrequirements, userGraphqls);
      if (item.children !== undefined) {
        permittedChildren = this._builPermittedMenu(item.children, userEndpoints, userGraphqls);
      }

      item.children = permittedChildren;
      const allowed = isPermitted || (isPermitted === null && permittedChildren.length > 0);
      if (allowed) {
        permittedItems.push(item);
        this.flatMenu.push(item);
        if (item.entitySource && item.entityType) {
          this.entityLookup.setEntityRoute(item.entitySource, item.entityType, item);
        }
        if (item.flexView) {
          this.entityLookup.setFlexViewRoute(item.flexView, item);
        }
        if (item.featureRoute) {
          this.entityLookup.setFeatureRoute(item.featureRoute, item);
        }
      }

      if (!allowed && !!item.requirements) {
        console.log(`${item.title} is NOT allowed!`);

        const diff = arr_diff(item.requirements, userEndpoints).filter((el) => item.requirements.includes(el));

        this.store.dispatch({ type: LOADED_MISSING_ENDPOINTS, payload: { [item.title]: diff } });
        console.log('Missing endpoints', diff);
      }
      if (!allowed && !!item.graphQlrequirements) {
        console.log(`${item.title} is NOT allowed!`);

        const graphqlDiff = arr_diff(item.graphQlrequirements, userGraphqls).filter((el) => item.graphQlrequirements.includes(el));

        this.store.dispatch({ type: LOADED_MISSING_GRAPHQLS, payload: { [item.title]: graphqlDiff } });
        console.log('Missing graphqls', graphqlDiff);
      }
    }

    return permittedItems.sort((a, b) => {
      return (a.order || 0) - (b.order || 0);
    });
  }

  private checkRequirements(requirements: string[], userEndpoints: string[]): boolean {
    if (!requirements) return true;

    const isPermitted = requirements.every((route) => {
      return userEndpoints.includes(route);
    });

    return isPermitted;
  }

  public adminConfigMode() {
    this.store.dispatch({
      type: CALCULATED_DYNAMIC_MENUS,
      payload: this.fullMenu.filter((m) => m.title === UserGroupsEnum.DIT || m.title === 'Home'),
    });
  }
}

function arr_diff(a1: string[], a2: string[]) {
  a1.sort();
  a2.sort();

  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (const k in a) {
    diff.push(k);
  }

  return diff;
}
