<div class="iframe-wrapper" *ngIf="!!path && type === 'iframe'">
  <iframe [hidden]="" [src]="path" frameborder="0"></iframe>
</div>
<div class="image-wrapper" *ngIf="!!path && type === 'image'">
  <div class="image-inner-wrapper">
    <img [src]="path" [ngStyle]="{ 'max-width': zoom * 100 + '%', 'max-height': zoom * 100 + '%' }" />
  </div>
  <div class="zoom">{{ zoom }}x</div>
  <div class="zoom-out"><span class="fas fa-search-minus" (click)="zoomOut()"></span></div>
  <div class="zoom-in"><span class="fas fa-search-plus" (click)="zoomIn()"></span></div>
</div>
<div *ngIf="!!path && type === 'unsupported'" class="text">
  <div>Cannot preview this file type</div>
  <div>
    <a [href]="path" target="_blank" [download]="fileName" rel="noopener noreferrer">Download</a>
  </div>
</div>
<div *ngIf="!path && !!file" class="text"><span>Loading...</span></div>
