<div class="card desktop-h-100 entity-card">
  <div class="card-header d-flex">
    <h4 class="flex-grow-1"><i [ngClass]="GenericListIcon"></i> Advances to Open</h4>
  </div>
  <div class="card-body" style="display: flex; flex-direction: column">
    <form class="row form-group" [formGroup]="filterForm">
      <vertical-field class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" [name]="'Contract Type'">
        <simple-dropdown cyData="type" [data]="contractTypes" valueField="value" textField="label" [valuePrimitive]="true" [formControlName]="'contractType'"> </simple-dropdown>
      </vertical-field>
      <vertical-field class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12" name="">
        <button type="submit" kendoButton (click)="fetchData()">Search</button>
      </vertical-field>
    </form>
    <div class="form-divider-line"></div>
    <div class="grid-header">
      <button kendoButton type="button" (click)="autosizeColumns()" title="Auto size columns" [icon]="'arrows-resizing'"></button>
      <div class="grid-header__divider"></div>
      <input kendoTextBox [(ngModel)]="quickFilterText" (input)="quickFilter(quickFilterText)" class="w-auto" />
      <button kendoButton type="button" [icon]="'filter-clear'" (click)="resetFilters()" title="Clear local grid filters"></button>
    </div>
    <ag-grid-angular style="width: 100%; flex-grow: 1" class="ag-theme-balham" [rowData]="data" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"> </ag-grid-angular>
  </div>
</div>
