<kendo-multiselect
  [hidden]="data?.length === 0 || !!readonly"
  [data]="data"
  [textField]="'approverName'"
  [valueField]="'approverId'"
  [filterable]="true"
  [(ngModel)]="_value"
  (valueChange)="onChange(value)"
  [readonly]="readonly"
>
  <ng-template kendoMultiSelectTagTemplate let-dataItem>
    <span class="template">{{ dataItem.approver?.displayName || dataItem.approverId }}</span
    ><span *ngIf="dataItem?.sentEmail" title="Email Sent" class="k-icon k-i-email"></span>
  </ng-template>

  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <span class="template">{{ dataItem.approver?.displayName || dataItem.approverId }}</span
    ><span *ngIf="dataItem?.sentEmail" title="Email Sent" class="k-icon k-i-email"></span>
  </ng-template>
</kendo-multiselect>

<ul style="list-style: none; padding-inline-start: 0" [hidden]="!readonly">
  <li *ngIf="!!approvedBy">
    <span>{{ status | approvalCode }} by {{ approvedBy?.displayName }}</span>
  </li>
  <ng-container *ngFor="let approver of existingRequests">
    <li *ngIf="!approvedBy || approvedBy.id !== approver.approverId">
      <span>{{ approver.approver?.displayName || approver.approverId }}</span
      ><span class="k-icon k-i-email" *ngIf="approver.sentEmail"></span>
    </li>
  </ng-container>
</ul>

<span [hidden]="data?.length > 0 || !!readonly">No approvers found</span>
