import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { ServiceOrder, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { multipleGroupByArray } from 'src/lib/helperFunctions';

const deleteServiceOrderPrefill: prefillCallback<DeleteServiceOrderPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const serviceOrderIds = Array.isArray(id) ? id : [id];
      const serviceOrderResponses: ServiceOrder[] = [];
      for (const id of serviceOrderIds) {
        const serviceOrderInfo = await api.run<ServiceOrder>(endpoints.getServiceOrder, { filters: { id: id } }, null);
        serviceOrderResponses.push(serviceOrderInfo);
      }

      return { serviceOrderResponses };
    })()
  );
};

const deleteServiceOrderCheck: checkPrefillCallback<DeleteServiceOrderPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.serviceOrderResponses.length === 0) return of('No Service Orders to be deleted.');
  for (let item of prefill.serviceOrderResponses) {
    if (!item) return of(`Service order to be deleted not found`);
    if (item.completelyInvoiced === YN.Y) return of(`Unable to delete invoiced Service Order ${item.id}`);
  }
  return of(true);
};

const deleteServiceOrderForm: createFormCallback<DeleteServiceOrderPrefill, DeleteServiceOrderForm> = (delegate, id, prefill) => {
  const serviceOrders = Array.isArray(prefill.serviceOrderResponses) ? prefill.serviceOrderResponses : [prefill.serviceOrderResponses];
  const groupSOByCounterparty = multipleGroupByArray<ServiceOrder>(serviceOrders, (property: ServiceOrder) => [property.counterpartyId]);
  const labels: { type: 'Label'; text: string; style?: object }[] = [
    {
      type: 'Label',
      text: `You are going to delete the following Service Order(s):`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px', display: 'flex', 'justify-content': 'center', color: 'red' },
    },
  ];
  for (const counterpartyGroup of groupSOByCounterparty) {
    const sumsByCurrencyId = counterpartyGroup.reduce((acc, item) => {
      const { currencyId, orderPrice } = item;
      if (acc[currencyId]) acc[currencyId] += orderPrice;
      else acc[currencyId] = orderPrice;
      return acc;
    }, {});

    const dataFormatter = delegate.getService('dataFormatter');
    const sumsByCurrenciesArray = Object.entries(sumsByCurrencyId).map(([currencyId, total]: [string, number]) => ({
      currencyId: parseInt(currencyId),
      amount: dataFormatter.amountCurrencyFormatter(total, parseInt(currencyId)),
    }));

    labels.push({
      type: 'Label',
      text: `<b>${counterpartyGroup.length}</b> Service Order(s) from Counterparty <b>${counterpartyGroup[0].contact.displayName}</b> with a Total Amount of: <br/>${sumsByCurrenciesArray
        .map((item) => `<b> - ${item.amount}</b><br/>`)
        .join('')}`,
      style: { 'margin-bottom': '15px' },
    });
  }
  labels.push({ type: 'Label', text: `Are you sure to delete the Service Order(s)?`, style: { 'font-weight': 'bold', display: 'flex', 'justify-content': 'center' } });
  return labels;
};

const deleteServiceOrderCallback: submitFormCallback<DeleteServiceOrderPrefill, DeleteServiceOrderForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<{ successSODeleted: ServiceOrder[]; failedSOIds: number[] }>((resolve, reject) => {
      (async () => {
        const successSODeleted: ServiceOrder[] = [];
        const failedSOIds: number[] = [];

        for (let res of prefill.serviceOrderResponses) {
          const deleteServiceOrderRequest: { filters: { id: number } } = { filters: { id: res.id } };

          const serviceOrderDeleted = await api.run<ServiceOrder>(endpoints.deleteServiceOrder, deleteServiceOrderRequest, null, { blockRedirect: true, hideErrorPopup: true });
          if (serviceOrderDeleted !== null) successSODeleted.push(serviceOrderDeleted);
          else failedSOIds.push(res.id);
        }

        if (successSODeleted || failedSOIds) return resolve({ successSODeleted, failedSOIds });
        return reject('Unknown result. Please check if the Service Order(s) were deleted and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const successMessage = 'Service Order(s) successfully deleted:';
          const errorMessage = 'An error ocurred with the following Service Order(s). Could not delete:';
          const successIds = res.successSODeleted.map((so) => `- ${so.id}`).join(`\n`);
          const failedIds = res.failedSOIds.map((sof) => `- ${sof}`).join(`\n`);
          if (res.successSODeleted && res.successSODeleted.length > 0 && res.failedSOIds && res.failedSOIds.length > 0)
            return prompt.htmlDialog('Warning', `<div style="white-space: pre">${successMessage} \n${successIds} \n\n${errorMessage} \n${failedIds}</div>`);
          if (res.successSODeleted && res.successSODeleted.length > 0 && !(res.failedSOIds && res.failedSOIds.length > 0))
            return prompt.htmlDialog('Success', `<div style="white-space: pre">${successMessage} \n${successIds} </div>`);
          if (!(res.successSODeleted && res.successSODeleted.length > 0) && res.failedSOIds && res.failedSOIds.length > 0)
            return prompt.htmlDialog('Error', `<div style="white-space: pre">${errorMessage} \n${failedIds}</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const deleteServiceOrderPreset: DynamicFormConstant<DeleteServiceOrderPrefill, DeleteServiceOrderForm> = {
  allowMultipleRows: true,
  checkPrefill: deleteServiceOrderCheck,
  createForm: deleteServiceOrderForm,
  entityType: SourceEntityType.SERVICE_ORDER_KEY,
  getPrefill: deleteServiceOrderPrefill,
  label: 'Delete Service Order',
  submitForm: deleteServiceOrderCallback,
  title: 'Delete Service Order',
  value: DynamicFormType.DELETE_SERVICE_ORDER,
  endpoints: [endpoints.deleteServiceOrder, endpoints.getServiceOrder],
  width: 500,
};

export type DeleteServiceOrderPrefill = {
  serviceOrderResponses: ServiceOrder[];
};
export type DeleteServiceOrderForm = {
  serviceOrderId: number;
};
