<div class="form-container" [formGroup]="form">
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field name="Value Date" class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'valueDate'" [readonly]="false"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Posting Bank" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'bank'" [dropdownConfig]="bankDropdown" [readonly]="false"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Bank Account" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'account'" [dropdownConfig]="accountDropdown" [readonly]="false"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Payment Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="amount" [fieldType]="1" [format]="amountFormat" #amountField> </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
      <vertical-field name="Requested Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="requestedAmount" [fieldType]="1" [format]="amountFormat" [readonly]="true" #amountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
      <vertical-field name="Balance Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="balancedAmount" [fieldType]="1" [format]="amountFormat" [readonly]="true" #amountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Fees'">
    <form-table [formArray]="form.get('fees')" noDataMessage="No fees added">
      <ng-template #tableHeader>
        <div class="col-form-label col-form-label-sm col-lg-2 col-md-4 col">Expense Type</div>
        <div class="col-form-label col-form-label-sm col-lg-2 col-md-4 col">Amount</div>
        <div class="col-md-1">
          <button type="button" kendoButton tabindex="-1" class="invisible" [icon]="'trash'" hideIfReadonlyMode></button>
        </div>
      </ng-template>
      <ng-template #tableRow let-fee="element" let-i="i">
        <table-field class="col-12 d-inline-flex d-md-none justify-content-end">
          <button kendoButton type="button" (click)="removeFee(i)" [icon]="'trash'" hideIfReadonlyMode></button>
        </table-field>
        <table-field [name]="'Expense Type'" class="col-lg-2 col-md-4">
          <dropdown-wrapper [formControl]="fee.get('budgetElement')" [dropdownConfig]="budgetElementDropdown"> </dropdown-wrapper>
        </table-field>
        <table-field name="Amount" class="col-lg-2 col-md-4">
          <numerictextbox-wrapper [formControl]="fee.get('amount')" [format]="amountFormat" [fieldType]="1" (ngModelChange)="getTotalFees()"></numerictextbox-wrapper>
        </table-field>
        <table-field class="col-md d-none d-md-flex">
          <button kendoButton type="button" (click)="removeFee(i)" [icon]="'trash'" hideIfReadonlyMode></button>
        </table-field>
      </ng-template>
      <ng-template #tableFooter>
        <div class="d-flex justify-content-end">
          <button kendoButton type="button" (click)="addFee()" hideIfReadonlyMode>Add Fee</button>
        </div>
      </ng-template>
    </form-table>
  </collapsible-card>
  <collapsible-card [title]="'Invoices'">
    <advance-payment-invoices [formControlName]="'openItemsLines'" [totalAmount]="getTotalAmount()" [totalFees]="totalFees" [currencyId]="currencyId"> </advance-payment-invoices>
  </collapsible-card>
</div>
