<div class="card form-group">
  <div class="card-header collapsible-card-header" (click)="isOpen = !isOpen">
    <div class="title-container">
      <h6><i *ngIf="icon" [ngClass]="icon"></i> {{ title }} <i *ngIf="showExternalLinkIcon" class="k-icon k-i-hyperlink-open" style="font-size: 14px; margin-left: 10px" (click)="openLink()"></i></h6>
      <button
        class="card-header__button k-button"
        *ngIf="addLineButton"
        type="button"
        kendoButton
        data-toggle="tooltip"
        data-placement="top"
        title="Add line"
        [icon]="'plus'"
        (click)="addLineOption()"
      ></button>
    </div>
    <div class="toogle-container">
      <button kendoButton [icon]="isOpen ? 'arrow-chevron-up' : 'arrow-chevron-down'" (click)="toggleCard($event)" class="k-button" type="button"></button>
    </div>
  </div>
  <div class="card-body" [hidden]="!isOpen">
    <ng-content></ng-content>
  </div>
</div>
