<div class="container h-100">
  <div class="d-flex justify-content-center h-100">
    <div class="login-box">
      <div class="d-flex flex-column h-100">
        <div class="medallion-container">
          <div class="medallion" alt="Logo"></div>
        </div>
        <div class="logo"></div>
        <!-- <form [formGroup]="loginForm" novalidate class="login-form mt-4 container flex-grow-1">
                    <div *ngIf="!errorMessage && authMessage | async" class="col-12 alert alert-warning small mb-3" role="alert">
                        {{ authMessage | async }}
                    </div>
                    <div *ngIf="errorMessage" class="col-12 alert alert-warning small mb-3" role="alert">
                        {{ errorMessage }}
                    </div>
                    <div class="input-group input-group-sm mb-3 col-12" [ngClass]="{ 'mt-4': !errorMessage }">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <fa-icon style="padding:0 1px;" [icon]="faUser"></fa-icon>
                            </span>
                        </div>
                        <input formControlName="username" type="email" class="form-control">
                    </div>
                    <div class="input-group input-group-sm mb-3 col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <fa-icon [icon]="faKey"></fa-icon>
                            </span>
                        </div>
                        <input formControlName="password" type="password" class="form-control">
                    </div>
                    <div class="col-12 mb-3 text-center">
                        <button type="submit" id="login" (click)="login()" class="btn btn-info btn-sm">Login</button>
                    </div>
                    <div style="text-align: center; color: white">
                        {{authMessage | async}}
                    </div>
                </form> -->
        <div (click)="login()" class="msoft-login">
          <img src="../../../../assets/msoft-login.png" />
        </div>
      </div>
    </div>
  </div>
</div>
