import { YN } from './enums';

export class VoucherList {
  id?: number;
  number?: number;
  voucherType: string;
  information: string;
  voucherNumber: number;
  externalReference: string;
  counterpartyName: string;
  invoiceDate: number;
  amount: number;
  currency: string;
  source: string;
  bankAccount: string;
  status: string;
  invoiceId: number;
  journalId: number;
  journalIndex: number;
  companyId: number;
  counterpartyId: number;
  voucherTypeLabel: string;
  bookingId: number | null;
  bookingNumber: number | null;
  bookingNumbers: string | null;
  contractId: number | null;
  contractNumber: number | null;
  contractNumbers: string | null;
  hasServiceOrder: YN;
}

export enum VoucherListStatus {
  TEMPORARY = 'TEMPORARY',
  SENT = 'SENT',
  TO_BE_SENT = 'TO_BE_SENT',
  DUE = 'DUE',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PRINTED = 'PRINTED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  PERMANENT = 'PERMANENT',
  TO_BE_VERIFIED = 'TO_BE_VERIFIED',
  REVERSED = 'REVERSED',
}
