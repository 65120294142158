<header class="flex-shrink-0 flex-grow-0">
  <nav class="main navbar navbar-expand navbar-dark bg-primary bg-gradient">
    <div class="navbar-brand" [routerLink]="'/portal'" style="cursor: pointer">
      <div class="logo" *ngIf="production">Royce Corporation</div>
      <div class="dev-logo" *ngIf="!production">TESTING SYSTEM</div>
    </div>
    <thalos-clock class="clock"></thalos-clock>
    <global-search *ngIf="globalSearchAuthorized" class="global-search"></global-search>
    <kendo-badge-container *ngIf="notificationsAuthorized">
      <button type="button" kendoButton class="notification-button" (click)="toggleSidebar(menuType.NOTIFICATIONS)">
        <i [ngClass]="notificationActivatedIcon"></i>
      </button>
      <kendo-badge *ngIf="notificationsNotReadCount" size="medium" themeColor="error">{{ notificationsNotReadCount }}</kendo-badge>
    </kendo-badge-container>
    <button type="button" kendoButton icon="menu" class="menu-button" (click)="toggleSidebar(menuType.MENU)" cyData="toggleSidebar"></button>
  </nav>
  <nav class="sidebar" #sidebar [ngClass]="{ expand: expandNotificationCenter }">
    <ng-container *ngIf="!!notifications && notifications.length > 0">
      <roco-notification-center
        [notifications]="notifications"
        [allNotificationsCounter]="allNotificationsCounter"
        [ngClass]="{ 'with-load-btn': isAllNotificationsCountGreater, 'without-load-btn': !isAllNotificationsCountGreater }"
      ></roco-notification-center>
      <div class="button-container">
        <button type="button" *ngIf="isAllNotificationsCountGreater" class="button" kendoButton [primary]="true" (click)="loadNotificationsFromDB()">Load more...</button>
      </div>
    </ng-container>
    <div *ngIf="!(notifications && notifications.length > 0)" class="no-notifications-container">
      <i class="no-notifications-icon" [ngClass]="notificationActivatedIcon"></i>
      <span class="no-notifications-title">No notifications</span>
      <span class="no-notifications-message">When you get notifications, they'll show up here</span>
    </div>
  </nav>
  <div class="click-away" (click)="expandNotificationCenter = false" *ngIf="expandNotificationCenter"></div>
  <nav class="sidebar" #sidebar [ngClass]="{ expand: expandSidebar }">
    <div style="text-align: center; font-size: 14px">Logged in as: {{ username | async }}</div>
    <roco-menu [data]="permittedMenu | async" [forceClose]="!expandSidebar"></roco-menu>
    <div class="button-container">
      <button type="button" class="button" kendoButton [primary]="true" (click)="logout()">Log out</button>
    </div>
  </nav>
  <div class="click-away" (click)="expandSidebar = false" *ngIf="expandSidebar"></div>
</header>
