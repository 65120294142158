<div *ngIf="!loader" class="thalos-ui-widget" [ngClass]="{ readonly: readonly }">
  <input
    [readonly]="readonly"
    kendoTextBox
    [(ngModel)]="_value"
    #input
    (change)="onValueChange(value)"
    (blur)="onBlur()"
    [placeholder]="placeholder"
    [type]="type"
    [maxlength]="maxLength"
    autocomplete="_none"
  />
</div>
<input-field-loader class="w-100" *ngIf="loader" [readonly]="readonly"></input-field-loader>
