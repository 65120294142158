import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

const EXPIRATION_TIME = 60000;

@Injectable()
export class CachingService {
  cacheMap: { [key: string]: CacheEntry } = {};

  public put(res: HttpResponse<any>, url) {
    this.cacheMap[url] = new CacheEntry(res, res.url);

    this.deleteExpired();
  }

  public get(req: HttpRequest<any>): HttpResponse<any> | null {
    const entry = this.cacheMap[req.urlWithParams + JSON.stringify(req.body)];
    if (entry === undefined || entry === null) return null;
    const expired = Date.now() - entry.entryTime > EXPIRATION_TIME;
    return expired ? null : entry.response;
  }

  public deleteExpired() {
    for (let key in this.cacheMap) {
      let entry = this.cacheMap[key];
      if (Date.now() - entry.entryTime > EXPIRATION_TIME) {
        delete this.cacheMap[key];
      }
    }
  }
}

class CacheEntry {
  public url: string;
  public response: HttpResponse<any>;
  public entryTime: number;

  constructor(response, url) {
    this.response = response;
    this.url = url;

    this.entryTime = Date.now();
  }
}
