import { map, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { PaymentTerm, PhysicalContract, SourceEntityType, UpdatePhysicalContractRequest } from 'src/lib/newBackendTypes';
import { contractPrefillCallback } from './archiveUnarchiveContract';
import { createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { paymentTermDropdown } from 'src/lib/commonTypes';

const paymentTermPrefill: prefillCallback<{ paymentTerm: PaymentTerm }> = (delegate, id, column) => {
  return contractPrefillCallback(delegate, id, column).pipe(
    map((contract) => {
      if (!contract || typeof contract === 'string') return contract as string | null;
      return {
        paymentTerm: contract.paymentTerm ?? null,
      };
    })
  );
};

export const createContractPaymentTermForm: createFormCallback<{ paymentTerm: PaymentTerm }> = (delegate, id) => {
  return [_fe('paymentTerm', 'Payment Term', paymentTermDropdown())];
};

const paymentTermUpdate: submitFormCallback<{ paymentTerm: PaymentTerm }> = (delegate, id, form, prefill, column) => {
  return updateContractCallback(delegate, id, { paymentTermId: form.paymentTerm?.id ?? null }, { paymentTermId: prefill.paymentTerm?.id ?? null }, column);
};

export const updateContractCallback: submitFormCallback<Partial<PhysicalContract>> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');

  let request: UpdatePhysicalContractRequest = {
    id,
  };
  //add more fields if needed
  if (form.archived !== prefill.archived) {
    request.archived = form.archived;
  }

  if (form.paymentTermId !== prefill.paymentTermId) {
    request.paymentTermId = form.paymentTermId;
  }

  if (Object.keys(request).length === 1) {
    return of(prefill);
  }

  return api.rpc<PhysicalContract>(endpoints.updateContract, request, null);
};

export const updatePaymentTermPreset: DynamicFormConstant<{ paymentTerm: PaymentTerm }> = {
  value: DynamicFormType.CONTRACT_PAYMENT_TERM,
  entityType: SourceEntityType.CONTRACT_KEY,
  label: 'Change Payment Term',
  title: 'Change Payment Term',
  width: 400,
  endpoints: [endpoints.getContract, endpoints.listPaymentTerms, endpoints.updateContract],
  getPrefill: paymentTermPrefill,
  createForm: createContractPaymentTermForm,
  submitForm: paymentTermUpdate,
};
