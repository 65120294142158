import { from, of } from 'rxjs';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { Booking, SourceEntityType, STBStatuses } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { bookingListPrefillCallback, ListBookingsPrefill } from './updateBooking';

const updateSTBStatusCheck: checkPrefillCallback<ListBookingsPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.bookingResponses.length === 0) return of('No bookings to be updated.');
  return of(true);
};

const updateSTBStatusForm: createFormCallback<ListBookingsPrefill, updateBookingStbForm> = (delegate, id, prefill) => {
  const bookings = ` ${prefill.bookingResponses.map((b) => b.number).join(', ')}`;
  const form: (FormElementConfig<updateBookingStbForm> | FormElementConfig<updateBookingStbForm>[])[] = [
    { type: 'Label', text: `Booking(s): ${bookings}` },
    _fe('stbStatus', 'STB Status', { data: STBStatuses, valueField: 'value', labelField: 'label' }, prefill.bookingResponses.length === 1 ? prefill.bookingResponses[0].stbStatus : null),
  ];
  if (prefill.bookingResponses.length > 1) {
    form.unshift({
      type: 'Label',
      text: 'Warning! Updating multiple bookings',
      style: { color: 'red', display: 'flex', 'justify-content': 'center' },
    });
  }
  return form;
};

const updateSTBStatusSubmit: submitFormCallback<ListBookingsPrefill, updateBookingStbForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<Booking[]>((resolve) => {
      (async () => {
        const updateBookingResponses: Booking[] = [];

        for (let res of prefill.bookingResponses) {
          let request: updateBookingStbRequest = {
            id: res.id,
            stbStatus: result.stbStatus,
            lastTrackingDate: getTodayUTC(),
          };
          const updateBooking = await api.run<Booking>(endpoints.updateBooking, request, null);
          updateBookingResponses.push(updateBooking);
        }
        if (updateBookingResponses) return resolve(updateBookingResponses);
        else return resolve(null);
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking(s) successfully updated: \n${response.map((booking) => `- ${booking.number}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Booking(s) were updated and try again if necessary.</div>`);
      })
  );
};

export const updateSTBPreset: DynamicFormConstant<ListBookingsPrefill, updateBookingStbForm> = {
  allowMultipleRows: true,
  value: DynamicFormType.UPDATE_STB_STATUS,
  label: 'Update STB Status',
  title: 'Update STB Status',
  endpoints: [endpoints.updateBooking, endpoints.getBooking],
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  width: 250,
  checkPrefill: updateSTBStatusCheck,
  getPrefill: bookingListPrefillCallback,
  createForm: updateSTBStatusForm,
  submitForm: updateSTBStatusSubmit,
};

export type updateBookingStbForm = Pick<Booking, 'stbStatus'>;
export type updateBookingStbRequest = Pick<Booking, 'stbStatus' | 'id' | 'lastTrackingDate'>;
