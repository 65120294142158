<form [formGroup]="form">
  <p *ngIf="bookings.length > 1" class="text-center text-danger">Warning! Updating multiple bookings</p>
  <div class="row">
    <vertical-field [name]="'Original ETD'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'originalETD'"> </datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Place of Receipt ETD'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'placeOfReceiptETD'"> </datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Loading Port ETD'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'loadingPortETD'"> </datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Transhipment ETD'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'transhipmentETD'"> </datepicker-wrapper>
    </vertical-field>
  </div>
  <div class="row">
    <vertical-field [name]="'Unloading Port ETA'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'unloadingPortETA'"> </datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Final ETA'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'finalDestinationETA'"> </datepicker-wrapper>
    </vertical-field>
    <vertical-field [name]="'Vessel'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <dropdown-wrapper [formControlName]="'vessel'" [dropdownConfig]="vesselConfig" class="vertical-input-dropdown-with-btn"> </dropdown-wrapper>
      <button kendoButton type="button" [icon]="'plus'" (click)="openCreateVessel()" hideIfReadonlyMode></button>
    </vertical-field>
    <vertical-field [name]="'Voyage'" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
      <input-wrapper kendoTextBox type="text" [formControlName]="'voyage'"> </input-wrapper>
    </vertical-field>
  </div>
</form>
