export const TOGGLE_DEBUG_MODE = 'debug_mode';

export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const LOADED_MISSING_ENDPOINTS = 'loaded_missing_endpoints';
export const LOADED_MISSING_GRAPHQLS = 'loaded_missing_graphqls';
export const LOADED_USER_ENDPOINTS = 'loaded_user_endpoints';
export const LOADED_USER_GRAPHQLS = 'loaded_user_graphqls';
export const CALCULATED_DYNAMIC_MENUS = 'calculated_dynamic_menus';

export const START_REQUEST = 'start_request';
export const FINISH_REQUEST = 'finish_request';
export const UPDATE_REQUEST_PROGRESS = 'update_request_progress';

export const SET_USER = 'set_user';
export const SET_USER_APPROVAL_TYPES = 'set_user_approval_types';
export const SET_USER_GROUPS = 'set_user_groups';

export const SET_ACTIVE_FORM = 'set_active_form';
export const SET_READONLY_MODE = 'set_readonly_mode';

export const SET_URL_TARGET = 'set_url_target';

export const SET_AUTH_MESSAGE = 'set_auth_message';

export const SET_FORM_ENTITY = 'form_entity';

export const SET_TIMEDOUT = 'set_timed_out';

export const SET_BOOKING_OPERATOR = 'set_booking_operator';

export const SET_BOOKING_LAYOUT = 'set_booking_layout';

export const SET_ANCHOR_POINT = 'set_anchor_point';
export const UPDATE_ANCHOR_POINT = 'update_anchor_point';
export const BLOCK_ANCHOR_POINT = 'block_anchor_point';

export const SET_FLAT_MENU = 'set_flat_menu';

export const SET_USER_AUTHORIZED_COMPANIES = 'set_user_authorized_companies';
