import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { GridOptions, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { BehaviorSubject, from, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorComponent } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { defaultComplexGrid, getContextMenuItems, gotoMenu, gotoMenuItem } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { Permissions } from 'src/lib/componentPermissions';
import { fromBradyDate } from 'src/lib/helperFunctions';
import { Contact, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { ShipmentFinderRequest } from 'src/lib/newBackendTypes/containerFinder';
import { ShipmentFinderResult } from 'src/lib/newBackendTypes/shipment';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import * as XLSX from 'xlsx';
import { excelHeaderMap, ExcelHeaders, ImportExcelComponent, renameExcelKeys, selectItemFormToPopulate } from '../aggrid/import/importExcel';
import { counterpartyDropdown } from 'src/lib/commonTypes';

@Component({
  selector: 'thalos-shipment-finder',
  templateUrl: './shipment-finder.component.html',
  styleUrls: ['./shipment-finder.component.scss'],
})
@Permissions('Shipment Finder', [endpoints.shipmentLookup])
export class ShipmentFinderComponent implements OnInit, SelectorComponent<ShipmentFinderResult[]>, ImportExcelComponent<ShipmentsExcelData> {
  @Input()
  public readonly: boolean;

  @Input()
  completelyInvoiced: boolean;

  @Input()
  preselectedShipments: ShipmentFinderResult[];

  @Input()
  allowMultipleSelect = true;

  public popup: boolean = false;

  gridOptions: GridOptions;

  data: ShipmentFinderForm[] | null;
  numberFormat: NumberFormatOptions = {
    useGrouping: false,
    maximumFractionDigits: 0,
  };

  searchForm: UntypedFormGroup;

  public selectedShipments: BehaviorSubject<ShipmentFinderResult[]>;
  public popupObservable: Observable<ShipmentFinderResult[]>;
  public popupObserver: Subscriber<ShipmentFinderResult[]>;

  counterpartyDropdown = counterpartyDropdown(YN.Y, 30);

  constructor(private api: ThalosApiService, private spinnerService: SpinnerService, private formatter: DataFormattingService, private delegate: DelegateService) {
    this.searchForm = new TypedFormGroup<ShipmentFinderFilterForm>(
      {
        bookingNumber: new UntypedFormControl(null, Validators.max(2147483647)),
        contractNumber: new UntypedFormControl(null, Validators.max(2147483647)),
        shipmentId: new UntypedFormControl(null, Validators.max(2147483647)),
        invoiceNumber: new UntypedFormControl(null, Validators.max(2147483647)),
        containerMarks: new UntypedFormControl(),
        counterparty: new UntypedFormControl(),
        appointmentReference: new UntypedFormControl(''),
        enableShipmentGenealogy: new UntypedFormControl(''),
      },
      (fg: UntypedFormGroup) => {
        for (let key in fg.controls) {
          if (fg.controls[key].value) return null;
        }
        return { required: true };
      }
    );

    this.data = null;
    this.popupObservable = new Observable((subscriber) => {
      this.popupObserver = subscriber;
    });
    this.selectedShipments = new BehaviorSubject(undefined);

    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'shipmentId'),
      domLayout: 'autoHeight',
      onGridReady: this.onGridReady,
      getRowId: (params) => params.data.shipmentId,
      defaultColDef: {
        resizable: true,
        width: 100,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      columnDefs: [
        {
          colId: 'checkbox',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: (params) => !this.readonly,
          headerCheckboxSelectionFilteredOnly: true,
          width: 50,
          cellStyle: (params) => {
            return this.readonly ? { 'pointer-events': 'none' } : null;
          },
        },
        { headerName: 'Shipment Id', field: 'shipmentId', sort: 'desc', width: 135 },
        { headerName: 'Container Marks', field: 'containerMarks', width: 140 },
        {
          field: 'netWeight',
          valueFormatter: this.formatter.gridUnitFormatter('unitCode'),
          cellStyle: { textAlign: 'right' },
          width: 115,
        },
        {
          field: 'grossWeight',
          valueFormatter: this.formatter.gridUnitFormatter('unitCode'),
          cellStyle: { textAlign: 'right' },
          width: 120,
        },
        {
          field: 'finalWeight',
          valueFormatter: this.formatter.gridUnitFormatter('unitCode'),
          cellStyle: { textAlign: 'right' },
          width: 120,
        },
        { headerName: 'Item', field: 'itemName', width: 200 },
        { headerName: 'Booking', field: 'bookingNumber' },
        { headerName: 'ATA', valueGetter: this.bookingATAGetter, width: 150 },
        { headerName: 'Purchase Invoice', field: 'purchaseInvoiceNumber', width: 140 },
        { headerName: 'Sale Invoice', field: 'saleInvoiceNumber', width: 115 },
        // { field: 'supplierFinalWeight', valueFormatter: this.formatter.gridUnitFormatter('unitCode'), cellStyle: { textAlign: "right" } },
        // { field: 'customerFinalWeight', valueFormatter: this.formatter.gridUnitFormatter('unitCode'), cellStyle: { textAlign: "right" } },
        { field: 'purchaseCounterpartyName', headerName: 'Supplier', width: 150 },
        { field: 'saleCounterpartyName', headerName: 'Customer', width: 150 },
      ],
      onSelectionChanged: this.onSelectionChange(),
      getContextMenuItems: getContextMenuItems(gotoMenu(gotoMenuItem(this.delegate, 'Booking', 'bookingId', SourceEntityType.FREIGHT_BOOKING_KEY, 'get', 'bookingNumber', false))),
    };
  }

  netWeightGetter = (params: ValueGetterParams) => {
    let c: ShipmentFinderResult = params.data;
    if (!c.netWeight) return '';
    if (!c.unitCode) return c.netWeight;
    return `${c.netWeight} ${c.unitCode}`;
  };

  grossWeightGetter = (params: ValueGetterParams) => {
    let c: ShipmentFinderResult = params.data;
    if (!c.grossWeight) return '';
    if (!c.unitCode) return c.grossWeight;
    return `${c.grossWeight} ${c.unitCode}`;
  };

  bookingATAGetter = (params: ValueGetterParams) => {
    let c: ShipmentFinderResult = params.data;
    if (!c?.ataNumber) return '';
    try {
      let d = fromBradyDate(c.ataNumber);
      return d.toLocaleDateString();
    } catch (e) {
      return '';
    }
  };

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preselectedShipments']) {
      this.data = [];
      this.addExistingShipments(this.preselectedShipments);

      if (this.gridOptions.api) {
        this.gridOptions.onRowDataUpdated = () => {
          this.gridOptions.api.selectAll();
          this.gridOptions.onRowDataUpdated = null;
        };
      }
    }
  }

  addExistingShipments(containers: ShipmentFinderForm[]) {
    if (containers.length <= 0) return;
    if (this.data === null) this.data = [];
    this.data = containers.concat(this.data);
  }

  preselectItems(shipments: ShipmentFinderResult[]) {
    this.addExistingShipments(shipments);
  }

  //Arrow function to preserve this
  onGridReady = (event) => {
    if (!!this.popup) {
      this.gridOptions.api.setDomLayout('normal');
      this.gridOptions!.api.setPopupParent(document.querySelector('.k-dialog'));
    }

    if (this.data) {
      for (let c of this.data) {
        this.gridOptions.api.getRowNode(`${c.shipmentId}`).selectThisNode(true);
      }
    }
    if (this.readonly) {
      this.gridOptions.columnApi.setColumnVisible('checkbox', false);
    }

    if (this.allowMultipleSelect) {
      this.gridOptions.rowSelection = 'multiple';
    } else {
      this.gridOptions.rowSelection = 'single';
    }
  };

  clickSearch() {
    if (this.searchForm.valid === false) {
      this.searchForm.markAsTouched();
      return;
    }
    const values: ShipmentFinderRequest = {};
    for (let key in this.searchForm.controls) {
      let val = this.searchForm.get(key).value;
      if (!!val) {
        if (key === 'counterparty') values['counterpartyId'] = val.id;
        else values[key] = val;
      }
    }

    const containers$ = this.searchForm.controls.enableShipmentGenealogy.value
      ? this.api.rpc<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookupGenealogy, { filters: values }, { count: 0, list: [] })
      : this.api.rpc<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookup, { filters: values }, { count: 0, list: [] });

    let rId = this.spinnerService.startRequest(randomFetchSynonym() + ' Shipments');
    containers$.pipe(map((res) => res.list)).subscribe((containers) => {
      this.spinnerService.completeRequest(rId);
      let selectedShipments: ShipmentFinderResult[];
      if (this.data !== null) {
        selectedShipments = this.gridOptions.api.getSelectedRows();
      }

      if (!!selectedShipments) {
        this.gridOptions.onRowDataUpdated = () => {
          for (let container of selectedShipments) {
            const row = this.gridOptions.api.getRowNode(`${container.shipmentId}`);
            if (row) row.selectThisNode(true);
          }
          this.gridOptions.onRowDataUpdated = null;
        };

        for (let container of selectedShipments) {
          if (!containers.some((c) => container.shipmentId === c.shipmentId)) {
            containers.unshift(container);
          }
        }
      }

      this.data = containers;
    });
  }

  onSelectionChange() {
    return (params: SelectionChangedEvent) => {
      if (!this.popup || this.readonly) return;
      let shipments = this.gridOptions.api.getSelectedRows();
      this.popupObserver.next(shipments);
    };
  }

  importExcel(event: any) {
    const prompt = this.delegate.getService('prompt');
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length > 1) return prompt.htmlDialog('Error', `<div style="white-space: pre">Cannot use multiple files</div>`);
    if (target.files.length === 0) return prompt.htmlDialog('Error', `<div style="white-space: pre">No files selected</div>`);
    this.convertData(target.files[0]);
  }

  // read the raw data and convert it to a JSON
  convertData(importFile) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(importFile);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data: ShipmentsExcelData[] = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // Data will be logged in array format containing objects
      /* populate ag grid mapping data */
      const resultData = this.populateExcelData(data);
      resultData.subscribe((data) => {
        this.data = data;
      });
    };
  }

  populateExcelData(data: ShipmentsExcelData[]): Observable<ShipmentFinderForm[]> {
    const api = this.delegate.getService('api');
    const spinner = this.delegate.getService('spinner');
    const prompt = this.delegate.getService('prompt');
    return from(
      new Promise<ShipmentFinderForm[]>((resolve, reject) => {
        (async () => {
          let rid = spinner.startRequest('Drawing Data');
          let lineNumber = 1;
          let result: ShipmentFinderForm[] = [];
          const shipmentMap = new Map<number, ShipmentFinderForm>();
          for (let item of data) {
            const mappedItem = renameExcelKeys(item, excelHeaderMap);
            item.lineNumber = lineNumber++;
            let lineShipment: ShipmentFinderForm | undefined = undefined;
            if (mappedItem.shipmentNumber !== undefined) {
              lineShipment = shipmentMap.get(mappedItem.shipmentNumber);
              item.shipmentFinder = lineShipment;
              item.shipmentId = lineShipment ? lineShipment.shipmentId : undefined;
              if (lineShipment === undefined) {
                const shipmentsResponse = await api.run<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookup, { filters: { shipmentId: mappedItem.shipmentNumber } }, null);
                if (shipmentsResponse.list.length === 1) {
                  lineShipment = shipmentsResponse.list[0];
                  shipmentMap.set(lineShipment.shipmentId, lineShipment);
                  item.shipmentFinder = lineShipment;
                  item.shipmentId = lineShipment ? lineShipment.shipmentId : undefined;
                } else if (shipmentsResponse.list.length > 1) {
                  spinner.completeRequest(rid);
                  await new Promise((resolve) => {
                    const formResponse = selectItemFormToPopulate<ShipmentFinderResult & { useForFollowing?: boolean }>(
                      this.delegate,
                      shipmentsResponse.list,
                      `Select the Shipment for Shipment ID: ${item.shipmentId} on line #${item.lineNumber}`,
                      'shipmentId',
                      'shipmentId',
                      'Shipments',
                      'useForFollowing'
                    );
                    formResponse.subscribe((formResult) => {
                      if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify Shipment: ${mappedItem.shipmentNumber}</div>`));
                      lineShipment = shipmentsResponse.list.find((item) => item.shipmentId === formResult.shipmentId);
                      if (formResult.useForFollowing) {
                        data.forEach((entry) => {
                          if (entry[ExcelHeaders.SHIPMENT] === mappedItem.shipmentNumber) entry[ExcelHeaders.SHIPMENT] = mappedItem.shipmentNumber;
                        });
                        shipmentMap.set(mappedItem.shipmentNumber, lineShipment);
                      }
                      item.shipmentFinder = lineShipment;
                      item.shipmentId = lineShipment ? lineShipment.shipmentId : undefined;
                      rid = spinner.startRequest('Drawing Data');
                      return resolve(lineShipment);
                    });
                  });
                } else {
                  spinner.completeRequest(rid);
                  return prompt.htmlDialog('Error', `<div style="white-space: pre">Shipment: ${mappedItem.shipmentNumber} not found on line #${item.lineNumber}</div>`);
                }
              }
            }
            if (item.shipmentFinder) {
              item.containerMarks = item.shipmentFinder.containerMarks;
              item.netWeight = item.shipmentFinder.netWeight;
              item.grossWeight = item.shipmentFinder.grossWeight;
              item.unitCode = item.shipmentFinder.unitCode;
              item.itemName = item.shipmentFinder.itemName;
              item.bookingNumber = item.shipmentFinder.bookingNumber;
              item.ataNumber = item.shipmentFinder.ataNumber;
              item.purchaseInvoiceNumber = item.shipmentFinder.purchaseInvoiceNumber;
              item.saleInvoiceNumber = item.shipmentFinder.saleInvoiceNumber;
              item.saleCounterpartyName = item.shipmentFinder.saleCounterpartyName;
              item.purchaseCounterpartyName = item.shipmentFinder.purchaseCounterpartyName;
            }
            result.push(item);
          }
          spinner.completeRequest(rid);
          return resolve(result);
        })();
      })
    );
  }
}

type ShipmentFinderFilterForm = Omit<ShipmentFinderRequest, 'counterpartyId'> & {
  counterparty?: Contact;
  enableShipmentGenealogy?: Boolean;
};

type ShipmentFinderForm = Pick<
  ShipmentFinderResult,
  | 'purchaseCounterpartyName'
  | 'saleCounterpartyName'
  | 'saleInvoiceNumber'
  | 'purchaseInvoiceNumber'
  | 'ataNumber'
  | 'bookingNumber'
  | 'itemName'
  | 'unitCode'
  | 'grossWeight'
  | 'netWeight'
  | 'containerMarks'
  | 'shipmentId'
>;

type ShipmentsExcelData = ShipmentFinderForm & {
  shipmentFinder?: ShipmentFinderForm;
  lineNumber?: number;
};
