<div class="d-flex checklist-entry" [ngClass]="{ 'checkbox-entry': type?.type === 1 }" [ngStyle]="{ 'flex-direction': type?.type === 1 ? 'row' : 'column' }" style="justify-content: space-between">
  <div class="checklist-label" style="margin-left: 7px">
    <span>{{ type?.name }}</span>
  </div>
  <ng-container [ngSwitch]="type?.type">
    <div *ngSwitchCase="1" class="checklist-entry-field">
      <div class="k-form-field" style="display: flex; align-items: center; height: 100%; flex-direction: row-reverse">
        <input [disabled]="readonly" [(ngModel)]="_value" (blur)="onTouch()" (change)="onChange(value)" [id]="id" type="checkbox" class="k-checkbox" #input />
        <label class="k-checkbox-label" [for]="id"></label>
        <input *ngIf="!label" [disabled]="readonly" kendoTextBox style="visibility: hidden; display: none" />
      </div>
    </div>
    <div *ngSwitchCase="2">
      <kendo-numerictextbox
        [format]="defaultNumberFormat"
        [decimals]="4"
        [hidden]="readonly"
        [placeholder]="'#' + type?.name"
        [(ngModel)]="_value"
        [spinners]="false"
        [step]="0"
        [autoCorrect]="true"
        (valueChange)="onValueChange(value)"
        (blur)="onBlur()"
        #input
        (scroll)="input.blur()"
        [readonly]="readonly"
      >
      </kendo-numerictextbox>
      <div *ngIf="readonly" style="margin-left: 7px" class="form-field-height">
        {{ _value === 0 ? 0 : !!_value ? (_value | number : decimalFormat : 'en-US') : 'No Value' }}
      </div>
    </div>
    <div *ngSwitchDefault>
      <input [hidden]="readonly" [readonly]="readonly" kendoTextBox [(ngModel)]="_value" #input (change)="onValueChange(value)" (blur)="onBlur()" [placeholder]="type?.name" />
      <div *ngIf="readonly" style="margin-left: 7px" class="form-field-height">
        {{ _value || 'No value' }}
      </div>
    </div>
  </ng-container>
</div>
