import { Contact, EntryOperation, InvoiceHeader, Product } from '.';
import { GlAccountLeaves } from './glAccount';
import { PramvJournalKey } from './pramvJournalKey';
import { YN } from './enums';
import { enumOptionsFromEnum } from '../helperFunctions';
import { AnalyticalGroup } from './analyticalGroup';
import { Subset } from '../generics';
import { PaymentHeader } from './payment';

export enum EntryMode {
  MANUAL = 1,
  AUTOMATIC = 2,
  TO_BE_VERIFIED = 3,
}
export const EntryModes = enumOptionsFromEnum(EntryMode);

export enum JournalStatus {
  TEMPORARY = 1,
  PERMANENT = 2,
  TO_BE_VERIFIED = 3,
}
export const JournalStatuses = enumOptionsFromEnum(JournalStatus);

export enum JournalEntrySource {
  CLIENT_INV = 1,
  CLIENT_CN = 2,
  CLIENT_PAYMENT = 3,
  SUPPLIER_INVOICE = 4,
  SUPPLIER_CN = 5,
  SUPPLIER_PAYMENT = 6,
  AUTOMATION = 7,
  BACK_OFFICE = 8,
  INTERFACE = 9,
  CLIENT_DN = 10,
  SUPPLIER_DN = 11,
}
export const JournalEntrySources = enumOptionsFromEnum(JournalEntrySource);

export enum ConsStatus {
  IF_AUXILIARY = 1,
  OTHERWISE = 0,
}
export const ConsStatuses = enumOptionsFromEnum(ConsStatus);

export class JournalHeader {
  id: number;
  companyId: number;
  /**
   * @deprecated
   */
  fiscalYear: number;
  journalIndex: number;
  documentReference: number | null;
  entryDate: Date;
  valueDate: Date;
  entryMode: EntryMode;
  entryOperation: EntryOperation;
  entrySource: JournalEntrySource | null;
  externalRef: string;
  consStatus: ConsStatus;
  title: string;
  auxDocumentReference: number | null;
  modifiedBy: string;
  modifyDate: Date;
  versionNb: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  invoiceHeader?: InvoiceHeader;
  paymentHeader?: PaymentHeader;
  journalEntries?: JournalEntry[];
  pramv?: PramvJournalKey;
}
export type JournalEntry = {
  journalKey: number;
  journalNumber: number;
  entryPosition: number;
  journalEntryId: number;
  accountId: number;
  /**
   * @deprecated
   */
  fiscalYear: number;
  entryText1: string;
  entryText2: string;
  amount: number;
  baseAmount: number;
  externalRef: string | null;
  cbnGroupId: number | null;
  anlJournalKey: number | null;
  matched: YN;
  counterpartyId: number | null;
  productQuantity: number;
  unitId: number | null;
  extrasId: number | null;
  vatAmount: number;
  vatBaseAmount: number;
  vatCurrencyId: number | null;
  vatNumber: string;
  vatDocumentType: DocumentType | null;
  vatAccAmount: number;
  matchingNumber: number | null;
  linkedVoucherId: number | null;
  anlCorrectQuantity: number;
  productId?: number | null;
  account?: GlAccountLeaves;
  counterparty?: Contact;
  journalHeader?: JournalHeader;
  product?: Product | null;
  analyticalGroup?: AnalyticalGroup;
};

export type CreateJournalRequest = Pick<JournalHeader, 'companyId' | 'entryDate' | 'valueDate' | 'entryMode' | 'entryOperation' | 'entrySource' | 'externalRef' | 'title' | 'auxDocumentReference'> & {
  entries: CreateJournalEntry[];
};

export type CreateJournalEntry = Subset<
  JournalEntry,
  'entryPosition' | 'entryText1' | 'accountId' | 'entryText2' | 'amount' | 'baseAmount',
  'externalRef' | 'counterpartyId' | 'productQuantity' | 'unitId' | 'extrasId' | 'vatAmount' | 'vatBaseAmount' | 'vatCurrencyId' | 'vatDocumentType' | 'vatAccAmount' | 'linkedVoucherId' | 'productId'
>;

export type UpdateJournalEntry = Subset<
  JournalEntry,
  'entryPosition',
  | 'entryText1'
  | 'accountId'
  | 'entryText2'
  | 'amount'
  | 'baseAmount'
  | 'externalRef'
  | 'counterpartyId'
  | 'productQuantity'
  | 'unitId'
  | 'extrasId'
  | 'vatAmount'
  | 'vatBaseAmount'
  | 'vatCurrencyId'
  | 'vatDocumentType'
  | 'vatAccAmount'
  | 'linkedVoucherId'
  | 'productId'
>;

export type UpdateJournalRequest = Pick<JournalHeader, 'id'> &
  Partial<Pick<JournalHeader, 'entryDate' | 'valueDate' | 'entryMode' | 'entryOperation' | 'entrySource' | 'externalRef' | 'title'>> & { entries: (CreateJournalEntry | UpdateJournalEntry)[] };
