import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { round } from 'lodash';
import { from, startWith } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { NumerictextboxWrapperComponent } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { DocumentRow } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { DropdownConfig, EntityContainer } from 'src/lib';
import { SaveOptions } from 'src/lib/EntityContainer';
import { endpoints } from 'src/lib/apiEndpoints';
import { dollarFormat } from 'src/lib/commonTypes';
import { CreateProposalPaymentPrefill, createPaymentRequest } from 'src/lib/flex/forms/createProposalPayment';
import { reconcilePaymentPreset } from 'src/lib/flex/forms/reconcilePayment';
import { conditionalValidators } from 'src/lib/genericValidators';
import { Subset } from 'src/lib/generics';
import { compareDates, dateGreaterThanOrSameAs, getCompanyByDefaultAuthorizedCompany, getTodayUTC, getVoucherSign, markFormGroupTouched, openFlexForm, toBradyUTCDate } from 'src/lib/helperFunctions';
import { Comment, Contact, EntryType, GlAccountLeaves, PayOrderStatus, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import {
  PaymentEntryMode,
  PaymentEntryModes,
  PaymentEntryOperation,
  PaymentEntryOperations,
  PaymentEntrySource,
  PaymentEntrySources,
  PaymentHeader,
  PaymentStatus,
  PaymentStatuses,
  UpsertPaymentRequest,
} from 'src/lib/newBackendTypes/payment';
import { toLocalDate } from 'src/lib/toUTCDate';
import { FormControlStatus, TypedFormGroup } from 'src/lib/typedForms';
import { DocumentsIcon, InvoiceIcon } from 'src/lib/uiConstants';
import { PaymentLine, PaymentsLinesComponent, Totals } from './payments-lines.component';

@Component({
  selector: 'accounting-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [PaymentsLinesComponent],
})
export class PaymentsComponent extends EntityContainer<PaymentHeader, UpsertPaymentRequest, UpsertPaymentRequest> implements ModalFormComponent<PaymentForm, CreateProposalPaymentPrefill> {
  form: TypedFormGroup<PaymentForm> = null;

  popUpOptions?: SaveOptions<PaymentHeader, UpsertPaymentRequest | UpsertPaymentRequest> = null;

  showSaveButtonsInPopup: boolean;

  prefill: Partial<PaymentForm> = null;

  currencyFormat = dollarFormat();

  paymentStatuses = PaymentStatuses;
  entryOperations = PaymentEntryOperations;
  entryModes = PaymentEntryModes;
  entrySources = PaymentEntrySources;

  PaymentIcon = InvoiceIcon;
  documentsIcon = DocumentsIcon;

  companies: Contact[];
  glAccountDropdown: DropdownConfig<GlAccountLeaves>;

  companyIdField: number | null = null;
  companyId: number | null = null;
  currencyId: number | null;

  exchangeRates: ExchangeRateInfo[] = [];

  totalAmount: number = null;
  totalBaseAmount: number = null;
  linesCurrencyId: number = null;

  journalEntityType = SourceEntityType.JOURNAL_ID;
  baseCurrencyId: number | null = null;

  @ViewChild('amountField', { static: false })
  amountField: NumerictextboxWrapperComponent;

  @ViewChild('baseAmountField', { static: false })
  baseAmountField: NumerictextboxWrapperComponent;

  @ViewChild('xrField', { static: false })
  xrField: NumerictextboxWrapperComponent;

  ynData = [
    { value: YN.Y, label: 'Yes' },
    { value: YN.N, label: 'No' },
  ];

  enableDifferentCurrencies: boolean = false;
  pendingStatus = FormControlStatus.PENDING;

  constructor(route: ActivatedRoute, elementRef: ElementRef<any>, zone: NgZone, delegate: DelegateService, public commonData: CommonDataService, public cdr: ChangeDetectorRef) {
    super(
      {
        entityName: 'Payment',
        idFields: ['id'],
        labelField: 'id',
        tabFields: ['documentReference'],
        sourceEntityType: SourceEntityType.PAYMENT_KEY,
        createProcedureId: endpoints.createPayment,
        updateProcedureId: endpoints.updatePayment,
        deleteProcedureId: endpoints.deletePayment,
        getProcedureId: endpoints.getPayment,
      },
      route,
      elementRef,
      zone,
      delegate
    );

    this.companies = commonData.staticCompanies.value;
    from(
      (async () => {
        this.exchangeRates = await this.getExhangeRates();
      })()
    );
    if (!this.entity || this.popup) {
      const defaultCompany = getCompanyByDefaultAuthorizedCompany(this.companies, this.store);
      this.companyIdField = defaultCompany ? defaultCompany.id : null;
      this.baseCurrencyId = defaultCompany && defaultCompany.fiscalCompany ? defaultCompany.fiscalCompany.currKey : null;
    }
    this.generateGlAccountsDropdownConfig();
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)
    if (this.form && this.form.touched) {
      this.amountField.focus();
      this.baseAmountField.focus();
      this.xrField.focus();
    }
  } // end ngAfterViewInit

  initializeForm() {
    const amountAndXrIsRequired = conditionalValidators(() => !this.amountAndXrReadonly, Validators.required);
    const requiredIfIsReadonly = conditionalValidators(() => !this.isReadOnly, Validators.required);
    this.form = new TypedFormGroup<PaymentForm>({
      id: new UntypedFormControl(),
      paymentTitle: new UntypedFormControl(null, Validators.required),
      valueDate: new UntypedFormControl(getTodayUTC(), [requiredIfIsReadonly, this.fiscalYearValidator()]),
      externalRef: new UntypedFormControl(),
      documentReference: new UntypedFormControl(),
      entryDate: new UntypedFormControl(getTodayUTC(), requiredIfIsReadonly),
      companyId: new UntypedFormControl(this.companyIdField, requiredIfIsReadonly),
      glAccount: new UntypedFormControl(null),
      paymentStatus: new UntypedFormControl(PaymentStatus.TEMPORARY, Validators.required),
      entryMode: new UntypedFormControl(PaymentEntryMode.MANUAL, Validators.required),
      entryOperation: new UntypedFormControl(PaymentEntryOperation.NORMAL, Validators.required),
      entrySource: new UntypedFormControl(PaymentEntrySource.NONE),
      documents: new UntypedFormControl([]),
      paymentEntries: new UntypedFormControl([{ lineNumber: 1 }], []),
      amount: new UntypedFormControl(null, amountAndXrIsRequired),
      xr: new UntypedFormControl(null, amountAndXrIsRequired),
      baseAmount: new UntypedFormControl(),
      reconciled: new UntypedFormControl(),
      comments: new UntypedFormControl(),
    });

    if (!!this.prefill) {
      this.form.patchValue(this.prefill);
    }
  }

  get disableEntryDate() {
    return true;
  }

  get journalKey() {
    let journalKey = this.entity?.journalId;
    return journalKey;
  }

  get journalIndex() {
    let journalIndex = this.entity?.journalHeader?.journalIndex;
    return journalIndex;
  }

  get isBooked() {
    return !!this.entity && this.entity?.payOrder?.payOrderStatus === PayOrderStatus.BOOKED;
  }

  initializeFormListeners(): void {
    this.glAccountDropdown.additionalFilters = { accountOption: 1, archived: YN.N };
    this.form
      .get('companyId')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((companyId: number) => {
        this.companyIdField = companyId;
        this.companyId = companyId;
        const account = this.form.value.glAccount;
        if (!!account && account.companyId !== companyId) {
          this.form.patchValue({ glAccount: null });
        }
        this.glAccountDropdown.additionalFilters.companyId = companyId;
        this.form.get('glAccount').updateValueAndValidity();
      });

    const account = this.form.get('glAccount');
    account.valueChanges.pipe(startWith(this.form.value.glAccount), untilDestroyed(this)).subscribe(async (res: GlAccountLeaves) => {
      this.currencyId = res && res.currencyId && res.currencyId !== 0 ? res.currencyId : null;
      this.companyId = res && res.companyId && res.companyId !== 0 ? res.companyId : this.companyId;
    });

    this.form
      .get('paymentEntries')
      .valueChanges.pipe(startWith(this.form.value.paymentEntries), untilDestroyed(this))
      .subscribe(async (res: PaymentLine[]) => {
        this.form.patchValue(
          {
            baseAmount: this.totalBaseAmount,
            amount: this.totalAmount,
            xr: this.totalBaseAmount / this.totalAmount,
          },
          { emitEvent: false }
        );
        this.form.get('baseAmount').updateValueAndValidity({ onlySelf: true });
        account.updateValueAndValidity();
        this.form.get('glAccount').setValidators(conditionalValidators(() => this.isRequiredAccount, Validators.required));
        this.form.get('amount').updateValueAndValidity();
        this.form.get('xr').updateValueAndValidity();
      });

    this.form
      .get('amount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((amount: number) => {
        if (!this.amountAndXrReadonly) this.form.patchValue({ xr: this.baseAmountField._value / amount }, { emitEvent: false });
        this.cdr.detectChanges();
      });

    this.form
      .get('xr')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((xr: number) => {
        if (!this.amountAndXrReadonly) this.form.patchValue({ amount: this.baseAmountField._value / xr }, { emitEvent: false });
        this.cdr.detectChanges();
      });
    if (!this.entity) {
      this.form
        .get('paymentEntries')
        .valueChanges.pipe(startWith(this.form.value.paymentEntries), untilDestroyed(this))
        .subscribe(async (res: PaymentLine[]) => {
          const xr = await this.getXrValue();
          this.form.patchValue(
            {
              baseAmount: this.totalBaseAmount,
              amount: !this.enableDifferentCurrencies ? this.totalBaseAmount / xr : this.totalAmount,
              xr: !this.enableDifferentCurrencies ? xr : this.totalBaseAmount / this.totalAmount,
            },
            { emitEvent: false }
          );
        });
    }
  }

  generateGlAccountsDropdownConfig() {
    this.glAccountDropdown = new DropdownConfig<GlAccountLeaves>({
      listProcedure: endpoints.listGlAccounts,
      valueField: 'id',
      labelField: 'idenLong',
      labelTransform: (glAccount) => glAccount.idenLong.replace(/ +/g, ' '),
      additionalFilters: {
        companyId: this.companyId,
      },
      take: 0,
      orderBy: { fieldName: 'idenLong', order: 'ASC' },
    });
  }

  async getCreateEntityRequest(saveOptions: SaveOptions<PaymentHeader, UpsertPaymentRequest>): Promise<UpsertPaymentRequest> {
    let request: UpsertPaymentRequest;
    if (!this.amountAndXrReadonly) {
      request = await createPaymentRequest(this.form.value, this.amountField._value, this.baseAmountField._value);
    } else {
      request = await createPaymentRequest(this.form.value, this.totalAmount, this.totalBaseAmount);
    }
    return { ...request, enableDifferentCurrencies: this.enableDifferentCurrencies };
  }

  async getUpdateEntityRequest(saveOptions: SaveOptions<PaymentHeader, UpsertPaymentRequest>): Promise<UpsertPaymentRequest> {
    const formVal = this.form.value;
    let request: UpsertPaymentRequest;
    if (!this.amountAndXrReadonly) {
      request = await createPaymentRequest(this.form.value, this.amountField ? this.amountField._value : undefined, this.baseAmountField ? this.baseAmountField._value : undefined);
    } else {
      request = await createPaymentRequest(this.form.value, this.totalAmount, this.totalBaseAmount);
    }

    if (formVal.paymentTitle !== this.entity.paymentTitle) {
      request.paymentTitle = formVal.paymentTitle;
    }

    if (!compareDates(formVal.entryDate, this.entity.entryDate)) {
      request.entryDate = formVal.entryDate;
    }

    if (!compareDates(formVal.valueDate, this.entity.valueDate)) {
      request.valueDate = formVal.valueDate;
    }

    if ((!!formVal.glAccount && formVal.glAccount.id !== this.entity.accountId) || (formVal.glAccount === null && this.entity.accountId !== null)) {
      request.accountId = formVal.glAccount ? formVal.glAccount.id : null;
    }

    if (formVal.paymentTitle !== this.entity.paymentTitle) {
      request.paymentTitle = formVal.paymentTitle;
    }

    if (formVal.externalRef !== this.entity.externalRef) {
      request.externalRef = formVal.externalRef || '';
    }

    if (formVal.paymentStatus !== this.entity.paymentStatus) {
      request.paymentStatus = formVal.paymentStatus ? formVal.paymentStatus : PaymentStatus.TEMPORARY;
    }

    return { ...request, enableDifferentCurrencies: this.enableDifferentCurrencies };
  }

  async loadEntity(entity: PaymentHeader) {
    this.exchangeRates = (await this.api.run(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) })) as ExchangeRateInfo[];
    const lines = entity.paymentEntries ?? [];
    this.companyId = entity.companyId;
    this.currencyId = entity.glAccount?.currencyId;
    const firstLine = lines[0];
    if (lines.some((line) => line.glAccount && (line.glAccount.currencyId !== firstLine.glAccount.currencyId || (!!this.currencyId && this.currencyId !== line.glAccount.currencyId)))) {
      this.enableDifferentCurrencies = true;
    } else {
      this.enableDifferentCurrencies = false;
    }

    this.form.patchValue({
      ...entity,
      xr: entity.baseAmount / entity.amount || 1,
      paymentEntries: lines
        .map((l) => {
          return {
            ...l,
            voucherSign: l.voucher ? getVoucherSign(l.voucher.documentType, l.voucher.voucherType) : undefined,
            xr: l.baseAmount / l.amount,
          };
        })
        .sort((a, b) => a.lineNumber - b.lineNumber),
    });
  }

  prefillForm(data: CreateProposalPaymentPrefill) {
    let lineNumber = 1;
    this.exchangeRates = data.currencyConversions;

    this.prefill = {
      paymentEntries: data.items.map((openItem) => {
        const glAccount = {
          id: openItem.accountId,
          companyId: openItem.companyId,
          currencyId: openItem.currencyId,
          accountType: openItem.accountType,
          accountOption: openItem.accountOption,
          idenLong: openItem.accountIdenLong,
        };
        const xr = (this.exchangeRates || []).find((xr) => (glAccount ? xr.baseCurrencyId === glAccount.currencyId && xr.targetCurrencyId === this.baseCurrencyId : undefined));
        let xrValue;
        if (xr) {
          xrValue = xr.factor;
        } else if (glAccount.currencyId === this.baseCurrencyId) {
          xrValue = 1;
        } else {
          xrValue = null;
        }
        if (openItem.entryType === EntryType.PMT) {
          const matchedPaymentEntry = {
            accountId: openItem.accountId,
            amount: openItem.balanceAmount,
            baseAmount: openItem.balanceBaseAmount,
            counterparty: data.counterparty,
            counterpartyId: data.counterparty.id,
            entryText: openItem.entryTitle,
            externalRef: openItem.externalRef,
            iden: openItem.iden,
            paymentEntryId: openItem.entryId,
            paymentId: openItem.paymentId,
            voucher: null,
            voucherId: null,
          };

          const line: PaymentLine = {
            lineNumber: lineNumber++,
            entryText: openItem.entryTitle,
            externalRef: openItem.externalRef,
            amount: -openItem.balanceAmount,
            accountId: openItem.accountId,
            baseAmount: -openItem.balanceBaseAmount,
            counterparty: data.counterparty,
            counterpartyId: data.counterparty.id,
            voucher: null,
            voucherId: null,
            auxPaymentEntryId: openItem.entryId,
            matchedPaymentEntry: matchedPaymentEntry,
            glAccount: glAccount,
            paymentEntryId: null,
            paymentId: null,
            voucherSign: undefined,
            xr: xrValue,
          };

          return line;
        } else if (openItem.entryType === EntryType.INV) {
          const voucher = {
            id: openItem.voucherId,
            iden: openItem.iden,
            ourReference: openItem.ourReference,
            amount: Math.abs(openItem.balanceAmount),
            baseAmount: Math.abs(openItem.balanceBaseAmount),
            documentType: openItem.documentType,
            voucherType: openItem.voucherType,
          };

          const voucherSign = voucher ? getVoucherSign(voucher.documentType, voucher.voucherType) : undefined;
          const line: PaymentLine = {
            lineNumber: lineNumber++,
            counterparty: data.counterparty,
            counterpartyId: data.counterparty.id,
            voucher: voucher,
            voucherId: openItem.voucherId,
            auxPaymentEntryId: null,
            matchedPaymentEntry: null,
            entryText: openItem.entryTitle,
            externalRef: openItem.externalRef,
            amount: -openItem.balanceAmount,
            baseAmount: -openItem.balanceBaseAmount,
            accountId: openItem.accountId,
            glAccount: glAccount,
            paymentEntryId: null,
            paymentId: null,
            xr: xrValue,
            voucherSign: voucherSign,
          };
          return line;
        }
      }),
    };
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.requestsPending.length === 0 && !this.form.pending && !this.form.invalid;
  }

  onGridReady(gridEvent: any) {}

  clickReconcilePayment() {
    const prompt = this.delegate.getService('prompt');
    if (!this.form.valid) return prompt.htmlDialog('Error', `<div style="white-space: pre">Some fields are invalid or missing. ${this.entityName} could not be saved.</div>`);
    if (this.form.touched && this.form.dirty)
      return prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to ${this.entity.reconciled === YN.Y ? 'Un-reconcile' : 'Reconcile'} payment: Please save before continuing.</div>`);
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.form.pending) return;

    openFlexForm(this.delegate, reconcilePaymentPreset, this.entity.id, (res: any) => {
      if (!!res) {
        const path = this.entityPath + this.entityKey;
        const queryParams = this.route.snapshot?.queryParams || {};
        this.router.navigate([path], { relativeTo: this.route, queryParams });
      }
    });
  }

  getTotalAmounts(event: Totals) {
    this.totalBaseAmount = -event.baseAmount;
    this.totalAmount = -event.amount;
  }

  async getExhangeRates() {
    return (await this.api.run(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) })) as ExchangeRateInfo[];
  }

  getXrValue() {
    const xr = (this.exchangeRates || []).find((xr) =>
      this.baseCurrencyId ? xr.baseCurrencyId === (this.currencyId ?? this.linesCurrencyId) && xr.targetCurrencyId === this.baseCurrencyId : undefined
    );
    let xrValue;
    if (xr) {
      xrValue = xr.factor;
    } else if (this.currencyId === this.baseCurrencyId) {
      xrValue = 1;
    } else {
      xrValue = null;
    }
    return xrValue;
  }

  getLinesCurrId(event: number | null) {
    this.linesCurrencyId = event;
  }

  get amountAndXrReadonly() {
    if (this.isReadOnly) return true;
    if (!this.linesCurrencyId || !this.currencyId || !this.companyId) return true;
    return this.linesCurrencyId && this.currencyId && this.currencyId === this.linesCurrencyId;
  }

  get isReadOnly() {
    return !!this.entity && (this.entity.entryMode === PaymentEntryMode.AUTOMATIC || this.closed);
  }

  get isMultipleCurrenciesReadonly() {
    return this.entity ? true : false;
  }

  get closed() {
    if (!this.entity) return false;
    if (!this.fiscalYear) return false;
    if (!this.entity.valueDate) return false;
    return !dateGreaterThanOrSameAs(this.entity.valueDate, this.fiscalYear);
  }

  get isRequiredAccount() {
    return Math.abs(round(this.totalAmount, 2)) !== 0;
  }

  get fiscalYear() {
    const company = this.companies.find((c) => c.id === this.companyId);
    if (!company?.fiscalCompany) return null;
    return company.fiscalCompany.permDateAccounting;
  }

  fiscalYearValidator() {
    return (c: AbstractControl) => {
      if (!this.form) return null;
      if (!this.fiscalYear) return null;
      if (!c.value) return null;
      if (!!this.entity) return null;
      if (!dateGreaterThanOrSameAs(c.value, this.fiscalYear)) {
        const readableDate = toLocalDate(this.fiscalYear).toLocaleDateString();
        return { custom: `Accounts have been closed through ${readableDate}` };
      }
      return null;
    };
  }
}

export type PaymentForm = Subset<
  PaymentHeader,
  'id' | 'paymentTitle' | 'valueDate' | 'externalRef' | 'paymentStatus' | 'entryMode' | 'entryOperation' | 'entrySource' | 'entryDate' | 'glAccount',
  'documentReference' | 'companyId' | 'reconciled'
> & {
  paymentEntries?: PaymentLine[];
  documents?: DocumentRow[];
  amount?: number;
  baseAmount?: number;
  xr?: number;
  comments?: Comment[];
};
