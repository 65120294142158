import { ExistingGraphql } from './graphQlEnums';
import { GraphqlValueTypes } from './graphqlRequestTypes';

export interface IGraphqlDropdownConfig<T> {
  query: string;
  graphQlName: ExistingGraphql;
  labelField: keyof T;
  valueField: keyof T;
  principalVariableKey?: string;
  secondaryVariables?: { [key: string]: any };
  secondaryLabelFields?: (entry: T) => string;
  labelTransform?: (entry: T) => string;
  valueType?: GraphqlValueTypes;
}

function isGraphqlDropdownConfig<T>(config: any): config is IGraphqlDropdownConfig<T> {
  return !!config && !!config.labelField && !!config.valueField && !!config.query && !!config.graphQlName;
}

export class GraphqlDropdownConfig<T> {
  query: string;
  graphQlName: ExistingGraphql;
  labelField: keyof T;
  valueField: keyof T;
  principalVariableKey?: string;
  secondaryVariables?: { [key: string]: any };
  secondaryLabelFields?: (entry: T) => string;
  labelTransform?: (entry: T) => string;
  valueType?: GraphqlValueTypes;

  /**
   * @param config An object containing options for the dropdown config
   * @param principalVariableKey An key ok T, using for query when value o dropdown changes
   * @param secondaryVariables An object with partial of T with aditional query variables
   * @param graphQlName Name of the graphql in the backend
   * @param labelField The property to display
   * @param valueField The unique key property to identify each result by
   * @param secondaryLabelFields A callback function that accepts the object and returns a string to display
   * @param labelTransform A callback function that accepts the object and returns a string to display
   * @param valueType Type of value sent to graphql
   */
  constructor(config: IGraphqlDropdownConfig<T>);
  constructor(query: string, graphQlName: ExistingGraphql, labelField: keyof T, valueField: keyof T);
  constructor(
    query: string,
    graphQlName: ExistingGraphql,
    labelField: keyof T,
    valueField: keyof T,
    principalVariableKey?: string,
    secondaryVariables?: { [key: string]: any },
    secondaryLabelFields?: (T) => string,
    labelTransform?: (T) => string,
    valueType?: GraphqlValueTypes
  );
  constructor(
    queryOrConfig: string | IGraphqlDropdownConfig<T>,
    graphQlName?: ExistingGraphql,
    labelField?: keyof T,
    valueField?: keyof T,
    principalVariableKey?: string,
    secondaryVariables?: { [key: string]: any },
    secondaryLabelFields?: (T) => string,
    labelTransform?: (T) => string,
    valueType?: GraphqlValueTypes
  ) {
    if (isGraphqlDropdownConfig<T>(queryOrConfig)) {
      this.query = queryOrConfig.query;
      this.principalVariableKey = queryOrConfig.principalVariableKey;
      this.secondaryVariables = queryOrConfig.secondaryVariables;
      this.graphQlName = queryOrConfig.graphQlName;
      this.labelField = queryOrConfig.labelField;
      this.valueField = queryOrConfig.valueField;
      this.secondaryLabelFields = queryOrConfig.secondaryLabelFields;
      this.labelTransform = queryOrConfig.labelTransform;
      this.valueType = queryOrConfig.valueType ? queryOrConfig.valueType : 'String';
    } else {
      this.query = queryOrConfig;
      this.principalVariableKey = principalVariableKey;
      this.secondaryVariables = secondaryVariables;
      this.graphQlName = graphQlName;
      this.labelField = labelField;
      this.valueField = valueField;
      this.secondaryLabelFields = secondaryLabelFields;
      this.labelTransform = labelTransform;
      this.valueType = valueType ? valueType : 'String';
    }
  }
}
