import { Component, Input, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { Store } from 'src/app/core/services/store.service';
import { ApprovalStatus, Contact } from 'src/lib/newBackendTypes';
import { ApprovalRequestRequest } from 'src/lib/newBackendTypes/approvalRequest';
import { ApprovalTypeApprover } from 'src/lib/newBackendTypes/approvalTypeApprover';
import { ApprovalType } from 'src/lib/newBackendTypes/approvalTypes';
import { FormElementComponent } from '../form-elements/form-element/form-element.component';

type ApproverForm = ApprovalTypeApprover & { approverName: string; sentEmail?: boolean };

@Component({
  selector: 'approver-dropdown',
  templateUrl: './approver-dropdown.component.html',
})
export class ApproverDropdownComponent extends FormElementComponent {
  @Input()
  status: ApprovalStatus;

  @Input()
  approvalType: ApprovalType;

  @Input()
  approvedBy?: Contact;

  data: ApproverForm[];
  _value: ApproverForm[] = [];
  existingRequests: ApproverForm[];

  constructor(private commonData: CommonDataService, controlDir: NgControl, store: Store) {
    super(controlDir, store);
    this._value = [];
    this.data = [];
    this.existingRequests = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly'] && changes['readonly'].currentValue !== changes['readonly'].previousValue) {
      if (this.value && this.value.length > 0) {
        this.onChange(this.value);
      }
    }
    if (changes['approvalType']) {
      this.getApprovers();
    }
  }

  ngOnInit() {}

  getApprovers() {
    if (!this.approvalType) {
      this.data = [];
      this.value = [];
      //this.onChange(this.value)
      return;
    }
    this.commonData.approvalTypeApprovers
      .pipe(
        map((res) => res.filter((ata) => ata.approvalTypeId === this.approvalType?.id)),
        map((res) =>
          res.map((approvalTypeApprover) => {
            return {
              ...approvalTypeApprover,
              approverName: approvalTypeApprover.approver ? approvalTypeApprover.approver.displayName : `${approvalTypeApprover.approvalTypeId}`,
            };
          })
        ),
        map((approvers) => {
          return approvers.map((approver) => {
            let sentEmail = !!this._value.find((request) => request.approverId === approver.approverId && request.sentEmail);
            if (sentEmail) return { ...approver, sentEmail: true };
            else return approver;
          });
        })
      )
      .subscribe((approvers) => {
        if (this._value.length === 0) {
          this._value = approvers.filter((approver) => approver.default);
        } else {
          this._value = approvers.filter((approver) => this._value.find((request) => request.approverId === approver.approverId));
          this.existingRequests = this.existingRequests.concat(this._value.map((approval) => approval));
        }
        this.data = approvers;
      });
  }

  getValue(): ApprovalRequestRequest[] {
    let approvers = this._value || [];

    return approvers.map((approver) => {
      let request: ApprovalRequestRequest = {
        approverId: approver.approverId,
      };
      return request;
    });
  }

  refreshPermissions() {
    //inner form element that doesn't care about permissions
  }
}
