<header class="flex-shrink-0 flex-grow-0">
  <nav class="main navbar navbar-expand navbar-dark bg-primary bg-gradient">
    <div class="container">
      <div class="navbar-brand ms-2">
        <div class="logo">Royce Corporation</div>
      </div>
    </div>
  </nav>
</header>

<div class="text-container">Logging in...</div>
