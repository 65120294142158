<closable-form
  [formGroup]="form"
  title="Document Template"
  [entityId]="entity?.id"
  class="widescreen"
  [sourceEntityType]="sourceEntityType"
  [nextEntity]="nextEntity"
  [previousEntity]="previousEntity"
>
  <collapsible-card title="General">
    <div class="row">
      <vertical-field name="URL" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <input-wrapper [formControlName]="'url'"></input-wrapper>
      </vertical-field>
      <vertical-field name="Name" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <input-wrapper [formControlName]="'name'"></input-wrapper>
      </vertical-field>
      <vertical-field name="Entity Type" class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'sourceEntityTypeId'" [data]="entityTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Type" class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'type'" [data]="types" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"></simple-dropdown>
      </vertical-field>
    </div>
  </collapsible-card>
  <div class="container-button" hideIfReadonlyMode>
    <button *ngIf="!!entity && deleteAuthorized" type="button" style="width: 75px" (click)="clickDelete()" class="container-button__button btn btn-sm btn-danger">Delete</button>
    <button type="button" cyData="submit" style="width: 75px" class="container-button__button btn btn-sm btn-success" (click)="clickSave()">Save</button>
  </div>
</closable-form>
