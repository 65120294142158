import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { CheckListItem } from 'src/lib/newBackendTypes/checklist';
import { UnguardedFormElementComponent } from '../unguarded-form-element/unguarded-form-element.component';

@Component({
  selector: 'checklist-entry',
  templateUrl: './checklist-entry.component.html',
  styleUrls: ['./checklist-entry.component.scss'],
})
export class ChecklistEntryComponent extends UnguardedFormElementComponent {
  @Input()
  type: CheckListItem;

  id: string;

  defaultNumberFormat: NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
    useGrouping: false,
  };

  constructor(controlDir: NgControl) {
    super(controlDir);

    this.id = `checklist-${ChecklistEntryComponent.idCounter++}`;
  }

  static idCounter = 0;

  ngOnInit(): void {}
}
