import { ContainerType } from './containerType';
import { Booking, CommonContainerTypes } from './freightBooking';
import { enumOptionsFromEnum } from '../helperFunctions';
import { PropertyDocument } from './propertyDocument';

export type Container = {
  id: number;
  containerNumber: string;
  seal: string;
  containerTypeId: CommonContainerTypes | null;
  deliveryDate: Date | null;
  status: ChunkType;
  tareWeight: number | null;
  warehouseUnloadingDate: Date | null;
  shipments?: PropertyDocument[];
  booking?: Booking;
  containerType?: ContainerType;
};

export type CreateContainerRequest = Omit<Container, 'id'> & {
  bookingId?: number;
};
export type UpdateContainerRequest = Pick<Container, 'id'> & Partial<CreateContainerRequest>;

export enum ChunkType {
  BILL_OF_LADING = 1,
  ROADWAY_BILL = 2,
  STOCK = 3,
  BUNKER = 4,
  TO_BUNKER = 5,
  TO_CARGO = 6,
  ROB_BUNKER = 9,
  ROB = 10,
  RAILWAY_BILL = 14,
  AIRWAY_BILL = 15,
  FCR = 16,
  CROSSING = 17,
  INSTRUCTED = 18,
  FCR_RELEASED = 19,
  RELEASE = 20,
  HOLDING_CERTIFICATE = 21,
  PRODUCED = 22,
  DAA = 23,
  DISCHARGE_REPORT = 24,
  DAA2 = 25,
  WASHOUT = 26,
  CIRCLE = 27,
  IM = 28,
  WARRANT = 29,
  WRITTEN_OFF = 30,
}
export const ChunkTypes = enumOptionsFromEnum(ChunkType);
