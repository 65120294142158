import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import Mexp from 'math-expression-evaluator';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { ModalFormComponent, SelectorApi } from 'src/app/core/services/selector-popup.service';
import { counterpartyDropdown, paymentTermDropdown } from 'src/lib/commonTypes';
import { Subset } from 'src/lib/generics';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { fromBradyDate, markFormGroupTouched } from 'src/lib/helperFunctions';
import { ContractType, Currency, MarketValuationHeader, Unit } from 'src/lib/newBackendTypes';
import { PremiumFixationList, PremiumFixationSummary } from 'src/lib/newBackendTypes/PremiumFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

export type PremiumFixationForm = Subset<PremiumFixationList, 'contractId' | 'elementNumber' | 'fixationDate' | 'marketPrice' | 'premium' | 'quantity', 'id'>;

@UntilDestroy()
@Component({
  selector: 'hedging-premium-fixation',
  templateUrl: './premium-fixation.component.html',
  styleUrls: ['./premium-fixation.component.scss'],
})
export class PremiumFixationComponent implements OnInit, ModalFormComponent<PremiumFixationForm, PremiumFixationSummary> {
  popup = true;

  selectorApi?: SelectorApi;

  ContractType = ContractType;

  prefill?: PremiumFixationSummary;
  valuation?: MarketValuationHeader;
  quantityUnit?: Unit;
  priceUnit?: Unit;
  priceCurrency?: Currency;
  paymentTermDropdown = paymentTermDropdown();
  counterpartyDropdown = counterpartyDropdown();
  form: TypedFormGroup<PremiumFixationForm>;
  premiumFormula: string = '';
  quantityFormat: NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    useGrouping: true,
  };
  priceFormat: NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    useGrouping: true,
  };

  constructor(private commonData: CommonDataService) {
    this.form = new TypedFormGroup<PremiumFixationForm>({
      id: new UntypedFormControl(null),
      contractId: new UntypedFormControl(null),
      elementNumber: new UntypedFormControl(null),
      fixationDate: new UntypedFormControl(null, [Validators.required, bradyDateValidator()]),
      marketPrice: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
      quantity: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
      premium: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
    });

    this.form
      .get('marketPrice')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((marketPrice: number | string | null) => {
        if (marketPrice === null || !this.prefill) {
          this.form.patchValue({ premium: null });
          return;
        }
        try {
          if (typeof marketPrice === 'string') {
            marketPrice = parseFloat(Mexp.eval(`${marketPrice || ''}`));
            if (!isNaN(marketPrice)) {
              this.form.patchValue({ marketPrice: Math.round(marketPrice * 1000000) / 1000000 });
            }
          }
          this.form.patchValue({
            premium: Math.round((marketPrice + this.prefill.spread) * this.prefill.percentage * 10000) / 1000000,
          });
        } catch (err) {
          this.form.patchValue({ premium: null });
        }
      });
  }

  prefillForm(data: PremiumFixationSummary) {
    this.prefill = data;

    this.commonData.staticUnits.value;
    this.quantityUnit = this.commonData.staticUnits.value.find((unit) => unit.unitId === data.quantityUnitId);

    this.valuation = this.commonData.staticPremiumValuations.value.find((valuation) => valuation.valuationId === data.valuationId);
    if (this.valuation) {
      this.priceUnit = this.commonData.staticUnits.value.find((unit) => unit.unitId === this.valuation.unitId);
      this.priceCurrency = this.commonData.staticCurrencies.value.find((currency) => currency.id === this.valuation.currencyId);

      this.premiumFormula = `( ${this.valuation.valuationName}`;
      if (this.prefill.spread > 0) {
        this.premiumFormula += ` + ${this.prefill.spread} ${this.priceCurrency ? this.priceCurrency.code : '???'} / ${this.priceUnit ? this.priceUnit.code : '??'}`;
      } else if (this.prefill.spread < 0) {
        this.premiumFormula += ` - ${Math.abs(this.prefill.spread)} ${this.priceCurrency ? this.priceCurrency.code : '???'} / ${this.priceUnit ? this.priceUnit.code : '??'}`;
      }
      this.premiumFormula += ` ) x ${this.prefill.percentage}%`;
    }

    this.form.patchValue({
      id: data.premiumFixationId || undefined,
      contractId: data.contractId,
      elementNumber: data.elementNumber,
      quantity: data.fixedQuantity || data.contractQuantity,
      fixationDate: fromBradyDate(data.fixationDate),
      marketPrice: data.marketPrice,
      premium: data.premium,
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): PremiumFixationForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    return this.form.value;
  }

  ngOnInit() {}
}
