import { EnumLabels } from '../generics';
import { Advance } from './advance';
import { PaymentEntry } from './payment';

export class AdvancePayment {
  id: number;
  advanceId: number;
  paymentEntryId: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updatedDate: Date | null;
  advance?: Advance;
  paymentEntry?: PaymentEntry;
}

export enum DueDateType {
  CONTRACT_DATE = 1,
  LOADING_ETD = 2,
  MANUAL = 3,
}

export const dueDateTypeLabels: EnumLabels<DueDateType> = [
  { value: DueDateType.CONTRACT_DATE, label: 'Contract Date' },
  { value: DueDateType.LOADING_ETD, label: 'Loading ETD' },
  { value: DueDateType.MANUAL, label: 'Manual' },
];
