<ng-container *ngFor="let b of bookings; let i = index">
  <div class="split-booking-link">
    <a entityLink [entityId]="b.id" [entityType]="bookingKey" (navigate)="onNavigate()"> Booking {{ b.index }} - {{ b.number }} </a>
    <a entityLink [entityId]="b.id" [entityType]="bookingKey" [newTab]="true">
      <i class="k-icon k-i-hyperlink-open" style="color: rgba(0, 0, 0, 0.85); height: 1.2em"></i>
    </a>
  </div>
  <div class="split-booking-containers">Containers: {{ b.containers }}</div>
  <div class="form-divider-line" *ngIf="i !== bookings.length - 1"></div>
</ng-container>
