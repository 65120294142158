<form [formGroup]="form">
  <div class="row">
    <vertical-field class="col-lg-2 col-md-4 col-sm-6 col-12" [name]="'Company'">
      <simple-dropdown [formControlName]="'companyId'" [data]="companies" [valuePrimitive]="true" [valueField]="'id'" [textField]="'displayName'"> </simple-dropdown>
    </vertical-field>
    <vertical-field name="Value Date" class="col-lg-2 col-md-4 col-sm-6 col-12">
      <datepicker-wrapper [formControlName]="'valueDate'"></datepicker-wrapper>
    </vertical-field>
    <vertical-field *ngIf="valueDateWarning.show" class="col-12" [showLabel]="false">
      <div class="row flex-column">
        <div class="warning-label"><i class="fas fa-info-circle"></i> {{ valueDateWarning.text }}.</div>
      </div>
    </vertical-field>
  </div>
  <div class="form-divider-line"></div>
  <div class="row">
    <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Operation'">
      <simple-dropdown [formControlName]="'operation'" [data]="operationsDropdown" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="true"> </simple-dropdown>
    </vertical-field>
    <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" [name]="'Class'" cyData="class">
      <simple-dropdown [data]="classData" valueField="value" textField="label" [valuePrimitive]="true" [formControlName]="'hedgeableType'"> </simple-dropdown>
    </vertical-field>
    <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" name="Article Type">
      <dropdown-wrapper [dropdownConfig]="productTypeDropdown" [formControlName]="'articleType'"> </dropdown-wrapper>
    </vertical-field>
  </div>
  <div class="form-divider-line"></div>
  <div class="row">
    <vertical-field [name]="'Description'" class="col-12">
      <textarea-wrapper [formControlName]="'description'" [showCounter]="showCounter" [maxlength]="maxLength"> </textarea-wrapper>
    </vertical-field>
  </div>
  <collapsible-card [title]="'Net Position Lines'">
    <risk-management-net-position-journal-lines [formControlName]="'lines'" [class]="hedgeableType" [companyId]="companyId" [productId]="productId"></risk-management-net-position-journal-lines>
  </collapsible-card>
</form>
