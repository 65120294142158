import { Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { MiscFeature } from 'src/lib/feature';
import { Contact, SourceEntityType } from 'src/lib/newBackendTypes';
import { NotificationActivatedIcon, NotificationDeactivatedIcon } from 'src/lib/uiConstants';
import { CollapsibleCardComponent } from '../collapsible-card/collapsible-card.component';
import { CreateNotificationSubscription, NotificationsSubscriptions } from 'src/lib/newBackendTypes/notificationTypes/notificationsSubscriptions';
import { endpointAuthorizationSubscription, endpointsAuthorized, getRandomColor } from 'src/lib/helperFunctions';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SocketIOService, SocketSessions } from 'src/app/core/services/socket.io.service';

@UntilDestroy()
@Component({
  selector: 'closable-form',
  templateUrl: './closable-form.component.html',
  styleUrls: ['./closable-form.component.scss'],
})
export class ClosableFormComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  entityId: number | string;

  @Input()
  altFeature?: MiscFeature;

  @Input()
  icon?: string;

  @ContentChild('headerContents', { static: false })
  headerContents;

  @ContentChildren(CollapsibleCardComponent)
  cardChildren: QueryList<CollapsibleCardComponent>;

  @Output()
  close: EventEmitter<void> = new EventEmitter(false);

  @Input()
  sourceEntityType: SourceEntityType;

  @Input()
  entityKey?: number;

  @Input()
  nextEntity: { label: string; id: number };

  @Input()
  previousEntity: { label: string; id: number };

  @Input()
  enableNotifications?: boolean = false;

  private hasCloseEvent: boolean;

  hotKeys: Hotkey[];
  authorized: endpointsAuthorized;
  user: Contact;
  subscribed: boolean = false;
  subscriptionRequest: CreateNotificationSubscription;
  notificationActivatedIcon = NotificationActivatedIcon;
  notificationDeactivatedIcon = NotificationDeactivatedIcon;
  usersConnected: UsersConnected[] = [];

  constructor(
    protected api: ThalosApiService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private hotKeysService: HotkeysService,
    private elementRef: ElementRef,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private socketIOService: SocketIOService
  ) {
    this.hotKeys = [];
    for (let i = 0; i <= 9; i++) {
      this.hotKeys.push(new Hotkey(`shift+ctrl+${i + 1}`, this.gotoCard(i), ['input', 'textarea', 'select']));
    }

    this.hotKeysService.add(this.hotKeys);
    endpointAuthorizationSubscription(store, this);
  }

  async ngOnInit() {
    this.hasCloseEvent = this.close.observers.length > 0;

    this.user = this.store.snapshot((state) => state.user.user);

    if (this.user && this.notificationsAuthorized) await this.getNotificationSubscription();
    if (this.entityAuthorized) this.getUsersConnected();
  }

  getUsersConnected() {
    this.socketIOService.onlineUsersInfo.subscribe((res) => {
      const webSocketSessions = res.webSocketSessions;
      const tempUsersConnected = webSocketSessions
        .filter(
          (item, index) =>
            item.entityId === this.entityKey &&
            item.entityType === this.sourceEntityType &&
            this.user.id !== item.userId &&
            webSocketSessions.findIndex((obj) => obj.userId === item.userId && obj.entityId === item.entityId && obj.entityType === item.entityType) === index
        )
        .map((user) => {
          const item = { ...user, fullname: user.firstName + ' ' + user.lastName, initials: user.firstName[0] + user.lastName[0], color: getRandomColor() };
          if (!this.usersConnected.some((u) => u.userId === user.userId)) this.usersConnected.push(item);
          return item;
        });

      this.usersConnected = this.usersConnected.filter((user) => {
        return tempUsersConnected.find((tempUser) => tempUser.userId === user.userId);
      });
    });
  }

  async getNotificationSubscription() {
    this.subscriptionRequest = {
      entityType: this.sourceEntityType,
      entityId: this.entityKey,
      userId: this.user.id,
    };

    const subscription = await this.api.run<{ notificationSubscription: NotificationsSubscriptions }>(endpoints.getNotificationSubscription, {
      filters: this.subscriptionRequest,
    });

    this.subscribed = subscription && subscription.notificationSubscription !== null ? true : false;
  }

  ngOnDestroy(): void {
    this.hotKeysService.remove(this.hotKeys);
  }

  clickClose() {
    if (this.hasCloseEvent) this.close.emit();
    else {
      let previous = this.store.snapshot((state) => state.layout.anchorPoint);
      if (previous) {
        this.router.navigate([previous.url]);
      } else {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    }
  }

  gotoCard(i: number) {
    return (_event: KeyboardEvent, _combo: string) => {
      let card = this.cardChildren.toArray()[i];

      if (!card) return false;

      let cards: HTMLElement[] = this.elementRef.nativeElement.querySelectorAll('.collapsible-card-header');
      if (cards[i]) {
        if (!card.isOpen) {
          card.toggleCard(null);
        }
        setTimeout(() => {
          cards[i].scrollIntoView({ behavior: 'auto', block: 'start' });
        });
      }
      return false;
    };
  }

  clickNotificationSubscription() {
    if (this.subscriptionRequest) {
      let endpoint: endpoints = endpoints.createNotificationSubscription;
      let request: CreateNotificationSubscription | { filters: CreateNotificationSubscription } = this.subscriptionRequest;
      let spinnerMessage: string = 'Enabling';
      let responseMessage: string = 'Enabled';

      this.subscribed = !this.subscribed;

      if (!this.subscribed) {
        endpoint = endpoints.deleteNotificationSubscription;
        request = { filters: this.subscriptionRequest };
        spinnerMessage = 'Disabling';
        responseMessage = 'Disabled';
      }

      const rid = this.spinner.startRequest(`${spinnerMessage} Notifications`);

      this.api.rpc<NotificationsSubscriptions>(endpoint, request, null).subscribe((res) => {
        this.spinner.completeRequest(rid);
        if (res) {
          this.notificationService.show(`Notifications ${responseMessage}`, this.subscribed ? 'success' : 'warning');
        }
      });
    }
  }

  get notificationSubscriptionAuthorized() {
    return this.authorized[endpoints.getNotificationSubscription];
  }

  get entityAuthorized() {
    return this.entityKey && this.sourceEntityType;
  }

  get notificationsAuthorized() {
    return this.notificationSubscriptionAuthorized && this.enableNotifications && this.entityAuthorized;
  }

  async onNextOrPreviusEntityNavigate(event: { entityKey: number }) {
    if (this.notificationsAuthorized) {
      this.entityKey = event.entityKey;
      await this.getNotificationSubscription();
    }
  }
}

export type UsersConnected = SocketSessions & {
  fullname: string;
  initials: string;
  color: string;
};
