<form class="form-inline" style="height: 100%; width: 100%">
  <div class="d-flex" style="height: 100%; width: 100%">
    <kendo-combobox
      class="global-search"
      #searchBar
      [data]="results | async"
      [textField]="'primaryLabel'"
      [valueField]="'uuid'"
      [formControl]="searchTerm"
      [filterable]="true"
      [itemDisabled]="itemDisabled"
      [loading]="searching"
      (valueChange)="setSearchValue($event)"
      (keydown.enter)="onKeydown($event, false)"
      (keydown.control.enter)="onKeydown($event, true)"
    >
      <ng-template kendoAutoCompleteItemTemplate let-dataItem>
        <div
          class="d-flex justify-content-between w-100"
          [ngClass]="{ 'stripes-background': dataItem.archived === 'Y' && (dataItem.entityType === contactEntityType || dataItem.entityType === contractEntityType) }"
        >
          <div class="d-flex w-100" (click)="selectResult(dataItem, false)">
            <div style="font-size: 2rem; padding-right: 10px">
              <i [ngClass]="dataItem.icon"></i>
            </div>
            <div>
              <div class="result-label">
                {{ dataItem.primaryLabel }}
                <span class="archived-color" *ngIf="dataItem.archived === 'Y' && (dataItem.entityType === contactEntityType || dataItem.entityType === contractEntityType)">(Archived)</span>
              </div>
              <div class="result-type">{{ dataItem.secondaryLabel }}</div>
            </div>
          </div>
          <a class="pe-1 ps-1" (click)="selectResult(dataItem, true)">
            <i class="k-icon k-i-hyperlink-open" style="color: rgba(0, 0, 0, 0.85); height: 1.2em"></i>
          </a>
        </div>
      </ng-template>
      <ng-template kendoDropDownListNoDataTemplate>
        {{ searching ? 'Searching...' : prompt }}
      </ng-template>
    </kendo-combobox>
  </div>
</form>
<div class="grey-background" *ngIf="searchBar.isFocused"></div>
