import _, { round } from 'lodash';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, createFormCallback, prefillCallback, submitFormCallback } from './types';
import { JournalReconciliation } from 'src/lib/newBackendTypes/journalReconciliation';

export const reconcileJournalEntriesPrefill: prefillCallback<ReconcileJournalEntriesPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const matchableIds = Array.isArray(id) ? id : [id];
      const matchableItemsResponse = await api.run<ListResponse<JournalReconciliation>>(endpoints.listJournalReconciliation, {
        filters: { journalEntryId: matchableIds },
      });
      const items = matchableItemsResponse.list;

      return { items };
    })()
  );
};

const reconcileJournalEntriesCheck: checkPrefillCallback<ReconcileJournalEntriesPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (!(prefill.items && prefill.items.length > 1)) return of('Unable to reconcile journals: entries selected should be more than 1.');

  const entryIdsToMatch = new Set(prefill.items);
  const firstEntry = prefill.items[0];

  if (prefill.items.length !== entryIdsToMatch.size) return of('Unable to reconcile journals: some entries are not available.');

  const accountId = firstEntry.accountId;
  let amountSum = 0;
  let baseAmountSum = 0;

  for (const journalEntry of prefill.items) {
    if (journalEntry.accountId !== accountId) return of('Unable to reconcile journals: all entries to match should belong to the same account.');
    amountSum += journalEntry.amount;
    baseAmountSum += journalEntry.baseAmount;
  }

  if (round(amountSum, 2) !== 0 || round(baseAmountSum, 2) !== 0) return of('Unable to reconcile journals: the sum of the amounts and base amounts in the entries to match should be zero.');

  return of(true);
};

const reconcileJournalEntriesForm: createFormCallback<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Are you sure you want to reconcile Journal Entries: ${prefill.items ? prefill.items.map((item) => `${item.journalNumber} - ${item.entryPosition}`).join(', ') + '?' : 'Unknown'}`,
      style: { 'margin-bottom': '25px', display: 'flex', 'justify-content': 'center' },
    },
    {
      type: 'Checkbox',
      field: 'enableInventoryAssetsPairing',
      label: 'Reconcile with coupling (inventory)',
      valueMask: { true: YN.Y, false: YN.N },
      startingValue: YN.Y,
      inLineLabel: true,
    },
  ];
};

const reconcileJournalEntriesCallback: submitFormCallback<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<JournalReconciliation[] | null>((resolve, reject) => {
      (async () => {
        const journalEntryIds: number[] = [];
        const matchableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
        for (const entry of matchableItems) {
          journalEntryIds.push(entry.journalEntryId);
        }

        const request: ReconcileJournalsRequest = {
          title: result.title,
          journalEntryIds,
          enableInventoryAssetsPairing: result.enableInventoryAssetsPairing,
        };
        try {
          const JournalReconciliation = await api.run<JournalReconciliation[]>(endpoints.reconcileJournalEntries, request, null);
          return resolve(JournalReconciliation);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (!!res && Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => !_.isNil(item));
          if (response.length > 0) return prompt.htmlDialog('Success', `<div style="white-space: pre">Journals successfully reconciled: ${response.length} line(s).</div>`);
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the journals were reconciled and try again if necessary.</div>`);
      })
  );
};

export const reconcileJournalEntriesPreset: DynamicFormConstant<ReconcileJournalEntriesPrefill, ReconcileJournalsRequest> = {
  allowMultipleRows: true,
  checkPrefill: reconcileJournalEntriesCheck,
  createForm: reconcileJournalEntriesForm,
  entityType: SourceEntityType.JOURNAL_ENTRY_ID,
  getPrefill: reconcileJournalEntriesPrefill,
  label: 'Reconcile Journal Entries',
  submitForm: reconcileJournalEntriesCallback,
  title: 'Reconcile Journal Entries',
  value: DynamicFormType.CREATE_JOURNAL_RECONCILIATION,
  endpoints: [endpoints.reconcileJournalEntries, endpoints.listJournalReconciliation],
  width: 600,
};

export type ReconcileJournalsRequest = { title: string; journalEntryIds: number[]; enableInventoryAssetsPairing?: YN };
export type ReconcileJournalEntriesPrefill = { items: JournalReconciliation[]; enableInventoryAssetsPairing: YN };
