import { Component, ComponentFactory, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { IClass, ModalFormComponent, WindowAction } from 'src/app/core/services/selector-popup.service';
import { ModalWindowHostDirective } from './modal-window.directive';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
})
export class ModalWindowComponent<T, C extends ModalFormComponent<T>> implements OnInit {
  @ViewChild(ModalWindowHostDirective, { static: false })
  container: ModalWindowHostDirective;

  public componentType: IClass<T, C>;

  result: Observable<WindowAction<T, C>>;
  private observer: Observer<WindowAction<T, C>>;
  private viewInit: Observable<C>;
  private viewInitObserver: Observer<C>;

  activeComponent?: C;

  actions: WindowAction<T, C>[];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.result = new Observable<WindowAction<T, C>>((s) => {
      this.observer = s;
    });

    this.viewInit = new Observable<C>((o) => {
      this.viewInitObserver = o;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(this.componentType);
    let comp: C = this.container.viewContainerRef.createComponent(factory).instance;
    this.activeComponent;
    this.viewInitObserver.next(comp);
  }

  loadWindow(componentType: IClass<T, C>, actions: WindowAction<T, C>[]) {
    this.componentType = componentType;
    this.actions = actions;

    return this.viewInit;
  }

  actionClicked(action: WindowAction<T, C>) {
    this.observer.next(action);
  }
}
