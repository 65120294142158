import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getErrorLabel } from 'src/lib/genericValidators';

@UntilDestroy()
@Component({
  selector: 'form-error-label',
  template: `<label *ngIf="!!error" class="error-label" [ngClass]="{ 'active-error': !!error }">{{ error }}</label>`,
  encapsulation: ViewEncapsulation.None,
})
export class FormErrorLabelComponent implements OnInit, OnDestroy {
  @Input()
  control: AbstractControl;

  error: string | null;

  constructor() {
    this.error = null;
  }

  ngOnInit(): void {
    if (!!this.control) {
      this.error = this.getErrorMessage();
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
        this.error = this.getErrorMessage();
      });
    }
  }

  ngOnDestroy() {}

  private getErrorMessage() {
    if (!this.control || this.control.valid || this.control.untouched) return null;

    return getErrorLabel(this.control);
  }
}
