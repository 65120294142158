import { from, of } from 'rxjs';
import { _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { InvoiceHeader, SourceEntityType, UpdateVoucherRequest } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { paymentTermDropdown } from 'src/lib/commonTypes';
import { Validators } from '@angular/forms';

const updateVoucherPaymentTermPrefill: prefillCallback<InvoiceHeader> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const voucher = await api.run<InvoiceHeader>(endpoints.getVoucher, { filters: { id } });
      return voucher;
    })()
  );
};

const updateVoucherPaymentTermCheck: checkPrefillCallback<InvoiceHeader> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to update Payment Term: Voucher not found');
  return of(true);
};

const updateVoucherPaymentTermForm: createFormCallback<InvoiceHeader, UpdateVoucherPaymentTermForm> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Voucher number: ${prefill ? prefill.number : 'Unknown'}`,
      style: { 'font-weight': 'bold', 'margin-bottom': '15px' },
    },
    _fe('paymentTerm', 'Payment Term', paymentTermDropdown(), prefill?.paymentTerm, Validators.required),
  ];
};

const updateVoucherPaymentTermCallback: submitFormCallback<InvoiceHeader, UpdateVoucherPaymentTermForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<InvoiceHeader>((resolve, reject) => {
      (async () => {
        const request: UpdateVoucherRequest = {
          id,
          paymentTermId: form.paymentTerm?.id,
        };
        if (prefill.paymentTermId === request.paymentTermId) return reject('Unable to update Payment Term: Payment Term is the same as the existing one.');
        const updateVoucher = await api.run<InvoiceHeader>(endpoints.updateVoucherPaymentTerm, request, null);
        if (updateVoucher) return resolve(updateVoucher);
        return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Voucher ${res.number} Payment Term successfuly updated.</div>`);
      })
      .catch((err) => {
        if (err) return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Voucher Payment Term was updated and try again if necessary.</div>`);
      })
  );
};

export const updateVoucherPaymentTermPreset: DynamicFormConstant<InvoiceHeader, UpdateVoucherPaymentTermForm> = {
  allowMultipleRows: false,
  checkPrefill: updateVoucherPaymentTermCheck,
  createForm: updateVoucherPaymentTermForm,
  entityType: SourceEntityType.INVOICE_KEY,
  getPrefill: updateVoucherPaymentTermPrefill,
  label: 'Update Voucher Payment Term',
  submitForm: updateVoucherPaymentTermCallback,
  title: 'Update Voucher Payment Term',
  value: DynamicFormType.UPDATE_VOUCHER_PAYMENT_TERM,
  endpoints: [endpoints.updateVoucherPaymentTerm, endpoints.getVoucher],
  width: 600,
};

export type UpdateVoucherPaymentTermForm = Pick<InvoiceHeader, 'paymentTerm'>;
