<div style="display: flex; flex-direction: column; height: 100%">
  <form [formGroup]="filterForm" *ngIf="!readonly">
    <div class="row">
      <vertical-field class="col-12 col-md-5 col-xl-3" [name]="'Counterparty'">
        <dropdown-wrapper [readonly]="preselectedCounterparty" [formControlName]="'contact'" [dropdownConfig]="counterpartyDropdown"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field class="col-12 col-md-5 col-xl-3" [name]="'Budget Category'">
        <dropdown-wrapper [formControlName]="'budgetElement'" [dropdownConfig]="budgetElementDropdown"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field style="padding-left: 15px; padding-right: 15px">
        <button kendoButton type="submit" (click)="fetchServiceOrders()" style="flex-grow: 0.4; width: auto; margin-left: auto">Search</button>
      </vertical-field>
    </div>
  </form>
  <ag-grid-angular class="ag-theme-balham" [rowData]="data" style="width: 100%; flex-grow: 1" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"> </ag-grid-angular>
</div>
