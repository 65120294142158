import { Observable } from 'rxjs';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorResult } from 'src/app/core/services/selector-popup.service';
import { FormElementConfig } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { IDListColumn } from 'src/lib/views';

export type checkPrefillCallback<prefillType> = (delegate: DelegateService, id: any, prefill: Partial<prefillType>, column?: IDListColumn) => Observable<string | boolean>;
export type createFormCallback<prefillType, formType = prefillType> = (
  delegate: DelegateService,
  id: any,
  prefill: Partial<prefillType>,
  column?: IDListColumn
) => (FormElementConfig<formType> | FormElementConfig<formType>[])[];

export type openFormCallback<prefillType, formType = prefillType> = (delgate: DelegateService, id: any, prefill: prefillType, column?: IDListColumn) => Observable<SelectorResult<formType>>;
/**
 * ORDER OF OPERATIONS
 *
 * getPrefill -> checkPrefill -> (createForm OR openForm) -> submitForm
 */
export type DynamicFormConstant<prefillType, formType = prefillType> = {
  label: string;
  value: DynamicFormType;
  /**
   * The entity type the preset is tied to.  If null, it can be used in any id column.
   */
  entityType: SourceEntityType | null;
  width?: number;
  title?: string;
  allowMultipleRows?: boolean;
  /**
   * Get data to hydrate dynamic form or pass to next stage
   */
  getPrefill?: prefillCallback<prefillType>;
  /**
   * Returns an array of dynamic form fields
   */
  createForm?: createFormCallback<prefillType, formType>;

  /**
   * Opens an existing hardcoded form
   */
  openForm?: openFormCallback<prefillType, formType>;

  /**
   * Takes data from create/open form and submits
   */
  submitForm: submitFormCallback<prefillType, formType>;

  /**
   * Checks if form can be used based on existing state
   */
  checkPrefill?: checkPrefillCallback<prefillType>;

  /**
   * Endpoints that are used by the form and thus are required for the form to be authorized
   */
  endpoints?: endpoints[];
};

export enum DynamicFormType {
  BOOKING_STATUS = 1,
  FINANCE_RELEASE = 2,
  FINANCE_RELEASE_TODAY = 3,
  CARRIER_TRACKING = 4,
  ATA_UPDATE = 5,
  INVOICE_SERVICE_ORDER = 6,
  REVERSE_PURCHASE_TICKET = 7,
  UPDATE_STB_STATUS = 8,
  CONTRACT_PAYMENT_TERM = 9,
  ARCHIVE_CONTRACT = 10,
  CANCEL_CONTRACT = 11,
  EDIT_CONTRACTS_EXEC = 12,
  EDIT_CONTRACT_LINE_EXEC = 13,
  CONTRACT_LINE_ROLLOVER = 14,
  MARK_BOOKING_FOR_TRACKING = 15,
  UPDATE_ADVANCE = 16,
  PRINT_ADVANCE = 17,
  MARK_ADVANCE = 18,
  DELETE_ADVANCE = 19,
  PRINT_DOCUMENT = 20,
  UPDATE_REFERENCE_RECIEVED = 21,
  CREATE_CLIENT_INVOICE = 22,
  GENERATE_DRAFT_INVOICE = 25,
  UPDATE_SHIPMENT_SALE_PRICE = 26,
  UPDATE_PRICE_FIXATION = 27,
  CREATE_ADVANCE_PAYMENT = 28,
  SET_INVOICE_SENT_DATE = 29,
  UPSERT_PREMIUM_FIXATION = 30,
  DELETE_PREMIUM_FIXATION = 31,
  CREATE_PAY_ORDER = 32,
  CREATE_MATCHING_PAYMENT = 33,
  CREATE_BOOKING_PAY_ORDER = 34,
  CREATE_JOURNAL_RECONCILIATION = 35,
  REVERSE_VOUCHER = 36,
  DELETE_JOURNAL_RECONCILIATION = 37,
  UNBOOKING_PAY_ORDER = 38,
  CREATE_PROPOSAL_PAYMENT = 39,
  APPLY_ITEMS_TO_VOUCHER = 40,
  REVERSE_JOURNAL = 41,
  PROCESS_AUTOMATED_REPORT = 42,
  CREATE_PRICE_FIXATION = 43,
  UPDATE_PERMANENT_DATE = 44,
  RUN_FX_REVALUATION = 45,
  DELETE_PRICE_FIXATION = 46,
  CREATE_FUTURE_MATCHING = 47,
  REVERSE_FUTURE_MATCHING = 48,
  UPDATE_SHIPMENT_VALUE_DATE = 49,
  FINISH_CONTRACT_LINE = 50,
  RECONCILE_PAYMENT = 51,
  APPLY_FIXATION_TO_INVOICE = 52,
  UNAPPLY_FIXATION_FROM_INVOICE = 53,
  INVOICE_SEARCH_PAYMENTS = 54,
  DELETE_FINANCE_RELEASE = 55,
  UPDATE_VOUCHER_TITLE_REFS = 56,
  UPDATE_BOOKING_COB = 57,
  UPDATE_BOOKING_TRACKING_DATES = 58,
  SHIPMENT_FIXATION_SUMMARY = 59,
  BANKING_TRACKING = 60,
  CUSTOMER_TRACKING = 61,
  MANAGE_QP = 62,
  ARCHIVE_UNARCHIVE_ITEM = 63,
  UPDATE_ITEM = 64,
  CREATE_SURCHARGE_TYPE = 65,
  SET_IVA_DECLARATION_DATE = 66,
  ARCHIVE_UNARCHIVE_GLACCOUNT = 67,
  INVENTORY_WRITE_OFF = 68,
  PRINT_PRESENTATION = 69,
  DELETE_SERVICE_ORDER = 70,
  REVERSE_INVENTORY_WRITE_OFF = 71,
  DELETE_APPOINTMENT_REFERENCE = 72,
  CREATE_APPOINTMENT_REFERENCE = 73,
  UPDATE_ITEM_MUP = 74,
  ARCHIVE_UNARCHIVE_DISCREPANCY = 75,
  LINK_UNLINK_DISCREPANCIES = 77,
  UPDATE_VOUCHER_PAYMENT_TERM = 78,
  ADVANCE_PAYMENT_MANAGER = 79,
  UPDATE_NET_POSITION_ENTRY = 80,
  UPDATE_NET_POSITION_JOURNAL = 81,
  UPDATE_NET_POSITION_JOURNAL_DATES = 82,
  FIX_MUP_FOR_ENTRIES_BY_ENTITY = 83,
}

export type prefillCallback<prefillType> = (delegate: DelegateService, id: any, column?: IDListColumn, data?: any[]) => Observable<Partial<prefillType> | string>;

export type submitFormCallback<prefillType, formType = prefillType> = (delegate: DelegateService, id: any, form: formType, prefill: Partial<prefillType>, column?: IDListColumn) => Observable<any>;
