import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, NgControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogAction, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { Observable, combineLatest, from, lastValueFrom, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { CustomDialogResult, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { InnerFormGroupFormElement } from 'src/lib/InnerFormGroupFormElement';
import { ListResponse } from 'src/lib/ListResponse';
import { SubEntityContainer } from 'src/lib/SubEntityContainer';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized, markFormGroupTouched } from 'src/lib/helperFunctions';
import { Entity, PrintDocumentResponse, SourceEntityType, getEntityName } from 'src/lib/newBackendTypes';
import { Document, DocumentVersion, StorageTypes, UpdateDocumentRequest, UploadDocumentParams, cleanFileName } from 'src/lib/newBackendTypes/document';
import { MetadataType } from 'src/lib/newBackendTypes/documentMetadata';
import { DocumentType, DocumentTypes, EntityDocumentTypeMap } from 'src/lib/newBackendTypes/documentType';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { _fe } from '../dynamic-form/dynamic-form.component';
import { FileSelectWidgetComponent } from '../file-select-widget/file-select-widget.component';
import { PrintDocumentComponent, PrintDocumentForm } from '../print-document/print-document.component';
import { UntilDestroy } from '@ngneat/until-destroy';

type ExistingDocumentRow = Pick<Document, 'id' | 'fileName' | 'metadata' | 'entityId' | 'entityType' | 'fsPath' | 'storageType'> & { file?: File } & {
  documentType: string;
  comments: string;
  documentDate: string;
  fileNameChanged: boolean;
};

type NewDocumentRow = Partial<ExistingDocumentRow>;

type DocumentRow = NewDocumentRow | ExistingDocumentRow;

@UntilDestroy()
@Component({
  selector: 'entity-documents',
  templateUrl: './entity-documents.component.html',
  styleUrls: ['./entity-documents.component.scss', '../form-table/form-table.component.scss'],
  providers: [
    { provide: SubEntityContainer, useExisting: forwardRef(() => EntityDocumentsComponent) },
    { provide: InnerFormGroupFormElement, useExisting: forwardRef(() => EntityDocumentsComponent) },
  ],
})
export class EntityDocumentsComponent<T extends SourceEntityType> extends SubEntityContainer<Document> {
  @ViewChild('fileSelect', { static: false })
  fileSelect: FileSelectWidgetComponent;

  @Input()
  requiredDocumentTypes: string[];

  @Input()
  entityType: T = null;

  @Input()
  entity?: Entity<T>;

  documentFormArray: UntypedFormArray;

  documentTypes: DocumentType[];

  fileHover: boolean = false;
  fileHoverTimer: number;

  authorized: endpointsAuthorized;

  loadingDocuments = false;

  get generateDocAuthorized() {
    return !!this.entity && this.authorized[endpoints.generateDocumentsFromPacket] && this.authorized[endpoints.listDocumentPackets];
  }

  get uploadAuthorized() {
    return this.authorized[endpoints.uploadDocument] && this.updateAuthorized;
  }

  get updateAuthorized() {
    return this.authorized[endpoints.updateDocument];
  }

  get versionsAuthorized() {
    return this.authorized[endpoints.listDocumentVersions];
  }

  get downloadAuthorized() {
    return this.authorized[endpoints.downloadDocument];
  }

  get unlinkAuthorized() {
    return this.authorized[endpoints.unlinkDocument];
  }

  get updateNameAuthorized() {
    return this.authorized[endpoints.updateDocumentName];
  }

  constructor(
    ngControl: NgControl,
    store: Store,
    route: ActivatedRoute,
    private api: ThalosApiService,
    private uploadService: FileUploadService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private popupService: SelectorPopupService
  ) {
    super(route, ngControl, store);
    this.documentFormArray = new UntypedFormArray([]);
    this.requiredDocumentTypes = [];
    this.documentTypes = DocumentTypes;

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit(): void {
    this.getFormControl().setValidators(this.validate);
    super.ngOnInit();

    if (this.entityType) {
      let matchingDocumentTypes = EntityDocumentTypeMap.get(this.entityType);
      if (matchingDocumentTypes) this.documentTypes = matchingDocumentTypes;
    }
  }

  async loadSubForm() {
    this.getDocuments();
  }

  async getDocuments(background = false) {
    if (!this.entityId) return;
    if (!this.authorized[endpoints.listDocuments]) return;

    let filters = { entityId: this.entityId, entityType: this.entityType };
    let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Documents', 0, background, !this.showSpinner);
    setTimeout(async () => {
      this.loadingDocuments = true;
      let docs = await lastValueFrom(
        this.api.rpc<ListResponse<Document>>(endpoints.listDocuments, { filters }, { list: [], count: 0 }).pipe(
          map((res) => {
            return res.list.flatMap((d: Document): DocumentRow | DocumentRow[] => {
              if (d.storageType === StorageTypes.FILESYSTEM_FILE) return [];
              let commentsMeta = d.metadata.find((m) => m.type === MetadataType.COMMENT);
              let comments = commentsMeta ? commentsMeta.value : '';

              let typeMeta = d.metadata.find((m) => m.type === MetadataType.DOCUMENT_TYPE);
              let documentType = typeMeta ? typeMeta.value : '';
              let documentDate = '';
              if (d.uploadDate) {
                const uploadDate = new Date(d.uploadDate);
                documentDate = uploadDate.getUTCMonth() + 1 + '/' + uploadDate.getUTCDate() + '/' + uploadDate.getUTCFullYear();
              }

              return {
                ...d,
                comments,
                documentType,
                documentDate,
              };
            });
          })
        )
      );
      this.loadingDocuments = false;
      this.spinnerService.completeRequest(rid);
      this.documentFormArray.clear();
      for (let _ of docs) {
        this.addDocument();
      }
      this.documentFormArray.patchValue(docs);
    });
  }

  validate = (control: UntypedFormControl) => {
    let rows: DocumentRow[] = this.documentFormArray.value;
    return !this.documentFormArray.valid || rows.some((d) => !d.file && !isExistingDocument(d) && this.requiredDocumentTypes.some((req) => d.documentType === req))
      ? { missingRequirement: true }
      : null;
  };

  clickAddDocument() {
    this.fileSelect.clickSelectFile();
  }

  fileSelected(event: File[]) {
    if (!!event) {
      for (let file of event) {
        const newFile = new File([file], cleanFileName(file.name));
        this.addDocument(newFile);
      }
    }
  }

  fileSelectedRow(event: File[], row: UntypedFormGroup) {
    if (!!event && event.length >= 1) {
      let file = event[0];
      const newFile = new File([file], cleanFileName(file.name));
      row.patchValue({ file: newFile });
      if (!row.value.id) {
        row.patchValue({ fileName: newFile.name });
        row.get('fileName').markAsTouched();
      }
    }
  }

  removeNewDocument(row: UntypedFormGroup) {
    row.patchValue({ file: null });
  }

  addDocument(fileOrDoc: File | DocumentRow = null, type: string = null) {
    let file: File | null = isNotFile(fileOrDoc) ? null : fileOrDoc ?? null;
    let fileName: string | null = isNotFile(fileOrDoc) ? null : fileOrDoc?.name ?? null;
    let fg: TypedFormGroup<DocumentRow> = new TypedFormGroup<DocumentRow>({
      id: new UntypedFormControl(),
      comments: new UntypedFormControl(''),
      documentType: new UntypedFormControl(type),
      entityType: new UntypedFormControl(),
      entityId: new UntypedFormControl(),
      fileName: new UntypedFormControl(fileName),
      fileNameChanged: new UntypedFormControl(false),
      file: new UntypedFormControl(file),
      metadata: new UntypedFormControl(),
      fsPath: new UntypedFormControl(),
      storageType: new UntypedFormControl(StorageTypes.DATABASE_FILE),
      documentDate: new UntypedFormControl(''),
    });

    fg.get('fileName').setValidators(this.fileNameValidator(fg));

    if (isNotFile(fileOrDoc)) {
      fg.patchValue(fileOrDoc);
    }

    fg.get('file').setValidators((c) => {
      return !!fg.value.id ? null : Validators.required(c);
    });

    fg.get('documentType').setValidators((c) => {
      return fg.value.storageType === 2 && (!fg.value.id || fg.get('comments').dirty || !!fg.value.file) ? Validators.required(c) : null;
    });

    fg.get('fileName').markAsTouched({ onlySelf: true });

    this.documentFormArray.push(fg);
  }

  dropHandler(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    window.clearTimeout(this.fileHoverTimer);
    this.fileHover = false;

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          var file = event.dataTransfer.items[i].getAsFile();
          this.addDocument(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.files.length; i++) {
        let item = event.dataTransfer.files[i];
        if (item.kind === 'file') {
          var file = event.dataTransfer.files[i].getAsFile();
          this.addDocument(file);
        }
      }
    }
  }

  dragOverHandler(ev) {
    this.fileHover = true;

    window.clearTimeout(this.fileHoverTimer);
    this.fileHoverTimer = window.setTimeout(() => {
      this.fileHover = false;
    }, 10000);

    if (ev && ev.dataTransfer && ev.dataTransfer.dropEffect != 'copy') {
      ev.dataTransfer.dropEffect = 'copy';
    }

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    ev.preventDefault();
  }

  dragLeaveHandler(ev) {
    this.fileHover = false;
    window.clearInterval(this.fileHoverTimer);
  }

  clickDelete(index: number) {
    this.dialogService
      .open({
        content: 'Are you sure you wish to delete this document?  This cannot be undone.',
        actions: [
          {
            text: 'Cancel',
          },
          {
            themeColor: 'primary',
            text: 'Delete',
          },
        ],
      })
      .result.subscribe(async (res) => {
        if (!(res instanceof DialogCloseResult) && res.text === 'Delete') {
          this.deleteDocument(index);
        }
      });
  }

  clickUnlink(index: number) {
    this.dialogService
      .open({
        content: 'Are you sure you wish to unlink this document',
        actions: [
          {
            text: 'Cancel',
          },
          {
            themeColor: 'primary',
            text: 'Unlink',
          },
        ],
      })
      .result.subscribe(async (res) => {
        if (!(res instanceof DialogCloseResult) && res.text === 'Unlink') {
          this.unlinkDocument(index);
        }
      });
  }

  async deleteDocument(index: number) {
    let row: DocumentRow = this.documentFormArray.value[index];
    if (!isExistingDocument(row)) {
      this.documentFormArray.removeAt(index);
    }
  }

  async unlinkDocument(index: number) {
    let row: DocumentRow = this.documentFormArray.value[index];
    if (isExistingDocument(row)) {
      let rid = this.spinnerService.startRequest('Unlinking File');
      let res = await lastValueFrom(this.api.rpc(endpoints.unlinkDocument, { documentId: row.id, entityType: this.entityType, entityId: this.entityId }, false));
      this.spinnerService.completeRequest(rid);
      if (res !== false) this.documentFormArray.removeAt(index);
    } else {
      this.documentFormArray.removeAt(index);
    }
  }

  async clickDownload(fileName: string, docId: number, event?: DocumentVersion) {
    if (!this.downloadAuthorized) return;
    let rid = this.spinnerService.startRequest('Fetching file');
    let file = await lastValueFrom(this.uploadService.download(docId, event ? event.version : null));
    this.spinnerService.completeRequest(rid);
    if (!!file) this.downloadFile(file, fileName);
  }

  downloadFile(data: ArrayBuffer, fileName: string) {
    let blob = new Blob([data]);
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  save(entityId: number): Observable<boolean> {
    let observables: Observable<Document | number>[] = [];
    let rows: DocumentRow[] = this.documentFormArray.value;
    for (let row of rows) {
      if (row.storageType !== StorageTypes.DATABASE_FILE) continue;
      if (row.file && this.uploadAuthorized) {
        let args: UploadDocumentParams = {
          entityId: entityId,
          entityType: this.entityType,
        };
        if (isExistingDocument(row)) {
          args.documentId = row.id;
        }
        let obs = this.uploadService.upload<Document>(row.file, args);
        if (row.documentType || row.comments) {
          obs = obs.pipe(
            switchMap((d): Observable<number | Document> => {
              if (typeof d === 'number') return of(d);
              let updateArgs: UpdateDocumentRequest = {
                id: d.id,
                metadata: [],
              };
              if (row.comments !== undefined) {
                updateArgs.metadata.push({ type: MetadataType.COMMENT, value: row.comments });
              }
              if (row.documentType !== undefined) {
                updateArgs.metadata.push({
                  type: MetadataType.DOCUMENT_TYPE,
                  value: row.documentType,
                });
              }
              return this.api.rpc<Document>(endpoints.updateDocument, updateArgs, null);
            })
          );
        }
        observables.push(obs);
      } else if (this.updateAuthorized) {
        if (isExistingDocument(row)) {
          let args: UpdateDocumentRequest = {
            id: row.id,
            metadata: row.metadata,
          };
          if (row.comments !== undefined) {
            let existingCommentMeta = row.metadata ? row.metadata.find((m) => m.type === MetadataType.COMMENT) : undefined;
            if (existingCommentMeta === undefined) args.metadata.push({ type: MetadataType.COMMENT, value: row.comments });
            else existingCommentMeta.value = row.comments;
          }
          if (row.documentType !== undefined) {
            let existingTypeMeta = row.metadata ? row.metadata.find((m) => m.type === MetadataType.DOCUMENT_TYPE) : undefined;
            if (existingTypeMeta === undefined)
              args.metadata.push({
                type: MetadataType.DOCUMENT_TYPE,
                value: row.documentType ?? '',
              });
            else existingTypeMeta.value = row.documentType;
          }
          if (row.fileNameChanged && this.authorized[endpoints.updateDocumentName]) {
            observables.push(this.api.rpc<Document>(endpoints.updateDocumentName, { documentId: row.id, name: row.fileName }, null));
          }

          let obs = this.api.rpc<Document>(endpoints.updateDocument, args, null);
          observables.push(obs);
        }
      }
    }
    if (observables.length === 0) return of(true);
    else {
      let rId;
      return of(null).pipe(
        tap(() => {
          rId = this.spinnerService.startRequest('Uploading Documents');
        }),
        switchMap(() => {
          return combineLatest(observables);
        }),
        map((res) => {
          let totalProgress = 0;
          let complete = 0;
          for (let status of res) {
            if (typeof status === 'number') {
              totalProgress += status;
            } else {
              totalProgress += 100;
              complete++;
            }
          }
          let progress = Math.floor(totalProgress / observables.length);
          this.spinnerService.updateRequest(rId, {
            progress,
            text: `${complete}/${observables.length} documents uploaded`,
          });
          return res;
        }),
        filter((res) => res.every((r) => typeof r !== 'number')),
        tap((_) => {
          this.spinnerService.completeRequest(rId);
        }),
        map((docs: Document[]) => {
          if (docs.some((d) => d === null)) return false;
          else return true;
        })
      );
    }
  }

  public clickSaveChanges() {
    markFormGroupTouched(this.documentFormArray);
    if (!this.entityId) return;
    if (this.documentFormArray.invalid) {
      this.dialogService.open({
        title: 'Invalid',
        content: 'One or more field(s) are invalid or missing',
      });
      return;
    }
    this.save(this.entityId).subscribe((res) => {
      if (!!res) {
        this.dialogService
          .open({
            title: 'Success',
            content: 'Documents saved successfully',
          })
          .result.subscribe(() => {
            this.loadSubForm();
          });
      }
    });
  }

  public markAsTouched() {
    markFormGroupTouched(this.documentFormArray);
  }

  public fileNameValidator(fg?: UntypedFormGroup) {
    return (control: AbstractControl) => {
      let val = control.value;

      //don't validate existing documents
      if (fg?.value.id) return null;

      let fileNameRegex = /^[a-zA-Z0-9\_\.\(\)\ \-]+\.[A-Za-z0-9]{3,4}$/;
      if (val) {
        return !fileNameRegex.test(val)
          ? {
              custom: `File name has unsupported characters, only spaces, alphanumeric characters, and the following punctuation are allowed: _ - . ) (`,
            }
          : null;
      }
      return null;
    };
  }

  public gotoFilesystemFile(doc: DocumentRow) {
    if (!this.isWindows || doc.storageType !== StorageTypes.FILESYSTEM_FOLDER || !doc.fsPath) return;

    this.openLinkedDox(doc.fsPath);
  }

  private handlePrintResponse(packet: PrintDocumentResponse) {
    switch (packet.locationType) {
      case StorageTypes.DATABASE_FILE:
        if (packet.documentId !== null) {
          let rid = this.spinnerService.startRequest('Downloading');
          this.uploadService.download(packet.documentId).subscribe((res) => {
            this.spinnerService.completeRequest(rid);
            if (res) {
              this.downloadFile(res, packet.fileName);
            }
          });
        }
        break;
      case StorageTypes.URL:
        window.open(packet.fileName);
        break;
      case StorageTypes.FILESYSTEM_FOLDER:
      case StorageTypes.FILESYSTEM_FILE:
      default:
        break;
    }
  }

  private openLinkedDox(path: string) {
    let baseUrl = path.replace(/\\/g, '/');

    let finalUrl = `linkeddox:${baseUrl}`;
    window.open(finalUrl);
  }

  clickPrintDocument() {
    if (!this.entityId || !this.entityType || !this.generateDocAuthorized) return;
    let entityName = getEntityName(this.entityType, this.entity);
    this.popupService
      .openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
        title: 'Generate Document',
        submitButtonText: 'Generate',
        initializer: (p: PrintDocumentComponent) => {
          p.documents = this.documentFormArray.value;
          p.entityName = entityName;
          p.entityType = this.entityType;
        },
        maxWidth: 400,
      })
      .subscribe((popupResult) => {
        if (popupResult !== 'Close') {
          let request = {
            packetId: popupResult.packet?.id,
            outputName: popupResult.saveAs,
            savingLocation: popupResult.location === -1 ? null : popupResult.location,
            entityId: this.entityId,
            copies: popupResult.copies,
          };
          let rid = this.spinnerService.startRequest('Generating Document');
          this.api.rpc<any>(endpoints.generateDocumentsFromPacket, request, null, { blockRedirect: true }).subscribe((packet) => {
            this.spinnerService.completeRequest(rid);
            if (packet !== null) {
              const actions: CustomDialogResult[] = [{ text: 'Close' }];
              if (this.isWindows || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
                actions.push({ text: 'Open File', themeColor: 'primary' });
              }
              let unsavedDocs: DocumentRow[] = (this.documentFormArray.value ?? []).filter((d) => !isExistingDocument(d));
              from(this.getDocuments(true)).subscribe(() => {
                for (let d of unsavedDocs) {
                  this.addDocument(d);
                }
              });
              this.dialogService
                .open({
                  title: 'Success',
                  content: 'File generated successfully',
                  actions,
                })
                .result.subscribe((action) => {
                  if ((action as DialogAction).text === 'Open File') {
                    this.handlePrintResponse(packet);
                  }
                });
            }
          });
        }
      });
  }

  public openFileNameEdit(row: TypedFormGroup<DocumentRow>) {
    if (!row.value.file && !(row.value.id || this.updateNameAuthorized)) return;
    this.popupService
      .dynamicForm<{ fileName: string }>('File Name', { fileName: row.value.fileName }, 350, _fe('fileName', 'File Name', 'Text', '', [Validators.required, this.fileNameValidator()]))
      .subscribe((res) => {
        if (res !== 'Close') {
          if (!row.value.id) {
            let file = new File([row.value.file], res.fileName);
            row.patchValue({ fileName: res.fileName, file });
          } else {
            row.patchValue({ fileName: res.fileName, fileNameChanged: true });
          }
        }
      });
  }

  get isWindows() {
    return /Win/.test(navigator.platform);
  }
}

export function isExistingDocument(val: DocumentRow): val is ExistingDocumentRow {
  return !!(<ExistingDocumentRow>val).id;
}
function isNotFile(val: DocumentRow | File): val is DocumentRow {
  return typeof val === 'object' && !!val && ('fileName' in val || 'fsPath' in val || 'storageType' in val || 'metadata' in val || 'id' in val || 'file' in val);
}
