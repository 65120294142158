import { Pipe, PipeTransform } from '@angular/core';
import { EnumLabels } from 'src/lib/generics';

@Pipe({ name: 'enumLabel', pure: true })
export class EnumLabelPipe implements PipeTransform {
  transform(value: any, labels: EnumLabels<any>): any {
    if (Array.isArray(value)) {
      return value.map((v) => labels.find((l) => l.value === v)?.label || v).join(', ');
    }
    return labels.find((l) => l.value === value)?.label || value;
  }
}
