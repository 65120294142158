// Enumeration with the name of the existing backend Graphql queries. Add new ones as they are implemented
export enum ExistingGraphql {
  alves = 'alves',
  getAlf = 'getAlf',
  glAccounts = 'glAccounts',
  accounts = 'accounts',
  paymentTerms = 'paymentTerms',
  propertyDocuments = 'propertyDocuments',
  budgetElements = 'budgetElements',
  places = 'places',
  waTermGroups = 'waTermGroups',
  waTermDates = 'waTermDates',
}

// Enumeration with the name of custom grahql query
export enum GraphqlNames {
  contacstListReport = 'contacstListReport',
  simpleContactsList = 'simpleContactsList',
  voucherContactsList = 'voucherContactsList',
  voucherGlAccountList = 'voucherGlAccountList',
  voucherGlAccountLinesList = 'voucherGlAccountLinesList',
  simpleGlAccountsList = 'simpleGlAccountsList',
  simpleBankAccountsList = 'simpleBankAccountsList',
  voucherBankAccountList = 'voucherBankAccountList',
  simplePaymentTermsList = 'simplePaymentTermsList',
  simpleShipmentsList = 'simpleShipmentsList',
  simpleBudgetElementsList = 'simpleBudgetElementsList',
  simpleCitiesList = 'simpleCitiesList',
  citiesListReport = 'citiesListReport',
  termGroupsList = 'termGroupsList',
  termDatesList = 'termDatesList',
}
