<kendo-combobox
  #dropdown
  [data]="filteredData || []"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  [ngClass]="{ readonly: readonly }"
  [readonly]="readonly"
  (filterChange)="handleFilter($event)"
  [filterable]="true"
  [suggest]="true"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
  (open)="onOpen()"
  [valueField]="'id'"
  [textField]="'label'"
>
  <ng-template kendoComboBoxItemTemplate let-dataItem>
    <div class="mb-1">
      <div class="">{{ dataItem.label }}</div>
    </div>
  </ng-template>
  <ng-template kendoDropDownListNoDataTemplate>
    <span>No Weight Tickets</span>
  </ng-template>
</kendo-combobox>
