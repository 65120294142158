import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { Contact } from './contact';
import { PhysicalContract, ShipmentPeriodTypes } from './contract';
import { YN } from './enums';
import { PaymentTerm } from './paymentTerm';
import { RedClausePayment } from './redClausePayment';

type Buffer = any;

export enum LCType {
  ADVANCE_PAYMENT_GUARANTEE = 7,
  BID_BOND = 6,
  CLIENT_ADVANCE = 9,
  COUNTER_GUARANTEE = 8,
  FCR_INVOICES = 17,
  GUARANTEE = 4,
  LC_PURCHASE = 1,
  LC_SALE = 2,
  PERFORMANCE_BOND_GUARANTEE = 5,
  PAYMENT_ADVANCE = 3,
  SALE_PROMISSORY_NOTE = 10,
  STANDBY_GUARANTEE = 15,
}

export const LCTypes = enumOptionsFromEnum(LCType);

export enum LCStatus {
  PENDING_AMENDMENTS = 109608,
  OK = 109609,
}

export const LCStatuses = enumOptionsFromEnum(LCStatus);

export type LetterOfCredit = {
  id: number;
  counterpartyId: number;
  currencyId: number;
  bankId: number;
  number: string;
  redClause: YN | null;
  greenClause: YN | null;
  validity: Date | null;
  totalAmount: number | null;
  partialShipments: YN | null;
  multiplePorts: YN | null;
  specialConditions: Buffer;
  fees: number | null;
  documentTransmission: string;
  referenceContract: string;
  type: LCType;
  productId: number | null;
  quantityMax: number;
  quantityMin: number;
  originId: number | null;
  itemId: number | null;
  deliveryType: ShipmentPeriodTypes | null;
  shipmentPeriodStartMonth: number | null;
  deliveryTermId: number | null;
  destinationId: number | null;
  weightBasisId: number | null;
  harbourId: number | null;
  toleranceMin: number;
  toleranceAmountMin: number;
  toleranceAmountMax: number;
  toleranceQuantityMin: number;
  closed: YN;
  unitPrice: number;
  tonnage: number;
  unitPriceUnitId: number;
  unitPriceCurrencyId: number;
  redRefundingDays: number;
  greenRefundingDays: number;
  physicalReference: string;
  totalAmountRed: number;
  totalAmountGreen: number;
  redQuantity: number;
  greenQuantity: number;
  auditingAmountRed: number;
  auditingAmountGreen: number;
  modifiedBy: string;
  modifyDate: Date | null;
  versionNb: number;
  status: LCStatus | null;
  latestShipmentDate: Date | null;
  operatorId: number | null;
  lcDate: Date | null;
  brokerId: number | null;
  toleranceMax: number | null;
  toleranceQuantityMax: number | null;
  presentationPeriod: number | null;
  expiryCityId: number | null;
  memo: Buffer | null;
  termKey: number | null;
  silent: YN | null;
  revolving: YN | null;
  backToBack: YN | null;
  totalQuantity: number | null;
  negotiationDate: Date | null;
  estimatedNegotiationDate: Date | null;
  applicantId: number | null;
  earliestShipmentDate: Date | null;
  noMoreQuantityWarning: YN | null;
  noMoreDateWarning: YN | null;
  transhipment: YN | null;
  negotations: number | null;
  estimatedValidityTime: number | null;
  spreadCalculation: number | null;
  subtype: number | null;
  confStatus: number;
  transferred: YN;
  assigned: YN | null;
  confInstruction: number | null;
  confRequestedDate: Date | null;
  confReceivedDate: Date | null;
  requestStatus: number | null;
  redClauseCheck: YN | null;
  leftAmountToPresent: number | null;
  leftQuantityToPresent: number | null;
  sourceLcId: number | null;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  autoNumber: number | null;
  originalLCAmendment: string;
  allAmendmentsReceived: YN | null;
  discountCharge: number;
  percentDiscount: number;
  firstAmountDiscount: number;
  ndf: number | null;
  forwardRate: number | null;
  discountCurrencyId: number | null;
  formula: number | null;
  discountingDate: Date | null;
  libor: number;
  spread: number;
  confirmationFees: number;
  confirmationAmount: number;
  negotiationFees: number;
  bankCharges: number;
  cpSpotrate: number;
  valueCurrencyId: number | null;
  discountChargeCurrencyId: number | null;
  totalValueCurrencyId: number | null;
  firstAmountCurrencyId: number | null;
  secondAmountCurrencyId: number | null;
  cofAmountCurrencyId: number | null;
  confFeesAmountCurrencyId: number | null;
  negotiationFeesCurrencyId: number | null;
  bankChargesCurrencyId: number | null;
  tradeRate: number;
  tradeRateCurrencyId: number | null;
  cpTotalCurrencyId: number | null;
  forwarderId: number | null;
  firstAmountDiscountManual: YN;
  maturityDate: Date | null;
  clientItemId: number | null;
  pcTermKey: number | null;
  counterpartyReference: string;
  clausePayment?: RedClausePayment;
  contact?: Contact;
  lcContractElement?: LCContractElement;
  bank?: Contact;
  /**
   * @todo - Not actually this type
   */
  paymentTerm?: PaymentTerm;
};
export type LCContractElement = {
  lcId: number | null;
  contractId: number | null;
  advance?: LetterOfCredit;
  contract?: PhysicalContract;
};

export type CreateLCAdvanceRequest = Pick<LetterOfCredit, 'lcDate'> &
  Pick<RedClausePayment, 'dueDate' | 'estimatedPaymentDate' | 'paymentAmount'> & {
    contractId: number;
    automaticAmount: YN;
  };

export type UpdateLCAdvanceRequest = Subset<LetterOfCredit, 'id', 'closed'> & Partial<Omit<CreateLCAdvanceRequest, 'contractId'>>;
