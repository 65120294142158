export enum MiscFeature {
  EDIT_FLEX_VIEW = 1,
  NEW_FLEX_VIEW = 2,
  LIST_FLEX_VIEWS = 3,
  GET_ARRANGED_BOOKING = 4,
  CREATE_ARRANGED_BOOKING = 5,
  LIST_ARRANGED_BOOKINGS = 6,
  GET_LOGISTICS_BOOKING = 7,
  CREATE_LOGISTICS_BOOKING = 8,
  LIST_LOGISTICS_BOOKINGS = 9,
  CONFIG = 10,
  CREATE_ADVANCE = 11,
  LIST_ADVANCES = 12,
  USERS_INFORMATION_DASHBOARD = 13,
  USER_AUTHORIZED_COMPANIES = 14,
}
