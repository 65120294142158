import { ApprovalRequest, ApprovalRequestRequest, ApprovalType, Contact, SourceEntityType } from '.';
import { WithOptional } from '../generics';

export enum ApprovalStatus {
  Y = 'Y',
  N = 'N',
  YN = 'YN',
  NY = 'NY',
}

export type Approval = {
  id: number;
  entityType: SourceEntityType;
  entityId: number;
  approvalTypeId: number;
  status: ApprovalStatus;
  requestedAt: Date;
  requestedById: number;
  approvedAt: Date | null;
  approvedById: number | null;
  approvalType?: ApprovalType;
  requestedBy?: Contact;
  approvedBy?: Contact | null;
  approvalRequests?: ApprovalRequest[];
};

export type CreateApprovalRequest = WithOptional<Pick<Approval, 'entityId' | 'entityType' | 'approvalTypeId' | 'status'>, 'status'> & {
  approvalRequests?: ApprovalRequestRequest[];
};

export type UpdateApprovalRequest = Pick<Approval, 'id'> & Partial<Pick<CreateApprovalRequest, 'status' | 'approvalRequests'>>;

export type CreateRelatedApproval = Omit<CreateApprovalRequest, 'entityId' | 'entityType'>;

export enum CommonApprovalTypes {
  LOGISTICS_APPROVAL = 1,
  WORLD_COMPLIANCE = 2,
  CONTACT_APPROVAL = 3,
  EMPLOYEE_ONBOARDING = 4,
  CBI_REVIEW = 5,
  CBI_APPROVAL = 6,
  DIT = 7,
  FINANCE_APPROVAL = 8,
  TRADING = 9,
}

export const CommonApprovalTypeNames: Map<CommonApprovalTypes, string> = new Map([
  [CommonApprovalTypes.LOGISTICS_APPROVAL, 'Logistics Approval'],
  [CommonApprovalTypes.WORLD_COMPLIANCE, 'World Compliance'],
  [CommonApprovalTypes.CONTACT_APPROVAL, 'Contact Approval'],
  [CommonApprovalTypes.EMPLOYEE_ONBOARDING, 'Employee Onboarding'],
  [CommonApprovalTypes.CBI_REVIEW, 'Payment Info Review'],
  [CommonApprovalTypes.CBI_APPROVAL, 'Payment Info Approval'],
  [CommonApprovalTypes.DIT, 'DIT'],
  [CommonApprovalTypes.FINANCE_APPROVAL, 'Finance Approval'],
  [CommonApprovalTypes.TRADING, 'Trading'],
]);

export type ApprovalQueueResult = {
  id: number;
  entityId: number;
  approvalTypeId: number;
  status: ApprovalStatus;
  requestedAt: Date | null;
  requestedById: number | null;
  requestedByName: string | null;
  entityType: SourceEntityType;
  entityReadableName: string;
};
