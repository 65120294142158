import { Component } from '@angular/core';
import { ColumnApi, GridApi, IStatusPanelParams, StatusPanelDef } from 'ag-grid-community';

@Component({
  selector: 'custom-status-panel-component',
  template: `<span>{{ label }}</span
    ><span style="color: black;">{{ text }}</span>`,
  styles: [
    'input { padding: 5px; margin: 5px }',
    `
      :host {
        margin-left: 4px;
        margin-right: 4px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    `,
  ],
})
export class CustomStatusPanelComponent {
  text = '';
  label = '';
  textGetter?: (params: any) => string;

  params: IStatusPanelParams;

  agInit(params: any & { api: GridApi; columnApi: ColumnApi } & CustomStatusBarComponentParams): void {
    this.params = params;
    if (params.text) {
      this.text = params.text;

      if (params.label) {
        this.label = params.label + ': ';
      }
    }
    if (params.textGetter) {
      this.textGetter = params.textGetter;
      if (params.event) {
        params.api.addEventListener(params.event, (ev) => {
          this.text = this.textGetter(ev);

          if (!!this.text) {
            if (params.label) {
              this.label = params.label + ': ';
            }
          } else {
            this.label = '';
          }
        });
      }
    }
    if (params.textSetter) {
      params.textSetter((text) => {
        this.text = text;
        if (!!this.text) {
          if (params.label) {
            this.label = params.label + ': ';
          }
        } else {
          this.label = '';
        }
      });
    }
  }
}

export type CustomStatusBarComponentParams = {
  /**
   * Label to show before value text
   *
   * EX: Selected MT: 123MT
   * Label is displayed as gray text whereas value is black
   * Colon is appended automatically
   */
  label?: string;

  /**
   * Sets initial text
   */
  text?: string;

  /**
   * Provide callback to return new text string when given event is triggered
   */
  textGetter?: (params: { api: GridApi; columnApi: ColumnApi } & CustomStatusBarComponentParams) => string;

  /**
   * Name of ag-grid event for which to trigger textGetter
   */
  event?: string;

  /**
   * Exposes text setter function to parent component so text can be updated manually
   */
  textSetter?: (setter: (text: string) => void) => void;
};

export function customStatusPanel(statusPanelParams: CustomStatusBarComponentParams, align?: 'left' | 'right' | 'center'): StatusPanelDef {
  return {
    statusPanelParams,
    statusPanelFramework: CustomStatusPanelComponent,
    align,
  };
}
