import { Component, Input, SimpleChanges, forwardRef } from '@angular/core';
import { AbstractControl, NgControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from 'src/app/core/services/store.service';
import { InnerFormGroupFormElement } from 'src/lib/InnerFormGroupFormElement';
import { CheckListEntry, CheckListItem, CheckListValueType, YN } from 'src/lib/newBackendTypes';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';

@UntilDestroy()
@Component({
  selector: 'entity-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  providers: [{ provide: InnerFormGroupFormElement, useExisting: forwardRef(() => ChecklistComponent) }],
})
export class ChecklistComponent extends InnerFormGroupFormElement {
  form: TypedFormArray<CheckListEntryForm>;

  @Input()
  types: CheckListItem[];

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);

    this.form = new TypedFormArray<CheckListEntryForm>([]);
    this.types = null;

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.onChange) {
        this.onChange(this.value);
      }
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['types']) {
      this.createChecklist();
    }
  }

  getValue(): Pick<CheckListEntry, 'itemId' | 'value' | 'checkListItem'>[] {
    return (this.form.value || []).map((cl) => {
      return {
        itemId: cl.item ? cl.item.id : null,
        value: `${cl.value || ''}`,
        checkListItem: cl.item,
      };
    });
  }

  setValue(checklist: CheckListEntry[]) {
    this.form.clear();
    this.createChecklist();

    if (!checklist) return;

    for (let cl of checklist) {
      if (!cl.checkListItem) continue;
      let value: number | string = cl.value;
      if (cl.checkListItem.type === CheckListValueType.NUMBER && !!value) {
        try {
          value = Number(cl.value);
        } catch (e) {
          console.warn('Invalid value ', cl.value, ' in numeric checklist entry');
          value = null;
        }
      }
      let matchingControl: AbstractControl = this.form.controls.find((c) => c.value.item && c.value.item.id === cl.itemId);

      if (!!matchingControl) {
        matchingControl.patchValue({ value });
      } else {
        let index = 0;
        let preSorted: boolean = false;
        let newEntry = new TypedFormGroup<CheckListEntryForm>({
          item: new UntypedFormControl(cl.checkListItem),
          value: new UntypedFormControl(value),
        });
        for (index = 0; index < this.form.length && !preSorted; index++) {
          if (this.form.value[index].item.type > cl.checkListItem.type || (this.form.value[index].item.type === cl.checkListItem.type && this.form.value[index].item.name > cl.checkListItem.name)) {
            preSorted = true;
          }
        }
        if (index >= this.form.length) {
          this.form.push(newEntry);
        } else {
          this.form.insert(index, newEntry);
        }

        this.sortChecklist();
      }
    }
  }

  createChecklist() {
    if (this.types === null) return;

    for (let type of this.types.sort((a, b) => (a.type - b.type || a.name >= b.name ? 1 : -1))) {
      let matchingChecklistItem = this.form.value.find((entry) => entry.item && entry.item.id === type.id);
      if (!matchingChecklistItem) {
        this.form.push(
          new TypedFormGroup<CheckListEntryForm>({
            item: new UntypedFormControl(type),
            value: new UntypedFormControl(null),
          })
        );
      }
    }

    this.sortChecklist();
  }

  markAsTouched() {
    this.form.markAsTouched();
  }

  validate() {
    return (control: AbstractControl) => {
      return this.form.valid ? null : { custom: 'Missing checklist values' };
    };
  }

  forceReset() {
    for (let i = this.form.controls.length - 1; i >= 0; i--) {
      this.form.removeAt(i);
    }
    this.form.reset();
  }

  sortChecklist() {
    this.form.controls = this.form.controls.sort((a, b) => (a.value.item.type > b.value.item.type || a.value.item.name >= b.value.item.name ? 1 : -1));
  }
}

type CheckListEntryForm = {
  value: string | number | YN;
  item: CheckListItem;
};
