import { Directive, ElementRef, HostListener, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Observable, Subscriber, combineLatest, from, isObservable, lastValueFrom, of } from 'rxjs';
import { filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { State } from 'src/app/core/reducers';
import {
  BLOCK_ANCHOR_POINT,
  FINISH_REQUEST,
  LOADED_USER_ENDPOINTS,
  SET_ACTIVE_FORM,
  SET_ANCHOR_POINT,
  SET_FORM_ENTITY,
  SET_READONLY_MODE,
  SET_TIMEDOUT,
  START_REQUEST,
  TOGGLE_DEBUG_MODE,
  UPDATE_ANCHOR_POINT,
} from 'src/app/core/reducers/actions';
import { RequestSpinner } from 'src/app/core/reducers/layout';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PromptService } from 'src/app/core/services/prompt.service';
import { SelectorApi } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ValidatorService } from 'src/app/core/services/validator.service';
import { CollapsibleCardComponent } from 'src/app/shared/collapsible-card/collapsible-card.component';
import { FormElementComponent } from 'src/app/shared/form-elements/form-element/form-element.component';
import { LazyCardComponent } from 'src/app/shared/lazy-card/lazy-card.component';
import { environment } from 'src/environments/environment';
import { endpoints } from 'src/lib/apiEndpoints';
import { EntityFormOptions } from './EntityFormOptions';
import { InnerFormGroupFormElement } from './InnerFormGroupFormElement';
import { StoreSubscription } from './StoreSubscription';
import { SubEntityContainer } from './SubEntityContainer';
import { MiscFeature } from './feature';
import {
  deepCleanForm,
  delay,
  endpointAuthorizationSubscription,
  endpointsAuthorized,
  graphqlAuthorizationSubscription,
  graphqlAuthorized,
  markFormGroupTouched,
  markFormGroupTouchedAsync,
} from './helperFunctions';
import { IRoutable } from './isRoutable';
import { SourceEntityType, getEntityId, getEntityName } from './newBackendTypes';
import { EntityVersionEvent, SocketIOService, WEB_SOCKET_THALOS_GET_USERS_EVENT } from 'src/app/core/services/socket.io.service';
import { channelId } from 'src/app/core/components/app/app.component';
import { FormControlStatus } from './typedForms';

export type SaveOptions<entityType, requestType = {}> = {
  forceCreate?: boolean;
  blockSubForms?: string[] | 'all';
  callback?: (entity: EntityResponse<entityType, requestType>) => Observable<EntityResponse<entityType, requestType>>;
  blockRedirect?: boolean;
  blockNotification?: boolean;
  saveAndClose?: boolean;
  reloadInPlace?: (e: entityType | null | entityType[]) => Observable<any> | any;
};

@UntilDestroy()
@Directive()
export abstract class EntityContainer<entityType, createType, updateType> implements OnInit, OnDestroy, IRoutable {
  /**
   * All collapsible card child components
   */
  @ViewChildren(CollapsibleCardComponent)
  collapsibleCards: QueryList<CollapsibleCardComponent>;

  /**
   * All child Form Element components
   */
  @ViewChildren(FormElementComponent)
  formElements: QueryList<FormElementComponent>;

  /**
   * Sub Entity Forms. Comments, Documents etc.
   */
  @ViewChildren(SubEntityContainer)
  subForms: QueryList<SubEntityContainer<any>>;

  /**
   * Any Form Element component that have inner form controls and validation that cannot be direcly accessed through the parent form.
   */
  @ViewChildren(InnerFormGroupFormElement)
  innerFormElements: QueryList<InnerFormGroupFormElement>;

  /**
   * Debug mode subscription.  If in debug mode, detailed information should be displayed about the form object.
   */
  debugMode: StoreSubscription<boolean>;

  /**
   * The existing entity if in update mode.  Null if in create mode.
   */
  entity: entityType | null;

  /**
   * The primary form.
   */
  form: UntypedFormGroup;

  /**
   * Default values for the form.  Defaults to null.
   */
  prefill: Partial<any> | null;

  /**
   * The name of the entity to be displayed in the header.
   */
  entityName: string;

  /**
   * The entity type from the DB.
   */
  sourceEntityType?: SourceEntityType;

  /**
   * The create endpoint used when saving the form in create mode.
   */
  createProcedureId: endpoints;

  /**
   * The update endpoint used when saving the form in update mode.
   */
  updateProcedureId?: endpoints;

  /**
   * A list of permissions required to create.
   */
  createProcedureRequirements: endpoints[] = [];

  /**
   * A list of permissions required to update.  All fields will be readonly and the save button will be hidden if permissions are missing.
   */
  updateProcedureRequirements: endpoints[] = [];

  /**
   * A list of permissions required to delete.  The delete button will be hidden if permissions are missing.
   */
  deleteProcedureRequirements: endpoints[] = [];

  /**
   * The endpoint used to retreive the entity.  Not used directly at this time.
   */
  getProcedureId: endpoints;

  /**
   * The endpoint used to delete.  Optional.
   */
  deleteProcedureId?: endpoints;

  /**
   * If true, the user can save a copy of an existing entity.  All behavior will be run as if creating a new entity.
   */
  allowSaveAsNew?: boolean;

  /**
   * A list of primary keys. (Contract Id etc)  Will only be one 99% of the time.
   */
  idFields: (keyof entityType)[];

  /**
   * Display name of the entity.  ie: Contract Number. Required for sharepoint fucntionality.  Defaults to first idFields
   */
  labelField: keyof entityType;
  /**
   * A list of keys. To display key value in the browser tab.
   */
  tabFields?: (keyof entityType)[];

  /**
   * If true, hides 'Are you sure you want to save' message when navigating to logout
   */
  timedOut: boolean;

  /**
   * List of requests pending (spinners).  If length > 0, disables save button.
   */
  requestsPending: readonly RequestSpinner[];

  /**
   * Set to true during setup to disable certain event loops.
   */
  loadingEntity: boolean = false;

  /**
   * True if form is being loaded in a modal.
   */
  popup = false;

  /**
   * True if yo want to set a custom pop up when apicallback finished.
   */
  customResultPopup = false;

  /**
   * Custom message to be displayed in result opoup.
   */
  customResultPopupMesage: string = '';

  /**
   * Execute actions after saved in a modal.
   */
  popupCallback?: (response: any) => void;

  selectorApi?: SelectorApi;
  /**
   * Define options to save/update when is a modal.
   */
  popUpOptions?: SaveOptions<entityType, createType | updateType>;

  onTitleChange: Observable<string>;
  titleChangeSub: Subscriber<string>;

  /**
   * Endpoint authorization map
   */
  authorized: endpointsAuthorized;

  /**
   * Graphql authorization map
   */
  graphqlAuthorized: graphqlAuthorized;

  /**
   * A list of store subscriptions to unsubscribe from in ngOnDestroy
   */
  storeSubscribtions: StoreSubscription<any>[] = [];

  /**
   * A list of hotkeys to remove in ngOnDestroy
   */
  _hotKeys: Hotkey[];

  hardReloadOnInit: any;

  /**
   * Data for the entity tied to the -> button
   */
  nextEntity: { id: number; label: string } | null;

  /**
   * Data for the entity tied to the <- button
   */
  previousEntity: { id: number; label: string } | null;

  alternateListEntityType: SourceEntityType | null;
  alternateListIdField: string | null;
  alternateListFeature: MiscFeature | null;
  alternateFormFeature: MiscFeature | null;

  /**
   * Data for when there's a new entity version, this flag is used for disabling buttons
   */
  isNewerEntityVersion: boolean = false;

  //Injected Services
  protected dialogService: DialogService;
  public api: ThalosApiService;
  protected notificationService: NotificationService;
  protected router: Router;
  protected validatorService: ValidatorService;
  protected store: Store;
  protected spinnerService: SpinnerService;
  protected hotKeys: HotkeysService;
  protected entityLookup: EntityLookupService;
  protected promptService: PromptService;
  protected socketIOService: SocketIOService;

  constructor(options: EntityFormOptions<entityType>, protected route: ActivatedRoute, protected elementRef: ElementRef, protected zone: NgZone, protected delegate: DelegateService) {
    this.dialogService = delegate.getService('dialog');
    this.api = delegate.getService('api');
    this.notificationService = delegate.getService('notification');
    this.router = delegate.getService('router');
    this.validatorService = delegate.getService('validator');
    this.spinnerService = delegate.getService('spinner');
    this.hotKeys = delegate.getService('hotkeys');
    this.entityLookup = delegate.getService('entityLookup');
    this.store = delegate.getService('store');
    this.promptService = delegate.getService('prompt');
    this.socketIOService = delegate.getService('socketIOService');

    this.createProcedureId = options.createProcedureId;
    this.updateProcedureId = options.updateProcedureId;
    this.deleteProcedureId = options.deleteProcedureId;
    this.getProcedureId = options.getProcedureId;
    this.idFields = options.idFields;
    this.allowSaveAsNew = options.allowSaveAsNew;
    this.labelField = options.labelField;
    this.tabFields = options.tabFields;
    if (options.createProcedureRequirements) this.createProcedureRequirements = options.createProcedureRequirements;
    if (options.updateProcedureRequirements) this.updateProcedureRequirements = options.updateProcedureRequirements;
    if (options.deleteProcedureRequirements) this.deleteProcedureRequirements = options.deleteProcedureRequirements;

    this.alternateListIdField = options.alternateListIdField ?? null;
    this.alternateListEntityType = options.alternateListEntityType ?? null;
    this.alternateListFeature = options.alternateListFeature ?? null;
    this.alternateFormFeature = options.alternateFormFeature ?? null;

    this.entityName = options.entityName;
    this.sourceEntityType = options.sourceEntityType;

    endpointAuthorizationSubscription(this.store, this);
    graphqlAuthorizationSubscription(this.store, this);

    const endpointSub = this.store.subscribe((val) => val.user.userEndpoints, [LOADED_USER_ENDPOINTS]);
    const graphqlSub = this.store.subscribe((val) => val.user.userEndpoints, [LOADED_USER_ENDPOINTS]);
    const requestsPendingSub = this.store.subscribe((val) => val.layout.requestsPending, [START_REQUEST, FINISH_REQUEST]);
    const timedoutSub = this.store.subscribe((val) => val.layout.timedOut, [SET_TIMEDOUT]);
    const anchorSub = this.store.subscribe((state) => state.layout.anchorPoint, [SET_ANCHOR_POINT, UPDATE_ANCHOR_POINT, BLOCK_ANCHOR_POINT]);

    endpointSub.$.pipe(untilDestroyed(this)).subscribe(() => {
      this.refreshFormState();
    });
    graphqlSub.$.pipe(untilDestroyed(this)).subscribe(() => {
      this.refreshFormState();
    });
    requestsPendingSub.$.pipe(untilDestroyed(this)).subscribe((requests) => {
      this.requestsPending = requests;
    });
    timedoutSub.$.pipe(untilDestroyed(this)).subscribe((timedOut) => {
      this.timedOut = timedOut;
    });
    anchorSub.$.pipe(untilDestroyed(this)).subscribe((a) => {
      if (a === null) {
        this.previousEntity = null;
        this.nextEntity = null;
      }
    });

    this.storeSubscribtions.push(endpointSub, graphqlSub, requestsPendingSub, timedoutSub, anchorSub);
    this.debugMode = this.store.subscribe((state: State) => state.layout.debugMode, [TOGGLE_DEBUG_MODE]);

    this.closeOptions = {
      showConfirmation: () => this.form.dirty && this.form.touched && this.saveAuthorized,
      title: 'Save Changes',
      content: this.getCloseMessage(),
      actions: this.getCloseActions(),
    };
    let nav = this.router.getCurrentNavigation();
    let prefill: Partial<any> = nav?.extras?.state?.formPrefill;
    if (prefill) this.prefill = prefill;
    else prefill = null;

    this.onTitleChange = new Observable((sub) => {
      this.titleChangeSub = sub;
    });
  }

  get entityKey(): string {
    if (!this.entity) return '';
    let entityDescriptor: string = '';
    for (let i in this.idFields) {
      let field = this.idFields[i];
      if (this.entity[field]) {
        if (parseInt(i) > 0) entityDescriptor += '/';
        entityDescriptor += this.entity[field];
      }
    }
    return entityDescriptor;
  }

  get entityHomePath(): string {
    return this.entityPath;
  }

  get entityTab(): string {
    if (!this.entity) return '';
    let entityDescriptor: string = '';
    for (let i in this.tabFields) {
      let field = this.tabFields[i];
      if (this.entity[field]) {
        if (parseInt(i) > 0) entityDescriptor += '/';
        entityDescriptor += this.entity[field];
      }
    }
    return entityDescriptor;
  }

  get entityPath(): string {
    let path = '';
    if (!!this.entity) {
      for (let _ in this.idFields) path += '../';
    } else {
      path = '../';
    }
    return path;
  }

  public hardReloadForm(entityData) {
    if (!!this.entity) {
      //if entity was previously loaded
      for (let c of this.collapsibleCards) {
        if (c instanceof LazyCardComponent) {
          if (!c.isOpen) {
            c.reset();
          }
        }
      }
    }

    this.loadingEntity = true;
    this.clearForm();
    this.entity = entityData;
    this.loadEntity(entityData);
    this.refreshFormState();
    this.setNavigationData(entityData);
    this.loadingEntity = false;
    const entityNumber = getEntityName(this.alternateListEntityType ?? this.sourceEntityType, entityData);
    const entityVersionEvent: EntityVersionEvent = {
      entityId: entityData[this.idFields[0]],
      entityNumber: entityNumber !== 'Unknown' ? entityNumber : undefined,
      entityType: this.sourceEntityType,
      channelId,
      url: this.router.url,
    };

    this.socketIOService.socket?.emit(WEB_SOCKET_THALOS_GET_USERS_EVENT, entityVersionEvent);
  }

  /**
   * Parses active list of items and displays items to the left and right of the current entity.
   * @param entityData Data from state
   */
  public setNavigationData(entityData): void {
    let anchorPoint = this.store.snapshot((state) => state.layout.anchorPoint);

    if (!this.entity || this.idFields.length !== 1 || this.popup || !anchorPoint) {
      this.nextEntity = null;
      this.previousEntity = null;
      return;
    }
    //right arrow
    let next: { id: number; label: string } | null = null;
    //left arrow
    let previous: { id: number; label: string } | null = null;

    let listEntity = this.alternateListFeature
      ? this.entityLookup.getFeatureRoute(this.alternateListFeature)?.link
      : this.entityLookup.getLink(this.alternateListEntityType ?? this.sourceEntityType, 'list');
    //No data accessor function was passed
    if (anchorPoint && listEntity && anchorPoint.url === listEntity && !anchorPoint.state.dataAccessor) {
      let data = anchorPoint.state.data as any[];
      if (Array.isArray(data) && data.length > 0) {
        let index = data.findIndex((pc) => {
          return pc[this.alternateListIdField ?? this.idFields[0]] === entityData[this.idFields[0]];
        });
        if (index >= 0 && index < data.length - 1) {
          let nextData = data[index + 1];
          let id = this.alternateListEntityType ? getEntityId(this.alternateListEntityType, nextData) : nextData[this.idFields[0]];
          if (id) {
            let label;
            if (this.sourceEntityType) {
              label = getEntityName(this.alternateListEntityType ?? this.sourceEntityType, nextData);
            }
            next = { id, label: label ?? `${id}` };
          }
        }
        if (index >= 1) {
          let previousData = data[index - 1];
          let id = this.alternateListEntityType ? getEntityId(this.alternateListEntityType, previousData) : previousData[this.idFields[0]];
          if (id) {
            let label;
            if (this.sourceEntityType) {
              label = getEntityName(this.alternateListEntityType ?? this.sourceEntityType, previousData);
            }
            previous = { id, label: label ?? `${id}` };
          }
        }
      }
    }
    //a data accessor function was passed
    else if (anchorPoint.state.dataAccessor && anchorPoint.state.data && Array.isArray(anchorPoint.state.data)) {
      let data: any[] = anchorPoint.state.data;
      let dataAccessor = anchorPoint.state.dataAccessor;
      let labelAccessor = anchorPoint.state.dataLabelAccessor;
      let finder = typeof dataAccessor === 'function' ? (v: any) => dataAccessor(v) === this.entity[this.idFields[0]] : (v: any) => v[dataAccessor] === this.entity[this.idFields[0]];

      let index = data.findIndex(finder);
      if (index >= 0) {
        let [nextNode, previousNode] = [
          [(n) => ++n, (n) => n < data.length - 1],
          [(n) => --n, (n) => n > 0],
        ].map(([inc, comp]: [(number) => number, (any) => boolean]) => {
          let i = index;
          let id;
          let label;
          let node = null;
          do {
            i = inc(i);
            node = data[i];
            if (!node) continue;
            if (typeof dataAccessor === 'function') {
              id = dataAccessor(node);
            } else if (typeof dataAccessor === 'string') {
              id = node[dataAccessor];
            }
            if (typeof labelAccessor === 'function') {
              label = labelAccessor(node);
            } else if (typeof labelAccessor === 'string') {
              label = node[labelAccessor];
            }
          } while (comp(i) && (!node || id === this.entity[this.idFields[0]]));
          if (!id) return null;
          if (!label) label = `${id}`;
          return {
            id,
            label,
          };
        });
        next = nextNode;
        previous = previousNode;
      }
    }
    this.previousEntity = previous;
    this.nextEntity = next;
  }

  /**
   * Forces the sub form components to reload without routing
   */
  forceSubformReload() {
    if (this.subForms && this.idFields.length === 1 && this.entity) {
      this.subForms.forEach((s) => {
        let id = this.entity[this.idFields[0]];
        //make typescript happy
        if (typeof id === 'number') {
          s.entityId = id;
          s.loadSubForm();
        }
      });
    }
  }

  /**
   *
   */
  ngOnInit(): void {
    this.initializeForm();

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      let entityData: entityType = data['entity'];
      if (entityData) {
        this.hardReloadForm(entityData);
      }
      if (this.titleChangeSub) {
        this.titleChangeSub.next(this.getTabTitle());
      }
    });

    if (this.prefill) {
      this.form.patchValue(this.prefill);
      this.prefill = null;
    }

    this.initializeFormListeners();

    if (!this.popup) {
      let saveKeys = new Hotkey(
        ['meta+s', 'ctrl+s', 'shift+meta+s', 'shift+ctrl+s'],
        (event: KeyboardEvent): boolean => {
          if (this.requestsPending.length > 0) {
            this.notificationService.show('Wait', 'warning');
            return false;
          }
          if (document.activeElement != document.body) (document.activeElement as HTMLElement).blur();
          let dialog = document.querySelector('.k-dialog');
          if (!!dialog) return;
          setTimeout(() => {
            this.dialogService
              .open({
                title: event.shiftKey ? 'Save and Close' : 'Save',
                content: 'Are you sure you wish to save ' + this.entityName + (event.shiftKey ? '. The form will be closed' : ''),
                actions: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Save',
                    themeColor: 'primary',
                  },
                ],
              })
              .result.subscribe((res) => {
                if (!(res instanceof DialogCloseResult) && res.text === 'Save') {
                  setTimeout(() => {
                    this.clickSave({ saveAndClose: event.shiftKey });
                  });
                }
              });
          });
          return false;
        },
        ['input', 'select', 'textarea'],
        `Save ${this.entityName}`
      );

      let openCardKeys = new Hotkey(
        ['shift+meta+o', 'shift+ctrl+o'],
        (event: KeyboardEvent): boolean => {
          let dialog = document.querySelector('.k-dialog');
          if (!!dialog) return false;

          if (this.collapsibleCards) {
            this.collapsibleCards.forEach((c) => {
              c.isOpen = true;
            });
          }

          return false;
        },
        ['input', 'select', 'textarea'],
        'Expand All'
      );
      let collapseCardKeys = new Hotkey(
        ['shift+meta+c', 'shift+ctrl+c'],
        (event: KeyboardEvent): boolean => {
          let dialog = document.querySelector('.k-dialog');
          if (!!dialog) return false;

          if (this.collapsibleCards) {
            this.collapsibleCards.forEach((c) => {
              c.isOpen = false;
            });
          }

          return false;
        },
        ['input', 'select', 'textarea'],
        'Collapse All'
      );

      let closeKeys = new Hotkey(
        ['shift+alt+backspace'],
        (ev) => {
          if (this.requestsPending.length > 0) {
            this.notificationService.show('Wait', 'warning');
            return false;
          }
          const closeButton = document.querySelector('#entity-close-button');
          if (!!closeButton) {
            (closeButton as HTMLButtonElement).click();
          }

          return false;
        },
        ['input', 'select', 'textarea'],
        'Close Form'
      );

      let leftKeys = new Hotkey(
        ['shift+alt+left'],
        (ev) => {
          if (this.requestsPending.length > 0) {
            this.notificationService.show('Wait', 'warning');
            return false;
          }
          const leftButton = document.querySelector('#entity-left-button');
          if (!!leftButton) {
            (leftButton as HTMLButtonElement).click();
          }

          return false;
        },
        ['input', 'select', 'textarea'],
        `Next ${this.entityName}`
      );

      let rightKeys = new Hotkey(
        ['shift+alt+right'],
        (ev) => {
          if (this.requestsPending.length > 0) {
            this.notificationService.show('Wait', 'warning');
            return false;
          }
          const rightButton = document.querySelector('#entity-right-button');
          if (!!rightButton) {
            (rightButton as HTMLButtonElement).click();
          }

          return false;
        },
        ['input', 'select', 'textarea'],
        `Previous ${this.entityName}`
      );

      this._hotKeys = [saveKeys, openCardKeys, collapseCardKeys, closeKeys, leftKeys, rightKeys];
      this.hotKeys.add(this._hotKeys);
    }

    if (this.hardReloadOnInit) {
      this.hardReloadForm(this.hardReloadOnInit);
    }

    this.socketIOService.newerEntityVersionInfo.subscribe((res) => {
      if (res && this.entity && res.entityId === this.entity[this.idFields[0]]) this.isNewerEntityVersion = true;
    });
  }

  ngAfterViewInit(): void {
    if (this.hardReloadOnInit) {
      this.forceSubformReload();
      this.hardReloadOnInit = null;
    }

    if (!this.popup) {
      this.store.dispatch({ type: SET_ACTIVE_FORM, payload: { procedureId: !!this.entity ? this.updateProcedureId : this.createProcedureId, activeForm: () => this.form } });
      this.store.dispatch({ type: SET_FORM_ENTITY, payload: this.entity || null });
    }

    if (!this.entity && this.formElements.length > 0) {
      this.formElements.first.focus();
    }
  }

  ngOnDestroy(): void {
    if (!this.popup) {
      this.store.dispatch({ type: SET_ACTIVE_FORM, payload: { procedureId: null, activeForm: null } });
      this.store.dispatch({ type: SET_READONLY_MODE, payload: false });
      if (this._hotKeys) this.hotKeys.remove(this._hotKeys);
    }

    this.debugMode.unsubscribe();
    this.storeSubscribtions.forEach((s) => s.unsubscribe());
    if (this.titleChangeSub) {
      this.titleChangeSub.unsubscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (!this.timedOut && !!this.closeOptions && this.closeOptions.showConfirmation()) {
      event.returnValue = 'this value does not matter';
    }
  }

  closeOptions: { showConfirmation: () => boolean; title: string; content: string; actions: () => { text: string; themeColor?: string; callback?: () => boolean | Observable<boolean> }[] };

  close(): boolean | Observable<boolean> {
    if (!!this.closeOptions && this.closeOptions.showConfirmation()) {
      const actions = this.closeOptions.actions();
      return this.dialogService
        .open({
          minWidth: 500,
          title: this.closeOptions.title,
          content: this.closeOptions.content,
          actions: actions.map((option) => {
            return { text: option.text, themeColor: option.themeColor };
          }),
        })
        .result.pipe(
          switchMap((result) => {
            if (result instanceof DialogCloseResult) return of(false);
            let action = actions.find((action) => result.text === action.text).callback;
            if (!action) return of(true);
            let actionResult = action();
            if (actionResult instanceof Observable) return actionResult;
            return of(actionResult);
          })
        );
    } else return true;
  }

  async clickSave(options?: SaveOptions<entityType, createType | updateType>) {
    if (this.requestsPending.length > 0 || (!!this.entity && !this.updateProcedureId)) return;
    if (this.popup && this.popUpOptions) options = this.popUpOptions;

    const resultPromise = await lastValueFrom(this.save(options));
    let entity$ = from(Promise.resolve(resultPromise) as Promise<entityType | entityType[]>);
    if (options && options.callback) entity$ = entity$.pipe(switchMap(options.callback));
    entity$.subscribe((result) => {
      if (!!result && !options?.blockRedirect) {
        const homePath = this.entityPath;
        if (Array.isArray(result)) {
          this.entity = result[0];
        } else {
          this.entity = result as entityType;
        }
        let path = `${homePath}`;
        let queryParams: Params;
        if (!options?.saveAndClose) {
          path += this.entityKey;
          queryParams = this.route.snapshot?.queryParams || {};
        }
        this.router.navigate([path], { relativeTo: this.route, queryParams });
      } else if (!result) {
        this.form.markAsDirty();
        this.form.markAsTouched();
      } else if (!!result) {
        if (options?.reloadInPlace) {
          let entity = Array.isArray(result) ? result[0] : result;
          let reload = options.reloadInPlace(entity);
          if (isObservable(reload)) {
            let rid = this.spinnerService.startRequest('Navigating');
            reload.subscribe((reloadValue: any) => {
              this.spinnerService.completeRequest(rid);
              this.hardReloadForm(reloadValue);
              this.forceSubformReload();
            });
          } else {
            this.hardReloadForm(reload);
            this.forceSubformReload();
          }
        }
      }
    });
  }

  clickDelete(customRedirect?: boolean, route?: string) {
    if (!this.entity || this.requestsPending.length > 0) return;

    this.dialogService
      .open({
        title: 'Delete',
        content: `Are you sure you wish to delete this ${this.entityName}`,
        actions: [
          {
            text: 'Cancel',
          },
          {
            text: 'Delete',
            themeColor: 'primary',
          },
        ],
      })
      .result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
        } else if (result.text === 'Delete') {
          this.form.markAsPristine();
          this.form.markAsUntouched();
          if (customRedirect) {
            this.deleteEntity(route);
          } else {
            this.deleteEntity();
          }
        }
      });
  }

  /**
   * Wraps the save entity function in a single spinner to prevent further actions from user input
   *
   * @param saveOptions Optional parameters for saving entity
   * @returns The result of the save operation
   */
  protected save(saveOptions?: SaveOptions<entityType>) {
    let rid = this.spinnerService.startRequest('Saving', null, true);
    return this.saveEntity(saveOptions).pipe(
      tap(() => {
        this.spinnerService.completeRequest(rid);
      })
    );
  }

  protected async validateEntity(): Promise<boolean> {
    if (this.subForms.length > 0) {
      for (let form of this.subForms.toArray()) {
        form.markAllTouched();
      }
    }
    if (this.innerFormElements.length > 0) {
      for (let form of this.innerFormElements.toArray()) {
        form.markAllTouched();
      }
    }
    const rid = this.spinnerService.startRequest('Validating Form');

    await delay(1000);
    return lastValueFrom(
      combineLatest([markFormGroupTouchedAsync(this.form), ...this.subForms.map((sf) => sf.getFormStatusChange())]).pipe(
        map((res) => {
          return res.every((r) => r !== FormControlStatus.PENDING);
        }),
        filter((r) => !!r),
        first(),
        tap(() => {
          this.spinnerService.completeRequest(rid);
        }),
        switchMap(() => {
          if (!this.form.valid) {
            this.dialogService.open({
              title: 'Save failed',
              content: `Some fields are invalid or missing, ${this.entityName} could not be saved`,
              actions: [
                {
                  text: 'Close',
                  themeColor: 'primary',
                },
              ],
            });
            if (this.collapsibleCards.length > 0) {
              this.collapsibleCards.forEach((c) => {
                if (!(c instanceof LazyCardComponent)) {
                  c.isOpen = true;
                }
              });
            }

            let tries = 0;
            this.zone.onStable.pipe(takeWhile(() => tries < 25)).subscribe(() => {
              tries++;
              const errors: HTMLElement[] = this.elementRef.nativeElement.querySelectorAll('.error-label.active-error');
              if (errors.length > 0) {
                tries = 26;
                setTimeout(() => {
                  errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                });
              }
            });
            return of(false);
          }
          return of(true);
        })
      )
    );
  }

  /**
   * This is the actual method that performs the saving of the entity.
   * .saveEntity() is kept with that name for the time being, as it needs to be replaced across the whole application
   */
  protected async processSave(saveOptions?: SaveOptions<entityType>): Promise<EntityResponse<entityType, createType | updateType>> {
    let rId: string;
    let savePromise: Promise<entityType | entityType[] | null>;
    rId = this.spinnerService.startRequest('Saving');
    if (!this.entity || saveOptions?.forceCreate) {
      let request = await this.getCreateEntityRequest(saveOptions);
      for (let form of this.subForms.toArray().filter((form) => {
        const blocked = saveOptions && saveOptions.blockSubForms && (saveOptions.blockSubForms === 'all' || saveOptions.blockSubForms.includes(form.thalosFieldPath));
        return !form.autoSave && !!form.field && !blocked;
      })) {
        request[form.field] = form.getValue();
      }

      if (Array.isArray(request)) {
        //we know the request is an array but typescript will not allow direct conversion from a generic type to any[]
        savePromise = Promise.all((request as any[]).map((r) => lastValueFrom(this.api.rpc<entityType>(this.createProcedureId, r, null, { blockRedirect: true }))));
      } else {
        savePromise = lastValueFrom(this.api.rpc<entityType>(this.createProcedureId, request, null, { blockRedirect: true }));
      }
    } else {
      const request = await this.getUpdateEntityRequest(saveOptions);
      for (let form of this.subForms.toArray().filter((form) => {
        const blocked = !!saveOptions && !!saveOptions.blockSubForms && (saveOptions.blockSubForms === 'all' || saveOptions.blockSubForms.includes(form.thalosFieldPath));
        return !form.autoSave && !!form.field && !blocked;
      })) {
        request[form.field] = form.getValue();
      }
      savePromise = lastValueFrom(this.api.rpc<entityType>(this.updateProcedureId, request, null, { blockRedirect: true }));
    }
    const result = await savePromise;
    let operation = !!this.entity && !saveOptions?.forceCreate ? 'updated' : 'created';
    if (!!result) {
      if (this.subForms.length > 0 && saveOptions?.blockSubForms !== 'all') {
        let key = this.idFields.length > 0 ? this.idFields[0] : null;
        if (key !== null) {
          let ids: any[] = Array.isArray(result) ? result.map((e) => e[key]) : [result[key]];
          if (ids.every((id) => typeof id === 'number')) {
            for (let i in ids) {
              let id = ids[i];
              let entity: entityType = Array.isArray(result) ? result[i] : result;
              for (let form of this.subForms.toArray().filter((form) => {
                const blocked = saveOptions && saveOptions.blockSubForms && (saveOptions.blockSubForms === 'all' || saveOptions.blockSubForms.includes(form.thalosFieldPath));
                return form.autoSave && !blocked;
              })) {
                let label: any = !!this.labelField ? entity[this.labelField] : id;
                if (form.autoSave) await lastValueFrom(form.save(id, operation === 'created' ? 'create' : 'update', label));
              }
            }
          }
        }
      }

      if (!saveOptions?.blockNotification) {
        this.notificationService.show(`${this.entityName} successfully ${operation}`, 'success');
        if (this.popup && this.tabFields && !this.customResultPopup) {
          this.resultPopUpActions(result as EntityResponse<entityType, createType | updateType>, operation, this.popupCallback);
        }
        if (this.customResultPopup && this.customResultPopupMesage) {
          this.customResultPopUpActions(this.popupCallback);
        }
      }
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
    this.spinnerService.completeRequest(rId);
    return result as EntityResponse<entityType, createType | updateType>;
  }

  resultPopUpActions(result, operation, callback?: (response: any) => void) {
    const prompt = this.delegate.getService('prompt');
    const field = this.tabFields[0];
    prompt.htmlDialog('Success', `<div style="white-space: pre">${this.entityName} successfully ${operation}. \nNumber: ${result[field]}</div>`).subscribe((res) => {
      callback(res);
    });
  }

  customResultPopUpActions(callback?: (response: any) => void) {
    const prompt = this.delegate.getService('prompt');
    prompt.htmlDialog('Success', `<div style="white-space: pre">${this.customResultPopupMesage}</div>`).subscribe((res) => {
      callback(res);
    });
  }

  /**
   * Can be overwritten.  Validates the form and saves if form is valid.
   *
   * @param saveOptions Optional parameters for saving entity
   * @returns The result of the save operation
   */
  protected saveEntity(saveOptions?: SaveOptions<entityType>): Observable<EntityResponse<entityType, createType | updateType> | null> {
    return from(
      (async () => {
        const valid = await this.validateEntity();
        if (valid) {
          return this.processSave(saveOptions);
        }
        return null;
      })()
    );
  }

  protected async deleteEntity(route?: string) {
    let args: { filters: { [key in keyof entityType]?: any } } = { filters: {} };
    for (let i in this.idFields) {
      let field = this.idFields[i];
      if (this.entity[field]) {
        args.filters[field] = this.entity[field];
      }
    }

    let rId = this.spinnerService.startRequest('Deleting');
    const res = await lastValueFrom(this.api.rpc<any, false>(this.deleteProcedureId, args, false));
    this.spinnerService.completeRequest(rId);
    if (!!res) {
      this.notificationService.show(`${this.entityName} ${this.entityKey} deleted`);
      if (route) {
        this.router.navigate([route]);
      } else {
        this.router.navigate([this.entityHomePath], { relativeTo: this.route });
      }
    }
  }

  get createAuthorized(): boolean {
    return !!this.createProcedureId && this.authorized[this.createProcedureId] && this.createProcedureRequirements.every((e) => this.authorized[e]);
  }

  get updateAuthorized(): boolean {
    return !!this.updateProcedureId && this.authorized[this.updateProcedureId] && this.updateProcedureRequirements.every((e) => this.authorized[e]);
  }

  get deleteAuthorized(): boolean {
    return !!this.deleteProcedureId && this.authorized[this.deleteProcedureId] && this.deleteProcedureRequirements.every((e) => this.authorized[e]);
  }

  get getAuthorized(): boolean {
    return !!this.getProcedureId && this.authorized[this.getProcedureId];
  }

  get saveAuthorized(): boolean {
    if (!!this.entity) {
      return this.updateAuthorized;
    } else {
      return this.createAuthorized;
    }
  }

  get commentsAuthorized(): boolean {
    return this.authorized[endpoints.listComments];
  }

  get tasksAuthorized(): boolean {
    return this.authorized[endpoints.listTasks];
  }

  get documentsAuthorized(): boolean {
    return this.authorized[endpoints.listDocuments];
  }

  get tagsAuthorized(): boolean {
    return this.authorized[endpoints.listTagAssignments];
  }

  getCloseActions() {
    return () => {
      const actions = [
        {
          text: "Don't Save",
        },
        {
          text: 'Cancel',
          callback: () => false,
        },
        {
          text: 'Save',
          themeColor: 'primary',
          callback: () => this.save().pipe(map((entity) => !!entity)),
        },
      ];

      return actions;
    };
  }

  getCloseMessage() {
    return `Do you want to save your changes to this ${this.entityName}?`;
  }

  refreshFormState() {
    if (this.saveAuthorized || environment.devMode) {
      this.store.dispatch({ type: SET_READONLY_MODE, payload: false });
    } else {
      this.store.dispatch({ type: SET_READONLY_MODE, payload: true });
    }
  }

  clearForm() {
    deepCleanForm(this.form);
  }

  abstract loadEntity(entityType);

  abstract getCreateEntityRequest(saveOptions: SaveOptions<entityType, createType>): createType | Promise<createType>;

  abstract getUpdateEntityRequest(saveOptions: SaveOptions<entityType, updateType>): updateType | Promise<updateType>;

  abstract initializeForm(): void;

  abstract initializeFormListeners(): void;

  prefillForm(prefill: Partial<any>) {
    this.prefill = prefill;
  }
  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.requestsPending.length === 0 && !this.form.invalid;
  }

  //popup
  submit() {
    return this.save();
  }

  getTabTitle() {
    return this.entity ? `${this.entityName} ${this.entityTab}` : `New ${this.entityName}`;
  }
}

type EntityResponse<entityType, requestType> = requestType extends ArrayLike<any> ? entityType[] : entityType;
