import { Pipe, PipeTransform } from '@angular/core';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { Unit } from 'src/lib/newBackendTypes';

@Pipe({
  name: 'unit',
  pure: true,
})
export class UnitPipe implements PipeTransform {
  constructor(private dataFormatter: DataFormattingService) {}

  transform(unitOrUnitId: Unit | number | string): any {
    let unit = this.dataFormatter.unitFromTag(unitOrUnitId);
    if (!!unit) return unit.code;
    return unitOrUnitId;
  }
}
