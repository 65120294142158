import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';
import { DropdownGraphqlComponent } from '../dropdown-graphql/dropdown-graphql.component';
import { GraphqlService } from 'src/app/core/services/graphql.service';

@UntilDestroy()
@Component({
  selector: 'multiselect-graphql',
  templateUrl: '../multiselect-wrapper/multiselect-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => MultiselectGraphqlComponent) }],
})
export class MultiselectGraphqlComponent extends DropdownGraphqlComponent implements OnInit {
  @ViewChild('multiselect', { static: false })
  multiselect: MultiSelectComponent;

  @Input()
  itemDisabled?: (any) => boolean;

  @Input()
  autoClose: boolean = false;

  @Output()
  removeTag?: EventEmitter<any> = new EventEmitter();

  _value: any[] = [];

  setValue(value) {
    if (Array.isArray(value)) {
      for (let i in value) {
        let v = value[i];
        if (typeof v !== 'object') {
          let obj = { [this.dropdownConfig.labelField]: v, [this.dropdownConfig.valueField]: v };
          value[i] = obj;
        }
      }
    } else if (!!value) {
      value = [value];
    }
    this._value = value;
  }

  constructor(graphqlService: GraphqlService, controlDir: NgControl, store: Store) {
    super(graphqlService, controlDir, store);
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.multiselect.filterChange
      .asObservable()
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((a, b) => a === b),
        debounceTime(300)
      )
      .subscribe((text) => {
        this.handleFilter(text);
      });
  }

  public neverDisable() {
    return false;
  }

  public onOpen() {
    this.handleFilter('');
  }

  tagRemoved(event) {
    this.removeTag.emit(event);
  }

  public focus() {
    if (this.multiselect) {
      setTimeout(() => {
        this.multiselect.focus();
      });
    }
  }

  comparator(a: any[] | null, b: any[] | null) {
    if (!a && !b) return true;
    if ((!a && !!b) || (!!a && !b)) return false;
    return _.isEqual(
      a.map((o) => o[this.dropdownConfig.valueField]),
      b.map((o) => o[this.dropdownConfig.valueField])
    );
  }
}
