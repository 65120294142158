<form [formGroup]="form">
  <div class="row">
    <vertical-field name="Invoice" class="col-xl-2 col-md-2 col-6">
      <label class="linksContainer">{{ invoiceNumber }}</label>
    </vertical-field>
    <vertical-field name="Contract" class="col-xl-2 col-md-2 col-6">
      <label class="linksContainer">{{ contractNumber }}</label>
    </vertical-field>
    <vertical-field name="Counterparty" class="col-xl-4 col-md-4 col-12">
      <label class="linksContainer">{{ counterparty }}</label>
    </vertical-field>
  </div>
  <br />
  <div>
    <hedging-invoice-shipments-list style="width: 100%" [formControlName]="'shipments'" (shipmentsSelected)="shipmentsAppliedHandler($event)"> </hedging-invoice-shipments-list>
  </div>
  <div class="row mt-3">
    <vertical-field [name]="'Email Options'" class="col-12 col-sm-4">
      <simple-dropdown [formControlName]="'sendEmail'" [data]="emailOptions" [readonly]="false" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true"> </simple-dropdown>
    </vertical-field>
    <vertical-field class="col-12 col-sm-8" *ngIf="!hasPricingConfEmail">
      <div class="col-12" style="margin-top: 5px">
        <span class="error-label"><i class="fas fa-triangle-exclamation"></i> The counterparty does not have a registered "Pricing conf email"</span>
      </div>
    </vertical-field>
  </div>
</form>
