<div class="card desktop-h-100">
  <div class="card-header d-flex">
    <h4 class="flex-grow-1">PO Review</h4>
  </div>
  <div class="card-body" style="display: flex; flex-direction: column">
    <form [formGroup]="filterForm">
      <div class="row">
        <vertical-field class="col-12 col-md-5 col-xl-3" [name]="'PO Number'">
          <numerictextbox-wrapper [formControlName]="'numbers'" [format]="intFormat" [decimals]="0"> </numerictextbox-wrapper>
        </vertical-field>
        <vertical-field class="col col-xl-3" [name]="'Counterparty'">
          <dropdown-wrapper cyData="counterparty" [formControlName]="'counterparty'" [dropdownConfig]="counterpartyDropdown"> </dropdown-wrapper>
        </vertical-field>
        <vertical-field class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-12" style="padding-left: 15px; padding-right: 15px">
          <button kendoButton type="submit" (click)="fetchContractLines()">Search</button>
        </vertical-field>
      </div>
    </form>
    <ag-grid-angular
      style="width: 100%; flex-grow: 1"
      [ngStyle]="{ height: popup ? '400px' : 'auto' }"
      class="ag-theme-balham"
      [rowData]="data"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
    <div *ngIf="!popup" class="d-flex" style="justify-content: flex-end">
      <button kendoButton type="submit" (click)="addToBooking()" *ngIf="addToBookingAuthorized">Add To Booking</button>
      <button kendoButton type="submit" (click)="newBooking()" *ngIf="createBookingAuthorized">New Freight Booking</button>
    </div>
  </div>
</div>
