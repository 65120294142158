import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogAction, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { CustomDialogResult, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { handlePrintResponse } from 'src/app/shared/microsoft-entity-documents/microsoft-entity-documents.component';
import { PrintDocumentComponent, PrintDocumentForm } from 'src/app/shared/print-document/print-document.component';
import { ListResponse } from 'src/lib';
import { BaseList } from 'src/lib/BaseList';
import { ContextMenuGetter, amountCurrencyColumn, dateColumn, getContextMenuItems, gotoMenuItem, priceColumn, quantityUnitColumn } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized, getTodayUTC } from 'src/lib/helperFunctions';
import { ContractType, ContractTypes, Document, PrintDocumentResponse, SourceEntityType, StorageTypes } from 'src/lib/newBackendTypes';
import { Advance, CreateAdvanceRequest } from 'src/lib/newBackendTypes/advance';
import { AdvancesToOpenQueueData } from 'src/lib/newBackendTypes/advanceToOpenData';
import { TypedFormGroup } from 'src/lib/typedForms';
import { GenericListIcon, GoToIcon, randomFetchSynonym } from 'src/lib/uiConstants';
import { AdvanceForm, AdvancePrefill, CreateAdvanceComponent } from '../create-advance/create-advance.component';

type AdvancesToOpenTableEntry = Pick<
  AdvancesToOpenQueueData,
  | 'contractId'
  | 'contractNumber'
  | 'contractDate'
  | 'contractType'
  | 'contractTypeLabel'
  | 'counterpartyId'
  | 'counterpartyName'
  | 'counterpartyReference'
  | 'traderId'
  | 'traderName'
  | 'productName'
  | 'paymentTermName'
  | 'advancePercentage'
  | 'advanceAmount'
  | 'currencyId'
  | 'quantity'
  | 'quantityUnitId'
> & {
  contractAmount: number;
  lines: Pick<AdvancesToOpenQueueData, 'elementId' | 'elementNumber' | 'itemName' | 'quantity' | 'quantityUnitId' | 'price' | 'lineAmount' | 'currencyId' | 'priceUnitId'>[];
};

@UntilDestroy()
@Component({
  selector: 'accounting-advance-queue',
  templateUrl: './advance-queue.component.html',
  styleUrls: ['./advance-queue.component.scss'],
})
export class AdvanceQueueComponent extends BaseList implements OnDestroy {
  data: AdvancesToOpenTableEntry[];

  filterForm: TypedFormGroup<AdvanceFilterForm>;
  contractTypes = ContractTypes;

  authorized: endpointsAuthorized;

  GenericListIcon = GenericListIcon;

  constructor(
    private formatter: DataFormattingService,
    private spinner: SpinnerService,
    private api: ThalosApiService,
    store: Store,
    private delegate: DelegateService,
    private selector: SelectorPopupService,
    private commonData: CommonDataService,
    private dialog: DialogService,
    private entityLookup: EntityLookupService
  ) {
    super();
    this.filterForm = new TypedFormGroup<AdvanceFilterForm>({
      contractType: new UntypedFormControl(ContractType.SALE, Validators.required),
    });

    endpointAuthorizationSubscription(store, this);
  }

  ngOnInit() {
    super.ngOnInit();

    this.fetchData();
  }

  ngOnDestroy() {}

  initializeGrid(): GridOptions {
    return {
      getRowId: (params) => params.data.contractId,
      columnDefs: [
        { headerName: 'Contract Type', field: 'contractTypeLabel', hide: true },
        {
          headerName: 'Contract Number',
          field: 'contractNumber',
          filter: 'agSetColumnFilter',
          cellRenderer: 'agGroupCellRenderer',
          width: 100,
          sort: 'asc',
        },
        {
          headerName: 'Counterparty',
          field: 'counterpartyName',
          filter: 'agSetColumnFilter',
          width: 150,
        },
        { headerName: 'Trader', field: 'traderName', filter: 'agSetColumnFilter', width: 100 },
        { field: 'counterpartyReference', filter: 'agSetColumnFilter', width: 200 },
        { ...dateColumn('contractDate', 'Date'), width: 90 },
        { headerName: 'Product', field: 'productName', filter: 'agTextColumnFilter', width: 100 },
        { ...quantityUnitColumn(this.formatter, 'quantityUnitId', 'quantity'), width: 150 },
        {
          headerName: 'Payment Term',
          field: 'paymentTermName',
          filter: 'agTextColumnFilter',
          width: 150,
        },
        {
          ...amountCurrencyColumn(this.formatter, 'currencyId', 'contractAmount', 'Contract Amount'),
          width: 150,
        },
      ],
      masterDetail: true,
      detailCellRendererParams: {
        detailGridOptions: {
          detailRowAutoHeight: true,
          columnDefs: [
            {
              headerName: 'Line',
              field: 'elementNumber',
              filter: 'agSetColumnFilter',
              width: 80,
              sort: 'asc',
            },
            { headerName: 'Item', field: 'itemName', filter: 'agTextColumnFilter', width: 150 },
            { ...quantityUnitColumn(this.formatter, 'quantityUnitId', 'quantity'), width: 150 },
            {
              ...priceColumn(this.formatter, 'priceUnitId', 'currencyId', 'price', 'Price'),
              width: 150,
            },
            {
              ...amountCurrencyColumn(this.formatter, 'currencyId', 'lineAmount', 'Line Amount'),
              width: 150,
            },
          ],
          getContextMenuItems: getContextMenuItems(),
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.lines);
        },
      },
      getContextMenuItems: getContextMenuItems(
        {
          name: 'Go To',
          menuItems: [gotoMenuItem(this.delegate, 'Contract', 'contractId', SourceEntityType.CONTRACT_KEY, 'get', 'contractNumber')],
          iconClass: GoToIcon,
        },
        {
          name: 'Forms',
          menuItems: [this.createAdvanceMenuItem()],
        }
      ),
    };
  }

  fetchData() {
    if (this.filterForm.invalid) return;
    const rid = this.spinner.startRequest(randomFetchSynonym() + ' Contracts', undefined, true);
    this.api.rpc<ListResponse<AdvancesToOpenQueueData>>(endpoints.listAdvancesToOpen, { filters: this.filterForm.value }, { list: [], count: 0 }).subscribe((res) => {
      this.spinner.completeRequest(rid);
      const dataMap = res.list.reduce((map, entry) => {
        const contract = map.get(entry.contractId) || {
          advancePercentage: entry.advancePercentage,
          advanceAmount: entry.advanceAmount,
          contractDate: entry.contractDate,
          contractId: entry.contractId,
          contractNumber: entry.contractNumber,
          contractType: entry.contractType,
          contractTypeLabel: entry.contractTypeLabel,
          counterpartyId: entry.counterpartyId,
          counterpartyName: entry.counterpartyName,
          counterpartyReference: entry.counterpartyReference,
          paymentTermName: entry.paymentTermName,
          productName: entry.productName,
          traderId: entry.traderId,
          traderName: entry.traderName,
          currencyId: entry.currencyId,
          quantityUnitId: entry.quantityUnitId,
          quantity: 0,
          lines: [],
          contractAmount: 0,
        };
        contract.lines.push({
          currencyId: entry.currencyId,
          elementId: entry.elementId,
          elementNumber: entry.elementNumber,
          itemName: entry.itemName,
          lineAmount: entry.lineAmount,
          price: entry.price,
          priceUnitId: entry.priceUnitId,
          quantity: entry.quantity,
          quantityUnitId: entry.quantityUnitId,
        });
        contract.contractAmount += entry.lineAmount;
        contract.quantity += entry.quantity;
        return map.set(entry.contractId, contract);
      }, new Map<number, AdvancesToOpenTableEntry>());
      this.data = Array.from(dataMap.values());
    });
  }

  createAdvanceMenuItem(): ContextMenuGetter {
    return (params: GetContextMenuItemsParams) => {
      if (!params.node?.data) return [];
      if (!this.authorized[endpoints.createAdvanceFromContract]) return [];
      const contract: AdvancesToOpenTableEntry = params.node.data;

      return {
        name: 'Create Advance',
        action: () => this.createAdvance(contract),
      };
    };
  }

  createAdvance(contract: AdvancesToOpenTableEntry) {
    const paymentTermName = contract.paymentTermName;
    const currency = this.commonData.staticCurrencies.value.find((c) => c.id === contract.currencyId);
    const price = contract.contractAmount;
    const valueDate = getTodayUTC();
    const contractDate = contract.contractDate;
    const amount = parseFloat(Math.max((contract.contractAmount * contract.advancePercentage) / 100, contract.advanceAmount).toFixed(2));

    this.selector
      .openForm<AdvanceForm, CreateAdvanceComponent, AdvancePrefill>(CreateAdvanceComponent, {
        title: 'New Advance',
        prefillValue: { paymentTermName, currency, price, valueDate, contractDate, amount },
        maxWidth: 300,
      })
      .subscribe((formResult) => {
        if (formResult === 'Close') return;
        const rid = this.spinner.startRequest('Creating Advance');
        const request: CreateAdvanceRequest = {
          valueDate: formResult.valueDate,
          dueDateType: formResult.dueDateType,
          dueDate: formResult.dueDate,
          automaticAmount: formResult.automaticAmount,
          amount: formResult.amount,
          contractId: contract.contractId,
        };
        this.api.rpc<Advance>(endpoints.createAdvanceFromContract, request, null).subscribe((loc) => {
          this.spinner.completeRequest(rid);

          if (loc) {
            this.fetchData();
            const actions: DialogAction[] = [{ text: 'Close' }];
            if (this.entityLookup.getEntityExists(SourceEntityType.ADVANCE_KEY)) {
              actions.push({
                text: 'Go to Advance',
              });
            }
            if (this.authorized[endpoints.generateDocumentsFromPacket]) {
              actions.push({
                text: 'Print Document',
              });
            }
            this.dialog
              .open({
                title: 'Success',
                content: 'Advance successfully created',
                actions,
              })
              .result.subscribe((result) => {
                if (!(result instanceof DialogCloseResult)) {
                  if (result.text === 'Go to Advance') {
                    this.entityLookup.gotoEntity(SourceEntityType.ADVANCE_KEY, loc.id, 'get', true);
                  }
                  if (result.text === 'Print Document') {
                    const rid = this.spinner.startRequest(randomFetchSynonym() + ' Documents');
                    this.api
                      .rpc<ListResponse<Document>>(
                        endpoints.listDocuments,
                        {
                          filters: {
                            entityType: SourceEntityType.ADVANCE_KEY,
                            entityId: loc.id,
                          },
                        },
                        null
                      )
                      .subscribe((documentRes) => {
                        this.spinner.completeRequest(rid);
                        if (documentRes) {
                          printAdvance(this.delegate, loc, documentRes.list);
                        }
                      });
                  }
                }
              });
          }
        });
      });
  }

  getTabTitle() {
    return 'Advances To Open';
  }
}

export type AdvanceFilterForm = Pick<AdvancesToOpenQueueData, 'contractType'>;

export function printAdvance(delegate: DelegateService, advance: Advance, documents: Document[]) {
  const selector = delegate.getService('selector');
  const api = delegate.getService('api');
  const spinner = delegate.getService('spinner');
  const dialog = delegate.getService('dialog');
  selector
    .openForm<PrintDocumentForm, PrintDocumentComponent>(PrintDocumentComponent, {
      title: 'Generate Document',
      submitButtonText: 'Generate',
      initializer: (p: PrintDocumentComponent) => {
        p.documents = documents;
        p.entityName = `${advance.number}`;
        p.entityType = SourceEntityType.ADVANCE_KEY;
      },
      maxWidth: 400,
    })
    .subscribe((popupResult) => {
      if (popupResult !== 'Close') {
        let request = {
          packetId: popupResult.packet?.id,
          outputName: popupResult.saveAs,
          savingLocation: popupResult.location === -1 ? null : popupResult.location,
          entityId: advance.id,
        };
        let rid = spinner.startRequest('Generating Document');
        api
          .rpc<PrintDocumentResponse>(endpoints.generateDocumentsFromPacket, request, null, {
            blockRedirect: true,
          })
          .subscribe((packet) => {
            spinner.completeRequest(rid);

            const actions: CustomDialogResult[] = [{ text: 'Close' }];
            if (/Win/.test(navigator.platform) || packet.locationType === StorageTypes.URL || packet.locationType === StorageTypes.DATABASE_FILE) {
              actions.push({ text: 'Open File', themeColor: 'primary' });
            }
            dialog
              .open({
                title: 'Success',
                content: 'File generated successfully',
                actions,
              })
              .result.subscribe((action) => {
                if ((action as DialogAction).text === 'Open File') {
                  handlePrintResponse(delegate, packet);
                }
              });
          });
      }
    });
}
