import { Component, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { UserGroup } from 'src/lib/newBackendTypes';
import { FormElementComponent } from '../form-element/form-element.component';

@UntilDestroy()
@Component({
  selector: 'user-group-dropdown',
  templateUrl: './user-group-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => UserGroupDropdownComponent) }],
})
export class UserGroupDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  protected dropdown: ComboBoxComponent;

  filteredOptions: UserGroup[];
  filterObservable: BehaviorSubject<string>;

  fetching = false;

  constructor(controlDir: NgControl, store: Store, private api: ThalosApiService) {
    super(controlDir, store);

    this.filterObservable = new BehaviorSubject('');
    this.filteredOptions = [];
  }

  ngOnInit(): void {
    if (!!this.value) this.value = this.value;

    this.initFilterSubscriber().subscribe((list) => {
      this.filteredOptions = list;
    });
  }

  protected initFilterSubscriber() {
    return this.filterObservable.asObservable().pipe(
      filter((text) => text !== null),
      switchMap((text: string) => {
        this.fetching = true;
        return this.api.rpc<UserGroup[]>(endpoints.findGroups, { query: text === '' ? '*' : `*${text}*` }, []);
      }),
      catchError((error) => {
        this.fetching = false;

        throw error;
      }),
      tap(() => {
        this.fetching = false;
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.dropdown)
      this.dropdown.filterChange
        .asObservable()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged((a, b) => a === b),
          debounceTime(300)
        )
        .subscribe((text) => {
          this.handleFilter(text);
        });
  }

  protected handleFilter(event) {
    this.filterObservable.next(event);
    return this.filterObservable;
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  comparator(a, b) {
    return a?.['cn'] === b?.['cn'];
  }
}
