import { Component, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { Store } from 'src/app/core/services/store.service';
import { DiscrepancyStatus, discrepancyStatuses } from 'src/lib/newBackendTypes/discrepancy';
import { FormElementComponent } from '../form-element/form-element.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs';

type DiscrepancyOption = { value: DiscrepancyStatus; label: string; phase: string };

@Component({
  selector: 'discrepancy-status-dropdown',
  templateUrl: './discrepancy-status-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => DiscrepancyStatusDropdownComponent) }],
})
export class DiscrepancyStatusDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  dropdown: ComboBoxComponent;

  data: (GroupResult | DiscrepancyOption)[];
  filteredData: (GroupResult | DiscrepancyOption)[];

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);

    this.data = groupBy(discrepancyStatuses, [{ field: 'phase' }]);
    this.filteredData = this.data;
  }

  ngOnInit(): void {}

  handleFilter(text: string) {
    let groupsFiltered = this.data
      ? this.data.map((value) => {
          if (this.isGroupResult(value)) return { ...value, items: value.items.filter(this.filter(text)) };
          else return value;
        })
      : [];
    let filtered = groupsFiltered.filter((value) => {
      if ((value as GroupResult).items.length === 0) return false;
      if (this.isGroupResult(value)) return true;
      return value.label.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });
    this.filteredData = filtered;
  }

  filter(text: string) {
    return (value: DiscrepancyOption) => {
      return value.label.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    };
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.dropdown)
      this.dropdown.filterChange
        .asObservable()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged((a, b) => a === b),
          debounceTime(100)
        )
        .subscribe((text) => {
          if (this.empty || !!text) this.handleFilter(text);
        });
  }

  isGroupResult(entry: GroupResult | { value: DiscrepancyStatus; label: string; phase: string }): entry is GroupResult {
    return (entry as GroupResult).aggregates !== undefined;
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  getLabelFromPrimitive() {
    if (!this._value) return '';
    if (!this.data) return '';
    let data = this.data.find((d) => {
      if (this.isGroupResult(d))
        return d.items.some((v: DiscrepancyOption) => {
          return v.value === this._value;
        });
      return d.value === this._value;
    });
    if (this.isGroupResult(data)) {
      data = <DiscrepancyOption>data.items.find((d: DiscrepancyOption) => {
        return d.value === this._value;
      });
    }
    if (data) return data.label;
    return this._value;
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  onSelectionChange(s) {
    if (s === undefined) {
      this.handleFilter('');
    }
  }
}
