import { ListLayout, ListLayoutDefault } from '../views';
import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { BankAccount } from './account';
import { Address } from './address';
import { Approval } from './approval';
import { ApprovalRequest } from './approvalRequest';
import { ApprovalTypeApprover } from './approvalTypeApprover';
import { AuthorizedCurrency } from './authorizedCurrency';
import { ClauseTemplate } from './clauseTemplate';
import { ClientCreditInsurance } from './clientCreditInsurance';
import { Comment } from './comment';
import { ContactBankInformation } from './contactBankInformation';
import { ContactMetadata, FixationRule, PaymentFrequency } from './contactMetadata';
import { ContactMethod } from './contactMethod';
import { ContactTypeAssignment } from './contactTypeAssignment';
import { PhysicalContract } from './contract';
import { PhysicalContractExpense } from './contractExpense';
import { ContaminationDiscrepancy, DemurrageAndDetentionDiscrepancy, LogisticsDiscrepancy, PercentRecoveryDiscrepancy, QualityDiscrepancy, WeightDiscrepancy } from './discrepancy';
import { Document } from './document';
import { PhysicalDraft } from './draft';
import { YN } from './enums';
import { FiscalCompany } from './fiscalCompany';
import { Booking } from './freightBooking';
import { GlAccountView, GlAccountLeaves } from './glAccount';
import { InvoiceHeader } from './invoice';
import { Language } from './language';
import { OceanratesRate } from './oceanRate';
import { PropertyDocument } from './propertyDocument';
import { ServiceOrder } from './serviceOrder';
import { Task, TasksAssignee } from './task';
import { WarehouseMovement } from './warehouseMovement';
import { Country } from './country';
import { UserAuthorizedCompanies } from './userAuthorizedCompanies';
import { UserAccount } from './userAccount';

export enum ContactCategory {
  INDIVIDUAL = 1,
  ORGANIZATION = 2,
}
export const ContactCategories = enumOptionsFromEnum(ContactCategory);
export enum Deleted {
  Y = 'Y',
  N = 'N',
  NY = 'NY',
  YN = 'YN',
}
export type Contact = {
  id: number;
  displayName: string;
  parentId: number | null;
  baseCountryKey: number | null;
  category: ContactCategory | null;
  salutation: '';
  firstName: string;
  lastName: string;
  title: string;
  departmentName: string;
  primaryType: string | null;
  distributionList: 'Y';
  photo: null;
  photoUpdatedBy: 0;
  photoUpdateDate: null;
  alfDefaultNbType: 0;
  departmentId: number | null;
  archived: YN | null;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  languageId: number;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  zipCode: string | null;
  state: string | null;
  countryKey: number;
  modifiedBy: null;
  lastUpdate: null;
  defaultClauseId: number | null;

  invoicedToId: number;
  fiscalCompanyId: number;
  taxId: string;
  receivablesAccountId: number;
  depositToAccountCode: number;
  payablesAccountId: number;
  payFromAccountCode: number;
  supplierInfoId: number | null;
  customerInfoId: number | null;
  swiftNumber: string;
  abaRouting: string;
  investmentGrade: YN | null;
  finalInvoiceRequired: YN | null;
  paymentFrequency: PaymentFrequency | null;
  finOperator: Contact | null;
  finOperatorId: number | null;
  adminFeeCharge: YN | null;
  requires1099: YN | null;
  fixationRule: FixationRule | null;
  fixationDays: number | null;
  assignedTraderId: number | null;
  provisionalWeight: YN;
  sendInvoice: YN;
  relatedCompanyId: number | null;
  traderAssistantId: number | null;
  signedStampedDocuments: YN;

  //RELATIONS
  contactTypeAssignments?: ContactTypeAssignment[];
  methods?: ContactMethod[];
  metadata?: ContactMetadata[];
  children?: Contact[];
  contractsAsCounterparty?: PhysicalContract[];
  draftsAsCounterparty?: PhysicalDraft[];
  contractsAsTrader?: PhysicalContract[];
  draftsAsTrader?: PhysicalDraft[];
  contractsAsCompany?: PhysicalContract[];
  draftsAsCompany?: PhysicalDraft[];
  language?: Language;
  parent?: Contact;
  parentRelatedCompany?: Contact;
  traderAssistant?: Contact;
  childrenRelatedCompany?: Contact[];
  authorizedApprovalTypes: ApprovalTypeApprover[];
  approvedApprovals?: Approval[];
  requestedApprovals?: Approval[];
  approvalsRequested?: ApprovalRequest[];
  cbiAsBeneficiaryBank: ContactBankInformation[];
  cbiAsCorrespondentBank: ContactBankInformation[];
  cbiAsIntermediaryBank: ContactBankInformation[];
  user?: UserAccount;
  supplierAsLogisticsDiscrepancy?: LogisticsDiscrepancy[];
  customerAsLogisticsDiscrepancy?: LogisticsDiscrepancy[];
  supplierAsDemurrageDiscrepancy?: DemurrageAndDetentionDiscrepancy[];
  customerAsDemurrageDiscrepancy?: DemurrageAndDetentionDiscrepancy[];
  supplierAsContaminationDiscrepancy?: ContaminationDiscrepancy[];
  customerAsContaminationDiscrepancy?: ContaminationDiscrepancy[];
  supplierAsPercentageOfRecoveryDiscrepancy?: PercentRecoveryDiscrepancy[];
  customerAsPercentageOfRecoveryDiscrepancy?: PercentRecoveryDiscrepancy[];
  supplierAsWeightDiscrepancy?: WeightDiscrepancy[];
  customerAsWeightDiscrepancy?: WeightDiscrepancy[];
  supplierAsQualityDiscrepancy?: QualityDiscrepancy[];
  customerAsQualityDiscrepancy?: QualityDiscrepancy[];
  comments?: Comment[];
  approvals?: Approval[];
  linkedDocuments?: Document[];
  serviceOrdersAsContact?: ServiceOrder[];
  serviceOrdersAsCompany?: ServiceOrder[];
  serviceOrderAsBank?: ServiceOrder[];
  vouchers?: InvoiceHeader[];
  draftExpensesAsContact?: PhysicalContractExpense[];
  contractExpensesAsContact?: PhysicalContractExpense[];
  // fiscalCompany?: FiscalCompany
  bookingsAsOperator?: Booking[];
  bookingsAsShippingLine?: Booking[];
  bookingsAsFreightForwarder?: Booking[];
  // jobsRequested?: Job[]
  flexViewLayouts?: ListLayout[];
  shipmentsAsWarehouse?: PropertyDocument[];
  bookingsAsSTBOperator?: Booking[];
  bookingsAsSTSOperator?: Booking[];
  bookingsAsBTSOperator?: Booking[];
  bookingsAsCTSOperator?: Booking[];
  bookingsAsConsignee?: Booking[];
  bookingsAsNotifyParty1?: Booking[];
  warehouseMovements?: WarehouseMovement[];
  warehouseMovementsCreated?: WarehouseMovement[];
  bookingsAsNotifyParty2?: Booking[];
  tasks?: TasksAssignee[];
  defaultFlexLayouts?: ListLayoutDefault[];
  bookingsAsTruckingCompany?: Booking[];
  tasksAsCreator?: Task[];
  rates?: OceanratesRate[];
  // flexviewRequests?: FlexViewRequest[]
  creditInsuranceEntries?: ClientCreditInsurance[];
  creditInsiranceEntriesAsInsuranceCompany?: ClientCreditInsurance[];
  addresses?: Address[];
  bankInformation?: ContactBankInformation[];
  authorizedCurrencies?: AuthorizedCurrency[];
  assignedTrader?: Contact | null;
  contactsAsAssignedTrader?: Contact[];
  invoicedTo?: Contact;
  contactsAsInvoicedTo?: Contact[];
  parentFiscalCompany?: Contact;
  contactsAsParentFiscalCompany?: Contact[];
  clauseTemplate?: ClauseTemplate;
  receivablesAccount?: GlAccountLeaves | GlAccountView;
  payablesAccount?: GlAccountLeaves | GlAccountView;
  depositToAccount?: BankAccount;
  payFromAccount?: BankAccount;
  fiscalCompany?: FiscalCompany;
  baseCountry?: Country;
  authorizedCompaniesByContact?: UserAuthorizedCompanies[];
  authorizedCompaniesByCompany?: UserAuthorizedCompanies[];
};
export type ContactPrimitive = Pick<Contact, 'id' | 'displayName'> & Partial<Omit<Contact, 'id' | 'displayName'>>;

export type CreateContactRequest = Pick<
  Contact,
  | 'displayName'
  | 'lastName'
  | 'firstName'
  | 'parentId'
  | 'category'
  | 'languageId'
  | 'title'
  | 'swiftNumber'
  | 'abaRouting'
  | 'investmentGrade'
  | 'fixationDays'
  | 'fixationRule'
  | 'assignedTraderId'
  | 'defaultClauseId'
  | 'taxId'
  | 'provisionalWeight'
  | 'relatedCompanyId'
  | 'traderAssistantId'
  | 'signedStampedDocuments'
>;
export type UpdateContactRequest = Subset<Contact, 'id', keyof CreateContactRequest> & Partial<Pick<Contact, 'archived'>>;

export type UpdateContactAccountingInfoRequest = Subset<
  Contact,
  'id',
  | 'invoicedToId'
  | 'fiscalCompanyId'
  | 'receivablesAccountId'
  | 'depositToAccountCode'
  | 'payablesAccountId'
  | 'payFromAccountCode'
  | 'adminFeeCharge'
  | 'requires1099'
  | 'finalInvoiceRequired'
  | 'paymentFrequency'
  | 'provisionalWeight'
  | 'sendInvoice'
  | 'finOperatorId'
>;

export function fullNameFromContact(contact: Contact) {
  if (contact.category === ContactCategory.INDIVIDUAL) {
    return !!contact.firstName ? `${contact.firstName} ${contact.lastName}` : contact.lastName;
  } else {
    return contact.lastName;
  }
}
