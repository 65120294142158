import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListQuery } from 'src/lib/generics';
import { fromBradyDateOrNull } from 'src/lib/helperFunctions';
import { ContractListView, ContractType, YN } from 'src/lib/newBackendTypes';
import { FormElementComponent } from '../form-element/form-element.component';

type ContractOption = Omit<ContractListView, 'contractDate'> & {
  label: string;
  contractDate: Date | null;
};

@UntilDestroy()
@Component({
  selector: 'contract-dropdown',
  templateUrl: './contract-dropdown.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => ContractDropdownComponent) }],
})
export class ContractDropdownComponent extends FormElementComponent {
  @ViewChild('dropdown', { static: false })
  private dropdown: ComboBoxComponent;

  _value: ContractOption = null;

  @Input()
  type: ContractType;

  dropdownOptions: ContractOption[];

  invalidInput: boolean;

  constructor(controlDir: NgControl, store: Store, private api: ThalosApiService) {
    super(controlDir, store);
    this.dropdownOptions = [];
  }

  setValue(contract?: ContractListView) {
    if (!contract) {
      this._value = null;
    } else {
      this._value = {
        ...contract,
        label: `${contract.contractNumber}`,
        contractDate: typeof contract.contractDate === 'number' ? fromBradyDateOrNull(contract.contractDate) : (contract.contractDate as any),
      };
    }
    if (!!contract && !this.dropdownOptions.some((c) => c.id === contract.id)) {
      this.dropdownOptions.push(this.value);
    }
  }

  ngOnInit(): void {
    if (!!this.value) this.value = this.value;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.dropdown)
      this.dropdown.filterChange
        .asObservable()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged((a, b) => a === b),
          debounceTime(300)
        )
        .subscribe((text) => {
          this.handleFilter(text);
        });
  }

  handleFilter(text: string): void {
    let args: ListQuery<ContractListView> = {
      filters: {
        archived: YN.N,
      },
      take: 20,
      orderBy: {
        fieldName: 'contractNumber',
        order: 'DESC',
      },
    };
    let num: number;
    if (text.length > 0) {
      num = parseInt(text);
      if (isNaN(num)) {
      } else {
        args.filters.contractNumber = num;
      }
    }
    if (this.type) args.filters.type = this.type;
    this.api
      .rpc<ListResponse<ContractListView>>(endpoints.listContractData, args, { list: [], count: 0 })
      .pipe(
        map((res) => res.list),
        map((list) => {
          return list.map((contract) => {
            return {
              ...contract,
              contractDate: fromBradyDateOrNull(contract.contractDate),
              label: `${contract.contractNumber}`,
            };
          });
        })
      )
      .subscribe((contracts) => (this.dropdownOptions = contracts));
  }

  public onOpen() {
    if (!!this.empty) {
      this.handleFilter('');
    }
  }

  public get empty() {
    return !this.value || (!!this.value && this.value.length === 0);
  }

  public focus() {
    if (this.dropdown) {
      setTimeout(() => {
        this.dropdown.focus();
      });
    }
  }

  comparator(a: ContractListView, b: ContractListView) {
    return a?.id === b?.id;
  }
}
