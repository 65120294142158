<kendo-multiselect
  #multiselect
  [autoClose]="autoClose"
  [readonly]="readonly"
  [data]="dropdownOptions"
  [filterable]="true"
  [ngClass]="{ readonly: readonly }"
  [valueField]="dropdownConfig.valueField"
  [textField]="!!dropdownConfig.labelTransform ? 'label' : dropdownConfig.labelField"
  [(ngModel)]="_value"
  [placeholder]="placeholder"
  (removeTag)="tagRemoved($event)"
  [itemDisabled]="itemDisabled || neverDisable"
  [loading]="fetching"
  (open)="onOpen()"
  (valueChange)="onValueChange(value)"
  (blur)="onBlur()"
>
</kendo-multiselect>
