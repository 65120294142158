import { Component, EventEmitter, Output, ViewChild, forwardRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'custom-multiselect',
  templateUrl: './custom-multiselect.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => CustomMultiselectComponent) }],
})
export class CustomMultiselectComponent extends FormElementComponent {
  @ViewChild('multiselect', { static: false })
  multiselect: MultiSelectComponent;

  @Output()
  removeTag?: EventEmitter<any> = new EventEmitter();

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
  }

  ngOnInit(): void {}

  onBlur() {
    if (this.multiselect && this.multiselect.searchbar.value) {
      if (this._value) {
        //this._value.push(this.multiselect.searchbar.value)
      } else {
        //this._value = [this.multiselect.searchbar.value]
      }
      this.multiselect.clearFilter();
    }
    super.onBlur();
  }

  tagRemoved(event) {
    this.removeTag.emit(event);
  }

  comparator(a: any[] | null, b: any[] | null) {
    if (!a && !b) return true;
    if ((!a && !!b) || (!!a && !b)) return false;
    return _.isEqual(a, b);
  }
}
