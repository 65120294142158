import { AfterViewInit, Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { AgGridCellEditor } from 'src/lib/agGridEditor';

@Component({
  selector: 'grid-text-editor',
  templateUrl: 'text-input.component.html',
})
export class GridTextEditorComponent extends AgGridCellEditor implements AgEditorComponent, AfterViewInit {
  get value() {
    return this._value;
  }

  constructor() {
    super();
  }
}
