import { Subset } from '../generics';
import { City } from './city';
import { Contact } from './contact';
import { YN } from './enums';
import { InvoiceHeader } from './invoice';
import { PayOrderLine } from './payOrder';

export enum PaymentInformationType {
  ACH = 2,
  CHECK = 1001,
  WIRE = 4,
  ONLINE = 1004,
}
export enum BankFeeAttribution {
  OUR_CHARGE = 1,
  INVOICING_PARTY = 2,
  SHARED = 3,
  NONE = 0,
}
export enum BankAccountType {
  CHECKING = 'C',
}

export type ContactBankInformation = {
  id: number;
  contactBankInformationIden: string | null;
  default: YN;
  type: PaymentInformationType;
  beneficiaryAccountNumber: string | null;
  beneficiaryName: string | null;
  beneficiaryAddress1: string | null;
  beneficiaryAddress2: string | null;
  beneficiaryPostCode: string | null;
  beneficiaryCity: string | null;
  beneficiaryCountryKey: number;
  beneficiaryBankName: string | null;
  beneficiaryBankAddress1: string | null;
  beneficiaryBankAddress2: string | null;
  beneficiaryBankPostCode: string | null;
  beneficiaryBankCity: string | null;
  beneficiaryBankCountry: string | null;
  beneficiaryBankSwiftCode: string | null;
  swiftYN: YN;
  bankFeeAttribution: BankFeeAttribution;
  notes: string | null;
  correspondentBankName: string | null;
  correspondentBankAddress1: string | null;
  correspondentBankAddress2: string | null;
  correspondentBankPostCode: string | null;
  correspondentBankCity: string | null;
  correspondentBankCountry: string | null;
  correspondentBankSwiftCode: string | null;
  intermediaryBankName: string | null;
  intermediaryBankAddress1: string | null;
  intermediaryBankAddress2: string | null;
  intermediaryBankPostCode: string | null;
  intermediaryBankCity: string | null;
  intermediaryBankCountry: string | null;
  intermediaryAccount: string | null;
  intermediaryBankSwiftCode: string | null;
  beneficiaryBankCountryKey: number | null;
  beneficiaryAccountIsIban: YN;
  correspondentAccountIsIban: YN;
  intermediaryAccountIsIban: YN;
  beneficiaryState: string | null;
  intermediaryBankId: number | null;
  beneficiaryBankId: number | null;
  correspondentBankId: number | null;
  furtherCreditBeneficiaryName: string | null;
  furtherCreditAccountNumber: string | null;
  archived: YN;
  contactId: number;
  beneficiaryFullAddress: string | null;
  cityId: number | null;
  createdBy: number | null;
  creationDate: Date | null;
  invoices?: InvoiceHeader[];
  paymentOrderLines?: PayOrderLine[];
  correspondentBank?: Contact;
  intermediaryBank?: Contact;
  bank?: Contact;
  contact?: Contact;
  place?: City;
};

type CreateAchContactBankInfo = Subset<
  ContactBankInformation,
  'beneficiaryBankId' | 'beneficiaryAccountNumber' | 'contactId' | 'beneficiaryName' | 'cityId' | 'beneficiaryFullAddress',
  'notes' | 'archived' | 'default' | 'beneficiaryPostCode'
> & { type: PaymentInformationType.ACH };
type CreateCheckContactBankInfo = Subset<ContactBankInformation, 'contactId' | 'beneficiaryName' | 'cityId' | 'beneficiaryFullAddress', 'default' | 'notes' | 'archived' | 'beneficiaryPostCode'> & {
  type: PaymentInformationType.CHECK;
};
type CreateWireContactBankInfo = Subset<
  ContactBankInformation,
  'contactId' | 'beneficiaryName' | 'beneficiaryAccountNumber' | 'beneficiaryBankId' | 'cityId' | 'beneficiaryFullAddress' | 'bankFeeAttribution',
  'default' | 'archived' | 'notes' | 'beneficiaryPostCode' | 'furtherCreditAccountNumber' | 'furtherCreditBeneficiaryName' | 'intermediaryBankId' | 'correspondentBankId'
> & { type: PaymentInformationType.WIRE };
type CreateOnlineContactBankInfo = Subset<ContactBankInformation, 'contactId', 'notes' | 'archived' | 'default'> & { type: PaymentInformationType.ONLINE };
export type CreateContactBankInformationRequest = CreateAchContactBankInfo | CreateCheckContactBankInfo | CreateWireContactBankInfo | CreateOnlineContactBankInfo;

export type UpdateContactBankInformationRequest = Subset<ContactBankInformation, 'id', 'default' | 'notes' | 'archived'>;
