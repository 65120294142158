import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { TypedFormGroup } from 'src/lib/typedForms';
import { lbPriceFormat } from 'src/lib/commonTypes';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getToday, markFormGroupTouched } from 'src/lib/helperFunctions';
import { round } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'mwti-market-price',
  templateUrl: './mwti-market-price.component.html',
})
export class MwtiMarketPriceComponent implements OnInit, OnDestroy, ModalFormComponent<MWTIMarketPriceForm> {
  form: TypedFormGroup<MWTIMarketPriceForm>;

  popup = true;

  priceFormat = lbPriceFormat();

  constructor() {
    this.form = new TypedFormGroup<MWTIMarketPriceForm>({
      valueDate: new UntypedFormControl(getToday(), Validators.required),
      price: new UntypedFormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  prefillForm(form: MWTIMarketPriceForm) {
    this.form.patchValue(form);
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): MWTIMarketPriceForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    return { valueDate: formVal.valueDate, price: round(formVal.price, 4) };
  }
}

type MWTIMarketPriceForm = {
  price: number;
  valueDate: Date;
};
