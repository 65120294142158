import { EventEmitter, Input, OnInit, Output, Self, Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive()
export abstract class UnguardedFormElementComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder: string = '';

  @Output()
  blur: EventEmitter<void> = new EventEmitter();

  _value: any;

  public set value(value: any) {
    this.setValue(value);
  }

  setValue(value: any, external?: boolean) {
    this._value = value;
    if (!external) this.onValueChange(value);
  }

  public get value(): any {
    return this.getValue();
  }

  getValue() {
    if (this._value === undefined) return null;
    return this._value;
  }

  onChange: (value) => {};
  onTouch: () => {};

  constructor(@Self() protected controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(obj: any): void {
    this.setValue(obj, true);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  //may be overwritten
  public onValueChange(value): boolean | void {
    if (this.comparator(this.controlDir?.value, this.value)) {
      return false;
    }
    if (value === undefined) {
      this.onTouch();
      this.onChange(null);
    } else {
      this.onTouch();
      this.onChange(this.value);
    }
  }

  public onBlur() {
    let changed = this.onValueChange(this.value);
    if (changed !== false) this.onTouch();
    this.blur.emit();
  }

  public getFormControl() {
    return this.controlDir.control;
  }

  focus() {}

  comparator(a, b) {
    return a === b;
  }
}
