import { AfterViewInit, OnDestroy, ViewChild, Directive } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgEditorComponent, ICellRendererAngularComp } from 'ag-grid-angular';
import { filter } from 'rxjs/operators';
import { FormElementComponent } from 'src/app/shared/form-elements/form-element/form-element.component';
import { EditableGrid } from './agGridRenderers';

@UntilDestroy()
@Directive()
export abstract class AgGridCellEditor implements AgEditorComponent, ICellRendererAngularComp, AfterViewInit, OnDestroy {
  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing

  private suppressChangeEvent: boolean = false;

  params: any;

  get _value() {
    return this.formControl.value;
  }

  set _value(val) {
    this.formControl.setValue(val);
  }

  set validator(val: ValidatorFn | ValidatorFn[]) {
    this.formControl.setValidators(val);
  }

  writeValueWithoutEvent(val) {
    this.suppressChangeEvent = true;
    this._value = val;
    this.suppressChangeEvent = false;
  }

  abstract get value();
  formControl: UntypedFormControl;

  readonly: boolean = false;

  constructor() {
    this.formControl = new UntypedFormControl(null);

    this.formControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((_) => this.formControl.touched)
      )
      .subscribe(() => {
        this.getValue();
      });
  }

  callback: (c: this, supressEvent?: boolean) => void;

  label!: string;
  required: boolean = false;

  type: 'Editor' | 'Renderer';

  @ViewChild(FormElementComponent, { static: false, read: FormElementComponent })
  element: FormElementComponent;

  context?: EditableGrid;

  getValue() {
    if (this.callback && !this.readonly) {
      this.callback(this, this.suppressChangeEvent);
    }
    return this.value;
  }

  agInit(params: any): void {
    this.params = params;
    if (params?.node?.group) return;

    if (params.init && typeof params.init === 'function') {
      params.init(this);
    }
    if (params.callback && typeof params.callback === 'function') {
      this.callback = params.callback;
    }
    if (params.type && typeof params.type === 'string') {
      if (params.type === 'Editor') {
        this.type = 'Editor';
      } else {
        this.type = 'Renderer';
      }
    }
    if (params.context && typeof params.context === 'object') {
      this.context = params.context;
    }
    if (params.componentParams && typeof params.componentParams === 'object') {
      for (let key in params.componentParams) {
        this[key] = params.componentParams[key];
      }
    }

    let validators;
    if (params.validatorGetter && typeof params.validatorGetter === 'function') {
      validators = params.validatorGetter(params);
    } else validators = params.validator;

    if (validators && (typeof validators === 'function' || (Array.isArray(validators) && validators.every((f) => typeof f === 'function')))) {
      this.formControl.setValidators(validators);
    }

    if (typeof params.readonly === 'boolean') {
      this.readonly = params.readonly;
    } else if (params.readonly && typeof params.readonly === 'function') {
      let readonly = params.readonly(params);
      if (typeof readonly === 'boolean') {
        this.readonly = readonly;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.params?.node?.group) return;
    if (this.context && !this.context.rendered && !!this.context.firstCellRendered) {
      this.context.rendered = true;
      setTimeout(() => {
        this.context.firstCellRendered();
      });
    }
  }

  ngOnDestroy() {}

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    // our editor will reject any value greater than 1000
    if (!this.formControl.value && this.required) return true;
    return false;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  isPopup() {
    return true;
  }

  refresh(params) {
    return true;
  }

  blur() {
    if (this.params?.node?.group) return;
    if (this.type === 'Renderer') {
      this.getValue();
    }
  }

  focus() {
    if (this.params?.node?.group) return;
    if (this.element) {
      this.element.focus();
    }
  }
}
