import { Contact, ContractType, CoversExchange, Currency, FiscalCompany, JournalHeader, YN } from '.';
import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';

export enum ExchangeReasons {
  SPEC = 103003,
}
export enum OperationType {
  NORMAL = 1,
}
export enum RateTypes {
  MULT = 1,
  DIV = 2,
}

export enum AdminType {
  ADMIN = 1,
  UNHEDGE = 2,
  ROLLOVER = 3,
}

export const AdminTypes = enumOptionsFromEnum(AdminType);

export class ExchangeContract {
  id: number;
  bankCode: number;
  reasonKey: ExchangeReasons;
  companyCode: number;
  currKey1: number;
  currKey2: number;
  contractNumber: number;
  operationDate: Date;
  valueDate: Date;
  confirmed: YN;
  bankReference: string;
  rateType: RateTypes;
  operationNumber: 1;
  buysell: ContractType;
  amount1: number;
  amount2: number;
  rate: number;
  oldReference: string | null;
  journalKey: number | null;
  acceptBl: YN;
  spotRate: number;
  rateDifferential: number;
  reportType: OperationType;
  reportKey: number | null;
  productClass: 'Admin';
  versionNb: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  brokerCode: number | null;
  adminType: AdminType | null;
  internalRef: string;
  covers?: CoversExchange[];
  bank?: Contact;
  broker?: Contact;
  fromCurrency?: Currency;
  toCurrency?: Currency;
  premiumOnly: number;
  fiscalCompany?: FiscalCompany;
  journal?: JournalHeader;
}

export type CreateExchangeContractRequest = Subset<
  ExchangeContract,
  | 'companyCode'
  | 'currKey1'
  | 'currKey2'
  | 'operationDate'
  | 'buysell'
  | 'valueDate'
  | 'confirmed'
  | 'bankReference'
  | 'amount1'
  | 'amount2'
  | 'bankCode'
  | 'brokerCode'
  | 'adminType'
  | 'internalRef'
  | 'premiumOnly'
> & {
  covers: Subset<CoversExchange, 'contractKey' | 'amountCovered' | 'quantityCovered' | 'quantityUnitKey'>[];
};

export type UpdateExchangeContractRequest = Subset<
  ExchangeContract,
  'id',
  | 'companyCode'
  | 'currKey1'
  | 'currKey2'
  | 'operationDate'
  | 'buysell'
  | 'valueDate'
  | 'confirmed'
  | 'bankReference'
  | 'amount1'
  | 'amount2'
  | 'bankCode'
  | 'brokerCode'
  | 'adminType'
  | 'internalRef'
  | 'premiumOnly'
> & {
  covers?: Subset<CoversExchange, 'contractKey', 'amountCovered' | 'quantityCovered' | 'quantityUnitKey'>[];
};
