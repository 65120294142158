import { from, of } from 'rxjs';
import { ShipmentFixationSummaryComponent } from 'src/app/+modules/+hedging/shipment-fixation-summary/shipment-fixation-summary.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { ShipmentFixationSummary } from 'src/lib/newBackendTypes/shipmentFixationSummary';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const shipmentFixationSummaryPrefill: prefillCallback<ShipmentFixationSummaryPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const shipmentFixationSummaryEntries = await api.run<ListResponse<ShipmentFixationSummary>>(endpoints.listShipmentFixationSummary, { filters: { contractId: id } });
      return { shipments: shipmentFixationSummaryEntries.list };
    })()
  );
};

const shipmentFixationSummaryForm: openFormCallback<ShipmentFixationSummaryPrefill, void> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<void, ShipmentFixationSummaryComponent, ShipmentFixationSummaryPrefill>(ShipmentFixationSummaryComponent, {
    title: `Shipment Fixation Summary ${prefill && prefill.shipments.length > 0 ? prefill.shipments[0].contractNumber : 'Unknown'}`,
    prefillValue: prefill,
    width: '90%',
    hideDefaultActions: true,
  });
};

const submitShipmentFixationSummary: submitFormCallback<ShipmentFixationSummaryPrefill, void> = (delegate, id, result, prefill) => {
  return of();
};

export const shipmentFixationSummaryPreset: DynamicFormConstant<ShipmentFixationSummaryPrefill, void> = {
  allowMultipleRows: false,
  openForm: shipmentFixationSummaryForm,
  entityType: SourceEntityType.CONTRACT_KEY,
  getPrefill: shipmentFixationSummaryPrefill,
  label: 'Shipment Fixation Summary',
  submitForm: submitShipmentFixationSummary,
  title: 'Shipment Fixation Summary',
  value: DynamicFormType.SHIPMENT_FIXATION_SUMMARY,
  endpoints: [endpoints.listShipmentFixationSummary],
  width: 400,
};

export type ShipmentFixationSummaryPrefill = {
  shipments: ShipmentFixationSummary[];
};
