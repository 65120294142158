import { EnumLabels, enumValues, Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { City } from './city';
import { Contact } from './contact';
import { Container } from './container';
import { ContainerType } from './containerType';
import { PhysicalContract } from './contract';
import { ContractListView } from './contractListView';
import { Document } from './document';
import { YN } from './enums';
import { OceanRateLookupData, OceanratesSegment } from './oceanRate';
import { PackingType } from './packingType';
import { PropertyDocument } from './propertyDocument';
import { ShipmentPurchaseSaleData } from './shipmentPurchaseSaleData';
import { Task } from './task';
import { Unit } from './unit';
import { Vessel } from './vessel';
import { WarehouseMovement } from './warehouseMovement';

export enum CommonContainerTypes {
  CONTAINER_20FT = 100128,
  CONTAINER_40FT = 100131,
  FIX_AMOUNT = 102723,
  CONTAINER_45FT = 114594,
  OPEN_TOP_20FT = 114596,
  RAIL_CAR = 114597,
  TRUCK_LOAD = 114599,
  CLOSED_48FT = 114601,
  OPEN_TOP_40FT = 114602,
  HIGH_CUBE_40FT = 15844258,
  BULK = 20572872,
  FLATBED = 24885544,
  DUMP_TRUCK = 25561703,
}

export const ContainerTypes = enumOptionsFromEnum(CommonContainerTypes);

export enum BookingStage {
  STS = 1,
  BTS = 2,
  STB = 3,
  CTS = 4,
  MANAGER = 5,
}

export enum DeliveryMovementType {
  DOOR_DELIVERY = 1,
  RAMP_DELIVERY = 2,
  PORT_DELIVERY = 3,
  SUPPLIER_DELIVERY = 4,
}

export const DeliveryMovementTypes = enumOptionsFromEnum(DeliveryMovementType);

export enum WoodPackage {
  APPLICABLE = 'APPLICABLE',
  NOT_APPLICABLE = 'NOT APPLICABLE (NOT USED)',
  TREATED_CERTIFIED = 'TREATED/CERTIFIED',
  USED_PROCESSED_PLYWOOD = 'USED PROCCESSED/PLYWOOD',
  USED_NOT_TREATED = 'USED NOT TREATED',
}

export const WoodPackages: string[] = enumValues(WoodPackage);

export const BookingStages: EnumLabels<BookingStage> = [
  { value: BookingStage.MANAGER, label: 'Manager' },
  { value: BookingStage.STB, label: 'STB' },
  { value: BookingStage.STS, label: 'STS' },
  { value: BookingStage.BTS, label: 'BTS' },
  { value: BookingStage.CTS, label: 'CTS' },
];

export enum STBStatus {
  DESTINATION_REQUIRED = 1,
  REQUESTED = 2,
  CONFIRMED = 3,
  ON_HOLD = 4,
  PENDING_DESTINATION = 5,
  PENDING_SO_NUMBER = 6,
  CANCELLED = 7,
  REVISED_DESTINATION = 8,
  PENDING_EQUIPMENT_SIZES = 9,
  PENDING_EQUIPMENT_QUANTITY = 10,
  RATE_EXPIRED = 11,
  NO_RATES_ON_FILE = 12,
  LOI = 13,
  DESTINATION_ASSIGNED = 14,
  BOOKING_REQ_PENDING = 15,
  ALTERNATIVE_RATES = 16,
  CONTRACT_EXPIRES = 17,
}

export const STBStatuses = enumOptionsFromEnum(STBStatus).sort((a, b) => {
  return a.label > b.label ? 1 : -1;
});

export type BaseBooking = {
  id: number;
  containerTypeId: CommonContainerTypes;
  packageTypeId: PackingType | null;
  characteristicKey1: number | null;
  characteristicKey2: number | null;
  characteristicKey3: number | null;
  unitKey: number;
  currKey: number;
  placeOfReceiptId: number;
  unloadingPortId: number | null;
  loadingPortId: number | null;
  finalDestinationId: number;
  shippingLineId: number;
  freightForwarderId: number | null;
  loader: number;
  transhiper: number;
  unloader: number | null;
  number: number;
  bookingType: BookingType | null;
  voyage: string | null;
  packageCount: number;
  containerCount: number;
  loadingAddress: string | null;
  loadingEtaDate: number | null;
  placeOfReceiptETD: Date | null;
  transhipmentAddress: string | null;
  transhipmentEtaDate: number | null;
  loadingPortETD: Date | null;
  unloadingAddress: string | null;
  unloadingPortETA: Date | null;
  unloadingEtdDate: number | null;
  finalDestinationETA: Date | null;
  price: number | null;
  vesselId: number | null;
  productId: number | null;
  transportNumber: number | null;
  unitByPacking: YN;
  bookingReceived: string | null;
  modifiedBy: string | null;
  modifyDate: Date | null;
  versionNb: number;
  bookingDate: Date | null;
  description: string | null;
  nbPositionned: number;
  nbStuffed: number;
  nbDelivered: number;
  supplierInvoiceNumber: string | null;
  blDate: Date | null;
  commentsText: string | null;
  companyId: number;
  freeStorageDaysDestination: number | null;
  freeStorageDaysOrigin: number | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  warning: string | null;
  toleranceMin: number;
  toleranceMax: number;
  carrierBookingNumber: string | null;
  operatorId: number;
  supplierDocsReceivedDate: Date | null;
  blInstructionsSentDate: Date | null;
  blDraftReceivedDate: Date | null;
  isdFilingDate: Date | null;
  draftToCustomerSentDate: Date | null;
  clientPreadvisorySentDate: Date | null;
  docApprovedByClientDate: Date | null;
  docsToBankSentDate: Date | null;
  sedFiledDate: Date | null;
  docsToCustomerSentDate: Date | null;
  maxPresentationDate: Date | null;
  deliveryAppointmentDate: Date | null;
  appointmentReference: string;
  cargoArrivalDate: Date | null;
  gateOutDate: Date | null;
  originalBlReceivedDate: Date | null;
  financeReleaseDate: Date | null;
  cargoReleasedDate: Date | null;
  docsToBankTracking: string | null;
  docsToCustomerTracking: string | null;
  docsCutoffDate: Date | null;
  docsCutoffTime: string;
  stsFileHandoverDate: Date | null;
  ctsAssignment: Date | null;
  btsAssignment: Date | null;
  cooRequestDate: Date | null;
  cooReceivedDate: Date | null;
  arrivalNoticeReceivedDate: Date | null;
  doSentDate: Date | null;
  weightConfirmReceivedDate: Date | null;
  cargoHoldPlacedDate: Date | null;
  cargoHoldReleaseDate: Date | null;
  customsFilingNumber: string | null;
  aesNumber: string | null;
  deliveryAppointmentTime: string | null;
  customerPurchaseOrder: string | null;
  notifyParty1Id: number | null;
  notifyParty2Id: number | null;
  consigneeId: number | null;
  blNumber: string;
  transhipmentPortId: number | null;
  transhipmentETD: Date | null;
  marks: string;
  blDescription: string;
  stbOperatorId: number | null;
  stsOperatorId: number | null;
  btsOperatorId: number | null;
  ctsOperatorId: number | null;
  stage: BookingStage | null;
  shipper: string;
  notifyParty1Address: string;
  notifyParty2Address: string;
  consigneeAddress: string;
  hsCode: string;
  ncmNumber: string;
  woodPackage: string;
  blRemarks: string;
  documentsUnitsId: number | null;
  matchingDate: Date | null;
  supplierCutoffDate: Date | null;
  rampCutoffDate: Date | null;
  rampCutoffTime: string;
  originalETD: Date | null;
  customsQuotaNumber: string; // new
  fileClosedDate: Date | null; // new
  deliveryMovementType: DeliveryMovementType | null; // new
  truckingCompanyId: number | null; // new
  supplierPurchaseOrder: string; // new - FK -> ALF
  pickUpDate: Date | null;
  pickUpTime: string;
  importsSwbReceivedDate: Date | null;
  customsBrokerReleaseDate: Date | null;
  carrierFreightReleaseDate: Date | null;
  customerDocsUploadedDate: Date | null;
  requestedInvoiceValueDate: Date | null;
  segmentId: number | null;
  containerPositioningDate: Date | null;
  exwAddress: string;
  loadingTargetDate: Date | null;
  stbStatus: STBStatus | null;
  purchaseContractId: number | null;
  warehousePreadvisorySentDate: Date | null;
  daDocsReceivedDate: Date | null;
  daReleaseRequestedDate: Date | null;
  draftDocsToWarehouseDate: Date | null;
  freightCost: number | null;
  cargoCutoffDate: Date | null;
  earliestReturnDate: Date | null;
  equipmentAvailableDate: Date | null;
  equipmentPickupLocation: string;
  equipmentReturnLocation: string;
  vgmCutoffDate: Date | null;
  lastTrackingDate: Date | null;
  bookingConfirmationComments: string;
  deliveryCommitment: DeliveryCommitment;
  castingList: string | null;
};

export type Booking = BaseBooking & {
  truckingCompany?: Contact;
  shipments?: PropertyDocument[]; // BOOKING_PROPERTY_DOC
  containerType?: ContainerType;
  comments?: Comment[];
  linkedDocuments?: Document[];
  containers?: Container[]; // CONTAINER_BOOKING
  operator?: Contact;
  shippingLine?: Contact;
  vessel?: Vessel;
  freightForwarder?: Contact;
  placeOfReceipt?: City;
  loadingPort?: City;
  unloadingPort?: City;
  finalDestination?: City;
  transhipmentPort?: City;
  tasks?: Task[];
  stbOperator?: Contact;
  stsOperator?: Contact;
  btsOperator?: Contact;
  ctsOperator?: Contact;
  consignee?: Contact;
  notifyParty1?: Contact;
  notifyParty2?: Contact;
  documentsUnits?: Unit;
  purchaseContract?: PhysicalContract;

  reservedInvoice?: { number: number; bookingId: number };

  invoiceData?: { shipmentId: number; invoiceKey: number; invoiceNumber: number }[];
  contractData?: ShipmentPurchaseSaleData[];
  warehouseMovements?: WarehouseMovement[];

  segment?: OceanratesSegment;
};

export type FrontendBooking = Booking & {
  /**
   * For dropdown
   */
  purchaseContractView?: ContractListView;
  oceanRate?: OceanRateLookupData;
};

export interface CreateBookingRequest
  extends Subset<
    Booking,
    | 'supplierInvoiceNumber'
    | 'description'
    | 'carrierBookingNumber'
    | 'blNumber'
    | 'bookingType'
    | 'vesselId'
    | 'voyage'
    | 'shippingLineId'
    | 'freightForwarderId'
    | 'placeOfReceiptId'
    | 'loadingPortId'
    | 'unloadingPortId'
    | 'finalDestinationId'
    | 'placeOfReceiptETD'
    | 'loadingPortETD'
    | 'unloadingPortETA'
    | 'finalDestinationETA'
    | 'supplierDocsReceivedDate'
    | 'blDate'
    | 'transhipmentPortId'
    | 'transhipmentETD'
    | 'freeStorageDaysDestination'
    | 'marks'
    | 'blDescription',
    | 'stbOperatorId'
    | 'stsOperatorId'
    | 'btsOperatorId'
    | 'ctsOperatorId'
    | 'stage'
    | 'shipper'
    | 'blInstructionsSentDate'
    | 'blDraftReceivedDate'
    | 'clientPreadvisorySentDate'
    | 'sedFiledDate'
    | 'docsCutoffDate'
    | 'notifyParty1Id'
    | 'notifyParty2Id'
    | 'consigneeId'
    | 'docsCutoffTime'
    | 'aesNumber'
    | 'consigneeAddress'
    | 'notifyParty1Address'
    | 'notifyParty2Address'
    | 'appointmentReference'
    | 'hsCode'
    | 'ncmNumber'
    | 'woodPackage'
    | 'blRemarks'
    | 'documentsUnitsId'
    | 'supplierCutoffDate'
    | 'rampCutoffDate'
    | 'rampCutoffTime'
    | 'originalETD'
    | 'cooRequestDate'
    | 'cooReceivedDate'
    | 'originalBlReceivedDate'
    | 'draftToCustomerSentDate'
    | 'docApprovedByClientDate'
    | 'financeReleaseDate'
    | 'cargoReleasedDate'
    | 'customsFilingNumber'
    | 'customsQuotaNumber'
    | 'docsToBankTracking'
    | 'docsToBankSentDate'
    | 'docsToCustomerTracking'
    | 'docsToCustomerSentDate'
    | 'btsAssignment'
    | 'ctsAssignment'
    | 'fileClosedDate'
    | 'cargoArrivalDate'
    | 'gateOutDate'
    | 'deliveryMovementType'
    | 'truckingCompanyId'
    | 'supplierPurchaseOrder'
    | 'pickUpDate'
    | 'pickUpTime'
    | 'customerPurchaseOrder'
    | 'appointmentReference'
    | 'deliveryAppointmentDate'
    | 'deliveryAppointmentTime'
    | 'isdFilingDate'
    | 'importsSwbReceivedDate'
    | 'arrivalNoticeReceivedDate'
    | 'cargoHoldPlacedDate'
    | 'cargoHoldReleaseDate'
    | 'customsBrokerReleaseDate'
    | 'carrierFreightReleaseDate'
    | 'doSentDate'
    | 'weightConfirmReceivedDate'
    | 'requestedInvoiceValueDate'
    | 'containerPositioningDate'
    | 'exwAddress'
    | 'loadingTargetDate'
    | 'stbStatus'
    | 'segmentId'
    | 'containerPositioningDate'
    | 'exwAddress'
    | 'loadingTargetDate'
    | 'stbStatus'
    | 'purchaseContractId'
    | 'warehousePreadvisorySentDate'
    | 'daDocsReceivedDate'
    | 'daReleaseRequestedDate'
    | 'draftDocsToWarehouseDate'
    | 'freightCost'
    | 'freeStorageDaysOrigin'
    | 'cargoCutoffDate'
    | 'earliestReturnDate'
    | 'equipmentAvailableDate'
    | 'equipmentPickupLocation'
    | 'equipmentReturnLocation'
    | 'vgmCutoffDate'
    | 'bookingConfirmationComments'
    | 'lastTrackingDate'
    | 'deliveryCommitment'
    | 'castingList'
  > {}

export interface UpdateBookingRequest
  extends Subset<
    Booking,
    'id',
    | 'supplierInvoiceNumber'
    | 'description'
    | 'carrierBookingNumber'
    | 'blNumber'
    | 'bookingType'
    | 'vesselId'
    | 'voyage'
    | 'shippingLineId'
    | 'freightForwarderId'
    | 'placeOfReceiptId'
    | 'loadingPortId'
    | 'unloadingPortId'
    | 'finalDestinationId'
    | 'placeOfReceiptETD'
    | 'loadingPortETD'
    | 'unloadingPortETA'
    | 'finalDestinationETA'
    | 'supplierDocsReceivedDate'
    | 'blDate'
    | 'transhipmentPortId'
    | 'transhipmentETD'
    | 'freeStorageDaysDestination'
    | 'marks'
    | 'blDescription'
    | 'stbOperatorId'
    | 'stsOperatorId'
    | 'btsOperatorId'
    | 'ctsOperatorId'
    | 'stage'
    | 'shipper'
    | 'docsCutoffTime'
    | 'aesNumber'
    | 'blInstructionsSentDate'
    | 'blDraftReceivedDate'
    | 'clientPreadvisorySentDate'
    | 'sedFiledDate'
    | 'docsCutoffDate'
    | 'notifyParty1Id'
    | 'notifyParty2Id'
    | 'consigneeId'
    | 'consigneeAddress'
    | 'notifyParty1Address'
    | 'notifyParty2Address'
    | 'appointmentReference'
    | 'hsCode'
    | 'ncmNumber'
    | 'woodPackage'
    | 'blRemarks'
    | 'documentsUnitsId'
    | 'supplierCutoffDate'
    | 'rampCutoffDate'
    | 'rampCutoffTime'
    | 'originalETD'
    | 'cooRequestDate'
    | 'cooReceivedDate'
    | 'originalBlReceivedDate'
    | 'draftToCustomerSentDate'
    | 'docApprovedByClientDate'
    | 'financeReleaseDate'
    | 'cargoReleasedDate'
    | 'customsFilingNumber'
    | 'customsQuotaNumber'
    | 'docsToBankTracking'
    | 'docsToBankSentDate'
    | 'docsToCustomerTracking'
    | 'docsToCustomerSentDate'
    | 'btsAssignment'
    | 'ctsAssignment'
    | 'fileClosedDate'
    | 'cargoArrivalDate'
    | 'gateOutDate'
    | 'deliveryMovementType'
    | 'truckingCompanyId'
    | 'supplierPurchaseOrder'
    | 'pickUpDate'
    | 'pickUpTime'
    | 'customerPurchaseOrder'
    | 'appointmentReference'
    | 'deliveryAppointmentDate'
    | 'deliveryAppointmentTime'
    | 'isdFilingDate'
    | 'importsSwbReceivedDate'
    | 'arrivalNoticeReceivedDate'
    | 'cargoHoldPlacedDate'
    | 'cargoHoldReleaseDate'
    | 'customsBrokerReleaseDate'
    | 'carrierFreightReleaseDate'
    | 'doSentDate'
    | 'weightConfirmReceivedDate'
    | 'requestedInvoiceValueDate'
    | 'containerPositioningDate'
    | 'exwAddress'
    | 'loadingTargetDate'
    | 'stbStatus'
    | 'segmentId'
    | 'containerPositioningDate'
    | 'exwAddress'
    | 'loadingTargetDate'
    | 'stbStatus'
    | 'purchaseContractId'
    | 'warehousePreadvisorySentDate'
    | 'daDocsReceivedDate'
    | 'daReleaseRequestedDate'
    | 'draftDocsToWarehouseDate'
    | 'freightCost'
    | 'freeStorageDaysOrigin'
    | 'cargoCutoffDate'
    | 'earliestReturnDate'
    | 'equipmentAvailableDate'
    | 'equipmentPickupLocation'
    | 'equipmentReturnLocation'
    | 'vgmCutoffDate'
    | 'bookingConfirmationComments'
    | 'lastTrackingDate'
    | 'deliveryCommitment'
    | 'castingList'
  > {}

export enum BookingType {
  MARITIME = 1,
  LAND = 2,
  AIR = 3,
  TRAIN = 4,
  TRUCK = 5,
  BARGE = 6,
  PIPELINE = 7,
  TANK = 8,
  SILO = 9,
}

export const BookingTypes = [
  { value: BookingType.AIR, label: 'Air' },
  { value: BookingType.BARGE, label: 'Barge' },
  { value: BookingType.LAND, label: 'Land' },
  { value: BookingType.MARITIME, label: 'Maritime' },
  { value: BookingType.PIPELINE, label: 'Pipeline' },
  { value: BookingType.SILO, label: 'Silo' },
  { value: BookingType.TANK, label: 'Tank' },
  { value: BookingType.TRAIN, label: 'Train' },
  { value: BookingType.TRUCK, label: 'Truck' },
];

export enum DeliveryCommitment {
  CUSTOMER_FACILITY = 1,
  PORT = 2,
  WAREHOUSE = 3,
}

export const DeliveryCommitments = [
  { value: DeliveryCommitment.CUSTOMER_FACILITY, label: 'Customer Facility' },
  { value: DeliveryCommitment.PORT, label: 'Port' },
  { value: DeliveryCommitment.WAREHOUSE, label: 'Warehouse' },
];
