import { endpoints } from 'src/lib/apiEndpoints';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { updateNetPositionJournalCheck } from './updateNetPositionJournal';
import { from } from 'rxjs';
import { NetPositionHeader, NetPositionLines, SourceEntityType } from 'src/lib/newBackendTypes';
import { Subset } from 'src/lib/generics';
import { NetPositionJournalDatesComponent, NetPositionJournalValueDateForm } from 'src/app/+modules/+risk-management/net-position-journal-dates/net-position-journal-dates.component';

const updateNetPositionJournalValueDatePrefill: prefillCallback<NetPositionHeader> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const netPositionLine = await api.run<NetPositionLines>(endpoints.getNetPositionLine, { filters: { id } });
      const netPositionHeader = await api.run<NetPositionHeader>(endpoints.getNetPositionOperation, { filters: { id: netPositionLine.headerId } });
      return { id: netPositionHeader.id, valueDate: netPositionHeader.valueDate, invalidDate: netPositionLine.invalidDate, company: netPositionHeader.company };
    })()
  );
};

const updateNetPositionJournalValueDateForm: openFormCallback<NetPositionJournalDatesPrefill, NetPositionJournalValueDateForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<NetPositionJournalValueDateForm, NetPositionJournalDatesComponent, NetPositionJournalDatesPrefill>(NetPositionJournalDatesComponent, {
    title: `Update Net Position Journal Dates`,
    prefillValue: prefill,
    maxWidth: '90%',
    width: 500,
  });
};

const submitUpdateNetPositionJournalDates: submitFormCallback<NetPositionJournalDatesPrefill> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<NetPositionHeader>((resolve, reject) => {
      (async () => {
        const updateNetPositionJournalValueDateRequest: NetPositionJournalDatesRequest = {
          id: prefill.id,
          lineId: id,
          valueDate: result.valueDate,
          invalidDate: result.invalidDate,
        };
        const netPositionHeader = await api.run<NetPositionHeader>(endpoints.updateNetPositionJournalDates, updateNetPositionJournalValueDateRequest, null);
        if (netPositionHeader) return resolve(netPositionHeader);
        return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Net Position Journal ${prefill.id} successfuly updated.</div>`);
      })
      .catch((err) => {
        if (err) return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if journal was updated and try again if necessary.</div>`);
      })
  );
};

export const updateNetPositionJournalDatesPreset: DynamicFormConstant<NetPositionJournalDatesPrefill, NetPositionJournalValueDateForm> = {
  allowMultipleRows: false,
  checkPrefill: updateNetPositionJournalCheck,
  getPrefill: updateNetPositionJournalValueDatePrefill,
  openForm: updateNetPositionJournalValueDateForm,
  entityType: SourceEntityType.NET_POSITION_ENTRIES_KEY,
  label: 'Update Net Position Journal Dates',
  submitForm: submitUpdateNetPositionJournalDates,
  title: 'Update Net Position Journal Dates',
  value: DynamicFormType.UPDATE_NET_POSITION_JOURNAL_DATES,
  endpoints: [endpoints.getNetPositionLine, endpoints.getNetPositionOperation, endpoints.updateNetPositionJournalDates],
  width: 405,
};

export type NetPositionJournalDatesPrefill = Subset<NetPositionHeader, 'id' | 'valueDate', 'company'> & Pick<NetPositionLines, 'invalidDate'>;
export type NetPositionJournalDatesRequest = Omit<NetPositionJournalDatesPrefill, 'company'> & { lineId: number };
