import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { ListColumnForm } from 'src/app/+modules/+it/containers/flex/flex-column/flex-column.component';
import { ListFilterForm } from 'src/app/+modules/+it/containers/flex/flex-filter-group/flex-filter-group.component';
import { ListFilterRowForm, ListFilterSubRowForm } from 'src/app/+modules/+it/containers/flex/flex-filter-row/flex-filter-row.component';
import { FlexFilterPopupComponent } from 'src/app/shared/flex-filter-popup/flex-filter-popup.component';
import { AndFilter, ListViewFilter } from 'src/lib/flex/flexFilterQueries';
import { getTodayUTC, toBradyUTCDate } from 'src/lib/helperFunctions';
import { Contact, YN } from 'src/lib/newBackendTypes';
import {
  CreateListColumnRequest,
  ListColumn,
  ListColumnMenuConfig,
  ListColumnResponse,
  ListColumnType,
  ListColumnTypeConfig,
  isAmountColumnConfig,
  isDateColumnConfig,
  isDebitCreditColumnConfig,
  isEnumColumnConfig,
  isIDColumnConfig,
  isNumberColumnConfig,
  isTextColumnConfig,
  isAmountUnitColumnConfig,
  isWeightColumnConfig,
} from 'src/lib/views/listColumn';
import {
  CreateListFilterGroupRequest,
  CreateListFilterRowRequest,
  ListComparativeOperator,
  ListFilterAbstractHardcodedRow,
  ListFilterAbstractPromptRow,
  ListFilterAbstractRow,
  ListFilterConfiguration,
  ListFilterGroup,
  ListFilterGroupResponse,
  ListFilterRow,
  ListFilterRowResponse,
  ListFilterSubRow,
  ListFilterType,
  ListFilterValueType,
  PlaceholderType,
  UpdateListFilterGroupRequest,
  UpdateListFilterRowRequest,
  isDynamicListFilterConfiguration,
  isHardcodedFilterConfiguration,
  isLogicSubRowFilterConfiguration,
  isPlaceholderFilterConfiguration,
  isPromptFilterConfiguration,
} from 'src/lib/views/listFilters';
import { FilterStrategy, ListGridConfig, ListView, ListViewResponse } from 'src/lib/views/listView';
import * as uuid from 'uuid';
import { PromptService } from './prompt.service';
import { SelectorPopupService } from './selector-popup.service';
import { Store } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class FlexService {
  currentUser: Contact;

  $emitter = new EventEmitter();

  constructor(private promptService: PromptService, private selectorService: SelectorPopupService, private store: Store) {
    this.currentUser = this.store.snapshot((state) => state.user.user);
  }

  emitDataReloadEvent() {
    this.$emitter.emit();
  }

  /**
   * Retrieves the selected filter group for the Flex View, optionally prompting the user to choose one.
   *
   * @param flexView The ListView containing filter groups and configuration.
   * @returns A Promise that resolves to an object containing the selected filter group and a flag indicating if the filtering was done by the user.
   */
  async getFlexFilter(flexView: ListView): Promise<{ selectedFilterGroup: ListFilterGroup | null | undefined; isUserFiltering: boolean }> {
    // If there are no filter groups or filter strategy is set to NO_FILTER, no filter is selected
    if (!flexView.filterGroups || flexView.filterGroups.length === 0 || flexView.filterStrategy === FilterStrategy.NO_FILTER) return { selectedFilterGroup: undefined, isUserFiltering: false };

    let selectedFilterGroup: ListFilterGroup | null = null;

    // Find the default filter group, or use the first one if no default is specified
    const defaultFilterGroup = flexView.filterGroups.find((fg) => fg.isDefault === YN.Y) || flexView.filterGroups[0];
    let isUserFiltering: boolean = false;
    // Determine the selected filter group based on the filter strategy
    if (flexView.filterStrategy === FilterStrategy.PROMPT_USER) {
      // Prompt the user to select a filter group
      isUserFiltering = true;
      const selected = await lastValueFrom(this.promptService.simpleDropdownPrompt('Select Filter', 'Filter Group', flexView.filterGroups, 'name', 'id', defaultFilterGroup));
      selectedFilterGroup = selected !== 'Close' ? selected : null;
    } else if (flexView.filterStrategy === FilterStrategy.USE_DEFAULT) selectedFilterGroup = defaultFilterGroup; // Use the default filter group

    // Return null if no filter group is selected, undefined if the selected group has no rows
    if (!selectedFilterGroup) return { selectedFilterGroup: null, isUserFiltering };
    if (!selectedFilterGroup.rows || selectedFilterGroup.rows.length === 0) return { selectedFilterGroup: undefined, isUserFiltering };

    // Return the selected filter group and the flag indicating if the filtering was done by the user
    return { selectedFilterGroup, isUserFiltering };
  }

  /**
   * Retrieves the filter query for the Flex View based on the selected filter group and prompts the user to set filters if necessary.
   *
   * @param flexView The ListView containing filter groups and configuration.
   * @returns A Promise that resolves to an object containing the selected filter group, filter query, filtered values,
   *          and a flag indicating if the filtering was done by the user.
   */
  async getFlexFilterQuery<T>(
    flexView: ListView
  ): Promise<{ selectedFilterGroup: ListFilterGroup | null | undefined; isUserFiltering: boolean; filters: ListViewFilter<T> | null | undefined; filteredValues: string }> {
    const filterGroup = await this.getFlexFilter(flexView);
    let filteredValues: string = '';
    // Handle cases where no filter group is selected
    if (filterGroup.selectedFilterGroup === undefined) return { ...filterGroup, filters: undefined, filteredValues };
    if (filterGroup.selectedFilterGroup === null) return { ...filterGroup, filters: null, filteredValues };
    // Set filtered values to the name of the selected filter group
    filteredValues = filterGroup.selectedFilterGroup.name;
    const topLevelFilter: AndFilter<T> = { and: [] };

    var filterTransformer: (row: ListFilterRow | ListFilterSubRow) => ListFilterAbstractRow;
    filterTransformer = (row: ListFilterRow | ListFilterSubRow) => {
      if (isLogicSubRowFilterConfiguration(row.typeConfiguration)) {
        return {
          ...row,
          uuid: uuid.v4(),
          typeConfiguration: {
            rows: row.typeConfiguration.rows.map(filterTransformer),
          },
        } as ListFilterAbstractRow;
      } else {
        return {
          ...row,
          uuid: uuid.v4(),
        } as ListFilterAbstractRow;
      }
    };
    // Transform filter rows to abstract representation for processin
    const filterRows = filterGroup.selectedFilterGroup.rows.map(filterTransformer);
    const promptValues: ListFilterValue[] = [];

    let mapper: (r: ListFilterAbstractRow) => ListFilterAbstractPromptRow | ListFilterAbstractPromptRow[];
    mapper = (r) => {
      if (r === undefined) return [];
      if (r.type === ListFilterType.PROMPT_USER || r.type === ListFilterType.DYNAMIC_LIST) return r as ListFilterAbstractPromptRow;
      if (isLogicSubRowFilterConfiguration(r.typeConfiguration)) {
        if (r.type === ListFilterType.NOT) {
          return r.typeConfiguration.rows.slice(0, 1).flatMap(mapper);
        } else {
          return r.typeConfiguration.rows.flatMap(mapper);
        }
      }
      return [];
    };
    // Extract prompt rows for user interaction
    const promptRows: ListFilterAbstractPromptRow[] = filterRows.flatMap(mapper);
    // Open a form to prompt the user to set filters
    if (promptRows.length > 0) {
      const res = await lastValueFrom(
        this.selectorService.openForm<{ obj: any; filters: string }, FlexFilterPopupComponent>(FlexFilterPopupComponent, {
          title: 'Flex Filters',
          initializer: (c) => {
            c.flexPrompts = promptRows;
            c.columns = flexView.columns;
          },
        })
      );
      if (res === 'Close') {
        return { ...filterGroup, filters: null, filteredValues };
      } else {
        filteredValues += res.filters.length > 0 ? `: ${res.filters}` : '';
        for (const k in res.obj) {
          const row: ListFilterAbstractPromptRow = promptRows.find((r) => r.uuid === k);
          const column = flexView.columns.find((c) => c.id === row.fieldId);
          if (row && column) {
            let values = res.obj[k];
            if (!Array.isArray(values)) values = [values];

            promptValues.push({
              values,
              column,
              type: row.type,
              operator: row.typeConfiguration.operator,
              uuid: row.uuid,
            });
          }
        }
      }
    }

    let hardcodedMapper: (r: ListFilterAbstractRow) => ListFilterAbstractRow | ListFilterAbstractRow[];
    hardcodedMapper = (r) => {
      if (r === undefined) return [];
      if (r.type === ListFilterType.HARDCODED_VALUE || r.type === ListFilterType.PLACEHOLDER) return r as ListFilterAbstractRow;
      if (isLogicSubRowFilterConfiguration(r.typeConfiguration)) {
        if (r.type === ListFilterType.NOT) {
          return r.typeConfiguration.rows.slice(0, 1).flatMap(hardcodedMapper);
        } else {
          return r.typeConfiguration.rows.flatMap(hardcodedMapper);
        }
      }
      return [];
    };
    // Extract and process hardcoded filter rows
    const hardcodedRows = filterRows.flatMap(hardcodedMapper);

    const hardcodedValues = hardcodedRows.flatMap(filterValueFromHardcodedFilter(flexView, this.currentUser));
    // Combine hardcoded and prompt values
    const filterValues = [...hardcodedValues, ...promptValues];
    let filterRowClauseBuilder: (row: ListFilterAbstractRow) => ListViewFilter<T> | ListViewFilter<T>[];
    filterRowClauseBuilder = (row: ListFilterAbstractRow) => {
      if (isLogicSubRowFilterConfiguration(row.typeConfiguration)) {
        const subClause = row.typeConfiguration.rows.flatMap(filterRowClauseBuilder);
        if (subClause.length === 0) return [];
        if (row.type === ListFilterType.AND) {
          return {
            and: subClause,
          };
        } else if (row.type === ListFilterType.OR) {
          return {
            or: subClause,
          };
        } else if (row.type === ListFilterType.NOT) {
          return {
            not: subClause[0],
          };
        }
        return [];
      } else {
        const matchingValue = filterValues.find((v) => v.uuid === row.uuid);
        if (!matchingValue) return [];
        return this.getFilterClauseFromFilter(matchingValue.column, row, matchingValue.values);
      }
    };
    // Build the filter clauses based on the filter values
    topLevelFilter.and = filterRows.flatMap(filterRowClauseBuilder);
    // Return the filter query along with other relevant information
    return topLevelFilter.and.length > 0 ? { ...filterGroup, filters: topLevelFilter, filteredValues } : { ...filterGroup, filters: undefined, filteredValues };
  }

  getFilterClauseFromFilter<T>(column: ListColumn, row: ListFilterRow | ListFilterSubRow, values: any[]): ListViewFilter<T> {
    if (isLogicSubRowFilterConfiguration(row.typeConfiguration)) {
      //this should never happen
      return;
    }

    if (values.length >= 1 && (row.typeConfiguration.operator === ListComparativeOperator.IN || row.typeConfiguration.operator === ListComparativeOperator.NOT_IN)) {
      if (row.typeConfiguration.operator === ListComparativeOperator.IN) return { _: column.field as keyof T, in: values };
      else if (row.typeConfiguration.operator === ListComparativeOperator.NOT_IN) return { not: { _: column.field as keyof T, in: values } };
    } else if (values.length === 1) {
      let value = values[0];

      if (moment.isDate(value)) {
        let valueType = 'valueType' in row.typeConfiguration ? row.typeConfiguration.valueType : null;
        if (valueType === ListFilterValueType.DATE && column.type === ListColumnType.DATE && column.typeConfiguration.numeric === YN.Y) {
          valueType = ListFilterValueType.NUMERIC_DATE;
        }
        if (valueType === ListFilterValueType.DATE) {
          value = moment(value).format('YYYY-MM-DD');
        } else if (valueType === ListFilterValueType.NUMERIC_DATE) {
          try {
            value = toBradyUTCDate(value);
          } catch (e) {
            console.warn(`Filter Clause failed to due to bad date: ${value}, column: ${column.name}, filter: ${row.label}`);
          }
        } else return null;
      }

      switch (row.typeConfiguration.operator) {
        case ListComparativeOperator.EQUAL_TO: {
          return { _: column.field as keyof T, equals: value };
        }
        case ListComparativeOperator.LIKE: {
          return { _: column.field as keyof T, like: `%${value}%` };
        }
        case ListComparativeOperator.NOT_EQUAL_TO: {
          return { not: { _: column.field as keyof T, equals: `${value}` } };
        }
        case ListComparativeOperator.NOT_LIKE: {
          return { not: { _: column.field as keyof T, like: `%${value}%` } };
        }
        case ListComparativeOperator.STARTS_WITH: {
          return { _: column.field as keyof T, like: `${value}%` };
        }
        case ListComparativeOperator.ENDS_WITH: {
          return { _: column.field as keyof T, like: `%${value}` };
        }
        case ListComparativeOperator.GREATER_THAN: {
          return { _: column.field as keyof T, greaterThan: value };
        }
        case ListComparativeOperator.LESS_THAN: {
          return { _: column.field as keyof T, lessThan: value };
        }
        case ListComparativeOperator.GREATER_THAN_OR_EQUAL_TO: {
          return { _: column.field as keyof T, greaterThanEqual: value };
        }
        case ListComparativeOperator.LESS_THAN_OR_EQUAL_TO: {
          return { _: column.field as keyof T, lessThanEqual: value };
        }
        case ListComparativeOperator.IS_NULL: {
          return { _: column.field as keyof T, isNull: true };
        }
        case ListComparativeOperator.NOT_NULL: {
          return { not: { _: column.field as keyof T, isNull: true } };
        }
        default: {
          return null;
        }
      }
    }
  }

  loadFlexView(flexView: ListViewResponse): ListView {
    let rawGridConfig = flexView.gridConfiguration;

    let gridConfiguration: ListGridConfig;

    try {
      gridConfiguration = JSON.parse(rawGridConfig);
    } catch (e) {
      gridConfiguration = { toolbars: [] };
    }

    let columns: ListColumn[] = flexView.columns.map(this.flexColumnMapper());

    columns = columns.filter((c) => !!c).sort((a, b) => a.defaultOrder - b.defaultOrder);

    let filterGroups: ListFilterGroup[] = flexView.filterGroups.map(this.flexFilterMapper(columns));

    filterGroups = filterGroups.filter((f) => !!f);

    let defaultLayout = (flexView.layouts || []).find((l) => l.default === YN.Y);

    return { ...flexView, gridConfiguration, columns, filterGroups, defaultLayout };
  }

  flexColumnMapper() {
    return (column: ListColumnResponse): ListColumn | null => {
      let rawTypeConfig = column.typeConfiguration;
      let rawMenuConfig = column.menuConfiguration;

      let typeConfiguration: ListColumnTypeConfig;
      let menuConfiguration: ListColumnMenuConfig;

      try {
        typeConfiguration = JSON.parse(rawTypeConfig);
      } catch (e) {
        return null;
      }
      try {
        menuConfiguration = JSON.parse(rawMenuConfig);
      } catch (e) {
        return null;
      }

      switch (column.type) {
        case ListColumnType.TEXT:
          if (isTextColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.TEXT, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.NUMBER:
          if (isNumberColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.NUMBER, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.DATE:
          if (isDateColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.DATE, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.ENUM:
          if (isEnumColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.ENUM, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.WEIGHT:
          if (isWeightColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.WEIGHT, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.AMOUNT:
          if (isAmountColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.AMOUNT, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.AMOUNT_UNIT:
          if (isAmountUnitColumnConfig(typeConfiguration)) {
            return {
              ...column,
              type: ListColumnType.AMOUNT_UNIT,
              typeConfiguration,
              menuConfiguration,
            };
          }
          return null;
        case ListColumnType.ID:
          if (isIDColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.ID, typeConfiguration, menuConfiguration };
          }
          return null;
        case ListColumnType.DEBIT_CREDIT:
          if (isDebitCreditColumnConfig(typeConfiguration)) {
            return {
              ...column,
              type: ListColumnType.DEBIT_CREDIT,
              typeConfiguration,
              menuConfiguration,
            };
          }
          return null;
        case ListColumnType.COMPANY:
          if (isIDColumnConfig(typeConfiguration)) {
            return { ...column, type: ListColumnType.COMPANY, typeConfiguration, menuConfiguration };
          }
          return null;
        default:
          return null;
      }
    };
  }

  flexFilterMapper(columns: ListColumn[]) {
    return (filterGroup: ListFilterGroupResponse): ListFilterGroup => {
      let rows = (filterGroup.rows || []).map(this.flexFilterRowMapper(columns)).filter((r) => !!r);
      return { ...filterGroup, rows };
    };
  }

  flexFilterRowMapper(columns: ListColumn[]) {
    return (filterRow: ListFilterRowResponse): ListFilterRow => {
      let rawTypeConfiguration = filterRow.typeConfiguration;
      let typeConfiguration: ListFilterConfiguration;

      try {
        typeConfiguration = JSON.parse(rawTypeConfiguration);
      } catch (e) {
        console.warn(e);
        return null;
      }

      return this.subRowMapper(columns)({ ...filterRow, typeConfiguration }) as ListFilterRow;
    };
  }

  subRowMapper(columns: ListColumn[]) {
    return (filterRow: ListFilterRow | ListFilterSubRow): ListFilterSubRow => {
      let typeConfiguration = filterRow.typeConfiguration;
      let field = columns.find((c) => c.id === filterRow.fieldId);

      switch (filterRow.type) {
        case ListFilterType.HARDCODED_VALUE:
          if (isHardcodedFilterConfiguration(typeConfiguration)) {
            return { ...filterRow, type: ListFilterType.HARDCODED_VALUE, typeConfiguration, field };
          }
          return null;
        case ListFilterType.PLACEHOLDER:
          if (isPlaceholderFilterConfiguration(typeConfiguration)) {
            return { ...filterRow, type: ListFilterType.PLACEHOLDER, typeConfiguration, field };
          }
          return null;
        case ListFilterType.PROMPT_USER:
          if (isPromptFilterConfiguration(typeConfiguration)) {
            return { ...filterRow, type: ListFilterType.PROMPT_USER, typeConfiguration, field };
          }
          return null;
        case ListFilterType.DYNAMIC_LIST:
          if (isDynamicListFilterConfiguration(typeConfiguration)) {
            return { ...filterRow, type: ListFilterType.DYNAMIC_LIST, typeConfiguration, field };
          }
          return null;
        case ListFilterType.AND:
        case ListFilterType.OR:
        case ListFilterType.NOT:
          if (isLogicSubRowFilterConfiguration(typeConfiguration)) {
            return {
              ...filterRow,
              type: filterRow.type,
              typeConfiguration: { rows: typeConfiguration.rows.map(this.subRowMapper(columns)) },
              field,
            };
          }
        default:
          return null;
      }
    };
  }

  upsertFlexColumnRequest(col: ListColumnForm): Omit<CreateListColumnRequest, 'flexViewId' | 'order'> {
    let name = col.name;
    let id = col.id;
    let type = col.type;
    let visibility = col.visibility;
    let field = col.field;
    let allowGrouping = col.allowGrouping;
    let defaultWidth = col.defaultWidth;
    let defaultOrder = col.defaultOrder;
    if (!col.typeConfiguration) col.typeConfiguration = {};
    col.typeConfiguration.allowAggregation = col.allowAggregation;

    let typeConfiguration: string;

    try {
      typeConfiguration = JSON.stringify(col.typeConfiguration);
    } catch (e) {}

    let menuConfiguration: string;

    try {
      menuConfiguration = JSON.stringify({ menus: col.menus });
    } catch (e) {}

    return {
      name,
      id,
      type,
      visibility,
      field,
      allowGrouping,
      defaultWidth,
      menuConfiguration,
      typeConfiguration,
      defaultOrder,
      entityType: null,
    };
  }

  createFlexFilterGroupRequest(group: ListFilterForm): Omit<CreateListFilterGroupRequest, 'flexViewId'> {
    const isDefault = group.isDefault;
    const name = group.name;
    const defaultOrder = group.defaultOrder;

    return { isDefault, name, defaultOrder };
  }

  updateFlexFilterGroupRequest(group: ListFilterForm): UpdateListFilterGroupRequest {
    const isDefault = group.isDefault;
    const name = group.name;
    const id = group.id;
    const defaultOrder = group.defaultOrder;

    return { isDefault, name, id, defaultOrder };
  }

  createListFilterRowRequest(row: ListFilterRowForm): Omit<CreateListFilterRowRequest, 'filterGroupId'> {
    const base = this.subRowRequest(row);
    const typeConfigurationObj = base.typeConfiguration;

    let typeConfiguration: string;

    try {
      typeConfiguration = JSON.stringify(typeConfigurationObj);
    } catch (e) {
      typeConfiguration = '{ }';
    }

    return { ...base, typeConfiguration };
  }

  subRowRequest(row: ListFilterSubRowForm): Omit<CreateListFilterRowRequest, 'typeConfiguration' | 'filterGroupId'> & {
    typeConfiguration: ListFilterConfiguration;
  } {
    const fieldId = row.field ? row.field.id : null;
    const label = row.label;
    const mandatory = row.mandatory;
    const type = row.type;
    const operator = row.operator;
    const endpoint = row.endpoint;
    const labelKey = row.labelKey;
    const placeholder = row.placeholder;
    const placeholderArray = row.placeholderArray;
    const value = row.value;
    const valueArray = row.valueArray;
    const valueKey = row.valueKey;
    const valueType = row.valueType;
    const rows = row.rows;
    const defaultOrder = row.defaultOrder;

    let typeConfiguration: ListFilterConfiguration;

    switch (row.type) {
      case ListFilterType.HARDCODED_VALUE: {
        typeConfiguration = {
          value,
          valueType,
          valueArray,
          operator,
        };
        break;
      }
      case ListFilterType.PLACEHOLDER: {
        typeConfiguration = {
          placeholder,
          placeholderArray,
          operator,
          valueType,
        };
        break;
      }
      case ListFilterType.PROMPT_USER: {
        typeConfiguration = {
          valueType,
          operator,
          mandatory,
        };
        break;
      }
      case ListFilterType.DYNAMIC_LIST: {
        typeConfiguration = {
          endpoint,
          valueKey,
          labelKey,
          operator,
          mandatory,
        };
        break;
      }
      case ListFilterType.AND:
      case ListFilterType.OR:
      case ListFilterType.NOT: {
        typeConfiguration = {
          rows: rows.map((s) => this.subRowRequest(s)),
        };
        break;
      }
      default: {
        typeConfiguration = null;
      }
    }

    return { fieldId, label, type, typeConfiguration, defaultOrder };
  }

  updateFlexFilterRowRequest(row: ListFilterRowForm): Omit<UpdateListFilterRowRequest, 'filterGroupId'> {
    return { ...this.createListFilterRowRequest(row), id: row.id };
  }
}

type ListFilterValue = {
  values: any[];
  column: ListColumn;
  type: ListFilterType;
  operator: ListComparativeOperator;
  uuid: string;
};

function filterValueFromHardcodedFilter(flexView: ListView, currentUser: Contact | null) {
  return (row: ListFilterAbstractHardcodedRow): ListFilterValue | ListFilterValue[] => {
    if (isLogicSubRowFilterConfiguration(row.typeConfiguration)) {
      return row.typeConfiguration.rows.flatMap(filterValueFromHardcodedFilter(flexView, currentUser));
    } else {
      let column = flexView.columns.find((c) => c.id === row.fieldId);
      if (!column) return [];
      let values: any[] = [];

      const valueType = row.typeConfiguration.valueType;

      if (row.type === ListFilterType.PLACEHOLDER && isPlaceholderFilterConfiguration(row.typeConfiguration)) {
        let placeholders: PlaceholderType[];
        if (row.typeConfiguration.operator === ListComparativeOperator.IN || row.typeConfiguration.operator === ListComparativeOperator.NOT_IN) {
          placeholders = [...row.typeConfiguration.placeholderArray];
        } else {
          placeholders = [row.typeConfiguration.placeholder];
        }
        for (let p of placeholders) {
          switch (p) {
            case PlaceholderType.TODAY: {
              if (valueType === ListFilterValueType.NUMBER) {
                values.push(new Date().getDate());
                break;
              }
              let d = getTodayUTC();
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_NEXT_MONTH: {
              if (valueType === ListFilterValueType.NUMBER) {
                let month = new Date().getMonth() + 2;
                if (month > 12) month -= 12;
                values.push(month);
                break;
              }
              let d = getTodayUTC();
              d.setUTCMonth(d.getUTCMonth() + 1, 1);
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_THIS_MONTH: {
              if (valueType === ListFilterValueType.NUMBER) {
                let month = new Date().getMonth() + 1;
                if (month > 12) month -= 12;
                values.push(month);
                break;
              }
              let d = getTodayUTC();
              d.setUTCDate(1);
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_LAST_MONTH: {
              if (valueType === ListFilterValueType.NUMBER) {
                let month = new Date().getMonth();
                if (month < 1) month += 12;
                if (month > 12) month -= 12;
                values.push(month);
                break;
              }
              let d = getTodayUTC();
              d.setUTCDate(1);
              d.setUTCMonth(d.getUTCMonth() - 1);
              values.push(d);
              break;
            }
            case PlaceholderType.CURRENT_USER: {
              if (currentUser) {
                values.push(currentUser.id);
              }
              break;
            }
            case PlaceholderType.END_OF_THIS_MONTH: {
              if (valueType === ListFilterValueType.NUMBER) {
                let month = new Date().getMonth() + 1;
                if (month > 12) month -= 12;
                values.push(month);
                break;
              }
              let d = getTodayUTC();
              d.setUTCMonth(d.getUTCMonth() + 1, 0);
              values.push(d);
              break;
            }
            case PlaceholderType.YESTERDAY: {
              if (valueType === ListFilterValueType.NUMBER) {
                let d = new Date();
                d.setDate(d.getDate() - 1);
                values.push(d.getDate());
                break;
              }
              let d = getTodayUTC();
              d.setUTCDate(d.getUTCDate() - 1);
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_THIS_YEAR: {
              if (valueType === ListFilterValueType.NUMBER) {
                let year = new Date().getFullYear();
                values.push(year);
                break;
              }
              let d = getTodayUTC();
              d.setUTCMonth(0, 1);
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_LAST_YEAR: {
              if (valueType === ListFilterValueType.NUMBER) {
                let year = new Date().getFullYear() - 1;
                values.push(year);
                break;
              }
              let d = getTodayUTC();
              d.setUTCFullYear(d.getUTCFullYear() - 1, 0, 1);
              values.push(d);
              break;
            }
            case PlaceholderType.BEGINNING_OF_NEXT_YEAR: {
              if (valueType === ListFilterValueType.NUMBER) {
                let year = new Date().getFullYear() + 1;
                values.push(year);
                break;
              }
              let d = getTodayUTC();
              d.setUTCFullYear(d.getUTCFullYear() + 1, 0, 1);
              values.push(d);
              break;
            }
            case PlaceholderType.THIS_WEEK: {
              let week = moment(new Date()).week();
              values.push(week);
              break;
            }
            case PlaceholderType.LAST_WEEK: {
              let today = new Date();
              today.setDate(today.getDate() - 7);
              let week = moment(today).week();
              values.push(week);
              break;
            }
            case PlaceholderType.YEAR_AGO_TODAY: {
              if (valueType === ListFilterValueType.NUMBER) {
                let year = new Date().getFullYear() - 1;
                values.push(year);
                break;
              }
              let d = getTodayUTC();
              d.setUTCFullYear(d.getUTCFullYear() - 1);
              values.push(d);
              break;
            }
            default: {
              continue;
            }
          }
        }
      } else if (row.type === ListFilterType.HARDCODED_VALUE && isHardcodedFilterConfiguration(row.typeConfiguration)) {
        let target: (string | number | Date)[];
        if (row.typeConfiguration.operator === ListComparativeOperator.IN || row.typeConfiguration.operator === ListComparativeOperator.NOT_IN) {
          target = row.typeConfiguration.valueArray;
        } else {
          target = [row.typeConfiguration.value];
        }
        if (!target) return [];

        for (let v of target) {
          let d: Date | number | string;

          if (valueType === ListFilterValueType.DATE) {
            try {
              d = new Date(v);
            } catch (e) {
              console.warn(e);
            }
          } else d = v;

          values.push(d);
        }
      }
      return {
        values,
        column,
        type: row.type,
        operator: row.typeConfiguration.operator,
        uuid: row.uuid,
      };
    }
  };
}
