import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { basicCityGraphqlRequest } from 'src/lib/graphql/entities/city.graphql';
import { ExistingGraphql, GraphqlNames } from 'src/lib/graphql/graphQlEnums';
import { basicGraphqlCityDropdown, getGraphqlRequestQuery } from 'src/lib/graphql/graphQlFunctions';
import { City, OceanratesTemplateCities } from 'src/lib/newBackendTypes';

export interface ImportExcelComponent<T> {
  importExcel: (event: any) => void;
  convertData: (event: DataTransfer) => void;
  populateExcelData(data: T[]): Observable<T[]>;
}

/**
 *  Form to select form a list a value and/or use it in all the following data
 *
 * @param delegate The delegate service to can access to services.
 * @param data An array of data obtained with enpoint
 * @param valueField Key for get the selected input value
 * @param labelField Key for show in the input field
 * @param label title of input field
 * @param check Key used for identify checkbox  value
 * @param checkHidden boolean used for hide checkbox field
 * @param secondaryTextField used for show secondary text in dropdown option
 * @returns valueField: T and check:boolean.
 */
export function selectItemFormToPopulate<T>(
  delegate: DelegateService,
  data: T[],
  title: string,
  valueField: keyof T,
  labelField: keyof T,
  label: string,
  check: keyof T,
  checkHidden?: boolean,
  secondaryTextField?: string
) {
  const selector = delegate.getService('selector');
  return selector.dynamicForm<T>(
    `${title}`,
    null,
    750,
    [
      {
        type: 'StaticDropdown',
        field: valueField,
        label: label,
        data: data,
        validator: [Validators.required],
        labelField: labelField,
        valueField: valueField,
        secondaryTextField,
      },
    ],
    [
      {
        type: 'Checkbox',
        field: check,
        label: 'Use for the following entries',
        valueMask: { true: true, false: false },
        hidden: checkHidden ?? false,
      },
    ]
  );
}

/**
 *  Form to select cities or skip cities and map this data
 *
 * @param delegate The delegate service to can access to services.
 * @param title Title for the modal
 * @param label title of input field
 * @returns cities: City[] and skipEntry: boolean .
 */
export function selectDynamicItemsFormToPopulate(delegate: DelegateService, title: string, label: string) {
  const selector = delegate.getService('selector');
  return selector.dynamicForm<CityMapperForm>(
    `${title}`,
    null,
    800,
    [
      {
        type: 'DynamicGraphqlMultiselect',
        field: 'cities',
        label: label,
        config: basicGraphqlCityDropdown(),
      },
    ],
    [
      {
        type: 'Checkbox',
        field: 'skipEntry',
        label: 'Skip this city',
        valueMask: { true: true, false: false },
      },
    ]
  );
}

export enum ExcelHeaders {
  ENTRYTEXT1 = 'Entry Text 1',
  ENTRYTEXT2 = 'Entry Text 2',
  EXTERNALREF = 'External Ref',
  ACCOUNT = 'Account',
  COUNTERPARTY = 'Counterparty',
  ANALYTICALGROUP = 'Analytical Group',
  AMOUNT = 'Amount',
  BASEAMOUNT = 'Base Amount',
  QUANTITY = 'Quantity',
  VOUCHER = 'Voucher Number',
  MATCHPAYMENTENTRY = 'Match Payment Entry',
  DESCRIPTION = 'Description',
  UNIT = 'Unit',
  PRICE_UNIT = 'Price Unit',
  PRICE = 'Price',
  SHIPMENT_ID = 'Shipment ID',
  SHIPMENT = 'Shipment Number',
  BUDGETELEMENT = 'Budget Element',
  CONTRACT = 'Contract',
  NOTES = 'Notes',
  ENTRYTYPE = 'Entry Type',
  ITEMREFERENCE = 'Item Reference',
  AMOUNTTOPAY = 'Amount To Pay',
  ORIGIN = 'Origin',
  POL = 'POL',
  POD = 'POD',
  DESTINATION = 'Destination',
  FREIGHT = 'Freight',
  SIZE = 'Size',
  SURCHARGES = 'Surcharges',
  COST = 'Cost',
  TRANSIT_TIME = 'Transit Time',
  CONTAINER_20FT = "20'",
  CONTAINER_40FT = "40'",
  CONTAINER_40FTHC = "40'HC",
  CONTAINER = 'Container',
  CURRENCY = 'Currency',
  FISCAL_COMPANY = 'Fiscal Company',
  PRODUCT = 'Product',
}

export const excelHeaderMap: { [key in ExcelHeaders]: string } = {
  [ExcelHeaders.ENTRYTEXT1]: 'entryText1',
  [ExcelHeaders.ENTRYTEXT2]: 'entryText2',
  [ExcelHeaders.EXTERNALREF]: 'externalRef',
  [ExcelHeaders.ACCOUNT]: 'accountCode',
  [ExcelHeaders.COUNTERPARTY]: 'counterpartyName',
  [ExcelHeaders.ANALYTICALGROUP]: 'analyticalGroupCode',
  [ExcelHeaders.AMOUNT]: 'amount',
  [ExcelHeaders.BASEAMOUNT]: 'baseAmount',
  [ExcelHeaders.QUANTITY]: 'quantity',
  [ExcelHeaders.VOUCHER]: 'voucherNumber',
  [ExcelHeaders.MATCHPAYMENTENTRY]: 'matchedPaymentIden',
  [ExcelHeaders.DESCRIPTION]: 'description',
  [ExcelHeaders.UNIT]: 'unitCode',
  [ExcelHeaders.PRICE_UNIT]: 'priceUnitCode',
  [ExcelHeaders.PRICE]: 'price',
  [ExcelHeaders.SHIPMENT_ID]: 'shipmentId',
  [ExcelHeaders.SHIPMENT]: 'shipmentNumber',
  [ExcelHeaders.BUDGETELEMENT]: 'budgetElementName',
  [ExcelHeaders.CONTRACT]: 'contractNumber',
  [ExcelHeaders.NOTES]: 'notes',
  [ExcelHeaders.ENTRYTYPE]: 'entryType',
  [ExcelHeaders.ITEMREFERENCE]: 'entryText',
  [ExcelHeaders.AMOUNTTOPAY]: 'amount',
  [ExcelHeaders.ORIGIN]: 'origin',
  [ExcelHeaders.POL]: 'portOfLoading',
  [ExcelHeaders.POD]: 'portOfDischarge',
  [ExcelHeaders.DESTINATION]: 'destination',
  [ExcelHeaders.FREIGHT]: 'cost',
  [ExcelHeaders.SIZE]: 'size',
  [ExcelHeaders.SURCHARGES]: 'surchargesType',
  [ExcelHeaders.COST]: 'cost',
  [ExcelHeaders.TRANSIT_TIME]: 'transitTime',
  [ExcelHeaders.CONTAINER_20FT]: 'cost20',
  [ExcelHeaders.CONTAINER_40FT]: 'cost40',
  [ExcelHeaders.CONTAINER_40FTHC]: 'cost40Hc',
  [ExcelHeaders.CONTAINER]: 'containerNumber',
  [ExcelHeaders.CURRENCY]: 'currency',
  [ExcelHeaders.FISCAL_COMPANY]: 'fiscalCompany',
  [ExcelHeaders.PRODUCT]: 'productName',
};

/**
 * renameExcelKeys
 * @param obj Object to be mapped
 * @param newKeys newKeys to be mapped in the object
 * @returns An object mapped with the new keys
 */
export function renameExcelKeys<T>(obj: T, newKeys: { [key in ExcelHeaders]: string }) {
  const keyValues = Object.keys(obj).map((key) => {
    const regex = /[\n\r\s\t]+/g;
    const trimKey = key.replace(regex, ' ').trim();
    const newKey = newKeys[trimKey] || trimKey;
    return { [newKey]: typeof obj[key] === 'string' ? obj[key].trim() : obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

/**
 * assignSegmentsCities
 * @param delegate DelegateService
 * @param item line mapped from excel
 * @param citiesMap map cities array
 * @param templateCitiesList list of db cities of the template id selected, array type OceanratesTemplateCities
 * @param column column name to be displayed
 * @param position position of the item to be identified in the modals title
 * @returns return cities, citiesMap and citiesTemplates
 */
export async function assignSegmentsCities(delegate: DelegateService, item: any, citiesMap: Map<number, City[]>, templateCitiesList: OceanratesTemplateCities[], column: string, position: number) {
  // Initialize services
  const spinner = delegate.getService('spinner');
  const prompt = delegate.getService('prompt');
  const graphql = delegate.getService('graphql');
  return new Promise<{
    cities: City[];
    citiesMap: Map<number, City[]>;
    citiesTemplates: any;
  }>((resolve, reject) => {
    (async () => {
      let rid = spinner.startRequest('Drawing Data');
      // Array to be saved in createRatesCitiesTemplate endpoint
      let createTemplatesCities: Pick<OceanratesTemplateCities, 'cityCodes' | 'templateId' | 'name'> | undefined = undefined;

      // Array of cities to be returned
      let citiesAray: City[] | undefined = undefined;
      if (item !== undefined) {
        // Try to get cities array into the Map
        citiesAray = citiesMap.get(item);
        // If citiesArray wasn't populated
        if (citiesAray === undefined) {
          // Find item into the list cities template
          const findItem = templateCitiesList.find((citie) => citie.name === item);
          // If found, call cities graphql filtering by array of city codes found
          if (findItem !== undefined) {
            const cityCodes = findItem.cityCodes.split(',');
            const numbersCityCodes = cityCodes.map(Number);
            // Api call to get cities in our data base
            const citiesResponse = await graphql
              .query<City[]>({
                query: getGraphqlRequestQuery<City>(GraphqlNames.simpleCitiesList, basicCityGraphqlRequest({ where: { id: { in: numbersCityCodes } } })),
                graphQlName: ExistingGraphql.places,
              })
              .then((res) => {
                return res;
              });
            // Fill citiesArray
            citiesAray = citiesResponse;
            // Set cities in Map
            citiesMap.set(item, citiesAray);
            spinner.completeRequest(rid);
          }
          // If not found, open the multiselect popup function for selecting cities
          else {
            await new Promise((resolve) => {
              spinner.completeRequest(rid);
              const formResponse = selectDynamicItemsFormToPopulate(delegate, `Select the ${column} for ${item} on line #${position}`, `${column}`);
              formResponse.subscribe(async (formResult) => {
                if (formResult === 'Close') return reject(prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to identify ${column} for ${item}</div>`));
                const cities = formResult.cities;
                if (formResult.skipEntry) {
                  // Fill citiesArray
                  citiesAray = [];
                  // Set cities in Map
                  citiesMap.set(item, citiesAray);
                  createTemplatesCities = undefined;
                } else {
                  // Fill citiesArray
                  citiesAray = cities;
                  // Set cities in Map
                  citiesMap.set(item, citiesAray);
                  // Fill object to cities template to be created
                  createTemplatesCities = {
                    name: item,
                    templateId: templateCitiesList[0].templateId,
                    cityCodes: cities.map((city) => city.id).toString(),
                  };
                }
                return resolve(citiesAray);
              });
            });
          }
        }
        spinner.completeRequest(rid);
        resolve({
          cities: citiesAray,
          citiesMap,
          citiesTemplates: createTemplatesCities,
        });
      }
    })();
  });
}

/**
 * combineObjects
 * @param head array of multiples arrays of objects
 * @returns returns an array with all the posible combinations of merging arrays
 */
export async function combineObjects<T>([head, ...[headTail, ...tailTail]]: {
  [key: string]: any;
}[]) {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => ({ ...h, ...x }))) as T[];
  }, []);

  return combineObjects([combined, ...tailTail]) as T[];
}

export type CityMapperForm = {
  cities: City[];
  skipEntry: boolean;
};
