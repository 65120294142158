import { Component, Input } from '@angular/core';

@Component({
  selector: 'input-field-loader',
  templateUrl: './input-field-loader.component.html',
  styleUrls: ['./input-field-loader.component.scss'],
})
export class InputFieldLoaderComponent {
  @Input()
  readonly: boolean = false;

  @Input()
  border: boolean = true;
}
