import { BaseDiscrepancy } from './discrepancy';
import { ServiceOrder } from './serviceOrder';

export class Service {
  id!: number;
  name!: string;
  modifiedBy!: string;
  modifyDate!: Date;
  versionNb!: number;
  discrepancies?: BaseDiscrepancy[];
  serviceOrders?: ServiceOrder[];
}
