import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

export enum StatusBarSettingType {
  ACTION = 'Action',
  SETTING = 'Setting',
}
export type StatusBarSetting = {
  type: StatusBarSettingType.SETTING;
  value: any;
  options: { icon: string; label: string; value: any }[];
};
export type StatusBarAction = {
  type: StatusBarSettingType.ACTION;
  action: () => any;
  icon: string;
  label: string;
};
export type StatusBarSettingsPanelParams = IStatusPanelParams & {
  settings: Map<string, StatusBarSetting | StatusBarAction>;
};
@Component({
  selector: 'status-bar-settings-panel',
  template: `<div class="ag-status-name-value" style="height:20px">
    <button
      *ngFor="let button of this.settings; let i = index"
      [attr.data-index]="i"
      type="button"
      kendoButton
      [icon]="icons[i]"
      (click)="click(i)"
      style="margin-top:-2px; margin-right:2px; padding: 0; height: 24px; width: 24px; outline: none;"
      [title]="labels[i]"
    ></button>
  </div>`,
})
export class StatusBarSettingsPanel implements IStatusPanelAngularComp {
  params: StatusBarSettingsPanelParams;
  settings: (StatusBarSetting | StatusBarAction)[];
  icons: string[] = [];
  labels: string[] = [];
  agInit(params: StatusBarSettingsPanelParams): void {
    this.params = params;
    this.settings = Array.from(params.settings.values());
    this.icons = this.settings.map((setting) => (setting.type === StatusBarSettingType.ACTION ? setting.icon : this.getIcon(setting)));
    this.labels = this.settings.map((setting) => (setting.type === StatusBarSettingType.ACTION ? setting.label : this.getLabel(setting)));
  }

  click(index: number) {
    const setting = this.settings[index];
    if (!setting) return;
    switch (setting.type) {
      case StatusBarSettingType.ACTION:
        setting.action();
        break;
      case StatusBarSettingType.SETTING:
        if (setting.options.length < 1) break;
        let currentOptionIndex = setting.options.findIndex((option) => option.value === setting.value);
        if (currentOptionIndex < 0 || currentOptionIndex >= setting.options.length - 1) currentOptionIndex = -1;
        setting.value = setting.options[currentOptionIndex + 1].value;
        this.icons[index] = this.getIcon(setting);
        this.labels[index] = this.getLabel(setting);
        break;
    }
  }

  getIcon(setting: StatusBarSetting) {
    const currentOption = setting.options.find((option) => option.value === setting.value);
    return currentOption ? currentOption.icon : 'question';
  }

  getLabel(setting: StatusBarSetting) {
    const currentOption = setting.options.find((option) => option.value === setting.value);
    return currentOption ? currentOption.label : '';
  }
}
