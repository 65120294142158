import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { endpoints } from 'src/lib/apiEndpoints';
import { GraphqlVariables } from 'src/lib/graphql/graphqlRequestTypes';
import { Observable, map, isObservable, lastValueFrom, catchError } from 'rxjs';
import { ThalosApiService, rpcOptions } from './thalos-api.service';
import { StoreSubscription } from 'src/lib';
import { FormState } from '../reducers/form';
import { FormGroup } from '@angular/forms';
import { Store } from './store.service';
import { State } from '../reducers';
import { SET_ACTIVE_FORM } from '../reducers/actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { ExistingGraphql } from 'src/lib/graphql/graphQlEnums';
import { GraphqlDropdownConfig } from 'src/lib/graphql/GraphqlDropdownConfig';
import { channelId } from '../components/app/app.component';

export type GraphqlResponse<T> = {
  data: {
    request: T;
  };
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GraphqlService implements OnDestroy {
  authorized: endpointsAuthorized;
  activeForm: () => FormGroup;
  storeSubscription: StoreSubscription<FormState>;

  constructor(private http: HttpClient, private store: Store, private apiService: ThalosApiService) {
    this.storeSubscription = this.store.subscribe((store: State) => store.form, [SET_ACTIVE_FORM]);
    this.storeSubscription.$.pipe(untilDestroyed(this)).subscribe((formState: FormState) => {
      this.activeForm = formState.activeForm;
    });

    endpointAuthorizationSubscription(store, this);
  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
  }

  public query<T>(payload: { query: string; graphQlName: ExistingGraphql; variables?: GraphqlVariables<T> }, defaultValue?: T | null, options?: rpcOptions): Promise<T> {
    const request = this.http
      .post<GraphqlResponse<T>>(
        `${environment.apiHost}${endpoints.graphql}`,
        {
          args: {
            query: payload.query,
            variables: payload.variables,
          },
          channelId,
          graphqlName: payload.graphQlName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        map(({ data }) => data.request),
        catchError((err) => this.apiService.handleError(err, { environmentName: environment.name, endpoint: payload.graphQlName, defaultValue, ...options }, payload.query))
      );
    if (isObservable(request)) {
      return lastValueFrom(request).then((res) => {
        return res;
      });
    }
  }

  public mutate<T>(options: { mutation: string; variables?: { [key: string]: any } }): Observable<any> {
    return this.http
      .post<{ data: T }>(
        `${environment.apiHost}${endpoints.graphql}`,
        {
          query: options.mutation,
          variables: options.variables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(map((d) => d.data));
  }

  public handleFilter<T>(textInput: string, source: T extends Array<infer U> ? GraphqlDropdownConfig<U> : GraphqlDropdownConfig<T>): Promise<T> {
    let variables = {};
    if (!!source.secondaryVariables) {
      variables = source.secondaryVariables;
    }
    if (!!source.principalVariableKey) {
      variables[source.principalVariableKey] = source.valueType === 'Int' ? parseInt(textInput) : textInput;
    }
    return this.query<T>({
      query: source.query,
      graphQlName: source.graphQlName,
      variables,
    });
  }
}
