import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  CellClassParams,
  CellClassRules,
  Column,
  ColumnState,
  ExcelStyle,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  IAggFuncParams,
  MenuItemDef,
  RowNode,
  ValueFormatterParams,
} from 'ag-grid-community';
import { Observable, Observer, map, of, switchMap } from 'rxjs';
import { UserState } from 'src/app/core/reducers/user';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { PromptService } from 'src/app/core/services/prompt.service';
import { SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { FlexLayoutPopupComponent, ListLayoutForm } from 'src/app/shared/flex-layout-popup/flex-layout-popup.component';
import { dateGetter } from './agGridFunctions';
import { endpoints } from './apiEndpoints';
import { intFormat } from './commonTypes';
import { endpointsAuthorized } from './helperFunctions';
import { Currency, Unit, YN } from './newBackendTypes';
import { toMetricTons, toUnit } from './unitConversions';
import {
  CreateEntityLayoutRequest,
  CreateFlexLayoutRequest,
  EntityLayout,
  EntityLayoutDefault,
  ListColumn,
  ListColumnType,
  ListDateGroupBehavior,
  ListLayout,
  ListLayoutDefault,
  ListView,
  ListViews,
  SetDefaultFlexLayoutRequest,
  UpdateEntityLayoutRequest,
  UpdateFlexLayoutRequest,
  UpsertEntityLayoutDefaultRequest,
  ViewType,
} from './views';
import { UserGroupsEnum } from './uiConstants';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  userState: UserState;
  userId: number;
  isUserInDIT: boolean;
  private _colorObservers: Observer<{ columnId: any; color: string | null }>[];
  colorSelected: Observable<{ columnId: any; color: string | null }>;
  /**
   * Authorized endpoints for the current user
   */
  authorized: endpointsAuthorized;

  $emitter = new EventEmitter();
  $emitterToggleSideBar = new EventEmitter();

  constructor(
    private promptService: PromptService,
    private spinnerService: SpinnerService,
    private store: Store,
    private commonData: CommonDataService,
    public dataFormatter: DataFormattingService,
    private selectorService: SelectorPopupService,
    private api: ThalosApiService,
    private dialogService: DialogService
  ) {
    this.userState = this.store.snapshot((state) => state.user);
    this.userId = this.userState.user.id;
    this.isUserInDIT = this.userState.userGroups.some((ug) => ug.cn === UserGroupsEnum.DIT);
    this._colorObservers = [];
    this.colorSelected = new Observable((obsv) => {
      this._colorObservers.push(obsv);
    });
  }

  emitReloadDataEvent() {
    this.$emitter.emit();
  }

  emitToggleSideBar() {
    this.$emitterToggleSideBar.emit();
  }

  /**
   *
   * @returns A callback function for the context menu to deselect all rows
   */
  getDeselectAllOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (!!params.api.getSelectedNodes().length) {
        return {
          name: 'Deselect All',
          action: () => {
            params.api.deselectAll();
          },
        };
      }
      return [];
    };
  }

  /**
   *
   * @returns The context menu callback to expand groups to a specific depth
   */
  expandToLevelOption() {
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef => {
      if (params.columnApi.getRowGroupColumns().length === 0) {
        return null;
      }
      return {
        name: 'Expand to Depth N',
        icon: `<span class="ag-icon ag-icon-calendar" unselectable="on" role="presentation"></span>`,
        action: () => {
          this.promptService.numericPrompt('Expand to Depth N', 'Depth', intFormat(), 1).subscribe((res) => {
            if (res >= 0) {
              this.expandToLevel(res, params.api);
            }
          });
        },
      };
    };
  }

  expandToLevel(depth: number, api: GridApi) {
    let rid = this.spinnerService.startRequest('Expanding');
    setTimeout(() => {
      api.forEachNode((n) => {
        if (!n.group) return;
        if (n.level < depth) {
          n.setExpanded(true);
        } else {
          n.setExpanded(false);
        }
      });
      this.spinnerService.completeRequest(rid);
    });
  }

  applyModelFilters(filter, api: GridApi) {
    let rid = this.spinnerService.startRequest('Applying filters');
    setTimeout(() => {
      api.setFilterModel(filter);
      this.spinnerService.completeRequest(rid);
    });
  }

  /**
   * Check the layoutChanges.  Apply column state and redraw rows
   *
   *
   * @returns The callback function for the color picker
   */
  layoutApplyChanges(gridOptions: GridOptions, raw, filters) {
    if (!gridOptions.columnApi) return;
    let columnState: ColumnState[] = raw;
    let rid = this.spinnerService.startRequest('Applying Layout');
    setTimeout(() => {
      gridOptions.columnApi.applyColumnState({ state: columnState, applyOrder: true });
      gridOptions.api.redrawRows();
      this.applyModelFilters(filters, gridOptions!.api);
      this.expandToLevel(gridOptions.groupDefaultExpanded, gridOptions!.api);
      this.spinnerService.completeRequest(rid);
    });
  }
  /**
   * The menu item that allows the user to select a background color for the column.
   * This column can be saved to a layout.
   *
   * @returns The callback function for the color picker
   */
  getColorPickerContextOption(columnOptions: ColumnOptions) {
    return (params: GetContextMenuItemsParams | GetMainMenuItemsParams): MenuItemDef => {
      if (!columnOptions) return;
      let colId = params?.column?.getColId();
      if (!colId) return;
      let startingColor = columnOptions[colId]?.backgroundColor;

      return {
        name: 'Select Column Color',
        icon: `<span class="ag-icon ag-icon-color-picker" style="color: ${startingColor}" unselectable="on" role="presentation"></span>`,
        action: () => {
          this.promptService.colorPickerPrompt('Select Color', '', startingColor || undefined).subscribe((res) => {
            if (res === false) {
              this.selectColor(colId, null);
            } else if (res) {
              this.selectColor(colId, res);
            }
          });
        },
      };
    };
  }

  selectColor(columnId: any, color: string) {
    this._colorObservers.forEach((o) => o.next({ columnId, color }));
  }

  /**
   * Exports the grid to excel.
   *
   * @param stylesArray Reference to the array of excel styles.  Must be edited in place as ag-grid expects to have a reference to the object.
   * @returns The menu item.
   */
  exportToExcel(listView: ListView | EntityLayout, columnOptions: ColumnOptions, stylesArray: ExcelStyle[]) {
    return (params: GetContextMenuItemsParams): MenuItemDef => {
      if (!listView || !columnOptions) return;
      return {
        name: 'Excel Export',
        icon: '<span class="ag-icon ag-icon-excel"></span>',
        action: () => {
          stylesArray.splice(0, stylesArray.length);
          stylesArray.push(
            ...params.columnApi.getAllDisplayedColumns().flatMap((c) => {
              const flexColumn = listView.columns.find((match) => match.field === c.getColId());
              return flexColumn ? this.createExcelStylesForColumn(flexColumn, c, columnOptions) : [];
            })
          );
          params.api.exportDataAsExcel();
        },
      };
    };
  }

  /**
   * Add filter value to column filters.
   *
   * @returns Column filtered by value.
   */
  filterByValue(listView: ListView | EntityLayout, gridOptions: GridOptions) {
    return (params: GetContextMenuItemsParams): MenuItemDef => {
      const colId = params?.column?.getColId();
      if (!colId) return;
      const col = listView.columns.find((c) => c.field === colId);
      if (!col) return;
      const filterInstance = gridOptions.api.getFilterInstance(col.field);
      const value = params.value;
      if (!value) return;
      return {
        name: `Filter by cell value`,
        icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
        action: () => {
          switch (col.type) {
            case ListColumnType.TEXT:
              filterInstance.setModel({ filterType: 'text', type: 'contains', filter: value });
              break;
            case ListColumnType.NUMBER:
            case ListColumnType.AMOUNT:
            case ListColumnType.AMOUNT_UNIT:
            case ListColumnType.WEIGHT:
            case ListColumnType.ID:
            case ListColumnType.DEBIT_CREDIT:
            case ListColumnType.PRICE_UNIT:
              filterInstance.setModel({ filterType: 'number', type: 'equals', filter: value });
              break;
            case ListColumnType.DATE:
              const getDate = dateGetter(value);
              const formatDate = getDate.toISOString();
              filterInstance.setModel({
                filterType: 'date',
                type: 'equals',
                dateFrom: formatDate,
                dateTo: null,
              });
              break;
            case ListColumnType.ENUM:
              const enumObject = col.typeConfiguration.enumValues;
              const matching = enumObject.find((l) => l.value == value);
              filterInstance.setModel({ filterType: 'set', values: [matching.label] });
              break;
          }
          gridOptions.api.onFilterChanged();
        },
      };
    };
  }

  /**
   *
   * @param def The List Column
   * @param col The ag-grid column
   * @returns Creates Excel styles based on the column.  These are mapped to the column via css classes assigned by the function assignExcelStylesToColumn
   */
  createExcelStylesForColumn(def: ListColumn, col: Column, columnOptions: ColumnOptions) {
    const styles: ExcelStyle[] = [];
    let baseId = `excel-export-${col.getColId()}`;
    const colOptions = columnOptions[col.getColId()] || {};
    const red = colOptions.negativeRed === YN.Y;
    const background = colOptions.backgroundColor;

    const baseExcelFormat: Partial<ExcelStyle> = {};

    if (!!background) {
      baseExcelFormat.interior = { color: background, pattern: 'Solid' } as any;
    }

    let baseNumberFormat = ``;
    let negativeNumberFormat = ``;

    if (
      def.type === ListColumnType.NUMBER ||
      def.type === ListColumnType.AMOUNT ||
      def.type === ListColumnType.DEBIT_CREDIT ||
      def.type === ListColumnType.AMOUNT_UNIT ||
      def.type === ListColumnType.WEIGHT ||
      def.type === ListColumnType.PRICE_UNIT
    ) {
      const comma = def.typeConfiguration.useGrouping === YN.Y;
      baseNumberFormat += comma ? `#,##` : `###`;
      const decimals = def.typeConfiguration.decimals;
      baseNumberFormat += `0`;
      if (decimals > 0) baseNumberFormat += '.';
      for (let i = 1; i <= decimals; i++) {
        baseNumberFormat += '0';
      }
      negativeNumberFormat = red ? `_);[Red]${baseNumberFormat}` : ``;

      baseExcelFormat.alignment = {
        horizontal: decimals > 0 ? 'Right' : 'Left',
      };
      styles.push({
        id: baseId,
        ...baseExcelFormat,
        numberFormat: {
          format: `${baseNumberFormat}${negativeNumberFormat}`,
        },
      });
    }
    switch (def.type) {
      case ListColumnType.AMOUNT:
      case ListColumnType.DEBIT_CREDIT: {
        const config = def.typeConfiguration;
        let currencies = this.commonData.staticCurrencies.value;

        const currencyOverride = colOptions.displayCurrency;

        if (currencyOverride) currencies = [currencyOverride];
        else if ('staticCurrency' in config) {
          let staticCurrency = this.dataFormatter.currencyFromTag(config.staticCurrency);
          if (staticCurrency) currencies = [staticCurrency];
        }

        for (let c of currencies) {
          const id = `${baseId}-${c.code}`;
          styles.push({
            ...baseExcelFormat,
            id,
            dataType: 'Number',
            numberFormat: {
              format: `${baseNumberFormat} ${escape(c.code)}` + (!!negativeNumberFormat ? `${negativeNumberFormat} ${escape(c.code)}` : ``),
            },
          });
        }
        break;
      }
      case ListColumnType.WEIGHT: {
        const config = def.typeConfiguration;
        let units = this.commonData.staticUnits.value;

        const unitOverride = colOptions.displayUnit;

        if (unitOverride) units = [unitOverride];
        else if ('staticUnit' in config) {
          let staticUnit = this.dataFormatter.unitFromTag(config.staticUnit);
          if (staticUnit) units = [staticUnit];
        }

        for (let u of units) {
          const id = `${baseId}-${u.code}`;
          styles.push({
            ...baseExcelFormat,
            id,
            dataType: 'Number',
            numberFormat: {
              format: `${baseNumberFormat} ${escape(u.code)}` + (!!negativeNumberFormat ? `${negativeNumberFormat} ${escape(u.code)}` : ``),
            },
          });
        }
        break;
      }

      case ListColumnType.AMOUNT_UNIT:
      case ListColumnType.PRICE_UNIT:
        {
          const config = def.typeConfiguration;
          let units = this.commonData.staticUnits.value;
          let currencies = this.commonData.staticCurrencies.value;

          const unitOverride = colOptions.displayUnit;
          const currencyOverride = colOptions.displayCurrency;

          if (unitOverride) units = [unitOverride];
          else if ('staticUnit' in config) {
            let staticUnit = this.dataFormatter.unitFromTag(config.staticUnit);
            if (staticUnit) units = [staticUnit];
          }
          if (currencyOverride) currencies = [currencyOverride];
          else if ('staticCurrency' in config) {
            let staticCurrency = this.dataFormatter.currencyFromTag(config.staticCurrency);
            if (staticCurrency) currencies = [staticCurrency];
          }

          for (let u of units) {
            for (let c of currencies) {
              const id = `${baseId}-${c.code}-${u.code}`;
              styles.push({
                ...baseExcelFormat,
                id,
                dataType: 'Number',
                numberFormat: {
                  format: `${baseNumberFormat} ${escape(c.code)}\\\/${escape(u.code)}` + (!!negativeNumberFormat ? `${negativeNumberFormat} ${escape(c.code)}\\\/${escape(u.code)}` : ``),
                },
              });
            }
          }
        }
        break;

      case ListColumnType.TEXT:
        {
          styles.push({
            ...baseExcelFormat,
            dataType: 'String',
            id: baseId,
          });
        }
        break;

      case ListColumnType.DATE: {
        styles.push({
          ...baseExcelFormat,
          numberFormat: {
            format: 'm/d/yy;-0;;@',
          },
          dataType: 'DateTime',
          id: baseId,
        });
      }

      case ListColumnType.ID:
        {
          styles.push({
            ...baseExcelFormat,
            dataType: 'Number',
            id: baseId,
            alignment: {
              horizontal: 'Left',
            },
          });
        }

        break;
    }
    return styles;
  }

  /**
   * Creates a formatter function that displays a currency in either the given unit for the row/column or converts to the user defined unit if
   * one is selected
   * @returns A value foramtter function that in turn returns a Currency containing the currency of the row
   * followed by the calculated unit code
   */
  currencyFromTag(currencyOrCurrencyId: string | number | Currency): Currency | null {
    return this.dataFormatter.currencyFromTag(currencyOrCurrencyId);
  }

  /**
   * Creates a formatter function that displays a quantity in either the given unit for the row/column or converts to the user defined unit if
   * one is selected
   * @param args Either a unitField in the case of a dynamic unit column, or a unit (or unitId or unitCode) in the case of a static unit column
   * @param decimals The number of decimals places to format the resulting value with
   * @returns A value foramtter function that in turn returns a string containing the quantity of the row with the given number of decimal places
   * followed by the calculated unit code
   */
  unitFormatter(args: { unitField: string } | { unit: string | number | Unit }, decimals: number, columnOptions: ColumnOptions, separator?: YN | string, showUnit: YN | string = YN.Y) {
    return (params: ValueFormatterParams) => {
      if (!columnOptions) return;
      let data: any = params.data;
      if (!data) return params.value;
      let quantity: number | undefined | object | null = params.value;
      if (typeof quantity !== 'number') return params.value;

      let unitTag: string | number | Unit;
      if ('unitField' in args) {
        unitTag = data[args.unitField];
      } else {
        unitTag = args.unit;
      }
      let originalUnit = this.dataFormatter.unitFromTag(unitTag);
      if (!originalUnit) return params.value;

      let option = columnOptions[params.column.getColId()];
      let displayUnit = !!option?.displayUnit ? option.displayUnit : originalUnit;

      if (displayUnit.code !== originalUnit.code) {
        quantity = toUnit(quantity, originalUnit, displayUnit);
      }

      return this.dataFormatter.quantityUnitFormatter(quantity, displayUnit, decimals, separator, showUnit);
    };
  }

  unitFromTag(unitOrUnitId: string | number | Unit): Unit | null {
    return this.dataFormatter.unitFromTag(unitOrUnitId);
  }

  gridStaticCurrencyFormatter<T>(currencyOrId: string | number | Currency | null, decimalPlaces?: number, seperator?: YN | string, showUnit: YN | string = YN.Y) {
    return this.dataFormatter.gridStaticCurrencyFormatter(currencyOrId, decimalPlaces, seperator, showUnit);
  }

  gridCurrencyFormatter<T>(currencyField: string, decimalPlaces?: number, seperator?: YN | string, showUnit: YN | string = YN.Y) {
    return this.dataFormatter.gridCurrencyFormatter(currencyField, decimalPlaces, seperator, showUnit);
  }

  gridStaticAmountCurrencyPerUnitFormatter<T>(currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit, decimalPlaces = 3, seperator?: YN | string) {
    return this.dataFormatter.gridStaticAmountCurrencyPerUnitFormatter(currencyOrCurrencyId, unitOrUnitId, decimalPlaces, seperator);
  }

  gridAmountStaticCurrencyPerUnitFormatter<T>(unitField: string, currencyOrCurrencyId: string | number | Currency, decimalPlaces = 3, seperator?: YN | string) {
    return this.dataFormatter.gridAmountStaticCurrencyPerUnitFormatter(unitField, currencyOrCurrencyId, decimalPlaces, seperator);
  }

  gridAmountCurrencyPerStaticUnitFormatter<T>(unitOrUnitId: string | number | Unit, currencyField: string, decimalPlaces: number = 3, seperator?: YN | string) {
    return this.dataFormatter.gridAmountCurrencyPerStaticUnitFormatter(unitOrUnitId, currencyField, decimalPlaces, seperator);
  }

  gridAmountCurrencyPerUnitFormatter<T>(unitFieldOrUnit: string | Unit, currencyFieldOrCurrency: string | Currency, decimalPlaces: number = 3, seperator?: YN | string) {
    return this.dataFormatter.gridAmountCurrencyPerUnitFormatter(unitFieldOrUnit, currencyFieldOrCurrency, decimalPlaces, seperator);
  }

  gridStaticPriceCurrencyPerUnitFormatter<T>(
    currencyOrCurrencyId: string | number | Currency,
    unitOrUnitId: string | number | Unit,
    decimalPlaces?: number,
    separator?: YN | string,
    showUnit: YN | string = YN.Y
  ) {
    return this.dataFormatter.gridStaticPriceCurrencyPerUnitFormatter(currencyOrCurrencyId, unitOrUnitId, decimalPlaces, separator, showUnit);
  }

  gridPriceCurrencyPerUnitFormatter<T>(unitFieldOrUnit: string | Unit, currencyFieldOrCurrency: string | Currency, decimalPlaces?: number, separator?: YN | string, showUnit: YN | string = YN.Y) {
    return this.dataFormatter.gridPriceCurrencyPerUnitFormatter(unitFieldOrUnit, currencyFieldOrCurrency, decimalPlaces, separator, showUnit);
  }

  gridStaticUnitFormatter<T>(unitOrUnitId: string | number | Unit, decimals?: number, seperator?: YN | string, showUnit: YN | string = YN.Y) {
    return this.dataFormatter.gridStaticUnitFormatter(unitOrUnitId, decimals, seperator, showUnit);
  }

  gridUnitFormatter<T>(unitField: string, decimals?: number, seperator?: YN | string, showUnit: YN | string = YN.Y) {
    return this.dataFormatter.gridUnitFormatter(unitField, decimals, seperator, showUnit);
  }

  /**
   * Creates a new layout preset based on changes the user has made to the ag-grid layout
   */
  saveLayout(listView: ListView | EntityLayout, columnOptions: ColumnOptions, layoutControl: UntypedFormControl, layouts: any[], gridOptions: GridOptions, type: ViewType, listId?: ListViews): void {
    if (!listView || !this.userId) return;

    let state = gridOptions.columnApi.getColumnState();

    //prefill form with values from current layout if it exists
    let columnState = JSON.stringify(
      state.map((c) => {
        let option = columnOptions[c.colId];
        if (typeof option !== 'object') option = {};
        return { ...c, thalosFlexOptions: option ?? {} };
      })
    );

    let layoutFilters = gridOptions.api ? JSON.stringify(gridOptions.api.getFilterModel()) : '{}';

    let prefill: Partial<ListLayoutForm> = {
      name: '',
      id: null,
    };

    if (!!layoutControl.value) {
      prefill.name = layoutControl.value.name.replace(' (Default)', '');
      prefill.shared = layoutControl.value.shared;
      prefill.id = layoutControl.value.id;
      prefill.groupsExpanded = layoutControl.value.groupsExpanded;
    }

    let createEndpoint: endpoints;
    let updateEndpoint: endpoints;
    let defaultEndpoint: endpoints;
    let isFlex: boolean;
    const fullactions = [
      {
        name: 'Modify Existing',
        action: (c) => {
          return c.clickModify();
        },
      },
      {
        name: 'Save as New',
        action: (c) => {
          return c.clickSaveAsNew();
        },
      },
    ];
    switch (type) {
      case ViewType.flexView:
        createEndpoint = endpoints.createFlexViewLayout;
        updateEndpoint = endpoints.updateFlexViewLayout;
        defaultEndpoint = endpoints.setDefaultFlexLayout;
        isFlex = true;
        if (!this.isUserInDIT && layoutControl && layoutControl?.value && layoutControl?.value?.userId !== this.userId) fullactions.shift();
        break;
      case ViewType.entityView:
        createEndpoint = endpoints.createEntityLayout;
        updateEndpoint = endpoints.updateEntityLayout;
        defaultEndpoint = endpoints.upsertEntityLayoutDefault;
        if (!this.isUserInDIT && layoutControl && layoutControl?.value && layoutControl?.value?.ownerId !== this.userId) fullactions.shift();
        isFlex = false;
        break;
    }

    //Open the layout form to save the layout
    this.selectorService
      .openForm<Partial<ListLayoutForm>, FlexLayoutPopupComponent>(
        FlexLayoutPopupComponent,
        {
          title: 'Save Layout',
          submitButtonText: 'Save',
          prefillValue: prefill,
          hideDefaultActions: true,
          initializer: (c) => {
            c.userId = this.userId;
            c.originalLayout = layoutControl.value;
            c.isFlex = isFlex;
          },
        },
        fullactions
      )
      .subscribe((layoutFormValue) => {
        if (layoutFormValue !== 'Close') {
          if (layoutFormValue.saveAsNew || !layoutFormValue.id) {
            const nameInUse = layouts.some((item) => item.name === layoutFormValue.name);
            if (nameInUse) return this.promptService.htmlDialog('Error', 'Name selected is already in use. Please set a different name.');
            const flexRequest: CreateFlexLayoutRequest = {
              columnState,
              flexViewId: listView.id || undefined,
              name: layoutFormValue.name,
              shared: layoutFormValue.shared ? YN.Y : null,
              userId: this.userId,
              groupsExpanded: layoutFormValue.groupsExpanded,
              default: !layouts?.length ? YN.Y : undefined,
              layoutFilters,
            };

            const entityRequest: CreateEntityLayoutRequest = {
              columnState,
              listId: listId,
              name: layoutFormValue.name,
              shared: layoutFormValue.shared,
              groupsExpanded: layoutFormValue.groupsExpanded,
              default: !layouts?.length ? YN.Y : layoutFormValue.makeDefault,
              layoutFilters,
            };
            let rid = this.spinnerService.startRequest('Saving Layout');
            this.api
              .rpc<any>(createEndpoint, isFlex ? flexRequest : entityRequest, null)
              .pipe(
                switchMap((res) => {
                  if (layoutFormValue.makeDefault) {
                    let defaultRequest: UpsertEntityLayoutDefaultRequest | SetDefaultFlexLayoutRequest;
                    if (isFlex) {
                      defaultRequest = {
                        flexLayoutId: res.id,
                        flexViewId: res.flexViewId,
                        userId: this.userId,
                      };
                    } else {
                      defaultRequest = {
                        layoutId: res.id,
                        listId: res.listId,
                        userId: this.userId,
                      };
                    }
                    return this.api.rpc<any>(defaultEndpoint, defaultRequest, null).pipe(map(() => res));
                  }
                  return of(res);
                })
              )
              .subscribe((res) => {
                this.spinnerService.completeRequest(rid);
                layouts.push(res);
                layoutControl.setValue(layouts[layouts.length - 1], { emitEvent: false });
                layouts = Array.from(layouts);
              });
          } else {
            const flexRequest: UpdateFlexLayoutRequest = {
              id: layoutFormValue.id,
              columnState,
              flexViewId: listView.id || undefined,
              name: layoutFormValue.name,
              shared: layoutFormValue.shared ? YN.Y : null,
              userId: this.userId,
              groupsExpanded: layoutFormValue.groupsExpanded,
              default: !layouts?.length ? YN.Y : undefined,
              layoutFilters,
            };
            const entityRequest: UpdateEntityLayoutRequest = {
              id: layoutFormValue.id,
              columnState,
              ownerId: this.userId,
              listId: listId,
              name: layoutFormValue.name,
              shared: layoutFormValue.shared,
              groupsExpanded: layoutFormValue.groupsExpanded,
              default: layoutFormValue.makeDefault,
              layoutFilters,
            };
            let rid = this.spinnerService.startRequest('Saving Layout');
            this.api
              .rpc<any>(updateEndpoint, isFlex ? flexRequest : entityRequest, null)
              .pipe(
                switchMap((res) => {
                  if (layoutFormValue.makeDefault) {
                    if (isFlex) {
                      return this.api.rpc<ListLayoutDefault>(defaultEndpoint, { flexLayoutId: res.id, flexViewId: res.flexViewId, userId: this.userId }, null).pipe(map(() => res));
                    } else {
                      return this.api.rpc<EntityLayoutDefault>(defaultEndpoint, { layoutId: res.id, listId: res.listId, userId: this.userId }, null).pipe(map(() => res));
                    }
                  }
                  return of(res);
                })
              )
              .subscribe((res) => {
                this.spinnerService.completeRequest(rid);
                let existingLayout = layouts.find((l) => l.id === res.id);
                let originalGroupsExpanded = existingLayout?.groupsExpanded ?? null;
                if (existingLayout) {
                  existingLayout.name = res.name;
                  existingLayout.columnState = res.columnState;
                  existingLayout.userId = res.userId;
                  existingLayout.shared = res.shared;
                  existingLayout.groupsExpanded = res.groupsExpanded;
                  existingLayout.default = res.default;
                  existingLayout.filters = res.filters;
                }
                layoutControl.setValue(existingLayout, { emitEvent: false });
                layouts = Array.from(layouts);
                if (existingLayout && originalGroupsExpanded !== existingLayout.groupsExpanded) {
                  this.expandToLevel(existingLayout.groupsExpanded, gridOptions!.api);
                }
              });
          }
        }
      });
  }

  /**
   * Deletes the current layout if the user has permission to do so.
   */
  deleteLayout(layoutControl: UntypedFormControl, layouts: any[], type: ViewType): void {
    let layout = layoutControl.value;
    if (!layout) {
      return;
    }
    if (!this.isUserInDIT && ((this.userId !== layout.userId && type === ViewType.flexView) || (this.userId !== layout.ownerId && type === ViewType.entityView))) {
      this.dialogService.open({
        title: 'Delete Failed',
        content: 'You do not have permission to delete this layout',
      });
      return;
    }
    let deleteEndpoint: endpoints;
    let isFlex: boolean;
    switch (type) {
      case ViewType.flexView:
        deleteEndpoint = endpoints.deleteFlexViewLayout;
        isFlex = true;
        break;
      case ViewType.entityView:
        deleteEndpoint = endpoints.deleteEntityLayout;
        isFlex = false;
        break;
    }
    this.promptService.simpleConfirmation('Delete Layout', 'This cannot be undone.  Are you sure you wish to delete the layout?', { confirmText: 'Delete' }).subscribe((res) => {
      if (res) {
        let rid = this.spinnerService.startRequest('Deleting Layout');
        this.api.rpc<ListLayout>(deleteEndpoint, isFlex ? { id: layout.id } : { filters: { id: layout.id } }, null).subscribe((res) => {
          this.spinnerService.completeRequest(rid);

          if (res) {
            let deletedIndex = layouts.findIndex((l) => l.id === res.id);
            if (deletedIndex >= 0) {
              layouts.splice(deletedIndex, 1);
              if (layouts.length >= 1) {
                layoutControl.setValue(layouts[0]);
              } else {
                layoutControl.setValue(null);
              }
            }
          }
        });
      }
    });
  }

  /**
   *
   * @param flexColumn The given column in the List View
   * @param unit If applicable, the unit object OR the id of the flex column containing the unit
   * @param currency If applicable, the currency object OR the id of the flex column containing the currency
   * @returns Css class rules to be applied to the column.  ag-grid identifies each column by css classes when exporting to excel
   */
  assignExcelStylesToColumn(flexColumn: any, unit: Unit | string | null, currency: Currency | string | null): CellClassRules {
    if (!unit && !currency) return {};
    if (typeof unit !== 'string' && typeof currency !== 'string') {
      let uCode = unit ? `-${unit.code}` : ``;
      let cCode = currency ? `-${currency.code}` : ``;
      return { [`excel-export-${flexColumn.field}${cCode}${uCode}`]: () => true };
    }
    const allCurrencies = this.commonData.staticCurrencies.value;
    const allUnits = this.commonData.staticUnits.value;
    const map: CellClassRules = {};

    switch (flexColumn.type) {
      case ListColumnType.AMOUNT_UNIT:
      case ListColumnType.PRICE_UNIT:
        {
          const currencies = typeof currency === 'object' ? [currency] : allCurrencies;
          for (let c of currencies) {
            let cCode = c ? `-${c.code}` : ``;
            const units = typeof unit === 'object' ? [unit] : allUnits;
            for (let u of units) {
              let uCode = u ? `-${u.code}` : ``;
              const cssClass = `excel-export-${flexColumn.field}${cCode}${uCode}`;
              map[cssClass] = (params: CellClassParams) => {
                if (!params.data) return false;
                let unitMatch = typeof unit === 'object' || this.dataFormatter.unitFromTag(params.data[unit])?.code === u.code;
                let currMatch = typeof currency === 'object' || this.dataFormatter.currencyFromTag(params.data[currency])?.code === c.code;
                return unitMatch && currMatch;
              };
            }
          }
        }
        break;
      case ListColumnType.AMOUNT:
      case ListColumnType.DEBIT_CREDIT:
        {
          if (typeof currency === 'object') return;
          for (let c of allCurrencies) {
            const cssClass = `excel-export-${flexColumn.field}-${c.code}`;
            map[cssClass] = (params: CellClassParams) => {
              if (!params.data) return false;
              return this.dataFormatter.currencyFromTag(params.data[currency])?.code === c.code;
            };
          }
        }
        break;
      case ListColumnType.WEIGHT:
        {
          if (typeof unit === 'object') return;
          for (let u of allUnits) {
            const cssClass = `excel-export-${flexColumn.field}-${u.code}`;

            map[cssClass] = (params: CellClassParams) => {
              if (!params.data) return false;
              return typeof unit === 'object' || this.dataFormatter.unitFromTag(params.data[unit])?.code === u.code;
            };
          }
        }
        break;
    }

    return map;
  }

  /**
   * Formats group total values to limit decimal places and display unit, converting from original unit,
   * which in the case of a static unit column, may not have been converted to MT
   *
   * Uses user defined unit if one is set for given column
   * @param decimals Number of decimal places to format number to, defaults to 3
   * @returns Formatter function that returns an object containing the original numerical value, the display unit,
   *  as well a toString function that returns the formatted value
   */
  staticUnitAggFormatter(decimals: number = 3, columnOptions: ColumnOptions, separator: YN | string = YN.Y, showUnit: YN | string = YN.Y): aggFormatter<{ unit: Unit | string | number }> {
    return (value: number | null, { unit }, params: IAggFuncParams) => {
      let option = columnOptions[params.column.getColId()];
      let originalUnit = this.dataFormatter.unitFromTag(unit);
      if (!originalUnit) return 'Error';
      let displayUnit = !!option?.displayUnit ? option.displayUnit : originalUnit;
      if (displayUnit.code !== originalUnit.code && (!params.rowNode.group || params.rowNode.leafGroup)) {
        value = toUnit(value, originalUnit, displayUnit);
      }

      return value !== null
        ? {
            amount: value,
            formatted: this.dataFormatter.quantityUnitFormatter(value, displayUnit, decimals, separator, showUnit),
            unit: displayUnit.code,
            toString: function () {
              return this.formatted;
            },
          }
        : null;
    };
  }

  /**
   * Formats group total values to limit decimal places and display currency
   *
   * @param decimals Number of decimal places to format number to, defaults to 3
   * @returns Formatter function that returns an object containing the original numerical value, the display currency,
   *  as well a toString function that returns the formatted value
   */
  currencyAggFormatter(decimals: number = 2, columnOptions: ColumnOptions, separator: YN | string = YN.Y, showUnit: YN | string = YN.Y): aggFormatter<{ currency: Currency }> {
    return (value: number | null, { currency }, params: IAggFuncParams) => {
      let option = columnOptions[params.column.getColId()];
      let displayCurrency = !!option?.displayCurrency ? option.displayCurrency : currency;

      return value !== null
        ? {
            amount: value,
            formatted: this.dataFormatter.amountCurrencyFormatter(value, displayCurrency, decimals, separator, showUnit),
            toString: function () {
              return this.formatted;
            },
          }
        : null;
    };
  }

  /**
   * Formats group total values to limit decimal places and display unit
   *
   * Uses user defined unit if one is set for given column
   * @param decimals Number of decimal places to format number to, defaults to 3
   * @returns Formatter function that returns an object containing the original numerical value, the display unit,
   *  as well a toString function that returns the formatted value
   */
  unitAggFormatter(decimals: number = 3, columnOptions: ColumnOptions, seperator: YN | string = YN.Y, showUnit: YN | string = YN.Y): aggFormatter<{ unit: Unit }> {
    return (value: number | null, { unit }, params: IAggFuncParams) => {
      let option = columnOptions[params.column.getColId()];
      let displayUnit = !!option?.displayUnit ? option.displayUnit : unit;
      let unitValue = toUnit(value, this.dataFormatter.mtUnit, displayUnit) ?? null;
      return unitValue !== null
        ? {
            amount: unitValue,
            formatted: this.dataFormatter.quantityUnitFormatter(unitValue, displayUnit, decimals, seperator, showUnit),
            unit: displayUnit,
            toString: function () {
              return this.formatted;
            },
          }
        : null;
    };
  }

  /**
   * Build an ag-grid aggregate function from three anonymous functions
   * @param mapper A function that maps all rows in the group to numbers to be passed to the aggregate function
   * it also returns any desired data that needs to be extracted from these rows
   * @param agg A function that aggregates an array of numbers, it is expected that this function will receive an array
   * of only numbners
   * @param formatter A function that formats the result of the aggregate function.  If this parameter is not defined,
   * the numerical output of the aggregate function will be used
   * @returns An ag-grid aggregate function that passes the IAggFuncParams through the three given functions and returns a formatted string
   */
  aggFunctionBuilder<T>(mapper: aggMapper<T>, agg: aggFunc<T>, formatter?: aggFormatter<T>) {
    return (params: IAggFuncParams) => {
      let { mappedValues, args, problem } = mapper(params);

      if (problem) return 'Error';

      let result = agg(mappedValues, args);

      return !!formatter ? formatter(result, args, params) : result;
    };
  }

  /**
   * Mapper to be used for unit columns
   * @param field The property key of the given quantity column
   * @param unitColumn The property key of the unit column
   * @returns An function that maps all row values to numbers and extracts the unit to be used for the total quantity
   */
  unitColumnAggMapper(field: string, unitColumn: string): aggMapper<{ unit: Unit }> {
    return (params: IAggFuncParams) => {
      let { problem, mappedValues, unit } =
        params.rowNode?.group && !params.rowNode.leafGroup ? this.__quantityAggGroupMapper(params.values) : this.__quantityAggChildMapper(params.rowNode.childrenAfterFilter ?? [], field, unitColumn);

      return { mappedValues, problem, args: { unit } };
    };
  }

  /**
   * A helper map function used for dynamic Unit Columns.
   *
   * Maps group values (total/group header values) to numbers.
   * Objects that are already displaying a unit and have associated unit data are handled here.
   *
   * If every group value the same unit, that unit is extracted as the display unit for the total value.
   *
   * If a mix of values is found, MT is used.
   *
   * @param values an array of values that are results of a previous aggregation loop
   * @returns the values array converted to numbers
   */
  public __quantityAggGroupMapper(values: any[]): {
    mappedValues: number[];
    problem: boolean;
    unit: Unit;
  } {
    let problem = false;
    let unit: Unit = null;
    let mappedValues: number[] = values.flatMap((current) => {
      if (!current && current !== 0) {
        return [];
      }
      if (typeof current === 'object') {
        if (isGridValueObject<{ unit?: Unit }>(current)) {
          let amount = current.amount;
          if (current.unit) {
            amount = toMetricTons(amount, current.unit);
            if (!unit) {
              unit = current.unit;
            } else {
              if (unit.code !== current.unit.code) {
                unit = this.dataFormatter.mtUnit;
              }
            }
          }
          return amount;
        }
        problem = true;
        return current.amount;
      }
      if (current === 'Error') {
        problem = true;
        return [];
      }
      if (typeof current === 'string') {
        current = current.replace(/\D/g, '');
        current = Number(current);
        if (isNaN(current)) {
          return [];
        }
        return current;
      }
      if (typeof current === 'number') {
        return current;
      }
      problem = true;
      return [];
    }, null);

    if (!unit) unit = this.dataFormatter.mtUnit;

    return { mappedValues, problem, unit };
  }

  /**
   * A helper map function used for dynamic Unit Columns.
   *
   * Maps child values to numbers, should be run on the
   * leaf node, (the bottom level group).  Each value is converted to MT based on the
   * given unit from that row.  If no unit is found, the value is skipped.
   *
   * If every row has the same unit, that unit is extracted as the display unit for the total value.
   *
   * If a mix of values is found, MT is used.
   *
   * @param values an array of values from bottom level rows.  They should in theory all be numbers,
   * so no unit data is extracted from the data itself, but from the associated unit column
   * @returns the values array converted to numbers
   */
  public __quantityAggChildMapper(nodes: RowNode[], field: string, unitColumn: string) {
    let problem = false;
    let unit: Unit = null;
    let mappedValues: number[] = nodes.flatMap((n: RowNode) => {
      if (!n.data) return [];
      let current = n.data[field];
      if (current !== 0 && !current) return [];

      let currentUnitTag: string | number | Unit | null = n.data[unitColumn];
      let currentUnit = currentUnitTag ? this.dataFormatter.unitFromTag(currentUnitTag) : null;

      if (!currentUnit || currentUnit.indivisible === YN.Y) return [];

      let value: number;

      if (typeof current === 'object') {
        if (isGridValueObject<{ unit?: Unit }>(current)) {
          let amount = current.amount;
          value = amount;
        } else {
          problem = true;
        }
      } else if (current === 'Error') {
        problem = true;
        return [];
      } else if (typeof current === 'string') {
        current = current.replace(/\D/g, '');
        current = Number(current);
        if (isNaN(current)) {
          problem = true;
          return [];
        }
        value = current;
      } else if (typeof current === 'number') {
        value = current;
      }

      if (!value && value !== 0) {
        problem = true;
        return [];
      }

      let valueInMT = toMetricTons(value, currentUnit);
      if (typeof valueInMT === 'number') return valueInMT;
      problem = true;
      return [];
    });
    if (!unit) unit = this.dataFormatter.mtUnit;

    return { problem, unit, mappedValues };
  }
}

function escape(code: string) {
  return code.replace(/(.)/g, '\\$1');
}

export function isGridValueObject<T = any>(data: any): data is gridValueObject<T> {
  return (
    typeof data === 'object' &&
    !!data &&
    (!!data.amount || data.amount === 0) &&
    typeof data.amount === 'number' &&
    data.formatted &&
    typeof data.formatted === 'string' &&
    data.toString &&
    typeof data.toString === 'function'
  );
}

/**
 * Creates an aggregate mapper function that maps any column to numeric values and returns the given args as
 * the extracted data.  For example, in the case of a static unit column, the values will be mapped to numbers,
 * and the static unit will be returned with the number array instead of any unit being extracted from the actual data.
 * @param args A generic object that will be passed to the given agg function and given formatter function
 * @returns The mapper function
 */
export function staticMapper<T>(args: T): aggMapper<T> {
  return (params) => {
    let problem;
    let mappedValues = params.values.flatMap((current) => {
      if (current === null) return current;
      else if (typeof current === 'object') {
        if (isGridValueObject<{}>(current)) {
          return current.amount;
        } else {
          problem = true;
          return [];
        }
      } else if (current === 'Error') {
        problem = true;
        return [];
      } else if (typeof current === 'string') {
        current = current.replace(/\D/g, '');
        let n = Number(current);
        if (isNaN(n)) {
          problem = true;
          return [];
        }
        return n;
      } else if (typeof current === 'number') {
        return current;
      }
      problem = true;
      return [];
    });
    return { problem, mappedValues, args };
  };
}

export function sumAggregate(): aggFunc<{}> {
  return (values, args) => {
    return values.reduce((prev, curr) => {
      if (typeof curr !== 'number') return prev;
      if (prev === null) return curr;
      return prev + curr;
    }, null);
  };
}

export function avgAggregate(): aggFunc<{}> {
  return (values, args) => {
    let count = 0;
    let sum = values.reduce((prev, curr) => {
      if (typeof curr !== 'number') return prev;
      count++;
      if (prev === null) {
        return curr;
      }
      return prev + curr;
    }, null);

    if (sum === null || count === 0) return null;
    return sum / count;
  };
}

export function maxAggregate(): aggFunc<{}> {
  return (values, args) => {
    return Math.max(...values) ?? null;
  };
}

export function minAggregate(): aggFunc<{}> {
  return (values, args) => {
    return Math.min(...values) ?? null;
  };
}

export function firstAggregate(): aggFunc<{}> {
  return (values, args) => {
    return values.find((v) => typeof v === 'number') ?? null;
  };
}

export function lastAggregate(): aggFunc<{}> {
  return (values, args) => {
    for (let i = values.length - 1; i >= 0; i--) {
      if (typeof values[i] === 'number') return values[i];
    }
    return null;
  };
}

export type aggFormatter<T> = (value: number, args: T, params: IAggFuncParams) => gridValueObject<any>;
export type gridValueObject<T> = { toString: () => string; amount: number; formatted: string } & T;
export type aggMapper<T> = (params: IAggFuncParams) => {
  mappedValues: any[];
  problem: boolean;
  args: T;
};
export type aggFunc<T> = (values: number[], args: T) => number | null;

export type ColumnOption = {
  groupBy?: ListDateGroupBehavior;
  backgroundColor?: string | false;
  negativeRed?: YN;
  pastDatesRed?: YN;
  displayUnit?: Unit;
  displayCurrency?: Currency;
};

export type aggType = '_sum' | '_avg' | '_max' | '_min';

export type ColumnOptions = { [key: string]: ColumnOption };

export type CustomAggFunction = {
  [key: string]: { [key in aggType]?: (params: IAggFuncParams) => any };
};
