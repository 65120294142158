import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GetContextMenuItemsParams, GridOptions, RowDoubleClickedEvent, RowNode, ValueFormatterParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { lastValueFrom, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { defaultComplexGrid, getContextMenuItems, gridDateFormatter } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { QueryFilters } from 'src/lib/generics';
import { endpointAuthorizationSubscription, endpointsAuthorized, getToday } from 'src/lib/helperFunctions';
import { Contact, PartialContact, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { CreateTaskRequest, Task, UpdateTaskRequest } from 'src/lib/newBackendTypes/task';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { ILazyContent } from '../lazy-card/lazy-card.component';
import { TaskComponent, TaskForm } from '../task/task.component';

@UntilDestroy()
@Component({
  selector: 'entity-tasks',
  templateUrl: './entity-tasks.component.html',
  styleUrls: ['./entity-tasks.component.scss'],
})
export class EntityTasksComponent implements OnInit, OnDestroy, ILazyContent {
  @Input()
  entityType: SourceEntityType;

  @Input()
  entityId?: number;

  @Input()
  showSpinner: boolean = false;

  popup = false;

  gridOptions: GridOptions;

  tasks: Task[];

  authorized: endpointsAuthorized;
  get createTaskAuthorized() {
    return this.authorized[endpoints.createTask];
  }
  get listTasksAuthorized() {
    return this.authorized[endpoints.listTasks];
  }

  user: Contact;

  loadingTasks;

  willDeload;

  constructor(
    private api: ThalosApiService,
    private spinnerService: SpinnerService,
    private selectorService: SelectorPopupService,
    private dialogService: DialogService,
    store: Store,
    private delegate: DelegateService
  ) {
    endpointAuthorizationSubscription(store, this);

    this.user = store.snapshot((state) => state.user.user);

    this.tasks = [];
    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'id'),
      domLayout: 'normal',
      getRowStyle: this.getRowStyle(),
      columnDefs: [
        { field: 'taskTitle', headerName: 'Title', width: 550, cellStyle: this.taskTitleStyle() },
        { field: 'taskText', headerName: 'Description', width: 550, cellStyle: this.taskTitleStyle() },
        { field: 'taskPriority', headerName: 'Priority', width: 115 },
        { field: 'dueDate', valueFormatter: gridDateFormatter(), cellStyle: this.dueDateStyle() },
        { field: 'accomplishedDate', valueFormatter: gridDateFormatter(), width: 160 },
        {
          field: 'taskAssignees.assignee.displayName',
          headerName: 'Assignees',
          width: 200,
          valueFormatter: this.assigneeFormatter(),
        },
      ],
      getContextMenuItems: getContextMenuItems(this.updateTaskMenuItem(), this.markCompleteTaskMenuItem()),
      onRowDoubleClicked: this.rowDoubleClicked(),
      sideBar: false,
    };

    this.loadingTasks = false;
    this.willDeload = false;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityId']) {
      if (this.entityId) {
        this.fetchTasks();
      } else {
        this.tasks = [];
      }
    }
  }

  ngOnDestroy() {}

  getRowStyle() {
    return (params?: { node?: RowNode }) => {
      if (!params?.node?.data) {
        return {};
      } else {
        const task: Task = params.node.data;
        let backgroundColor: string;
        if (task.accomplished === YN.Y) backgroundColor = '#0F9';
        return { 'background-color': backgroundColor };
      }
    };
  }

  fetchTasks() {
    if (!this.listTasksAuthorized) {
      this.tasks = [];
      return;
    }
    const filters: QueryFilters<Task> = {
      sourceId: this.entityType,
      sourceKey1: this.entityId,
    };

    const relations = ['taskAssignees', 'taskAssignees.assignee', 'creator'];

    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Tasks', 0, false, !this.showSpinner);
    this.loadingTasks = true;
    this.api.rpc<ListResponse<Task>>(endpoints.listTasks, { filters, relations }, { list: [], count: 0 }).subscribe((res) => {
      this.spinnerService.completeRequest(rid);
      this.loadingTasks = false;
      this.tasks = res.list;
    });
  }

  onGridReady(_event) {
    if (!!this.popup) {
      this.gridOptions.api.setDomLayout('normal');
    }
  }

  clickCreateTask() {
    if (this.authorized[endpoints.createTask]) {
      this.createTask();
    }
  }

  updateTaskMenuItem() {
    return (params: GetContextMenuItemsParams) => {
      if (!this.authorized[endpoints.updateTask]) return;
      if (params?.node?.data) {
        const task: Task = params.node.data;

        return {
          name: 'Edit',
          action: () => this.updateTask(task),
        };
      }
      return null;
    };
  }

  markCompleteTaskMenuItem() {
    return (params: GetContextMenuItemsParams) => {
      if (!this.authorized[endpoints.updateTask]) return;
      if (params?.node?.data) {
        const task: Task = params.node.data;

        return {
          name: task.accomplished === YN.Y ? 'Mark Incomplete' : 'Mark Complete',
          action: () => this.markTaskCompletion(task, task.accomplished === YN.Y ? YN.N : YN.Y),
        };
      }
      return null;
    };
  }

  createTask() {
    this.selectorService
      .openForm<Partial<TaskForm>, TaskComponent>(TaskComponent, {
        title: 'New Task',
        width: 300,
        maxWidth: 600,
        prefillValue: { assignees: [this.user] },
        submitButtonText: 'Save',
      })
      .subscribe((res) => {
        if (res === 'Close') {
          return;
        } else {
          const request: CreateTaskRequest = {
            taskTitle: res.taskTitle,
            taskText: res.taskText,
            sourceId: this.entityType,
            sourceKey1: this.entityId,
            dueDate: res.dueDate,
            assignees: res.assignees.map((c) => c.id),
            sourceClientId: null,
            taskPriority: res.taskPriority,
          };
          const rid = this.spinnerService.startRequest('Creating Task');
          this.api.rpc<Task>(endpoints.createTask, request, null).subscribe((res) => {
            this.spinnerService.completeRequest(rid);

            if (!!res) {
              this.dialogService.open({
                title: 'Task Created',
                content: 'Task has been successfully created',
              });
            }
            this.fetchTasks();
          });
        }
      });
  }

  updateTask(task: Task) {
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Task');
    this.api
      .rpc<Task>(endpoints.getTask, { filters: { id: task.id } }, null)
      .pipe(
        switchMap(async (task) => {
          const missingContactIds: number[] = [];

          if (task.updatedBy && !task.updator) missingContactIds.push(task.updatedBy);
          if (task.createdBy && !task.creator) missingContactIds.push(task.createdBy);
          if (missingContactIds.length > 0) {
            const contacts = await lastValueFrom(this.api.rpc<PartialContact[]>(endpoints.getContactNames, { contactIds: missingContactIds }, []));
            if (task.updatedBy && !task.updator) task.updator = contacts.find((c) => c.id === task.updatedBy) as Contact;
            if (task.createdBy && !task.creator) task.creator = contacts.find((c) => c.id === task.createdBy) as Contact;
          }

          return task;
        }),
        tap(() => {
          this.spinnerService.completeRequest(rid);
        }),
        switchMap((task) => {
          if (!task) return of(null);

          return this.selectorService.openForm<TaskForm, TaskComponent>(TaskComponent, {
            title: 'Edit Task',
            width: 300,
            maxWidth: 600,
            prefillValue: {
              ...task,
              assignees: (task.taskAssignees || []).flatMap((a) => a.assignee ?? []),
            },
            submitButtonText: 'Save',
          });
        })
      )
      .subscribe((res) => {
        if (res === 'Close') {
          return;
        } else {
          const request: UpdateTaskRequest = {
            id: task.id,
          };
          if (res.accomplished !== task.accomplished) request.accomplished = res.accomplished;
          if (res.taskTitle !== task.taskTitle) request.taskTitle = res.taskTitle;
          if (res.taskText !== task.taskText) request.taskText = res.taskText;
          if (res.dueDate !== task.dueDate) request.dueDate = res.dueDate;
          if (
            !_.isEqual(
              (task.taskAssignees || []).map((a) => a.assigneeAlfCode),
              res.assignees.map((c) => c.id)
            )
          ) {
            request.assignees = res.assignees.map((c) => c.id);
          }
          if (res.taskPriority !== task.taskPriority) request.taskPriority = res.taskPriority;

          const rid = this.spinnerService.startRequest('Updating Task');
          this.api.rpc<Task>(endpoints.updateTask, request, null).subscribe((res) => {
            this.spinnerService.completeRequest(rid);

            if (!!res) {
              this.dialogService.open({
                title: 'Task Updated',
                content: 'Task has been successfully updated',
              });
              this.fetchTasks();
            }
          });
        }
      });
  }

  markTaskCompletion(task: Task, value: YN) {
    const request: UpdateTaskRequest = {
      id: task.id,
      accomplished: value,
    };
    const rid = this.spinnerService.startRequest('Updating Task');
    this.api.rpc<Task>(endpoints.updateTask, request, null).subscribe((res) => {
      this.spinnerService.completeRequest(rid);

      if (!!res) {
        this.dialogService.open({
          title: 'Task Updated',
          content: 'Task has been successfully updated',
        });
        this.fetchTasks();
      }
    });
  }

  assigneeFormatter() {
    return (params: ValueFormatterParams) => {
      const task: Task = params?.data;
      if (!task) return '';
      const assignees = task.taskAssignees;
      if (!assignees || assignees.length === 0) return '';

      return assignees.flatMap((a) => a.assignee?.displayName ?? []).join(', ');
    };
  }

  dueDateStyle() {
    return (params: any) => {
      const task: Task = params?.node?.data;

      if (!task) return null;
      else return task.accomplished === YN.N && task.dueDate && new Date(task.dueDate) < getToday() ? { 'font-weight': 'bold', color: '#FF3333' } : { 'font-weight': 'normal', color: 'black' };
    };
  }

  taskTitleStyle() {
    return (params: any) => {
      const task: Task = params?.node?.data;

      if (!task) return null;
      else if (task.accomplished === YN.N && task.dueDate && new Date(task.dueDate) < getToday()) return { 'font-weight': 'bold', color: '#FF3333' };
      else return task.accomplished === YN.Y ? { 'font-weight': 'normal', color: 'black' } : { 'font-weight': 'normal', color: 'black' };
    };
  }

  rowDoubleClicked() {
    return (params: RowDoubleClickedEvent) => {
      if (!this.authorized[endpoints.updateTask]) return;
      const task: Task = params?.data;

      if (!!task) this.updateTask(task);
    };
  }
}
