import { Injectable } from '@angular/core';
import { ContractForm } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { toMetricTons } from 'src/lib/unitConversions';
import { PhysicalContract, PhysicalDraft } from 'src/lib/newBackendTypes';
import { CommonDataService } from './common-data.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessLogicService {
  constructor(private commonDataService: CommonDataService) {}

  getNumberOfLoadsRange(pc: ContractForm | PhysicalDraft | PhysicalContract): {
    max: number | null;
    min: number | null;
  } {
    //calculate mt/load
    if (!pc.quantityUnitId) return { max: null, min: null };
    let units = this.commonDataService.staticFilteredUnits.getValue();
    if (!units) return { max: null, min: null };
    let quantityUnit = units.find((u) => u.unitId === pc.quantityUnitId);
    if (!quantityUnit) return { max: null, min: null };

    let total = 0;
    if (pc.lines)
      for (let line of pc.lines) {
        total += toMetricTons(line.quantity || 0, quantityUnit);
      }
    if (total === 0) {
      return { max: null, min: null };
    }

    let min = Math.max(Math.floor(total / 25), 1);
    let max = Math.max(Math.ceil(total / 15), 1);

    return { max, min };
  }
}
