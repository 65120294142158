import { Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'textarea-wrapper',
  templateUrl: './textarea-wrapper.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => TextAreaWrapperComponent) }],
})
export class TextAreaWrapperComponent extends FormElementComponent implements OnInit {
  @ViewChild('textarea', { static: false })
  textarea: ElementRef;

  @Input()
  showCounter?: boolean;

  @Input()
  maxlength?: number;

  @Input()
  rows?: number;

  charactersCount: number;
  counter: string;

  ngOnInit(): void {
    if (this.showCounter && this.maxlength) {
      this.charactersCount = this.value ? this.value.length : 0;
      this.counter = `${this.charactersCount}/${this.maxlength}`;
    }
  }

  onValueChange(ev: string): void {
    super.onValueChange(ev);
    if (this.showCounter && this.maxlength) {
      this.charactersCount = ev?.length ?? 0;
      this.counter = `${this.charactersCount}/${this.maxlength}`;
    }
  }

  focus() {
    if (this.textarea) {
      setTimeout(() => {
        this.textarea.nativeElement.focus();
      });
    }
  }
}
