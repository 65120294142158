import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { DiscrepanciesToCreateAndDelete } from 'src/lib/flex/forms/linkUnlinkDiscrepancies';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { BaseDiscrepancy, DiscrepancyListView, DiscrepancyTypeOptions } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'quality-control-link-unlink-discrepancies',
  templateUrl: './link-unlink-discrepancies.component.html',
})
export class LinkUnlinkDiscrepanciesComponent implements OnInit, ModalFormComponent<DiscrepanciesToCreateAndDelete, BaseDiscrepancy> {
  form: TypedFormGroup<LinkUnlinkDiscrepanciesForm>;
  popup = true;
  popupObservable: Observable<LinkUnlinkDiscrepanciesForm>;
  popupObserver: Observer<LinkUnlinkDiscrepanciesForm>;

  exisitingRelatedDiscrepancies: BaseDiscrepancy[] = [];
  discrepanciesListDropdown: DropdownConfig<DiscrepancyListView>;

  discrepancyId: number;
  constructor() {
    this.form = new TypedFormGroup<LinkUnlinkDiscrepanciesForm>({
      relatedDiscrepancies: new UntypedFormControl(null),
    });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  ngOnInit(): void {
    this.discrepanciesListDropdown = new DropdownConfig<DiscrepancyListView>({
      labelField: 'discrepancyId',
      valueField: 'discrepancyId',
      listProcedure: endpoints.listDiscrepancies,
      labelTransform: (entry) =>
        `Discrepancy: ${entry.discrepancyId} - Type: ${DiscrepancyTypeOptions.find((item) => item.value === entry.discrepancyType)?.label ?? 'Unknown'} - Booking: ${entry.freightBooking}`,
      take: 20,
    });
  }

  prefillForm(discrepancy: BaseDiscrepancy) {
    this.discrepancyId = discrepancy.id;
    this.exisitingRelatedDiscrepancies = discrepancy.relatedDiscrepancies;
    this.form.patchValue({ relatedDiscrepancies: this.exisitingRelatedDiscrepancies });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): DiscrepanciesToCreateAndDelete {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    const formVal = this.form.value;
    const toCreate = {
      id: this.discrepancyId,
      relatedDiscrepanciesIds: formVal.relatedDiscrepancies
        .filter((discrepancy: DiscrepancyListView) => !this.exisitingRelatedDiscrepancies.some((existing) => existing.id === discrepancy.discrepancyId))
        .map((discrepancy: DiscrepancyListView) => Number(discrepancy.discrepancyId)),
    };

    const toDelete = {
      id: this.discrepancyId,
      relatedDiscrepanciesIds: this.exisitingRelatedDiscrepancies
        .filter((existing) => !formVal.relatedDiscrepancies.some((discrepancy: DiscrepancyListView) => discrepancy.discrepancyId === existing.id))
        .map((existing) => existing.id),
    };

    return { toCreate, toDelete };
  }
}

type LinkUnlinkDiscrepanciesForm = Pick<BaseDiscrepancy, 'relatedDiscrepancies'>;
