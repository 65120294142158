import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { ClauseMissingInformationEmailRequest, Contact, ContactMethod } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'trading-clause-missing-information-email',
  templateUrl: './clause-missing-information-email.component.html',
})
export class ClauseMissingInformationEmailComponent implements OnInit, ModalFormComponent<ClauseMissingInformationEmailRequest, ClauseEmailPrefill> {
  form: TypedFormGroup<ClauseMissingInformationEmailRequest>;
  popup = true;

  traderId: number | null = null;
  traderEmails: ContactMethod[];
  showCounter = true;
  maxlength = 255;

  constructor(private api: ThalosApiService) {
    this.form = new TypedFormGroup<ClauseMissingInformationEmailRequest>({
      contractNumber: new UntypedFormControl(''),
      emailTo: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {}

  async getTraderEmailsDropdown() {
    const trader = await this.api.run<Contact>(endpoints.getContact, { filters: { id: this.traderId } }, null);
    const traderEmails = trader.methods;
    this.traderEmails = traderEmails;
  }

  prefillForm(data: ClauseEmailPrefill) {
    if (data) {
      this.traderId = data.traderId;
      this.getTraderEmailsDropdown();
      this.form.patchValue({
        contractNumber: data.contractNumber,
      });
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): ClauseMissingInformationEmailRequest {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;
    let formVal = this.form.value;
    return {
      contractNumber: formVal.contractNumber,
      emailTo: formVal.emailTo,
      message: formVal.message,
    };
  }
}

export type ClauseEmailPrefill = {
  contractNumber: number;
  traderId: number;
};
