import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Observer, of, switchMap } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModalFormComponent, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { VesselComponent } from 'src/app/shared/vessel/vessel.component';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListBookingsPrefill } from 'src/lib/flex/forms/updateBooking';
import { cobUpdateForm, cobUpdateRequest } from 'src/lib/flex/forms/updateBookingCob';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Booking, CreateVesselRequest, Vessel } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'booking-update-cob',
  templateUrl: './booking-update-cob.component.html',
  styleUrls: ['./booking-update-cob.component.scss'],
})
export class BookingUpdateCobComponent implements ModalFormComponent<cobUpdateForm, ListBookingsPrefill> {
  form: TypedFormGroup<cobUpdateForm>;
  popup = true;
  popupObservable: Observable<cobUpdateRequest>;
  popupObserver: Observer<cobUpdateRequest>;

  bookings: Booking[];

  vesselConfig: DropdownConfig<Vessel>;
  constructor(private selectorService: SelectorPopupService, private spinnerService: SpinnerService, private api: ThalosApiService, private notificationService: NotificationService) {
    this.vesselConfig = new DropdownConfig({
      listProcedure: endpoints.listVessels,
      valueField: 'shipKey',
      labelField: 'shipName',
      take: 20,
    });
    this.form = new TypedFormGroup<cobUpdateForm>({
      originalETD: new UntypedFormControl(),
      placeOfReceiptETD: new UntypedFormControl(),
      loadingPortETD: new UntypedFormControl(),
      transhipmentETD: new UntypedFormControl(),
      unloadingPortETA: new UntypedFormControl(),
      finalDestinationETA: new UntypedFormControl(),
      vessel: new UntypedFormControl(),
      voyage: new UntypedFormControl(),
    });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  prefillForm(data: ListBookingsPrefill) {
    if (data.bookingResponses.length === 1) {
      this.form.patchValue(data.bookingResponses[0]);
    }
    this.bookings = data.bookingResponses;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit(): cobUpdateForm {
    markFormGroupTouched(this.form);
    if (this.form.invalid) return;

    let formVal = this.form.value;
    return formVal;
  }

  openCreateVessel() {
    let rid;
    this.selectorService
      .openForm<CreateVesselRequest, VesselComponent>(VesselComponent, { title: 'New Vessel' })
      .pipe(
        switchMap((res): Observable<Vessel | 'Close'> => {
          if (res !== 'Close') {
            rid = this.spinnerService.startRequest('Saving Vessel');
            return this.api.rpc<Vessel>(endpoints.createVessel, res, null);
          } else return of(res);
        })
      )
      .subscribe((res) => {
        if (res === 'Close') return;
        this.spinnerService.completeRequest(rid);
        if (!!res) {
          if (!this.form.value.vessel) {
            this.form.patchValue({ vessel: res });
          }
          this.notificationService.show('Vessel created', 'success');
        } else {
          this.notificationService.show('Vessel creation failed', 'error');
        }
      });
  }
}
