import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ManageQPForm, ManageQPPrefill } from 'src/lib/flex/forms/manageQP';
import { PhysicalPricingType, QPStatuses } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-manage-qp',
  templateUrl: './manage-qp.component.html',
})
export class ManageQPComponent implements ModalFormComponent<ManageQPForm, ManageQPPrefill> {
  form: TypedFormGroup<ManageQPForm>;
  popup = true;
  showCounter = true;
  maxlength = 255;

  qpStatuses = QPStatuses;

  constructor() {
    this.form = new TypedFormGroup<ManageQPForm>({
      id: new UntypedFormControl(null),
      qpStatus: new UntypedFormControl(null, Validators.required),
      notes: new UntypedFormControl(null),
    });
  }

  prefillForm(data: ManageQPPrefill) {
    if (!!data) {
      let qpStatus: number;
      let notes: string;

      if (data.pricing && data.pricing.length > 0) {
        for (const pricing of data.pricing) {
          if (pricing.pricingType === PhysicalPricingType.PRICE) {
            qpStatus = pricing.qpStatus;
            notes = pricing.notes;
          }
        }
      }

      this.form.patchValue({
        id: data.id,
        qpStatus,
        notes,
      });
    }
  }

  allowSubmit() {
    return this.form.valid;
  }

  submit(): ManageQPForm {
    const formVal = this.form.value;
    const regexp = /^ *$/;
    return {
      id: formVal.id,
      qpStatus: formVal.qpStatus,
      notes: regexp.test(formVal.notes) ? null : formVal.notes,
    };
  }
}
