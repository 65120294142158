import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

export type ValidationStatusPanelParams = IStatusPanelParams & {
  errors: string[];
  readonly: boolean;
  warnings?: string[];
};
@Component({
  selector: 'validation-status-panel',
  template: `
    <ng-template [ngIf]="!params.readonly" [ngIfElse]="readonly">
      <div *ngIf="params.errors.length > 0" class="ag-status-name-value c-red">
        <span>{{ params.errors[0] }}</span
        >&nbsp; <i *ngIf="params.errors.length > 1">({{ params.errors.length - 1 }} more)</i>&nbsp;
        <i [ngClass]="'fas fa-exclamation-triangle'"></i>
      </div>
      <div *ngIf="params.warnings && params.warnings.length > 0" class="ag-status-name-value c-warning">
        <span>{{ params.warnings[0] }}</span
        >&nbsp;
        <i [ngClass]="'fas fa-exclamation-triangle'"></i>
      </div>
      <div *ngIf="params.errors.length < 1 && params.warnings && params.warnings.length < 1" class="ag-status-name-value c-green">
        <i [ngClass]="'fas fa-check-circle'"></i>
      </div>
    </ng-template>
    <ng-template #readonly>
      <div *ngIf="params.errors.length < 1 && params.warnings && params.warnings.length < 1" class="ag-status-name-value">No changes allowed <i [ngClass]="'fas fa-lock'"></i></div>
    </ng-template>
  `,
})
export class ValidationStatusPanel implements IStatusPanelAngularComp {
  params: ValidationStatusPanelParams;

  agInit(params: ValidationStatusPanelParams): void {
    this.params = params;
  }

  setReadonly(readonly: boolean) {
    this.params.readonly = readonly;
  }
}
