import { Item } from './item';

export enum CommonQualityTypes {
  SCRAP = 22075224,
  FINISHED = 22075225,
  PRIMARY = 22075226,
  CONCENTRATES = 22079870,
  EQUIPMENT = 22079871,
  RSI = 22079874,
  SEMI_FINISHED = 22079875,
}

export class QualityType {
  qualityTypeKey: number;
  qualityTypeName: string;
  humanFood: string;
  articleText?: Item[];
}
