import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GetContextMenuItemsParams, GridOptions, MenuItemDef, RowNode, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { EntityLookupService } from 'src/app/core/services/entity-lookup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { getContextMenuItems, gotoMenu, gotoMenuItem } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { counterpartyDropdown, entityIdFormat } from 'src/lib/commonTypes';
import { endpointAuthorizationSubscription, endpointsAuthorized, fromBradyDate } from 'src/lib/helperFunctions';
import { IRoutable } from 'src/lib/isRoutable';
import { Contact, PhysicalContract, ShipmentPeriodType, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { POLineFinderRequest, POLineSelection } from 'src/lib/newBackendTypes/poLineSelection';
import { toLocalDate } from 'src/lib/toUTCDate';
import { TypedFormGroup } from 'src/lib/typedForms';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { BookingLookupComponent } from '../booking-lookup/booking-lookup.component';

@UntilDestroy()
@Component({
  selector: 'logistics-contract-review',
  templateUrl: './contract-review.component.html',
  styleUrls: ['./contract-review.component.scss'],
})
export class ContractReviewComponent implements OnInit, OnDestroy, IRoutable {
  readonly = false;
  gridOptions: GridOptions;

  data: POLineSelection[] = [];

  filterForm: TypedFormGroup<LineFilters>;

  counterpartyDropdown = counterpartyDropdown(YN.Y);
  preSelectedLines?: POLineSelectionRow[];
  lockedLineIds?: number[];

  popup: boolean = false;
  popupSubsciption: Observable<POLineSelectionRow[]>;
  private _popupSubscription: BehaviorSubject<POLineSelectionRow[]>;

  intFormat = entityIdFormat();

  authorized: endpointsAuthorized;

  get createBookingAuthorized() {
    return this.entityLookupService.entityPathExists('create', SourceEntityType.FREIGHT_BOOKING_KEY);
  }
  get addToBookingAuthorized() {
    return (
      this.entityLookupService.entityPathExists('get', SourceEntityType.FREIGHT_BOOKING_KEY) &&
      this.authorized[endpoints.updateBooking] &&
      this.authorized[endpoints.updateContainer] &&
      this.authorized[endpoints.updateShipment]
    );
  }

  constructor(
    private api: ThalosApiService,
    private spinnerService: SpinnerService,
    private commonDataService: CommonDataService,
    store: Store,
    private router: Router,
    private dialogService: DialogService,
    private entityLookupService: EntityLookupService,
    private valueFormatterService: DataFormattingService,
    delegate: DelegateService
  ) {
    this.filterForm = new TypedFormGroup<LineFilters>({
      numbers: new UntypedFormControl(null, this.numbersValidator()),
      counterparty: new UntypedFormControl(),
    });

    this._popupSubscription = new BehaviorSubject(null);
    this.popupSubsciption = this._popupSubscription.pipe(filter((res) => !!res));

    this.gridOptions = {
      rowSelection: 'multiple',
      getRowId: (params) => params.data.lineId,
      defaultColDef: { width: 100, resizable: true, menuTabs: [] },
      columnDefs: [
        {
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: (params) => !this.readonly,
          headerCheckboxSelectionFilteredOnly: true,
          width: 50,
          cellStyle: (params) => {
            return this.readonly ? { 'pointer-events': 'none' } : null;
          },
        },
        { field: 'contractNumber', headerName: 'Contract' },
        { field: 'lineNumber', headerName: 'Line', width: 60 },
        { field: 'counterpartyDisplayName', headerName: 'Counterparty', width: 150 },
        { field: 'traderDisplayName', headerName: 'Trader', width: 125 },
        {
          field: 'lineQuantity',
          width: 140,
          cellStyle: { 'text-align': 'right' },
          headerName: 'Quantity',
          valueFormatter: this.totalQuantityFormatter(),
        },
        {
          field: 'availableQuantity',
          width: 215,
          cellStyle: { 'text-align': 'right' },
          headerName: 'Available',
          valueFormatter: this.availableQuantityFormatter(),
        },
        {
          field: 'shippedQuantity',
          cellStyle: { 'text-align': 'right' },
          headerName: 'Shipped',
          valueFormatter: this.valueFormatterService.gridUnitFormatter<POLineSelectionRow>('quantityUnitId'),
        },
        { field: 'customerItemName', headerName: 'Item', width: 175 },
        { field: 'destinationName', headerName: 'Destination', width: 175 },
        { field: 'originCountryName', headerName: 'Origin', width: 175 },
        {
          field: 'incotermId',
          headerName: 'Incoterm',
          width: 200,
          valueFormatter: this.valueFormatterService.gridIncotermFormatter('incotermPlaceName'),
        },
        {
          field: 'price',
          width: 140,
          valueFormatter: this.valueFormatterService.gridPriceCurrencyPerUnitFormatter('quantityUnitId', 'priceCurrencyId'),
          cellStyle: { 'text-align': 'right' },
        },
        { field: 'Shipment', width: 250, valueGetter: this.shipmentValueGetter() },
        { field: 'containerTypes', width: 250 },
      ],
      getContextMenuItems: getContextMenuItems(this.getContextMenuItems(), gotoMenu(gotoMenuItem(delegate, 'Contract', 'contractId', SourceEntityType.CONTRACT_KEY, 'get', 'contractNumber', false))),
      onSelectionChanged: this.onSelectionChanged(),
      isRowSelectable: this.isRowSelectable(),
    };

    endpointAuthorizationSubscription(store, this);
  }

  ngOnDestroy(): void {
    this._popupSubscription.complete();
    this._popupSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  onGridReady(event) {}

  setPreSelectedLines(lines: POLineSelectionRow[], lockedLines?: number[]) {
    this.lockedLineIds = lockedLines;
    this.preSelectedLines = lines;
    this.gridOptions.onRowDataChanged = () => {
      for (let line of lines) {
        const row = this.gridOptions.api.getRowNode(`${line.lineId}`);
        if (row) row.selectThisNode(true);
      }
      this.gridOptions.onRowDataChanged = null;
    };

    for (let line of lines) {
      if (!this.data.some((l) => line.lineId === l.lineId)) {
        this.data.unshift(line);
      }
    }
  }

  gridDateFormatter() {
    return (row: ValueFormatterParams) => {
      if (!row.value) return '';
      return this._dateFormatter(row.value);
    };
  }

  _dateFormatter(val) {
    return Array.isArray(val)
      ? val
          .filter((v) => !!v)
          .map((v) => toLocalDate(v).toLocaleDateString())
          .join(', ')
      : toLocalDate(val).toLocaleDateString();
  }

  shipmentValueGetter() {
    return (row: ValueGetterParams) => {
      if (!row.data) return '';
      let val: POLineSelectionRow = row.data;

      let type = val.shipmentPeriodType === ShipmentPeriodType.DELIVERY ? 'Delivery' : 'Shipment';
      let start = val.shipmentPeriodStart ? this._dateFormatter(fromBradyDate(val.shipmentPeriodStart)) : 'N/A';
      let end = val.shipmentPeriodEnd ? this._dateFormatter(fromBradyDate(val.shipmentPeriodEnd)) : 'N/A';

      return `${type} - ${start} to ${end}`;
    };
  }

  totalQuantityFormatter() {
    return (row: ValueFormatterParams) => {
      let quantityAndUnit = this.valueFormatterService.gridUnitFormatter('quantityUnitId')(row);

      let line: POLineSelectionRow = row.data;
      if (!line) return quantityAndUnit;

      let tolerance = line.tolerance;
      if (!tolerance) return quantityAndUnit;

      return `${quantityAndUnit} ± ${tolerance}%`;
    };
  }

  onSelectionChanged() {
    return (event: SelectionChangedEvent) => {
      let lines: POLineSelectionRow[] = this.gridOptions.api.getSelectedRows();
      if (this.preSelectedLines && this.lockedLineIds) {
        for (let line of this.preSelectedLines) {
          if (this.lockedLineIds.some((id) => id === line.lineId) && !lines.some((l) => l.lineId === line.lineId)) {
            lines.unshift(line);
          }
        }
      }
      this._popupSubscription.next(lines);
    };
  }

  numbersValidator() {
    return (control: AbstractControl) => {
      let val: number | null = control.value;
      if (!val) return null;

      if (isNaN(val)) {
        return { custom: 'Only numeric characters allowed' };
      }

      return null;
    };
  }

  newBooking() {
    if (this.popup) return;
    let lines: POLineSelectionRow[] = this.gridOptions.api.getSelectedRows();

    if (lines.length === 0) {
      this.dialogService.open({
        title: 'No lines selected',
        content: 'You must select at least one line to create a Freight Booking',
      });
      return;
    }

    this.router.navigate(['portal/logistics/bookings/new'], {
      state: { 'logistics-booking-lines': lines },
      queryParams: { 'line-ids': lines.map((l) => l.lineId) },
    });
  }

  fetchContractLines() {
    let filters: POLineFinderRequest = {};
    let value = this.filterForm.value;

    let valid = false;

    if (value.counterparty) {
      filters.counterpartyId = value.counterparty.id;
      valid = true;
    }
    if (value.numbers) {
      filters.contractNumber = value.numbers;
      valid = true;
    }

    valid = valid && this.filterForm.valid;
    if (!valid) return;

    let rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Contracts');
    combineLatest([
      this.commonDataService.staticFilteredUnits,
      this.commonDataService.staticCurrencies,
      this.api.rpc<ListResponse<POLineSelection>>(endpoints.poLineFinder, { filters, relations: [{ path: 'currency' }] }, { list: [], count: 0 }).pipe(
        tap((res) => {
          this.spinnerService.completeRequest(rid);
        }),
        map((res) => {
          return res.list;
        })
      ),
    ])
      .pipe(
        untilDestroyed(this),
        map(([units, currencies, res]) => {
          return res.map((pol) => {
            const toleranceQuantity = pol.lineQuantity * (pol.tolerance * 0.01);
            const availableQuantityMax = pol.availableQuantity;

            const availableQuantity = this.valueFormatterService.roundQuantity(availableQuantityMax - toleranceQuantity, pol.quantityUnitId);
            const currency = pol.priceCurrencyId ? currencies.find((c) => c.id === pol.priceCurrencyId) : null;
            const quantityUnit = pol.quantityUnitId ? units.find((c) => c.unitId === pol.quantityUnitId) : null;
            return { ...pol, quantityUnit, currency, availableQuantityMax, availableQuantity };
          });
        })
      )
      .subscribe((lines: POLineSelectionRow[]) => {
        let selectedLines: POLineSelectionRow[];
        if (this.data !== null) {
          selectedLines = this.gridOptions.api.getSelectedRows();
        }

        if (!!selectedLines) {
          this.gridOptions.onRowDataChanged = () => {
            for (let line of selectedLines) {
              const row = this.gridOptions.api.getRowNode(`${line.lineId}`);
              if (row) row.selectThisNode(true);
            }
            this.gridOptions.onRowDataChanged = null;
          };

          for (let line of selectedLines) {
            let index = lines.findIndex((l) => line.lineId === l.lineId);
            if (index >= 0) lines.splice(index, 1);
            lines.unshift(line);
          }
          if (this.preSelectedLines) {
            for (let line of this.preSelectedLines) {
              let index = lines.findIndex((l) => line.lineId === l.lineId);
              if (index >= 0) lines.splice(index, 1);
              lines.unshift(line);
            }
          }
        }

        this.data = lines;
      });
  }

  getContextMenuItems() {
    return (params: GetContextMenuItemsParams) => {
      let menu: (MenuItemDef | string)[] = [];
      let line: POLineSelectionRow = params?.node?.data;

      if (!!line) {
        if (/Win/.test(navigator.platform)) {
          if (!!line.contractNumber) {
            menu.push({
              name: 'Open Contract Folder',
              action: () => {
                window.open(`linkeddox://${line.contractNumber}`);
              },
            });
          }
        }
      }

      return menu;
    };
  }

  addToBooking() {
    if (this.popup) return;
    let lines: POLineSelectionRow[] = this.gridOptions.api.getSelectedRows();

    if (lines.length === 0) {
      this.dialogService.open({
        title: 'No lines selected',
        content: 'You must select at least one line to add them to a Freight Booking',
      });
      return;
    }
    let dialog = this.dialogService.open({
      title: 'Booking Lookup',
      content: BookingLookupComponent,
    });
    let component: BookingLookupComponent = dialog.content.instance;
    component.selectedBooking.subscribe((booking) => {
      dialog.close();
      this.router.navigate(['/portal/logistics/bookings/', booking.id], {
        state: { entity: booking, 'logistics-booking-lines': lines },
        queryParams: { 'line-ids': lines.map((l) => l.lineId) },
      });
    });
  }

  isRowSelectable() {
    return (node: RowNode): boolean => {
      let data: POLineSelection = node.data;
      if (data.shipmentLock === YN.Y) return false;
      if (data && this.lockedLineIds && this.lockedLineIds.some((id) => id === data.lineId)) {
        return false;
      }
      return true;
    };
  }

  availableQuantityFormatter() {
    return (params: ValueFormatterParams) => {
      let data: POLineSelectionRow = params.data;
      if (!data) return '';

      let availableWeight = data.availableQuantity;
      let max = data.availableQuantityMax;

      if (!availableWeight && availableWeight !== 0) return '';
      let unit = params.data.quantityUnitId;

      let str = `${this.valueFormatterService.quantityUnitFormatter(availableWeight, unit)}`;
      if (!!max || max === 0) {
        str += ` (Max ${this.valueFormatterService.quantityUnitFormatter(max, unit)})`;
      }

      return str;
    };
  }

  getTabTitle() {
    return 'PO Review';
  }
}

export type POLineSelectionRow = POLineSelection &
  Pick<PhysicalContract, 'quantityUnit' | 'currency' | 'incoterm'> & {
    availableQuantityMax?: number;
  };

type LineFilters = {
  counterparty: Contact;
  numbers: number;
};
