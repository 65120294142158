import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'blank-form',
  templateUrl: './blank-form.component.html',
  styleUrls: ['./blank-form.component.scss'],
})
export class BlankFormComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  icon?: string;

  constructor() {}

  ngOnInit(): void {}
}
