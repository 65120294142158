<div class="row" [formGroup]="form">
  <vertical-field [name]="'Name'" class="col-12">
    <input-wrapper [ignoreReadonlyMode]="true" [formControlName]="'name'"></input-wrapper>
  </vertical-field>
  <vertical-field [name]="'Category'" class="col-12">
    <dropdown-wrapper [ignoreReadonlyMode]="true" [dropdownConfig]="qualityDropdown" [formControlName]="'qualityType'"></dropdown-wrapper>
  </vertical-field>
  <vertical-field *ngIf="type === 'royce'" [name]="'Reporting Group'" class="col-12">
    <dropdown-wrapper [ignoreReadonlyMode]="true" [dropdownConfig]="reportingGroupDropdown" [formControlName]="'reportingGroup'"></dropdown-wrapper>
  </vertical-field>
  <vertical-field *ngIf="type === 'royce'" [name]="'Analytic Group'" class="col-12">
    <dropdown-wrapper [ignoreReadonlyMode]="true" [dropdownConfig]="analyticGroupDropdown" [formControlName]="'analyticGroup'"></dropdown-wrapper>
  </vertical-field>
  <vertical-field [name]="'Metal Unit Percentage'" class="col-12">
    <numerictextbox-wrapper [ignoreReadonlyMode]="true" [formControlName]="'metalUnitPercentage'" [fieldType]="4"></numerictextbox-wrapper>
  </vertical-field>
</div>
