import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import * as go from 'gojs';
import { combineLatest, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { goKey } from 'src/lib/goJs.key';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { Contact, UserAuthorizedCompanies } from 'src/lib/newBackendTypes';
import { ApprovalTypeApprover } from 'src/lib/newBackendTypes/approvalTypeApprover';
import { UserGroup } from 'src/lib/newBackendTypes/userGroup';
import { AppRoutingModule } from './app-routing.module';
import { FrameComponent } from './core/components/frame/frame.component';
import { CoreModule } from './core/core.module';
import { SET_USER, SET_USER_APPROVAL_TYPES, SET_USER_AUTHORIZED_COMPANIES, SET_USER_GROUPS } from './core/reducers/actions';
import { LoginAction, RoleObject } from './core/reducers/user';
import { AuthenticationService } from './core/services/authentication.service';
import { GlobalHttpInterceptor } from './core/services/global-http-interceptor';
import { Store } from './core/services/store.service';
import { ThalosApiService } from './core/services/thalos-api.service';

go.Diagram.licenseKey = goKey;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: 'https://login.microsoftonline.com/' + environment.msalConfig.tenant,
      redirectUri: environment.msalConfig.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map([
    [environment.apiHost, [environment.msalConfig.clientId + '/.default']],
    ['https://graph.microsoft.com/v1.0/sites', ['Sites.Read.All']],
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

@UntilDestroy()
@NgModule({
  declarations: [],
  imports: [BrowserModule, BrowserAnimationsModule, CoreModule, AppRoutingModule, HttpClientModule, MsalModule, TreeListModule, ProgressBarModule],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [FrameComponent, MsalRedirectComponent],
})
export class AppModule implements OnDestroy {
  authorized: endpointsAuthorized;

  constructor(private store: Store, private api: ThalosApiService, public router: Router, private authenticationService: AuthenticationService) {
    this.onInit();
  }

  ngOnDestroy() {}

  private onInit() {
    combineLatest([endpointAuthorizationSubscription(this.store, this), this.authenticationService.userChangeEvent]).subscribe(([_, account]) => {
      if (account) this.onUserChange();
    });
  }

  private async onUserChange(): Promise<void> {
    let userRoles: RoleObject[] = [
      {
        label: 'default',
        roleName: 'default',
        scopeValues: [],
      },
    ];

    let selectedRoleIndex = 0;
    this.store.dispatch(new LoginAction({ email: '', userRoles: userRoles, selectedRoleIndex }));

    let meResponse = await lastValueFrom(
      this.api.rpcWithResponse<Contact>(endpoints.me, {}, null, {
        hideErrorPopup: true,
        blockRedirect: true,
      })
    );
    let userGroups = [];
    if (!meResponse) return;
    if (meResponse.status !== 200) return;

    const me = meResponse.body;
    if (me === null) {
      return;
    }
    let userGroupResponse = await lastValueFrom(
      this.api.rpcWithResponse<UserGroup[]>(endpoints.listUserGroups, {}, null, {
        hideErrorPopup: true,
        blockRedirect: true,
      })
    );
    if (userGroupResponse.status === 200) {
      userGroups = userGroupResponse.body ?? [];
    }

    let approvalTypes;
    let authorizedCompanies: UserAuthorizedCompanies[] = [];
    if (!!me) {
      authorizedCompanies = me.authorizedCompaniesByContact;
      approvalTypes = await lastValueFrom(
        this.api
          .rpc<ListResponse<ApprovalTypeApprover>>(endpoints.listApprovalTypeApprovers, { filters: { approverId: me.id } }, { list: [], count: 0 }, { hideErrorPopup: true, blockRedirect: true })
          .pipe(
            map((res) => res.list),
            map((list) => list.map((approvalTypeApprover) => approvalTypeApprover.approvalTypeId))
          )
      );
    } else {
      approvalTypes = [];
    }
    this.store.dispatch({ type: SET_USER_APPROVAL_TYPES, payload: approvalTypes });

    this.store.dispatch({ type: SET_USER, payload: me });
    this.store.dispatch({ type: SET_USER_GROUPS, payload: userGroups });
    this.store.dispatch({ type: SET_USER_AUTHORIZED_COMPANIES, payload: authorizedCompanies });
  }
}
