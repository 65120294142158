import { City } from './city';
import { Unit } from './unit';
import { PropertyDocument } from './propertyDocument';

export class WeightTicket {
  id: number;
  chunkKey: number;
  date: string;
  placeId: number;
  grossWeight?: number;
  netWeight?: number;
  unitId: number;
  source: WeightTicketSource;
  place?: City;
  unit?: Unit;
  shipment?: PropertyDocument;
}

export type CreateWeightTicketRequest = {
  date: Date;
  placeId: number;
  chunkKey: number;
  unitId: number;
  grossWeight: number;
  netWeight: number;
  source: WeightTicketSource;
};

export enum WeightTicketSource {
  PROPERTY_DOCUMENT = 1,
  USER_CREATED = 2,
  WEIGHT_REPORT = 3,
  SUPPLIER = 4,
  CUSTOMER = 5,
  PORT = 6,
  THIRD_PARTY = 7,
}

export const WeightTicketSourceOptions = [
  { value: WeightTicketSource.PROPERTY_DOCUMENT, label: 'Supplier Docs' },
  { value: WeightTicketSource.USER_CREATED, label: 'Weight Ticket' },
  { value: WeightTicketSource.WEIGHT_REPORT, label: 'Final Weight' },
  { value: WeightTicketSource.SUPPLIER, label: 'Supplier' },
  { value: WeightTicketSource.CUSTOMER, label: 'Customer' },
  { value: WeightTicketSource.PORT, label: 'Port' },
  { value: WeightTicketSource.THIRD_PARTY, label: 'Third Party' },
];

export const WeightTicketSourceUserOptions = [
  { value: WeightTicketSource.SUPPLIER, label: 'Supplier' },
  { value: WeightTicketSource.CUSTOMER, label: 'Customer' },
  { value: WeightTicketSource.PORT, label: 'Port' },
  { value: WeightTicketSource.THIRD_PARTY, label: 'Third Party' },
];
