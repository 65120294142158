import { from, lastValueFrom, switchMap } from 'rxjs';
import { PremiumFixationComponent, PremiumFixationForm } from 'src/app/+modules/+hedging/premium-fixation/premium-fixation.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListResponse } from 'src/lib/ListResponse';
import { ContractType, SourceEntityType } from 'src/lib/newBackendTypes';
import { CreatePremiumFixationListRequest, PremiumFixationList, PremiumFixationSummary, UpdatePremiumFixationListRequest } from 'src/lib/newBackendTypes/PremiumFixation';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const modifyPremiumFixationPrefill: prefillCallback<PremiumFixationSummary> = (delegate, elementId: number) => {
  const api = delegate.getService('api');

  return from(
    new Promise<PremiumFixationSummary | string>((resolve, reject) => {
      (async () => {
        const summary = await lastValueFrom(api.rpc<ListResponse<PremiumFixationSummary>>(endpoints.listPremiumFixationSummary, { filters: { elementId } }, { count: 0, list: [] }));
        if (summary.list.length < 1) return resolve('No Premium information found for this contract line');
        if (summary.list.length > 1) return resolve('This contract line has more than one premium fixation');

        resolve(summary.list[0]);
      })();
    })
  );
};

const openPremiumFixationForm: openFormCallback<PremiumFixationSummary, PremiumFixationForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<PremiumFixationForm, PremiumFixationComponent, PremiumFixationSummary>(PremiumFixationComponent, {
    title: `Premium Fixation: ${prefill.contractType == ContractType.PURCHASE ? 'MP' : 'MS'} ${prefill.contractNumber} / ${prefill.elementNumber}`,
    maxWidth: '400px',
    prefillValue: prefill,
  });
};

const submitPremiumFixation: submitFormCallback<PremiumFixationSummary, PremiumFixationForm> = (delegate, elementId, form, prefill) => {
  const api = delegate.getService('api');

  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PremiumFixationList | null>((resolve, reject) => {
      (async () => {
        try {
          if (prefill.premiumFixationId > 0) {
            const request: UpdatePremiumFixationListRequest = {
              id: prefill.premiumFixationId,
              fixationDate: form.fixationDate,
              marketPrice: form.marketPrice,
              quantity: form.quantity,
            };
            return resolve(await lastValueFrom(api.rpc<PremiumFixationList>(endpoints.updatePremiumFixation, request, null)));
          } else {
            const request: CreatePremiumFixationListRequest = {
              contractId: prefill.contractId,
              elementNumber: prefill.elementNumber,
              fixationDate: form.fixationDate,
              marketPrice: form.marketPrice,
              quantity: form.quantity,
            };
            return resolve(await lastValueFrom(api.rpc<PremiumFixationList>(endpoints.createPremiumFixation, request, null)));
          }
        } catch (err) {
          return resolve(err);
        }
      })();
    })
  ).pipe(
    switchMap((res) => {
      if (!!res && !!res.id) {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Premium Fixation successfully ${prefill.premiumFixationId > 0 ? 'updated' : 'created'}</div>`);
      } else {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unable to save Premium Fixation.<br/>${res instanceof Error ? res.message : 'Unknown error'}</div>`);
      }
    })
  );
};

export const modifyPremiumFixationPreset: DynamicFormConstant<PremiumFixationSummary, PremiumFixationForm> = {
  allowMultipleRows: true,
  title: 'Modify Premium Fixation',
  value: DynamicFormType.UPSERT_PREMIUM_FIXATION,
  label: 'Modify Premium Fixation',
  entityType: SourceEntityType.CONTRACT_LINE_ID,
  getPrefill: modifyPremiumFixationPrefill,
  openForm: openPremiumFixationForm,
  submitForm: submitPremiumFixation,
  endpoints: [endpoints.listPremiumFixationSummary, endpoints.updatePremiumFixation, endpoints.createPremiumFixation],
};
