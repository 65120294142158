import { Country } from './country';
import { Product } from './product';

export class TemplateHeader {
  tplKey: number;
  companyId: number;
  tplCode: string;
  tplName: string | null;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  lastOperationType: number | null;
  productId: number | null;
  countryId: number | null;
  product?: Product;
  country?: Country;
}
