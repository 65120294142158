import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuEvent, MenuItem } from '@progress/kendo-angular-menu';
import { DriveItem, DriveItemVersion } from 'microsoft-graph';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharePointService } from 'src/app/core/services/share-point-service.service';

@Component({
  selector: 'sharepoint-version-menu',
  templateUrl: './sharepoint-version-menu.component.html',
})
export class SharepointVersionMenuComponent implements OnInit {
  @Input()
  driveItem: DriveItem;

  @Output()
  downloadVersion: EventEmitter<any> = new EventEmitter();

  data$: BehaviorSubject<any>;

  constructor(private sharepoint: SharePointService) {
    this.data$ = new BehaviorSubject([]);
  }

  ngOnInit(): void {}

  onOpen = (event) => {
    this.sharepoint
      .getVersionsOfFile(this.driveItem)
      .pipe(map((res) => res.value))
      .subscribe((res) => {
        if (!!res) {
          if (res.length === 0)
            this.data$.next([
              {
                text: 'No versions found, file may have been deleted',
                data: null,
              },
            ]);
          else
            this.data$.next(
              res
                .sort((v1, v2) => {
                  return new Date(v2.lastModifiedDateTime).getTime() - new Date(v1.lastModifiedDateTime).getTime();
                })
                .map((version: DriveItemVersion, i: number) => {
                  let text = moment(version.lastModifiedDateTime).fromNow();
                  if (i === 0) text = `${text} (Current)`;
                  else if (version.id) text = `${text} (v ${version.id})`;

                  let menuItem: MenuItem = {
                    text,
                    data: { ...version },
                  };
                  return menuItem;
                })
            );
        }
      });
  };

  selectVersion(event: ContextMenuEvent) {
    if (event.index === '0') this.downloadVersion.emit(null);
    else if (event && event.item && event.item.data) {
      this.downloadVersion.emit(event.item.data?.id);
    }
  }
}
