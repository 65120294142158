import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'simple-dropdown-prompt',
  templateUrl: './simple-dropdown-prompt.component.html',
})
export class SimpleDropdownPromptComponent<T> implements OnInit {
  public data: T[];
  public label: string;
  public labelField: keyof T;
  public valueField: keyof T;
  value: T;

  constructor() {
    this.value = null;
  }

  ngOnInit(): void {}
}
