import { Container } from './container';
import { ContractLine } from './contractLine';
import { DraftLine } from './draftLine';
import { Booking } from './freightBooking';
import { PlaceMaxWeights } from './placeMaxWeights';
import { ServiceOrder } from './serviceOrder';

export class ContainerType {
  id: number;
  transportType: number | null;
  name: string;
  modifiedBy: string | null;
  modifyDate: number | null;
  versionNb: number | null;
  contractLines?: ContractLine[];
  draftLines?: DraftLine[];
  draftLinesAsFirstType?: DraftLine[];
  freightBookings?: Booking[];
  serviceOrders?: ServiceOrder[];
  containers?: Container[];
  methodMaxWeights?: PlaceMaxWeights[];
}

export type ContainerMaxWeight = Pick<ContainerType, 'id' | 'name'> & {
  maxWeight?: number;
};
