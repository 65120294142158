import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { _fa, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { ListResponse } from 'src/lib/ListResponse';
import { EntryType, OpenItem, SourceEntityType } from 'src/lib/newBackendTypes';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';
import { getUniqueArray } from 'src/lib/helperFunctions';
import { QueryFilters } from 'src/lib/generics';

const createMatchingPaymentPrefill: prefillCallback<CreateMatchingPaymentPrefill> = (delegate, id, column, data) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const matchableIds = Array.isArray(id) ? id : [id];
      const entryTypes = data ? data.map((item) => item.entryType || item.ENTRY_TYPE).filter((entryType) => entryType !== undefined) : undefined;
      const uniqueEntryTypes = entryTypes && entryTypes.length > 0 ? getUniqueArray(entryTypes) : undefined;
      const filters: QueryFilters<OpenItem> = { entryId: matchableIds, entryType: uniqueEntryTypes ?? undefined };
      const matchableItemsResponse = await api.run<ListResponse<OpenItem>>(endpoints.listOpenItems, { filters });

      const items = matchableItemsResponse.list;

      return { items };
    })()
  );
};

const createMatchingPaymentCheck: checkPrefillCallback<CreateMatchingPaymentPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  const entryIdsToMatch = new Set(prefill.items);
  const firstEntry = prefill.items[0];
  if (entryIdsToMatch.size < 2) return of('Unable to match payments: you need more than one payment entries to create a payment matching');
  if (prefill.items.length !== entryIdsToMatch.size) return of('Unable to match payments: some entries are not available');
  const companyId = firstEntry.companyId;
  const counterpartyId = firstEntry.counterpartyId;
  let amountSum = 0;

  const matchingEntries: number[] = [];
  for (const paymentEntry of prefill.items) {
    if (paymentEntry.companyId !== companyId) return of('Unable to match payments: all entries to match should belong to the same company');
    if (paymentEntry.counterpartyId !== counterpartyId) return of('Unable to match payments: all entries to match should belong to the same counterparty');

    const amount = paymentEntry.balanceAmount * -1;
    matchingEntries.push(amount);

    amountSum += amount;
  }
  amountSum = Math.round(amountSum);
  if (amountSum !== 0) return of('Unable to match payments: the sum of the amounts in the entries to match should be zero');

  return of(true);
};

const createMatchingPaymentForm: createFormCallback<CreateMatchingPaymentPrefill, MatchPaymentsRequest> = (delegate, id, prefill) => {
  const defaultTitle = prefill.items && prefill.items.length > 0 ? `Matching - ${prefill.items[0].counterpartyName}` : '';
  return [
    {
      type: 'Label',
      text: `Matching Payments: ${prefill.items ? 'ID: ' + prefill.items.map((item) => item.entryReference).join(', ') : 'Unknown'}`,
    },
    [
      {
        type: 'Date',
        field: 'valueDate',
        label: 'Value Date',
        validator: [Validators.required, bradyDateValidator()],
        startingValue: new Date(),
      },
      {
        type: 'Date',
        field: 'entryDate',
        label: 'Entry Date',
        validator: [Validators.required, bradyDateValidator()],
        startingValue: new Date(),
      },
    ],
    [_fe('paymentTitle', 'Payment Title', 'Text', defaultTitle, [Validators.required])],
    [
      _fa<MatchPaymentsRequest, 'entryIds'>(
        'entryIds',
        'Entries',
        [[_fe('type', 'Type', 'Text', null, [Validators.required]), _fe('id', 'ID', { data: id, valueField: 'id', labelField: 'ID' }, null, id.length <= 1 ? Validators.required : undefined)]],
        []
      ),
    ],
  ];
};

const createMatchingPaymentCallback: submitFormCallback<CreateMatchingPaymentPrefill, MatchPaymentsRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader>((resolve, reject) => {
      (async () => {
        let entryIds: MatchableEntryIds[] = [];
        const matchableItems = Array.isArray(prefill.items) ? prefill.items : [prefill.items];
        let requestIds: MatchableEntryIds;
        for (const entry of matchableItems) {
          requestIds = {
            type: entry.entryType,
            id: entry.entryId,
          };

          entryIds.push(requestIds);
        }

        const request: MatchPaymentsRequest = {
          paymentTitle: result.paymentTitle,
          valueDate: result.valueDate,
          entryDate: result.entryDate,
          entryIds: entryIds,
        };
        const matchPayment = await api.run<PaymentHeader>(endpoints.matchPayments, request, null);
        if (matchPayment) return resolve(matchPayment);
        return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Matching Payment successfully created: \n${res.paymentTitle} (${res.documentReference})</div>`);
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the matching payment was created and try again if necessary.</div>`);
      })
  );
};

export const createMatchingPaymentPreset: DynamicFormConstant<CreateMatchingPaymentPrefill, MatchPaymentsRequest> = {
  allowMultipleRows: true,
  checkPrefill: createMatchingPaymentCheck,
  createForm: createMatchingPaymentForm,
  entityType: SourceEntityType.PAYABLE_ITEM_KEY,
  getPrefill: createMatchingPaymentPrefill,
  label: 'Create Matching Payment',
  submitForm: createMatchingPaymentCallback,
  title: 'Create Matching Payment',
  value: DynamicFormType.CREATE_MATCHING_PAYMENT,
  endpoints: [endpoints.matchPayments, endpoints.listOpenItems],
  width: 600,
};

export type MatchPaymentsRequest = {
  paymentTitle: string;
  valueDate: Date;
  entryDate: Date;
  entryIds: MatchableEntryIds[];
};
export type MatchableEntryIds = { type: EntryType; id: number };

export type CreateMatchingPaymentPrefill = { items: OpenItem[] };
