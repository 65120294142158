<div class="d-flex" style="height: 100%; flex-direction: column">
  <div style="width: 100%; flex-grow: 1">
    <div *ngIf="!presentations">Loading</div>
    <ag-grid-angular *ngIf="presentations" style="width: 100%; height: 100%" class="ag-theme-balham" [rowData]="presentations" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div class="d-flex" style="flex-direction: row-reverse" *ngIf="createPresentationAuthorized">
    <button type="button" kendoButton (click)="clickCreatePresentation()">Create Presentation</button>
  </div>
</div>
