import { MenuEffect } from './MenuItem';
import { DelegateService } from '../app/core/services/delegate-service.service';
import { RateFinderComponent } from '../app/shared/rate-finder/rate-finder.component';
import { DateCalculatorComponent } from 'src/app/shared/date-calculator/date-calculator.component';
import { formatDate, getTodayUTC } from './helperFunctions';
import { MathCalculatorComponent } from 'src/app/shared/math-calculator/math-calculator.component';
import { MwtiMarketPriceComponent } from 'src/app/+modules/+hedging/mwti-market-price/mwti-market-price.component';
import { endpoints } from './apiEndpoints';
import { map } from 'rxjs';
import { CommonCurrencies, CommonUnits, DynamiteMarketPrice } from './newBackendTypes';

//Utility items in menu

export function OceanRateFinderMenuItem(): MenuEffect {
  return (delegate: DelegateService) => {
    const selector = delegate.getService('selector');

    return selector.openStatic(RateFinderComponent, {
      title: 'Ocean Rate Finder',
      initializer: (c) => {
        c.readonly = true;
        c.popup = true;
      },
      width: '95%',
      height: '90%',
    });
  };
}

export function DateCalculator(): MenuEffect {
  return (delegate: DelegateService) => {
    const selector = delegate.getService('selector');

    return selector.openStatic(DateCalculatorComponent, {
      title: 'Date Calculator',
      initializer: (c) => {
        c.form.patchValue({ fromDate: getTodayUTC() });
      },
      width: 200,
      height: 270,
    });
  };
}

export function Calculator(): MenuEffect {
  return (delegate: DelegateService) => {
    const selector = delegate.getService('selector');

    return selector.openStatic(MathCalculatorComponent, {
      title: 'Expression Calculator',
      initializer: (c) => {
        c.outputType = null;
      },
      width: 400,
      height: 200,
    });
  };
}

export function MWTIMarketPriceMenuItem(): MenuEffect {
  return (delegate: DelegateService) => {
    const selector = delegate.getService('selector');
    const api = delegate.getService('api');
    const spinner = delegate.getService('spinner');
    const prompt = delegate.getService('prompt');
    const formatter = delegate.getService('dataFormatter');

    return selector
      .openForm(MwtiMarketPriceComponent, {
        title: 'Create MWTI Market Price',
        initializer: (c) => {
          c.popup = true;
        },
        width: 300,
      })
      .pipe(
        map(async (result) => {
          if (result === 'Close') return;
          const rid = spinner.startRequest('Saving MWTI Market Price');
          await api
            .run<DynamiteMarketPrice>(endpoints.createMWTIMarketPrice, result)
            .then((res) => {
              spinner.completeRequest(rid);
              return prompt.htmlDialog(
                'Success',
                `<div style="white-space: pre">MWTI Market Price ${formatter.amountCurrencyPerUnit(res.Bid, CommonCurrencies.USC, CommonUnits.LB, 4)}  and Value Date ${formatDate(
                  res.EffectiveDate
                )} successfully created.</div>`
              );
            })
            .catch(() => {
              spinner.completeRequest(rid);
            });
        })
      );
  };
}
