import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalStatus } from 'src/lib/newBackendTypes/approval';

@Pipe({
  name: 'approvalCode',
})
export class ApprovalCodePipe implements PipeTransform {
  transform(value: ApprovalStatus): string {
    switch (value) {
      case ApprovalStatus.Y:
        return 'Approved';
      case ApprovalStatus.N:
        return 'Denied';
      case ApprovalStatus.YN:
        return 'Review Pending';
      case ApprovalStatus.NY:
        return 'Approval Pending';
      default:
        return '';
    }
  }
}
