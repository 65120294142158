import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[highlightOnDragover]',
})
export class HighlightOnDragoverDirective {
  @HostBinding('class.drag-hover')
  dragHover: boolean;

  constructor() {
    this.dragHover = false;
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: Event) {
    if ($event && ($event as any).dataTransfer && ($event as any).dataTransfer.dropEffect !== 'copy') {
      ($event as any).dataTransfer.dropEffect = 'copy';
    }

    $event.preventDefault();
    $event.stopPropagation();
    this.dragHover = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dragHover = false;
  }

  @HostListener('drop', ['$event'])
  onDrop($event: Event) {
    $event.preventDefault();
    this.dragHover = false;
  }
}
