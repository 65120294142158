<div>
  <div class="row">
    <div style="padding: 0px 6px 8px">Shipment Creation Date: {{ valueDate | date : 'shortDate' }}</div>
  </div>
  <ag-grid-angular
    style="width: 100%"
    [ngStyle]="{ height: gridOptions?.domLayout === 'autoHeight' ? 'auto' : '325px' }"
    class="ag-theme-balham"
    [rowData]="movements"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>

  <form [formGroup]="form" *ngIf="createAuthorized">
    <div class="row">
      <vertical-field name="Warehouse" class="col-xl-2 col-lg-3 col-md-6 col-12">
        <dropdown-wrapper [readonly]="form.value.movementType === -1" [formControlName]="'warehouse'" [dropdownConfig]="warehouseDropdown"></dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Date" class="col-xl-2 col-lg-3 col-md-6 col-12">
        <datepicker-wrapper [formControlName]="'movementDate'"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Type" class="col-xl-1 col-lg-2 col-md-3 col-12">
        <simple-dropdown [formControlName]="'movementType'" [data]="warehouseMovementTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Notes" class="col-xl-2 col-lg-3 col-md-6 col-12">
        <input-wrapper [formControlName]="'notes'"></input-wrapper>
      </vertical-field>
      <vertical-field class="col-xl-2 col-lg-3 col-md-6 col-12">
        <button style="width: 100px; flex-grow: 0" kendoButton type="submit" (click)="clickCreateWarehouseMovement()">Submit</button>
      </vertical-field>
    </div>
  </form>
</div>
