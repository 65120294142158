<div class="grid-container grid-container--h100 d-flex">
  <div class="grid-container__grid">
    <ag-grid-angular
      class="ag-grid-container ag-grid-container--h300 ag-theme-balham"
      [rowData]="data"
      [gridOptions]="gridOptions"
      (cellValueChanged)="onCellValueChanged($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
