import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuItem } from 'src/lib';
import { MiscFeature } from 'src/lib/feature';
import { SourceEntityType } from 'src/lib/newBackendTypes';

@Injectable({
  providedIn: 'root',
})
export class EntityLookupService {
  private entityMenuMap: {
    list: Map<SourceEntityType, MenuItem>;
    get: Map<SourceEntityType, MenuItem>;
    create: Map<SourceEntityType, MenuItem>;
  };
  private flexViewMap: Map<number, MenuItem>;
  private featureMap: Map<MiscFeature, MenuItem>;

  constructor(private router: Router) {
    this.entityMenuMap = { list: new Map(), get: new Map(), create: new Map() };
    this.flexViewMap = new Map();
    this.featureMap = new Map();
  }

  entityPathExists(type: 'get' | 'list' | 'create', entityType: SourceEntityType, feature?: MiscFeature) {
    return this.entityMenuMap[type] && this.entityMenuMap[type].has(entityType) && !!this.entityMenuMap[type].get(entityType).link;
  }

  getEntityExists(type: SourceEntityType) {
    return this.entityMenuMap.get.has(type);
  }

  gotoEntity(type: SourceEntityType, id: any, operation?: 'get' | 'list' | 'create', newTab?: true);
  gotoEntity(type: SourceEntityType, id: any, operation: 'get' | 'list' | 'create', extras?: NavigationExtras);
  gotoEntity(type: SourceEntityType, id: any, operation: 'get' | 'list' | 'create' = 'get', extras?: NavigationExtras | true) {
    let route = this.entityMenuMap[operation].get(type);
    if (route && route.link) {
      if (extras === true) {
        let link = `${route.link}/${id}`;
        window.open(link);
      } else {
        this.router.navigate(operation === 'get' ? [route.link, id] : [route.link], extras);
      }
    }
  }

  getLink(type: SourceEntityType, operation: 'get' | 'list' | 'create' = 'get') {
    let route = this.entityMenuMap[operation].get(type);
    return route?.link;
  }
  setEntityRoute(type: 'get' | 'list' | 'create', entityType: SourceEntityType, menuItem: MenuItem) {
    this.entityMenuMap[type].set(entityType, menuItem);
  }

  flexViewExists(id: number) {
    return this.flexViewMap.has(id);
  }

  setFlexViewRoute(id: number, menuItem: MenuItem) {
    this.flexViewMap.set(id, menuItem);
  }

  featureExists(feature: MiscFeature) {
    return this.featureMap.has(feature) && !!this.featureMap.get(feature).link;
  }

  setFeatureRoute(feature: MiscFeature, menuItem: MenuItem) {
    return this.featureMap.set(feature, menuItem);
  }

  getFeatureRoute(feature: MiscFeature) {
    return this.featureMap.get(feature);
  }
}
