import { endpoints } from 'src/lib/apiEndpoints';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { Advance } from 'src/lib/newBackendTypes/advance';
import { simpleConfirmation } from '../flexDynamicForm';
import { advancePrefill } from './printAdvance';
import { DynamicFormConstant, DynamicFormType, submitFormCallback } from './types';
import { updateAdvancePrefillCheck } from './updateAdvance';

export const deleteAdvanceCallback: submitFormCallback<Advance> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');

  return api.rpc<Advance>(endpoints.deleteAdvance, { filters: { id } }, null, {
    blockRedirect: true,
  });
};

export const deleteAdvancePreset: DynamicFormConstant<Advance> = {
  allowMultipleRows: false,
  checkPrefill: updateAdvancePrefillCheck,
  getPrefill: advancePrefill,
  openForm: simpleConfirmation('Delete Advance', 'Are you sure you wish to delete this advance?', {
    confirmText: 'Delete',
  }),
  endpoints: [endpoints.getAdvance, endpoints.deleteAdvance],
  entityType: SourceEntityType.ADVANCE_KEY,
  label: 'Delete Advance',
  value: DynamicFormType.DELETE_ADVANCE,
  width: 300,
  submitForm: deleteAdvanceCallback,
};
