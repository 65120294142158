import { InvoiceHeader } from './invoice';
import { ServiceOrder } from './serviceOrder';

export class ServiceOrderHistory {
  serviceOrderId!: number;
  shipmentId!: number;
  voucherKey!: number;
  quantity!: number | null;
  amount!: number | null;
  baseAmount!: number | null;
  modifiedBy!: string | null;
  modifyDate!: Date | null;
  completelyInvoice!: string | null;
  serviceOrder?: ServiceOrder;
  voucher?: InvoiceHeader;
}
