import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { FormState } from 'src/app/core/reducers/form';
import { Store } from 'src/app/core/services/store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StoreSubscription } from 'src/lib';
import { SET_READONLY_MODE } from 'src/app/core/reducers/actions';

@UntilDestroy()
@Directive({
  selector: '[hideIfReadonlyMode]',
})
export class HideIfReadonlyModeDirective implements OnDestroy {
  storeSub: StoreSubscription<any>;

  constructor(el: ElementRef, store: Store) {
    let original;
    this.storeSub = store.subscribe((store) => store.form, [SET_READONLY_MODE]);
    this.storeSub.$.pipe(untilDestroyed(this)).subscribe((form: FormState) => {
      if (form.readonlyMode) {
        original = el.nativeElement.style.display ?? 'inherit';
        el.nativeElement.style.display = 'none';
      } else {
        if (original !== undefined) {
          el.nativeElement.style.display = original;
        }
      }
    });
  }

  ngOnDestroy() {
    this.storeSub.unsubscribe();
  }
}
