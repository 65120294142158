import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { FormElementConfig, _fe } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { ChunkType, PropertyDocument, SourceEntityType } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, createFormCallback, prefillCallback, submitFormCallback } from './types';
import _ from 'lodash';

const reverseInventoryWriteOffPrefill: prefillCallback<ReverseInventoryWriteOffPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const shipmentsResponse = await api.run<ListResponse<PropertyDocument>>(endpoints.listShipments, { filters: { id: Array.isArray(id) ? id : [id] } }, null);
      return { shipments: shipmentsResponse.list };
    })()
  );
};

const reverseInventoryWriteOffCheck: checkPrefillCallback<ReverseInventoryWriteOffPrefill> = (delegate, id, prefill) => {
  if (!(prefill && prefill.shipments.length > 0)) return of(false);
  for (const shipment of prefill.shipments) {
    if (shipment.status !== ChunkType.WRITTEN_OFF) return of(`Shipment ${shipment.id} is not written off.`);
  }
  return of(true);
};

const reverseInventoryWriteOffForm: createFormCallback<ReverseInventoryWriteOffPrefill, ReverseInventoryWriteOffForm> = (delegate, id, prefill) => {
  const form: (FormElementConfig<ReverseInventoryWriteOffForm> | FormElementConfig<ReverseInventoryWriteOffForm>[])[] = [
    {
      type: 'Label',
      text: "Note: This method will reverse the WriteOff Invoice generated and return the Shipment to it's original warehouse at the provided Value Date",
    },
    {
      type: 'Label',
      text: `Shipment(s) ID(s): ${prefill.shipments ? prefill.shipments.map((s: PropertyDocument) => s.id).join(', ') : 'Unknown'}`,
    },
    {
      type: 'Date',
      field: 'valueDate',
      label: 'Value Date',
      validator: [Validators.required],
      startingValue: new Date(),
    },
    _fe('comment', 'Comment', 'TextArea', '', [Validators.required]),
  ];
  return form;
};

const submitReverseInventoryWriteOff: submitFormCallback<ReverseInventoryWriteOffPrefill, ReverseInventoryWriteOffForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  const router = delegate.getService('router');

  return from(
    new Promise<ReverseInventoryWriteOffResponse[] | null>((resolve, reject) => {
      (async () => {
        const invoicesInfo: ReverseInventoryWriteOffResponse[] = [];
        for (const shipment of prefill.shipments) {
          const request: ReverseInventoryWriteOffRequest = {
            valueDate: result.valueDate,
            comment: result.comment,
            shipmentId: shipment.id,
          };

          try {
            invoicesInfo.push(await api.run<ReverseInventoryWriteOffResponse>(endpoints.reverseInventoryWriteOff, request, null));
          } catch (err) {
            return reject(err);
          }
        }
        return resolve(invoicesInfo);
      })();
    })
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const invoicesInfo = res.filter((invoiceInfo) => !_.isNil(invoiceInfo));
          // Checks if invoicesInfo has more than one response, so it shows a list of the invoices created per shipment
          if (invoicesInfo.length > 0) {
            return prompt
              .htmlDialog(
                'Success',
                `<div style="white-space: pre">Inventory unwritten off successfully reversed: \n\n${invoicesInfo
                  .map((invoiceInfo) => `- Invoice ${invoiceInfo.invoiceNumber} from Shipment ID ${invoiceInfo.shipmentId}`)
                  .join(`\n`)}</div>`
              )
              .subscribe((result) => {
                if (result) router.navigate([router.url]);
              });
          }
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the Inventory was Unwrite Off was successful and try again if necessary.</div>`);
      })
  );
};

export const reverseInventoryWriteOffPreset: DynamicFormConstant<ReverseInventoryWriteOffPrefill, ReverseInventoryWriteOffForm> = {
  allowMultipleRows: true,
  checkPrefill: reverseInventoryWriteOffCheck,
  createForm: reverseInventoryWriteOffForm,
  entityType: SourceEntityType.CHUNK_KEY,
  getPrefill: reverseInventoryWriteOffPrefill,
  label: 'Reverse Inventory Write Off',
  submitForm: submitReverseInventoryWriteOff,
  title: 'Reverse Inventory Write Off',
  value: DynamicFormType.REVERSE_INVENTORY_WRITE_OFF,
  endpoints: [endpoints.listShipments, endpoints.reverseInventoryWriteOff],
  width: 400,
};

export type ReverseInventoryWriteOffPrefill = {
  shipments: Subset<PropertyDocument, 'id' | 'status'>[];
};
export type ReverseInventoryWriteOffForm = Omit<ReverseInventoryWriteOffRequest, 'shipmentId'>;
export type ReverseInventoryWriteOffRequest = {
  valueDate: Date;
  comment: string;
  shipmentId: number;
};
export type ReverseInventoryWriteOffResponse = {
  invoiceId: number;
  invoiceNumber: number;
  shipmentId: string;
};
