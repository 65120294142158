import { from, of } from 'rxjs';
import { NetPositionJournalComponent } from 'src/app/+modules/+risk-management/net-position-journal/net-position-journal.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { NetPositionHeader, NetPositionLines, Product, SourceEntityType } from 'src/lib/newBackendTypes';
import { CreateNetPositionRequest } from 'src/lib/newBackendTypes/netPositionEntries';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { Subset } from 'src/lib/generics';

const updateNetPositionJournalPrefill: prefillCallback<NetPositionHeader> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const netPositionLine = await api.run<NetPositionLines>(endpoints.getNetPositionLine, { filters: { id } });
      const netPositionHeader = await api.run<NetPositionHeader>(endpoints.getNetPositionOperation, { filters: { id: netPositionLine.headerId } });
      return netPositionHeader;
    })()
  );
};

export const updateNetPositionJournalCheck: checkPrefillCallback<NetPositionHeader> = (delegate, id, prefill) => {
  if (!prefill) return of('Unable to update Net Position Journal: Net Position Journal not found');
  return of(true);
};

const updateNetPositionJournalForm: openFormCallback<NetPositionJournalPrefill, NetPositionJournalForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<NetPositionJournalForm, NetPositionJournalComponent, NetPositionJournalPrefill>(NetPositionJournalComponent, {
    title: `Update Net Position journal`,
    prefillValue: prefill,
    height: '90%',
    width: '90%',
  });
};

const submitUpdateNetPositionJournal: submitFormCallback<NetPositionJournalPrefill, NetPositionJournalForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  return from(
    new Promise<NetPositionHeader>((resolve, reject) => {
      (async () => {
        const reverseRequest = {
          headerId: prefill.id,
          lines: result.lines,
          description: result.description,
          invalidDate: new Date(),
          setReverseLinesInvalid: true,
          valueDate: result.valueDate,
          companyId: result.companyId,
        };
        const resultJournal = await api.run<NetPositionHeader>(endpoints.reverseNPJournalByLines, reverseRequest, null);
        if (resultJournal) return resolve(resultJournal);
        return resolve(null);
      })();
    })
      .then((res) => {
        if (res) return prompt.htmlDialog('Success', `<div style="white-space: pre">Net Position Journal ${prefill.id} successfuly updated.</div>`);
      })
      .catch((err) => {
        if (err) return prompt.htmlDialog('Error', `<div style="white-space: pre">${err}</div>`);
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if journal was updated and try again if necessary.</div>`);
      })
  );
};

export const updateNetPositionJournalPreset: DynamicFormConstant<NetPositionJournalPrefill, NetPositionJournalForm> = {
  allowMultipleRows: false,
  checkPrefill: updateNetPositionJournalCheck,
  getPrefill: updateNetPositionJournalPrefill,
  openForm: updateNetPositionJournalForm,
  entityType: SourceEntityType.NET_POSITION_ENTRIES_KEY,
  label: 'Update Net Position Journal',
  submitForm: submitUpdateNetPositionJournal,
  title: 'Update Net Position Journal',
  value: DynamicFormType.UPDATE_NET_POSITION_JOURNAL,
  endpoints: [endpoints.getNetPositionLine, endpoints.getNetPositionOperation, endpoints.createNetPositionJournal, endpoints.reverseNetPositionLine],
  width: 840,
};

export type NetPositionJournalPrefill = Subset<
  NetPositionHeader,
  'valueDate' | 'operation' | 'description' | 'hedgeableType' | 'productId' | 'companyId',
  'id' | 'entryDate' | 'articleType' | 'company' | 'netPositionLines'
>;

export type NetPositionJournalForm = Omit<CreateNetPositionRequest, 'entryDate'> & { articleType?: Product };
