import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { Store } from 'src/app/core/services/store.service';
import { DropdownConfig } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { NetPositionJournalForm, NetPositionJournalPrefill } from 'src/lib/flex/forms/updateNetPositionJournal';
import { dateGreaterThanOrSameAs, formatDate, getCompanyByDefaultAuthorizedCompany, getTodayUTC, markFormGroupTouched } from 'src/lib/helperFunctions';
import { ContractClass, Product } from 'src/lib/newBackendTypes';
import { Contact } from 'src/lib/newBackendTypes/contact';
import { NetPositionOperations, NetPositionOperationsLabels } from 'src/lib/newBackendTypes/netPositionEntries';
import { TypedFormGroup } from 'src/lib/typedForms';
import { NetPositionJournalLinesComponent } from './net-position-journal-lines/net-position-journal-lines.component';
import { round } from 'lodash';
import { maxLengthValidator } from 'src/lib/genericValidators';

@Component({
  selector: 'risk-management-net-position-journal',
  templateUrl: './net-position-journal.component.html',
  providers: [NetPositionJournalLinesComponent],
})
export class NetPositionJournalComponent implements ModalFormComponent<NetPositionJournalForm, NetPositionJournalPrefill> {
  form: TypedFormGroup<NetPositionJournalForm>;
  popup = true;
  showCounter = true;
  maxLength = 150;

  operationsDropdown = NetPositionOperationsLabels;
  classData: { label: string; value: ContractClass }[] = [
    {
      label: 'No Hedge',
      value: ContractClass.NH,
    },
    {
      label: 'Open Hedge',
      value: ContractClass.H,
    },
    {
      label: 'QP Hedge',
      value: ContractClass.QP,
    },
  ];
  productTypeDropdown: DropdownConfig<Product>;
  productId: number;
  hedgeableType: string;

  companies: Contact[] = [];
  companyId: number;
  npPermDate: Date;

  constructor(public commonData: CommonDataService, private store: Store, public delegate: DelegateService) {
    this.companies = delegate.commonData.staticCompanies.value;
    const defaultCompany = getCompanyByDefaultAuthorizedCompany(this.companies, this.store);
    this.companyId = defaultCompany ? defaultCompany.id : null;
    this.npPermDate = defaultCompany.fiscalCompany.permDateNetPosition;

    this.generateProductTypeDropdown();

    this.form = new TypedFormGroup<NetPositionJournalForm>({
      valueDate: new UntypedFormControl(getTodayUTC(), Validators.required),
      operation: new UntypedFormControl(NetPositionOperations.MANUAL_CREATION, Validators.required),
      company: new UntypedFormControl(defaultCompany, Validators.required),
      hedgeableType: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null, [Validators.required, maxLengthValidator(this.maxLength)]),
      productId: new UntypedFormControl(null),
      lines: new UntypedFormControl([]),
      articleType: new UntypedFormControl(null, Validators.required),
      companyId: new UntypedFormControl(this.companyId, Validators.required),
    });
  }

  prefillForm(data: NetPositionJournalPrefill) {
    if (!!data) {
      let linePosition = 1;
      this.form.patchValue({
        ...data,
        lines: data.netPositionLines.map((line) => {
          return {
            ...line,
            linePosition: linePosition++,
            account: line.netPositionAccount,
            physicalUnits: line.metalUnitPercentage ? round((line.quantity * 100) / line.metalUnitPercentage, 3) : line.quantity,
            quantityUnitId: line?.contract?.quantityUnitId,
          };
        }),
      });

      this.npPermDate = new Date(data.company?.permDateNetPosition);
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  generateProductTypeDropdown() {
    this.productTypeDropdown = new DropdownConfig({
      listProcedure: endpoints.listProducts,
      valueField: 'productId',
      labelField: 'name',
      take: 20,
      additionalFilters: { productId: { not: 0 } },
    });
  }

  submit(): NetPositionJournalForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    const mappedLines = formVal.lines.map((line) => {
      return {
        id: line.id,
        accountId: line.account ? line.account.id : undefined,
        contractId: line.contract ? line.contract.id : undefined,
        elementId: line.element ? line.element.id : undefined,
        shipmentId: line.shipment ? line.shipment.id : undefined,
        invoiceId: line.invoice ? line.invoice.id : undefined,
        futureId: line.futureContract ? line.futureContract.id : undefined,
        fixationId: line.fixation ? line.fixation.id : undefined,
        itemId: line.item ? line.item.id : undefined,
        quantity: line.quantity || null,
        metalUnitPercentage: line.metalUnitPercentage || null,
        description: line.account ? line.account.description : undefined,
        comments: line.comments,
      };
    });
    return {
      valueDate: formVal.valueDate,
      companyId: formVal.companyId,
      operation: formVal.operation,
      hedgeableType: formVal.hedgeableType,
      description: formVal.description,
      productId: formVal.articleType.productId,
      lines: mappedLines,
    };
  }

  get valueDateWarning() {
    const valueDate = this.form.get('valueDate').value;
    return {
      text: `Warning!! You are trying to create a Journal with Value Date out of the NP Perm Date: ${formatDate(this.npPermDate, true)}`,
      show: !dateGreaterThanOrSameAs(valueDate, this.npPermDate),
    };
  }
}
