import { Component, OnInit } from '@angular/core';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { Permissions } from 'src/lib/componentPermissions';
import { Clause, ClauseTypes } from 'src/lib/newBackendTypes';

@Component({
  selector: 'contract-clause-popup',
  templateUrl: './contract-clause-popup.component.html',
  styleUrls: ['./contract-clause-popup.component.scss'],
})
@Permissions('Contract Clause Popup', [endpoints.listContractClauses])
export class ContractClausePopupComponent implements OnInit {
  contractId: number;

  clauses: (Clause & { clauseType: string })[];

  constructor(private api: ThalosApiService) {}

  ngOnInit(): void {
    if (this.contractId) {
      this.api.rpc<ListResponse<Clause>>(endpoints.listContractClauses, { filters: { contractId: this.contractId } }, { list: [], count: 0 }).subscribe((r) => {
        this.clauses = r.list.map((c) => {
          return { ...c, clauseType: c.type === ClauseTypes.LC ? 'LC' : 'General' };
        });
      });
    }
  }
}
