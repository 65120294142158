import { from, map, of } from 'rxjs';
import { BaseInvoiceServiceOrderRequest, ServiceOrderInvoiceComponent } from 'src/app/+modules/+core/containers/service-order-invoice/service-order-invoice.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { ListResponse } from 'src/lib/ListResponse';
import { InvoiceHeader, ServiceOrder, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const invoiceServiceOrderPrefill: prefillCallback<InvoiceServiceOrderPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const serviceOrderIds = Array.isArray(id) ? id : [id];
      const items = (await api.run<ListResponse<ServiceOrder>>(endpoints.listServiceOrders, { filters: { id: serviceOrderIds } }, null)).list;

      return { items };
    })()
  );
};

const invoiceServiceOrderCheck: checkPrefillCallback<InvoiceServiceOrderPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  const items = prefill.items;
  if (items.length === 0) return of('No Service Orders found.');
  const counterpartyIds = new Set<number>(items.map((so) => so.counterpartyId));
  if (counterpartyIds.size > 1) return of('Service Orders must have a matching counterparty.');
  const invoicedItems = items.filter((so) => so.completelyInvoiced === YN.Y);
  if (invoicedItems.length > 0) {
    const invoicedIds = invoicedItems.map((so) => so.id).join(', ');
    return of(`One or more selected Service Orders have already been invoiced: ${invoicedIds}`);
  }
  return of(true);
};

const invoiceServiceOrderForm: openFormCallback<InvoiceServiceOrderPrefill, BaseInvoiceServiceOrderRequest> = (delegate, id, prefill) => {
  // Service Orders with a price of 0 can be 'invoiced' without any extra information
  const items = prefill.items;
  const serviceOrderIds = items.map((item) => item.id).join(', ');
  if (items.every((so) => so.orderPrice === 0)) {
    const prompt = delegate.getService('prompt');
    return prompt
      .simpleConfirmation('Invoice Service Order', `Service Order(s) ID(s): ${serviceOrderIds} will be invoiced.  Continue?`, {
        confirmText: 'Invoice',
      })
      .pipe(map((response) => (response ? {} : 'Close')));
  }

  const selector = delegate.getService('selector');
  return selector.openForm<BaseInvoiceServiceOrderRequest, ServiceOrderInvoiceComponent>(ServiceOrderInvoiceComponent, {
    title: 'Invoice Service Order(s)',
    initializer: (c) => {
      (c.serviceOrders = items), (c.expenseType = items.length === 1 ? items[0].expenseType : undefined), (c.serviceOrderIds = serviceOrderIds);
    },
    prefillValue: {
      dueDate: getTodayUTC(),
      invoiceDate: getTodayUTC(),
      yourReference: items.flatMap((so) => so.orderReference ?? [])[0] ?? '',
    },
  });
};

const submitInvoiceServiceOrderForm: submitFormCallback<InvoiceServiceOrderPrefill, BaseInvoiceServiceOrderRequest> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<{ voucher: InvoiceHeader; serviceOrders: ServiceOrder[] }>((resolve, reject) => {
      (async () => {
        id = Array.isArray(id) ? id : [id];

        const invoiceServiceOrderResponse = await api.run<{ serviceOrders: ServiceOrder[]; voucher: InvoiceHeader }>(endpoints.invoiceServiceOrder, { ...form, id }, null);

        if (invoiceServiceOrderResponse) return resolve(invoiceServiceOrderResponse);
        return reject('Unknown result. Please check if the service orders were invoiced and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res && res.voucher) {
          return prompt.htmlDialog('Success', `<div style="white-space: pre">Service Order(s) successfully invoiced\nInvoice #: ${res.voucher.number}</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const invoiceServiceOrderPreset: DynamicFormConstant<InvoiceServiceOrderPrefill, BaseInvoiceServiceOrderRequest> = {
  value: DynamicFormType.INVOICE_SERVICE_ORDER,
  label: 'Invoice Service Order',
  endpoints: [endpoints.listServiceOrders, endpoints.invoiceServiceOrder],
  entityType: SourceEntityType.SERVICE_ORDER_KEY,
  title: 'Invoice Service Order',
  width: 400,
  allowMultipleRows: true,
  getPrefill: invoiceServiceOrderPrefill,
  checkPrefill: invoiceServiceOrderCheck,
  openForm: invoiceServiceOrderForm,
  submitForm: submitInvoiceServiceOrderForm,
};

export type InvoiceServiceOrderPrefill = {
  items: ServiceOrder[];
};
