import { EnumType, VariableType } from 'json-to-graphql-query';
import { GraphqlArgsRequest, GraphqlRequest, GraphqlVariables } from '../graphqlRequestTypes';
import { WaTermGroup } from 'src/lib/newBackendTypes';
import { ExistingGraphql } from '../graphQlEnums';

export function getPaymentTermGroupListArgsGraphql(take: number = 20) {
  const args: GraphqlRequest<WaTermGroup> = {
    where: { termGroupName: { contains: new VariableType('termGroupName') } },
    orderBy: [{ termGroupName: new EnumType('asc') }],
    take,
  };
  return args;
}

export function getPaymentTermGroupListVariablesGraphql() {
  const variables: GraphqlVariables<WaTermGroup> = { termGroupName: 'String' };
  return variables;
}

export const listPaymentTermGroupGraphqlRequest = (args: GraphqlRequest<WaTermGroup>): GraphqlArgsRequest<WaTermGroup> => {
  return {
    __aliasFor: ExistingGraphql.waTermGroups,
    __args: args,
    termGroupKey: true,
    termGroupName: true,
  };
};
