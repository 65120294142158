// For type consolidation with optional attributes
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type OnlyIncludes<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>;

export type RelationsQuery = {
  path: string;
  property?: string;
  value?: string | number | string[] | number[] | { not: string | number };
  eagerLoad?: boolean;
};

export type ListQuery<T> = {
  filters: QueryFilters<T>;
  relations?: RelationsQuery[];
  take?: number;
  skip?: number;
  orderBy?: {
    fieldName: string;
    order?: 'ASC' | 'DESC';
  };
};

export type Flatten<T> = T extends any[] ? T[number] : T;

export type QueryFilters<Type> = {
  [key in keyof Partial<Type>]: Type[key] | Type[key][] | { not: Type[key] } | { not: Type[key][] };
} & { [key: string]: any };

export type GetQuery<T> = {
  filters: QueryFilters<T>;
  relations?: RelationsQuery[];
};

export type Nullable<T> = {
  [key in keyof T]: T[key] | null;
};

export type Subset<T, Mandatory extends keyof T | never = never, Optional extends keyof T | never = never> = Pick<T, Mandatory> & Partial<Pick<T, Optional>>;

export type Base<T> = Pick<
  T,
  {
    [P in keyof T]: Exclude<T[P], undefined> extends object ? (Exclude<T[P], undefined> extends Date ? P : never) : P;
  }[keyof T]
>;

export function enumValues(enumObject: object, addNull: boolean = false) {
  const numericValues = Object.values(enumObject)
    .filter((value) => typeof value === 'number')
    .map((value) => `${value}`);
  const values = Object.entries(enumObject)
    .filter((entry) => numericValues.findIndex((nv) => nv === entry[0]) < 0)
    .map((entry) => entry[1]);
  if (addNull && !values.includes(null)) values.push(null);
  return values;
}

export type DeepPartial<T> = {
  [key in keyof T]?: T[key] extends Array<any> ? DeepPartial<T[key][number]>[] : T[key] extends object ? DeepPartial<T> : T[key];
};
export type EnumEntry<T extends number | string> = { label: string; value: T };
export type EnumLabels<T extends number | string> = EnumEntry<T>[];
