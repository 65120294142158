import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import {
  ColDef,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { round } from 'lodash';
import Mexp from 'math-expression-evaluator';
import { DebitCreditRenderer } from 'src/app/shared/aggrid/debitcreditrenderer/DebitCreditRenderer';
import { StatusBarTotalizerPanel } from 'src/app/shared/aggrid/statusbartotalizerpanel/StatusBarTotalizerPanel';
import { TableForm } from 'src/app/shared/aggrid/TableForm';
import { ValidationStatusPanel } from 'src/app/shared/aggrid/validationstatuspanel/ValidationStatusPanel';
import { dateColumn, getContextMenuItems, quantityColumn } from 'src/lib/agGridFunctions';
import { YN } from 'src/lib/newBackendTypes';
import { ClientInvoicePaymentCreditLineForm } from '../client-invoice.component';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';

@Component({
  selector: 'client-invoice-payments-credits',
  templateUrl: './client-invoice-payments-credits.component.html',
  styleUrls: ['./client-invoice-payments-credits.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ClientInvoicePaymentsCreditsComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ClientInvoicePaymentsCreditsComponent),
    },
  ],
})
export class ClientInvoicePaymentsCreditsComponent extends TableForm<ClientInvoicePaymentCreditLineForm> {
  private totalRow: { amountToApply: number | false | null; paymentBalance: number | false } = {
    amountToApply: null,
    paymentBalance: 0,
  };
  errors: string[] = [];
  private _oneShipmentPerInvoice: YN;

  @Input()
  set oneShipmentPerInvoice(oneShipmentPerInvoice: YN) {
    this._oneShipmentPerInvoice = oneShipmentPerInvoice;
  }

  get oneShipmentPerInvoice() {
    return this._oneShipmentPerInvoice;
  }

  /**
   * Will hold a reference to the grid API
   */
  gridApi: GridApi | null = null;

  /**
   * Total invoice from client invopice shipments table
   */
  totalInvoice: number;

  constructor(private formatter: DataFormattingService) {
    super();

    this.gridOptions = {
      getRowId: (params) => params.data.entryId,
      domLayout: 'normal',
      suppressAggFuncInHeader: true,
      animateRows: false,
      getRowStyle: (params) => (params?.node?.group ? { 'font-weight': 'bold' } : {}),
      groupIncludeFooter: true,
      groupDefaultExpanded: 1,
      groupSuppressAutoColumn: false,
      groupUseEntireRow: false,
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 150,
      },
      stopEditingWhenCellsLoseFocus: false,
      columnDefs: this.columnDefinitions,
      statusBar: {
        statusPanels: [
          {
            statusPanel: StatusBarTotalizerPanel,
            align: 'left',
            statusPanelParams: {
              totalRow: this.totalRow,
              labels: [
                { key: 'amountToApply', text: 'Amount to Apply' },
                { key: 'paymentBalance', text: 'Payment Balance' },
              ],
            },
          },
          {
            statusPanel: ValidationStatusPanel,
            align: 'right',
            key: 'validationStatusPanel',
            statusPanelParams: {
              errors: this.errors,
              readonly: false,
            },
          },
        ],
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      onSelectionChanged: this.refreshAmountToPay.bind(this),
      isRowSelectable: () => {
        return this.oneShipmentPerInvoice === YN.N;
      },
      onRowSelected: this.refreshAmountToPay.bind(this),
      getContextMenuItems: getContextMenuItems(this.getDeselectAllOption()),
    };
  }

  get columnDefinitions(): ColDef[] {
    return [
      {
        field: 'lineNumber',
        headerName: '#',
        width: 75,
        checkboxSelection: true,
      },
      {
        field: 'entryType',
        headerName: 'Entry Type',
        width: 130,
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'entryReference',
        headerName: 'Item Reference',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
        width: 150,
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        ...dateColumn('valueDate', 'Item Date'),
        cellStyle: { 'text-align': 'left' },
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'entryTitle',
        headerName: 'Description',
        width: 230,
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'externalRef',
        headerName: 'External Reference',
        width: 230,
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'entryExternalRef',
        headerName: 'Line External Reference',
        width: 230,
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'ourReference',
        headerName: 'Our Reference',
        width: 230,
        filter: 'agTextColumnFilter',
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'balanceAmount',
        headerName: 'Balance Amount',
        ...quantityColumn(),
        cellRenderer: DebitCreditRenderer,
        cellRendererParams: { currencyField: 'currencyId' },
        width: 180,
        cellClassRules: {
          'locked-cell': () => true,
        },
      },
      {
        field: 'amountToApply',
        headerName: 'Amount To Apply',
        ...quantityColumn(),
        cellRenderer: DebitCreditRenderer,
        cellRendererParams: { currencyField: 'currencyId' },
        editable: (params: EditableCallbackParams) => {
          if (this.oneShipmentPerInvoice === YN.Y) return false;
          if (!params.node.isSelected()) return false;
          return true;
        },
        valueGetter: (params: ValueGetterParams) => {
          if (!params.node.isSelected()) return null;
          return params.data.amountToApply;
        },
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          let dataChanged = false;
          if (isNaN(value)) {
            params.data.amountToApply = params.newValue;
            dataChanged = params.oldValue !== params.newValue;
          } else {
            params.data.amountToApply = this.formatter.roundAmount(value, params?.data?.currencyId);
            dataChanged = parseFloat(params.oldValue) != value;
          }
          return dataChanged;
        },
        onCellValueChanged: () => {
          this.onChange(this.gridApi.getSelectedRows());
          this.refreshAmountToPay();
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete' && !params.editing) {
            params.event.stopPropagation();
            for (const row of [params.data]) {
              row.amountToApply = null;
              if (this.gridApi) {
                this.refreshAmountToPay();
                this.onChange(this.gridApi.getSelectedRows());
              }
            }
            params.api.redrawRows({ rowNodes: [params.node] });
            return true;
          }
          return false;
        },
        width: 150,
        cellClassRules: {
          'invalid-cell': (params) =>
            params.node.isSelected() &&
            (typeof params.value !== 'number' ||
              this.totalRow.amountToApply > this.totalInvoice ||
              (!!params.data && !params.data.amountToApply) ||
              (params.data.amountToApply &&
                (Math.sign(params.data.balanceAmount) !== Math.sign(params.data.amountToApply) || Math.abs(params.data.amountToApply) > Math.abs(params.data.balanceAmount)))),
          'locked-cell': (params) => !params.node.isSelected(),
        },
      },
    ];
  }

  onGridReady(event: GridReadyEvent) {
    super.onGridReady(event);
    this.refreshAmountToPay();
    this.refreshDefinitions();
    this.gridApi.refreshCells();
    this.onChange(this.gridApi.getSelectedRows());
  }

  ngOnChanges(): void {
    if (this.oneShipmentPerInvoice) {
      this.rowSelectable();
    }
  }

  rowSelectable() {
    if (this.gridApi) {
      return this.gridApi.forEachNode((node) => {
        node.setRowSelectable(this.gridOptions.isRowSelectable(node));
      });
    }
  }

  private refreshDefinitions() {
    const definitions = this.columnDefinitions;

    if (this.gridApi) {
      this.gridApi.setColumnDefs(definitions);
    }
  }

  getDeselectAllOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (!!params.api.getSelectedNodes().length) {
        return {
          name: 'Deselect All',
          action: () => {
            params.api.deselectAll();
          },
        };
      }
      return [];
    };
  }

  getTotalInvoice($event) {
    if ($event) this.totalInvoice = $event;
    return;
  }

  refreshAmountToPay() {
    const selectedRows = this.gridApi.getSelectedRows();
    const amountToApply = selectedRows.reduce((total, row) => Math.abs(total) + Math.abs(row.amountToApply), 0 as number | number);
    this.totalRow.amountToApply = amountToApply;
    this.totalRow.paymentBalance = this.totalInvoice - (this.totalRow.amountToApply as number) || 0;
    this.gridApi.refreshCells();
    this.onChange(selectedRows);
  }

  validate(control: AbstractControl): ValidationErrors {
    if (!this.gridApi) return;
    this.errors.splice(0, this.errors.length);
    if (!(control.value instanceof Array)) {
      this.errors.push('Invalid line values');
    } else {
      let sum = 0;
      for (const line of control.value as ClientInvoicePaymentCreditLineForm[]) {
        const pos = line.lineNumber;
        const selectedRow = this.gridApi.getRowNode(line.entryId.toString());
        if (selectedRow.isSelected()) {
          const amountToApply = line.amountToApply ? this.formatter.roundAmount(line.amountToApply, line.currencyId) : 0;
          const balanceAmount = line.balanceAmount ? this.formatter.roundAmount(line.balanceAmount, line.currencyId) : 0;
          if (isNaN(amountToApply)) this.errors.push(`Invalid Amount To Apply on line #${pos}`);
          if (!amountToApply && amountToApply === 0) this.errors.push(`Missing Amount To Apply on line #${pos}`);
          if (amountToApply === 0 && balanceAmount !== 0) this.errors.push(`Amount To Apply on line #${pos} cannot be zero`);
          if (Math.sign(amountToApply) !== Math.sign(balanceAmount)) this.errors.push(`Amount To Apply and Balance Amount on line #${pos} must have the same sign`);
          if (Math.abs(amountToApply) > Math.abs(balanceAmount)) this.errors.push(`Amount To Apply on line #${pos} cannot exceed the Balance Amount`);
          sum += amountToApply ? Math.abs(amountToApply) : null;
        }
      }
      if (round(sum, 2) > round(this.totalInvoice, 2)) this.errors.push(`Selected Amount To Apply cannot exceed the Invoice Total`);
    }
    return !!this.errors && this.errors.length > 0 ? { custom: this.errors[0] } : null;
  }
}
