<div class="form-container" [formGroup]="form">
  <collapsible-card [title]="'General'">
    <div class="row">
      <vertical-field name="Counterparty" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'counterparty'" [dropdownConfig]="counterpartyDropdown" [readonly]="true"></dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Number" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <input-wrapper [formControlName]="'iden'" placeholder="(auto)" [readonly]="true"></input-wrapper>
      </vertical-field>
      <vertical-field [name]="'Type'" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <simple-dropdown [formControlName]="'type'" [data]="voucherTypes" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [readonly]="true"></simple-dropdown>
      </vertical-field>
      <vertical-field name="Payment Term" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <dropdown-wrapper [formControlName]="'paymentTerm'" [dropdownConfig]="paymentTermDropdown" [readonly]="true"> </dropdown-wrapper>
      </vertical-field>
      <vertical-field name="Value Date" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'valueDate'" [readonly]="false"></datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Invoice Date" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'invoiceDate'" [showCalculator]="true" [readonly]="true"> </datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Due Date" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <datepicker-wrapper [formControlName]="'dueDate'" [showCalculator]="true" [readonly]="true"> </datepicker-wrapper>
      </vertical-field>
      <vertical-field name="Amount" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <numerictextbox-wrapper class="vertical-field-1 w-50;" style="flex-grow: 1" formControlName="amount" [fieldType]="1" [format]="amountFormat" [readonly]="true" #amountField>
        </numerictextbox-wrapper>
        <div class="unit-label" style="flex-grow: 0">{{ currencyId | currency }}</div>
      </vertical-field>
    </div>
  </collapsible-card>
  <collapsible-card [title]="'Apply Payments/Credits'">
    <voucher-payments-credits-lines [formControlName]="'itemsLines'" [totalAmount]="totalAmount"> </voucher-payments-credits-lines>
  </collapsible-card>
</div>
