import { Subset } from '../generics';
import { YN } from './enums';
import { ItemAnalyticGroup } from './itemAnalyticGroup';
import { ItemReportingGroup } from './itemReportingGroup';
import { NetPositionLines } from './netPositionLine';
import { Product } from './product';
import { QualityType } from './quality';
import { SteelTypeGroup } from './steelTypeGroup';

export enum CommonSteelTypeGroups {
  CLIENT = 22524432,
  BASE_METAL = 100142,
  DEFAULT = 100100,
}

export type Item = {
  id: number;
  typeId: number;
  productId: number;
  name: string;
  qualityTypeId: number;
  groupName: string;
  groupTypeId: CommonSteelTypeGroups;
  modifiedBy: string | null;
  modifyDate: Date | null;
  archived: YN;
  reportingGroupId: number | null;
  analyticGroupId: number | null;
  metalUnitPercentage: number | null;
  mupUpdatedBy: number | null;
  mupUpdatedDate: Date | null;
  steelTypeGroup?: SteelTypeGroup;
  product?: Product;
  qualityType?: QualityType;
  reportingGroup?: ItemReportingGroup;
  analyticGroup?: ItemAnalyticGroup;
  netPositionLines?: NetPositionLines[];
};

export type CreateItemRequest = Subset<Item, 'productId' | 'name' | 'qualityTypeId' | 'groupTypeId' | 'reportingGroupId' | 'analyticGroupId' | 'metalUnitPercentage'>;
export type UpdateItemRequest = Subset<Item, 'id', keyof CreateItemRequest>;
