import { round } from 'lodash';
import { from } from 'rxjs';
import { PaymentForm, PaymentsComponent } from 'src/app/+modules/+accounting/payments/payments.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { getTodayUTC, toBradyUTCDate } from 'src/lib/helperFunctions';
import { BudgetElement, Contact, OpenItem, SourceEntityType } from 'src/lib/newBackendTypes';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { PaymentHeader, UpsertPaymentRequest } from 'src/lib/newBackendTypes/payment';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const createProposalPaymentPrefill: prefillCallback<CreateProposalPaymentPrefill> = (delegate, id, column, data) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const tuplesArray: OpenItemTuple[] = convertArrayToOpenItemTuple(data);
      const openItemsResponse = await api.run<ListResponse<OpenItem>>(endpoints.listOpenItemsByEntryType, { tuples: tuplesArray });

      const items = openItemsResponse.list;
      if (items.length < 1) return 'No items selected or available to be paid';

      const counterpartyId = items[0].counterpartyId;
      if (items.some((item) => item.counterpartyId !== counterpartyId)) {
        return 'All items selected should belong to the same counterparty';
      }

      const counterparty = (await api.run(endpoints.getContact, {
        filters: { id: counterpartyId },
      })) as Contact;
      if (!counterparty) return 'Unable to fetch information for the counterparty';

      const currencyConversions = (await api.run(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) })) as ExchangeRateInfo[];

      return { counterparty, items, currencyConversions };
    })()
  );
};

const openProposalPaymentForm: openFormCallback<CreateProposalPaymentPrefill, PaymentForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<PaymentForm, PaymentsComponent, CreateProposalPaymentPrefill>(PaymentsComponent, {
    title: `Propose New Payment: ${prefill.counterparty.displayName}`,
    width: '98%',
    prefillValue: prefill,
    height: '98%',
    hideDefaultActions: true,
    initializer: (c) => {
      c.showSaveButtonsInPopup = true;
      c.popUpOptions = {
        ...c.popUpOptions,
        blockRedirect: true,
        reloadInPlace: (e) => {
          if (!e) return null;
          let entity = Array.isArray(e) ? e[0] : e;
          return {
            ...entity,
          };
        },
      };
      c.popupCallback = (response) => {
        if (!!response) {
          c.selectorApi.close();
        }
      };
      c.companyIdField = prefill.items[0].companyId;
    },
  });
};

const createProposalPaymentCallback: submitFormCallback<CreateProposalPaymentPrefill, PaymentForm> = (delegate, id, form) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<PaymentHeader>((resolve, reject) => {
      (async () => {
        const amount = form.paymentEntries.reduce((p, c) => {
          let adjusted: number;
          adjusted = c.amount;
          return (adjusted || 0) + p;
        }, 0);
        const baseAmount = form.paymentEntries.reduce((p, c) => {
          let adjusted: number;
          adjusted = c.baseAmount;
          return (adjusted || 0) + p;
        }, 0);

        const request = await createPaymentRequest(form, -amount, -baseAmount);

        const proposalPayment = await api.run<PaymentHeader>(endpoints.createPayment, request, null);
        if (proposalPayment) return resolve(proposalPayment);
        return reject('Unknown result. Please check if the Proposal Payment was reversed and try again if necessary.');
      })();
    })
      .then((res) => {
        return prompt.htmlDialog('Success', `<div style="white-space: pre">Proposal Payment successfully created: \n Number: ${res.documentReference}</div>`);
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const createProposalPaymentPreset: DynamicFormConstant<CreateProposalPaymentPrefill, PaymentForm> = {
  allowMultipleRows: true,
  title: 'Propose New Payment',
  value: DynamicFormType.CREATE_PROPOSAL_PAYMENT,
  label: 'Propose New Payment',
  entityType: SourceEntityType.PAYABLE_ITEM_KEY,
  getPrefill: createProposalPaymentPrefill,
  openForm: openProposalPaymentForm,
  submitForm: createProposalPaymentCallback,
  endpoints: [endpoints.listOpenItems, endpoints.getContact, endpoints.getDailyExchangeRates, endpoints.createPayment],
};

export type CreateProposalPaymentForm = {
  totalAmount: number;
  bank: Contact;
  fees: feesForm[];
  valueDate: string;
  openItemsLines: OpenItemsLineForm[];
};

export type CreateProposalPaymentPrefill = {
  counterparty: Contact;
  items: OpenItem[];
  currencyConversions: ExchangeRateInfo[];
};

export type feesForm = {
  budgetElement: Partial<BudgetElement>;
  amount: number;
};

export type OpenItemsLineForm = Subset<
  OpenItem,
  'entryReference' | 'valueDate' | 'entryTitle' | 'balanceAmount' | 'entryType' | 'ourReference',
  'entryId' | 'currencyId' | 'accountId' | 'balanceBaseAmount'
> & {
  lineNumber?: number;
  amountToApply?: number;
};

export type CreateProposalPaymentRequest = CreateProposalPaymentForm;

export const createPaymentRequest = async (formVal: PaymentForm, amount: number, baseAmount: number) => {
  const request: UpsertPaymentRequest = {
    id: formVal.id,
    paymentTitle: formVal.paymentTitle,
    valueDate: formVal.valueDate,
    amount: round(amount, 2),
    baseAmount: round(baseAmount, 2),
    externalRef: formVal.externalRef ?? '',
    accountId: formVal.glAccount?.id ?? null,
    paymentStatus: formVal.paymentStatus,
    entryMode: formVal.entryMode,
    entryOperation: formVal.entryOperation,
    entrySource: formVal.entrySource,
    entryDate: formVal.entryDate,
    entries: formVal.paymentEntries.map((l) => {
      return {
        amount: round(l.amount, 2),
        baseAmount: round(l.baseAmount, 2),
        entryText: l.entryText,
        externalRef: l.externalRef ?? '',
        accountId: l.accountId,
        counterpartyId: l.counterpartyId || null,
        auxPaymentEntryId: l.auxPaymentEntryId || null,
        voucherId: l.voucherId || null,
        paymentId: l.paymentId || null,
        paymentEntryId: l.paymentEntryId || null,
      };
    }),
  };
  return request;
};

function convertArrayToOpenItemTuple(array: OpenItem[]): OpenItemTuple[] {
  return array.map((entry) => ({
    entryType: entry.entryType,
    entryId: entry.entryId,
  }));
}

type OpenItemTuple = { entryType: string; entryId: number };
