import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListQuery } from 'src/lib/generics';
import { DropdownConfig } from '../../../lib/DropdownConfig';
import { ThalosApiService } from './thalos-api.service';

@Injectable()
export class ListFilterService {
  constructor(private api: ThalosApiService) {}

  public globalPostFilters: { [name: string]: Function } = {};

  public listData<T = any>(rpcId: endpoints, request: any): Observable<{ list: T[]; count: number }> {
    return this.api.rpc<{ count: number; list: T[] }>(rpcId, request, { count: 0, list: [] }).pipe(first());
  }

  public handleFilter(textInput: string, source: DropdownConfig<any>) {
    let input: string | number;

    input = `*${textInput}*`;

    const request: ListQuery<any> = {
      filters: {
        [source.labelField]: input,
      },
      orderBy: {
        fieldName: source.orderBy ? <string>source.orderBy.fieldName : <string>source.labelField,
        order: source.orderBy ? source.orderBy.order : 'ASC',
      },
    };

    if (!!source.take || source.take === 0) request.take = source.take;

    request.filters = { ...request.filters, ...source.additionalFilters };

    if (source.relationFilters) request.relations = source.relationFilters;

    let list$ = this.api.rpc<{ count: number; list: any[] }>(source.listProcedure, request, {
      count: 0,
      list: [],
    });
    return list$.pipe(
      map((res) => {
        if (!!source.postFilter) res.list = res.list.filter(source.postFilter);
        // If source.newItems add them items to response array
        if (!!source.newItems && source.newItems.items.length > 0) {
          if (source.newItems.atStart) res.list.unshift(...source.newItems.items);
          else res.list.push(...source.newItems.items);
        }
        return res;
      })
    );
  }
}
