<div class="row" [formGroup]="form">
  <vertical-field name="Packet" class="col-12">
    <dropdown-wrapper [formControlName]="'packet'" [dropdownConfig]="packetDropdown" [ignoreReadonlyMode]="true"></dropdown-wrapper>
  </vertical-field>
  <vertical-field [name]="isMultipleIds ? 'File Name' : 'Save As'" class="col-12">
    <input-wrapper [formControlName]="'saveAs'" [ignoreReadonlyMode]="true"></input-wrapper>
  </vertical-field>
  <vertical-field *ngIf="isMultipleIds" name="Send To" class="col-12">
    <input-wrapper [formControlName]="'emailTo'" [ignoreReadonlyMode]="true"></input-wrapper>
  </vertical-field>
  <vertical-field *ngIf="!isMultipleIds" name="Location" class="col-12">
    <simple-dropdown [formControlName]="'location'" [data]="locations" [valueField]="'value'" [textField]="'label'" [valuePrimitive]="true" [ignoreReadonlyMode]="true"></simple-dropdown>
  </vertical-field>
  <div class="col-12" style="text-align: right" *ngIf="!!form.value.packet">
    <button kendoButton type="button" (click)="toggleAdvancedView()">Toggle Advanced View</button>
  </div>
  <ng-container *ngIf="showAdvanced && !!form.value.packet">
    <div class="col-12">Template Quantities</div>
    <div class="form-divider-line col-12"></div>
    <ng-container *ngFor="let template of copiesArray.controls">
      <horizontal-field-left [name]="template.value.name" class="col-12">
        <numerictextbox-wrapper [formControl]="template.get('quantity')" [format]="intFormat" [min]="0" [max]="20"></numerictextbox-wrapper>
      </horizontal-field-left>
    </ng-container>
  </ng-container>
</div>
