import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { FlexService } from 'src/app/core/services/flex.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { ListFilterGroupResponse, ListViewResponse } from 'src/lib/views';
import { GetQuery } from 'src/lib/generics';

@Injectable({
  providedIn: 'root',
})
export class FlexResolver implements Resolve<any> {
  constructor(private api: ThalosApiService, private router: Router, private flexService: FlexService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let id = route.params['id'];
    if (!id) {
      const fv: ListViewResponse = route.data['flexView'];
      if (!fv) this.router.navigate(['error/404']);
      else id = fv.id;
    }
    const query: GetQuery<ListViewResponse> = { filters: { id } };
    const res = await lastValueFrom(this.api.rpc<ListViewResponse>(endpoints.getFlexView, query, null, { hideErrorPopup: true }));
    if (!res) {
      this.router.navigate(['error/404']);
      return null;
    }
    const filterGroups = (await lastValueFrom(this.api.rpc<ListResponse<ListFilterGroupResponse>>(endpoints.listFlexFilterGroups, { flexViewId: res.id }, { list: [], count: 0 }))).list;
    res.filterGroups = filterGroups;
    const flexView = this.flexService.loadFlexView(res);
    if (!flexView) return null;

    return { flexView };
  }
}
