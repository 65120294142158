declare type BaseTag = {
  label: string;
};
export type MarketTag = BaseTag & {
  type: TagType.MARKET;
  id: number;
};
export type AmountTag = BaseTag & {
  type: TagType.AMOUNT;
  amount: number;
};
export type MultiplierTag = BaseTag & {
  type: TagType.MULTIPLIER;
  value: number;
};
export type CurrencyTag = BaseTag & {
  type: TagType.CURRENCY;
  id: number;
};
export type UnitTag = BaseTag & {
  type: TagType.UNIT;
  unitId: number;
};
declare type OperatorTag = BaseTag & {
  type: TagType.OPEN_PARENTHESIS | TagType.CLOSE_PARENTHESIS | TagType.PLUS | TagType.MINUS | TagType.TIMES;
};
export type FormulaTag = MarketTag | AmountTag | MultiplierTag | CurrencyTag | UnitTag | OperatorTag;
export enum TagType {
  MARKET = 'market',
  AMOUNT = 'amount',
  MULTIPLIER = 'multiplier',
  CURRENCY = 'currency',
  UNIT = 'unit',
  OPEN_PARENTHESIS = '(',
  CLOSE_PARENTHESIS = ')',
  PLUS = '+',
  MINUS = '-',
  TIMES = '*',
}
