import { BookingStage } from './freightBooking';

export class LogisticsFileAssignmentQueueData {
  id!: number;
  number!: number;
  description!: string;
  shippingLineName!: string | null;
  draftToCustomerSentDate!: Date | null;
  placeOfReceiptName!: string | null;
  placeOfReceiptETD!: Date | null;
  unloadingPortName!: string | null;
  unloadingPortETA!: Date | null;
  finalDestinationName!: string | null;
  finalDestinationETA!: Date | null;
  purchaseContractId!: number | null;
  purchaseContractNumber!: number | null;
  supplierName!: string | null;
  saleContractId!: number | null;
  saleContractNumber!: number | null;
  customerName!: string | null;
  term!: string | null;
  stage!: BookingStage;
  btsOperator!: number | null;
  btsOperatorName!: string | null;
  btsAssignmentDate!: number;
  ctsOperator!: number | null;
  ctsOperatorName!: string | null;
  ctsAssignmentDate!: number;
}
