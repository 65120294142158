import { EnumLabels } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';

export type PurchaseMatchData = {
  shipmentId: number;
  companyId: number;
  counterpartyId: number;
  counterpartyName: string;
  counterpartyReference: string;
  traderId: number;
  traderName: string;
  contractClass: string;
  productId: number;
  productName: string;
  itemId: number;
  itemName: string;
  metalControlGroupId: number;
  metalControlGroupName: string;
  contractId: number;
  contractNumber: string;
  contractLineId: number;
  contractLineNumber: string;
  contractDate: number;
  originCountryId: number;
  originCountryName: string;
  destinationPortId: number;
  destinationPortName: string;
  shippedWeight: number;
  shipmentPeriodType: string;
  shipmentPeriodStart: number;
  shipmentPeriodEnd: number;
  bookingId: number;
  bookingNumber: string;
  unloadingEtaDate: number;
  finalDestinationEtaDate: number;
  metalControlNotes: string;
  metalControlStatus: MetalControlStatus;
  appointmentReference: string;
};

export enum MetalControlStatus {
  PENDING = 1,
  WAREHOUSE = 2,
  LONG = 3,
  REJECTION = 4,
  MATCHED = 5,
  DOMESTIC = 6,
  REEXPORT = 7,
  REWORK = 8,
  MATCHED_PREVIOUS_REJECTION = 9,
  WRITTEN_OFF = 10,
  OTHER = 99,
}
export const MetalControlStatuses: EnumLabels<MetalControlStatus> = enumOptionsFromEnum(MetalControlStatus, [MetalControlStatus.MATCHED_PREVIOUS_REJECTION]).concat([
  { label: 'Matched - Prev Rejection', value: MetalControlStatus.MATCHED_PREVIOUS_REJECTION },
]);
