import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { ValidatorService } from 'src/app/core/services/validator.service';
import { endpoints } from 'src/lib/apiEndpoints';
import { contactDropdown } from 'src/lib/commonTypes';
import { markFormGroupTouchedAsync } from 'src/lib/helperFunctions';
import { Contact } from 'src/lib/newBackendTypes';
import { CreateVesselRequest } from 'src/lib/newBackendTypes/vessel';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'thalos-vessel-component',
  templateUrl: './vessel.component.html',
  styleUrls: ['./vessel.component.scss'],
})
export class VesselComponent implements OnInit, ModalFormComponent<CreateVesselRequest> {
  form: TypedFormGroup<VesselForm>;
  popup = true;

  contactDropdown = contactDropdown();

  constructor(private validatorService: ValidatorService) {
    this.form = new TypedFormGroup<VesselForm>({
      shipName: new UntypedFormControl(null, Validators.required, [this.validatorService.uniqueNameValidator(endpoints.listVessels, 'shipName')]),
      contact: new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {}

  submit() {
    let contactId = this.form.value.contact ? this.form.value.contact.id : null;
    let shipName = this.form.value.shipName;

    return {
      contactId,
      shipName,
    };
  }

  async allowSubmit() {
    await lastValueFrom(markFormGroupTouchedAsync(this.form));
    return this.form.valid && !this.form.pending;
  }

  prefillForm() {}
}

export type VesselForm = {
  contact: Contact;
  shipName: string;
};
