import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachingService } from './caching.service';

const cacheableUrls: string[] = ['listRequirements', 'listUnits', 'listCurrencies', 'listContacts'];

@Injectable()
export class DataCacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: CachingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRequestCacheable(req)) {
      return next.handle(req);
    }
    const cached = this.cacheService.get(req);
    if (cached) {
      return cached instanceof Observable ? cached : of(cached.clone());
    }

    const requestHandle = next.handle(req).pipe(
      tap((e) => {
        if (e instanceof HttpResponse) {
          this.cacheService.put(e, req.urlWithParams + JSON.stringify(req.body));
        }
      })
    );
    return requestHandle;
  }

  private isRequestCacheable(req: HttpRequest<any>): boolean {
    let cacheable = false;
    for (let match of cacheableUrls) {
      if (req.url.includes(match)) {
        cacheable = true;
        break;
      }
    }
    return cacheable;
  }
}
