import { Injectable } from '@angular/core';
import { IAggFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { toMetricTons } from 'src/lib/unitConversions';
import { numberWithCommas } from 'src/lib/helperFunctions';
import { City, CommonCurrencies, CommonUnits, ContainerType, Currency, Incoterm, Product, Unit, YN } from 'src/lib/newBackendTypes';
import { toUnit } from 'src/lib/unitConversions';
import { CommonDataService } from './common-data.service';
import { round } from 'lodash';
import { PricePrecision } from 'src/lib/newBackendTypes/pricePrecision';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';

@Injectable({
  providedIn: 'root',
})
export class DataFormattingService {
  get mtUnit(): Omit<Unit, 'code'> & {
    code: 'MT';
  } {
    return this.unitLookUp['MT'] as any;
  }
  unitLookUp: { [key: string]: Unit };

  constructor(private commonDataService: CommonDataService) {
    this.unitLookUp = {};
    commonDataService.staticUnits.value.forEach((u) => {
      this.unitLookUp[u.code] = u;
    });
  }

  gridUnitFormatter<T extends object>(unitField: string, decimals?: number, separator?: YN | string, showUnit?: YN | string) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const quantity: number | undefined | null = params.value;
      if (typeof quantity !== 'number') return params.value;
      if (!(unitField in data)) return '';

      const rowUnitProp: string | number | Unit | null = data[unitField];

      return this.quantityUnitFormatter(quantity, rowUnitProp, decimals, separator, showUnit);
    };
  }

  gridStaticUnitFormatter<T>(unitOrUnitId: string | number | Unit, decimals?: number, separator?: YN | string, showUnit?: YN | string) {
    const unit = this.unitFromTag(unitOrUnitId);
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const quantity: number | undefined | null = params.value;
      if (typeof quantity !== 'number') return params.value;

      return this.quantityUnitFormatter(quantity, unit, decimals, separator, showUnit);
    };
  }

  gridCurrencyFormatter<T extends object>(currencyField: string, decimalPlaces?: number, separator?: YN | string, showUnit: YN | string = YN.Y) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      if (!(currencyField in data)) return '';

      const rowCurrencyProp: string | number | Currency | null = data[currencyField];

      return this.amountCurrencyFormatter(amount, rowCurrencyProp, decimalPlaces, separator, showUnit);
    };
  }

  quantityUnitFormatter(quantity: number, unitOrUnitId: string | number | Unit, decimals?: number, separator: YN | string = YN.Y, showUnit: YN | string = YN.Y): string {
    if ((!quantity && quantity !== 0) || isNaN(quantity)) return '';
    const unit = this.unitFromTag(unitOrUnitId);
    if (!!unit) decimals = decimals ?? unit.precision;
    else decimals = decimals ?? 3;

    const fixed = quantity.toFixed(decimals);
    const formattedFixed = separator === YN.Y ? numberWithCommas(fixed) : fixed;
    return showUnit === YN.Y && !!unit ? `${formattedFixed} ${unit.code}` : formattedFixed;
  }

  amountCurrencyFormatter(amount: number, currencyOrCurrencyId: string | number | Currency, decimalPlaces?: number, separator: YN | string = YN.Y, showUnit: YN | string = YN.Y): string {
    if ((!amount && amount !== 0) || typeof amount !== 'number') return '';
    const currency = this.currencyFromTag(currencyOrCurrencyId);

    if (!!currency) decimalPlaces = decimalPlaces ?? currency.currAmountPrecision;
    else decimalPlaces = decimalPlaces ?? 2;
    const fixed = amount.toFixed(decimalPlaces);
    const formattedFixed = separator === YN.Y ? numberWithCommas(fixed) : fixed;
    return showUnit === YN.Y && !!currency ? `${formattedFixed} ${currency.code}` : formattedFixed;
  }

  gridIncotermFormatter(placeField?: string) {
    return (params: ValueFormatterParams) => {
      if (!params.data || !params.value) return '';

      const label = this.incotermLabelFormatter(params.value);
      if (placeField) {
        const place: City | string | null | undefined = params.data[placeField];
        if (!!place) {
          if (typeof place === 'object') return `${label} ${place.name}`;
          else return `${label} ${place}`;
        }
      }
      return label;
    };
  }

  gridProductFormatter(dataField?: string) {
    return (params: ValueFormatterParams) => {
      if (!params.data) return '';
      const val = params.value || params.data[dataField];
      if (!val) return '';
      return this.productLabelFormatter(val);
    };
  }

  incotermLabelFormatter(incotermOrId: number | Incoterm): string {
    const incoterm = !incotermOrId ? '' : typeof incotermOrId === 'object' ? incotermOrId : this.commonDataService.staticIncoterms.value.find((i) => i.id === incotermOrId);
    return !!incoterm ? incoterm.name : '';
  }

  productLabelFormatter(productOrId: number | Product): string {
    if (!productOrId) return '';
    const product: Product = typeof productOrId === 'object' ? productOrId : this.commonDataService.staticProducts.value.find((p) => p.productId === productOrId);
    return !!product ? product.name : '';
  }

  gridContainerTypeFormatter<T>() {
    return (params: ValueFormatterParams) => {
      if (!params.data || !params.value) return '';
      return this.containerTypeLabelFormatter(params.value);
    };
  }

  containerTypeLabelFormatter(containerTypeOrId: number | ContainerType): string {
    if (!containerTypeOrId) return '';
    const containerType: ContainerType = typeof containerTypeOrId === 'object' ? containerTypeOrId : this.commonDataService.staticContainerTypes.value.find((ct) => ct.id === containerTypeOrId);
    return !!containerType ? containerType.name : '';
  }

  gridStaticAmountCurrencyPerUnitFormatter<T>(currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit, decimalPlaces?: number, separator?: YN | string) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      return this.amountCurrencyPerUnit(amount, currencyOrCurrencyId, unitOrUnitId, decimalPlaces, separator);
    };
  }

  gridAmountStaticCurrencyPerUnitFormatter<T extends object>(unitField: string, currencyOrCurrencyId: string | number | Currency, decimalPlaces?: number, separator?: YN | string) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      if (!(unitField in data)) return '';

      const rowUnitProp: string | number | Unit | null = data[unitField];

      return this.amountCurrencyPerUnit(amount, currencyOrCurrencyId, rowUnitProp, decimalPlaces, separator);
    };
  }

  gridAmountCurrencyPerStaticUnitFormatter<T extends object>(unitOrUnitId: string | number | Unit, currencyField: string, decimalPlaces?: number, separator?: YN | string) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      if (!(currencyField in data)) return '';

      const rowCurrencyProp: string | number | Currency | null = data[currencyField];

      return this.amountCurrencyPerUnit(amount, rowCurrencyProp, unitOrUnitId, decimalPlaces, separator);
    };
  }

  gridAmountCurrencyPerUnitFormatter<T extends object>(unitFieldOrUnit: string | Unit, currencyFieldOrCurrency: string | Currency, decimalPlaces?: number, separator?: YN | string) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      const rowUnitProp: string | number | Unit = typeof unitFieldOrUnit === 'string' ? (unitFieldOrUnit in data ? data[unitFieldOrUnit] : '') : unitFieldOrUnit;

      const rowCurrencyProp: string | number | Currency =
        typeof currencyFieldOrCurrency === 'string' ? (currencyFieldOrCurrency in data ? data[currencyFieldOrCurrency] : '') : currencyFieldOrCurrency;

      return this.amountCurrencyPerUnit(amount, rowCurrencyProp, rowUnitProp, decimalPlaces, separator);
    };
  }

  gridPriceCurrencyPerUnitFormatter<T extends object>(
    unitFieldOrUnit: string | Unit,
    currencyFieldOrCurrency: string | Currency,
    decimalPlaces?: number,
    separator?: YN | string,
    showUnit: YN | string = YN.Y
  ) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const price: number | undefined | null = params.value;
      if (typeof price !== 'number') return params.value;

      const rowUnitProp: string | number | Unit = typeof unitFieldOrUnit === 'string' ? (unitFieldOrUnit in data ? data[unitFieldOrUnit] : '') : unitFieldOrUnit;

      const rowCurrencyProp: string | number | Currency =
        typeof currencyFieldOrCurrency === 'string' ? (currencyFieldOrCurrency in data ? data[currencyFieldOrCurrency] : '') : currencyFieldOrCurrency;

      return this.priceCurrencyPerUnit(price, rowCurrencyProp, rowUnitProp, decimalPlaces, separator, showUnit);
    };
  }

  gridStaticPriceCurrencyPerUnitFormatter<T>(
    currencyOrCurrencyId: string | number | Currency,
    unitOrUnitId: string | number | Unit,
    decimalPlaces?: number,
    separator?: YN | string,
    showUnit: YN | string = YN.Y
  ) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const price: number | undefined | null = params.value;
      if (typeof price !== 'number') return params.value;

      return this.priceCurrencyPerUnit(price, currencyOrCurrencyId, unitOrUnitId, decimalPlaces, separator, showUnit);
    };
  }

  gridAmountCurrencyFormatter<T extends object>(currencyField: string, decimalPlaces?: number, separator: YN = YN.Y) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      if (!(currencyField in data)) return '';

      const rowCurrencyProp: string | number | Currency | null = data[currencyField];

      return this.amountCurrencyFormatter(amount, rowCurrencyProp, decimalPlaces, separator);
    };
  }

  gridFxRateFormatter<T extends object>(currencyField: string, futureCurrencyField: string, decimalPlaces: number = 5, separator: YN = YN.Y) {
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const fx: number | undefined | null = params.value;
      if (typeof fx !== 'number') return params.value;

      if (!(currencyField in data) || !(futureCurrencyField in data)) return '';

      const rowCurrencyProp: string | number | Currency | null = data[currencyField];
      const rowFutureCurrencyProp: string | number | Currency | null = data[futureCurrencyField];

      return this.fxRateFormatter(fx, rowCurrencyProp, rowFutureCurrencyProp, decimalPlaces, separator);
    };
  }

  fxRateFormatter(fx: number, currencyOrCurrencyId: string | number | Currency, futureCurrencyOrCurrencyId: string | number | Currency, decimalPlaces?: number, separator: YN | string = YN.Y): string {
    if ((!fx && fx !== 0) || typeof fx !== 'number') return '';
    const currency = this.currencyFromTag(currencyOrCurrencyId);
    const futureCurrency = this.currencyFromTag(futureCurrencyOrCurrencyId);

    if (!!currency && !!futureCurrency) decimalPlaces = decimalPlaces ?? -Math.round(Math.log(currency.currPrecision || 0.01) * Math.LOG10E);
    else decimalPlaces = decimalPlaces ?? 2;

    const fixed = fx.toFixed(decimalPlaces);
    const formattedFixed = separator === YN.Y ? numberWithCommas(fixed) : fixed;
    return !!currency && !!futureCurrency ? `${fixed} ${currency.code} / ${futureCurrency.code}` : formattedFixed;
  }

  amountCurrencyPerUnit(amount: number, currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit, decimalPlaces?: number, separator: YN | string = YN.Y): string {
    if (!amount && amount !== 0) return '';
    const unit = this.unitFromTag(unitOrUnitId);

    const amountCurrency = this.amountCurrencyFormatter(amount, currencyOrCurrencyId, decimalPlaces, separator);

    return !!unit ? `${amountCurrency} / ${unit.code}` : `${amountCurrency} / ??`;
  }

  priceCurrencyPerUnit(
    price: number,
    currencyOrCurrencyId: string | number | Currency,
    unitOrUnitId: string | number | Unit,
    decimalPlaces?: number,
    separator: YN | string = YN.Y,
    showUnit: YN | string = YN.Y
  ): string {
    if (!price && price !== 0) return '';
    const unit = this.unitFromTag(unitOrUnitId);
    const currency = this.currencyFromTag(currencyOrCurrencyId);
    const pricePrecision = this.getPricePrecision(currency, unit);

    if (!!pricePrecision) decimalPlaces = decimalPlaces ?? pricePrecision.precision;
    else decimalPlaces = decimalPlaces ?? 4;

    const fixed = price.toFixed(decimalPlaces);
    const formattedFixed = separator === YN.Y ? numberWithCommas(fixed) : fixed;

    const currencyCode = currency?.code ?? '???';
    const unitCode = unit?.code ?? '??';
    return showUnit === YN.Y ? `${formattedFixed} ${unitCode} / ${currencyCode}` : formattedFixed;
  }

  currencyFromTag(currencyOrCurrencyId: string | number | Currency): Currency | null {
    let currency: Currency;

    if (!!currencyOrCurrencyId && typeof currencyOrCurrencyId === 'object') currency = currencyOrCurrencyId;
    else if (typeof currencyOrCurrencyId === 'number') currency = this.commonDataService.staticCurrencies.value.find((c) => currencyOrCurrencyId === c.id);
    else if (typeof currencyOrCurrencyId === 'string') currency = this.commonDataService.staticCurrencies.value.find((c) => c.code === currencyOrCurrencyId);
    else currency = null;
    return currency;
  }

  unitFromTag(unitOrUnitId: string | number | Unit): Unit | null {
    let unit: Unit;

    if (!!unitOrUnitId && typeof unitOrUnitId === 'object') unit = unitOrUnitId;
    else if (typeof unitOrUnitId === 'number') unit = this.commonDataService.staticUnits.value.find((u) => unitOrUnitId === u.unitId);
    else if (typeof unitOrUnitId === 'string') unit = this.unitLookUp[unitOrUnitId];
    else unit = null;
    return unit;
  }

  getPricePrecision(currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit): PricePrecision | null {
    const unit = this.unitFromTag(unitOrUnitId);
    const currency = this.currencyFromTag(currencyOrCurrencyId);

    const pricePrecision: PricePrecision =
      !!unit && !!currency ? this.commonDataService.staticPricePrecisions.value.find((item) => item.currencyId === currency.id && item.unitId === unit.unitId) : null;

    return pricePrecision;
  }

  getPriceFormat(currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit): NumberFormatOptions {
    const pricePrecision = this.getPricePrecision(currencyOrCurrencyId, unitOrUnitId);
    return {
      maximumFractionDigits: pricePrecision && pricePrecision.precision,
      minimumFractionDigits: pricePrecision && pricePrecision.precision,
      useGrouping: true,
    };
  }

  getUnitsFormat(unitOrUnitId: string | number | Unit): NumberFormatOptions {
    const unitFormat = this.unitFromTag(unitOrUnitId);
    return {
      maximumFractionDigits: unitFormat && unitFormat.precision,
      minimumFractionDigits: unitFormat && unitFormat.precision,
      useGrouping: true,
    };
  }

  getAmountFormat(currencyOrCurrencyId: string | number | Currency): NumberFormatOptions {
    const amountFormat = this.currencyFromTag(currencyOrCurrencyId);
    return {
      maximumFractionDigits: amountFormat && amountFormat.currAmountPrecision,
      minimumFractionDigits: amountFormat && amountFormat.currAmountPrecision,
      useGrouping: true,
    };
  }

  staticMTAggregator(showUnit: YN | string = YN.Y) {
    return this.staticWeightAggregator(CommonUnits.MT, showUnit);
  }

  staticWeightAggregator(unitOrUnitId: string | number | Unit, showUnit: YN | string = YN.Y) {
    const unit = this.unitFromTag(unitOrUnitId);
    return (params) => {
      let problem: boolean = false;

      const total: number = params.values.reduce((prev, curr) => {
        if (!curr && curr !== 0) return prev;

        if (!prev) prev = 0;
        if (curr === 'Error') {
          problem = true;
          return prev;
        }
        if (typeof curr === 'string') {
          curr = curr.replace(/\D/g, '');
          try {
            curr = Number(curr);
          } catch (e) {
            problem = true;
            return prev;
          }
          return curr || 0;
        }
        if (typeof curr === 'object') {
          if ((!!curr.amount || curr.amount === 0) && typeof curr.amount === 'number' && curr.formatted && typeof curr.formatted === 'string') return prev + curr.amount;
          problem = true;
          return prev;
        }
        if (typeof curr === 'number') return prev + (curr || 0);
        problem = true;
        return prev;
      }, null);
      if (problem) return 'Error';
      if (total === null) return null;

      const amountObj = new Object({
        amount: total,
        formatted: this.quantityUnitFormatter(total, unit, undefined, YN.Y, showUnit),
      });
      amountObj.toString = function () {
        return this.formatted;
      };
      return amountObj;
    };
  }

  staticUSDAggregator(showUnit: YN | string = YN.Y) {
    return this.currencyAggregator(CommonCurrencies.USD, showUnit);
  }

  currencyAggregator(currencyOrCurrencyId: string | number | Currency, showUnit: YN | string = YN.Y) {
    const currency = this.currencyFromTag(this.currencyFromTag(currencyOrCurrencyId));
    return (params: IAggFuncParams) => {
      let problem: boolean = false;

      const total: number = params.values.reduce((prev, curr) => {
        if (!curr && curr !== 0) return prev;

        if (!prev) prev = 0;
        if (curr === 'Error') {
          problem = true;
          return prev;
        }
        if (typeof curr === 'string') {
          curr = curr.replace(/\D/g, '');
          try {
            curr = Number(curr);
          } catch (e) {
            problem = true;
            return prev;
          }
          return curr || 0;
        }
        if (typeof curr === 'object') {
          if ((!!curr.amount || curr.amount === 0) && typeof curr.amount === 'number' && curr.formatted && typeof curr.formatted === 'string') return prev + curr.amount;
          problem = true;
          return prev;
        }
        if (typeof curr === 'number') return prev + (curr || 0);
        problem = true;
        return prev;
      }, null);

      if (problem) return 'Error';
      if (total === null) return null;

      const amountObj = new Object({
        amount: total,
        formatted: this.amountCurrencyFormatter(total, currency, undefined, YN.Y, showUnit),
      });
      amountObj.toString = function () {
        return this.formatted;
      };
      return amountObj;
    };
  }

  currencyOnlyMatchingAggregator(field: string, currencyField: string, aggType: 'sum' | 'avg' = 'sum') {
    return (params: IAggFuncParams) => {
      if (params.rowNode?.group && !params.rowNode.leafGroup) {
        const { total, problem, currency } = this.currencyAggReducer(params.values);

        if (total === null || problem) return null;

        const obj = new Object({
          amount: total,
          formatted: this.amountCurrencyFormatter(total, currency),
        });
        obj.toString = function () {
          return this.formatted;
        };
        return obj;
      }

      const children = params.rowNode.childrenAfterFilter;
      let childCount = 0;
      let currency: Currency = null;
      let mixed = false;
      if (children) {
        let total = children
          .map((c) => {
            if (mixed) return null;
            if (!c.data?.[field] && c.data?.[field] !== 0) return null;
            const prop: Currency | string | number | null = c.data?.[currencyField];
            if (!prop) {
              mixed = true;
              return null;
            }
            let rowCurrency = this.currencyFromTag(prop);
            if (!rowCurrency) {
              mixed = true;
              return null;
            }
            if (currency === null) currency = rowCurrency;
            else if (currency.code !== rowCurrency.code) {
              mixed = true;
              return null;
            }
            childCount++;
            return c.data[field];
          })
          .reduce((prev, curr) => (curr === null ? prev : (prev || 0) + (curr || 0)), null);
        if (total === null) return null;

        if (mixed || !currency) return 'Mixed Currencies';

        const amount = aggType === 'avg' ? total / childCount : total;

        const obj = new Object({
          amount,
          formatted: this.amountCurrencyFormatter(amount, currency),
          currency,
        });
        obj.toString = function () {
          return this.formatted;
        };
        return obj;
      } else return null;
    };
  }

  columnQuantityAggregator(unit: Unit) {
    return (params: IAggFuncParams) => {
      const { total, problem } = this.quantityAggReducer(params.values);

      if (problem) return 'Error';
      if (total === null) return null;
      const obj = new Object({ amount: total, formatted: this.quantityUnitFormatter(total, unit) });
      obj.toString = function () {
        return this.formatted;
      };
      return obj;
    };
  }

  quantityAggregator(field: string, unitField: string, aggType: 'sum' | 'avg' = 'sum') {
    return (params: IAggFuncParams) => {
      if (params.rowNode?.group && !params.rowNode.leafGroup) {
        const { total, problem, unit } = this.quantityAggReducer(params.values);

        if (problem) return 'Error';
        if (total === null) return null;

        const obj = new Object({ amount: total, formatted: this.quantityUnitFormatter(total, unit) });
        obj.toString = function () {
          return this.formatted;
        };
        return obj;
      }

      const children = params.rowNode.childrenAfterFilter;
      let childCount = 0;
      if (children) {
        const total = children
          .map((c) => {
            if (!c.data?.[field] && c.data?.[field] !== 0) return null;
            let prop: Unit | string | number | null = c.data?.[unitField];
            if (!prop) return null;
            let unit = this.unitFromTag(prop);
            if (!unit) return null;
            if (unit.indivisible === YN.Y) return null;
            childCount++;
            return toMetricTons(c.data?.[field] || 0, unit);
          })
          .reduce((prev, curr) => (curr === null ? prev : (prev || 0) + (curr || 0)), null);
        if (total === null) return null;

        let amount = aggType === 'avg' ? total / childCount : total;

        const unitSet: Unit[] = Array.from(new Set(children.map((v) => (typeof v?.data?.[unitField] === 'object' ? v?.data?.[unitField]?.unitId : v?.data?.[unitField])).filter((u) => !!u))).map((u) =>
          this.unitFromTag(u)
        );

        let unit: Unit = this.mtUnit;
        if (unitSet.length === 1 && unitSet[0].code !== 'MT') {
          unit = unitSet[0];
          amount = toUnit(amount, this.mtUnit, unit);
        }
        const obj = new Object({ amount, formatted: this.quantityUnitFormatter(amount, unit), unit });
        obj.toString = function () {
          return this.formatted;
        };
        return obj;
      } else return '';
    };
  }

  gridStaticCurrencyFormatter<T>(currencyOrId: string | number | Currency | null, decimalPlaces?: number, separator?: YN | string, showUnit: YN | string = YN.Y) {
    const currency = this.currencyFromTag(currencyOrId);
    return (params: ValueFormatterParams) => {
      const data: T = params.data;
      if (!data) return params.value;

      const amount: number | undefined | null = params.value;
      if (typeof amount !== 'number') return params.value;

      return this.amountCurrencyFormatter(amount, currency, decimalPlaces, separator, showUnit);
    };
  }

  quantityAggReducer(values: any[], type: 'sum' | 'avg' = 'sum'): { total: number; problem: boolean; unit: Unit } {
    let problem = false;
    let unit: Unit = null;
    let count = 0;
    let total: number = values.reduce((previous: number, current) => {
      if (!current && current !== 0) return previous;

      if (typeof current === 'object') {
        if ((!!current.amount || current.amount === 0) && typeof current.amount === 'number' && current.formatted && typeof current.formatted === 'string') {
          count++;
          let amount = current.amount;
          if (current.unit) {
            amount = toMetricTons(amount, current.unit);
            if (!unit) unit = current.unit;
            else {
              if (unit.code !== current.unit.code) unit = this.mtUnit;
            }
          }
          return previous + amount;
        }
        problem = true;
        return previous;
      }
      if (!previous) previous = 0;
      if (current === 'Error') {
        problem = true;
        return previous;
      }
      if (typeof current === 'string') {
        current = current.replace(/\D/g, '');
        try {
          current = Number(current);
        } catch (e) {
          problem = true;
          return previous;
        }
        count++;
        return current || 0;
      }
      if (typeof current === 'number') {
        count++;
        return previous + (current || 0);
      }
      problem = true;
      return previous;
    }, null);

    if (!unit) unit = this.mtUnit;
    if (unit.code !== 'MT') total = toUnit(type === 'avg' ? total / count : total, this.mtUnit, unit);

    return { total, problem, unit };
  }

  currencyAggReducer(values: any[], type: 'sum' | 'avg' = 'sum'): { total: number; problem: boolean; currency: Currency } {
    let problem = false;
    let currency: Currency = null;
    let count = 0;
    let total: number = values.reduce((previous: number, current) => {
      if (problem) return previous;

      if (!current && current !== 0) {
        problem = true;
        return previous;
      }
      if (typeof current === 'object') {
        if ((!!current.amount || current.amount === 0) && typeof current.amount === 'number' && current.formatted && typeof current.formatted === 'string') {
          count++;
          let amount = current.amount;
          if (current.currency && (currency === null || currency.code === current.currency.code)) return previous + amount;
          else {
            problem = true;
            return problem;
          }
        }
        problem = true;
        return previous;
      }
      problem = true;
      return previous;
    }, null);

    total = type === 'avg' && total !== null ? total / count : total;

    return { total, currency, problem };
  }

  roundQuantity(quantity: number, unitOrUnitId: string | number | Unit): number | null {
    if (typeof quantity !== 'number' || isNaN(quantity)) return null;
    const unit = this.unitFromTag(unitOrUnitId);
    const decimalPlaces = unit ? unit.precision : 3;
    return round(quantity, decimalPlaces);
  }

  roundAmount(amount: number, currencyOrCurrencyId: string | number | Currency): number | null {
    if (typeof amount !== 'number' || isNaN(amount)) return null;
    const currency = this.currencyFromTag(currencyOrCurrencyId);
    const decimalPlaces = currency ? currency.currAmountPrecision : 2;
    return round(amount, decimalPlaces);
  }

  roundPrice(price: number, currencyOrCurrencyId: string | number | Currency, unitOrUnitId: string | number | Unit): number | null {
    if (typeof price !== 'number' || isNaN(price)) return null;
    const pricePrecision = this.getPricePrecision(currencyOrCurrencyId, unitOrUnitId);
    const decimalPlaces = pricePrecision ? pricePrecision.precision : 4;
    return round(price, decimalPlaces);
  }
}
