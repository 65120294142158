<div class="form-group" style="display: flex; flex-flow: row; height: 30px">
  <button kendoButton type="button" (click)="autosizeColumns()" title="Auto size columns" [icon]="'arrows-resizing'"></button>
  <input kendoTextBox [(ngModel)]="quickFilterText" (input)="quickFilter(quickFilterText)" style="width: auto; margin-left: auto" />
  <button kendoButton type="button" [icon]="'filter-clear'" (click)="resetFilters()" style="margin-left: 5px" title="Clear filters"></button>
</div>
<div class="d-flex" style="height: 100%; flex-direction: column">
  <div style="width: 100%; flex-grow: 1">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-balham" [rowData]="shipments" [ngClass]="{ hidden: shipments === null }" [gridOptions]="gridOptions"> </ag-grid-angular>
  </div>
</div>
