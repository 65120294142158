import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  StatusPanelDef,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { round } from 'lodash';
import Mexp from 'math-expression-evaluator';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { Store } from 'src/app/core/services/store.service';
import { TableForm } from 'src/app/shared/aggrid/TableForm';
import { AutoCompleteEditor } from 'src/app/shared/aggrid/autocompleteeditor/AutoCompleteEditor';
import { NewValueParams } from 'src/app/shared/aggrid/types';
import { ValidationStatusPanel } from 'src/app/shared/aggrid/validationstatuspanel/ValidationStatusPanel';
import { DropdownConfig } from 'src/lib';
import { enumValueGetter, getContextMenuItems, metalUnitPercentageFormatter, quantityColumn } from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import {
  CommonSteelTypeGroups,
  CommonUnits,
  ContractClass,
  ContractForNP,
  ContractLine,
  ContractListView,
  ContractType,
  ContractTypes,
  InvoiceHeader,
  Item,
  NetPositionAccount,
  NetPositionAccountsTypesEnum,
  PropertyDocument,
  YN,
} from 'src/lib/newBackendTypes';
import { FutureContract } from 'src/lib/newBackendTypes/futureContract';
import { NetPositionJournalEntriesForm } from 'src/lib/newBackendTypes/netPositionEntries';
import { PcPriceFixing } from 'src/lib/newBackendTypes/priceFixation';

@UntilDestroy()
@Component({
  selector: 'risk-management-net-position-journal-lines',
  templateUrl: './net-position-journal-lines.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NetPositionJournalLinesComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => NetPositionJournalLinesComponent),
    },
  ],
})
export class NetPositionJournalLinesComponent extends TableForm<NetPositionJournalEntriesForm> {
  /**
   * Will hold a reference to the grid API
   */
  gridApi: GridApi | null = null;

  /**
   * Flags status of the grid
   */
  private _readonly: boolean;

  @Input()
  set readonly(val: boolean) {
    this._readonly = val;
    if (this.gridApi) {
      const statusPanel: ValidationStatusPanel | undefined = this.gridApi.getStatusPanel('validationStatusPanel') as ValidationStatusPanel;
      if (!!statusPanel) {
        statusPanel.setReadonly(val);
      }
    }
    this.refreshDefinitions();
  }

  get readonly() {
    return this._readonly;
  }

  /**
   * Keeps track of the company ID passed as an input, used to filter the accounts dropdown
   */
  private _companyId: number | null = null;

  @Input()
  set companyId(companyId: number | null) {
    this._companyId = companyId;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get companyId() {
    return this._companyId;
  }

  /**
   * Keeps track of the class passed as an input, used to filter the accounts dropdown
   */
  private _class: ContractClass | null = null;

  @Input()
  set class(contractClass: ContractClass | null) {
    this._class = contractClass;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get class() {
    return this._class;
  }

  /**
   * Keeps track of the product ID passed as an input, used to filter the accounts dropdown
   */
  private _productId: number | null = null;

  @Input()
  set productId(productId: number | null) {
    this._productId = productId;
    this.refreshDefinitions();
    this.onValidationChange();
  }

  get productId() {
    return this._productId;
  }

  /**
   * Any possible error messages or null if the grid is valid
   */
  errors: string[] = [];

  /**
   * authorized endpoints
   */
  authorized: endpointsAuthorized;

  constructor(store: Store, private formatter: DataFormattingService, public delegate: DelegateService) {
    super();
    const body = document.querySelector('body');
    const statusPanels: StatusPanelDef[] = [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: ValidationStatusPanel,
        align: 'right',
        key: 'validationStatusPanel',
        statusPanelParams: {
          errors: this.errors,
          readonly: this.readonly,
        },
      },
    ];

    this.gridOptions = {
      getRowId: (params) => params.data.linePosition,
      domLayout: 'normal',
      popupParent: body,
      suppressAggFuncInHeader: true,
      animateRows: false,
      getRowStyle: (params) => (params?.node?.group ? { 'font-weight': 'bold' } : {}),
      groupIncludeFooter: true,
      groupDefaultExpanded: 1,
      groupSuppressAutoColumn: false,
      groupUseEntireRow: false,
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 135,
      },
      stopEditingWhenCellsLoseFocus: true,
      columnDefs: this.getColumnDefinitions(),
      rowSelection: 'single',
      suppressRowClickSelection: true,
      getContextMenuItems: getContextMenuItems('separator', this.addLineOption(), this.deleteLineOption()),
      statusBar: { statusPanels },
    };
    endpointAuthorizationSubscription(store, this);
  }

  onGridReady(event: GridReadyEvent) {
    if (!this.data) return;
    super.onGridReady(event);
    this.validateUniqueAccountContractElementAndShipment();
    this.validateContractsClass();
    this.validateContractsCompany();
    this.refreshDefinitions();
    this.gridApi.refreshCells();
  }

  private refreshDefinitions() {
    const definitions = this.getColumnDefinitions();
    if (this.gridApi) this.gridApi.setColumnDefs(definitions);
  }

  private getColumnDefinitions(): (ColDef | ColGroupDef)[] {
    const definitions: (ColDef | ColGroupDef)[] = [
      {
        field: 'linePosition',
        hide: true,
      },
      {
        field: 'quantityUnitId',
        hide: true,
      },
      {
        field: 'account',
        headerName: 'Account',
        width: 220,
        editable: true,
        enableRowGroup: true,
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 250,
          propertyRendered: 'description',
          returnObject: true,
          asyncListConfig: new DropdownConfig<NetPositionAccount>({
            listProcedure: endpoints.listNetPositionAccounts,
            labelField: 'description',
            valueField: 'id',
            additionalFilters: { companyId: this.companyId },
            orderBy: { fieldName: 'description', order: 'ASC' },
          }),
          columnDefs: [
            { headerName: 'Account Name', field: 'description' },
            { headerName: 'ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.accountValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('account', null);
            return true;
          }
          return false;
        },
        keyCreator: (params) => params.value.idenLong,
        comparator: (valueA: NetPositionAccount | null, valueB: NetPositionAccount | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.description > valueB.description ? 1 : -1;
        },
        onCellValueChanged: (event: NewValueParams) => {
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        filter: 'agSetColumnFilter',
        cellClassRules: {
          'invalid-cell': (params) => !params.data.account,
        },
      },
      {
        field: 'contract',
        headerName: 'Contract',
        width: 230,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'contract') && this.validateAccountTypeInvalid(params.data.account.accountType, 'contract')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'number',
          returnObject: true,
          asyncListConfig: new DropdownConfig<ContractForNP>({
            listProcedure: endpoints.listContractsForNetPosition,
            labelField: 'number',
            valueField: 'id',
            additionalFilters: { companyId: this.companyId ?? undefined, class: this.class ?? undefined, productId: this.productId ?? undefined },
            orderBy: { fieldName: 'number', order: 'DESC' },
          }),
          columnDefs: [
            { headerName: 'Number', field: 'number' },
            { headerName: 'Type', field: 'type', valueGetter: enumValueGetter('type', ContractTypes) },
            { headerName: 'Quantity', field: 'quantity', valueFormatter: this.formatter.gridUnitFormatter('quantityUnitId') },
            { headerName: 'Contract ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.contractValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('contract', null);
            params.node.setDataValue('element', null);
            params.node.setDataValue('fixation', null);
            params.node.setDataValue('shipment', null);
            params.node.setDataValue('quantity', null);
            params.node.setDataValue('metalUnitPercentage', null);
            params.node.setDataValue('quantityUnitId', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            event.node.setDataValue('element', null);
            event.node.setDataValue('shipment', null);
            event.node.setDataValue('fixation', null);
            event.node.setDataValue('quantity', null);
            event.node.setDataValue('metalUnitPercentage', null);
            event.node.setDataValue('quantityUnitId', event.newValue.quantityUnitId);
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: ContractForNP | null, valueB: ContractForNP | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.number > valueB.number ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.contract?.number;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'contract') && !params.data.contract),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'contract') && this.validateAccountTypeInvalid(params.data.account.accountType, 'contract')),
        },
      },
      {
        field: 'element',
        headerName: 'Contract Line',
        width: 180,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.contract || !params.data.contract?.id) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'element') && this.validateAccountTypeInvalid(params.data.account.accountType, 'element')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'lineNumber',
          returnObject: true,
          rowData: (params) => {
            if (params.data.contract) {
              return params.data.contract.lines.map((line) => {
                if (line.line) return line.line;
                else return line;
              });
            }
            return [];
          },
          columnDefs: [
            { headerName: 'Line number', field: 'lineNumber' },
            { headerName: 'Quantity', field: 'quantity' },
            { headerName: 'Line ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.contractLineValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('element', null);
            params.node.setDataValue('shipment', null);
            params.node.setDataValue('fixation', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          event.node.setDataValue('shipment', null);
          event.node.setDataValue('fixation', null);
          if (event.newValue && event.newValue.id) {
            if (event.newValue.item) event.node.setDataValue('item', event.newValue.item);
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: ContractLine | null, valueB: ContractLine | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.lineNumber > valueB.lineNumber ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.element?.lineNumber;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'element') && !params.data.element),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'element') && this.validateAccountTypeInvalid(params.data.account.accountType, 'element')),
        },
      },
      {
        field: 'shipment',
        headerName: 'Shipment',
        width: 180,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.contract || !params.data.contract?.id) return false;
          if (!params.data.element || !params.data.element?.id) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'shipment') && this.validateAccountTypeInvalid(params.data.account.accountType, 'shipment')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'id',
          returnObject: true,
          rowData: (params) => {
            if (params.data.contract && params.data.contract.type && params.data.element) {
              const shipments = params.data.contract.type === ContractType.PURCHASE ? params.data.element.shipmentsAsPurchase : params.data.element.shipmentsAsSale;
              return shipments.filter((ship) => {
                if (params.data.contract.type === ContractType.PURCHASE) return ship?.purchaseElementId === params.data?.element?.id;
                if (params.data.contract.type === ContractType.SALE) return ship?.saleElementId === params.data?.element?.id;
              });
            }
            return [];
          },
          columnDefs: [
            { headerName: 'Shipment ID', field: 'id' },
            { headerName: 'Container', field: 'containerMarks' },
          ],
        },
        valueFormatter: this.shipmentValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('shipment', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          event.node.setDataValue('invoice', null);
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: PropertyDocument | null, valueB: PropertyDocument | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.id > valueB.id ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.shipment?.id;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) => {
            const checkInvalid = this.checkInvalidCombination(params.data);
            return (
              !this.validateContractsClass() ||
              !this.validateContractsCompany() ||
              (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'shipment') && !params.data.shipment) ||
              checkInvalid.invalid
            );
          },
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'shipment') && this.validateAccountTypeInvalid(params.data.account.accountType, 'shipment')),
        },
      },
      {
        field: 'fixation',
        headerName: 'Fixation',
        width: 180,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.contract || !params.data.contract?.id) return false;
          if (!params.data.element || !params.data.element?.id) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'fixation') && this.validateAccountTypeInvalid(params.data.account.accountType, 'fixation')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: (params) => {
          return {
            minWidth: 300,
            propertyRendered: 'id',
            returnObject: true,
            rowData: (params) => {
              if (params.data.contract && params.data.contract.type && params.data.element) return params.data.element.priceFixations;
              return [];
            },
            columnDefs: [
              { headerName: 'Fixation ID', field: 'id' },
              { headerName: 'Quantity Fixed', field: 'quantityFixed', valueFormatter: this.formatter.gridStaticUnitFormatter(params.data.quantityUnitId) },
            ],
          };
        },
        valueFormatter: this.fixationValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('fixation', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: PcPriceFixing | null, valueB: PcPriceFixing | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.id > valueB.id ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.fixation?.id;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'fixation') && !params.data.fixation),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'fixation') && this.validateAccountTypeInvalid(params.data.account.accountType, 'fixation')),
        },
      },
      {
        field: 'invoice',
        headerName: 'Invoice',
        width: 180,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.contract || !params.data.contract?.id) return false;
          if (!params.data.element || !params.data.element?.id) return false;
          if (!params.data.shipment || !params.data.shipment?.id) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'invoice') && this.validateAccountTypeInvalid(params.data.account.accountType, 'invoice')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'iden',
          returnObject: true,
          rowData: (params) => {
            if (params.data.contract && params.data.shipment) {
              if (
                params.data.contract.type === ContractType.PURCHASE &&
                params.data.shipment.purchaseDelivery &&
                params.data.shipment.purchaseDelivery.deliveryKey > 0 &&
                params.data.shipment.purchaseDelivery.invoice
              ) {
                return [params.data.shipment.purchaseDelivery.invoice];
              } else if (
                params.data.contract.type === ContractType.SALE &&
                params.data.shipment.saleDelivery &&
                params.data.shipment.saleDelivery.deliveryKey > 0 &&
                params.data.shipment.saleDelivery.invoice
              ) {
                return [params.data.shipment.saleDelivery.invoice];
              }
              return [];
            }
            return [];
          },
          columnDefs: [
            { headerName: 'Invoice Name', field: 'iden' },
            { headerName: 'Invoice ID', field: 'id', hide: true },
          ],
        },
        valueFormatter: this.invoiceValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('invoice', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: InvoiceHeader | null, valueB: InvoiceHeader | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.iden > valueB.iden ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.invoice?.iden;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'invoice') && !params.data.invoice),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'invoice') && this.validateAccountTypeInvalid(params.data.account.accountType, 'invoice')),
        },
      },
      {
        field: 'futureContract',
        headerName: 'Future',
        width: 230,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'futureContract') && this.validateAccountTypeInvalid(params.data.account.accountType, 'futureContract')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'contractNumber',
          returnObject: true,
          asyncListConfig: new DropdownConfig<FutureContract>({
            listProcedure: endpoints.listFutureContracts,
            labelField: 'contractNumber',
            valueField: 'id',
            additionalFilters: { companyId: this.companyId ?? undefined },
            orderBy: { fieldName: 'id', order: 'DESC' },
          }),
          columnDefs: [
            { headerName: 'Id', field: 'id', hide: true },
            { headerName: 'Contract Number', field: 'contractNumber' },
            { headerName: '# of Lots', field: 'numberOfLots' },
          ],
        },
        valueFormatter: this.futureValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('futureContract', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: ContractListView | null, valueB: ContractListView | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.contractNumber > valueB.contractNumber ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.contract?.number;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'futureContract') && !params.data.futureContract),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account &&
              !this.validateAccountTypeEnabled(params.data.account.accountType, 'futureContract') &&
              this.validateAccountTypeInvalid(params.data.account.accountType, 'futureContract')),
        },
      },
      {
        field: 'item',
        headerName: 'Item',
        width: 200,
        editable: (params: EditableCallbackParams) => {
          if (this.readonly) return false;
          if (!params.data.contract || !params.data.contract?.id) return false;
          if (!params.data.account) return false;
          if (!this.validateAccountTypeEnabled(params.data.account.accountType, 'item') || this.validateAccountTypeInvalid(params.data.account.accountType, 'item')) return false;
          return true;
        },
        cellEditor: AutoCompleteEditor,
        cellEditorParams: {
          minWidth: 300,
          propertyRendered: 'name',
          returnObject: true,
          asyncListConfig: new DropdownConfig<Item>({
            listProcedure: endpoints.listItems,
            labelField: 'name',
            valueField: 'id',
            additionalFilters: { groupTypeId: CommonSteelTypeGroups.DEFAULT, archived: YN.N, productId: this.productId ?? undefined },
            orderBy: { fieldName: 'name', order: 'DESC' },
          }),
          columnDefs: [
            { headerName: 'ID', field: 'id', hide: true },
            { headerName: 'Name', field: 'name' },
          ],
        },
        valueFormatter: this.itemValueFormatter,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('item', null);
            return true;
          }
          return false;
        },
        onCellValueChanged: (event: NewValueParams) => {
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        comparator: (valueA: Item | null, valueB: Item | null) => {
          if (valueA == valueB) return 0;
          if (valueA === null) return -1;
          if (valueB === null) return 1;
          return valueA.id > valueB.id ? 1 : -1;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: (params) => {
            return params.data.item?.id;
          },
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            !this.validateContractsClass() ||
            !this.validateContractsCompany() ||
            (params.data.account && this.validateAccountTypeEnabled(params.data.account.accountType, 'item') && !params.data.item),
          'readonly-cell': (params) =>
            !params.data.account ||
            (params.data.account && !this.validateAccountTypeEnabled(params.data.account.accountType, 'item') && this.validateAccountTypeInvalid(params.data.account.accountType, 'item')),
        },
      },
      {
        field: 'quantity',
        headerName: 'Metal Units',
        width: 150,
        editable: true,
        valueFormatter: this.quantityValueFormatter(),
        ...quantityColumn(),
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          if (isNaN(value)) {
            params.data.quantity = null;
            return params.oldValue !== params.newValue;
          } else {
            params.data.quantity = this.formatter.roundQuantity(value, CommonUnits.MT);
            return parseFloat(params.oldValue) !== value;
          }
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            if (event.data.metalUnitPercentage) event.node.setDataValue('physicalUnits', this.calculatePhysicalUnits(event.newValue, event.data.metalUnitPercentage));
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        cellClassRules: {
          'invalid-cell': (params) =>
            (!!params.data && typeof params.data.quantity !== 'number') ||
            (params.data.contract && this.getPhysicalQuantity(params.data.quantity) < this.formatter.roundQuantity(params.data.quantity, CommonUnits.MT)),
        },
      },
      {
        field: 'physicalUnits',
        headerName: 'Physical Units',
        width: 150,
        valueFormatter: this.quantityValueFormatter(),
        ...quantityColumn(),
        cellClassRules: {
          'readonly-cell': () => true,
        },
      },
      {
        field: 'metalUnitPercentage',
        headerName: 'MUP',
        width: 160,
        editable: () => {
          if (this.readonly) return false;
          return true;
        },
        valueFormatter: metalUnitPercentageFormatter(),
        ...quantityColumn(),
        valueSetter: (params: ValueSetterParams) => {
          let value: number;
          try {
            value = typeof params.newValue === 'number' ? params.newValue : parseFloat(Mexp.eval(`${params.newValue || ''}`));
          } catch (err) {
            value = NaN;
          }
          if (isNaN(value)) {
            params.data.metalUnitPercentage = null;
            return params.oldValue !== params.newValue;
          } else {
            params.data.metalUnitPercentage = round(value, 3);
            return parseFloat(params.oldValue) !== value;
          }
        },
        cellClassRules: {
          'invalid-cell': (params) => !params.value && (params.data.metalUnitPercentage < 0 || params.data.metalUnitPercentage > 100 || typeof params.data.metalUnitPercentage !== 'number'),
        },
        onCellValueChanged: (event: NewValueParams) => {
          if (event.newValue) {
            if (event.data.quantity) event.node.setDataValue('physicalUnits', this.calculatePhysicalUnits(event.data.quantity, event.newValue));
          }
          this.refreshDefinitions();
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Delete') {
            params.node.setDataValue('metalUnitPercentage', null);
            return true;
          }
          return false;
        },
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'comments',
        headerName: 'Comments',
        width: 300,
        editable: true,
        onCellValueChanged: (event: NewValueParams) => {
          event.api.redrawRows({ rowNodes: [event.node] });
        },
        filter: 'agTextColumnFilter',
      },
    ];
    return definitions;
  }

  onCellValueChanged(event: NewValueParams) {
    this.onDataChanged();
  }

  onDataChanged() {
    if (typeof this.onChange === 'function') {
      this.onChange(
        this.data.map((line): NetPositionJournalEntriesForm => {
          return {
            linePosition: line.linePosition,
            id: line.id ?? null,
            account: line.account,
            accountId: line.account ? line.account.id : undefined,
            contract: line.contract,
            contractId: line.contract ? line.contract.id : undefined,
            element: line.element,
            elementId: line.element ? line.element.id : undefined,
            shipment: line.shipment,
            shipmentId: line.shipment ? line.shipment.id : undefined,
            invoice: line.invoice,
            invoiceId: line.invoice ? line.invoice.id : undefined,
            fixation: line.fixation,
            fixationId: line.fixation ? line.fixation.id : undefined,
            futureContract: line.futureContract,
            futureId: line.futureContract ? line.futureContract.id : undefined,
            item: line.item,
            itemId: line.item ? line.item.id : undefined,
            quantity: line.quantity || null,
            metalUnitPercentage: line.metalUnitPercentage || null,
            quantityUnitId: line.quantityUnitId,
            comments: line.comments ?? null,
          };
        })
      );
    }
    if (typeof this.onValidationChange === 'function') {
      this.onValidationChange();
    }
    this.validateUniqueAccountContractElementAndShipment();
    this.validateContractsClass();
    this.validateContractsCompany();
  }

  writeValue(lines: NetPositionJournalEntriesForm[]): void {
    if (!lines) return;
    this.data = lines;
  }

  addLine() {
    if (this.gridApi) {
      const maxPosition = this.data.reduce((position, existing) => Math.max(position, existing.linePosition), 0) || 0;
      const row: NetPositionJournalEntriesForm = {
        linePosition: maxPosition + 1,
        id: null,
        account: null,
        contract: null,
        element: null,
        shipment: null,
        invoice: null,
        fixation: null,
        futureContract: null,
        item: null,
        quantity: null,
        metalUnitPercentage: null,
        quantityUnitId: null,
        comments: null,
      };
      this.data.push(row);
      this.gridApi.setRowData(this.data);
      this.onDataChanged();
      return row;
    }
  }

  addLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (this.readonly) return [];
      return [
        {
          name: `Add Line`,
          action: this.addLine.bind(this),
        },
      ];
    };
  }

  deleteLineOption() {
    return (params: GetMainMenuItemsParams | GetContextMenuItemsParams) => {
      if (this.readonly) return [];
      const selectedNodes = params.api.getSelectedNodes();
      const thisNode = (params as GetContextMenuItemsParams).node;
      const options = [];
      if (!!thisNode && (selectedNodes.length === 0 || selectedNodes.length === 1 || !selectedNodes.includes(thisNode)) && this.data.length > 1) {
        options.push({
          name: `Delete This Line`,
          action: () => {
            const index = this.data.findIndex((row) => row.linePosition === thisNode.data.linePosition);
            if (index < 0) return;
            this.data.splice(index, 1);
            this.gridApi.setRowData(this.data);
            this.onDataChanged();
          },
        });
      }
      return options;
    };
  }

  getPhysicalQuantity(quantity: number) {
    return this.formatter.roundQuantity(quantity, CommonUnits.MT);
  }

  calculatePhysicalUnits(metalUnits: number, metalUnitPercentage: number) {
    return round((metalUnits * 100) / metalUnitPercentage, 3);
  }

  accountValueFormatter(row: { data: NetPositionJournalEntriesForm }) {
    if (row && row.data && row.data.account && row.data.account.description) return row.data.account.description;
    return null;
  }

  contractValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.number) return `${params.value.type === ContractType.PURCHASE ? 'MP' : 'MS'} - ${params.value.number}`;
    return '';
  }

  contractLineValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.lineNumber && params.value.quantity) return `${params.value.lineNumber}`;
    return '';
  }

  shipmentValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.id) return `S - ${params.value.id}`;
    return '';
  }

  fixationValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.id) return `PF - ${params.value.id}`;
    return '';
  }

  invoiceValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.iden) return `${params.value.iden}`;
    return '';
  }

  futureValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.contractNumber) {
      const longOrShort = params.value.numberOfLots > 0 ? 'Long' : params.value.numberOfLots < 0 ? 'Short' : '';
      return `F ${params.value.contractNumber} - ${longOrShort}`;
    }
    return '';
  }

  itemValueFormatter(params: ValueFormatterParams) {
    if (params && params.value && params.value.name) return `${params.value.name}`;
    return '';
  }

  quantityValueFormatter() {
    return (params: ValueFormatterParams) => {
      if (params && params.value) return this.formatter.gridStaticUnitFormatter(CommonUnits.MT)(params);
      return this.formatter.gridUnitFormatter('quantityUnitId')(params);
    };
  }

  validateUniqueAccountContractElementAndShipment() {
    if (this.data.length > 0) {
      const uniqueCombinations = new Set();
      const duplicatedCombinationData = new Set<{ accountId: number; contractId: number; elementId: number; shipmentId: number; linePosition: number }>();
      for (const item of this.data) {
        const { contract, account, element, shipment, linePosition } = item;
        if (contract && account && element && shipment) {
          const combination = `${account.id}-${contract.id}-${element.id}-${shipment.id}`;
          if (uniqueCombinations.has(combination)) duplicatedCombinationData.add({ shipmentId: item.shipment.id, accountId: account.id, contractId: contract.id, elementId: element.id, linePosition });
          else uniqueCombinations.add(combination);
        }
      }
      if (duplicatedCombinationData.size > 0) return { invalid: true, data: Array.from(duplicatedCombinationData.values()) };
    }
    return { invalid: false };
  }

  validateContractsClass() {
    if (this.data.length > 0) {
      if (!this.class) return true;
      return !this.data.every((line) => line.contract && line.contract.productClass !== this.class);
    }
    return true;
  }

  validateContractsCompany() {
    if (this.data.length > 0) {
      if (!this.companyId) return true;
      return !this.data.every((line) => line.contract && line.contract.companyId !== this.companyId);
    }
    return true;
  }

  validateAccountTypeEnabled(accountType: NetPositionAccountsTypesEnum, columnName: string) {
    const required = this.getRequiredColumnsByType(accountType);
    return required.includes(columnName);
  }

  validateAccountTypeInvalid(accountType: NetPositionAccountsTypesEnum, columnName: string) {
    const invalid = this.getInvalidColumnsByType(accountType);
    return invalid.includes(columnName);
  }

  checkInvalidCombination(line: NetPositionJournalEntriesForm) {
    const invalidCombination = this.validateUniqueAccountContractElementAndShipment();
    const hasAllPropertiesToCheck = line.account && line.contract && line.element && line.shipment;
    if (!hasAllPropertiesToCheck) return { invalid: false };
    const { invalid, data } = invalidCombination;
    if (!data && !invalid) return { invalid: false };

    const shipmentData = data.find((item) => item.accountId === line.account.id && item.contractId === line.contract.id && item.elementId === line.element.id && item.shipmentId === line.shipment.id);
    if (!shipmentData) return { invalid: false };
    return { invalid: true, linePosition: shipmentData.linePosition };
  }

  getRequiredColumnsByType(accountType: NetPositionAccountsTypesEnum) {
    if (accountType === NetPositionAccountsTypesEnum.CONTRACT) {
      return ['contract', 'element', 'item'];
    } else if (accountType === NetPositionAccountsTypesEnum.FUTURES || accountType === NetPositionAccountsTypesEnum.ADMIN) {
      return ['futureContract'];
    } else if (accountType === NetPositionAccountsTypesEnum.INVENTORY || accountType === NetPositionAccountsTypesEnum.VOUCHER) {
      return ['contract', 'element', 'shipment', 'item'];
    } else if (accountType === NetPositionAccountsTypesEnum.FIXATIONS_TO_APPLY) {
      return ['contract', 'element', 'fixation', 'item'];
    }

    return [];
  }

  getInvalidColumnsByType(accountType: NetPositionAccountsTypesEnum) {
    if (accountType === NetPositionAccountsTypesEnum.CONTRACT || accountType === NetPositionAccountsTypesEnum.VOUCHER || accountType === NetPositionAccountsTypesEnum.FIXATIONS_TO_APPLY) {
      return ['futureContract'];
    } else if (accountType === NetPositionAccountsTypesEnum.FUTURES_TO_BE_DONE) {
      return ['invoice'];
    } else if (accountType === NetPositionAccountsTypesEnum.FUTURES) {
      return ['element', 'shipment', 'invoice', 'fixation', 'item'];
    } else if (accountType === NetPositionAccountsTypesEnum.INVENTORY) {
      return ['futureContract', 'fixation'];
    } else if (accountType === NetPositionAccountsTypesEnum.ADMIN) {
      return ['element', 'shipment', 'invoice', 'fixation'];
    }

    return [];
  }

  validate(control: AbstractControl): ValidationErrors {
    this.errors.splice(0, this.errors.length);
    if (!(control.value instanceof Array)) {
      this.errors.push('Invalid line values');
    } else {
      let count = 0;
      for (const line of control.value as NetPositionJournalEntriesForm[]) {
        const pos = line.linePosition;
        if (line.account) {
          if (!line.contract) if (this.validateAccountTypeEnabled(line.account.accountType, 'contract')) this.errors.push(`Missing Contract on line #${pos}`);
          if (!line.element) if (this.validateAccountTypeEnabled(line.account.accountType, 'element')) this.errors.push(`Missing Element on line #${pos}`);
          if (!line.shipment) {
            if (this.validateAccountTypeEnabled(line.account.accountType, 'shipment')) this.errors.push(`Missing Shipment on line #${pos}`);
          } else {
            const checkInvalid = this.checkInvalidCombination(line);
            if (checkInvalid.invalid && checkInvalid.linePosition && line.linePosition === checkInvalid.linePosition)
              this.errors.push(`Shipment must be different for the combination of (Account/Contract/Contract Line) on line ${checkInvalid.linePosition}`);
          }
          if (!line.invoice) if (this.validateAccountTypeEnabled(line.account.accountType, 'invoice')) this.errors.push(`Missing Invoice on line #${pos}`);
          if (!line.item) if (this.validateAccountTypeEnabled(line.account.accountType, 'item')) this.errors.push(`Missing Item on line #${pos}`);
          if (!line.fixation) if (this.validateAccountTypeEnabled(line.account.accountType, 'fixation')) this.errors.push(`Missing Fixation on line #${pos}`);
          if (!line.futureContract) if (this.validateAccountTypeEnabled(line.account.accountType, 'futureContract')) this.errors.push(`Missing Future on line #${pos}`);
        } else this.errors.push(`Missing Account on line #${pos}`);
        if (!line.quantity) this.errors.push(`Missing Metal Units on line #${pos}`);
        if (!line.metalUnitPercentage) this.errors.push(`Missing MUP on line #${pos}`);

        count++;
      }
      if (count < 1) this.errors.push(`There should be at least one valid line`);
    }
    return !!this.errors && this.errors.length > 0 ? { custom: this.errors[0] } : null;
  }
}
