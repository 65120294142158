import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { deepFind } from '../../../lib/deepFindRegex';
import { MenuItem } from '../../../lib/MenuItem';
import { StoreSubscription } from '../../../lib/StoreSubscription';
import { State } from '../reducers';
import { CALCULATED_DYNAMIC_MENUS, SET_AUTH_MESSAGE, SET_USER } from '../reducers/actions';
import { UserState } from '../reducers/user';
import { SpinnerService } from './spinner.service';
import { Store } from './store.service';

@Injectable()
export class AuthGuard implements CanActivateChild, OnDestroy, CanActivate {
  storeSub: StoreSubscription<{ permittedMenu: MenuItem[]; user: UserState }>;

  constructor(private router: Router, private msalService: MsalService, private store: Store, private spinnerService: SpinnerService) {
    this.storeSub = this.store.subscribe(
      (state: State) => {
        return {
          permittedMenu: state.layout.permittedMenu,
          user: state.user,
        };
      },
      [CALCULATED_DYNAMIC_MENUS, SET_USER]
    );
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(routerState);
  }

  canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(routerState);
  }

  private _canActivate(routerState: RouterStateSnapshot): Observable<boolean> {
    let accounts = this.msalService.instance.getAllAccounts();
    let rId: string;
    return this.storeSub.$.pipe(
      filter(({ permittedMenu, user }) => !!permittedMenu || user.isLoggedIn === false),
      switchMap(async (permittedMenu) => {
        if (accounts.length === 0) {
          if (routerState.url.includes('portal/') && routerState.url.length > 7) {
            // sessionStorage.setItem('thalos-url-target', routerState.url)
            this.store.dispatch({
              type: SET_AUTH_MESSAGE,
              payload: 'You must be logged in to access this page',
            });
          }
          // this.router.navigate(['/login']);
          return false;
        }
        if (!permittedMenu) {
          rId = this.spinnerService.startRequest('Building menu');
          return true;
        } else {
          if (rId) this.spinnerService.completeRequest(rId);
        }
        var obj = deepFind(routerState.url, permittedMenu, { searchField: 'regexPath' });
        if (obj) return true;
        if (routerState.url === '/portal') return true;
        let successfulRoute = await this.router.navigate(['/error/unauthorized']);
        if (successfulRoute === false) window.location.href = '/error/unauthorized';
        return false;
      })
    );
  }

  ngOnDestroy() {
    this.storeSub.unsubscribe();
  }
}
